import { makeStyles } from "@mui/styles";
import {alpha} from "@mui/material/styles";
import {Theme} from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  field: {
    width: '100%',
    '& .MuiInputBase-root': {
      '& input': {
        minWidth: '100%',
      }
    }
  },


  selectAll: {
    cursor: "pointer",
    padding: '6px 16px',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: alpha(theme.palette.action.active, theme.palette.action.activatedOpacity),
  },

  borderedFlag: {
    width: 20,
    marginRight: 8,
    fontSize: 0,
    '& img': {
      width: 20,
      height: 'auto',
      border: `1px solid ${alpha(theme.palette.secondary.light, 0.2)}`,
    },
  },
  chipAvatar: {
    margin: '0 -4px 0 8px!important',
  },
  chipFlag: {
    display: 'flex',
    width: 18,
    height: 18,
    fontSize: 0,
    '& img': {
      width: 18,
      height: 'auto',
    },
  },

  customScroll: {
    margin: '0 -59px 0px 0px',
    width: 'auto!important',
    '& .view-select': {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '0 60px 0 0',
    },
    '& .track-vertical': {
      top: 0,
      bottom: 0,
      right: 2,
      borderRadius: '3px',
      backgroundColor: alpha(theme.palette.action.selected, theme.palette.action.selectedOpacity),
      '& > div': {
        position: 'relative',
        display: 'block',
        width: '100%',
        cursor: 'pointer',
        borderRadius: 'inherit',
        backgroundColor: `${alpha(theme.palette.secondary.light, 0.3)}!important`,
      }
    },
  },
  loader: {
    pointerEvents: 'none',
    display: 'block',
    position: 'absolute',
    right: 11,
    top: '50%',
    marginTop: '-10px',
  },
}));
