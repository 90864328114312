export interface IItemOption {
  id: string | number;
  value: string | string[];
  expanded: boolean;
}

type StrNum = string | number;

export const getPlayerOptions = (options: IItemOption[]) => {
  const hasProduct = !!options.filter((el: IItemOption) => el.id === 'group_by_product').length;
  const hasManager = !!options.filter((el: IItemOption) => el.id === 'group_by_manager').length;
  const hasAffiliate = !!options.filter((el: IItemOption) => el.id === 'group_by_affiliate').length;
  const hasTracker = !!options.filter((el: IItemOption) => el.id === 'group_by_tracker').length;
  const hasCreative = !!options.filter((el: IItemOption) => el.id === 'group_by_creative').length;
  const hasBrand = !!options.filter((el: IItemOption) => el.id === 'group_by_brand').length;
  const hasCurrency = !!options.filter((el: IItemOption) => el.id === 'group_by_currency').length;
  const hasSource = !!options.filter((el: IItemOption) => el.id === 'group_by_source').length;
  const hasPlayer = !!options.filter((el: IItemOption) => el.id === 'group_by_player').length;
  const hasAnid = !!options.filter((el: IItemOption) => el.id === 'group_by_anid').length;

  return {
    ...(hasManager ? {
      "manager": "group_by_manager",
    } : {}),
    ...(hasAffiliate ? {
      "affiliate": "group_by_affiliate",
    } : {}),
    ...(hasTracker ? {
      "tracker": "group_by_tracker",
    } : {}),
    ...(hasCreative ? {
      "creative": "group_by_creative",
    } : {}),
    ...(hasBrand ? {
      "brand": "group_by_brand",
    } : {}),
    ...(hasCurrency ? {
      "currency": "group_by_currency",
    } : {}),
    ...(hasSource ? {
      "source": "group_by_source",
    } : {}),
    ...(hasPlayer ? {
      "player": "group_by_player",
    } : {}),
    ...(hasAnid ? {
      "anid": "group_by_anid",
    } : {}),
    ...(hasProduct ? {
      "product": "group_by_product",
    } : {})
  }
}

export const hasActiveItem = (id: string | number, selected: IItemOption[]) => {
  const ids: StrNum[] = selected.reduce((acc: string[], item: IItemOption) => {
    if (typeof item.id === "string") {
      acc.push(item.id);
    }
    return acc;
  }, []);
  return ids.includes(id);
}
