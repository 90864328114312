import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles/createTheme";

const usePageBreadcrumbs = makeStyles<Theme>((theme) => ({
    breadcrumbs: {
        marginBottom: 0,
        '@media (max-width: 767px)': {
            overflowX: 'auto',
        },
        '& ol': {
            '@media (max-width: 767px)': {
                whiteSpace: 'nowrap',
                flexWrap: 'inherit',
            },
            '& li': {
                '@media (min-width: 768px)': {
                    lineHeight: '36px',
                },
            }
        }
    },
    link: {
        color: `${theme.palette.text.secondary}!important`,
        textDecoration: 'none!important',
        '&:hover': {
            color: `${theme.palette.primary.dark}!important`,
            textDecoration: 'underline!important',
        }
    },
    disabled: {
        color: theme.palette.text.disabled
    }
}));

export default usePageBreadcrumbs;
