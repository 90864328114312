//core
import React, {useRef, useState} from "react";
import arePropsEqual from "react-fast-compare";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import { Box, Paper, Tooltip } from "@mui/material";
import useDimensions from "../../../../../customHooks/useDimensions";

type Item = {
  id: string,
  label: string,
}

interface IArrayProps {
  value: Item[];
  prefix?: string;
  maxWidth?: number;
}

const useStyles = makeStyles({
  block: {
    position: "relative",
    color: "inherit",
    display: "block",
    width: "100%",
    minWidth: "60px",
  },
  wrapper: {
    width: "max-content",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  item: {
    display: "inline-block",
    whiteSpace: "nowrap",
    marginRight: "4px",
  },
  hide: {
    opacity: 0,
    visibility: "hidden",
  }
});

const ObjArray = ({ value, prefix, maxWidth = 320 }: IArrayProps) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const wrapperRef: any = useRef(null);
  const size = useDimensions(wrapperRef);

  const listArr = value.reduce((acc:any[], item) => {
    acc.push(!!prefix ? t(`${prefix}${item.label}`) : item.label)
    return acc
  }, []);

  const list = listArr.map((item, index) => {
    return <span key={`${Math.random()}-${index}`} className={styles.item}>{item}{index+1 !== listArr.length && ','}</span>
  });

  return (
    <>
      <Box className={styles.block} sx={{ maxWidth: `${maxWidth}px` }}>
        <Box ref={wrapperRef} className={styles.wrapper}
             sx={{ opacity: 0, visibility: "hidden", position: "absolute" }}
        >
          { list }
        </Box>
        {
          (size.width === maxWidth) ? (
            <Tooltip title={list}>
              <Box className={styles.wrapper}>
                { list }
              </Box>
            </Tooltip>
          ) : (
            <Box className={styles.wrapper}>
              {
                value.length > 0 ? list : '—'
              }
            </Box>
          )
        }
      </Box>
    </>
  );
};


export default React.memo(ObjArray, arePropsEqual);
