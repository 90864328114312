//core
import React from "react";
import {useTranslation} from "react-i18next";
import {Theme} from "@mui/material/styles/createTheme";
import {useTheme} from "@mui/styles";
import { Typography, Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

//icon
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import routes from "../../../../../navigation/routes";
import usePageBreadcrumbs from "../../../../common/Breadcrumbs/usePageBreadcrumbs";


const PageBreadcrumbs = () => {
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  const styles = usePageBreadcrumbs();

  return (
    <>
      <Breadcrumbs
        className={styles.breadcrumbs}
        separator={<NavigateNextIcon sx={{ color: theme.palette.action.active }} fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link className={styles.link} to={routes.root}>
          <Typography variant="overline">{t("common.breadcrumbs.dashboard")}</Typography>
        </Link>
        <Typography className={styles.disabled} variant="overline">
          {t(`common.breadcrumbs.admin.employees`)}
        </Typography>
      </Breadcrumbs>
    </>
  );
};

export default PageBreadcrumbs;
