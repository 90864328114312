import { useDispatch } from "react-redux";

import {
  getAffiliatesData,
  getChiefsData,
  getDepartmentsData,
  getPlatformsData,
  getCountriesData,
  getTrackersData,
  getWebhooksData,
  getOffersData,
  getOperatorsData,
  clearOperatorsState,
  OptionsPayloadType,
  getBrandsData,
} from "./index";

export const useFields = () => {
  const dispatch = useDispatch();

  const getAffiliates = (data: OptionsPayloadType) => dispatch(getAffiliatesData(data));
  const getChiefs = (data: OptionsPayloadType) => dispatch(getChiefsData(data));
  const getDepartments = (data: OptionsPayloadType) => dispatch(getDepartmentsData(data));
  const getPlatforms = (data: any) => dispatch(getPlatformsData(data));
  const getCountries = () => dispatch(getCountriesData());
  const getTrackers = (data: OptionsPayloadType) => dispatch(getTrackersData(data));
  const getWebhooks = (data: OptionsPayloadType) => dispatch(getWebhooksData(data));
  const getOffers = (data: OptionsPayloadType) => dispatch(getOffersData(data));
  const getOperators = (data: OptionsPayloadType) => dispatch(getOperatorsData(data));
  const clearOperatorsData = () => dispatch(clearOperatorsState());
  const getBrands = (data: OptionsPayloadType) => dispatch(getBrandsData(data));

  return {
    getAffiliates,
    getChiefs,
    getDepartments,
    getPlatforms,
    getCountries,
    getTrackers,
    getWebhooks,
    getOffers,
    getOperators,
    clearOperatorsData,
    getBrands,
  }
}
