import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import { OptionsPayloadType } from "../../store/common/fields";
import { cookieSupervision, cookieToken } from "../../constants";

const getAffiliates = (data: OptionsPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.fields.affiliates;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getChiefs = (data: OptionsPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.fields.chiefs;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getDepartments = (data: OptionsPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.fields.departments;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getPlatforms = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.fields.platforms;

  const sendData = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(sendData))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getCountries = () => {
  return fetch(`${api.reg_country}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getTrackers = (data: OptionsPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.fields.trackers;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getWebhooks = (data: OptionsPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.fields.webhooks;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getOffers = (data: OptionsPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.fields.offers;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getOperators = (data: OptionsPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.fields.operators;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getBrands = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.fields.brands_list;

  const sendData = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(sendData))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};



export const fieldsService = {
  getAffiliates,
  getChiefs,
  getDepartments,
  getPlatforms,
  getCountries,
  getTrackers,
  getWebhooks,
  getOffers,
  getOperators,
  getBrands
}
