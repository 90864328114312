//core
import React, {useCallback, useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import arePropsEqual from "react-fast-compare";
import {Box, Button, Typography} from "@mui/material";
import {useSelector} from "react-redux";

//components
import {useWebhooksReport} from "../../../../../../store/admin/reports/webhooks_report/useWebhooksReport";
import TableData from "../../../../../common/TableData";
import {OrderType} from "../../../../../affiliate/Trackers";
import {useStyles} from "./styles";
import {selectDetailsWebhook} from "../../../../../../store/admin/reports/webhooks_report/selectors";
import {Delete, RemoveRedEye} from "@mui/icons-material";
import ActionIconButton from "../../../../../UI/Buttons/ActionIconButton";
import RemoveAdmin from "../../../../Employees/components/RemoveAdmin";
import MuiModal from "../../../../../UI/MuiModal";
import DetailsWebhook from "../DetailsWebhook";


export interface ITableActions {
  id: number;
  data: any;
}


const initialOrderState: OrderType = {
  none: 'desc',
}

const TableActions: React.FC<ITableActions> = (props): JSX.Element => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();

  const { id, data } = props;

  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);

  const handleToggleDetailsModal = useCallback((state: boolean) => {
    setShowDetailsModal(state);
  }, [setShowDetailsModal]);

  return (

    <>
      <ActionIconButton
        text={t("common.buttons.view")}
        icon={<RemoveRedEye fontSize="small" color='primary' />}
        onClick={() => handleToggleDetailsModal(true)}
      />

      <MuiModal
        width={1072}
        open={showDetailsModal}
        closeOnOutside={false}
        setToggleModal={handleToggleDetailsModal}
      >
        <DetailsWebhook
          id={id}
          data={data}
          onClose={handleToggleDetailsModal}
        />
      </MuiModal>
    </>
  );
};

export default React.memo(TableActions, arePropsEqual);
