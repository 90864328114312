//core
import React, {useCallback, useState, useContext} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import arePropsEqual from "react-fast-compare";
import {
  Box,
  IconButton,
  Badge,
  Tooltip,
  useMediaQuery,
  Typography,
} from '@mui/material';

//styles
import { useStyles } from "./styles";

//icons
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SettingsIcon from '@mui/icons-material/Settings';

//helpers
import { countValuesInObj } from "../../../../../helpers/countValuesInObj";
import { getCookie } from "../../../../../helpers/utils";

//routes
import routes from "../../../../../navigation/routes";

//context
import { GlobalContext } from "../../../../../context/GlobalContext";

//constants
import { cookieSupervision } from "../../../../../constants";

//components
import MuiDrawerWrapper from "../../../../UI/MuiDrawerWrapper";
import PeriodDatesField from "../../../../UI/FormFields/PeriodDatesField";
import Filter from "../Filter";
import { initialDashboardV2FilterData, IPeriodsType } from "../../index";
import { IDashboardFilterType } from "../../types";

interface IWidgetsDrawerFilterProps {
  initialFilter: IDashboardFilterType;
  setUpdateFilter: React.Dispatch<React.SetStateAction<IDashboardFilterType>>;
  periods: IPeriodsType;
  setPeriods: (data: IPeriodsType) => void;
  availableFields: string[];
}

const WidgetsDrawerFilter: React.FC<IWidgetsDrawerFilterProps> = ({
  periods,
  setPeriods,
  initialFilter,
  setUpdateFilter,
  availableFields,
}): JSX.Element => {
  const history = useNavigate();
  const { t } = useTranslation();
  const styles = useStyles();

  const mobile = useMediaQuery('(max-width:767px)');
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const context = useContext(GlobalContext);

  const [openDrawer, setOpenDrawer] = useState(false);

  //state
  //const [filter, setFilter] = useState<IDashboardFilterData>(filter);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  }

  const handleClearFilters = useCallback(() => {
    setUpdateFilter(initialDashboardV2FilterData);
    context.resetDashboardV2Filter();
  }, [setUpdateFilter]);

  const handleApplyPeriods = (data: any) => {
    setPeriods(data);
  }

  const handleClickSettings = () => {
    history(routes.dashboard.settings);
  }

  return (
    <>
      <Box className={styles.block}>
        <Box className={styles.first}>
          {!mobile ? (
            <PeriodDatesField
              periods={periods}
              onApply={handleApplyPeriods}
            />
          ) : (
            <Typography variant="h6">{t("common.breadcrumbs.overview")}</Typography>
          )}
        </Box>

        <Box className={styles.second}>
          <Box className={styles.actions}>
            {/*reset filter*/}
            <IconButton
              className={styles.iconButton}
              size={mobile ? 'small' : 'medium'}
              onClick={handleClearFilters}
              disabled={arePropsEqual(initialDashboardV2FilterData, initialFilter)}
            >
              <Tooltip title={t("common.buttons.reset_filters")} placement="bottom">
                <FilterAltOffIcon
                  color={arePropsEqual(initialDashboardV2FilterData, initialFilter) ? 'inherit' : 'primary'}
                />
              </Tooltip>
            </IconButton>

            {/*open filter*/}
            <IconButton
              className={styles.iconButton}
              size={mobile ? 'small' : 'medium'}
              onClick={handleOpenDrawer}
            >
              <Tooltip title={t("common.components.filters.title")} placement="bottom">
                <Badge
                  className={styles.badge}
                  badgeContent={countValuesInObj(initialFilter)}
                  color="secondary"
                >
                  <FilterAltIcon
                    color={!!countValuesInObj(initialFilter) ? 'primary' : 'inherit'}
                  />
                </Badge>
              </Tooltip>
            </IconButton>

            {!isSupervision && (<>
              <IconButton
                className={styles.iconButton}
                size={mobile ? 'small' : 'medium'}
                onClick={handleClickSettings}

              >
                <Tooltip title={t("common.buttons.settings")} placement="bottom">
                  <SettingsIcon color={'inherit'} />
                </Tooltip>
              </IconButton>
            </>)}
          </Box>
        </Box>
      </Box>

      {mobile && (
        <Box className={styles.block}>
          <PeriodDatesField
            periods={periods}
            onApply={handleApplyPeriods}
          />
        </Box>
      )}

      <MuiDrawerWrapper
        title={t("common.components.filters.title")}
        open={openDrawer}
        toggleDrawerState={setOpenDrawer}
      >
        <Filter
          initialFilter={initialFilter}
          setUpdateFilter={setUpdateFilter}
          availableFields={availableFields}
          setToggleDrawer={setOpenDrawer}
        />
      </MuiDrawerWrapper>
    </>
  );
};

export default WidgetsDrawerFilter;
