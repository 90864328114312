//core
import React, {useState} from "react";
import arePropsEqual from "react-fast-compare";
import { v4 } from 'uuid';

//components
import TableRowItem from '../TableRowItem';

//types
import {itemColumnsData, itemListData} from "../../index";

interface ITableRows {
  list: itemListData[];
  columns: itemColumnsData[];
  totalRow?: itemColumnsData,
  ActionsComponent?: any;
  freezeColumns?: string[];
  tooltipRow?: boolean;
  dataTooltipRow?: string[];
  tooltipExceptions: string[];
  translationColumnsPrefix: string;
  translationTooltipsPrefix?: string;
  disabled: boolean;
  detailModalTitle?: string;
  showDetailOnMobile?: boolean;
  actionsCount: number;
  hideTotal?: boolean;
  columnCurrency?: any;
}

const TableRows = (props: ITableRows) => {
  const {
    list,
    columns,
    totalRow = false,
    ActionsComponent,
    freezeColumns,
    tooltipRow = false,
    dataTooltipRow,
    tooltipExceptions,
    translationColumnsPrefix,
    disabled = false,
    detailModalTitle,
    showDetailOnMobile,
    actionsCount = 1,
    translationTooltipsPrefix = '',
    hideTotal = false,
    columnCurrency = null,
  } = props;

  return (
    <>
      {
        list.map((row) => {
          return <TableRowItem
            translationColumnsPrefix={translationColumnsPrefix}
            translationTooltipsPrefix={translationTooltipsPrefix}
            tooltipRow={tooltipRow}
            dataTooltipRow={dataTooltipRow}
            tooltipExceptions={tooltipExceptions}
            row={row}
            columns={columns}
            ActionsComponent={ActionsComponent}
            freezeColumns={freezeColumns}
            key={v4()}
            disabled={disabled}
            detailModalTitle={detailModalTitle}
            showDetailOnMobile={showDetailOnMobile}
            actionsCount={actionsCount}
            columnCurrency={columnCurrency}
          />
        })
      }
      {
        !hideTotal && !!list.length && !!totalRow && (
          <>
            <TableRowItem
              translationColumnsPrefix={translationColumnsPrefix}
              total={true}
              row={totalRow}
              columns={columns}
              //ActionsComponent={ActionsComponent}
              freezeColumns={freezeColumns}
              tooltipExceptions={tooltipExceptions}
              key={v4()}
              disabled={disabled}
            />
          </>
        )
      }
    </>
  );
};

export default React.memo(TableRows, arePropsEqual);
