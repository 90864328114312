import {useDispatch} from "react-redux";

import {
  getListData,
  clearListState,
  reset,
  getListDepartmetsData,
  getListRolesData,
  addAdminData,
  removeAdminData,
  inviteAdminData,
  blockAdminData,
  editAdminData,
  clearAddAdminState,
  clearEditAdminState,
  getFilterRolesData,
  clearFilterRolesState,
} from "./index";

export const useEmployees = () => {
  const dispatch = useDispatch();

  const getList = (data: any) => dispatch(getListData(data));
  const getListRoles = (data: any) => dispatch(getListRolesData(data));
  const getListDepartments = (data: any) => dispatch(getListDepartmetsData(data));
  const addAdmin = (data: any) => dispatch(addAdminData(data));
  const removeAdmin = (data: any) => dispatch(removeAdminData(data));
  const inviteAdmin = (data: any) => dispatch(inviteAdminData(data));
  const blockAdmin = (data: any) => dispatch(blockAdminData(data));
  const editAdmin = (data: any) => dispatch(editAdminData(data));

  const getFilterRoles = (data: any) => dispatch(getFilterRolesData(data));
  const clearFilterRolesData = () => dispatch(clearFilterRolesState());

  const clearListData = () => dispatch(clearListState());
  const clearAddAdminData = () => dispatch(clearAddAdminState());
  const clearEditAdminData = () => dispatch(clearEditAdminState());
  const resetState = () => dispatch(reset());

  return {
    getList,
    clearListData,
    resetState,
    getListRoles,
    getListDepartments,
    addAdmin,
    removeAdmin,
    inviteAdmin,
    blockAdmin,
    editAdmin,
    clearAddAdminData,
    clearEditAdminData,
    getFilterRoles,
    clearFilterRolesData,
  }
}
