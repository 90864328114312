import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import {Theme} from "@mui/material/styles/createTheme";
import {styled} from "@mui/system";

export const useStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%'
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 16,
    marginBottom: 20,
  },
  headerFirst: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 24,
  },
  headerSecond: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 16,
  },

  box: {
    padding: '5px 0 19px',
    '&.noMargin': {
      '& > div': {
        marginBottom: 0,
      }
    }
  },

  actions: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: '24px 36px',
    position: 'fixed',
    right: 0,
    bottom: 0,
    width: 456,
    borderTop: `1px solid ${alpha(theme.palette.secondary.main, 0.12)}`,
    '@media (max-width: 767px)': {
      width: '100%',
      padding: '16px 16px',
    }
  },
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 16,
  },

  button: {
    width: '100%',
  },

  customScroll: {
    margin: '0 0 0 0',
    width: 'auto!important',
    height: 'calc(100% - 52px)!important',
    '& .view': {
      // display: 'flex',
      // flexWrap: 'wrap',
      margin: '0 -12px 0 0',
      paddingRight: '12px',
    },
    '& .track-vertical': {
      top: 5,
      bottom: 0,
      right: 0,
      borderRadius: '3px',
      width: '4px!important',
      backgroundColor: alpha(theme.palette.action.selected, theme.palette.action.selectedOpacity),
      '& > div': {
        position: 'relative',
        display: 'block',
        width: '100%',
        cursor: 'pointer',
        borderRadius: 'inherit',
        backgroundColor: `${alpha(theme.palette.secondary.light, 0.3)}!important`,
      }
    },
    '& .track-horizontal': {
      bottom: 2,
      left: 8,
      right: 8,
      height: '4px!important',
      borderRadius: '3px',
      backgroundColor: alpha(theme.palette.action.selected, theme.palette.action.selectedOpacity),
      '& > div': {
        position: 'relative',
        display: 'block',
        cursor: 'pointer',
        borderRadius: '3px!important',
        backgroundColor: `${alpha(theme.palette.secondary.light, 0.3)}!important`,
      }
    },
  },

  fieldWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  field: {
    flex: '1 1 auto'
  },

  checkboxGroup: {
    paddingLeft: 11,
  }
}));

export const useListItemStyles = makeStyles<Theme>((theme) => ({
  drag: {

  },
  dragBtn: {
    pointerEvents: "none",
  },
  btn: {
    padding: 4,
  },
  group: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: "1 1 auto",
    gap: 12,
  }
}));


export const ColumnsList = styled("ul")(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flexDirection: 'column',
}));

export const ListItem = styled("li")(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 8,
  width: '100%',
}));
