import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  block: {
    width: '100%',
    '@media (max-width: 767px)': {}
  },
  footer: {
    paddingTop: 24,
  },
}));
