//core
import { createSelector } from "@reduxjs/toolkit";

//store
import { RootState } from "../../index";

//settings
const dashboardV2ChartsSettingsDataSelector = (state: RootState) => state.admin.dashboardV2.statisticChartsSettings.data;
const dashboardV2ChartsSettingsErrorSelector = (state: RootState) => state.admin.dashboardV2.statisticChartsSettings.error;
const dashboardV2ChartsSettingsIsFetchingSelector = (state: RootState) => state.admin.dashboardV2.statisticChartsSettings.isFetching;

export const selectDashboardChartsSettingsData = createSelector(dashboardV2ChartsSettingsDataSelector, (data) => data);
export const selectDashboardChartsSettingsError = createSelector(dashboardV2ChartsSettingsErrorSelector, (error) => error);
export const selectDashboardChartsSettingsIsFetching = createSelector(dashboardV2ChartsSettingsIsFetchingSelector, (isFetching) => isFetching);


//top widgets
const dashboardV2TopWidgetsDataSelector = (state: RootState) => state.admin.dashboardV2.topWidgets.data;
const dashboardV2TopWidgetsErrorSelector = (state: RootState) => state.admin.dashboardV2.topWidgets.error;
const dashboardV2TopWidgetsIsFetchingSelector = (state: RootState) => state.admin.dashboardV2.topWidgets.isFetching;

export const selectDashboardTopWidgetsData = createSelector(dashboardV2TopWidgetsDataSelector, (data) => data);
export const selectDashboardTopWidgetsError = createSelector(dashboardV2TopWidgetsErrorSelector, (error) => error);
export const selectDashboardTopWidgetsIsFetching = createSelector(dashboardV2TopWidgetsIsFetchingSelector, (isFetching) => isFetching);

//grid widgets
const dashboardV2GridWidgetsDataSelector = (state: RootState) => state.admin.dashboardV2.gridWidgets.data;
const dashboardV2GridWidgetsErrorSelector = (state: RootState) => state.admin.dashboardV2.gridWidgets.error;
const dashboardV2GridWidgetsIsFetchingSelector = (state: RootState) => state.admin.dashboardV2.gridWidgets.isFetching;

export const selectDashboardGridWidgetsData = createSelector(dashboardV2GridWidgetsDataSelector, (data) => data);
export const selectDashboardGridWidgetsError = createSelector(dashboardV2GridWidgetsErrorSelector, (error) => error);
export const selectDashboardGridWidgetsIsFetching = createSelector(dashboardV2GridWidgetsIsFetchingSelector, (isFetching) => isFetching);


//statistic charts data
const dashboardV2StatisticChartsDataSelector = (state: RootState) => state.admin.dashboardV2.statisticCharts.data;
const dashboardV2StatisticChartsErrorSelector = (state: RootState) => state.admin.dashboardV2.statisticCharts.error;
const dashboardV2StatisticChartsIsFetchingSelector = (state: RootState) => state.admin.dashboardV2.statisticCharts.isFetching;

export const selectDashboardStatisticChartsData = createSelector(dashboardV2StatisticChartsDataSelector, (data) => data);
export const selectDashboardStatisticChartsError = createSelector(dashboardV2StatisticChartsErrorSelector, (error) => error);
export const selectDashboardStatisticChartsIsFetching = createSelector(dashboardV2StatisticChartsIsFetchingSelector, (isFetching) => isFetching);

//filters
const dashboardV2FiltersDataSelector = (state: RootState) => state.admin.dashboardV2.filters.data;
const dashboardV2FiltersErrorSelector = (state: RootState) => state.admin.dashboardV2.filters.error;
const dashboardV2FiltersIsFetchingSelector = (state: RootState) => state.admin.dashboardV2.filters.isFetching;

export const selectDashboardFiltersData = createSelector(dashboardV2FiltersDataSelector, (data) => data);
export const selectDashboardFiltersError = createSelector(dashboardV2FiltersErrorSelector, (error) => error);
export const selectDashboardFiltersIsFetching = createSelector(dashboardV2FiltersIsFetchingSelector, (isFetching) => isFetching);
