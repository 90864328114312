import { api } from "../../api";
import {handleResponse, RequestOptions} from "../types";
import {getCookie} from "../../helpers/utils";
import {cookieToken} from "../../constants";

const getList = (data:any) => {
  const authenticity_token = getCookie(cookieToken);
  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };
  return fetch(`${api.admin.players_list.list}`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}


const getProfile = (data: any) => {
  const endpoint = api.admin.players_list.players_profile.replace(
    '{playerId}',
    data.playerId.toString()
  );

  return fetch(
    `${endpoint}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const updatePlayer = (data: any) => {
  const endpoint = api.admin.players_list.players_profile
    .replace('{playerId}', data.playerId.toString());

  const payload = {
    ...data.data,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

export const playersListService = {
  getList,
  updatePlayer,
  getProfile,
};
