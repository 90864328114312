import React from "react";
import { useTranslation } from "react-i18next";

//components
import DateTimeRange from "../../../../../../../../../../../common/TableData/components/DateTimeRange";

interface IPaymentAddedProps {
  data: any;
}

const PaymentAdded: React.FC<IPaymentAddedProps> = ({
  data,
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      {t("admin.reports.detailed_fin_report.logs.actions.payment.created")}{' '}
      {t("admin.reports.detailed_fin_report.logs.actions.payment.date")}: {<DateTimeRange value={data.paymentDate} fullDate />},{' '}
      {t("admin.reports.detailed_fin_report.logs.actions.payment.amount")}: {data.amount},{' '}
      {!!data.walletPaymentMethodName && <>
        {t("admin.reports.detailed_fin_report.logs.actions.payment.wallet")}:{' '}
        {t(`common.components.payment.payment_method_options.${data.walletPaymentMethodName}`)}
        {' - '}
        {!!data.walletPaymentMethodDefaultField ? data.walletPaymentMethodValue[data.walletPaymentMethodDefaultField] : 'null'}
        ,{' '}
      </>}
      {!!data.walletCurrency && <>
        {t("admin.reports.detailed_fin_report.logs.actions.payment.walletCurrency")}:{' '}
        {data.walletCurrency}
        ,{' '}
      </>}
      {!!data.walletAmount && <>
        {t("admin.reports.detailed_fin_report.logs.actions.payment.walletAmount")}:{' '}
        {data.walletAmount}
        ,{' '}
      </>}
      {!!data.transactionId && <>
        {t("admin.reports.detailed_fin_report.logs.actions.payment.transactionId")}:{' '}
        {data.transactionId}
        ,{' '}
      </>}
      {t("admin.reports.detailed_fin_report.logs.actions.payment.comment")}: {!!data.comment ? data.comment : '-'}
    </>
  );
};

export default PaymentAdded;
