//core
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import { Box, Skeleton } from '@mui/material';

//styles
import { useStyles } from "./styles";

//hooks
import { useUser } from "../../../../../store/common/user/useUser";
import { useDashboardV2 } from "../../../../../store/admin/dashboardV2/useDashboardV2";
import useDidMountEffect from "../../../../../customHooks/useDidMountEffect";

//selectors
import { selectDashboardTopWidgetsData } from "../../../../../store/admin/dashboardV2/selectors";

//utils
import { getDataIds } from "../../../../admin/Reports/utils";

//components
import Counter from "../../../Widgets/Counter";
import { IPeriodsType } from "../../index";
import { IDashboardFilterType } from "../../../DashboardV2/types";

export interface ITopWidgetItem {
  name: string;
  value: {
    main: number;
    comparison?: number;
  };
  order?: number;
  /*[key: string]: {
    main: number;
    compare?: number;
  };*/
};

interface ISettingsItem {
  [key: string]: any;
}

interface ITopWidgetsProps {
  periods: IPeriodsType;
  filter: IDashboardFilterType;
  settings: ISettingsItem[] | null;
}

const TopWidgets: React.FC<ITopWidgetsProps> = ({
  periods,
  filter,
  settings,
}): JSX.Element => {
  const styles = useStyles();

  const { user } = useUser();
  const { getTopWidgets, clearTopWidgetsData } = useDashboardV2();

  const [list, setList] = useState<ITopWidgetItem[] | null>(null);

  const topWidgetData: ITopWidgetItem[] = useSelector(selectDashboardTopWidgetsData);

  useEffect(() => {
    if (!topWidgetData) {
      const payload = getPayloadData();
      getTopWidgets(payload);
    }
  }, [topWidgetData]);

  useDidMountEffect(() => {
    const payload = getPayloadData();
    getTopWidgets(payload);
  }, [periods, filter]);

  useEffect(() => {
    return () => {
      clearTopWidgetsData();
    };
  }, []);

  useEffect(() => {
    if (!!topWidgetData) {
      if (!!settings) {
        const settingsArr = settings.map((item) => item.id);
        const settingsObj = settings.reduce((acc, item) => {
          acc = {
            ...acc,
            [item.id]: item,
          }
          return acc;
        }, {});

        const visibleWidgets = topWidgetData.reduce((acc, item, i) => {
          if (settingsObj.hasOwnProperty(item.name)) {
            if (settingsObj[item.name].checked) {
              //@ts-ignore
              acc.push({
                ...item,
                //@ts-ignore
                order: settingsArr.indexOf(item.name),
              });
            }
          }
         /* else {
            acc.push({
              ...item,
              //@ts-ignore
              order: settingsArr.length + i,
            })
          }*/
          return acc;
        }, []).sort((a: any, b: any) => a.order > b.order ? 1 : -1);

        setList(visibleWidgets);
      } else {
        //default
        setList(topWidgetData);
      }
    }
  }, [settings, topWidgetData]);

  const getPayloadData = useCallback(() => {
    return {
      namespace: user.user.namespace,
      payload: {
        periods: {
          main: {
            start: format(new Date(periods.main.start), 'yyyy-MM-dd'),
            end: format(new Date(periods.main.end), 'yyyy-MM-dd'),
          },
          ...(!!periods.comparative.start || !!periods.comparative.end ? {
            comparison: {
              start: format(new Date(periods.comparative.start), 'yyyy-MM-dd'),
              end: format(new Date(periods.comparative.end), 'yyyy-MM-dd'),
            }
          } : {}),
        },
        filters: {
          ...(!!filter.geo.length ? { geo: getDataIds(filter.geo) } : {}),
          ...(!!filter.affiliateIds.length ? { affiliateIds: getDataIds(filter.affiliateIds) } : {}),
          ...(!!filter.creativeIds.length ? { creativeIds: getDataIds(filter.creativeIds) } : {}),
          ...(!!filter.trackerIds.length ? { trackerIds: getDataIds(filter.trackerIds) } : {}),
          ...(!!filter.brands.length ? { brands: getDataIds(filter.brands) } : {}),
        }
      },
    }
  }, [periods, filter]);

  return (
    <Box className={styles.block}>
      {!!list
        ? !!list.length
          ? list.map((item, i: number) => <Counter key={`w-${i}`} data={item} />)
          : null
        : (
          <Box sx={{ padding: '0 10px 20px', width: '100%' }}>
            <Skeleton variant="rectangular" height={'104px'} width={'100%'} />
          </Box>
        )
      }
    </Box>
  );
};

export default TopWidgets;
