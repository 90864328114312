import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  block: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16,
    '@media (max-width: 767px)': {
      marginBottom: 16,
      justifyContent: 'flex-end',
    },
  },

  first: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
    '@media (max-width: 767px)': {
      gap: 8,
    },
  },

  second: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
    '@media (max-width: 767px)': {
      gap: 8,
    },
  },

  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
    '@media (max-width: 767px)': {
      gap: 8,
    },
    '@media (max-width: 376px)': {
      flex: '1 1 auto',
      '& > button': {
        width: '100%',
      }
    }
  },

  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
    '@media (max-width: 767px)': {
      gap: 8,
    },
  },

  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-between",
    marginBottom: 16,
  },

  buttonWrapperDown: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "flex-end",
    marginBottom: 16,
  },

  iconButton: {
    position: 'relative',
  },
  badge: {
    position: 'initial',
    '& > span': {
      height: 16,
      minWidth: 16,
      lineHeight: '16px',
      fontSize: '10px',
      padding: '0 4px',
      transform: 'scale(1) translate(0)',
      '@media (max-width: 767px)': {
        height: 15,
        minWidth: 15,
        lineHeight: '15px',
        fontSize: '9px',
        padding: '0 2px',
      }
    }
  },
});
