//core
import React, { useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { Scrollbars } from "react-custom-scrollbars";
import {
  Autocomplete,
  Box,
  Chip,
  TextField,
  Checkbox,
  AutocompleteCloseReason,
} from "@mui/material";

//icons
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";

//styles
import { useStyles } from "./styles";

//components
import CircularProgress from "@mui/material/CircularProgress";
import CustomPaper from "./CustomPaper";
import ListboxComponent from "./ListboxComponent";

import { AutocompleteSelectContext } from "./context";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MAX_HEIGHT = ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP;

interface IMuiAutocompleteSelectProps  {
  keyValue: string;
  keyId: string;
  value: any;
  id: string;
  name: string;
  label: string;
  limitTags?: number;
  disabled?: boolean;
  autoFocus?: boolean;
  multiple?: boolean;
  prefix?: string;
  getOptions?: (search: string | null) => any;
  staticOptions?: any;
  showSelectAll?: boolean; // only for staticOptions & multiple
  showSingleSelectAll?: boolean; // only for staticOptions & multiple
  disableCloseOnSelect?: boolean;
  onChange: (data: any) => void;
  maxHeightFieldScroll?: number; // min value 32, max value 96
  showLabelCounter?: boolean;
  filterOptionsById?: boolean;
}

const MuiAutocompleteSelect = (props: IMuiAutocompleteSelectProps) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const {
    keyValue,
    keyId,
    id,
    value,
    name,
    label,
    disabled = false,
    autoFocus = false,
    multiple = false,
    limitTags = -1,
    prefix = '',
    staticOptions = null,
    showSelectAll = false,
    showSingleSelectAll = false,
    disableCloseOnSelect = false,
    getOptions,
    onChange,
    maxHeightFieldScroll = 96,
    showLabelCounter = false,
    filterOptionsById = false,
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<readonly any[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [singleSelectAll, setSingleSelectAll] = useState(false);
  const loading = open && options.length === 0;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    if (!!staticOptions) {
      setOptions([
        /*showSingleSelectAll && {
          [keyId]: 'all',
          label: 'all',
          originalValue: 'all',
        },*/
        ...staticOptions,
      ]);
    }

    if (typeof getOptions === 'function') {
      (async () => {
        const data = await getOptions(null);

        if (active) {
          setOptions(data.payload.list);
        }
      })();
    }

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (showSelectAll) {
      if (!!value && value.length !== options.length) {
        setSelectAll(false);
      }
    }

    if (showSingleSelectAll) {
      if (!!value && value.length) {
        if (!!value.filter((item: any) => item.id === 'all').length) {
          setSingleSelectAll(true);
        }
      }
    }
  }, [value]);

  useEffect(() => {
    if (!!staticOptions && showSelectAll) {
      if (staticOptions.length === value.length) {
        setSelectAll(true);
      }
    }
  }, [staticOptions]);

  const handleCheckAllStaticOptions = () => {
    if (!!staticOptions) {
      if (!selectAll) {
        onChange((prevState: any) => {
          return {
            ...prevState,
            [`${name}`]: [ ...staticOptions ],
          }
        });
        setSelectAll(true);
      } else {
        onChange((prevState: any) => {
          return {
            ...prevState,
            [`${name}`]: [],
          }
        });
        setSelectAll(false);
      }
    }
  };

  const handleSingleSelectAll = () => {
    if (!singleSelectAll) {
      onChange((prevState: any) => {
        return {
          ...prevState,
          [`${name}`]: [{
            [keyId]: 'all',
            label: 'all',
            originalValue: 'all',
          }],
        }
      });
      setSingleSelectAll(true);
    } else {
      onChange((prevState: any) => {
        return {
          ...prevState,
          [`${name}`]: [],
        }
      });
      setSingleSelectAll(false);
    }
  }

  const handleChangeInput = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const search = e.target.value;

    if (typeof getOptions === 'function') {
      (async () => {
        const data = await getOptions(search.length >= 2 ? search : null);
        setOptions(data.payload.list);
      })();
    }
  };

  return (
    <AutocompleteSelectContext.Provider value={{
      showSelectAll: showSelectAll,
      selectAllState: selectAll,
      handleCheckAllStaticOptions: handleCheckAllStaticOptions,
      showSingleSelectAll: showSingleSelectAll,
      singleSelectAllState: singleSelectAll,
      handleSingleSelectAll: handleSingleSelectAll,
    }}>
      <Box>
        <Autocomplete
          disableCloseOnSelect={disableCloseOnSelect}
          id={id}
          size="small"
          multiple={multiple}
          value={value}
          open={open}
          limitTags={limitTags}
          disabled={disabled}
          noOptionsText={t("common.components.autocomplete.no_matches")}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={(e: any, reason: AutocompleteCloseReason) => {
            if (reason === "escape") {
              setOpen(false);
            }
            setOpen(false);
          }}
          loading={loading}
          options={options.map((option) => option)}
          isOptionEqualToValue={(option, value) => option[keyId] === value[keyId]}
          getOptionLabel={(option) => {
            if (!!prefix) {
              return !!option[keyValue] ? t(`${prefix}${option[keyValue]}`) : '';
            } else {
              return !!option[keyValue] ? `${option[keyValue]}` : '';
            }
          }}
          getOptionDisabled={(option) => singleSelectAll}
          filterOptions={(options, state) => {
            const inputValue = state.inputValue.toLowerCase().trim();

            const displayOptions = options.filter((option) => {
              const label = !!prefix
                ? !!option[keyValue] ? t(`${prefix}${option[keyValue]}`) : ''
                : !!option[keyValue] ? `${option[keyValue]}` : '';

              if (filterOptionsById) {
                return label.toLowerCase().trim()
                  .includes(inputValue) ||
                  option[keyId].toLowerCase().trim()
                  .includes(inputValue)
              } else {
                return label
                  .toLowerCase()
                  .trim()
                  .includes(inputValue);
              }
            });

            return displayOptions;
          }}
          renderOption={(props, option, { selected }) => {
            if (multiple) {
              return (
                <Box
                  sx={{ minHeight: 'auto!important' }}
                  component="li"
                  {...props}
                  key={`${name}-li-${option[keyId]}`}
                >
                  <Checkbox
                    sx={{ padding: '0', width: 24, height: 24, marginRight: 1 }}
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    disabled={singleSelectAll}
                  />
                  <Box component='span' sx={{
                    display: 'block',
                    width: '100%',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}>
                    {prefix ? t(`${prefix}${option[keyValue]}`) : option[keyValue]}
                  </Box>
                </Box>
              )
            } else {
              return (
                <Box
                  sx={{ minHeight: 'auto!important' }}
                  component="li"
                  {...props}
                  key={`${name}-li-${option[keyId]}`}
                >
                  <Box component='span' sx={{
                    display: 'block',
                    width: '100%',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}>
                    {prefix ? t(`${prefix}${option[keyValue]}`) : option[keyValue]}
                  </Box>
                </Box>
              )
            }
          }}
          renderTags={(tagValue, getTagProps) => {
            return (
              tagValue.map((option, index) => (
                <Chip
                  size="small"
                  variant="filled"
                  label={prefix ? t(`${prefix}${option[keyValue]}`) : option[keyValue]}
                  {...getTagProps({ index })}
                  sx={{ maxWidth: '200px!important', margin: '4px 2px!important' }}
                />
              ))
            )
          }}
          onChange={(e, val, reason) => {
            if (reason === 'clear') {
              setSelectAll(false);
              setSingleSelectAll(false);
            }

            if (Array.isArray(val) && !val.length) {
              setSingleSelectAll(false);
            }

            onChange((prevState: any) => {
              return {
                ...prevState,
                [`${name}`]: val,
              }
            });
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                name={name}
                label={`${label} ${showLabelCounter && multiple && value.length > 0 ? `(${value.length})` : ''}`}
                placeholder={t("common.components.autocomplete.placeholder")}
                autoFocus={autoFocus}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: multiple && !!value.length ? (
                    <Box
                      sx={{
                        paddingRight: multiple && !!value.length ? '30px' : '0px',
                        boxSizing: 'initial',
                      }}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      <Scrollbars
                        className={styles.customScroll}
                        autoHide={true}
                        hideTracksWhenNotNeeded={true}
                        autoHeight={true}
                        autoHeightMin={32}
                        autoHeightMax={maxHeightFieldScroll}
                        renderView={props => <div {...props} className="view-select"/>}
                        renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
                        renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                      >
                        {params.InputProps.startAdornment}
                      </Scrollbars>
                    </Box>
                  ) : undefined,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                  sx: {
                    paddingTop: '4px!important',
                    paddingBottom: '4px!important',
                    minHeight: '40px',
                  },
                }}
                onChange={(e) => handleChangeInput(e)}
              />
            )
          }}
          disableListWrap
          PaperComponent={CustomPaper}
          ListboxComponent={ListboxComponent}
        />
      </Box>
    </AutocompleteSelectContext.Provider>
  );
};

export default MuiAutocompleteSelect;
