export const favoriteFilterKeys = {
  affiliateIds: 'favWRAffiliates',
  trackerIds: 'favWRTrackers',
  webhookIds: 'favWRWebhooks',
  webhookTypes: 'favWRWebhookTypes',
  statuses: 'favWRStatuses',
}

export const reportStorageProps = {
  type: 'webhooks-report',
  target: 'filters',
  tableSettingsTarget: 'table-settings',
  exceptions: ['period', 'sent_from', 'sent_to'],
  exceptionsIds: ['affiliateIds', 'trackerIds', 'webhookIds', 'webhookTypes', 'statuses'],

  // affiliates: [],
  //   trackers: [],
  //   webhooks: [],
  //   webhook_types: [],
  //   statuses: [],
}
