//core
import { createSelector } from "@reduxjs/toolkit";

//store
import {RootState} from "../../index";

// state
const employeesSelector = (state: RootState) => state.admin.employees;

const employeesListSelector = (state: RootState) => state.admin.employees.list;
const employeesListDataSelector = (state: RootState) => state.admin.employees.list.data;
const employeesListErrorSelector = (state: RootState) => state.admin.employees.list.error;
const employeesListFetchingSelector = (state: RootState) => state.admin.employees.list.isFetching;

const employeesListRolesDataSelector = (state: RootState) => state.admin.employees.list_roles.data;
const employeesListRolesErrorSelector = (state: RootState) => state.admin.employees.list_roles.error;
const employeesListRolesFetchingSelector = (state: RootState) => state.admin.employees.list_roles.isFetching;


const employeesListDepartmentsDataSelector = (state: RootState) => state.admin.employees.list_departments.data;
const employeesListDepartmentsErrorSelector = (state: RootState) => state.admin.employees.list_departments.error;
const employeesListDepartmentsFetchingSelector = (state: RootState) => state.admin.employees.list_departments.isFetching;

const employeesAddDataSelector = (state: RootState) => state.admin.employees.add.data;
const employeesAddErrorSelector = (state: RootState) => state.admin.employees.add.error;

const employeesRemoveDataSelector = (state: RootState) => state.admin.employees.remove.data;

const employeesInviteDataSelector = (state: RootState) => state.admin.employees.invite.data;

const employeesBlockDataSelector = (state: RootState) => state.admin.employees.block.data;

const employeesEditDataSelector = (state: RootState) => state.admin.employees.edit.data;
const employeesEditErrorSelector = (state: RootState) => state.admin.employees.edit.error;

//selectors
export const selectEmployees = createSelector(employeesSelector, (report) => report);

export const selectList = createSelector(employeesListSelector, (list) => list);

export const selectListData = createSelector(employeesListDataSelector, (data) => {
  if (!!data) {
    let columns = data.columns_for_display_data.map((item: any) => {

      if (item.name === "last_sign_in_ip") {
        return { ...item, type: 'string' };
      }
      if (item.name === "name") {
        return { ...item, type: 'mediumString' };
      }
      if (item.name === "email") {
        return { ...item, type: 'mail' };
      }
      if (item.name === "created_at") {
        return { ...item, type: 'fulldatetime' };
      }
      if (item.name === "last_sign_in_at") {
        return { ...item, type: 'fulldatetime' };
      }

      return item;
    });

    return { ...data, columns_for_display_data: columns };
  } else {
    return data;
  }
});
export const selectListError = createSelector(employeesListErrorSelector, (error) => error);
export const selectListIsFetching = createSelector(employeesListFetchingSelector, (isFetching) => isFetching);
export const selectListDataColumnsSort = createSelector(employeesListDataSelector, (data) => data?.columns_for_display_data.filter((el) => el.sort === true));


export const selectListRolesData = createSelector(employeesListRolesDataSelector, (data) => data);
export const selectListRolesError = createSelector(employeesListRolesErrorSelector, (error) => error);
export const selectListRolesIsFetching = createSelector(employeesListRolesFetchingSelector, (isFetching) => isFetching);

export const selectListDepartmentsData = createSelector(employeesListDepartmentsDataSelector, (data) => data);
export const selectListDepartmentsError = createSelector(employeesListDepartmentsErrorSelector, (error) => error);
export const selectListDepartmentsIsFetching = createSelector(employeesListDepartmentsFetchingSelector, (isFetching) => isFetching);

export const selectAddData = createSelector(employeesAddDataSelector, (data) => data);
export const selectAddError = createSelector(employeesAddErrorSelector, (error) => error);

export const selectRemoveData = createSelector(employeesRemoveDataSelector, (data) => data);

export const selectInviteData = createSelector(employeesInviteDataSelector, (data) => data);

export const selectBlockData = createSelector(employeesBlockDataSelector, (data) => data);

export const selectEditData = createSelector(employeesEditDataSelector, (data) => data);
export const selectEditError = createSelector(employeesEditErrorSelector, (error) => error);
