import { createContext } from "react";

export interface IAutocompleteSelectContext {
  showSelectAll: boolean;
  selectAllState: boolean;
  handleCheckAllStaticOptions: () => void;

  showSingleSelectAll: boolean;
  singleSelectAllState: boolean;
  handleSingleSelectAll: () => void;
}

export const initialValue: IAutocompleteSelectContext = {
  showSelectAll: false,
  selectAllState: false,
  handleCheckAllStaticOptions: () => {},


  showSingleSelectAll: false,
  singleSelectAllState: false,
  handleSingleSelectAll: () => {},
}

export const AutocompleteSelectContext = createContext<IAutocompleteSelectContext>(initialValue);
