//core
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Chip } from "@mui/material";

//styles
import { useStyles } from "./styles";


import { ISelectedData } from "../SelectedFiltersWrapper";

interface ISelectedFieldProps {
  data: ISelectedData;
  name: string,
  setActiveField: (field: string) => void;
  onDelete?: (id: string | number, fieldName: string) => void;
}

const SelectedField: React.FC<ISelectedFieldProps> = ({
  data,
  name,
  setActiveField,
  onDelete,
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <Box className={`${styles.wrapper}`}>
      <Box className={`${styles.block} `}>
        <Box className={styles.header}>
          <Typography className={styles.title} variant="subtitle2">
            {t(`admin.employees.form.${data.name}.label`)}
          </Typography>
        </Box>
        <Box className={styles.list}>
          {data.data.map((item) => (
            <Chip
              key={item.id}
              className={styles.chip}
              label={!!data.prefix ? `${t(`${data.prefix}${item.id}`)}` : item.hasOwnProperty('label') ? `${item.label}` : item.hasOwnProperty('value') ? `${item.value}` : `${item.name}`}
              variant="filled"
              size="small"
              onClick={() => setActiveField(data.name)}
              { ...(typeof onDelete === 'function' ? { onDelete: () => onDelete(item.id, name) } : {}) }
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default SelectedField;
