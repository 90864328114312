import {alpha, darken, rgbToHex} from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { tablePaginationClasses } from "@mui/material";
import {Theme} from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    width: '100%',
    //overflowX: 'auto',
    borderRadius: '0',
    overflow: 'hidden',
    position: 'relative',
    '@media (max-width: 767px)': {
      overflowX: 'hidden',
    }
  },
  tableContainer: {
    minWidth: 600,
    borderRadius: '0',
    overflow: 'visible',
    //marginBottom: 14,
    '@media (max-width: 767px)': {
      minWidth: '100%',
    },
  },
  stickyTableContainer: {
    //maxHeight: 674
  },
  stickyTableContainerMobile: {
    //minHeight: 300,
    //maxHeight: 'calc(100vh - 140px)'
  },
  table: {
    borderRadius: '0',
    borderCollapse: 'separate',
  },
  tableHead: {
    //backgroundColor: alpha(theme.palette.secondary.dark, 0.09),
  },
  tableCell: {
    whiteSpace: 'nowrap',
    padding: '8px 16px',
  },
  tablePagination: {
    [`& .${tablePaginationClasses.select}`]: {
      '@media (max-width: 767px)': {
        marginLeft: 0,
        marginRight: 8,
        paddingRight: '16px!important',
      }
    },
    [`& .${tablePaginationClasses.selectIcon}`]: {
      '@media (max-width: 767px)': {
        right: 4,
      },
    },
    [`& .${tablePaginationClasses.selectLabel}`]: {
      '@media (max-width: 767px)': {
        display: "none"
      },
      [`& + div`]: {
        '@media (max-width: 767px)': {
          marginLeft: 0,
          marginRight: 8,
        }
      }
    },
    [`& .${tablePaginationClasses.spacer}`]: {
      display: "none"
    },
    [`& .${tablePaginationClasses.toolbar}`]: {
      justifyContent: "center",
      padding: '10px 0',

    },
    [`& .${tablePaginationClasses.actions}`]: {
      '@media (max-width: 767px)': {
        marginLeft: 8,
      },
      [`& button`]: {
        '@media (max-width: 767px)': {
          width: 32,
          height: 32,
          padding: 2,
        }
      }
    },
  },
  sticky: {
    position: 'sticky',
    right: 0,
    // backgroundColor: '#fafafa',
    // boxShadow: '0px 2px 3px #E0E0E0',
    // borderLeft: '1px solid #E0E0E0',
    backgroundColor: rgbToHex(darken(theme.palette.background.paper, 0.017)),
    boxShadow: `0px 2px 3px ${rgbToHex(darken(theme.palette.background.paper, 0.12))}`,
    borderLeft: `1px solid ${rgbToHex(darken(theme.palette.background.paper, 0.12))}`,
  },

  page: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.875rem',
    color: theme.palette.text.primary,
    '@media (max-width: 767px)': {
      fontSize: '0.75rem',
      flexDirection: "column",
    },
  },
  page_row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.875rem',
    color: theme.palette.text.primary,
    '@media (max-width: 767px)': {
      fontSize: '0.75rem',
      order: 1
    },
  },
  field: {
    margin: '0 6px',
    width: '100%',
    minWidth: 44,
    display: "block",
    '& + span': {
      whiteSpace: 'nowrap',
    }
  },
  input: {
    width: '100%',
    '& input': {
      padding: '0 4px',
      height: 32,
      fontSize: '0.875rem',
      textAlign: 'center',
      '-moz-appearance': 'textfield',

      '&::-webkit-outer-spin-button': {
        "-webkit-appearance": 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        "-webkit-appearance": 'none',
        margin: 0,
      },
    },
  },
  total_items: {
    marginLeft: 26,
    '@media (max-width: 767px)': {
      order: 0,
      margin: '0 0 8px',
    }
  }
}));

export const useCustomScroll = makeStyles<Theme>((theme) => ({
  customScroll: {
    height: '100%!important',
    '& .view': {
      //overflowX: 'hidden!important',
      //paddingBottom: 16,
    },
    '& .track-vertical': {
      top: 56,
      bottom: 9,
      right: 7,
      width: '4px!important',
      borderRadius: '3px',
      backgroundColor: alpha(theme.palette.action.selected, theme.palette.action.selectedOpacity),
      '& > div': {
        position: 'relative',
        display: 'block',
        width: '100%',
        cursor: 'pointer',
        borderRadius: 'inherit',
        backgroundColor: `${alpha(theme.palette.secondary.light, 0.3)}!important`,
      }
    },
    '& .track-horizontal': {
      bottom: 3,
      left: 16,
      right: 18,
      height: '4px!important',
      borderRadius: '3px',
      backgroundColor: alpha(theme.palette.action.selected, theme.palette.action.selectedOpacity),
      '& > div': {
        position: 'relative',
        display: 'block',
        cursor: 'pointer',
        borderRadius: '3px!important',
        backgroundColor: `${alpha(theme.palette.secondary.light, 0.3)}!important`,
      }
    },
  },
}));
