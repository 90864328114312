import React, {useCallback, useContext, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { Scrollbars } from "react-custom-scrollbars";
import { useLocalStorage } from "usehooks-ts";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import { endOfDay, startOfDay } from "date-fns";
import arePropsEqual from "react-fast-compare";
import {
  Box,
  SelectChangeEvent,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Button from "../../../../../../../UI/Buttons/Button";

//styles
import { useStyles } from "./styles";

//utils
import { getPeriod, PeriodTypes } from "../../../../../utils";

//selectors
import { selectFiltersData } from "../../../../../../../../store/admin/reports/webhooks_report/selectors";

//hooks
import { usePartners } from "../../../../../../../../store/admin/partners/usePartners";
import { useFields } from "../../../../../../../../store/common/fields/useFields";
import { usePermissions } from "../../../../../../../../store/common/configuration/usePermissions";

//context
import { GlobalContext } from "../../../../../../../../context/GlobalContext";

//constants
import { summaryPeriodOptions } from "../../../../../constant";
import { favoriteFilterKeys, reportStorageProps } from "../../../../constants";

//validation
import { validationTextField as validationTextFieldMax } from "../../../../../../../validation/textFieldMax.validation";

//components
import { IFilterData } from "../../../../types";
import MuiAutocompleteSelect from "../../../../../../../UI/Fields/MuiAutocompleteSelect";
import CSelect from "../../../../../../../UI/Fields/CSelect";
import MuiAutocompleteSelectLazy from "../../../../../../../UI/Fields/MuiAutocompleteSelectLazy";
import CDateRangePicker from "../../../../../../../UI/Fields/CDateRangePicker";
import FavoriteCheckboxField from "../../../../../../../UI/Fields/FavoriteCheckboxField";
import SaveFilterForm from "../../../../../../../common/FiltersComponents/SaveFilterForm";
import { initialFilterData } from "../../../DesktopReport";


interface IFilterProps {
  initialFilter: IFilterData;
  activeField: string;
  setUpdateFilter: (data: any) => void;
  setToggleDrawer: (data: boolean) => void;
  setGenerateReport: React.Dispatch<React.SetStateAction<boolean>>;
  editMode?: boolean;
  filterName?: string;
  onSave?: (data: object) => void;
  setSelectedFilterId?: React.Dispatch<React.SetStateAction<string>>;
}


const Filter: React.FC<IFilterProps> = ({
  initialFilter,
  activeField,
  setUpdateFilter,
  setToggleDrawer,
  setGenerateReport,
  editMode = false,
  filterName = '',
  onSave,
  setSelectedFilterId,
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();
  const mobile = useMediaQuery('(max-width:767px)');
  const { hasPermissions } = usePermissions();

  const hasMyFilters = hasPermissions(["api2.admin.webhookscontroller.my_filters"]);

  const context = useContext(GlobalContext);

  const { getAffiliates } = usePartners();
  const { getTrackers, getWebhooks } = useFields();

  const filtersData = useSelector(selectFiltersData);

  const [filter, setFilter] = useState<IFilterData>(editMode ? initialFilter : context.admWebhooksReportFilter);
  const [fieldName, setFieldName] = useState<string>(filterName);

  //state
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodTypes>(initialFilter.period);
  const [startDate, setStartDate] = useState<Date | ''>(new Date(initialFilter.sent_from));
  const [endDate, setEndDate] = useState<Date | ''>(new Date(initialFilter.sent_to));

  //favorite fields state
  const [favWRAffiliates, setFavWRAffiliates] = useLocalStorage<boolean>(favoriteFilterKeys.affiliateIds, false);
  const [favWRTrackers, setFavWRTrackers] = useLocalStorage<boolean>(favoriteFilterKeys.trackerIds, false);
  const [favWRWebhooks, setFavWRWebhooks] = useLocalStorage<boolean>(favoriteFilterKeys.webhookIds, false);
  const [favWRWebhookTypes, setFavWRWebhookTypes] = useLocalStorage<boolean>(favoriteFilterKeys.webhookTypes, false);
  const [favWRStatuses, setFavWRStatuses] = useLocalStorage<boolean>(favoriteFilterKeys.statuses, false);


  useEffect(() => {
    if (!!selectedPeriod) {
      const period = getPeriod(selectedPeriod);

      if (!!period.start && !!period.end) {
        setStartDate(period.start);
        setEndDate(period.end);
        setFilter((prevState: any) => {
          return {
            ...prevState,
            period: selectedPeriod,
            sent_from: format(startOfDay(new Date(period.start)), "yyyy-MM-dd HH:mm:ss"),
            sent_to: format(endOfDay(new Date(period.end)), "yyyy-MM-dd HH:mm:ss"),
          }
        })
      }
    }
  }, [selectedPeriod]);

  useEffect(() => {
    setSelectedPeriod(filter.period);
  }, [filter.period]);

  useEffect(() => {
    context.setAdmWebhooksReportFilter(filter);
  }, [filter]);

  const handleChangeDates = (start: Date, end: Date) => {
    const noEndDate = !end;

    setStartDate(start);
    setEndDate(noEndDate ? start : end);
    setFilter((prevState: any) => {
      return {
        ...prevState,
        period: '',
        sent_from: format(startOfDay(new Date(start)), "yyyy-MM-dd HH:mm:ss"),
        sent_to: format(endOfDay(new Date(noEndDate ? start : end)), "yyyy-MM-dd HH:mm:ss"),
      }
    });
  };

  const handleChangePeriodSelect = (event: SelectChangeEvent) => {
    //@ts-ignore
    const value: PeriodTypes = event.target.value;
    setSelectedPeriod(value);
  };

  const handleApplyFilter = useCallback(() => {
    setUpdateFilter(filter);
    setToggleDrawer(false);

    if (!editMode) {
      if (typeof setSelectedFilterId === 'function') {
        setSelectedFilterId('');
      }
      setTimeout(() => {
        setGenerateReport((prevState) => {
          return !prevState
        });
      }, 120);
    }
  }, [filter]);

  const affPayload = () => {
    return {
      chiefIds: null,
      departmentIds: null,
      platformIds: null,
      page: 1,
      search: null,
      per_page: 100,
    }
  };

  const fieldPayload = () => {
    return {
      page: 1,
      search: null,
      per_page: 100,
    }
  };

  const validateName = validationTextFieldMax(fieldName, 255, {
    required: t("common.validation.required_field"),
    maxLength: t("common.validation.max_length"),
  });

  const handleSave = useCallback(() => {
    if (typeof onSave === 'function') {
      onSave({
        name: fieldName,
        filter: filter,
      })
    }
  }, [filter, fieldName]);

  return (<>
    <Box className={styles.wrapper}>
      <Scrollbars
        className={styles.customScroll}
        style={{ overflow: "hidden" }}
        hideTracksWhenNotNeeded={true}
        renderView={props => <div {...props} className="view"/>}
        renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
        renderTrackVertical={props => <div {...props} className="track-vertical"/>}
      >
        {editMode && (
          <Box className={styles.box}>
            <TextField
              className={styles.fieldFull}
              size="small"
              id="filter_name"
              name="filter_name"
              type="text"
              label={t(`common.forms.fields.filter_name`)}
              value={fieldName}
              error={!!validateName}
              helperText={!!validateName ? validateName : ''}
              onChange={(event) => {
                setFieldName(event.target.value);
              }}
            />
          </Box>
        )}

        {!!filtersData?.types && !!filtersData?.statuses && (<>
          {mobile && (<>
            <Box className={styles.box}>
              <CSelect
                name="period"
                label={t("admin.reports.filter.period")}
                value={selectedPeriod}
                options={summaryPeriodOptions}
                onChange={handleChangePeriodSelect}
                prefix="common.components.periods."
              />
            </Box>
            <Box className={`${styles.box} noMargin`}>
              <CDateRangePicker
                startDate={startDate}
                endDate={endDate}
                onChange={handleChangeDates}
              />
            </Box>
          </>)}
          <Box className={styles.box}>
            <Box className={styles.fieldWrapper}>
              {!editMode && !mobile && (
                <Box className={styles.fieldFavorite}>
                  <FavoriteCheckboxField
                    checked={favWRAffiliates}
                    setChecked={setFavWRAffiliates}
                    fieldName={t(`admin.reports.filter.affiliates`)}
                  />
                </Box>
              )}
              <Box className={styles.field}>
                <MuiAutocompleteSelectLazy
                  keyValue="value"
                  keyId="id"
                  id="affiliateIds"
                  name="affiliateIds"
                  label={t(`admin.reports.filter.affiliates`)}
                  value={filter.affiliateIds}
                  multiple={true}
                  showSelectAll={false}
                  disabled={false}
                  getOptions={getAffiliates}
                  payloadData={affPayload()}
                  labelWithId={true}
                  onChange={setFilter}
                  disableCloseOnSelect={true}
                  autoFocus={activeField === 'affiliateIds'}
                  showLabelCounter={true}
                />
              </Box>
            </Box>
          </Box>
          <Box className={styles.box}>
            <Box className={styles.fieldWrapper}>
              {!editMode && !mobile && (
                <Box className={styles.fieldFavorite}>
                  <FavoriteCheckboxField
                    checked={favWRTrackers}
                    setChecked={setFavWRTrackers}
                    fieldName={t(`admin.reports.filter.trackers`)}
                  />
                </Box>
              )}
              <Box className={styles.field}>
                <MuiAutocompleteSelectLazy
                  keyValue="value"
                  keyId="id"
                  id="trackerIds"
                  name="trackerIds"
                  label={t(`admin.reports.filter.trackers`)}
                  value={filter.trackerIds}
                  multiple={true}
                  showSelectAll={false}
                  disabled={false}
                  getOptions={getTrackers}
                  payloadData={fieldPayload()}
                  labelWithId={false}
                  onChange={setFilter}
                  disableCloseOnSelect={true}
                  autoFocus={activeField === 'trackerIds'}
                  showLabelCounter={true}
                />
              </Box>
            </Box>
          </Box>
          <Box className={styles.box}>
            <Box className={styles.fieldWrapper}>
              {!editMode && !mobile && (
                <Box className={styles.fieldFavorite}>
                  <FavoriteCheckboxField
                    checked={favWRWebhooks}
                    setChecked={setFavWRWebhooks}
                    fieldName={t(`admin.reports.filter.webhooks`)}
                  />
                </Box>
              )}
              <Box className={styles.field}>
                <MuiAutocompleteSelectLazy
                  keyValue="value"
                  keyId="id"
                  id="webhookIds"
                  name="webhookIds"
                  label={t(`admin.reports.filter.webhooks`)}
                  value={filter.webhookIds}
                  multiple={true}
                  showSelectAll={false}
                  disabled={false}
                  getOptions={getWebhooks}
                  payloadData={fieldPayload()}
                  labelWithId={true}
                  onChange={setFilter}
                  disableCloseOnSelect={true}
                  autoFocus={activeField === 'webhookIds'}
                  showLabelCounter={true}
                />
              </Box>
            </Box>
          </Box>
          <Box className={styles.box}>
            <Box className={styles.fieldWrapper}>
              {!editMode && !mobile && (
                <Box className={styles.fieldFavorite}>
                  <FavoriteCheckboxField
                    checked={favWRWebhookTypes}
                    setChecked={setFavWRWebhookTypes}
                    fieldName={t(`admin.reports.filter.types`)}
                  />
                </Box>
              )}
              <Box className={styles.field}>
                <MuiAutocompleteSelect
                  keyValue="value"
                  keyId="id"
                  id="webhookTypes"
                  name="webhookTypes"
                  label={t(`admin.reports.filter.types`)}
                  value={filter.webhookTypes}
                  multiple={true}
                  showSelectAll={false}
                  disabled={false}
                  staticOptions={[...filtersData.types.list].reduce<Record<any, any>>((acc, item) => {
                    acc.push({
                      id: item,
                      value: item,
                    })
                    return acc;
                  }, [])}
                  onChange={setFilter}
                  disableCloseOnSelect={true}
                  autoFocus={activeField === 'webhookTypes'}
                  showLabelCounter={true}
                />
              </Box>
            </Box>
          </Box>
          <Box className={styles.box}>
            <Box className={styles.fieldWrapper}>
              {!editMode && !mobile && (
                <Box className={styles.fieldFavorite}>
                  <FavoriteCheckboxField
                    checked={favWRStatuses}
                    setChecked={setFavWRStatuses}
                    fieldName={t(`admin.reports.filter.statuses`)}
                  />
                </Box>
              )}
              <Box className={styles.field}>
                <MuiAutocompleteSelect
                  keyValue="value"
                  keyId="id"
                  id="statuses"
                  name="statuses"
                  label={t(`admin.reports.filter.statuses`)}
                  value={filter.statuses}
                  multiple={true}
                  showSelectAll={false}
                  disabled={false}
                  staticOptions={[...filtersData.statuses.list].reduce<Record<any, any>>((acc, item) => {
                    acc.push({
                      id: item,
                      value: item,
                    })
                    return acc;
                  }, [])}
                  onChange={setFilter}
                  disableCloseOnSelect={true}
                  autoFocus={activeField === 'statuses'}
                  showLabelCounter={true}
                />
              </Box>
            </Box>
          </Box>

          {!editMode && hasMyFilters && (
            <SaveFilterForm
              filter={filter}
              type={reportStorageProps.type}
              target={reportStorageProps.target}
              exceptions={reportStorageProps.exceptions}
              exceptionsIds={reportStorageProps.exceptionsIds}
              disabled={
                arePropsEqual(
                  {
                    ...initialFilterData,
                    sent_from: format(new Date(initialFilterData.sent_from), "yyyy-MM-dd HH:mm:ss"),
                    sent_to: format(new Date(initialFilterData.sent_to), "yyyy-MM-dd HH:mm:ss"),
                  },
                  {
                    ...filter,
                    sent_from: format(new Date(filter.sent_from), "yyyy-MM-dd HH:mm:ss"),
                    sent_to: format(new Date(filter.sent_to), "yyyy-MM-dd HH:mm:ss"),
                  }
                )
              }
            />
          )}
        </>)}
      </Scrollbars>
    </Box>
    <Box className={styles.actions}>
      <Box className={styles.actionsWrapper}>
        {editMode ? (
          <Button
            gradient
            className={styles.button}
            fullWidth
            disableElevation
            type="submit"
            variant="contained"
            onClick={handleSave}
            disabled={
              arePropsEqual({
                name: filterName,
                ...initialFilter,
                sent_from: format(new Date(initialFilter.sent_from), "yyyy-MM-dd HH:mm:ss"),
                sent_to: format(new Date(initialFilter.sent_to), "yyyy-MM-dd HH:mm:ss"),
              }, {
                name: fieldName,
                ...filter,
                sent_from: format(new Date(filter.sent_from), "yyyy-MM-dd HH:mm:ss"),
                sent_to: format(new Date(filter.sent_to), "yyyy-MM-dd HH:mm:ss"),
              }) || !!validateName
            }
          >
            {t("common.buttons.save")}
          </Button>
        ) : (
          <Button
            gradient
            className={styles.button}
            fullWidth
            disableElevation
            type="submit"
            variant="contained"
            onClick={handleApplyFilter}
            disabled={arePropsEqual(initialFilter, filter)}
          >
            {t("common.buttons.apply")}
          </Button>
        )}
        <Button
          className={styles.button}
          color="secondary"
          disableElevation
          type="button"
          variant="outlined"
          onClick={() => setToggleDrawer(false)}
        >
          {t("common.buttons.cancel")}
        </Button>
      </Box>
    </Box>
  </>);
};

export default Filter;
