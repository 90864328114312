//core
import React from "react";
import arePropsEqual from "react-fast-compare";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import { Box, Chip } from "@mui/material";

//icon
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';

//styles
import { useStyles } from "./styles";

interface IPreviewProps {
  value: {
    url?: string;
    recommended?: boolean;
  }
}

const Preview = (props: IPreviewProps) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const { value: { url, recommended = false } } = props;

  return (
    <Box className={`preview ${styles.preview} ${!!url ? '' : styles.emptyBg}`}>
      {!!url
          ? <img className={styles.img} src={url} alt="img"/>
          : <ImageNotSupportedIcon className={styles.icon} />
      }
      {recommended && <Chip
                        className={styles.chip}
                        size="small"
                        color="success"
                        label={t("admin.creatives.form.recommended.label")} />
      }
    </Box>
  );
};

export default React.memo(Preview, arePropsEqual);
