//core
import { createTheme, Palette } from '@mui/material/styles';
import { Theme, ThemeOptions } from "@mui/material/styles/createTheme";
import { formLabelClasses, outlinedInputClasses } from "@mui/material";
import { MuiButtonsOverrides } from "./general/buttonsOverrides";


type AppThemeType = string;

interface IAppThemes {
  [theme: string]: ThemeOptions;
};

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xlg: true;
  }
}

interface NavigationPalette {
  [key: string]: string;
}
interface OtherPalette {
  [key: string]: string;
}

interface GradientButtonPalette {
  [key: string]: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    white: Palette['primary'];
    navigation: NavigationPalette;
    selected: Palette['primary'];
    other: OtherPalette;
    gradientButton: GradientButtonPalette;
  }
  interface PaletteOptions {
    white: PaletteOptions['primary'];
    navigation: NavigationPalette;
    selected: PaletteOptions['primary'];
    other: OtherPalette;
    gradientButton: GradientButtonPalette;
  }
}

export const appTheme: AppThemeType = process.env.REACT_APP_THEME || 'pma_light';

export const themes: IAppThemes = {
  pma_light: {
    spacing: 8,
    //shadows: 'none',
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#009E69',
        contrastText: '#ffffff',
        dark: '#008F5F',
      },
      gradientButton: {
        background: '#009E69', //primary.main
        hover: '#008F5F', //primary.dark
      },
      secondary: {
        main: '#222',
        light: '#555555',
        dark: '#000',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      navigation: {
        menuBg: '#fff',
        menuHover: 'rgba(0, 0, 0, 0.04)',
        menuActive: 'rgba(0,158,105,0.08)',
        menuContrast: '#292621',
        menuContrastActive: '#009E69',
        headerBg: '#222222',
        headerBorder: '#222222',
        headerContrast: '#fff',
        headerContrastHover: 'rgba(255, 255, 255, 0.04)',
        gradientFrom: 'rgba(0, 0, 0, 0.54)',
        gradientTo: 'rgba(0, 0, 0, 0.54)',
        gradientActiveFrom: '#009E69',
        gradientActiveTo: '#009E69',
        scrollBg: 'rgba(0, 0, 0, 0.08)',
        scrollTrackBg: 'rgba(85, 85, 85, 0.3)',
      },
      other: {
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      selected: {
        main: '#ebf7f3',
      },
      background: {
        paper: '#fff',
        default: '#F5F4F1',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
      }
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      /*MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(34,34,34,0.8)',
          },
        },
      },*/
    },
  },
  pma_dark: {
    spacing: 8,
    //shadows: 'none',
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#009E69',
        contrastText: '#ffffff',
        dark: '#008F5F',
      },
      gradientButton: {
        background: '#009E69', //primary.main
        hover: '#008F5F', //primary.dark
      },
      secondary: {
        main: '#222',
        light: '#555555',
        dark: '#000',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      navigation: {
        menuBg: '#fff',
        menuHover: 'rgba(0, 0, 0, 0.04)',
        menuActive: 'rgba(0,158,105,0.08)',
        menuContrast: '#292621',
        menuContrastActive: '#009E69',
        headerBg: '#222222',
        headerBorder: '#222222',
        headerContrast: '#fff',
        headerContrastHover: 'rgba(255, 255, 255, 0.04)',
        gradientFrom: 'rgba(0, 0, 0, 0.54)',
        gradientTo: 'rgba(0, 0, 0, 0.54)',
        gradientActiveFrom: '#009E69',
        gradientActiveTo: '#009E69',
        scrollBg: 'rgba(0, 0, 0, 0.08)',
        scrollTrackBg: 'rgba(85, 85, 85, 0.3)',
      },
      other: {
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      selected: {
        main: '#ebf7f3',
      },
      background: {
        paper: '#fff',
        default: '#F5F4F1',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
      }
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      /*MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(34,34,34,0.8)',
          },
        },
      },*/
    },
  },
  nmda_light: {
    spacing: 8,
    //shadows: 'none',
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#1D2158',
        dark: '#111441',
        light: '#1E3B86',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: '#1D2158', //primary.main
        hover: '#111441', //primary.dark
      },
      secondary: {
        main: '#2E4B96',
        dark: '#1E3B86',
        light: '#4E6BB6',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      navigation: {
        menuBg: '#1D2158',
        menuHover: '#111441',
        menuActive: '#343769',
        menuContrast: '#E3E4F6',
        menuContrastActive: '#F2C555',
        headerBg: '#1D2158',
        headerBorder: '#1D2158',
        headerContrast: '#FFFFFF',
        headerContrastHover: '#111441',
        gradientFrom: '#F3C555',
        gradientTo: '#FF8E18',
        gradientActiveFrom: '#F3C555',
        gradientActiveTo: '#FF8E18',
        scrollBg: 'rgba(0, 0, 0, 0.35)',
        scrollTrackBg: 'rgba(255,255,255,0.2)',
      },
      other: {
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      selected: {
        main: '#edf0f7',
      },
      background: {
        paper: '#F5F5F5',
        default: '#EBEBEB',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
      }
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
    },
  },
  nmda_dark: {
    spacing: 8,
    //shadows: 'none',
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#1D2158',
        dark: '#111441',
        light: '#1E3B86',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: '#1D2158', //primary.main
        hover: '#111441', //primary.dark
      },
      secondary: {
        main: '#2E4B96',
        dark: '#1E3B86',
        light: '#4E6BB6',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      navigation: {
        menuBg: '#1D2158',
        menuHover: '#111441',
        menuActive: '#343769',
        menuContrast: '#E3E4F6',
        menuContrastActive: '#F2C555',
        headerBg: '#1D2158',
        headerBorder: '#1D2158',
        headerContrast: '#FFFFFF',
        headerContrastHover: '#111441',
        gradientFrom: '#F3C555',
        gradientTo: '#FF8E18',
        gradientActiveFrom: '#F3C555',
        gradientActiveTo: '#FF8E18',
        scrollBg: 'rgba(0, 0, 0, 0.35)',
        scrollTrackBg: 'rgba(255,255,255,0.2)',
      },
      other: {
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      selected: {
        main: '#edf0f7',
      },
      background: {
        paper: '#F5F5F5',
        default: '#EBEBEB',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
      }
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
    },
  },
  pkmp_light: {
    spacing: 8,
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#FFC200',
        dark: '#F8BC00',
        light: '#FFCD2E',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: '#FFC200', //primary.main
        hover: '#F8BC00', //primary.dark
      },
      secondary: {
        main: '#222222',
        dark: '#000000',
        light: '#3E3E3E',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      navigation: {
        menuBg: '#222222',
        menuHover: '#1A1A1A',
        menuActive: 'rgba(255, 194, 0, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#FFC200',
        headerBg: '#222222',
        headerBorder: '#222222',
        headerContrast: '#FFFFFF',
        headerContrastHover: '#1A1A1A',
        gradientFrom: '#EEEEEE',
        gradientTo: '#EEEEEE',
        gradientActiveFrom: '#FFC200',
        gradientActiveTo: '#FFC200',
        scrollBg: 'rgba(0, 0, 0, 0.5)',
        scrollTrackBg: 'rgba(255,255,255,0.2)',
      },
      other: {
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      selected: {
        main: '#ebf7f3',
      },
      action: {
        //active: '#222222', // primary.light
        active: 'rgba(34, 34, 34, 0.54)',
        activatedOpacity: 0.54,
        hover: 'rgba(34, 34, 34, 0.04)', // primary.dark
        hoverOpacity: 0.04,
        selected: 'rgba(34, 34, 34, 0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(34, 34, 34, 0.26)', // text.disabled
        disabledOpacity: 0.25,
        disabledBackground: 'rgba(34, 34, 34, 0.12)',
        focus: 'rgba(5, 5, 27, 0.12)',
        focusOpacity: 0.12,
      },
      background: {
        paper: '#FFFFFF',
        default: '#F5F4F1',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
      }
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      /*MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: '#dda800', //primary.main
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: '#dda800', //primary.main
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: '#dda800', //primary.main
              },
            },
          },
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            backgroundColor: '#fff', //primary.main
          }
        }
      },*/
      MuiModal: {
        styleOverrides: {
          root: {
            '& .MuiBackdrop-root': {
              //backgroundColor: 'rgba(34,34,34,0.8)',
            }
          },
        },
      },
      /*MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(34,34,34,0.8)', //secondary.main
          },
        },
      },*/
      /*MuiAppBar: {
        styleOverrides: {
          root: {
            background: 'primary.main',
          }
        }
      },*/
    },
  },
  pkmp_dark: {
    spacing: 8,
    palette: {
      mode: 'light',
      text: {
        primary: '#fff',
        secondary: '#D9D9D9',
        disabled: '#BDB8AD',
        // primary: '#292621',
        // secondary: '#403C34',
        // disabled: '#BDB8AD',
      },
      primary: {
        main: '#ffc200',
        contrastText: '#000',
        dark: '#dda800',
        light: '#fecc2d',
      },
      gradientButton: {
        background: '#ffc200', //primary.main
        hover: '#dda800', //primary.dark
      },
      secondary: {
        main: '#fff',
        light: '#f5f5f5',
        dark: '#e8e8e8',
        contrastText: '#000',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      navigation: {
        menuBg: '#222222',
        menuHover: '#1A1A1A',
        menuActive: 'rgba(255, 194, 0, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#FFC200',
        headerBg: '#222222',
        headerBorder: '#222222',
        headerContrast: '#FFFFFF',
        headerContrastHover: '#1A1A1A',
        gradientFrom: '#EEEEEE',
        gradientTo: '#EEEEEE',
        gradientActiveFrom: '#FFC200',
        gradientActiveTo: '#FFC200',
        scrollBg: 'rgba(0, 0, 0, 0.5)',
        scrollTrackBg: 'rgba(255,255,255,0.2)',
      },
      other: {
        outlinedBorder: '#dda800', //primary.main
      },
      selected: {
        main: '#2b2a2a',
      },
      action: {
        active: '#fff', // primary.light
        hover: 'rgba(255,255,255,0.08)', // primary.dark
        hoverOpacity: 0.08,
        selected: 'rgba(255,255,255,0.16)',
        selectedOpacity: 0.16,
        disabled: 'rgba(255,255,255,0.3)', // text.disabled
        disabledOpacity: 0.38,
        disabledBackground: 'rgba(255,255,255, 0.12)',
        focus: 'rgba(255,255,255, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#363535',
        default: '#000',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#f5f5f5',
        A200: '#eeeeee',
        A400: '#bdbdbd',
        A700: '#616161',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: '#dda800', //primary.main
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: '#dda800', //primary.main
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: '#dda800', //primary.main
              },
            },
          },
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            backgroundColor: '#fff', //primary.main
          }
        }
      },
      /*MuiAppBar: {
        styleOverrides: {
          root: {
            background: 'primary.main',
          }
        }
      },*/
    },
  },
  afft_light: {
    spacing: 8,
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#1D0909',
        light: '#292621',
        contrastText: '#ffffff',
        dark: '#1D0909',
      },
      gradientButton: {
        background: '#1D0909', //primary.main
        hover: '#1D0909', //primary.dark
      },
      secondary: {
        main: '#222',
        light: '#555',
        dark: '#000',
        contrastText: '#FFFFFF'
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      navigation: {
        menuBg: '#050403',
        menuHover: 'rgba(255, 255, 255, 0.06)',
        menuActive: 'rgba(255, 255, 255, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#FF291F',
        headerBg: '#050403',
        headerBorder: '#050403',
        headerContrast: '#FFFFFF',
        headerContrastHover: 'rgba(255, 255, 255, 0.08)',
        gradientFrom: '#FFFFFF',
        gradientTo: '#FFFFFF',
        gradientActiveFrom: '#FF291F',
        gradientActiveTo: '#FF291F',
        scrollBg: '#222',
        scrollTrackBg: 'rgba(255, 255, 255, 0.12)',
      },
      other: {
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      selected: {
        main: '#ebf7f3',
      },
      background: {
        paper: '#FFFFFF',
        default: '#F5F4F1',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
      }
    },
    typography: {
      fontFamily: `'Arimo', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
    },
  },
  afft_dark: {
    spacing: 8,
    palette: {
      mode: 'dark',
      text: {
        primary: '#E2E2E2',
        secondary: '#AFAFAF',
        disabled: '#777575',
      },
      primary: {
        main: '#FF291F',
        light: '#FF5951',
        contrastText: '#ffffff',
        dark: '#E01107',
      },
      gradientButton: {
        background: '#FF291F', //primary.main
        hover: '#E01107', //primary.dark
      },
      secondary: {
        main: '#F8FAFF',
        light: '#FEFEFF',
        dark: '#ECEEF1',
        contrastText: 'rgba(0,0,0,0.87)'
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#222222',
      },
      navigation: {
        menuBg: '#050403',
        menuHover: 'rgba(255, 255, 255, 0.06)',
        menuActive: 'rgba(255, 255, 255, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#FF291F',
        headerBg: '#050403',
        headerBorder: '#050403',
        headerContrast: '#FFFFFF',
        headerContrastHover: 'rgba(255, 255, 255, 0.08)',
        gradientFrom: '#FFFFFF',
        gradientTo: '#FFFFFF',
        gradientActiveFrom: '#FF291F',
        gradientActiveTo: '#FF291F',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(255, 255, 255, 0.12)',
        outlinedBorder: 'rgba(255, 255, 255, 0.23)',
        filledInputBackground: 'rgba(255, 255, 255, 0.09)',
        standardInputLine: 'rgba(255, 255, 255, 0.09)',
        snackbar: '#F8FAFF',
        ratingActive: '#FFB400',
        tooltip: 'rgba(97, 97, 97, 0.90)',
        searchBg: 'rgba(255, 255, 255, 0.15)',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      selected: {
        main: '#151515',
      },
      action: {
        active: 'rgba(226,226,226,0.54)', // primary.light
        hover: 'rgba(226,226,226,0.04)', // primary.dark
        hoverOpacity: 0.08,
        selected: 'rgba(226,226,226,0.08)',
        selectedOpacity: 0.16,
        disabled: 'rgba(226,226,226,0.26)', // text.disabled
        disabledOpacity: 0.38,
        disabledBackground: 'rgba(226,226,226, 0.12)',
        focus: 'rgba(226,226,226, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#1E1E1E',
        default: '#121212',
      },

      grey: {
        50: '#212121',
        100: '#424242',
        200: '#616161',
        300: '#757575',
        400: '#9E9E9E',
        500: '#BDBDBD',
        600: '#E0E0E0',
        700: '#EEEEEE',
        800: '#F5F5F5',
        900: '#FAFAFA',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Arimo', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#F8FAFF', //secondary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: '#F8FAFF', //secondary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#F8FAFF', //secondary.main
              },
            }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              "& > fieldset": {
                borderColor: '#F8FAFF!important', //secondary.main
              },
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#F8FAFF', //secondary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: '#F8FAFF', //secondary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#F8FAFF', //secondary.main
              },
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#F8FAFF', //secondary.main
                }
              }
            },
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: '#F8FAFF' //secondary.main
            },
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: 'rgba(97,97,97,0.9)',
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            //borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
          }
        }
      },
      /*MuiAppBar: {
        styleOverrides: {
          root: {
            background: 'primary.main',
          }
        }
      },*/
    },
  },
  grp_light: {
    spacing: 8,
    //shadows: 'none',
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#0066FF',
        light: '#2D81FF',
        contrastText: '#ffffff',
        dark: '#004CBF',
      },
      gradientButton: {
        background: '#0066FF', //primary.main
        hover: '#004CBF', //primary.dark
      },
      secondary: {
        main: '#222222',
        light: '#3E3E3E',
        dark: '#000000',
        contrastText: '#FFFFFF'
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      navigation: {
        menuBg: '#000000',
        menuHover: '#1A1A1A',
        menuActive: 'rgba(1, 255, 19, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#01FF13',
        headerBg: '#000000',
        headerBorder: '#000000',
        headerContrast: '#FFFFFF',
        headerContrastHover: '#1A1A1A',
        gradientFrom: '#EEEEEE',
        gradientTo: '#EEEEEE',
        gradientActiveFrom: '#01FF13',
        gradientActiveTo: '#01FF13',
        scrollBg: '#222',
        scrollTrackBg: 'rgba(255, 255, 255, 0.12)',
      },
      other: {
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      selected: {
        main: '#ebf7f3',
      },
      background: {
        paper: '#FFFFFF',
        default: '#F5F4F1',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
      }
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
    },
  },
  grp_dark: {
    spacing: 8,
    //shadows: 'none',
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#0066FF',
        light: '#2D81FF',
        contrastText: '#ffffff',
        dark: '#004CBF',
      },
      gradientButton: {
        background: '#0066FF', //primary.main
        hover: '#004CBF', //primary.dark
      },
      secondary: {
        main: '#222222',
        light: '#3E3E3E',
        dark: '#000000',
        contrastText: '#FFFFFF'
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      navigation: {
        menuBg: '#000000',
        menuHover: '#1A1A1A',
        menuActive: 'rgba(1, 255, 19, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#01FF13',
        headerBg: '#000000',
        headerBorder: '#000000',
        headerContrast: '#FFFFFF',
        headerContrastHover: '#1A1A1A',
        gradientFrom: '#EEEEEE',
        gradientTo: '#EEEEEE',
        gradientActiveFrom: '#01FF13',
        gradientActiveTo: '#01FF13',
        scrollBg: '#222',
        scrollTrackBg: 'rgba(255, 255, 255, 0.12)',
      },
      other: {
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      selected: {
        main: '#ebf7f3',
      },
      background: {
        paper: '#FFFFFF',
        default: '#F5F4F1',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
      }
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
    },
  },
  bap_light: {
    spacing: 8,
    palette: {
      mode: 'light',
      text: {
        primary: '#FFFFFF',
        secondary: 'rgba(255, 255, 255, 0.7)',
        disabled: '#ffffff80',
      },
      primary: {
        main: '#F2F740',
        dark: '#EAEF24',
        light: '#FBFF6A',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      gradientButton: {
        background: '#F2F740', //primary.main
        hover: '#EAEF24', //primary.dark
      },
      secondary: {
        main: '#F0ECEC',
        dark: '#E7E3E3',
        light: '#FBF7F7',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#222222',
      },
      navigation: {
        menuBg: '#222222',
        menuHover: 'rgba(255, 255, 255, 0.04)',
        menuActive: 'rgba(242, 247, 64, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#F2F740',
        headerBg: '#222222',
        headerBorder: '#222222',
        headerContrast: '#FFFFFF',
        headerContrastHover: 'rgba(255, 255, 255, 0.04)',
        gradientFrom: 'rgba(255, 255, 255, 0.54)',
        gradientTo: 'rgba(255, 255, 255, 0.54)',
        gradientActiveFrom: '#F2F740',
        gradientActiveTo: '#F2F740',
        scrollBg: 'rgba(0, 0, 0, 0.5)',
        scrollTrackBg: 'rgba(255,255,255,0.2)',
      },
      other: {
        divider: 'rgba(255, 255, 255, 0.16)',
        outlinedBorder: 'rgba(255, 255, 255, 0.23)',
      },
      divider: 'rgba(255, 255, 255, 0.16)',
      selected: {
        main: '#2b2a2a',
      },
      action: {
        active: '#fff', // primary.light
        hover: 'rgba(255,255,255,0.08)', // primary.dark
        hoverOpacity: 0.08,
        selected: 'rgba(255,255,255,0.16)',
        selectedOpacity: 0.16,
        disabled: 'rgba(255,255,255,0.3)', // text.disabled
        disabledOpacity: 0.38,
        disabledBackground: 'rgba(255,255,255, 0.12)',
        focus: 'rgba(255,255,255, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#393939',
        default: '#303030',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: '#F2F740', //primary.main
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: '#F2F740', //primary.main
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: '#F2F740', //primary.main
              },
            },
          },
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            backgroundColor: '#F2F740', //primary.main
          }
        }
      },
    },
  },
  bap_dark: {
    spacing: 8,
    palette: {
      mode: 'light',
      text: {
        primary: '#FFFFFF',
        secondary: 'rgba(255, 255, 255, 0.7)',
        disabled: '#ffffff80',
      },
      primary: {
        main: '#F2F740',
        dark: '#EAEF24',
        light: '#FBFF6A',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      gradientButton: {
        background: '#F2F740', //primary.main
        hover: '#EAEF24', //primary.dark
      },
      secondary: {
        main: '#F0ECEC',
        dark: '#E7E3E3',
        light: '#FBF7F7',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#222222',
      },
      navigation: {
        menuBg: '#222222',
        menuHover: 'rgba(255, 255, 255, 0.04)',
        menuActive: 'rgba(242, 247, 64, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#F2F740',
        headerBg: '#222222',
        headerBorder: '#222222',
        headerContrast: '#FFFFFF',
        headerContrastHover: 'rgba(255, 255, 255, 0.04)',
        gradientFrom: 'rgba(255, 255, 255, 0.54)',
        gradientTo: 'rgba(255, 255, 255, 0.54)',
        gradientActiveFrom: '#F2F740',
        gradientActiveTo: '#F2F740',
        scrollBg: 'rgba(0, 0, 0, 0.5)',
        scrollTrackBg: 'rgba(255,255,255,0.2)',
      },
      other: {
        divider: 'rgba(255, 255, 255, 0.16)',
        outlinedBorder: 'rgba(255, 255, 255, 0.23)',
      },
      divider: 'rgba(255, 255, 255, 0.16)',
      selected: {
        main: '#2b2a2a',
      },
      action: {
        active: '#fff', // primary.light
        hover: 'rgba(255,255,255,0.08)', // primary.dark
        hoverOpacity: 0.08,
        selected: 'rgba(255,255,255,0.16)',
        selectedOpacity: 0.16,
        disabled: 'rgba(255,255,255,0.3)', // text.disabled
        disabledOpacity: 0.38,
        disabledBackground: 'rgba(255,255,255, 0.12)',
        focus: 'rgba(255,255,255, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#393939',
        default: '#303030',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: '#F2F740', //primary.main
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: '#F2F740', //primary.main
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: '#F2F740', //primary.main
              },
            },
          },
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            backgroundColor: '#F2F740', //primary.main
          }
        }
      },
    },
  },
  pmaf_light: {
    spacing: 8,
    //shadows: 'none',
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#009E69',
        contrastText: '#ffffff',
        dark: '#008F5F',
      },
      gradientButton: {
        background: '#009E69', //primary.main
        hover: '#008F5F', //primary.dark
      },
      secondary: {
        main: '#222',
        light: '#555555',
        dark: '#000',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      navigation: {
        menuBg: '#fff',
        menuHover: 'rgba(0, 0, 0, 0.04)',
        menuActive: 'rgba(0,158,105,0.08)',
        menuContrast: '#292621',
        menuContrastActive: '#009E69',
        headerBg: '#222222',
        headerBorder: '#222222',
        headerContrast: '#fff',
        headerContrastHover: 'rgba(255, 255, 255, 0.04)',
        gradientFrom: 'rgba(0, 0, 0, 0.54)',
        gradientTo: 'rgba(0, 0, 0, 0.54)',
        gradientActiveFrom: '#009E69',
        gradientActiveTo: '#009E69',
        scrollBg: 'rgba(0, 0, 0, 0.08)',
        scrollTrackBg: 'rgba(85, 85, 85, 0.3)',
      },
      other: {
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      selected: {
        main: '#ebf7f3',
      },
      background: {
        paper: '#fff',
        default: '#F5F4F1',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
      }
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
    },
  },
  pmaf_dark: {
    spacing: 8,
    //shadows: 'none',
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#009E69',
        contrastText: '#ffffff',
        dark: '#008F5F',
      },
      gradientButton: {
        background: '#009E69', //primary.main
        hover: '#008F5F', //primary.dark
      },
      secondary: {
        main: '#222',
        light: '#555555',
        dark: '#000',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      navigation: {
        menuBg: '#fff',
        menuHover: 'rgba(0, 0, 0, 0.04)',
        menuActive: 'rgba(0,158,105,0.08)',
        menuContrast: '#292621',
        menuContrastActive: '#009E69',
        headerBg: '#222222',
        headerBorder: '#222222',
        headerContrast: '#fff',
        headerContrastHover: 'rgba(255, 255, 255, 0.04)',
        gradientFrom: 'rgba(0, 0, 0, 0.54)',
        gradientTo: 'rgba(0, 0, 0, 0.54)',
        gradientActiveFrom: '#009E69',
        gradientActiveTo: '#009E69',
        scrollBg: 'rgba(0, 0, 0, 0.08)',
        scrollTrackBg: 'rgba(85, 85, 85, 0.3)',
      },
      other: {
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      selected: {
        main: '#ebf7f3',
      },
      background: {
        paper: '#fff',
        default: '#F5F4F1',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
      }
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
    },
  },
  bat_light: {
    spacing: 8,
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#3FB5AB',
        dark: '#3FB5AB',
        light: '#57D4CA',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: 'linear-gradient(90deg, #3FB5AB 0%, #19CDA1 100%);', //primary.main
        hover: 'rgba(0,0,0,0.10)', //primary.dark
      },
      secondary: {
        main: '#1C1C1F',
        dark: '#000000',
        light: '#8D8D8D',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#222222',
      },
      navigation: {
        menuBg: '#222222',
        menuHover: 'rgba(255, 255, 255, 0.04)',
        menuActive: 'rgba(6, 233, 157, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#06E99D',
        headerBg: '#222222',
        headerBorder: '#222222',
        headerContrast: '#FFFFFF',
        headerContrastHover: '#2E2E2E',
        gradientFrom: '#FFFFFF',
        gradientTo: '#FFFFFF',
        gradientActiveFrom: '#06E99D',
        gradientActiveTo: '#06E99D',
        scrollBg: 'rgba(0, 0, 0, 0.08)',
        scrollTrackBg: 'rgba(85,85,85,0.3)',
      },
      other: {
        divider: 'rgba(34, 34, 34, 0.12)',
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      divider: 'rgba(0, 0, 0, 0.12)',
      selected: {
        main: 'rgba(0,0,0,0.08)',
      },
      action: {
        active: 'rgba(0,0,0,0.54)',
        hover: 'rgba(0,0,0,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(0,0,0,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(0,0,0,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(0,0,0,0.12)',
        focus: 'rgba(0,0,0, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#FFFFFF',
        default: '#F7F7F8',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(0,0,0,0.54)', //primary.main
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            backgroundColor: 'rgba(0,0,0,0.54)', //primary.main
          }
        }
      },
    },
  },
  bat_dark: {
    spacing: 8,
    palette: {
      mode: 'dark',
      text: {
        primary: '#FFFFFF',
        secondary: 'rgba(255, 255, 255, 0.7)',
        disabled: 'rgba(255, 255, 255, 0.5)',
      },
      primary: {
        main: '#06E99D',
        dark: '#01CB87',
        light: '#0EFDAD',
        contrastText: '#E1FFFC',
      },
      gradientButton: {
        background: 'linear-gradient(90deg, #3FB5AB 0%, #19CDA1 100%);', //primary.main
        hover: 'rgba(0,0,0,0.10)', //primary.dark
      },
      secondary: {
        main: '#E4ECFF',
        dark: '#D4DFFF',
        light: '#EFF3FF',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#222222',
      },
      navigation: {
        menuBg: '#222222',
        menuHover: 'rgba(255, 255, 255, 0.04)',
        menuActive: 'rgba(6, 233, 157, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#06E99D',
        headerBg: '#222222',
        headerBorder: '#222222',
        headerContrast: '#FFFFFF',
        headerContrastHover: '#2E2E2E',
        gradientFrom: '#FFFFFF',
        gradientTo: '#FFFFFF',
        gradientActiveFrom: '#06E99D',
        gradientActiveTo: '#06E99D',
        scrollBg: 'rgba(0, 0, 0, 0.08)',
        scrollTrackBg: 'rgba(85,85,85,0.3)',
      },
      other: {
        divider: 'rgba(255, 255, 255, 0.12)',
        outlinedBorder: 'rgba(255, 255, 255, 0.23)',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      selected: {
        main: 'rgba(255,255,255,0.08)',
      },
      action: {
        active: 'rgba(255,255,255,0.54)',
        hover: 'rgba(255,255,255,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(255,255,255,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(255,255,255,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(255,255,255,0.12)',
        focus: 'rgba(255,255,255,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#363636',
        default: '#2D2D2D',
      },
      grey: {
        50: '#212121',
        100: '#424242',
        200: '#616161',
        300: '#757575',
        400: '#9E9E9E',
        500: '#BDBDBD',
        600: '#E0E0E0',
        700: '#EEEEEE',
        800: '#F5F5F5',
        900: '#FAFAFA',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#06E99D', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#06E99D', //primary.main
              },
            }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              "& > fieldset": {
                borderColor: '#06E99D !important', //primary.main
              },
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#06E99D', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#06E99D', //primary.main
              },
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#06E99D', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#06E99D', //primary.main
              },
            }
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: '#06E99D' //primary.main
            },
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: 'rgba(97,97,97,0.9)',
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            //borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
          }
        }
      },
    },
  },
  pmtz_light: {
    spacing: 8,
    //shadows: 'none',
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#009E69',
        contrastText: '#ffffff',
        dark: '#008F5F',
      },
      gradientButton: {
        background: '#009E69', //primary.main
        hover: '#008F5F', //primary.dark
      },
      secondary: {
        main: '#222',
        light: '#555555',
        dark: '#000',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      navigation: {
        menuBg: '#fff',
        menuHover: 'rgba(0, 0, 0, 0.04)',
        menuActive: 'rgba(0,158,105,0.08)',
        menuContrast: '#292621',
        menuContrastActive: '#009E69',
        headerBg: '#222222',
        headerBorder: '#222222',
        headerContrast: '#fff',
        headerContrastHover: 'rgba(255, 255, 255, 0.04)',
        gradientFrom: 'rgba(0, 0, 0, 0.54)',
        gradientTo: 'rgba(0, 0, 0, 0.54)',
        gradientActiveFrom: '#009E69',
        gradientActiveTo: '#009E69',
        scrollBg: 'rgba(0, 0, 0, 0.08)',
        scrollTrackBg: 'rgba(85, 85, 85, 0.3)',
      },
      other: {
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      selected: {
        main: '#ebf7f3',
      },
      background: {
        paper: '#fff',
        default: '#F5F4F1',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
      }
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
    },
  },
  pmtz_dark: {
    spacing: 8,
    //shadows: 'none',
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#009E69',
        contrastText: '#ffffff',
        dark: '#008F5F',
      },
      gradientButton: {
        background: '#009E69', //primary.main
        hover: '#008F5F', //primary.dark
      },
      secondary: {
        main: '#222',
        light: '#555555',
        dark: '#000',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      navigation: {
        menuBg: '#fff',
        menuHover: 'rgba(0, 0, 0, 0.04)',
        menuActive: 'rgba(0,158,105,0.08)',
        menuContrast: '#292621',
        menuContrastActive: '#009E69',
        headerBg: '#222222',
        headerBorder: '#222222',
        headerContrast: '#fff',
        headerContrastHover: 'rgba(255, 255, 255, 0.04)',
        gradientFrom: 'rgba(0, 0, 0, 0.54)',
        gradientTo: 'rgba(0, 0, 0, 0.54)',
        gradientActiveFrom: '#009E69',
        gradientActiveTo: '#009E69',
        scrollBg: 'rgba(0, 0, 0, 0.08)',
        scrollTrackBg: 'rgba(85, 85, 85, 0.3)',
      },
      other: {
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      selected: {
        main: '#ebf7f3',
      },
      background: {
        paper: '#fff',
        default: '#F5F4F1',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
      }
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
    },
  },
  mjp_light: {
    spacing: 8,
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#3EA1D9',
        light: '#88CCF2',
        contrastText: '#ffffff',
        dark: '#3790C3',
      },
      gradientButton: {
        background: '#3EA1D9', //primary.main
        hover: '#3790C3', //primary.dark
      },
      secondary: {
        main: '#222222',
        light: '#3E3E3E',
        dark: '#000',
        contrastText: '#FFFFFF'
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      navigation: {
        menuBg: '#152C49',
        menuHover: 'rgba(62, 161, 217, 0.08)',
        menuActive: 'rgba(62, 161, 217, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#3EA1D9',
        headerBg: '#152C49',
        headerBorder: '#152C49',
        headerContrast: '#FFFFFF',
        headerContrastHover: 'rgba(21, 44, 73, 1)',
        gradientFrom: '#EEEEEE',
        gradientTo: '#EEEEEE',
        gradientActiveFrom: '#3EA1D9',
        gradientActiveTo: '#3EA1D9',
        scrollBg: '#222',
        scrollTrackBg: 'rgba(255, 255, 255, 0.12)',
      },
      other: {
        outlinedBorder: 'rgba(34, 34, 34, 0.26)',
      },
      selected: {
        main: '#ebf7f3',
      },
      background: {
        paper: '#FFFFFF',
        default: '#F7F7F8',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
      }
    },
    typography: {
      fontFamily: `'Arimo', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
    },
  },
  mjp_dark: {
    spacing: 8,
    palette: {
      mode: 'dark',
      text: {
        primary: '#E2E2E2',
        secondary: '#AFAFAF',
        disabled: '#777575',
      },
      primary: {
        main: '#FF291F',
        light: '#FF5951',
        contrastText: '#ffffff',
        dark: '#E01107',
      },
      gradientButton: {
        background: '#FF291F', //primary.main
        hover: '#E01107', //primary.dark
      },
      secondary: {
        main: '#F8FAFF',
        light: '#FEFEFF',
        dark: '#ECEEF1',
        contrastText: 'rgba(0,0,0,0.87)'
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      navigation: {
        menuBg: '#050403',
        menuHover: 'rgba(0, 0, 0, 0.04)',
        menuActive: 'rgba(255, 255, 255, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#FF291F',
        headerBg: '#050403',
        headerBorder: '#050403',
        headerContrast: '#FFFFFF',
        headerContrastHover: 'rgba(255, 255, 255, 0.04)',
        gradientFrom: '#FFFFFF',
        gradientTo: '#FFFFFF',
        gradientActiveFrom: '#FF291F',
        gradientActiveTo: '#FF291F',
        scrollBg: '#222',
        scrollTrackBg: 'rgba(255, 255, 255, 0.12)',
      },
      other: {
        divider: 'rgba(255, 255, 255, 0.12)',
        outlinedBorder: 'rgba(255, 255, 255, 0.23)',
        filledInputBackground: 'rgba(255, 255, 255, 0.09)',
        standardInputLine: 'rgba(255, 255, 255, 0.09)',
        snackbar: '#F8FAFF',
        ratingActive: '#FFB400',
        tooltip: 'rgba(97, 97, 97, 0.90)',
        searchBg: 'rgba(255, 255, 255, 0.15)',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      selected: {
        main: '#151515',
      },
      action: {
        active: 'rgba(226,226,226,0.54)', // primary.light
        hover: 'rgba(226,226,226,0.04)', // primary.dark
        hoverOpacity: 0.08,
        selected: 'rgba(226,226,226,0.08)',
        selectedOpacity: 0.16,
        disabled: 'rgba(226,226,226,0.26)', // text.disabled
        disabledOpacity: 0.38,
        disabledBackground: 'rgba(226,226,226, 0.12)',
        focus: 'rgba(226,226,226, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#1E1E1E',
        default: '#121212',
      },
      grey: {
        50: '#303030',
        100: '#424242',
        200: '#616161',
        300: '#424242',
        400: '#9E9E9E',
        500: '#BDBDBD',
        600: '#E0E0E0',
        700: '#EEEEEE',
        800: '#F5F5F5',
        900: '#FAFAFA',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      }
    },
    typography: {
      fontFamily: `'Arimo', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#F8FAFF', //secondary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: '#F8FAFF', //secondary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#F8FAFF', //secondary.main
              },
            }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              "& > fieldset": {
                borderColor: '#F8FAFF!important', //secondary.main
              },
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#F8FAFF', //secondary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: '#F8FAFF', //secondary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#F8FAFF', //secondary.main
              },
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#F8FAFF', //secondary.main
                }
              }
            },
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: '#F8FAFF' //secondary.main
            },
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: 'rgba(97,97,97,0.9)',
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            //borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
          }
        }
      },
      /*MuiAppBar: {
        styleOverrides: {
          root: {
            background: 'primary.main',
          }
        }
      },*/
    },
  },
  wno_light: {
    spacing: 8,
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#2896E6',
        dark: '#1D85D2',
        light: '#4DB0F7',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: '#2896E6', //primary.main
        hover: '#1D85D2', //primary.dark
      },
      secondary: {
        main: '#222222',
        dark: '#000000',
        light: '#3E3E3E',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#000000',
      },
      navigation: {
        menuBg: '#0F2445',
        menuHover: 'rgba(40, 150, 230, 0.04)',
        menuActive: 'rgba(40, 150, 230, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#2896E6',
        headerBg: '#0F2445',
        headerBorder: '#0F2445',
        headerContrast: '#FFFFFF',
        headerContrastHover: 'rgba(255, 255, 255, 0.04)',
        gradientFrom: '#EEEEEE',
        gradientTo: '#EEEEEE',
        gradientActiveFrom: '#2896E6',
        gradientActiveTo: '#2896E6',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255,0.3)',
      },
      other: {
        divider: 'rgba(34, 34, 34, 0.12)',
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      divider: 'rgba(0, 0, 0, 0.12)',
      selected: {
        main: 'rgba(0,0,0,0.08)',
      },
      action: {
        active: 'rgba(15,36,69,0.54)',
        hover: 'rgba(15,36,69,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(15,36,69,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(15,36,69,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(15,36,69,0.12)',
        focus: 'rgba(0,0,0,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#FFFFFF',
        default: '#F7F7F8',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(0,0,0,0.54)', //primary.main
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            backgroundColor: 'rgba(0,0,0,0.54)', //primary.main
          }
        }
      },
    },
  },
  wno_dark: {
    spacing: 8,
    palette: {
      mode: 'dark',
      text: {
        primary: '#FFFFFF',
        secondary: '#AFAFAF',
        disabled: '#777575',
      },
      primary: {
        main: '#2896E6',
        dark: '#1D85D2',
        light: '#4DB0F7',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: '#2896E6', //primary.main
        hover: '#1D85D2', //primary.dark
      },
      secondary: {
        main: '#F6F9FF',
        dark: '#E0E4EF',
        light: '#FFFFFF',
        contrastText: 'rgba(0,0,0,0.87)'
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      navigation: {
        menuBg: '#0F2445',
        menuHover: 'rgba(40, 150, 230, 0.04)',
        menuActive: 'rgba(40, 150, 230, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#2896E6',
        headerBg: '#0F2445',
        headerBorder: '#0F2445',
        headerContrast: '#FFFFFF',
        headerContrastHover: 'rgba(255, 255, 255, 0.08)',
        gradientFrom: '#EEEEEE',
        gradientTo: '#EEEEEE',
        gradientActiveFrom: '#2896E6',
        gradientActiveTo: '#2896E6',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255,0.3)',
      },
      other: {
        divider: 'rgba(255, 255, 255, 0.12)',
        outlinedBorder: 'rgba(255, 255, 255, 0.23)',
        filledInputBackground: 'rgba(0, 0, 0, 0.09)',
        standardInputLine: 'rgba(255, 255, 255, 0.09)',
        snackbar: '#FFFFFF',
        ratingActive: '#FFB400',
        tooltip: 'rgba(97, 97, 97, 0.90)',
        searchBg: 'rgba(255, 255, 255, 0.15)',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      selected: {
        main: '#151515',
      },
      action: {
        active: 'rgba(255,255,255,0.54)', // primary.light
        hover: 'rgba(255,255,255,0.04)', // primary.dark
        hoverOpacity: 0.08,
        selected: 'rgba(255,255,255,0.08)',
        selectedOpacity: 0.16,
        disabled: 'rgba(255,255,255,0.26)', // text.disabled
        disabledOpacity: 0.38,
        disabledBackground: 'rgba(255,255,255, 0.12)',
        focus: 'rgba(255,255,255, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#363636',
        default: '#2D2D2D',
      },
      grey: {
        50: '#212121',
        100: '#424242',
        200: '#616161',
        300: '#757575',
        400: '#9E9E9E',
        500: '#BDBDBD',
        600: '#E0E0E0',
        700: '#EEEEEE',
        800: '#F5F5F5',
        900: '#FAFAFA',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      }
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#2896E6', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#2896E6', //primary.main
              },
            }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              "& > fieldset": {
                borderColor: '#2896E6!important', //primary.main
              },
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#2896E6', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#2896E6', //primary.main
              },
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#2896E6', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#2896E6', //primary.main
              },
            }
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: '#2896E6' //primary.main
            },
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: 'rgba(97,97,97,0.9)',
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            //borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
          }
        }
      },
    },
  },
  plds_light: {
    spacing: 8,
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#70AE26',
        dark: '#67A121',
        light: '#96D34D',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: '#70AE26', //primary.main
        hover: '#67A121', //primary.dark
      },
      secondary: {
        main: '#231F20',
        dark: '#000000',
        light: '#8D8D8D',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#000000',
      },
      navigation: {
        menuBg: '#222222',
        menuHover: 'rgba(255, 255, 255, 0.04)',
        menuActive: 'rgba(112, 174, 38, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#70AE26',
        headerBg: '#231F20',
        headerBorder: '#231F20',
        headerContrast: '#FFFFFF',
        headerContrastHover: 'rgba(255, 255, 255, 0.04)',
        gradientFrom: '#FFFFFF',
        gradientTo: '#FFFFFF',
        gradientActiveFrom: '#70AE26',
        gradientActiveTo: '#70AE26',
        scrollBg: 'rgba(0, 0, 0, 0.08)',
        scrollTrackBg: 'rgba(85, 85, 85,0.3)',
      },
      other: {
        divider: 'rgba(34, 34, 34, 0.12)',
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      divider: 'rgba(0, 0, 0, 0.12)',
      selected: {
        main: 'rgba(0,0,0,0.08)',
      },
      action: {
        active: 'rgba(0,0,0,0.54)',
        hover: 'rgba(0,0,0,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(0,0,0,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(0,0,0,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(0,0,0,0.12)',
        focus: 'rgba(0,0,0,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#FFFFFF',
        default: '#F7F7F8',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(0,0,0,0.54)', //primary.main
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            backgroundColor: 'rgba(0,0,0,0.54)', //primary.main
          }
        }
      },
    },
  },
  plds_dark: {
    spacing: 8,
    palette: {
      mode: 'dark',
      text: {
        primary: '#FFFFFF',
        secondary: '#AFAFAF',
        disabled: '#777575',
      },
      primary: {
        main: '#70AE26',
        dark: '#67A121',
        light: '#96D34D',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: '#70AE26', //primary.main
        hover: '#67A121', //primary.dark
      },
      secondary: {
        main: '#F6F9FF',
        dark: '#E0E4EF',
        light: '#FFFFFF',
        contrastText: 'rgba(0,0,0,0.87)'
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      navigation: {
        menuBg: '#231F20',
        menuHover: 'rgba(255, 255, 255, 0.04)',
        menuActive: 'rgba(112, 174, 38, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#70AE26',
        headerBg: '#231F20',
        headerBorder: '#231F20',
        headerContrast: '#FFFFFF',
        headerContrastHover: 'rgba(255, 255, 255, 0.08)',
        gradientFrom: '#FFFFFF',
        gradientTo: '#FFFFFF',
        gradientActiveFrom: '#70AE26',
        gradientActiveTo: '#70AE26',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255,0.3)',
      },
      other: {
        divider: 'rgba(255, 255, 255, 0.12)',
        outlinedBorder: 'rgba(255, 255, 255, 0.23)',
        filledInputBackground: 'rgba(255, 255, 255, 0.06)',
        standardInputLine: 'rgba(255, 255, 255, 0.09)',
        snackbar: '#FFFFFF',
        ratingActive: '#FFB400',
        tooltip: 'rgba(97, 97, 97, 0.90)',
        searchBg: 'rgba(255, 255, 255, 0.15)',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      selected: {
        main: '#151515',
      },
      action: {
        active: 'rgba(255,255,255,0.54)', // primary.light
        hover: 'rgba(255,255,255,0.04)', // primary.dark
        hoverOpacity: 0.08,
        selected: 'rgba(255,255,255,0.08)',
        selectedOpacity: 0.16,
        disabled: 'rgba(255,255,255,0.26)', // text.disabled
        disabledOpacity: 0.38,
        disabledBackground: 'rgba(255,255,255, 0.12)',
        focus: 'rgba(255,255,255, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#363636',
        default: '#2D2D2D',
      },
      grey: {
        50: '#212121',
        100: '#424242',
        200: '#616161',
        300: '#757575',
        400: '#9E9E9E',
        500: '#BDBDBD',
        600: '#E0E0E0',
        700: '#EEEEEE',
        800: '#F5F5F5',
        900: '#FAFAFA',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      }
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#70AE26', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#70AE26', //primary.main
              },
            }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              "& > fieldset": {
                borderColor: '#70AE26!important', //primary.main
              },
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#70AE26', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#70AE26', //primary.main
              },
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#70AE26', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#70AE26', //primary.main
              },
            }
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: '#70AE26' //primary.main
            },
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: 'rgba(97,97,97,0.9)',
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            //borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
          }
        }
      },
    },
  },
  gca_light: {
    spacing: 8,
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#3634EE',
        dark: '#1B19D5',
        light: '#5E5CFB',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: '#3634EE', //primary.main
        hover: '#1B19D5', //primary.dark
      },
      secondary: {
        main: '#222222',
        dark: '#000000',
        light: '#3E3E3E',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#000000',
      },
      navigation: {
        menuBg: '#000000',
        menuHover: 'rgba(198, 239, 61, 0.08)',
        menuActive: 'rgba(198, 239, 61, 0.12)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#C6EF3D',
        headerBg: '#000000',
        headerBorder: '#000000',
        headerContrast: '#FFFFFF',
        headerContrastHover: 'rgba(255, 255, 255, 0.08)',
        gradientFrom: '#EEEEEE',
        gradientTo: '#EEEEEE',
        gradientActiveFrom: '#C6EF3D',
        gradientActiveTo: '#C6EF3D',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(34, 34, 34, 0.12)',
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      divider: 'rgba(34, 34, 34, 0.12)',
      selected: {
        main: 'rgba(0,0,0,0.08)',
      },
      action: {
        active: 'rgba(0,0,0,0.54)',
        hover: 'rgba(0,0,0,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(0,0,0,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(0,0,0,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(0,0,0,0.12)',
        focus: 'rgba(0,0,0,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#FFFFFF',
        default: '#F7F7F8',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(0,0,0,0.54)', //primary.main
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            backgroundColor: 'rgba(0,0,0,0.54)', //primary.main
          }
        }
      },
    },
  },
  gca_dark: {
    spacing: 8,
    palette: {
      mode: 'dark',
      text: {
        primary: '#FFFFFF',
        secondary: '#AFAFAF',
        disabled: '#777575',
      },
      primary: {
        main: '#C6EF3D',
        dark: '#ACD81B',
        light: '#DBFE69',
        contrastText: '#222222',
      },
      gradientButton: {
        background: '#C6EF3D', //primary.main
        hover: '#ACD81B', //primary.dark
      },
      secondary: {
        main: '#F6F9FF',
        dark: '#E0E4EF',
        light: '#FFFFFF',
        contrastText: 'rgba(0,0,0,0.87)'
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      navigation: {
        menuBg: '#000000',
        menuHover: 'rgba(198, 239, 61, 0.08)',
        menuActive: 'rgba(198, 239, 61, 0.12)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#C6EF3D',
        headerBg: '#000000',
        headerBorder: '#000000',
        headerContrast: '#FFFFFF',
        headerContrastHover: 'rgba(255, 255, 255, 0.08)',
        gradientFrom: '#EEEEEE',
        gradientTo: '#EEEEEE',
        gradientActiveFrom: '#C6EF3D',
        gradientActiveTo: '#C6EF3D',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(255, 255, 255, 0.12)',
        outlinedBorder: 'rgba(255, 255, 255, 0.23)',
        filledInputBackground: 'rgba(255, 255, 255, 0.06)',
        standardInputLine: 'rgba(255, 255, 255, 0.09)',
        snackbar: '#FFFFFF',
        ratingActive: '#FFB400',
        tooltip: 'rgba(97, 97, 97, 0.90)',
        searchBg: 'rgba(255, 255, 255, 0.15)',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      selected: {
        main: '#151515',
      },
      action: {
        active: 'rgba(255,255,255,0.54)', // primary.light
        hover: 'rgba(255,255,255,0.04)', // primary.dark
        hoverOpacity: 0.08,
        selected: 'rgba(255,255,255,0.08)',
        selectedOpacity: 0.16,
        disabled: 'rgba(255,255,255,0.26)', // text.disabled
        disabledOpacity: 0.38,
        disabledBackground: 'rgba(255,255,255, 0.12)',
        focus: 'rgba(255,255,255, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#151515',
        default: '#1E1D1D',
      },
      grey: {
        50: '#212121',
        100: '#424242',
        200: '#616161',
        300: '#757575',
        400: '#9E9E9E',
        500: '#BDBDBD',
        600: '#E0E0E0',
        700: '#EEEEEE',
        800: '#F5F5F5',
        900: '#FAFAFA',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      }
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#C6EF3D', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#C6EF3D', //primary.main
              },
            }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              "& > fieldset": {
                borderColor: '#C6EF3D !important', //primary.main
              },
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#C6EF3D', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#C6EF3D', //primary.main
              },
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#C6EF3D', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#C6EF3D', //primary.main
              },
            }
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: '#C6EF3D' //primary.main
            },
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: 'rgba(97,97,97,0.9)',
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            //borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
          }
        }
      },
    },
  },
  mlbr_light: {
    spacing: 8,
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#006A39',
        dark: '#01522C',
        light: '#05864A',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: '#006A39', //primary.main
        hover: '#01522C', //primary.dark
      },
      secondary: {
        main: '#222222',
        dark: '#000000',
        light: '#3E3E3E',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#000000',
      },
      navigation: {
        menuBg: '#016B39',
        menuHover: '#034425',
        menuActive: '#034425',
        menuContrast: '#EAF0ED',
        menuContrastActive: '#F7FCFA',
        headerBg: '#016B39',
        headerBorder: '#016B39',
        headerContrast: '#EAF0ED',
        headerContrastHover: 'rgba(253, 251, 251, 0.08)',
        gradientFrom: '#EAF0ED',
        gradientTo: '#EAF0ED',
        gradientActiveFrom: '#F7FCFA',
        gradientActiveTo: '#F7FCFA',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(34, 34, 34, 0.12)',
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      divider: 'rgba(34, 34, 34, 0.12)',
      selected: {
        main: 'rgba(0,0,0,0.08)',
      },
      action: {
        active: 'rgba(34,34,34,0.54)',
        hover: 'rgba(34,34,34,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(34,34,34,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(34,34,34,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(34,34,34,0.12)',
        focus: 'rgba(34,34,34,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#FFFFFF',
        default: '#F5F6F8',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(0,0,0,0.54)', //primary.main
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            backgroundColor: 'rgba(0,0,0,0.54)', //primary.main
          }
        }
      },
    },
  },
  mlbr_dark: {
    spacing: 8,
    palette: {
      mode: 'dark',
      text: {
        primary: '#FFFFFF',
        secondary: '#AFAFAF',
        disabled: '#777575',
      },
      primary: {
        main: '#006A39',
        dark: '#01522C',
        light: '#05864A',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: '#006A39', //primary.main
        hover: '#01522C', //primary.dark
      },
      secondary: {
        main: '#FAFCFF',
        dark: '#F0F3F8',
        light: '#FFFFFF',
        contrastText: '#000000',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#000000',
      },
      navigation: {
        menuBg: '#01522C',
        menuHover: '#016B39',
        menuActive: '#016B39',
        menuContrast: '#EAF0ED',
        menuContrastActive: '#F7FCFA',
        headerBg: '#01522C',
        headerBorder: '#01522C',
        headerContrast: '#EAF0ED',
        headerContrastHover: 'rgba(253, 251, 251, 0.08)',
        gradientFrom: '#EAF0ED',
        gradientTo: '#EAF0ED',
        gradientActiveFrom: '#F7FCFA',
        gradientActiveTo: '#F7FCFA',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(255, 255, 255, 0.12)',
        outlinedBorder: 'rgba(255, 255, 255, 0.23)',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      selected: {
        main: 'rgba(255,255,255,0.08)',
      },
      action: {
        active: 'rgba(255,255,255,0.54)',
        hover: 'rgba(255,255,255,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(255,255,255,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(255,255,255,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(255,255,255,0.12)',
        focus: 'rgba(255,255,255,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#1D2320',
        default: '#141414',
      },
      grey: {
        50: '#212121',
        100: '#424242',
        200: '#616161',
        300: '#757575',
        400: '#9E9E9E',
        500: '#BDBDBD',
        600: '#E0E0E0',
        700: '#EEEEEE',
        800: '#F5F5F5',
        900: '#FAFAFA',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#C6EF3D', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#C6EF3D', //primary.main
              },
            }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              "& > fieldset": {
                borderColor: '#C6EF3D !important', //primary.main
              },
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#C6EF3D', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#C6EF3D', //primary.main
              },
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#C6EF3D', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#C6EF3D', //primary.main
              },
            }
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: '#C6EF3D' //primary.main
            },
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: 'rgba(97,97,97,0.9)',
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            //borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
          }
        }
      },
    },
  },
  btp_light: {
    spacing: 8,
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#6C4AC2',
        dark: '#5325C5',
        light: '#AA89FF',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: '#6C4AC2', //primary.main
        hover: '#5325C5', //primary.dark
      },
      secondary: {
        main: '#222222',
        dark: '#000000',
        light: '#3E3E3E',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#000000',
      },
      navigation: {
        menuBg: '#070707',
        menuHover: 'rgba(232, 255, 0, 0.04)',
        menuActive: 'rgba(232, 255, 0, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#E8FF00',
        headerBg: '#070707',
        headerBorder: '#070707',
        headerContrast: '#FFFFFF',
        headerContrastHover: 'rgba(232, 255, 0, 0.08)',
        gradientFrom: '#FFFFFF',
        gradientTo: '#FFFFFF',
        gradientActiveFrom: '#E8FF00',
        gradientActiveTo: '#E8FF00',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(34, 34, 34, 0.12)',
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      divider: 'rgba(34, 34, 34, 0.12)',
      selected: {
        main: 'rgba(0,0,0,0.08)',
      },
      action: {
        active: 'rgba(34,34,34,0.54)',
        hover: 'rgba(34,34,34,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(34,34,34,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(34,34,34,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(34,34,34,0.12)',
        focus: 'rgba(34,34,34,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#FFFFFF',
        default: '#F5F6F8',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(0,0,0,0.54)', //primary.main
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            backgroundColor: 'rgba(0,0,0,0.54)', //primary.main
          }
        }
      },
    },
  },
  btp_dark: {
    spacing: 8,
    palette: {
      mode: 'dark',
      text: {
        primary: '#FFFFFF',
        secondary: '#AFAFAF',
        disabled: '#777575',
      },
      primary: {
        main: '#E8FF00',
        dark: '#D8EE00',
        light: '#EBFF24',
        contrastText: '#070707',
      },
      gradientButton: {
        background: '#E8FF00', //primary.main
        hover: '#D8EE00', //primary.dark
      },
      secondary: {
        main: '#FAFCFF',
        dark: '#F0F3F8',
        light: '#FFFFFF',
        contrastText: '#000000',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#000000',
      },
      navigation: {
        menuBg: '#070707',
        menuHover: 'rgba(232, 255, 0, 0.04)',
        menuActive: 'rgba(232, 255, 0, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#E8FF00',
        headerBg: '#070707',
        headerBorder: '#070707',
        headerContrast: '#FFFFFF',
        headerContrastHover: 'rgba(232, 255, 0, 0.08)',
        gradientFrom: '#FFFFFF',
        gradientTo: '#FFFFFF',
        gradientActiveFrom: '#E8FF00',
        gradientActiveTo: '#E8FF00',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(255, 255, 255, 0.12)',
        outlinedBorder: 'rgba(255, 255, 255, 0.23)',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      selected: {
        main: 'rgba(255,255,255,0.08)',
      },
      action: {
        active: 'rgba(255,255,255,0.54)',
        hover: 'rgba(255,255,255,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(255,255,255,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(255,255,255,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(255,255,255,0.12)',
        focus: 'rgba(255,255,255,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#151515',
        default: '#2D2D2D',
      },
      grey: {
        50: '#212121',
        100: '#424242',
        200: '#616161',
        300: '#757575',
        400: '#9E9E9E',
        500: '#BDBDBD',
        600: '#E0E0E0',
        700: '#EEEEEE',
        800: '#F5F5F5',
        900: '#FAFAFA',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#E8FF00', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#E8FF00', //primary.main
              },
            }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              "& > fieldset": {
                borderColor: '#E8FF00 !important', //primary.main
              },
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#E8FF00', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#E8FF00', //primary.main
              },
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#E8FF00', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#E8FF00', //primary.main
              },
            }
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: '#E8FF00' //primary.main
            },
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: 'rgba(97,97,97,0.9)',
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            //borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
          }
        }
      },
    },
  },
  hlp_light: {
    spacing: 8,
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#6936FE',
        dark: '#4C17E8',
        light: '#7F54FF',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: 'linear-gradient(90deg, #7132FF 0%, #00D7BB 100%)', //primary.main
        hover: 'rgba(0,0,0,0.10)', //primary.dark
      },
      secondary: {
        main: '#222222',
        dark: '#000000',
        light: '#3E3E3E',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#222222',
      },
      navigation: {
        menuBg: '#121129',
        menuHover: 'rgba(105, 54, 254, 0.5)',
        menuActive: 'rgba(105, 54, 254, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#6936FE',
        headerBg: '#121129',
        headerBorder: '#121129',
        headerContrast: 'rgba(255, 255, 255, 0.9)',
        headerContrastHover: 'rgba(255, 255, 255, 0.08)',
        gradientFrom: 'rgba(255, 255, 255, 0.88)',
        gradientTo: 'rgba(255, 255, 255, 0.88)',
        gradientActiveFrom: '#6936FE',
        gradientActiveTo: '#6936FE',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(34, 34, 34, 0.12)',
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      divider: 'rgba(34, 34, 34, 0.12)',
      selected: {
        main: 'rgba(0,0,0,0.08)',
      },
      action: {
        active: 'rgba(34,34,34,0.54)',
        hover: 'rgba(34,34,34,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(34,34,34,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(34,34,34,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(34,34,34,0.12)',
        focus: 'rgba(34,34,34,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#FFFFFF',
        default: '#F5F6F8',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(0,0,0,0.54)', //primary.main
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            backgroundColor: 'rgba(0,0,0,0.54)', //primary.main
          }
        }
      },
    },
  },
  hlp_dark: {
    spacing: 8,
    palette: {
      mode: 'dark',
      text: {
        primary: '#FFFFFF',
        secondary: '#AFAFAF',
        disabled: '#777575',
      },
      primary: {
        main: '#6936FE',
        dark: '#4C17E8',
        light: '#7F54FF',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: 'linear-gradient(90deg, #7132FF 0%, #00D7BB 100%)', //primary.main
        hover: 'rgba(0,0,0,0.10)', //primary.dark
      },
      secondary: {
        main: '#FAFCFF',
        dark: '#F6F6F6',
        light: '#FFFFFF',
        contrastText: '#000000',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#222222',
      },
      navigation: {
        menuBg: '#121129',
        menuHover: 'rgba(105, 54, 254, 0.5)',
        menuActive: 'rgba(105, 54, 254, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#6936FE',
        headerBg: '#121129',
        headerBorder: '#121129',
        headerContrast: 'rgba(255, 255, 255, 0.9)',
        headerContrastHover: 'rgba(255, 255, 255, 0.08)',
        gradientFrom: 'rgba(255, 255, 255, 0.88)',
        gradientTo: 'rgba(255, 255, 255, 0.88)',
        gradientActiveFrom: '#6936FE',
        gradientActiveTo: '#6936FE',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(255, 255, 255, 0.12)',
        outlinedBorder: 'rgba(255, 255, 255, 0.23)',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      selected: {
        main: 'rgba(255,255,255,0.08)',
      },
      action: {
        active: 'rgba(255,255,255,0.54)',
        hover: 'rgba(255,255,255,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(255,255,255,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(255,255,255,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(255,255,255,0.12)',
        focus: 'rgba(255,255,255,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#1A1A1A',
        default: '#0F0F0F',
      },
      grey: {
        50: '#212121',
        100: '#424242',
        200: '#616161',
        300: '#757575',
        400: '#9E9E9E',
        500: '#BDBDBD',
        600: '#E0E0E0',
        700: '#EEEEEE',
        800: '#F5F5F5',
        900: '#FAFAFA',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#6936FE', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#6936FE', //primary.main
              },
            }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              "& > fieldset": {
                borderColor: '#6936FE !important', //primary.main
              },
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#6936FE', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#6936FE', //primary.main
              },
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#6936FE', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#6936FE', //primary.main
              },
            }
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: '#6936FE' //primary.main
            },
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: 'rgba(97,97,97,0.9)',
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            //borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
          }
        }
      },
    },
  },
  lxa_light: {
    spacing: 8,
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#580151',
        dark: '#1C041C',
        light: '#BF95D2',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: '#580151', //primary.main
        hover: '#1C041C', //primary.dark
      },
      secondary: {
        main: '#222222',
        dark: '#000000',
        light: '#3E3E3E',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#222222',
      },
      navigation: {
        menuBg: '#1C041C',
        menuHover: 'rgba(255, 255, 255, 0.04)',
        menuActive: 'rgba(255, 255, 255, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#FFFFFF',
        headerBg: '#580151',
        headerBorder: '#580151',
        headerContrast: '#FFFFFF',
        headerContrastHover: 'rgba(255, 255, 255, 0.08)',
        gradientFrom: '#BF95D2',
        gradientTo: '#BF95D2',
        gradientActiveFrom: '#FFFFFF',
        gradientActiveTo: '#FFFFFF',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(34, 34, 34, 0.12)',
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      divider: 'rgba(34, 34, 34, 0.12)',
      selected: {
        main: 'rgba(0,0,0,0.08)',
      },
      action: {
        active: 'rgba(34,34,34,0.54)',
        hover: 'rgba(34,34,34,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(34,34,34,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(34,34,34,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(34,34,34,0.12)',
        focus: 'rgba(34,34,34,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#FFFFFF',
        default: '#F5F6F8',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(0,0,0,0.54)', //primary.main
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            backgroundColor: 'rgba(0,0,0,0.54)', //primary.main
          }
        }
      },
    },
  },
  lxa_dark: {
    spacing: 8,
    palette: {
      mode: 'dark',
      text: {
        primary: '#FFFFFF',
        secondary: '#AFAFAF',
        disabled: '#777575',
      },
      primary: {
        main: '#39DFD4',
        dark: '#10C4B8',
        light: '#61F1E7',
        contrastText: '#070707',
      },
      gradientButton: {
        background: '#39DFD4', //primary.main
        hover: '#10C4B8', //primary.dark
      },
      secondary: {
        main: '#FAFCFF',
        dark: '#F6F6F6',
        light: '#FFFFFF',
        contrastText: '#000000',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#222222',
      },
      navigation: {
        menuBg: '#1C041C',
        menuHover: 'rgba(255, 255, 255, 0.04)',
        menuActive: 'rgba(255, 255, 255, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#FFFFFF',
        headerBg: '#580151',
        headerBorder: '#580151',
        headerContrast: '#D8B7E7',
        headerContrastHover: 'rgba(216, 183, 231, 0.08)',
        gradientFrom: '#BF95D2',
        gradientTo: '#BF95D2',
        gradientActiveFrom: '#FFFFFF',
        gradientActiveTo: '#FFFFFF',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(255, 255, 255, 0.12)',
        outlinedBorder: 'rgba(255, 255, 255, 0.23)',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      selected: {
        main: 'rgba(255,255,255,0.08)',
      },
      action: {
        active: 'rgba(255,255,255,0.54)',
        hover: 'rgba(255,255,255,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(255,255,255,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(255,255,255,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(255,255,255,0.12)',
        focus: 'rgba(255,255,255,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#1C041C',
        default: '#2D1329',
      },
      grey: {
        50: '#212121',
        100: '#424242',
        200: '#616161',
        300: '#757575',
        400: '#9E9E9E',
        500: '#BDBDBD',
        600: '#E0E0E0',
        700: '#EEEEEE',
        800: '#F5F5F5',
        900: '#FAFAFA',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#39DFD4', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#39DFD4', //primary.main
              },
            }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              "& > fieldset": {
                borderColor: '#39DFD4 !important', //primary.main
              },
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#39DFD4', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#39DFD4', //primary.main
              },
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#39DFD4', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#39DFD4', //primary.main
              },
            }
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: '#39DFD4' //primary.main
            },
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: 'rgba(97,97,97,0.9)',
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            //borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
          }
        }
      },
    },
  },
  f1x2_light: {
    spacing: 8,
    palette: {
      mode: 'dark',
      text: {
        primary: '#FFFFFF',
        secondary: 'rgba(255, 255, 255, 0.5)',
        disabled: 'rgba(255, 255, 255, 0.4)',
      },
      primary: {
        main: '#F6EC00',
        dark: '#EAE102',
        light: '#FAF111',
        contrastText: '#070707',
      },
      gradientButton: {
        background: '#F6EC00', //primary.main
        hover: '#EAE102', //primary.dark
      },
      secondary: {
        main: '#FFFFFF',
        dark: '#F6F6F6',
        light: '#FFFFFF',
        contrastText: '#000000',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#222222',
      },
      navigation: {
        menuBg: '#054D4D',
        menuHover: 'rgba(1, 60, 60, 0.50)',
        menuActive: 'rgba(1, 60, 60, 1)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#FFFFFF',
        headerBg: '#08171A',
        headerBorder: '#08171A',
        headerContrast: 'rgba(255, 255, 255, 0.9)',
        headerContrastHover: 'rgba(255, 255, 255, 0.08)',
        gradientFrom: 'rgba(255, 255, 255, 0.88)',
        gradientTo: 'rgba(255, 255, 255, 0.88)',
        gradientActiveFrom: '#FFFFFF',
        gradientActiveTo: '#FFFFFF',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(255, 255, 255, 0.12)',
        outlinedBorder: 'rgba(255, 255, 255, 0.23)',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      selected: {
        main: 'rgba(255,255,255,0.08)',
      },
      action: {
        active: 'rgba(255,255,255,0.54)',
        hover: 'rgba(255,255,255,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(255,255,255,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(255,255,255,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(255,255,255,0.12)',
        focus: 'rgba(255,255,255,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#054D4D',
        default: '#013C3C',
      },
      grey: {
        50: '#212121',
        100: '#424242',
        200: '#616161',
        300: '#757575',
        400: '#9E9E9E',
        500: '#BDBDBD',
        600: '#E0E0E0',
        700: '#EEEEEE',
        800: '#F5F5F5',
        900: '#FAFAFA',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#F6EC00', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#F6EC00', //primary.main
              },
            }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              "& > fieldset": {
                borderColor: '#F6EC00 !important', //primary.main
              },
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#F6EC00', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#F6EC00', //primary.main
              },
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#F6EC00', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#F6EC00', //primary.main
              },
            }
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: '#F6EC00' //primary.main
            },
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: 'rgba(8,23,26,0.9)',
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            //borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
          }
        }
      },
    },
  },
  f1x2_dark: {
    spacing: 8,
    palette: {
      mode: 'dark',
      text: {
        primary: '#FFFFFF',
        secondary: 'rgba(255, 255, 255, 0.5)',
        disabled: 'rgba(255, 255, 255, 0.4)',
      },
      primary: {
        main: '#F6EC00',
        dark: '#EAE102',
        light: '#FAF111',
        contrastText: '#070707',
      },
      gradientButton: {
        background: '#F6EC00', //primary.main
        hover: '#EAE102', //primary.dark
      },
      secondary: {
        main: '#FFFFFF',
        dark: '#F6F6F6',
        light: '#FFFFFF',
        contrastText: '#000000',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#222222',
      },
      navigation: {
        menuBg: '#054D4D',
        menuHover: 'rgba(1, 60, 60, 0.50)',
        menuActive: 'rgba(1, 60, 60, 1)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#FFFFFF',
        headerBg: '#08171A',
        headerBorder: '#08171A',
        headerContrast: 'rgba(255, 255, 255, 0.9)',
        headerContrastHover: 'rgba(255, 255, 255, 0.08)',
        gradientFrom: 'rgba(255, 255, 255, 0.88)',
        gradientTo: 'rgba(255, 255, 255, 0.88)',
        gradientActiveFrom: '#FFFFFF',
        gradientActiveTo: '#FFFFFF',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(255, 255, 255, 0.12)',
        outlinedBorder: 'rgba(255, 255, 255, 0.23)',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      selected: {
        main: 'rgba(255,255,255,0.08)',
      },
      action: {
        active: 'rgba(255,255,255,0.54)',
        hover: 'rgba(255,255,255,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(255,255,255,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(255,255,255,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(255,255,255,0.12)',
        focus: 'rgba(255,255,255,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#054D4D',
        default: '#013C3C',
      },
      grey: {
        50: '#212121',
        100: '#424242',
        200: '#616161',
        300: '#757575',
        400: '#9E9E9E',
        500: '#BDBDBD',
        600: '#E0E0E0',
        700: '#EEEEEE',
        800: '#F5F5F5',
        900: '#FAFAFA',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#F6EC00', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#F6EC00', //primary.main
              },
            }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              "& > fieldset": {
                borderColor: '#F6EC00 !important', //primary.main
              },
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#F6EC00', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#F6EC00', //primary.main
              },
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#F6EC00', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#F6EC00', //primary.main
              },
            }
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: '#F6EC00' //primary.main
            },
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: 'rgba(8,23,26,0.9)',
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            //borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
          }
        }
      },
    },
  },
  mbp_light: {
    spacing: 8,
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#E72786',
        dark: '#DB1074',
        light: '#F73C99',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: '#E72786', //primary.main
        hover: '#DB1074', //primary.dark
      },
      secondary: {
        main: '#222222',
        dark: '#000000',
        light: '#3E3E3E',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#222222',
      },
      navigation: {
        menuBg: '#1A1A1A',
        menuHover: 'rgba(219, 16, 116, 0.5)',
        menuActive: 'rgba(219, 16, 116, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#DB1074',
        headerBg: '#1A1A1A',
        headerBorder: '#1A1A1A',
        headerContrast: '#FFFFFF',
        headerContrastHover: 'rgba(255, 255, 255, 0.08)',
        gradientFrom: 'rgba(255, 255, 255, 0.88)',
        gradientTo: 'rgba(255, 255, 255, 0.88)',
        gradientActiveFrom: '#DB1074',
        gradientActiveTo: '#DB1074',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(34, 34, 34, 0.12)',
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      divider: 'rgba(34, 34, 34, 0.12)',
      selected: {
        main: 'rgba(0,0,0,0.08)',
      },
      action: {
        active: 'rgba(34,34,34,0.54)',
        hover: 'rgba(34,34,34,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(34,34,34,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(34,34,34,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(34,34,34,0.12)',
        focus: 'rgba(34,34,34,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#FFFFFF',
        default: '#F5F6F8',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(0,0,0,0.54)', //primary.main
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            backgroundColor: 'rgba(0,0,0,0.54)', //primary.main
          }
        }
      },
    },
  },
  mbp_dark: {
    spacing: 8,
    palette: {
      mode: 'dark',
      text: {
        primary: '#FFFFFF',
        secondary: '#AFAFAF',
        disabled: '#777575',
      },
      primary: {
        main: '#E72786',
        dark: '#DB1074',
        light: '#F73C99',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: '#E72786', //primary.main
        hover: '#DB1074', //primary.dark
      },
      secondary: {
        main: '#FAFCFF',
        dark: '#F6F6F6',
        light: '#FFFFFF',
        contrastText: '#000000',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#222222',
      },
      navigation: {
        menuBg: '#1A1A1A',
        menuHover: 'rgba(219, 16, 116, 0.5)',
        menuActive: 'rgba(219, 16, 116, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#DB1074',
        headerBg: '#1A1A1A',
        headerBorder: '#1A1A1A',
        headerContrast: '#FFFFFF',
        headerContrastHover: 'rgba(255, 255, 255, 0.08)',
        gradientFrom: 'rgba(255, 255, 255, 0.88)',
        gradientTo: 'rgba(255, 255, 255, 0.88)',
        gradientActiveFrom: '#DB1074',
        gradientActiveTo: '#DB1074',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(255, 255, 255, 0.12)',
        outlinedBorder: 'rgba(255, 255, 255, 0.23)',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      selected: {
        main: 'rgba(255,255,255,0.08)',
      },
      action: {
        active: 'rgba(255,255,255,0.54)',
        hover: 'rgba(255,255,255,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(255,255,255,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(255,255,255,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(255,255,255,0.12)',
        focus: 'rgba(255,255,255,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#1A1A1A',
        default: '#0F0F0F',
      },
      grey: {
        50: '#212121',
        100: '#424242',
        200: '#616161',
        300: '#757575',
        400: '#9E9E9E',
        500: '#BDBDBD',
        600: '#E0E0E0',
        700: '#EEEEEE',
        800: '#F5F5F5',
        900: '#FAFAFA',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#E72786', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#E72786', //primary.main
              },
            }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              "& > fieldset": {
                borderColor: '#E72786 !important', //primary.main
              },
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#E72786', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#E72786', //primary.main
              },
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#E72786', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#E72786', //primary.main
              },
            }
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: '#E72786' //primary.main
            },
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: 'rgba(97,97,97,0.9)',
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            //borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
          }
        }
      },
    },
  },
  sngp_light: {
    spacing: 8,
    palette: {
      mode: 'dark',
      text: {
        primary: '#FFFFFF',
        secondary: '#AFAFAF',
        disabled: '#777575',
      },
      primary: {
        main: '#51A63B',
        dark: '#7CCF49',
        light: '#7CCF49',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: '#51A63B', //primary.main
        hover: '#7CCF49', //primary.dark
      },
      secondary: {
        main: '#FAFCFF',
        dark: '#F6F6F6',
        light: '#FFFFFF',
        contrastText: '#000000',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#222222',
      },
      navigation: {
        menuBg: '#1F1C1D',
        menuHover: 'rgba(81, 166, 59, 0.5)',
        menuActive: 'rgba(81, 166, 59, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#51A63B',
        headerBg: '#1F1C1D',
        headerBorder: '#1F1C1D',
        headerContrast: 'rgba(255, 255, 255, 0.9)',
        headerContrastHover: 'rgba(255, 255, 255, 0.08)',
        gradientFrom: 'rgba(255, 255, 255, 0.88)',
        gradientTo: 'rgba(255, 255, 255, 0.88)',
        gradientActiveFrom: '#51A63B',
        gradientActiveTo: '#51A63B',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(255, 255, 255, 0.12)',
        outlinedBorder: 'rgba(255, 255, 255, 0.23)',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      selected: {
        main: 'rgba(255,255,255,0.08)',
      },
      action: {
        active: 'rgba(255,255,255,0.54)',
        hover: 'rgba(255,255,255,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(255,255,255,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(255,255,255,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(255,255,255,0.12)',
        focus: 'rgba(255,255,255,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#1F1C1D',
        default: '#131011',
      },
      grey: {
        50: '#212121',
        100: '#424242',
        200: '#616161',
        300: '#757575',
        400: '#9E9E9E',
        500: '#BDBDBD',
        600: '#E0E0E0',
        700: '#EEEEEE',
        800: '#F5F5F5',
        900: '#FAFAFA',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#51A63B', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#51A63B', //primary.main
              },
            }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              "& > fieldset": {
                borderColor: '#51A63B !important', //primary.main
              },
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#51A63B', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#51A63B', //primary.main
              },
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#51A63B', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#51A63B', //primary.main
              },
            }
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: '#51A63B' //primary.main
            },
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: 'rgba(97,97,97,0.9)',
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            //borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
          }
        }
      },
    },
  },
  sngp_dark: {
    spacing: 8,
    palette: {
      mode: 'dark',
      text: {
        primary: '#FFFFFF',
        secondary: '#AFAFAF',
        disabled: '#777575',
      },
      primary: {
        main: '#51A63B',
        dark: '#7CCF49',
        light: '#7CCF49',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: '#51A63B', //primary.main
        hover: '#7CCF49', //primary.dark
      },
      secondary: {
        main: '#FAFCFF',
        dark: '#F6F6F6',
        light: '#FFFFFF',
        contrastText: '#000000',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#222222',
      },
      navigation: {
        menuBg: '#1F1C1D',
        menuHover: 'rgba(81, 166, 59, 0.5)',
        menuActive: 'rgba(81, 166, 59, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#51A63B',
        headerBg: '#1F1C1D',
        headerBorder: '#1F1C1D',
        headerContrast: 'rgba(255, 255, 255, 0.9)',
        headerContrastHover: 'rgba(255, 255, 255, 0.08)',
        gradientFrom: 'rgba(255, 255, 255, 0.88)',
        gradientTo: 'rgba(255, 255, 255, 0.88)',
        gradientActiveFrom: '#51A63B',
        gradientActiveTo: '#51A63B',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(255, 255, 255, 0.12)',
        outlinedBorder: 'rgba(255, 255, 255, 0.23)',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      selected: {
        main: 'rgba(255,255,255,0.08)',
      },
      action: {
        active: 'rgba(255,255,255,0.54)',
        hover: 'rgba(255,255,255,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(255,255,255,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(255,255,255,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(255,255,255,0.12)',
        focus: 'rgba(255,255,255,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#1F1C1D',
        default: '#131011',
      },
      grey: {
        50: '#212121',
        100: '#424242',
        200: '#616161',
        300: '#757575',
        400: '#9E9E9E',
        500: '#BDBDBD',
        600: '#E0E0E0',
        700: '#EEEEEE',
        800: '#F5F5F5',
        900: '#FAFAFA',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#51A63B', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#51A63B', //primary.main
              },
            }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              "& > fieldset": {
                borderColor: '#51A63B !important', //primary.main
              },
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#51A63B', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#51A63B', //primary.main
              },
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#51A63B', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#51A63B', //primary.main
              },
            }
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: '#51A63B' //primary.main
            },
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: 'rgba(97,97,97,0.9)',
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            //borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
          }
        }
      },
    },
  },
  afbr_light: {
    spacing: 8,
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#FD6440',
        dark: '#FF7A1F',
        light: '#FF9162',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: '#FD6440', //primary.main
        hover: '#FF7A1F', //primary.dark
      },
      secondary: {
        main: '#222222',
        dark: '#000000',
        light: '#3E3E3E',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#222222',
      },
      navigation: {
        menuBg: '#141414',
        menuHover: 'rgba(253, 100, 64, 0.04)',
        menuActive: 'rgba(253, 100, 64, 0.08)',
        menuContrast: 'rgba(255, 255, 255, 0.88)',
        menuContrastActive: '#FD6440',
        headerBg: '#141414',
        headerBorder: '#141414',
        headerContrast: 'rgba(255, 255, 255, 0.88)',
        headerContrastHover: 'rgba(255, 255, 255, 0.08)',
        gradientFrom: 'rgba(255, 255, 255, 0.88)',
        gradientTo: 'rgba(255, 255, 255, 0.88)',
        gradientActiveFrom: '#FD6440',
        gradientActiveTo: '#FD6440',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(34, 34, 34, 0.12)',
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      divider: 'rgba(34, 34, 34, 0.12)',
      selected: {
        main: 'rgba(0,0,0,0.08)',
      },
      action: {
        active: 'rgba(34,34,34,0.54)',
        hover: 'rgba(34,34,34,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(34,34,34,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(34,34,34,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(34,34,34,0.12)',
        focus: 'rgba(34,34,34,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#FFFFFF',
        default: '#E2E2E8',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(0,0,0,0.54)', //primary.main
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(0,0,0,0.54)', //primary.main
              },
            },
          },
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            backgroundColor: 'rgba(0,0,0,0.54)', //primary.main
          }
        }
      },
    },
  },
  afbr_dark: {
    spacing: 8,
    palette: {
      mode: 'dark',
      text: {
        primary: '#FFFFFF',
        secondary: '#AFAFAF',
        disabled: '#777575',
      },
      primary: {
        main: '#FD6440',
        dark: '#FF7A1F',
        light: '#FF9162',
        contrastText: '#FFFFFF',
      },
      gradientButton: {
        background: '#FD6440', //primary.main
        hover: '#FF7A1F', //primary.dark
      },
      secondary: {
        main: '#FAFCFF',
        dark: '#F6F6F6',
        light: '#FFFFFF',
        contrastText: '#000000',
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#FF9800',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#222222',
      },
      navigation: {
        menuBg: '#141414',
        menuHover: 'rgba(253, 100, 64, 0.04)',
        menuActive: 'rgba(253, 100, 64, 0.08)',
        menuContrast: 'rgba(255, 255, 255, 0.88)',
        menuContrastActive: '#FD6440',
        headerBg: '#141414',
        headerBorder: '#141414',
        headerContrast: 'rgba(255, 255, 255, 0.88)',
        headerContrastHover: 'rgba(255, 255, 255, 0.08)',
        gradientFrom: 'rgba(255, 255, 255, 0.88)',
        gradientTo: 'rgba(255, 255, 255, 0.88)',
        gradientActiveFrom: '#FD6440',
        gradientActiveTo: '#FD6440',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(255, 255, 255, 0.12)',
        outlinedBorder: 'rgba(255, 255, 255, 0.23)',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      selected: {
        main: 'rgba(255,255,255,0.08)',
      },
      action: {
        active: 'rgba(255,255,255,0.54)',
        hover: 'rgba(255,255,255,0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(255,255,255,0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(255,255,255,0.26)',
        disabledOpacity: 0.26,
        disabledBackground: 'rgba(255,255,255,0.12)',
        focus: 'rgba(255,255,255,0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#141414',
        default: '#000000',
      },
      grey: {
        50: '#212121',
        100: '#424242',
        200: '#616161',
        300: '#757575',
        400: '#9E9E9E',
        500: '#BDBDBD',
        600: '#E0E0E0',
        700: '#EEEEEE',
        800: '#F5F5F5',
        900: '#FAFAFA',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Roboto', 'Arial', sans-serif`,
      //fontFamily: `'Wix Madefor Display', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#FD6440', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#FD6440', //primary.main
              },
            }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              "& > fieldset": {
                borderColor: '#FD6440 !important', //primary.main
              },
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#FD6440', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#FD6440', //primary.main
              },
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#FD6440', //primary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: 'rgba(255,255,255,1)', //primary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#FD6440', //primary.main
              },
            }
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: '#FD6440' //primary.main
            },
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: 'rgba(97,97,97,0.9)',
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            //borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
          }
        }
      },
    },
  },
  bbpa_light: {
    spacing: 8,
    palette: {
      mode: 'light',
      text: {
        primary: '#292621',
        secondary: '#403C34',
        disabled: '#BDB8AD',
      },
      primary: {
        main: '#222222',
        light: '#3E3E3E',
        contrastText: '#FFFFFF',
        dark: '#0E0E0E',
      },
      gradientButton: {
        background: '#222222', //primary.main
        hover: '#0E0E0E', //primary.dark
      },
      secondary: {
        main: '#231F20',
        light: '#8D8D8D',
        dark: '#000',
        contrastText: '#FFFFFF'
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#F57C00',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      navigation: {
        menuBg: '#222222',
        menuHover: 'rgba(255, 255, 255, 0.04)',
        menuActive: 'rgba(248, 255, 19, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#F8FF13',
        headerBg: '#222222',
        headerBorder: '#222222',
        headerContrast: '#FFFFFF',
        headerContrastHover: '#EDEDED',
        gradientFrom: '#FFFFFF',
        gradientTo: '#FFFFFF',
        gradientActiveFrom: '#F8FF13',
        gradientActiveTo: '#F8FF13',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        outlinedBorder: 'rgba(0, 0, 0, 0.23)',
      },
      selected: {
        main: 'rgba(0, 0, 0, 0.08)',
      },
      background: {
        paper: '#FFFFFF',
        default: '#F7F7F8',
      },
      grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEEEEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
      }
    },
    typography: {
      fontFamily: `'Arimo', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
    },
  },
  bbpa_dark: {
    spacing: 8,
    palette: {
      mode: 'dark',
      text: {
        primary: '#FFFFFF',
        secondary: '#AFAFAF',
        disabled: '#777575',
      },
      primary: {
        main: '#F8FF13',
        light: '#FBFF82',
        contrastText: '#000000',
        dark: '#E6EC0C',
      },
      gradientButton: {
        background: '#F8FF13', //primary.main
        hover: '#E6EC0C', //primary.dark
      },
      secondary: {
        main: '#F6F9FF',
        light: '#FFFFFF',
        dark: '#E0E4EF',
        contrastText: '#000000'
      },
      error: {
        main: '#F44336',
        dark: '#D32F2F',
        light: '#E57373',
        contrastText: '#FFFFFF'
      },
      warning: {
        main: '#FF9800',
        dark: '#F57C00',
        light: '#FFE0B2',
        contrastText: '#FFFFFF',
      },
      info: {
        main: '#03A9F4',
        dark: '#0288D1',
        light: '#B3E5FC',
        contrastText: '#FFFFFF',
      },
      success: {
        main: '#8BC34A',
        dark: '#689F38',
        light: '#DCEDC8',
        contrastText: '#FFFFFF',
      },
      white: {
        main: '#fff',
        contrastText: '#ffffff',
      },
      common: {
        white: '#FFFFFF',
        black: '#222222',
      },
      navigation: {
        menuBg: '#222222',
        menuHover: 'rgba(255, 255, 255, 0.04)',
        menuActive: 'rgba(248, 255, 19, 0.08)',
        menuContrast: '#FFFFFF',
        menuContrastActive: '#F8FF13',
        headerBg: '#222222',
        headerBorder: '#222222',
        headerContrast: '#FFFFFF',
        headerContrastHover: 'rgba(237, 237, 237, 0.08)',
        gradientFrom: '#FFFFFF',
        gradientTo: '#FFFFFF',
        gradientActiveFrom: '#F8FF13',
        gradientActiveTo: '#F8FF13',
        scrollBg: 'rgba(255, 255, 255, 0.08)',
        scrollTrackBg: 'rgba(255, 255, 255, 0.3)',
      },
      other: {
        divider: 'rgba(255, 255, 255, 0.12)',
        outlinedBorder: 'rgba(255, 255, 255, 0.23)',
        filledInputBackground: 'rgba(255, 255, 255, 0.06)',
        standardInputLine: 'rgba(255, 255, 255, 0.09)',
        snackbar: '#FFFFFF',
        ratingActive: '#FFB400',
        tooltip: 'rgba(97, 97, 97, 0.90)',
        searchBg: 'rgba(255, 255, 255, 0.15)',
      },
      divider: 'rgba(255, 255, 255, 0.12)',
      selected: {
        main: 'rgba(255, 255, 255, 0.08)',
      },
      action: {
        active: 'rgba(255,255,255,0.54)', // primary.light
        hover: 'rgba(255,255,255,0.04)', // primary.dark
        hoverOpacity: 0.08,
        selected: 'rgba(255,255,255,0.08)',
        selectedOpacity: 0.16,
        disabled: 'rgba(255,255,255,0.26)', // text.disabled
        disabledOpacity: 0.38,
        disabledBackground: 'rgba(255,255,255, 0.12)',
        focus: 'rgba(255,255,255, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24,
      },
      background: {
        paper: '#282727',
        default: '#1E1D1D',
      },

      grey: {
        50: '#212121',
        100: '#424242',
        200: '#616161',
        300: '#757575',
        400: '#9E9E9E',
        500: '#BDBDBD',
        600: '#E0E0E0',
        700: '#EEEEEE',
        800: '#F5F5F5',
        900: '#FAFAFA',
        A100: '#D5D5D5',
        A200: '#AAAAAA',
        A400: '#616161',
        A700: '#303030',
      },
    },
    typography: {
      fontFamily: `'Arimo', 'Arial', sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1200,
        xl: 1440,
        xlg: 1272,
      }
    },
    components: {
      MuiButton: MuiButtonsOverrides,
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#F6F9FF', //secondary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: '#F6F9FF', //secondary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#F6F9FF', //secondary.main
              },
            }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              "& > fieldset": {
                borderColor: '#F6F9FF!important', //secondary.main
              },
            },

            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#F6F9FF', //secondary.main
                }
              },
              "&:hover": {
                "& > fieldset": {
                  borderColor: '#F6F9FF', //secondary.main
                }
              },
            },
            "& > label": {
              "&.Mui-focused": {
                color: '#F6F9FF', //secondary.main
              },
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "& .MuiSelect-select": {
              "& > fieldset": {
                borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
              },
              "&.Mui-focused": {
                "& > fieldset": {
                  borderColor: '#F6F9FF', //secondary.main
                }
              }
            },
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: '#F6F9FF' //secondary.main
            },
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            background: 'rgba(97,97,97,0.9)',
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          track: {
            //borderColor: 'rgba(255, 255, 255, 0.23)', //other.outlinedBorder
          }
        }
      },
      /*MuiAppBar: {
        styleOverrides: {
          root: {
            background: 'primary.main',
          }
        }
      },*/
    },
  },
}

const theme: Theme = (() => {
  const currentTheme = createTheme(themes[appTheme]);

  return currentTheme;
})();

export default {...theme};
