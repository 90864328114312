//core
import * as React from "react";
import {useField} from "formik";
import {Checkbox, FormControlLabel} from "@mui/material";

//styles
import { useStyles } from "./styles/style";

//types
interface IMuiCheckbox {
  id: string,
  name: string
  label?: string | number | React.ReactElement | undefined | null | any,
  value: boolean,
  required?: boolean,
  smallFont?: boolean,
  disabled?: boolean,
  validate?: (value: any) => any,
  size?: 'small' | 'medium' | 'large',
  onChange: React.ChangeEventHandler<HTMLInputElement>,
}

export const MuiCheckbox = (props: IMuiCheckbox) => {
  const styles = useStyles();
  const [field, meta] = useField(props.name);

  const {
    id,
    name,
    label,
    value,
    onChange,
    required,
    disabled,
    smallFont = false,
    size = 'medium',
  } = props;

  return (
    <div className={styles.formField}>
      <FormControlLabel
        className={smallFont ? styles.smallFont : ''}
        control={
          <Checkbox
            { ...field }
            id={id}
            required={required}
            disabled={disabled}
            checked={value}
            size={size}
            onChange={onChange}
            color={ meta.touched && meta.error && meta.error !== 'error' ? 'warning' : 'primary' }
          />
        }
        // @ts-ignore
        label={label}
        //error={meta.touched && meta.error && meta.error !== 'error'}
      />
    </div>
  );
};
