//core
import React from 'react';
import {useTranslation} from "react-i18next";
import arePropsEqual from "react-fast-compare";
import {Chip} from "@mui/material";

//theme
import {Theme} from "@mui/material/styles/createTheme";
import {useTheme} from "@mui/styles";


interface IStatus {
  value: string;
  isBlocked: boolean;
}

const PartnerStatus = ({value, isBlocked = false}: IStatus) => {
  const theme: Theme = useTheme();
  const {t} = useTranslation();

  return (
    <>
      {isBlocked ? (<>
        <Chip size="small" label={t("common.components.status.blocked")} color="error"/>
      </>) : (<>
        {value === "awaiting" && <Chip size="small" label={t("common.components.status.new")} color="primary" sx={{backgroundColor: '#44B2E8'}}/>}
        {value === "approved" && <Chip size="small" label={t("common.components.partners_status.approved")} color="primary" sx={{backgroundColor: theme.palette.success.main}}/>}
        {value === "denied" && <Chip size="small" label={t("common.components.status.declined")} color="default"/>}
        {value === "status_active" && <Chip size="small" label={t("admin.creatives.form.status.options.status_active")} color="primary" sx={{backgroundColor: theme.palette.success.main}}/>}
        {value === "status_not_active" && <Chip size="small" label={t("admin.creatives.form.status.options.status_not_active")} color="primary" sx={{backgroundColor: theme.palette.text.disabled}}/>}
      </>)}
    </>
  );
};

export default React.memo(PartnerStatus, arePropsEqual)
