//core
import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {format} from "date-fns";
import enLocale from "date-fns/locale/en-US";
import ruLocale from "date-fns/locale/ru";
import ukLocale from "date-fns/locale/uk";

interface IDateTime {
  value: any;
}

type itemLocale = {
  locale: object,
  format: string,
}

const localeMap = {
  en: {
    locale: enLocale,
    format: 'MM/dd/yyyy'
  },
  ru: {
    locale: ruLocale,
    format: 'dd.MM.yyyy'
  },
  uk: {
    locale: ukLocale,
    format: 'dd.MM.yyyy'
  },
};

const useStyles = makeStyles({
  text: {
    color: 'inherit'
  }
});

const DateTime = ({ value }: IDateTime) => {
  const styles = useStyles();
  const { i18n } = useTranslation();

  const [locale, setLocale] = useState<itemLocale>(localeMap.en);

  useEffect(() => {
    if (i18n.language === 'ru') {
      setLocale(localeMap.ru);
    }

    if (i18n.language === 'en') {
      setLocale(localeMap.en);
    }

    if (i18n.language === 'uk') {
      setLocale(localeMap.uk);
    }

  }, [i18n.language]);

  return (
    <span className={styles.text}>
      {
        !!value ? format(new Date(value), locale.format, {locale: locale.locale}) : '—'
      }
    </span>
  );
};

export default DateTime;
