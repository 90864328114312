import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    '&:not(:last-child)': {
      marginRight: 8,
    },
  },

  block: {
    padding: 0,
    '@media (max-width: 767px)': {},
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 4,
    minHeight: 30,
  },
  title: {
    fontWeight: 700,
  },

  list: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: 4,
  },

  chip: {
    maxWidth: 200,
    '&:not(:last-child)': {
      marginRight: 4,
    },
  },

});
