//core
import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Chip } from "@mui/material";

//styles
import { useStyles } from "./styles";

import { ISelectedData } from "../SelectedFiltersWrapper";

interface ISelectedFieldProps {
  data: ISelectedData;
  name: string;
  exceptions?: string[],
  setActiveField: (field: string) => void;
  onDelete?: (id: string | number, fieldName: string, index: number) => void;
}

const SelectedField: React.FC<ISelectedFieldProps> = ({
  data,
  name,
  setActiveField,
  exceptions = [],
  onDelete,
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <Box className={`${styles.wrapper}`}>
      <Box className={`${styles.block} `}>
        <Box className={styles.header}>
          <Typography className={styles.title} variant="subtitle2">
            {t(`admin.reports.filter.${data.name}`)}
          </Typography>
        </Box>
        <Box className={styles.list}>
          {data.data.map((item, i) => {
            return (
              <Chip
                key={`${item.id}-${i}`}
                className={styles.chip}
                label={
                  !!data.prefix
                    ? `${t(`${data.prefix}${item.id}`)}`
                    : !exceptions?.includes(name)
                      ? `${
                          (name === 'affiliateIds' || name === 'managerIds' || name === 'trackerIds' || name === 'creativeIds' || name === 'brands') 
                            ? `${item.id} - ` 
                            : ''}${item.value
                        }`
                      : `${item.id}-${item.value}`
                }
                variant="filled"
                size="small"
                onClick={() => setActiveField(data.name)}
                { ...(typeof onDelete === 'function' ? { onDelete: () => onDelete(item.id, name, i) } : {}) }
              />
            )
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default SelectedField;
