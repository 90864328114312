//core
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { setUser } from "../../common/user";
import {
  setCreateCreativeState,
  setGeneralProgressHide,
  setGeneralProgressShow,
  setGeneralSnackbarState
} from "../../common/ui";
import {accountService} from "../../../services/admin/account.service";

export type accountSliceState = {
  account: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  updateAccount: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  changePassword: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
};

const initialState: accountSliceState = {
  account: {
    data: null,
    error: null,
    isFetching: false,
  },
  updateAccount: {
    data: null,
    error: null,
    isFetching: false,
  },
  changePassword: {
    data: null,
    error: null,
    isFetching: false,
  },
};

export const getAccountData: any = createAsyncThunk(
  'adm/account/getAccountData',
  async (payload:any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await accountService.getAccount();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const updateAccountData: any = createAsyncThunk(
  'adm/account/updateAccountData',
  async (payload:any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await accountService.updateAccount(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setCreateCreativeState(false));
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const changePasswordData: any = createAsyncThunk(
  'adm/account/changePasswordData',
  async (payload:any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await accountService.changePassword(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


//slice
const accountSlice = createSlice({
  name: 'adm/account',
  initialState: initialState,
  reducers: {
    clearAccountState(state) {
      state.account.data = null;
      state.account.error = null;
      state.account.isFetching = false;
    },
    clearChangePasswordState(state) {
      state.changePassword.data = null;
      state.changePassword.error = null;
      state.changePassword.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<accountSliceState>>) => {
    builder.addCase(getAccountData.pending, (state ) => {
      state.account.isFetching = true;
      state.account.error = null;
    });
    builder.addCase(getAccountData.fulfilled, (state , action) => {
      state.account.error = null;
      state.account.isFetching = false;
      state.account.data = action.payload;
    });
    builder.addCase(getAccountData.rejected, (state , action) => {
      state.account.isFetching = false;
      state.account.error = action.payload;
    });

    builder.addCase(updateAccountData.pending, (state ) => {
      state.updateAccount.isFetching = true;
      state.updateAccount.error = null;
    });
    builder.addCase(updateAccountData.fulfilled, (state , action) => {
      state.updateAccount.error = null;
      state.updateAccount.isFetching = false;
      state.updateAccount.data = action.payload;
    });
    builder.addCase(updateAccountData.rejected, (state , action) => {
      state.updateAccount.isFetching = false;
      state.updateAccount.error = action.payload;
    });

    builder.addCase(changePasswordData.pending, (state ) => {
      state.changePassword.isFetching = true;
      state.changePassword.error = null;
    });
    builder.addCase(changePasswordData.fulfilled, (state , action) => {
      state.changePassword.error = null;
      state.changePassword.isFetching = false;
      state.changePassword.data = action.payload;
    });
    builder.addCase(changePasswordData.rejected, (state , action) => {
      state.changePassword.isFetching = false;
      state.changePassword.error = action.payload;
    });

  },
});

export default accountSlice.reducer;

export const {
  clearAccountState,
  clearChangePasswordState,
} = accountSlice.actions;
