//core
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

//styles
import { useStyles } from "./styles";


import { IEmployeesFilterData } from "../../../DesktopReport";
import SelectedField from "../SelectedField";

interface IDataItem {
  [key: string]: any;
}

export interface ISelectedData {
  name: string;
  data: IDataItem[];
  prefix: string;
}

interface ISelectedFiltersProps {
  initialFilter: IEmployeesFilterData;
  setActiveField: (field: string) => void;
  onDeleteOption: (id: string | number, fieldName: string) => void;
}

const fieldsPrefix: IDataItem = {
  departments: "",
  status: "admin.employees.form.status.options.",
  //company: "",
  roles: "",
  platforms: "",
};

const SelectedFiltersWrapper: React.FC<ISelectedFiltersProps> = ({
  initialFilter,
  setActiveField,
  onDeleteOption,
}): JSX.Element => {
  const styles = useStyles();

  const [selectedData, setSelectedData] = useState<ISelectedData[] | null>(null);

  useEffect(() => {
    const data = Object.keys(initialFilter).reduce((acc: ISelectedData[], item): ISelectedData[] => {
      if (Array.isArray(initialFilter[item]) && initialFilter[item].length !== 0 || (initialFilter[item] != '') && !(initialFilter[item] === null)) {
        acc.push({
          name: item,
          data: Array.isArray(initialFilter[item]) ? initialFilter[item] : [initialFilter[item]],
          prefix: `${fieldsPrefix[item]}`,
        })
      }

      return acc;
    }, []);

    setSelectedData(data);
  }, [initialFilter]);

  return (
    <>
      {!!selectedData && (
        <Box className={styles.block}>
          {selectedData.map((item) => (
            <SelectedField
              key={item.name}
              name={item.name}
              data={item}
              setActiveField={setActiveField}
              onDelete={onDeleteOption}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default SelectedFiltersWrapper;
