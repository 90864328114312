//core
import React from 'react';
import { useTranslation } from "react-i18next";
import { Checkbox } from '@mui/material';

//icons
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

//hooks
import { useUI } from "../../../../store/common/ui/useUI";


interface IFavoriteCheckboxFieldProps {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  fieldName: string;
}

const FavoriteCheckboxField: React.FC<IFavoriteCheckboxFieldProps> = ({
  checked,
  setChecked,
  fieldName,
}): JSX.Element => {
  const { t } = useTranslation();
  const { ui, setGeneralSnackbar } = useUI();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGeneralSnackbar({...ui.generalSnackbar, open: false });

    const value = event.target.checked;
    setChecked(value);

    if (value) {
      setTimeout(() => {
        setGeneralSnackbar({
          open: true,
          type: 'success',
          message: `${t("common.messages.success_add_to_favorite_filter_field").replace('{fieldName}', fieldName)}`,
          messageKey: '',
        })
      }, 200);
    }
  };

  return (
    <>
      <Checkbox
        size="small"
        icon={<StarBorderIcon />}
        checkedIcon={<StarIcon />}
        checked={checked}
        onChange={handleChange}
      />
    </>
  );
};

export default FavoriteCheckboxField;
