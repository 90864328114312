//core
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from '@mui/material';

//styles
import { useStyles } from "./styles";

//hooks
import { useUser } from "../../../../../store/common/user/useUser";

//components
import SuspenseLinear from "../../../SuspenseLinear";
import PermissionsWrapper from "../../../PermissionsWrapper";
import { IPeriodsType } from "../../index";
import { IDashboardFilterType } from "../../types";
import { GridWidgetItemType } from "../../../../../store/admin/dashboardV2";
const StatisticChart = React.lazy(() => import("../../../Widgets/StatisticChart"));
const PersonalChief = React.lazy(() => import("../../../Widgets/PersonalChief"));

interface ISettingsItem {
  [key: string]: any;
}

export interface IWidgetItem extends GridWidgetItemType {
  name: string,
  data?: {
    [key: string]: any;
  },
}

interface IGridWidgetsProps {
  periods: IPeriodsType;
  filter: IDashboardFilterType;
  gridWidgetsList: GridWidgetItemType[] | null;
  settings: ISettingsItem[] | null;
}

const GridWidgets: React.FC<IGridWidgetsProps> = ({
  periods,
  filter,
  gridWidgetsList,
  settings,
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();

  const { user } = useUser();

  const [list, setList] = useState<IWidgetItem[] | null>(null);

  useEffect(() => {
    if (!!gridWidgetsList) {
      if (!!settings) {
        const availableWidgets = gridWidgetsList.map((item) => item.type);
        const visibleWidgets: IWidgetItem[] = settings.reduce((acc: IWidgetItem[], item) => {
          if (availableWidgets.includes(item.type)) {
            acc.push({
              id: item.id,
              type: item.type,
              name: item.name,
              data: {
                ...item,
              },
            });
          }
          return acc;
        }, []);

        setList(visibleWidgets);
      } else {
        const visibleWidgets: IWidgetItem[] = gridWidgetsList.reduce((acc: IWidgetItem[], item) => {
          if (item.type === 'statistic_charts') {
            acc.push({
              ...item,
              name: '',
            })
          }

          if (item.type === 'personal_chief') {
            acc.push({
              ...item,
              name: '',
            })
          }

          return acc;
        }, []);

        setList(visibleWidgets);
      }
    }
  }, [settings, gridWidgetsList]);

  return (
    <SuspenseLinear>
      <Box className={styles.block}>
        {
          !!list && list.map((item, i) => {
            const key = `${item.type}-${i}`;
            if (item.type === 'statistic_charts') {
              return <StatisticChart
                key={`${key}`}
                title={`${!!item.name ? item.name : t("common.components.charts.by_day.header") }`}
                periods={periods}
                pageFilter={filter}
                initialData={item.hasOwnProperty('data') ? item.data : null}
              />
            }
            if (item.type === 'personal_chief') {
              if (user.user.namespace === 'affiliate') {
                return (
                  <PermissionsWrapper
                    key={`${key}`}
                    permissions={["api3.affiliate.dashboardv1widgetcontroller.personal_chief"]}
                  >
                    <PersonalChief />
                  </PermissionsWrapper>
                )
              }
            }
            if (item.type === 'other_widget_type') {
              return <Box key={`${key}`}>other_widget_type</Box>
            }
          })
        }
      </Box>
    </SuspenseLinear>
  );
};

export default GridWidgets;
