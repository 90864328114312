import React, {useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { Scrollbars } from "react-custom-scrollbars";
import { MuiChipsInput, MuiChipsInputChip } from "mui-chips-input";
import { Box, Chip } from "@mui/material";

//styles
import { useStyles } from "./styles";
import CircularProgress from "@mui/material/CircularProgress";


interface IMuiChipsInputProps {
  value: any;
  id: string;
  name: string;
  label: string;
  onChange: (data: any) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  maxHeightFieldScroll?: number; // min value 32, max value 96
  maxWidthChip?: number;
}

const MuiChipsInputField = (props: IMuiChipsInputProps) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const {
    value,
    id,
    name,
    label,
    onChange,
    autoFocus = false,
    disabled = false,
    maxHeightFieldScroll = 92,
    maxWidthChip = 200,
  } = props;

  const [chips, setChips] = React.useState<MuiChipsInputChip[]>(value);

  useEffect(() => {
    setChips(value);
  }, [value])

  useEffect(() => {
    onChange((prevState: any) => {
      return {
        ...prevState,
        [name]: chips,
      }
    });
  }, [chips]);

  const handleDeleteChip = (chipIndex: number) => {
    setChips((prevChips) => prevChips.filter((_, index) => index !== chipIndex));
  };

  /*const handleChange = (newChips: string) => {
    onChange((prevState: any) => {
      return {
        ...prevState,
        [name]: [newChips],
      }
    });
  }*/

  return (
    <MuiChipsInput
      className={styles.field}
      label={label}
      size="small"
      value={chips}
      id={id}
      fullWidth
      disabled={disabled}
      onChange={setChips}
      renderChip={(Component, key, props) => {
        return <Component
          {...props}
          key={key}
          size="small"
          sx={{ height: '24px', maxWidth: `${maxWidthChip}px!important`, margin: '3px 2px!important' }}
        />
      }}
      inputProps={{
        maxlength: 255,
      }}
      InputProps={{
        /*inputProps: {
          //maxlength: 255,
        },*/
        name: name,
        placeholder: t("common.components.chips_input.placeholder"),
        autoFocus: autoFocus,
        startAdornment: !!value.length ? (
          <Box
            sx={{
              paddingRight: !!chips.length ? '30px' : '0px',
              boxSizing: 'initial',
            }}
          >
            <Scrollbars
              className={styles.customScroll}
              autoHide={true}
              hideTracksWhenNotNeeded={true}
              autoHeight={true}
              autoHeightMin={32}
              autoHeightMax={maxHeightFieldScroll}
              renderView={props => <div {...props} className="view-select"/>}
              renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
              renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            >
              {chips.map((chip, index) => (
                <Chip
                  key={index}
                  label={chip}
                  size="small"
                  onDelete={() => handleDeleteChip(index)}
                  sx={{
                    margin: '3px 2px',
                    maxWidth: `${maxWidthChip}px`,
                  }}
                />
              ))}
            </Scrollbars>
          </Box>
        ) : undefined,
        sx: {
          paddingTop: '4px!important',
          paddingBottom: '4px!important',
          //paddingRight: '50px!important',
          minHeight: '40px',
        },
      }}
    />
  );
};

export default MuiChipsInputField;
