//core
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { storageService } from "../../../services/common/storage.service";
import { setUser } from "../user";
import { setGeneralProgressHide, setGeneralProgressShow, setGeneralSnackbarState } from "../ui";


export type ListPayloadType = {
  payload: {
    page: number;
    per_page: number;
    order?: {
      [key: string]: 'asc' | 'desc';
    },
  },
  type: string;
  target: string;
}

export type CreatePayloadType = {
  type: string;
  target: string;
  payload: {
    name: string;
    data: string;
  }
}

export type ReadPayloadType = {
  type: string;
  target: string;
  id: string | number;
}

export type DeletePayloadType = {
  type: string;
  target: string;
  id: string | number;
}

export type UpdatePayloadType = {
  type: string;
  target: string;
  id: string | number;
  payload: {
    name: string;
    data?: string;
  }
}

export type LabelsPayloadType = {
  [key: string]: string[] | string;
}

export type storageSliceState = {
  list: {
    data: any | null,
    error: any | null,
    isFetching: boolean,
  },
  item: {
    data: any | null,
    error: any | null,
    isFetching: boolean,
  },
  create: {
    data: any | null,
    error: any | null,
    isFetching: boolean,
  },
  update: {
    data: any | null,
    error: any | null,
    isFetching: boolean,
  },
  delete: {
    data: any | null,
    error: any | null,
    isFetching: boolean,
  },
  labels: {
    data: any | null,
    error: any | null,
    isFetching: boolean,
  },
};

const initialState: storageSliceState = {
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  item: {
    data: null,
    error: null,
    isFetching: false,
  },
  create: {
    data: null,
    error: null,
    isFetching: false,
  },
  update: {
    data: null,
    error: null,
    isFetching: false,
  },
  delete: {
    data: null,
    error: null,
    isFetching: false,
  },
  labels: {
    data: null,
    error: null,
    isFetching: false,
  },
};

export const getListData: any = createAsyncThunk(
  'storage/getListData',
  async (payload: ListPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await storageService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const createItemData: any = createAsyncThunk(
  'storage/createItemData',
  async (payload: CreatePayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await storageService.create(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 201) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.saved',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const updateItemData: any = createAsyncThunk(
  'storage/updateItemData',
  async (payload: UpdatePayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await storageService.update(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 201) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.updated',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const deleteItemData: any = createAsyncThunk(
  'storage/deleteItemData',
  async (payload: DeletePayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await storageService.remove(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.removed',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getLabelsData: any = createAsyncThunk(
  'storage/getLabelsData',
  async (payload: LabelsPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await storageService.getLabels(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getItemData: any = createAsyncThunk(
  'storage/getItemData',
  async (payload: ReadPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await storageService.getById(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const storageSlice = createSlice({
  name: 'storage',
  initialState: initialState,
  reducers: {
    clearListState(state) {
      state.list.data = null;
      state.list.error = null;
      state.list.isFetching = false;
    },
    clearCreateState(state) {
      state.create.data = null;
      state.create.error = null;
      state.create.isFetching = false;
    },
    clearUpdateState(state) {
      state.update.data = null;
      state.update.error = null;
      state.update.isFetching = false;
    },
    clearDeleteState(state) {
      state.delete.data = null;
      state.delete.error = null;
      state.delete.isFetching = false;
    },
    clearLabelsState(state) {
      state.labels.data = null;
      state.labels.error = null;
      state.labels.isFetching = false;
    },
    clearItemState(state) {
      state.item.data = null;
      state.item.error = null;
      state.item.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<storageSliceState>>) => {
    builder.addCase(getListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getListData.fulfilled, (state , action) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getListData.rejected, (state , action) => {
      state.list.isFetching = false;
      state.list.error = action.payload;
    });

    builder.addCase(createItemData.pending, (state ) => {
      state.create.isFetching = true;
      state.create.error = null;
    });
    builder.addCase(createItemData.fulfilled, (state , action) => {
      state.create.error = null;
      state.create.isFetching = false;
      state.create.data = action.payload;
    });
    builder.addCase(createItemData.rejected, (state , action) => {
      state.create.isFetching = false;
      state.create.error = action.payload;
    });

    builder.addCase(updateItemData.pending, (state ) => {
      state.update.isFetching = true;
      state.update.error = null;
    });
    builder.addCase(updateItemData.fulfilled, (state , action) => {
      state.update.error = null;
      state.update.isFetching = false;
      state.update.data = action.payload;
    });
    builder.addCase(updateItemData.rejected, (state , action) => {
      state.update.isFetching = false;
      state.update.error = action.payload;
    });

    builder.addCase(deleteItemData.pending, (state ) => {
      state.delete.isFetching = true;
      state.delete.error = null;
    });
    builder.addCase(deleteItemData.fulfilled, (state , action) => {
      state.delete.error = null;
      state.delete.isFetching = false;
      state.delete.data = action.payload;
    });
    builder.addCase(deleteItemData.rejected, (state , action) => {
      state.delete.isFetching = false;
      state.delete.error = action.payload;
    });

    builder.addCase(getLabelsData.pending, (state ) => {
      state.labels.isFetching = true;
      state.labels.error = null;
    });
    builder.addCase(getLabelsData.fulfilled, (state , action) => {
      state.labels.error = null;
      state.labels.isFetching = false;
      state.labels.data = action.payload;
    });
    builder.addCase(getLabelsData.rejected, (state , action) => {
      state.labels.isFetching = false;
      state.labels.error = action.payload;
    });

    builder.addCase(getItemData.pending, (state ) => {
      state.item.isFetching = true;
      state.item.error = null;
    });
    builder.addCase(getItemData.fulfilled, (state , action) => {
      state.item.error = null;
      state.item.isFetching = false;
      state.item.data = action.payload;
    });
    builder.addCase(getItemData.rejected, (state , action) => {
      state.item.isFetching = false;
      state.item.error = action.payload;
    });
  },
});

export const {
  clearListState,
  clearCreateState,
  clearUpdateState,
  clearDeleteState,
  clearLabelsState,
  clearItemState,
} = storageSlice.actions;

export default storageSlice.reducer;
