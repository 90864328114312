import {createContext} from "react";

//types
import { itemListData } from "../../../../../common/TableData";

interface IDetailsContext {
  selectedRows: itemListData[],
  addSelectedRow: (data: itemListData) => void;
  setSelectedRows: (data: itemListData[]) => void;
  onGenerateCurrentState: () => void;
  showCreateUpdatePayout?: boolean;
  setShowCreateUpdatePayout?: any;
  dataCreateUpdatePayout?: any,
  setDataCreateUpdatePayout?: any;
  showCreateUpdateAdjustments?: boolean;
  setShowCreateUpdateAdjustments?: any;
  dataCreateUpdateAdjustments?: any,
  setDataCreateUpdateAdjustments?: any;
  convertCurrency: any;
  setConvertCurrency: any;
}

export const DetailsContext = createContext<IDetailsContext>({
  selectedRows: [],
  addSelectedRow: () => {},
  setSelectedRows: () => {},
  onGenerateCurrentState: () => {},
  showCreateUpdatePayout: false,
  setShowCreateUpdatePayout: () => {},
  dataCreateUpdatePayout: null,
  setDataCreateUpdatePayout: () => {},
  showCreateUpdateAdjustments: false,
  setShowCreateUpdateAdjustments: () => {},
  dataCreateUpdateAdjustments: null,
  setDataCreateUpdateAdjustments: () => {},
  convertCurrency: {},
  setConvertCurrency: () => {},
});


//GenerateContext
