import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles({
  block: {
    width: '100%',
  },
  btn__approve: {
    width: '100%',
  },
  btn__wrapper : {
    display: 'flex',
    marginTop: 30,
  },
  btn: {
    width: '100%',
  },
  btn__block: {
    display: 'inline-block',
    maxWidth: '250px',
    width: "100%",
    marginRight: '16px',
    '&:last-of-type': {
      marginRight: 0
    }
  },
});
