//core
import React from "react";
import arePropsEqual from "react-fast-compare";
import { makeStyles } from "@mui/styles";

interface IDepartmentTeam {
  team: number | null;
  whiteSpace?: boolean; //default true
}

const useStyles = makeStyles({
  text: {
    color: 'inherit',
  },
  whiteSpace: {
    whiteSpace: 'nowrap',
  }
});

const DepartmentTeam: React.FC<IDepartmentTeam> = ({
  team,
  whiteSpace = true,
}): JSX.Element => {
  const styles = useStyles();

  return (
    <span className={`${styles.text} ${whiteSpace ? styles.whiteSpace : ''}`}>
      {team ? `${team}` : '—'}
    </span>
  );
};

export default React.memo(DepartmentTeam, arePropsEqual);
