import {createContext} from "react";

//types
import { itemListData } from "../../../../../../common/TableData";

interface IDetailsContext {
  selectedRows: itemListData[],
  addSelectedRow: (data: itemListData) => void;
  setSelectedRows: (data: itemListData[]) => void;
  updateSelectAllCheckbox: boolean;
  selectedAllBrands: boolean;
}

export const BrandsContext = createContext<IDetailsContext>({
  selectedRows: [],
  addSelectedRow: () => {},
  setSelectedRows: () => {},
  updateSelectAllCheckbox: false,
  selectedAllBrands: false,
});
