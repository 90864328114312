import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles({
  content: {},
  btn: {
    minWidth: 300,
  },
  header_bottom: {
    display: "flex",
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    ['@media (max-width: 768px)']: {
      marginBottom: 16,
    }
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  }
});
