//core
import React, { useContext, useState, useEffect, useCallback } from "react";
import arePropsEqual from "react-fast-compare";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Radio,
} from "@mui/material";


import { itemListData } from "../../../../../../common/TableData";
import { DealsContext } from "../../DealsContext";

interface IText {
  value: string | number;
  data: itemListData;
  //setSelectedRow: (state: boolean) => void;
}

const useStyles = makeStyles({
  block: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  text: {
    color: 'inherit',
  }
});



const SelectDealRow: React.FC<IText> = ({
  value,
  data,
}): JSX.Element => {
  const styles = useStyles();

  const context = useContext(DealsContext);

  const [selectedValue, setSelectedValue] = React.useState(`${!!context.selectedRow ? context.selectedRow.id : 'null'}`);

  useEffect(() => {
    if (!!context.selectedRow) {
      setSelectedValue(`${!!context.selectedRow ? context.selectedRow.id : 'null'}`);
    }
  }, [context.selectedRow])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
    context.setSelectedRow(data);
  };

  return (
    <Box
      component="span"
      className={styles.block}
    >
      <Radio
        checked={selectedValue === `${value}`}
        onChange={handleChange}
        value={`${value}`}
        name="radio-buttons"
        inputProps={{ 'aria-label': `${value}` }}
        size="small"
        sx={{ marginLeft: '-12px' }}
      />

      <span className={styles.text}>
        {value ? value : '—'}
      </span>
    </Box>
  );
};

export default SelectDealRow;
