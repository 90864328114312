export type ObjArrayItem = {
  id: string | number;
  [key: string]: any,
};

export const strToNumberFormat = (str: string | number): string => {
  const sliceNumber = (a: string) => {
    let result = (+a).toString().split(".");
    return result[0].concat(".", !!result[1] ? result[1].length > 2 ? result[1].slice(0, 2) : result[1] : '00');
  }

  return Number(sliceNumber(`${str}`)).toLocaleString('ru', {
    maximumFractionDigits: 20,
    minimumFractionDigits: 2
  }).toString()
}

export const strToIntegerNumberFormat = (str: string | number): string => {
  let number = + str;
  return number.toLocaleString('ru', { maximumFractionDigits: 2 }).toString()
}

type OptionsType = {
  expires?: Date | string,
  path?: string,
  [key: string]: any,
}

export const setCookie = (name: string, value: any, options: OptionsType = {}): void => {
  options = {
    path: '/',
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export const setCookieObject = (name: string, value: any, options: OptionsType = {}): void => {
  options = {
    path: '/',
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + value;

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export const getCookie = (name: string): string => {
  const nameLenPlus = (name.length + 1);
  const cookie = document.cookie
    .split(';')
    .map(c => c.trim())
    .filter(cookie => {
      return cookie.substring(0, nameLenPlus) === `${name}=`;
    })
    .map(cookie => {
      return decodeURIComponent(cookie.substring(nameLenPlus));
    })[0] || "null";

  return cookie
};

export const deleteCookie = (name: string) => {
  const date = new Date();
  date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));
  document.cookie = name+"=; expires="+date.toUTCString()+"; path=/";
};

export const getAnid = (click_id: string, source_id: string, sub_id: string) => {
  if (!click_id && !source_id && !sub_id) {
    return '';
  }

  if (!click_id) {
    if (!source_id) {
      if (!sub_id) {
        return ''
      } else {
        return sub_id
      }
    } else {
      if (!sub_id) {
        return source_id
      } else {
        return `${source_id && `${source_id}`}${sub_id && `_${sub_id}`}`
      }
    }
  } else {
    return `${click_id}${source_id && `_${source_id}`}${sub_id && `_${sub_id}`}`
  }
};

export const numberToLocaleFormat = (str: string | number, locale:string): string => {
  let number = strToNumberFormat(str);

  if (locale === 'ru') {
    const newValue = number.replace(/\./g,',');
    number = spaceDigits(newValue);
  }

  if (locale === 'en') {
    const newValue = number.replace(/,/g, '.');
    number = spaceDigits(newValue);
  }

  return ((number === '0.00') || (number === '0,00')) ? '0' : number;
}

export const numberToLocaleFormatInteger = (str: string | number, locale:string): string => {
  let number = strToIntegerNumberFormat(str);

  if (locale === 'ru') {
    const newValue = number.replace(/\./g,',');
    number = spaceDigits(newValue);
  }

  if (locale === 'en') {
    const newValue = number.replace(/,/g, '.');
    number = spaceDigits(newValue);
  }
  return number;
}

export const spaceDigits = (number:string | number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const getIds = (arr: ObjArrayItem[]): string[] | number[] => {
  return arr.reduce((acc: string[] | number[], el) => {
    //@ts-ignore
    acc.push(el.id);
    return acc;
  }, []);
}

