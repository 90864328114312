//core
import React from "react";
import { useTranslation } from "react-i18next";
import {Box, Button, Typography} from "@mui/material";

//styles
import { useStyles } from "./styles";

//hooks
import { useEmployees } from "../../../../../store/admin/employees/useEmployees";

interface IAddAdminProps {
  data: any;
  onClose: (data: boolean) => void;
}

const RemoveAdmin: React.FC<IAddAdminProps> = ({data, onClose}): JSX.Element => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { removeAdmin } = useEmployees();

  const handleRemoveAdmin = ( ) => {
    const payload = {
      admin_id: data.id,
    }

    removeAdmin(payload);
    onClose(false);
  }

  return (
    <Box className={styles.block}>
      <Typography sx={{ mb: 3 }} variant="h6">{t("admin.employees.remove_admin_title")}</Typography>
      <Typography variant="body2">
        {t("admin.employees.remove_admin_text")}<Box sx={{ wordWrap: 'break-word' }} component="span">{data.name}</Box>?
      </Typography>
      <Box className={styles.btn__wrapper}>
        <Box className={styles.btn__block}>
          <Button
            variant="contained"
            color="secondary"
            className={styles.btn__approve}
            onClick={handleRemoveAdmin}
          >
            {t("common.buttons.delete")}
          </Button>
        </Box>
        <Box className={styles.btn__block}>
          <Button
            variant="outlined"
            color="secondary"
            className={styles.btn}
            onClick={() => {onClose(false)}}
          >
            {t("common.buttons.cancel")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default RemoveAdmin;
