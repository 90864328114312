import React, { ForwardedRef, forwardRef, useContext, useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Checkbox, Paper, Box } from "@mui/material";

//styles
import { useStyles } from "./styles";

//icons
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";

//context
import { AutocompleteSelectContext } from "./context";


const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

interface CustomPaperProps extends React.HTMLAttributes<HTMLElement> {}

const CustomPaper = forwardRef(
  function CustomPaperBase (
    props: CustomPaperProps,
    ref: ForwardedRef<HTMLElement>,
  ) {
    const styles = useStyles();
    const { t } = useTranslation();

    const {
      showSelectAll,
      selectAllState,
      maxSelectAllCount,
      options,
      handleCheckAllOptions,
    } = useContext(AutocompleteSelectContext);

    const { children, ...rest } = props;

    const innerRef = useRef<HTMLUListElement>(null);

    useImperativeHandle<NullableUlElement, NullableUlElement>(ref, () => innerRef.current);

    return (
      <Paper elevation={2} {...rest}>
        <Box
          {...rest}
          onMouseDown={(e: any) => e.preventDefault()}
        >
          {showSelectAll && (options.length < maxSelectAllCount && options.length > 0) && (
            <MenuItem
              className={styles.selectAll}
              sx={{ minHeight: 'auto!important' }}
              selected={selectAllState}
              value={"all"}
              onClick={handleCheckAllOptions}
            >
              <Checkbox
                sx={{ padding: '0', width: 24, height: 24, marginRight: 1 }}
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selectAllState}
              />
              {selectAllState ? t("common.components.select_all.clear") : t("common.components.select_all.check")}
            </MenuItem>
          )}
          {children}
        </Box>
      </Paper>
    );
  },
);

export default CustomPaper;

type NullableUlElement = HTMLElement | null;
