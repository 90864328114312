import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  block: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    margin: "0 -10px",
    '@media (max-width: 767px)': {}
  },
}));
