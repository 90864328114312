import {api} from "../../api";
import {handleResponse, RequestOptions} from "../types";
import {getCookie} from "../../helpers/utils";
import { cookieSupervision, cookieToken } from "../../constants";

const getWidgets = () => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.paymentHistory.widgets : api.affiliate.paymentHistory.widgets;

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getList = (data: any) => {
  const isSupervision = JSON.parse(getCookie(cookieSupervision));
  const authenticity_token = getCookie(cookieToken);

  const endpoint = isSupervision ? api.supervision.paymentHistory.list : api.affiliate.paymentHistory.list;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getPaymentDetails = (data: any) => {
  const isSupervision = JSON.parse(getCookie(cookieSupervision));
  const authenticity_token = getCookie(cookieToken);

  const endpoint = isSupervision
    ? api.supervision.paymentHistory.paymentDetails
    : api.affiliate.paymentHistory.paymentDetails;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};


export const paymentHistoryService = {
  getWidgets,
  getList,
  getPaymentDetails,
};
