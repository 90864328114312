import { createContext } from "react";

export interface IThemeContext {
  darkMode: boolean,
  setDarkMode: (mode: boolean) => void;
}

export const initialValue: IThemeContext = {
  darkMode: false,
  setDarkMode: function (mode) {
    this.darkMode = mode;
  }
}

export const ThemeContext = createContext<IThemeContext>(initialValue);
