//core
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import arePropsEqual from "react-fast-compare";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@mui/styles";
import { darken, rgbToHex } from "@mui/material/styles";
import { TableCell, Tooltip } from "@mui/material";
import { getSymbolFromCode } from "currency-code-symbol-map";

//theme
import {Theme} from "@mui/material/styles/createTheme";

//types
import { itemColumnsData } from "../../index";
import { OrderType } from "../../../../affiliate/Trackers";

//components
import SortButton from "../SortButton"
import SelectAllRows from "../../../../admin/Reports/Finance_report/components/TableActions/SelectAllRows";
import useEffectOnce from "../../../../../customHooks/useEffectOnce";
import SelectAllDeals from "../../../../admin/Deals/components/TableActions/SelectAllDeals";

const getTooltipTitle = (str: string): any[] =>  {
  const splitted = str.split('\n');

  const result: any[] = [];
  splitted.forEach((element: string, index: number) => {
    result.push(element);
    if (index < splitted.length - 1) {
      result.push(<br/>);
    }
  });

  return result;
}

let useStyles = makeStyles<Theme>((theme) => ({
  tableHeadCell: {
    color: 'inherit',
    whiteSpace: 'nowrap',
    height: 44,
    padding: '3px 16px',
    backgroundColor: rgbToHex(rgbToHex(darken(theme.palette.background.paper, 0.12))),
    borderBottom: 'none',
    position: 'sticky',
    top: 0,
    '&.tableHeadCenter': {
      textAlign: 'center',
    }
  },
  stickyHeader: {
    position: 'sticky',
    borderLeft: `1px solid ${rgbToHex(darken(theme.palette.background.paper, 0.12))}`,
    boxShadow: theme.shadows[2],
  },
  stickyActionHeader: {
    right: 0,
  },
  tableCellFreeze: {
    position: 'sticky',
    boxShadow: theme.shadows[2],
    borderLeft: `1px solid ${rgbToHex(darken(theme.palette.background.paper, 0.12))}`,
    zIndex: 5,
    '&:hover': {

    }
  },
  sort: {
    marginLeft: '4px'
  },
}));


interface ITableHeadColumns {
  columns: itemColumnsData[];
  order: OrderType;
  initialOrder: OrderType;
  setOrder: Dispatch<SetStateAction<OrderType>> | Dispatch<OrderType>;
  actions: boolean;
  actionsCount: number;
  disabled?: boolean;
  translationColumnsPrefix: string;
  translationTooltipsPrefix?: string;
  freezeColumns?: string[];
  columnCurrency?: any;
}

const TableHeadColumns = (props: ITableHeadColumns) => {
  const theme: Theme = useTheme();
  const styles = useStyles();
  const { t } = useTranslation();

  const {
    columns,
    actions = false,
    actionsCount,
    order,
    initialOrder,
    setOrder,
    disabled = false,
    translationColumnsPrefix,
    freezeColumns,
    translationTooltipsPrefix = '',
    columnCurrency,
  } = props;

  const [freezeColumn, setFreezeColumn] = useState<number[]>([]);
  const [widthFreezeColumns, setWidthFreezeColumns] = useState<any>(0);

  const count = actionsCount === 1 ? 1.75 : actionsCount;

  useEffectOnce(() => {
    if (!!columns && !!freezeColumns) {
      columns.forEach((column) => {
        if (freezeColumns?.includes(column.name)) {
          setFreezeColumn((prevState:any) => {
            return [
              ...prevState,
              document?.getElementById(`${styles.tableCellFreeze + '-' + column.name}`)?.offsetWidth
            ]
          });
        }
      })
    }
  })

  useEffect(() => {
    if (!!freezeColumns && !!freezeColumn) {
      const widthArr = freezeColumn.reduce((acc:any, item:number, index:number) => {
        if (freezeColumn.length - 1 !== index) {
          const width = acc[index] + item;
          acc.push(width);
        }
        return acc;
      },[0]);

      setWidthFreezeColumns(widthArr);
    }
  },[freezeColumn])

  useEffect(() => {
    return () => {
      setWidthFreezeColumns(0);
      setFreezeColumn([]);
    }
  },[])

  return (
    <>
      {
        columns.map((item,index) => {
          const columnName = !!translationTooltipsPrefix ? <>
            {!!t(`${translationTooltipsPrefix}${item.name}`) ? (
              <Tooltip
                title={
                  getTooltipTitle(t(`${translationTooltipsPrefix}${item.name}`))
                }
                placement="bottom"
              >
                <span>
                  {t(`${translationColumnsPrefix}${item.name}`).replace('{currency}', (!!columnCurrency && (!!getSymbolFromCode(columnCurrency.id.toUpperCase()) ? getSymbolFromCode(columnCurrency.id.toUpperCase()) : columnCurrency.id)) || '$')}
                </span>
              </Tooltip>
            ) : t(`${translationColumnsPrefix}${item.name}`).replace('{currency}', (!!columnCurrency && (!!getSymbolFromCode(columnCurrency.id.toUpperCase()) ? getSymbolFromCode(columnCurrency.id.toUpperCase()) : columnCurrency.id)) || '$')}
          </> : t(`${translationColumnsPrefix}${item.name}`).replace('{currency}', (!!columnCurrency && (!!getSymbolFromCode(columnCurrency.id.toUpperCase()) ? getSymbolFromCode(columnCurrency.id.toUpperCase()) : columnCurrency.id)) || '$');


          return <TableCell
            className={`${styles.tableHeadCell} ${freezeColumns?.includes(item.name) ? styles.tableCellFreeze : ''}`}
            key={item.name}
            align="left"
            id={`${freezeColumns?.includes(item.name) ? `${styles.tableCellFreeze + '-' + item.name}` : ''}`}
            {...(freezeColumns ? {sx: {
                left: `${freezeColumns?.includes(item.name) && !!freezeColumn ? `${widthFreezeColumns[index]}px` : `${widthFreezeColumns[widthFreezeColumns.length - 1]}px`}`
              }} : {})}
          >
            {item.type === 'finAffId' && <SelectAllRows/> }
            {item.type === 'dealsSelectBrand' && <SelectAllDeals /> }
            {columnName}
            {
              item.sort && <SortButton className={styles.sort} order={order} setOrder={setOrder} name={item.name} initialOrder={initialOrder} />
            }
          </TableCell>
        })
      }
      { actions && <TableCell
        className={`
          ${styles.tableHeadCell} 
          ${styles.stickyHeader} 
          ${styles.stickyActionHeader}
          ${actionsCount === 1 ? 'tableHeadCenter' : ''}
        `}
        align="left"
        sx={{
          minWidth: `${32 + 36*(count)}px`,
          maxWidth: `${32 + 36*count}px`,
          width: `${32 + 36*count}px`,
          backgroundColor: disabled
            ? rgbToHex(darken(theme.palette.background.paper, 0.04))
            : rgbToHex(darken(theme.palette.background.paper, 0.09))
        }}
      >
        {t("columns_for_display.table.actions")}
      </TableCell> }
    </>
  );
};

export default React.memo(TableHeadColumns, arePropsEqual);
