//core
import { createSelector } from "@reduxjs/toolkit";

//store
import { RootState } from "../../index";

// state
const conditionsSelector = (state: RootState) => state.admin.conditions;

const conditionsListSelector = (state: RootState) => state.admin.conditions.list;
const conditionsListDataSelector = (state: RootState) => state.admin.conditions.list.data;
const conditionsListErrorSelector = (state: RootState) => state.admin.conditions.list.error;
const conditionsListFetchingSelector = (state: RootState) => state.admin.conditions.list.isFetching;

//selectors
export const selectAdmConditions = createSelector(conditionsSelector, (conditions) => conditions);

export const selectList = createSelector(conditionsListSelector, (list) => list);
export const selectListData = createSelector(conditionsListDataSelector, (data) => {
  if (!!data) {
    const columns = data.columns_for_display_data.map((item: any) => {
      if (item.name === 'id') {
        return { ...item, type: 'string' };
      }
      if (item.name === 'title') {
        return { ...item, type: 'mediumString' };
      }
      if (item.name === 'description') {
        return { ...item, type: 'mediumString' };
      }
      if (item.name === 'type_of_transaction') {
        return { ...item, type: 'type_of_transaction' };
      }
      if (item.name === 'created_at') {
        return { ...item, type: 'fulldatetime' };
      }

      return item;
    });

    return { ...data, columns_for_display_data: columns };
  } else {
    return data;
  }
});
export const selectListDataColumnsSort = createSelector(conditionsListDataSelector, (data) => {
  return data?.columns_for_display_data.filter((el) => el.sort === true)
});
export const selectListError = createSelector(conditionsListErrorSelector, (error) => error);
export const selectListIsFetching = createSelector(conditionsListFetchingSelector, (isFetching) => isFetching);


//delete
const deleteConditionDataSelector = (state: RootState) => state.admin.conditions.deleteCondition.data;
const deleteConditionErrorSelector = (state: RootState) => state.admin.conditions.deleteCondition.error;
const deleteConditionFetchingSelector = (state: RootState) => state.admin.conditions.deleteCondition.isFetching;

export const selectDeleteConditionData = createSelector(deleteConditionDataSelector, (data) => data);
export const selectDeleteConditionError = createSelector(deleteConditionErrorSelector, (error) => error);
export const selectDeleteConditionIsFetching = createSelector(deleteConditionFetchingSelector, (isFetching) => isFetching);

//clone
const cloneConditionDataSelector = (state: RootState) => state.admin.conditions.cloneCondition.data;
const cloneConditionErrorSelector = (state: RootState) => state.admin.conditions.cloneCondition.error;
const cloneConditionFetchingSelector = (state: RootState) => state.admin.conditions.cloneCondition.isFetching;

export const selectCloneConditionData = createSelector(cloneConditionDataSelector, (data) => data);
export const selectCloneConditionError = createSelector(cloneConditionErrorSelector, (error) => error);
export const selectCloneConditionIsFetching = createSelector(cloneConditionFetchingSelector, (isFetching) => isFetching);

//constants
const conditionConstantsDataSelector = (state: RootState) => state.admin.conditions.constants.data;
const conditionConstantsErrorSelector = (state: RootState) => state.admin.conditions.constants.error;
const conditionConstantsFetchingSelector = (state: RootState) => state.admin.conditions.constants.isFetching;

export const selectConditionConstantsData = createSelector(conditionConstantsDataSelector, (data) => data);
export const selectConditionConstantsError = createSelector(conditionConstantsErrorSelector, (error) => error);
export const selectConditionConstantsIsFetching = createSelector(conditionConstantsFetchingSelector, (isFetching) => isFetching);

//createCondition
const createConditionDataSelector = (state: RootState) => state.admin.conditions.createCondition.data;
const createConditionErrorSelector = (state: RootState) => state.admin.conditions.createCondition.error;
const createConditionFetchingSelector = (state: RootState) => state.admin.conditions.createCondition.isFetching;

export const selectCreateConditionData = createSelector(createConditionDataSelector, (data) => data);
export const selectCreateConditionError = createSelector(createConditionErrorSelector, (error) => error);
export const selectCreateConditionIsFetching = createSelector(createConditionFetchingSelector, (isFetching) => isFetching);

//viewCondition
const viewConditionDataSelector = (state: RootState) => state.admin.conditions.viewCondition.data;
const viewConditionErrorSelector = (state: RootState) => state.admin.conditions.viewCondition.error;
const viewConditionFetchingSelector = (state: RootState) => state.admin.conditions.viewCondition.isFetching;

export const selectViewConditionData = createSelector(viewConditionDataSelector, (data) => data);
export const selectViewConditionError = createSelector(viewConditionErrorSelector, (error) => error);
export const selectViewConditionIsFetching = createSelector(viewConditionFetchingSelector, (isFetching) => isFetching);

//editCondition
const editConditionDataSelector = (state: RootState) => state.admin.conditions.editCondition.data;
const editConditionErrorSelector = (state: RootState) => state.admin.conditions.editCondition.error;
const editConditionFetchingSelector = (state: RootState) => state.admin.conditions.editCondition.isFetching;

export const selectEditConditionData = createSelector(editConditionDataSelector, (data) => data);
export const selectEditConditionError = createSelector(editConditionErrorSelector, (error) => error);
export const selectEditConditionIsFetching = createSelector(editConditionFetchingSelector, (isFetching) => isFetching);



//Offers id
const offersIdConditionDataSelector = (state: RootState) => state.admin.conditions.offersId.data;
const offersIdConditionErrorSelector = (state: RootState) => state.admin.conditions.offersId.error;
const offersIdConditionFetchingSelector = (state: RootState) => state.admin.conditions.offersId.isFetching;

export const selectOffersIdConditionData = createSelector(offersIdConditionDataSelector, (data) => {
  if (!!data) {
    const list = data.list.map((item: any) => {
      return {...item, value: `${item.value} (id: ${item.id})`};
    });

    return { ...data, list: list };
  } else {
    return data;
  }
});
export const selectOffersIdConditionError = createSelector(offersIdConditionErrorSelector, (error) => error);
export const selectOffersIdConditionIsFetching = createSelector(offersIdConditionFetchingSelector, (isFetching) => isFetching);


//External Brands and Currencies
const externalCurrencyConditionDataSelector = (state: RootState) => state.admin.conditions.externalCurrency.data;
const externalCurrencyConditionErrorSelector = (state: RootState) => state.admin.conditions.externalCurrency.error;
const externalCurrencyConditionFetchingSelector = (state: RootState) => state.admin.conditions.externalCurrency.isFetching;

export const selectExternalCurrencyConditionData = createSelector(externalCurrencyConditionDataSelector, (data) => data);
export const selectExternalCurrencyConditionError = createSelector(externalCurrencyConditionErrorSelector, (error) => error);
export const selectExternalCurrencyConditionIsFetching = createSelector(externalCurrencyConditionFetchingSelector, (isFetching) => isFetching);



//Archivate deals
const archiveConditionDataSelector = (state: RootState) => state.admin.conditions.archiveDeal.data;
const archiveConditionErrorSelector = (state: RootState) => state.admin.conditions.archiveDeal.error;
const archiveConditionFetchingSelector = (state: RootState) => state.admin.conditions.archiveDeal.isFetching;

export const selectArchiveConditionData = createSelector(archiveConditionDataSelector, (data) => data);
export const selectArchiveConditionError = createSelector(archiveConditionErrorSelector, (error) => error);
export const selectArchiveConditionIsFetching = createSelector(archiveConditionFetchingSelector, (isFetching) => isFetching);


//Archivate deals
const archivedListConditionsDataSelector = (state: RootState) => state.admin.conditions.archivedList.data;
const archivedListConditionsErrorSelector = (state: RootState) => state.admin.conditions.archivedList.error;
const archivedListConditionsFetchingSelector = (state: RootState) => state.admin.conditions.archivedList.isFetching;

export const selectArchivedListConditionsData = createSelector(archivedListConditionsDataSelector, (data) => data);
export const selectArchivedListConditionsError = createSelector(archivedListConditionsErrorSelector, (error) => error);
export const selectArchivedListConditionsnIsFetching = createSelector(archivedListConditionsFetchingSelector, (isFetching) => isFetching);


//Brands List
const conditionBrandsListDataSelector = (state: RootState) => state.admin.conditions.brandsList.data;
const conditionBrandsListErrorSelector = (state: RootState) => state.admin.conditions.brandsList.error;
const conditionBrandsListFetchingSelector = (state: RootState) => state.admin.conditions.brandsList.isFetching;

export const selectConditionBrandsListData = createSelector(conditionBrandsListDataSelector, (data) => {
  if (!!data && !!data.list[0]) {
    const columns = Object.keys(data.list[0]).reduce((acc: any[], key: string) => {
      if (key === 'code') {
         acc.push({ name: "code", sort: false, type: "dealsSelectBrand" });
      }

      if (key === 'countries') {
        acc.push({ name: "countries", sort: false, type: "geoArrayShort" });
      }

      if (key === 'domain') {
        acc.push({ name: "domain", sort: false, type: "shortUrl" });
      }

      if (key === 'name') {
        acc.push({ name: "name", sort: false, type: "shortString" });
      }

      return acc;
    },[]);

    const list = data.list.map((item: any) => {
      const new_countries = item.countries.map((item:any) => {
        return {id: item, label: item}
      })
      return {...item, countries: new_countries};
    });

    return { ...data, columns: columns, list: list};
  } else {
    return data;
  }
});
export const selectConditionBrandsListError = createSelector(conditionBrandsListErrorSelector, (error) => error);
export const selectConditionBrandsListIsFetching = createSelector(conditionBrandsListFetchingSelector, (isFetching) => isFetching);


//Creator Filter
const conditionFilterCreatorDataSelector = (state: RootState) => state.admin.conditions.filterCreator.data;
const conditionFilterCreatorErrorSelector = (state: RootState) => state.admin.conditions.filterCreator.error;
const conditionFilterCreatorFetchingSelector = (state: RootState) => state.admin.conditions.filterCreator.isFetching;

export const selectConditionFilterCreatorData = createSelector(conditionFilterCreatorDataSelector, (data) => data);
export const selectConditionFilterCreatorError = createSelector(conditionFilterCreatorErrorSelector, (error) => error);
export const selectConditionFilterCreatorIsFetching = createSelector(conditionFilterCreatorFetchingSelector, (isFetching) => isFetching);


//Brands For Deal
const conditionBrandsForDealDataSelector = (state: RootState) => state.admin.conditions.brandsForDeal.data;
const conditionBrandsForDealErrorSelector = (state: RootState) => state.admin.conditions.brandsForDeal.error;
const conditionBrandsForDealFetchingSelector = (state: RootState) => state.admin.conditions.brandsForDeal.isFetching;

export const selectConditionBrandsForDealData = createSelector(conditionBrandsForDealDataSelector, (data) => data);
export const selectConditionBrandsForDealError = createSelector(conditionBrandsForDealErrorSelector, (error) => error);
export const selectConditionBrandsForDealIsFetching = createSelector(conditionBrandsForDealFetchingSelector, (isFetching) => isFetching);
