import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import { cookieToken } from "../../constants";

const inviteAdmin = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.invite_admin;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('PUT', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

export const inviteAdminService = {
  inviteAdmin
};
