//core
import React from "react";
import {makeStyles} from "@mui/styles";

interface IAdjustment {
    value: any;
}

const useStyles = makeStyles({
    block: {
        textAlign: "center",
    }
});

const Adjustment = (props: IAdjustment) => {
    const {
        value,
    } = props;

    const styles = useStyles();

    return (
        <div className={styles.block}>
            <span>
                {value.adjustment}
            </span>
        </div>
    );
};

export default Adjustment;
