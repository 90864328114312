import React from "react";
import { useTranslation } from "react-i18next";

//components
import DateTimeRange from "../../../../../../../../../../../common/TableData/components/DateTimeRange";

interface IPaymentEditedProps {
  data: any;
}

const PaymentEdited: React.FC<IPaymentEditedProps> = ({
  data,
}): JSX.Element => {
  const { t } = useTranslation();

  const first = <>
    {t("admin.reports.detailed_fin_report.logs.actions.payment.edited.first")} <DateTimeRange value={data.paymentDate} fullDate />{`, `}{t("admin.reports.detailed_fin_report.logs.actions.payment.amount")}{`: `}{data.amount} {t("admin.reports.detailed_fin_report.logs.actions.payment.edited.second")}
  </>;

  const date = data.from.paymentDate !== data.to.paymentDate && <>
    {t("admin.reports.detailed_fin_report.logs.actions.payment.date_from")} <DateTimeRange value={data.from.paymentDate} fullDate /> {t("admin.reports.detailed_fin_report.logs.actions.payment.to")} <DateTimeRange value={data.to.paymentDate} fullDate />
  </>;

  const amount = data.from.amount !== data.to.amount && `${t("admin.reports.detailed_fin_report.logs.actions.payment.amount_from")
    .replace('{from}', `${data.from.amount}`)
    .replace('{to}', `${data.to.amount}`)}`;

  const comment = data.from.comment !== data.to.comment && `${t("admin.reports.detailed_fin_report.logs.actions.payment.comment_from")
    .replace('{from}', `${data.from.comment !== null ? data.from.comment : '-'}`)
    .replace('{to}', `${data.to.comment !== null ? data.to.comment : '-'}`)}`;

  const wallet = data.from.walletPaymentMethodName !== data.to.walletPaymentMethodName && `${t("admin.reports.detailed_fin_report.logs.actions.payment.wallet_from")
    .replace('{from}', `${data.from.walletPaymentMethodName !== null ? `${t(`common.components.payment.payment_method_options.${data.from.walletPaymentMethodName}`)} - ${data.from.walletPaymentMethodValue[data.from.walletPaymentMethodDefaultField]}` : '-'}`)
    .replace('{to}', `${data.to.walletPaymentMethodName !== null ? `${t(`common.components.payment.payment_method_options.${data.to.walletPaymentMethodName}`)} - ${data.to.walletPaymentMethodValue[data.to.walletPaymentMethodDefaultField]}` : '-'}`)}`;

  const walletCurrency = data.from.walletCurrency !== data.to.walletCurrency && `${t("admin.reports.detailed_fin_report.logs.actions.payment.wallet_currency_from")
    .replace('{from}', `${data.from.walletCurrency !== null ? data.from.walletCurrency : '-'}`)
    .replace('{to}', `${data.to.walletCurrency !== null ? data.to.walletCurrency : '-'}`)}`;

  const walletAmount = data.from.walletAmount !== data.to.walletAmount && `${t("admin.reports.detailed_fin_report.logs.actions.payment.wallet_amount_from")
    .replace('{from}', `${data.from.walletAmount !== null ? data.from.walletAmount : '-'}`)
    .replace('{to}', `${data.to.walletAmount !== null ? data.to.walletAmount : '-'}`)}`;

  const transactionId = data.from.transactionId !== data.to.transactionId && `${t("admin.reports.detailed_fin_report.logs.actions.payment.transaction_id_from")
    .replace('{from}', `${data.from.transactionId !== null ? data.from.transactionId : '-'}`)
    .replace('{to}', `${data.to.transactionId !== null ? data.to.transactionId : '-'}`)}`;

  return (
    <>
      {first}
      {!!date ? date : ''}
      {!!date && (!!amount || !!wallet || !!walletCurrency || !!walletAmount || !!transactionId || !!comment) ? ', ' : ' '}
      {!!amount ? amount : ' '}
      {!!amount && (!!wallet || !!walletCurrency || !!walletAmount || !!transactionId || !!comment) ? ', ' : ' '}
      {!!wallet ? wallet : ''}
      {!!wallet && (!!walletCurrency || !!walletAmount || !!transactionId || !!comment) ? ', ' : ' '}
      {!!walletCurrency ? walletCurrency : ''}
      {!!walletCurrency && (!!walletAmount || !!transactionId || !!comment) ? ', ' : ' '}
      {!!walletAmount ? walletAmount : ''}
      {!!walletAmount && (!!transactionId || !!comment) ? ', ' : ' '}
      {!!transactionId ? transactionId : ''}
      {!!transactionId && !!comment ? ', ' : ' '}
      {!!comment ? comment : ''}
    </>
  );
};

export default PaymentEdited;
