//core
import React, {Suspense} from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { LicenseInfo } from '@mui/x-license';

//reset css
import "reset-css";

//components
import AppLoader from "./components/UI/AppLoader";

// multilanguage
import "./i18n";

//store
import { store } from "./store";

//context
import { GlobalContext, initialValue } from "./context/GlobalContext";

//providers
import MuiThemeProvider from "./components/UI/MuiTheme/MuiThemeProvider";

LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_LICENSE_KEY}`);

const App = React.lazy(() => import("./components/common/App"));

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <GlobalContext.Provider value={initialValue}>
      <MuiThemeProvider>
        <Suspense fallback={<AppLoader/>}>
          <App />
        </Suspense>
      </MuiThemeProvider>
    </GlobalContext.Provider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
