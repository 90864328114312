//core
import { useDispatch } from "react-redux";

//types
import {
  resetState,
  getTopWidgetsData,
  clearTopWidgetsState,
  getStatisticChartsSettings,
  clearStatisticChartsSettingsState,
  getStatisticChartsData,
  clearStatisticChartsState,
  getGridWidgetsData,
  clearGridWidgetsState,
  getFiltersData,
  clearFiltersState,
  getAffiliatesData,
  getCreativesData,
  getTrackersData,
  getBrandsData,
} from "./index";

export const useDashboardV2 = () => {
  const dispatch = useDispatch();

  const resetDashboardState = () => dispatch(resetState());

  const getFilters = (data: any) => dispatch(getFiltersData(data));
  const clearFiltersData = () => dispatch(clearFiltersState());

  const getTopWidgets = (data: any) => dispatch(getTopWidgetsData(data));
  const clearTopWidgetsData = () => dispatch(clearTopWidgetsState());

  const getGridWidgets = (data: any) => dispatch(getGridWidgetsData(data));
  const clearGridWidgetsData = () => dispatch(clearGridWidgetsState());

  const getStatisticChartData = (data: any) => dispatch(getStatisticChartsData(data));
  const clearStatisticChartData = () => dispatch(clearStatisticChartsState());
  const getStatisticChartSettings = () => dispatch(getStatisticChartsSettings());
  const clearStatisticChartSettings = () => dispatch(clearStatisticChartsSettingsState());

  const getAffiliates = (data: any) => dispatch(getAffiliatesData(data));
  const getCreatives = (data: any) => dispatch(getCreativesData(data));
  const getTrackers = (data: any) => dispatch(getTrackersData(data));
  const getBrands = (data: any) => dispatch(getBrandsData(data));


  return {
    resetDashboardState,
    getTopWidgets,
    clearTopWidgetsData,
    getStatisticChartData,
    clearStatisticChartData,
    getStatisticChartSettings,
    clearStatisticChartSettings,
    getGridWidgets,
    clearGridWidgetsData,
    getFilters,
    clearFiltersData,
    getAffiliates,
    getCreatives,
    getTrackers,
    getBrands,
  }
}
