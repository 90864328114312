//core
import React, {useRef} from 'react';
import { Modal, Fade, Box, IconButton } from '@mui/material';
import { OverridableStringUnion } from "@mui/types";
import { IconButtonPropsColorOverrides } from "@mui/material/IconButton/IconButton";
import CloseIcon from '@mui/icons-material/Close';

//hooks
import {useOnClickOutside} from "../../../customHooks/useOnClickOutside";


interface IMuiModal {
  width?: number;
  open: boolean;
  setToggleModal: (arg0: boolean) => void;
  closeOnOutside?: boolean;
  children: React.ReactNode;
  className?: string;
  closeBtnColor?: OverridableStringUnion<
    'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'white',
    IconButtonPropsColorOverrides
  >;
  withoutCloseIcon?: boolean;
}

const MuiModal = (props: IMuiModal) => {
  const ref: any = useRef<HTMLDivElement>();

  const {
    width = 472,
    closeOnOutside = true,
    open,
    setToggleModal,
    className,
    children,
    closeBtnColor = 'secondary',
    withoutCloseIcon = false
  } = props;

  const handleClose = () => setToggleModal(false);

  useOnClickOutside(ref, closeOnOutside ? handleClose : () => {});

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        hideBackdrop={true}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          bgcolor: 'rgba(34,34,34,0.8)',
          overflowY: 'auto',
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              width: '100%',
              maxWidth: `${width}px`,
              minHeight: 'calc(100% - 80px)',
              margin: '40px auto',
              outline: 'none',
              padding: '0 16px'
            }}
          >
            <Box
              sx={{ width: '100%' }}>
              <Box
                ref={ref}
                className={className}
                sx={{
                  position: 'relative',
                  width: '100%',
                  bgcolor: 'background.paper',
                  borderRadius: 0.5,
                  boxShadow: 2,
                  padding: {
                    xs: '32px 16px',
                    sm: '32px 24px',
                    md: '40px 36px',
                  },
                }}
              >
                {
                  !withoutCloseIcon && (
                    <IconButton
                      sx={{
                        position: 'absolute',
                        right: '10px',
                        top: '10px'
                      }}
                      color={closeBtnColor}
                      aria-label="delete"
                      size="small"
                      onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  )
                }
                {children}
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default MuiModal;
