//core
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {dashboardService} from "../../../services/admin/dashboard.service";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";
import {setUser} from "../../common/user";
import {setGeneralProgressHide, setGeneralProgressShow} from "../../common/ui";


type statisticsItem = {
  clicks_count: number | string,
  views_count: number | string,
  registration_count: number | string,
  clien_count: number | string,
  profit: number | string,
}

export type dashboardStats = {
  statistics: {
    today: statisticsItem,
    yesterday: statisticsItem,
    week: statisticsItem,
    last_week: statisticsItem,
    month: statisticsItem,
    last_month: statisticsItem,
  },
  chart: {
    by_day: object,
    by_months: object,
  },
  date_update_statistic: string,
  locale_key_chart: string,
}

export type dashboardSliceState = {
  stats: {
    data: dashboardStats | null,
    error: any,
    isFetching: boolean,
  }
}

const initialState: dashboardSliceState = {
  stats: {
    data: null,
    error: null,
    isFetching: false,
  }
}

export const getStatsData: any = createAsyncThunk(
  'admin/dashboard/stats',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await dashboardService.getStats();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(setGeneralProgressHide());
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const dashboardSlice = createSlice({
  name: 'admin/dashboard',
  initialState: initialState,
  reducers: {
    clearStatsState(state) {
      state.stats.data = null;
      state.stats.error = null;
      state.stats.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<dashboardSliceState>>) => {
    builder.addCase(getStatsData.pending, (state ) => {
      state.stats.isFetching = true;
      state.stats.error = null;
    });
    builder.addCase(getStatsData.fulfilled, (state , action) => {
      state.stats.error = null;
      state.stats.isFetching = false;
      state.stats.data = action.payload;
    });
    builder.addCase(getStatsData.rejected, (state , action) => {
      state.stats.error = action.payload;
    });
  }
})

export default dashboardSlice.reducer;

export const {
  clearStatsState,
} = dashboardSlice.actions;
