//core
import { createSelector } from "@reduxjs/toolkit";

//store
import { RootState } from "../../index";

// state
const paymentHistoryWidgetsSelector = (state: RootState) => state.affiliate.payment_history.widgets;
const paymentHistoryWidgetsDataSelector = (state: RootState) => state.affiliate.payment_history.widgets.data;
const paymentHistoryWidgetsErrorSelector = (state: RootState) => state.affiliate.payment_history.widgets.error;
const paymentHistoryWidgetsFetchingSelector = (state: RootState) => state.affiliate.payment_history.widgets.isFetching;

//selectors
export const selectPaymentHistoryWidgets = createSelector(paymentHistoryWidgetsSelector, (filters) => filters);
export const selectPaymentHistoryWidgetsData = createSelector(paymentHistoryWidgetsDataSelector, (data) => data);
export const selectPaymentHistoryWidgetsError = createSelector(paymentHistoryWidgetsErrorSelector, (error) => error);
export const selectPaymentHistoryWidgetsIsFetching = createSelector(paymentHistoryWidgetsFetchingSelector, (isFetching) => isFetching);

//List
const paymentHistoryListDataSelector = (state: RootState) => state.affiliate.payment_history.list.data;
const paymentHistoryListErrorSelector = (state: RootState) => state.affiliate.payment_history.list.error;
const paymentHistoryListFetchingSelector = (state: RootState) => state.affiliate.payment_history.list.isFetching;

export const selectListData = createSelector(paymentHistoryListDataSelector, (data) => {
  if (!!data) {
    const columns = data.columns.map((item: any) => {
      if (item.name === "paid") {
        return { ...item, type: 'affPaymentDetails' };
      }
      return item;
    });

    return { ...data, columns: columns };
  } else {
    return data;
  }
});
export const selectListError = createSelector(paymentHistoryListErrorSelector, (error) => error);
export const selectListIsFetching = createSelector(paymentHistoryListFetchingSelector, (isFetching) => isFetching);
export const selectListDataColumnsSort = createSelector(paymentHistoryListDataSelector, (data) => data?.columns.filter((el: any) => el.sort === true));


//paymentDetails
const paymentHistoryPaymentDetailsSelector = (state: RootState) => state.affiliate.payment_history.paymentDetails;
const paymentHistoryPaymentDetailsDataSelector = (state: RootState) => state.affiliate.payment_history.paymentDetails.data;
const paymentHistoryPaymentDetailsErrorSelector = (state: RootState) => state.affiliate.payment_history.paymentDetails.error;
const paymentHistoryPaymentDetailsFetchingSelector = (state: RootState) => state.affiliate.payment_history.paymentDetails.isFetching;

//selectors
export const selectPaymentHistoryPaymentDetails = createSelector(paymentHistoryPaymentDetailsSelector, (data) => data);
export const selectPaymentHistoryPaymentDetailsData = createSelector(paymentHistoryPaymentDetailsDataSelector, (data) => {
  if (!!data) {
    const columns = data.columns.map((item: any) => {
      if (item.name === "comment") {
        return {
          ...item,
          type: 'mediumString'
        }
      } else {
        return item
      }
    });

    const list = data.list.map((item: any) => {
      return { ...item, paidAt: item.paymentDate };
    });

    return {
      ...data,
      columns: columns,
      list: list,
      total_entries: data.list.length,
      start_row_num: 1,
      end_row_num: data.list.length,
      total_pages: 1,
    }
  } else {
    return data;
  }
});
export const selectPaymentHistoryPaymentDetailsError = createSelector(paymentHistoryPaymentDetailsErrorSelector, (error) => error);
export const selectPaymentHistoryPaymentDetailsIsFetching = createSelector(paymentHistoryPaymentDetailsFetchingSelector, (isFetching) => isFetching);
