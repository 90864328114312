//core
import React, { useRef } from "react";
import arePropsEqual from "react-fast-compare";
import { makeStyles } from "@mui/styles";
import { Box, Tooltip } from "@mui/material";
import useDimensions from "../../../../../customHooks/useDimensions";

interface IArrayProps {
  value: string[] | number[];
  maxWidth?: number;
  showAll?: boolean,
}

const useStyles = makeStyles({
  block: {
    position: "relative",
    color: "inherit",
    display: "block",
    width: "100%",
    minWidth: "60px",
  },
  wrapper: {
    width: "max-content",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  wrapperAll: {
    width: "max-content",
    maxWidth: "100%",
  },
  item: {
    display: "inline-block",
    whiteSpace: "nowrap",
    marginRight: "4px",
  },
  hide: {
    opacity: 0,
    visibility: "hidden",
  }
});

const ArrayOfStrings = ({
  value,
  maxWidth = 320,
  showAll = false,
}: IArrayProps) => {
  const styles = useStyles();
  const wrapperRef: any = useRef(null);
  const size = useDimensions(wrapperRef);

  const list = value.map((item, index) => {
    return (
      <span key={`${Math.random()}-${index}`} className={styles.item}>
        {item}{index+1 !== value.length && ','}
      </span>
    )
  });

  return (
    <>
      <Box className={styles.block} sx={{ maxWidth: `${maxWidth}px` }}>
        <Box ref={wrapperRef} className={styles.wrapper}
             sx={{ opacity: 0, visibility: "hidden", position: "absolute" }}
        >
          { list }
        </Box>
        {showAll ? (
          <Box className={styles.wrapperAll}>
            { list }
          </Box>
        ) : (<>
          {
            (size.width === maxWidth) ? (
              <Tooltip title={list}>
                <Box className={styles.wrapper}>
                  { list }
                </Box>
              </Tooltip>
            ) : (
              <Box className={styles.wrapper}>
                { list }
              </Box>
            )
          }
        </>)}
      </Box>
    </>
  );
};


export default React.memo(ArrayOfStrings, arePropsEqual);
