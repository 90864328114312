import { makeStyles } from "@mui/styles";

export const useHeaderStyles = makeStyles({
  header: {
    marginBottom: 16,
    '@media (max-width: 767px)': {
      marginBottom: 16,
    },
    '&.noMargin': {
      '@media (max-width: 767px)': {
        marginBottom: 0,
      }
    },
    '&.noMarginDesktop': {
      marginBottom: 0,
    }
  },

  headerTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 8,
    '@media (max-width: 767px)': {
      marginBottom: 0,
    },
    '& > nav': {
      marginBottom: '0!important',
    }
  },

  headerTopWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    maxWidth: '60%',
    '@media only screen and (max-width: 940px)': {
      marginTop: 6,
      width: '100%',
    }
  },

  headerBottom: {
    width: '100%',
    // display: "flex",
    // alignItems: 'center',
    // justifyContent: 'space-between',
    // flexDirection: 'column',
    marginBottom: 16,
    '@media (max-width: 767px)': {
      marginBottom: 16,
    },
    '& > *': {
      '&:last-child': {
        marginBottom: '0!important',
      }
    }
  },

  headerBottomActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 16,
    paddingTop: 16,
  },

  headerSearch: {
    width: "100%",
    maxWidth: 600,
    '@media (max-width: 1500px)': {
      maxWidth: 452,
    },
    '@media (max-width: 1024px)': {
      maxWidth: 400,
    },
    '@media (max-width: 767px)': {
      maxWidth: '100%',
    },
  },

  headerSearchSmall: {
    maxWidth: 600,
    '@media (max-width: 1500px)': {
      maxWidth: 452,
    },
    '@media (max-width: 1024px)': {
      maxWidth: 400,
    },
    '@media (max-width: 840px)': {
      maxWidth: 200,
    },
    '@media (max-width: 767px)': {
      maxWidth: '100%',
    },
  },

  headerSearchExtraSmall: {
    width: "100%",
    maxWidth: 600,
    '@media (max-width: 1500px)': {
      maxWidth: 452,
    },
    '@media (max-width: 1200px)': {
      maxWidth: 300,
    },
    '@media (max-width: 1024px)': {
      maxWidth: 300,
    },
    '@media (max-width: 767px)': {
      maxWidth: '100%',
    },
  },

  headerSearchWithButton: {
    width: "100%",
    maxWidth: 452,
    '@media (max-width: 1300px)': {
      maxWidth: 340,
    },
    '@media (max-width: 1024px)': {
      maxWidth: 300,
    },
    '@media (max-width: 840px)': {
      maxWidth: 200,
    },
    '@media (max-width: 767px)': {
      maxWidth: '100%',
    },
  },

  headerGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    maxWidth: "60%",
  },

  headerBottomWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "100%",
    '& > *': {
      marginBottom: '0!important',
    }
  },

  headerBottomWrapperStart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    maxWidth: "100%",
    gap: 8,
    '& > *': {
      marginBottom: '0!important',
    }
  },

  pageTitle: {
    lineHeight: '40px',
    '@media (max-width: 767px)': {
      lineHeight: '1.6',
      marginBottom: 16,
    },
  },

  contentWrapper: {
    paddingTop: 4,
  },
});

