//core
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";

//service
import {inviteAdminService} from "../../../services/common/inviteAdmin.service";

export type inviteAdminSliceState = {
  inviteAdmin: {
    data: any,
    error: any,
    isFetching: boolean,
  }
}

const initialState: inviteAdminSliceState = {
  inviteAdmin: {
    data: null,
    error: null,
    isFetching: false,
  },
}

export const sendAdminPasswordData: any = createAsyncThunk(
  'sendAdminPasswordData',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await inviteAdminService.inviteAdmin(payload);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);


//slice
const inviteAdminSlice = createSlice({
  name: 'invite_admin',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<inviteAdminSliceState>>) => {
    builder.addCase(sendAdminPasswordData.pending, (state ) => {
      state.inviteAdmin.isFetching = true;
      state.inviteAdmin.error = null;
    });
    builder.addCase(sendAdminPasswordData.fulfilled, (state, action ) => {
      state.inviteAdmin.error = null;
      state.inviteAdmin.isFetching = false;
      state.inviteAdmin.data = action.payload;
    });
    builder.addCase(sendAdminPasswordData.rejected, (state, action ) => {
      state.inviteAdmin.error = action.payload;
    });
  },
});

export default inviteAdminSlice.reducer;

export const {
  reset
} = inviteAdminSlice.actions;
