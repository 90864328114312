//core
import React, { useRef } from "react";
import arePropsEqual from "react-fast-compare";
import {makeStyles, useTheme} from "@mui/styles";
import { Box, Tooltip } from "@mui/material";
import useDimensions from "../../../../../customHooks/useDimensions";

import {
  CheckCircleOutlineOutlined,
  CancelOutlined,
} from '@mui/icons-material';
import {Theme} from "@mui/material/styles/createTheme";


interface ILinkProps {
  value: string;
  maxWidth: number;
  confirmed?: boolean;
  showConfirmedIcon?: boolean;
  fullWidth?: boolean;
  className?: string;
}

const useStyles = makeStyles({
  blockWrapper: {
    display: "flex",
    alignItems: "center"
  },
  text: {
    color: "inherit",
    width: "150px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
    //wordBreak: 'break-word'
  },
  block: {
    position: "relative",
    color: "inherit",
    display: "block",
    width: "100%",
    minWidth: "60px",
  },
  wrapper: {
    width: "max-content",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  icon: {
    width: 20,
    height: 20,
    marginLeft: 2,
    '& svg': {
      width: 20,
      height: 20,
    }
  }
});


const EmailLink = ({
  value,
  maxWidth = 200,
  confirmed = false,
  showConfirmedIcon = false,
  fullWidth = false,
  className = '',
}: ILinkProps) => {
  const theme: Theme = useTheme();
  const styles = useStyles();
  const wrapperRef: any = useRef(null);
  const size = useDimensions(wrapperRef);

  return (
      <Box className={`${styles.blockWrapper} ${className}`}>
        <Box
          className={styles.block}
          sx={{
            maxWidth: `${fullWidth ? 'fit-content' : `${maxWidth}px`}`,
            width: `${fullWidth ? 'auto' : `100%`}`
          }}
        >
          {fullWidth ? <>
            <Box className={styles.wrapper} sx={{ width: "auto", }}>
              { !!value ? (
                <a
                  href={`mailto:${value}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.text}
                  style={{ width: "auto" }}
                >
                  {value}
                </a>
              ) : '--'}
            </Box>
          </> : <>
            <Box ref={wrapperRef} className={styles.wrapper}
                 sx={{ opacity: 0, visibility: "hidden", position: "absolute" }}
            >
              { value }
            </Box>
            {
              (size.width === maxWidth) ? (
                <Tooltip title={value}>
                  <a href={`mailto:${value}`} target="_blank" rel="noopener noreferrer" className={styles.text}>
                    { value }
                  </a>
                </Tooltip>
              ) : (
                <Box className={styles.wrapper}>
                  { !!value ? (
                    <a href={`mailto:${value}`} target="_blank" rel="noopener noreferrer" className={styles.text}>
                      {value}
                    </a>
                  ) : '--'
                  }
                </Box>
              )
            }
          </>}
        </Box>
        {showConfirmedIcon && (<Box className={styles.icon}>
          {confirmed
            ? <CheckCircleOutlineOutlined color="primary" />
            : <CancelOutlined sx={{ color: theme.palette.action.active }} />
          }
        </Box>)}
      </Box>
  );
};

export default React.memo(EmailLink, arePropsEqual);
