//core
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";

//service
import { s2sService } from "../../../services/affiliate/s2s.service";
import {
  setGeneralProgressHide, setGeneralProgressShow,
  setGeneralSnackbarState,
} from "../../common/ui";
import {setUser} from "../../common/user";

export type s2sSliceState = {
  list: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  s2sData: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  create: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  copy: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  remove: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  activate: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  deactivate: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  webhook: {
    data:any,
    error: any,
    isFetching: boolean,
  },
  updateUrlOption: {
    data:any,
    error: any,
    isFetching: boolean,
  },
  urlOption: {
    data:any,
    error: any,
    isFetching: boolean,
  },
  createUrlOption: {
    data:any,
    error: any,
    isFetching: boolean,
  },
  removeUrlOption: {
    data:any,
    error: any,
    isFetching: boolean,
  },
  updateWebhook: {
    data:any,
    error: any,
    isFetching: boolean,
  },
}

const initialState: s2sSliceState = {
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  s2sData: {
    data: null,
    error: null,
    isFetching: false,
  },
  create: {
    data: null,
    error: null,
    isFetching: false,
  },
  copy: {
    data: null,
    error: null,
    isFetching: false,
  },
  remove: {
    data: null,
    error: null,
    isFetching: false,
  },
  activate: {
    data: null,
    error: null,
    isFetching: false,
  },
  deactivate: {
    data: null,
    error: null,
    isFetching: false,
  },
  webhook: {
    data: null,
    error: null,
    isFetching: false,
  },
  updateUrlOption: {
    data: null,
    error: null,
    isFetching: false,
  },
  urlOption: {
    data: null,
    error: null,
    isFetching: false,
  },
  createUrlOption: {
    data: null,
    error: null,
    isFetching: false,
  },
  removeUrlOption: {
    data: null,
    error: null,
    isFetching: false,
  },
  updateWebhook: {
    data: null,
    error: null,
    isFetching: false,
  }
}

export const getListData: any = createAsyncThunk(
  's2s/list',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await s2sService.getListData(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


export const createWebhook: any = createAsyncThunk(
  's2s/create',
  async (payload,  { rejectWithValue, dispatch }) => {
    try {
      const response = await s2sService.createWebhook(payload);
      const data = await response.json();
      if (response.ok && response.status === 201) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.created',
          })
        );
      }

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);


export const copyWebhook: any = createAsyncThunk(
  's2s/copy',
  async (payload,  { rejectWithValue, dispatch }) => {
    try {
      const response = await s2sService.copyWebhook(payload);
      const data = await response.json();
      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.copied',
          })
        );
      }

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const removeWebhook: any = createAsyncThunk(
  's2s/remove',
  async (payload,  { rejectWithValue, dispatch }) => {
    try {
      const response = await s2sService.removeWebhook(payload);
      const data = await response.json();
      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.removed',
          })
        );
      }

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);


export const activateWebhook: any = createAsyncThunk(
  's2s/activate',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await s2sService.activateWebhook(payload);
      const data = await response.json();
      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const deactivateWebhook: any = createAsyncThunk(
  's2s/deactivate',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await s2sService.deactivateWebhook(payload);
      const data = await response.json();
      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getWebhook: any = createAsyncThunk(
  's2s/webhook',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await s2sService.getWebhook(payload);
      const data = await response.json();
      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const updateUrlOption: any = createAsyncThunk(
  's2s/update_url_option',
  async (payload,  { rejectWithValue, dispatch }) => {
    try {
      const response = await s2sService.updateUrlOption(payload);
      const data = await response.json();
      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.edited',
          })
        );
      }

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const getUrlOption: any = createAsyncThunk(
  's2s/url_option',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await s2sService.getUrlOption(payload);
      const data = await response.json();
      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);


export const createUrlOption: any = createAsyncThunk(
  's2s/create_url_option',
  async (payload,  { rejectWithValue, dispatch }) => {
    try {
      const response = await s2sService.createUrlOption(payload);
      const data = await response.json();
      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.created',
          })
        );
      }

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);


export const removeUrlOption: any = createAsyncThunk(
  's2s/remove_url_option',
  async (payload,  { rejectWithValue, dispatch }) => {
    try {
      const response = await s2sService.removeUrlOption(payload);
      const data = await response.json();
      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.removed',
          })
        );
      }

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);


export const updateWebhook: any = createAsyncThunk(
  's2s/update_webhook',
  async (payload,  { rejectWithValue, dispatch }) => {
    try {
      const response = await s2sService.updateWebhook(payload);
      const data = await response.json();
      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.edited',
          })
        );
      }

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

//slice
const s2sSlice = createSlice({
  name: 's2s',
  initialState: initialState,
  reducers: {
    s2sSetError(state, action) {
      state.s2sData.error = action.payload;
    },
    setS2sData(state, action) {
      state.s2sData.data = action.payload;
    },
    clearCreateState(state) {
      state.create.error = null;
      state.create.isFetching = false;
      state.create.data = null;
      state.updateWebhook.error = null;
      state.updateWebhook.isFetching = false;
      state.updateWebhook.data = null;
      state.remove.error = null;
      state.remove.isFetching = false;
      state.remove.data = null;
      state.copy.error = null;
      state.copy.isFetching = false;
      state.copy.data = null;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<s2sSliceState>>) => {
    builder.addCase(getListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getListData.fulfilled, (state, action ) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getListData.rejected, (state, action ) => {
      state.list.error = action.payload;
    });
    builder.addCase(createWebhook.pending, (state ) => {
      state.create.isFetching = true;
      state.create.error = null;
    });
    builder.addCase(createWebhook.fulfilled, (state, action ) => {
      state.create.error = null;
      state.create.isFetching = false;
      state.create.data = action.payload;
    });
    builder.addCase(createWebhook.rejected, (state, action ) => {
      state.create.error = action.payload;
    });
    builder.addCase(copyWebhook.pending, (state ) => {
      state.copy.isFetching = true;
      state.copy.error = null;
    });
    builder.addCase(copyWebhook.fulfilled, (state, action ) => {
      state.copy.error = null;
      state.copy.isFetching = false;
      state.copy.data = action.payload;
    });
    builder.addCase(copyWebhook.rejected, (state, action ) => {
      state.copy.error = action.payload;
    });
    builder.addCase(removeWebhook.pending, (state ) => {
      state.remove.isFetching = true;
      state.remove.error = null;
    });
    builder.addCase(removeWebhook.fulfilled, (state, action ) => {
      state.remove.error = null;
      state.remove.isFetching = false;
      state.remove.data = action.payload;
    });
    builder.addCase(removeWebhook.rejected, (state, action ) => {
      state.remove.error = action.payload;
    });
    builder.addCase(activateWebhook.pending, (state ) => {
      state.activate.isFetching = true;
      state.activate.error = null;
    });
    builder.addCase(activateWebhook.fulfilled, (state, action ) => {
      state.activate.error = null;
      state.activate.isFetching = false;
      state.activate.data = action.payload;
    });
    builder.addCase(activateWebhook.rejected, (state, action ) => {
      state.activate.error = action.payload;
    });
    builder.addCase(deactivateWebhook.pending, (state ) => {
      state.deactivate.isFetching = true;
      state.deactivate.error = null;
    });
    builder.addCase(deactivateWebhook.fulfilled, (state, action ) => {
      state.deactivate.error = null;
      state.deactivate.isFetching = false;
      state.deactivate.data = action.payload;
    });
    builder.addCase(deactivateWebhook.rejected, (state, action ) => {
      state.deactivate.error = action.payload;
    });
    builder.addCase(getWebhook.pending, (state ) => {
      state.webhook.isFetching = true;
      state.webhook.error = null;
    });
    builder.addCase(getWebhook.fulfilled, (state, action ) => {
      state.webhook.error = null;
      state.webhook.isFetching = false;
      state.webhook.data = action.payload;
    });
    builder.addCase(getWebhook.rejected, (state, action ) => {
      state.webhook.error = action.payload;
    });
    builder.addCase(updateUrlOption.pending, (state ) => {
      state.updateUrlOption.isFetching = true;
      state.updateUrlOption.error = null;
    });
    builder.addCase(updateUrlOption.fulfilled, (state, action ) => {
      state.updateUrlOption.error = null;
      state.updateUrlOption.isFetching = false;
      state.updateUrlOption.data = action.payload;
    });
    builder.addCase(updateUrlOption.rejected, (state, action ) => {
      state.updateUrlOption.error = action.payload;
    });
    builder.addCase(getUrlOption.pending, (state ) => {
      state.urlOption.isFetching = true;
      state.urlOption.error = null;
    });
    builder.addCase(getUrlOption.fulfilled, (state, action ) => {
      state.urlOption.error = null;
      state.urlOption.isFetching = false;
      state.urlOption.data = action.payload;
    });
    builder.addCase(getUrlOption.rejected, (state, action ) => {
      state.urlOption.error = action.payload;
    });
    builder.addCase(createUrlOption.pending, (state ) => {
      state.createUrlOption.isFetching = true;
      state.createUrlOption.error = null;
    });
    builder.addCase(createUrlOption.fulfilled, (state, action ) => {
      state.createUrlOption.error = null;
      state.createUrlOption.isFetching = false;
      state.createUrlOption.data = action.payload;
    });
    builder.addCase(createUrlOption.rejected, (state, action ) => {
      state.createUrlOption.error = action.payload;
    });
    builder.addCase(removeUrlOption.pending, (state ) => {
      state.removeUrlOption.isFetching = true;
      state.removeUrlOption.error = null;
    });
    builder.addCase(removeUrlOption.fulfilled, (state, action ) => {
      state.removeUrlOption.error = null;
      state.removeUrlOption.isFetching = false;
      state.removeUrlOption.data = action.payload;
    });
    builder.addCase(removeUrlOption.rejected, (state, action ) => {
      state.removeUrlOption.error = action.payload;
    });
    builder.addCase(updateWebhook.pending, (state ) => {
      state.updateWebhook.isFetching = true;
      state.updateWebhook.error = null;
    });
    builder.addCase(updateWebhook.fulfilled, (state, action ) => {
      state.updateWebhook.error = null;
      state.updateWebhook.isFetching = false;
      state.updateWebhook.data = action.payload;
    });
    builder.addCase(updateWebhook.rejected, (state, action ) => {
      state.updateWebhook.error = action.payload;
    });

  },
});

export default s2sSlice.reducer;

export const {
  s2sSetError,
  setS2sData,
  clearCreateState,
} = s2sSlice.actions;
