import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  block: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: 4,
    marginBottom: 16,
  },
  wrapper: {
    display: 'block',
    flex: '1 1 auto',
    padding: '7px 16px',
    borderRadius: 4,
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    boxShadow: 'none!important',
    '&.dark': {},
    '&.light': {
      background: theme.palette.grey[100],
    },
  },
  radio: {
    margin: 0,
  },

  header: {
    position: 'relative',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 4,
  },
  iconBtn: {
    padding: 4,
  },
  nameWrapper: {
    display: 'block',
    overflow: 'hidden',
    flex: '1 1 auto',
    maxWidth: 'calc(100% - 64px)',
    '&.full': {
      maxWidth: '100%',
    }
  },

  fieldName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    gap: 4,
  },
  name: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
  },

  collapseContent: {
    padding: '24px 0 10px',
  },
  linearLoader: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
}));
