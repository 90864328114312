import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles({
  filters: {
    marginBottom: 0,
    '@media (max-width: 767px)': {
      marginBottom: 0,
    }
  },
  content: {},
  table: {
    paddingTop: 16,
    '@media (max-width: 767px)': {
      paddingTop: 16,
    }
  },
  tableHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    '@media (max-width: 767px)': {
      marginTop: 20,
    },
    '& > div': {
      maxWidth: '100%',
    },
    '&.noMargin': {
      '@media (max-width: 767px)': {
        marginTop: 0,
      },
    }
  },
});
