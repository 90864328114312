import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles({
  filters: {
    marginBottom: 0,
    '@media (max-width: 767px)': {
      marginBottom: 0,
    }
  },
  filterWrapper: {
    padding: '16px 36px',
    '@media (max-width: 1200px)': {
      padding: '16px 24px',
    }
  },
  content: {},
  tableHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 0,
  },
});
