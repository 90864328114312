import {useDispatch} from "react-redux";

import {
  getOptionsData,
  getListData,
  setDownloadData,
  clearDownload,
  clearListState,
  reset,
  getFiltersData,
  getDetailsWebhookData,
  clearDetailsWebhook,
  getColumnsData,
  clearColumnsState,
} from "./index";

export const useWebhooksReport = () => {
  const dispatch = useDispatch();

  const getOptions = () => dispatch(getOptionsData());
  const getList = (data: any) => dispatch(getListData(data));
  const setDownload = (data: any) => dispatch(setDownloadData(data));

  const clearListData = () => dispatch(clearListState());
  const resetState = () => dispatch(reset());
  const clearDownloadState = () => dispatch(clearDownload());
  const clearDetailsWebhookState = () => dispatch(clearDetailsWebhook());

  const getFiltersList = (data:any) => dispatch(getFiltersData(data));
  const getDetailsWebhook = (data:any) => dispatch(getDetailsWebhookData(data));

  const getColumns = () => dispatch(getColumnsData());
  const clearColumns = () => dispatch(clearColumnsState());

  return {
    getOptions,
    getList,
    setDownload,
    clearDownloadState,
    clearListData,
    resetState,
    getFiltersList,
    clearDetailsWebhookState,
    getDetailsWebhook,
    getColumns,
    clearColumns,
  }
}
