//core
import React from 'react';
import {useTranslation} from "react-i18next";
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import {Box, IconButton, InputAdornment} from '@mui/material';


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.action.selected, theme.palette.action.selectedOpacity),
  // '&:hover': {
  //   //backgroundColor: alpha(theme.palette.secondary.dark, 0.1),
  // },
  marginRight: 0,
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: '100%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.secondary.light,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(0.5, 1, 0.5, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: '36px',
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
}));

interface IPrimarySearch {
  value: string,
  name: string;
  placeholder?: string;
  autoCompleteOff?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  className?: any;
  disabled?: boolean;
}

const PrimarySearch = (props: IPrimarySearch) => {
  const { t } = useTranslation();
  const {
    value,
    name,
    placeholder = t("common.forms.fields.search"),
    autoCompleteOff = false,
    onChange,
    onClear,
    className,
    disabled = false
  } = props;

  return (
    <Box>
      <Search className={className}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          autoComplete={autoCompleteOff ? 'off' : name}
          disabled={disabled}
          inputProps={{
            'aria-label': 'search',
            ...(autoCompleteOff ? { autoComplete: 'off' } : {})
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                size="small"
                aria-label="clear"
                onClick={onClear}
                onMouseDown={onClear}
                edge="end"
                sx={{ marginRight: '2px' }}
                disabled={!value.length}
              >
                <CancelIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </Search>
    </Box>
  );
};

export default PrimarySearch;
