//core
import { createSelector } from "@reduxjs/toolkit";

//store
import { RootState } from "../../index";


//Aff List
const fieldsAffiliatesDataSelector = (state: RootState) => state.fields.affiliates.data;
const fieldsAffiliatesErrorSelector = (state: RootState) => state.fields.affiliates.error;
const fieldsAffiliatesFetchingSelector = (state: RootState) => state.fields.affiliates.isFetching;

export const selectAffiliatesData = createSelector(fieldsAffiliatesDataSelector, (data) => data);
export const selectAffiliatesError = createSelector(fieldsAffiliatesErrorSelector, (error) => error);
export const selectAffiliatesIsFetching = createSelector(fieldsAffiliatesFetchingSelector, (isFetching) => isFetching);

//Chiefs List
const fieldsChiefsDataSelector = (state: RootState) => state.fields.chiefs.data;
const fieldsChiefsErrorSelector = (state: RootState) => state.fields.chiefs.error;
const fieldsChiefsFetchingSelector = (state: RootState) => state.fields.chiefs.isFetching;

export const selectChiefsData = createSelector(fieldsChiefsDataSelector, (data) => data);
export const selectChiefsError = createSelector(fieldsChiefsErrorSelector, (error) => error);
export const selectChiefsIsFetching = createSelector(fieldsChiefsFetchingSelector, (isFetching) => isFetching);

//Departments List
const fieldsDepartmentsDataSelector = (state: RootState) => state.fields.departments.data;
const fieldsDepartmentsErrorSelector = (state: RootState) => state.fields.departments.error;
const fieldsDepartmentsFetchingSelector = (state: RootState) => state.fields.departments.isFetching;

export const selectDepartmentsData = createSelector(fieldsDepartmentsDataSelector, (data) => data);
export const selectDepartmentsError = createSelector(fieldsDepartmentsErrorSelector, (error) => error);
export const selectDepartmentsIsFetching = createSelector(fieldsDepartmentsFetchingSelector, (isFetching) => isFetching);

//Platforms List
const fieldsPlatformsDataSelector = (state: RootState) => state.fields.platforms.data;
export const selectPlatformsData = createSelector(fieldsPlatformsDataSelector, (data) => data);

//Countries List
const fieldsCountriesDataSelector = (state: RootState) => state.fields.countries.data;
const fieldsCountriesErrorSelector = (state: RootState) => state.fields.countries.error;
const fieldsCountriesFetchingSelector = (state: RootState) => state.fields.countries.isFetching;

export const selectCountriesData = createSelector(fieldsCountriesDataSelector, (data) => data);
export const selectCountriesError = createSelector(fieldsCountriesErrorSelector, (error) => error);
export const selectCountriesIsFetching = createSelector(fieldsCountriesFetchingSelector, (isFetching) => isFetching);
export const selectCountriesOptionsData = createSelector(fieldsCountriesDataSelector, (data) => {
  if (!!data) {
    return data.map(item => {
      return {
        id: item.code,
        value: item.name,
      }
    })
  } else {
    return data;
  }
});

//Trackers
const fieldsTrackersDataSelector = (state: RootState) => state.fields.trackers.data;
const fieldsTrackersErrorSelector = (state: RootState) => state.fields.trackers.error;
const fieldsTrackersFetchingSelector = (state: RootState) => state.fields.trackers.isFetching;

export const selectTrackersData = createSelector(fieldsTrackersDataSelector, (data) => data);
export const selectTrackersError = createSelector(fieldsTrackersErrorSelector, (error) => error);
export const selectTrackersIsFetching = createSelector(fieldsTrackersFetchingSelector, (isFetching) => isFetching);

//Webhooks
const fieldsWebhooksDataSelector = (state: RootState) => state.fields.webhooks.data;
const fieldsWebhooksErrorSelector = (state: RootState) => state.fields.webhooks.error;
const fieldsWebhooksFetchingSelector = (state: RootState) => state.fields.webhooks.isFetching;

export const selectWebhooksData = createSelector(fieldsWebhooksDataSelector, (data) => data);
export const selectWebhooksError = createSelector(fieldsWebhooksErrorSelector, (error) => error);
export const selectWebhooksIsFetching = createSelector(fieldsWebhooksFetchingSelector, (isFetching) => isFetching);

//Offers
const fieldsOffersDataSelector = (state: RootState) => state.fields.offers.data;
const fieldsOffersErrorSelector = (state: RootState) => state.fields.offers.error;
const fieldsOffersFetchingSelector = (state: RootState) => state.fields.offers.isFetching;

export const selectOffersData = createSelector(fieldsOffersDataSelector, (data) => data);
export const selectOffersError = createSelector(fieldsOffersErrorSelector, (error) => error);
export const selectOffersIsFetching = createSelector(fieldsOffersFetchingSelector, (isFetching) => isFetching);

//Operators
const fieldsOperatorsDataSelector = (state: RootState) => state.fields.operators.data;
const fieldsOperatorsErrorSelector = (state: RootState) => state.fields.operators.error;
const fieldsOperatorsFetchingSelector = (state: RootState) => state.fields.operators.isFetching;

export const selectOperatorsData = createSelector(fieldsOperatorsDataSelector, (data) => data);
export const selectOperatorsError = createSelector(fieldsOperatorsErrorSelector, (error) => error);
export const selectOperatorsIsFetching = createSelector(fieldsOperatorsFetchingSelector, (isFetching) => isFetching);


// Brands list
const fieldsBrandsDataSelector = (state: RootState) => state.fields.brands.data;
const fieldsBrandsErrorSelector = (state: RootState) => state.fields.brands.error;
const fieldsBrandsFetchingSelector = (state: RootState) => state.fields.brands.isFetching;

export const selectBrandsData = createSelector(fieldsBrandsDataSelector, (data) => data);
export const selectBrandsError = createSelector(fieldsBrandsErrorSelector, (error) => error);
export const selectBrandsIsFetching = createSelector(fieldsBrandsFetchingSelector, (isFetching) => isFetching);