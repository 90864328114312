//core
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "usehooks-ts";
import { Box, Typography } from "@mui/material";
import { PaginationItem, TextField } from '@mui/material';

//styles
import { useStyles } from "./styles";

const exceptThisSymbols = ["e", "E", "+", "-", ".", ","];

type Total = {
  end_row_num: number;
  start_row_num: number;
  total_entries: number | string;
};

interface IMobilePaginationProps {
  currentPage: number;
  lastPage: number;
  total?: Total | null;
  setPage: (event: unknown, newPage: number) => void;
}

const MobilePagination: React.FC<IMobilePaginationProps> = ({
  currentPage,
  lastPage,
  setPage,
  total = null,
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();

  const [pageState, setPageState] = useState<number>(currentPage + 1);
  const [inputError, setInputError] = useState<boolean>(false);

  const debouncedPageState = useDebounce<number>(pageState, 800);

  useEffect(() => {
    setPageState(currentPage + 1);
  }, [currentPage]);

  useEffect(() => {
    const page = currentPage + 1;
    const newPage = Number(debouncedPageState);

    if (page !== newPage && newPage >= 0 && newPage <= lastPage) {
      setPage(null, newPage - 1);
    }
  }, [debouncedPageState]);


  const handleChangePage = (event: any) => {
    const newPage = Number(event.target.value);

    if (newPage <= 0 || newPage > lastPage) {
      setInputError(true);
    } else {
      setInputError(false);
    }

    setPageState(event.target.value);
  }
  const handleSetPage = (e: unknown, page: number) => {
    setPage(e, page);
  }


  return (<>
    {!!total && (
      <Box className={styles.total}>
        <Typography variant="subtitle2" sx={{ fontWeight: 'inherit' }} component="span">
          {`${total.start_row_num} - ${total.end_row_num}`}
        </Typography>
        <Typography variant="subtitle2" sx={{ fontWeight: 'inherit' }} component="span">
          {t("common.components.table.from")}
        </Typography>
        <Typography variant="subtitle2" component="span">
          <strong>
            {`${total.total_entries}`}
          </strong>
        </Typography>
      </Box>
    )}
    <Box className={styles.pagination}>
      <PaginationItem
        color="standard"
        type="first"
        disabled={currentPage === 0}
        onClick={(e) => handleSetPage(e, 0)}
      />
      <PaginationItem
        color="standard"
        type="previous"
        disabled={currentPage === 0}
        onClick={(e) => handleSetPage(e, currentPage - 1)}
      />

      <TextField
        className={styles.input}
        value={pageState}
        type="number"
        size="small"
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
          min: 1,
          max: lastPage,
        }}
        error={inputError}
        onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
        onChange={handleChangePage}
      />

      <PaginationItem
        color="standard"
        type="next"
        disabled={currentPage === lastPage - 1}
        onClick={(e) => handleSetPage(e,currentPage + 1)}
      />
      <PaginationItem
        color="standard"
        type="last"
        disabled={currentPage === lastPage - 1}
        onClick={(e) => handleSetPage(e,lastPage - 1)}
      />
    </Box>
  </>);
};

export default MobilePagination;
