//core
import React from "react";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

interface ITranslationTextProps {
  value: string;
  prefix: string;
  whiteSpace?: boolean;
}

const useStyles = makeStyles({
  text: {
    color: 'inherit'
  }
});

const TranslationText = ({ value, prefix, whiteSpace = false }: ITranslationTextProps) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <span className={styles.text} style={{ whiteSpace: whiteSpace ? 'nowrap' : 'normal' }}>
      {
        !!value ? t(`${prefix}${value}`) : '—'
      }
    </span>
  );
};

export default TranslationText;
