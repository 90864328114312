import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

interface IPaymentWalletProps {
  data: any;
}

const PaymentWallet: React.FC<IPaymentWalletProps> = ({
  data,
}): JSX.Element => {
  const { t } = useTranslation();

  const value = !!data && (data.hasOwnProperty('wallet_number') ? data.wallet_number : (data.hasOwnProperty('iban') ? data.iban : (data.hasOwnProperty('other') ? data.other : '—')));
  return (
    <>
      <Typography variant="body2">
        { !!value ? value : '—'}
      </Typography>
    </>
  );
};

export default PaymentWallet;
