//core
import React, {useState, useEffect, useRef} from "react";
import arePropsEqual from "react-fast-compare";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select, SelectChangeEvent,
} from "@mui/material";

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 4;

//types
interface optionItem {
  id: string | number;
  label: string;
}

interface ISelect {
  label?: string;
  name: string;
  value: any,
  options: optionItem[];
  onChange: (event: SelectChangeEvent) => void;
  multiple?: boolean;
  withQuantity?: boolean;
  withCheckbox?: boolean;
  prefix?: string;
  showSelectedAll?: boolean;
  showNoneOption?: boolean;
  disabled?: boolean;
  extraSmall?: boolean;
  fixWidth?: boolean;
  overflowX?: any;
}

const all = {
  id: 'all',
  label: 'all',
}

const CSelect = (props: ISelect) => {
  const { t, i18n } = useTranslation();
  const selectRef: any = useRef(null);
  const [fixedWidth, setFixedWidth] = useState<number | string>('auto')

  const {
    label,
    name,
    value,
    options,
    onChange,
    multiple,
    withQuantity = false,
    withCheckbox = false,
    prefix = '',
    showSelectedAll = false,
    showNoneOption = false,
    disabled = false,
    extraSmall = false,
    fixWidth = false,
    overflowX = 'hidden',
  } = props;

  const [selectedAll, setSelectedAll] = useState<boolean>(!!value && !!value.length && value[0].id === 'all');

  const renderValue = (selected: any) => {
    return withQuantity
      ? <>{`${label} ${!!selected.length ? `(${selected.length})` : ''}`}</>
      : <>{label}</>;
  };

  const labelText = withQuantity
    ? <>{`${label} ${!!value.length ? `(${value.length})` : ''}`}</>
    : <>{label}</>;

  useEffect(() => {
    if (showSelectedAll) {
      if (!value.length) {
        setSelectedAll(false)
      } else {
        if (!!value.filter((item: string) => item === 'all').length) {
          setSelectedAll(true)
        } else {
          setSelectedAll(false)
        }
      }
    }
  }, [value]);


  const handleChange = (event: SelectChangeEvent) => {

    const fieldValue: any = event.target.value;
    const name = event.target.name;
    if (showSelectedAll) {
      if (!!fieldValue.filter((item:string) => item === 'all').length) {
        const customEvent = {
          ...event,
          target: {
            name: name,
            value: ['all'],
          }
        }
        setSelectedAll(true);
        //@ts-ignore
        onChange(customEvent);
      } else {
        onChange(event);
      }
    } else {
      onChange(event);
    }
  }

  useEffect(() => {
    if (selectRef.current != null && fixWidth) {
      setFixedWidth(selectRef.current.clientWidth);
    }
  },[selectRef])

  return (
    <>
      <FormControl size="small" fullWidth>
        {
          withQuantity
            ? <>
              {
                !value.length && <InputLabel
                  disabled={disabled}
                  id={`${name}-select-label`}
                  sx={{ lineHeight: '1.437em' }}
                  { ...(withQuantity ? {shrink: false} : {}) } >
                  {labelText}
                </InputLabel>
              }
              </>
            : <InputLabel id={`${name}-select-label`} disabled={disabled}>{labelText}</InputLabel>
        }
        <Select
          labelId={`${name}-select-label`}
          id={`${name}-select`}
          name={name}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          ref={selectRef}
          {...(!withQuantity && {label: label})}
          {...(multiple ? {multiple: true} : {})}
          {...(withQuantity ? {renderValue: renderValue} : { renderValue: (value: any) => {
              if (multiple) {
                const val = value.reduce((acc: string[], item: string) => {
                  acc.push(!!prefix ? t(`${prefix}${item}`) : item);
                  return acc;
                }, []);

                return val.map((item: string, i: number) => <Box key={item} component="span">{`${item}${val.length - 1 !== i ? ', ' : ''}`}</Box>)
              } else {
                return !!prefix ? t(`${prefix}${value}`) : value
              }
            }})
          }
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 8 + (ITEM_PADDING_TOP * 2),
                width: fixedWidth,
                overflowX: overflowX
              }
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            }
          }}
          {...(extraSmall
            ? {sx: {
                '& > div': {
                  padding: '2px 14px',
                  fontSize: '14px',
                  height: '24px',
                  minHeight: '24px'
                }
              }}
            : {}
          )}
        >
          {showNoneOption && (
            <MenuItem key={`${name}-option-none`}
                      sx={{ minHeight: '36px' }}
                      value={t(`common.buttons.none`)}
            >
              {t(`common.buttons.none`)}
            </MenuItem>
          )}
          {
            showSelectedAll && (
                withCheckbox ? (
                    <MenuItem key={`${name}-option-${all.label}`} value={all.id} sx={{ minHeight: '36px' }}>
                      <Checkbox
                          sx={{padding: '0', width: 24, height: 24, marginRight: 1}}
                          checked={value.includes(all.id)}
                      />
                      {!!prefix ? t(`${prefix}${all.label}`) : all.label}
                    </MenuItem>
                ) : (
                    <MenuItem key={`${name}-option-${all.label}`}
                              sx={{ minHeight: '36px' }}
                              value={all.id}
                    >
                      {!!prefix ? t(`${prefix}${all.label}`) : all.label}
                    </MenuItem>
                )
            )
          }
          {
            options.map((item) => {
              return (
                  withCheckbox ? (
                      <MenuItem key={`${name}-option-${item.label}`}
                                disabled={selectedAll}
                                value={item.id}
                                sx={{ minHeight: '36px' }}
                      >
                        <Checkbox
                            sx={{padding: '0', width: 24, height: 24, marginRight: 1}}
                            checked={value.includes(item.id)}
                        />
                        {!!prefix ? t(`${prefix}${item.label}`) : item.label}
                      </MenuItem>
                  ) : (
                      <MenuItem key={`${name}-option-${item.label}`}
                                disabled={selectedAll}
                                sx={{ wordBreak: "break-all", minHeight: '36px' }}
                                value={item.id}
                      >
                        {!!prefix ? t(`${prefix}${item.label}`) : item.label}
                      </MenuItem>
                  )
              )
            })
          }
        </Select>
      </FormControl>
    </>
  );
};

export default React.memo(CSelect, arePropsEqual)
