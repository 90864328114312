import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import {
  ListPayloadType,
} from "../../store/admin/offers";
import { cookieToken } from "../../constants";


const getFilters = () => {
  const authenticity_token = getCookie(cookieToken);
  const endpoint = `${api.admin.offers.filters}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`;

  return fetch(
    `${endpoint}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getList = (data: ListPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.offers.list;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const setCreateOffer = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.offers.create;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const deleteOffer = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.offers.delete.replace('{id}', data.id);

  const payload = {
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('DELETE', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getOffer = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const endpoint = api.admin.offers.get.replace('{id}', `${data.id}`);

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const updateOffer = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.offers.update.replace('{id}', `${data.id}`);

  const payload = {
    ...data.payload,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const copyOffer = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.offers.copy.replace('{id}', data.id);

  const payload = {
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getCreateOptions = () => {
  const authenticity_token = getCookie(cookieToken);
  const endpoint = api.admin.offers.createOptions;

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

export const offersService = {
  getFilters,
  getList,
  setCreateOffer,
  deleteOffer,
  getOffer,
  updateOffer,
  getCreateOptions,
  copyOffer,
}
