export const oldFront = process.env.REACT_APP_OLD_FRONT_LINK;

export const affiliateLinks = {
  affiliate_trackers: `${oldFront}#/affiliate_trackers`,
  creatives_link: `${oldFront}#/creatives_link`,
  webhooks: `${oldFront}#/webhooks?type=registration`,
  api_stats: `${oldFront}#/affiliate_api`,
  affiliate_reference: `${oldFront}#/affiliate_reference`,
  affiliate_reports: `${oldFront}#/affiliate_reports`,
  affiliate_subaffiliate_reports: `${oldFront}#/affiliate_subaffiliate_reports`,
  affiliate_creative_reports: `${oldFront}#/affiliate_creative_reports`,
  affiliate_download_reports: `${oldFront}#/affiliate/download_csv`,
  news: `${oldFront}#/affiliate/articles`,
  faq: `${oldFront}#/faq`,
  //bot: `https://t.me/Pmaffiliates`,
};

export const adminLinks = {
  root: `${oldFront}`,
  partners: `${oldFront}#/partners`,
  partners_department: `${oldFront}#/partners_department`,
  partners_chief: `${oldFront}#/partners_chief`,
  new_partners: `${oldFront}#/new_partners`,
  requests_history: `${oldFront}#/request_history`,
  unconfirmed_partners: `${oldFront}#/partners_with_unconfirmed_emails`,
  reports: `${oldFront}#/reports/v3`,
  webhooks_tasks: `${oldFront}#/webhooks_tasks`,
  subaffiliate_reports: `${oldFront}#/subaffiliate_reports`,
  creative_reports: `${oldFront}#/creative_reports`,
  download_csv: `${oldFront}#/admin/download_csv`,

  players: `${oldFront}#/players`,

  conditions: `${oldFront}#/conditions`,
  subaffiliate_conditions: `${oldFront}#/subaffiliate_conditions`,
  trackers: `${oldFront}#/trackers`,
  creatives: `${oldFront}#/creatives?type=link`,
  articles: `${oldFront}#/admin/articles`,

  personal: `${oldFront}#/personal`,
  group_of_managers: `${oldFront}#/group_of_managers`,

  settings: `${oldFront}#/settings/company`,
  permissions_manager: `${oldFront}#/admin/permissions_manager`,
  translate_manager: `${oldFront}#/admin/translate_manager`,
  logbooks: `${oldFront}#/logbooks`,
  api_tokens: `${oldFront}#/quints_api`,
};


// theme
const containerWidth = '1200px';
export { containerWidth };

// cookies
export const cookieSupervision = 'isSupervision';
export const cookieFullStat = 'full_stat';
export const cookieToken = 'XSRF-TOKEN-X';
export const cookieTheme = 'theme';
