//core
import React, {useCallback, useContext, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { Scrollbars } from "react-custom-scrollbars";
import { useLocalStorage } from "usehooks-ts";
import arePropsEqual from "react-fast-compare";
import {
  Box,
  useMediaQuery,
} from "@mui/material";
import Button from "../../../../../../UI/Buttons/Button";

//styles
import { useStyles } from "./styles";

//hooks
import useDidMountEffect from "../../../../../../../customHooks/useDidMountEffect";
import { useFields } from "../../../../../../../store/common/fields/useFields";
import { usePartners } from "../../../../../../../store/admin/partners/usePartners";
import { useEmployees } from "../../../../../../../store/admin/employees/useEmployees";

//context
import { GlobalContext } from "../../../../../../../context/GlobalContext";

//constants
import { favoriteFilterKeys } from "../../../../constants";

//components
import FavoriteCheckboxField from "../../../../../../UI/Fields/FavoriteCheckboxField";
import MuiAutocompleteSelectLazy from "../../../../../../UI/Fields/MuiAutocompleteSelectLazy";
import MuiAutocompleteSelect from "../../../../../../UI/Fields/MuiAutocompleteSelect";


interface IFilterData {
  [key: string]: any;
}

interface IFilterProps {
  initialFilter: IFilterData;
  activeField: string;
  setUpdateFilter: (data: any) => void;
  setToggleDrawer: (data: boolean) => void;
}

const Filter: React.FC<IFilterProps> = ({
  initialFilter,
  activeField,
  setUpdateFilter,
  setToggleDrawer,
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();
  const mobile = useMediaQuery('(max-width:767px)');

  const { getPlatforms } = useFields();
  const { getDepartments } = usePartners();
  const { getFilterRoles } = useEmployees();

  const context = useContext(GlobalContext);

  const [filter, setFilter] = useState<IFilterData>(context.admEmployeesFilter);

  //favorite fields state
  const [favAdmEmployeesDepartments, setFavAdmEmployeesDepartments] = useLocalStorage<boolean>(favoriteFilterKeys.departments, false);
  const [favAdmEmployeesStatus, setFavAdmEmployeesStatus] = useLocalStorage<boolean>(favoriteFilterKeys.status, false);
  //const [favAdmEmployeesCompany, setFavAdmEmployeesCompany] = useLocalStorage<boolean>(favoriteFilterKeys.company, false);
  const [favAdmEmployeesRoles, setFavAdmEmployeesRoles] = useLocalStorage<boolean>(favoriteFilterKeys.roles, false);
  const [favAdmEmployeesPlatforms, setFavAdmEmployeesPlatforms] = useLocalStorage<boolean>(favoriteFilterKeys.platforms, false);

  const [platformsList, setPlatformsList] = useState<any | null>(null);

  useEffect( () => {
    if (!platformsList) {
      (async () => {
        const data = await getPlatforms({});
        setPlatformsList(data.payload.list)
      })()
    }
  }, [platformsList]);

  useDidMountEffect(() => {
    context.setAdmEmployeesFilter(filter);
  }, [filter]);

  const handleApplyFilter = useCallback(() => {
    setUpdateFilter(filter);
    setToggleDrawer(false);
  }, [filter]);

  const fieldPayload = () => {
    return {
      page: 1,
      search: null,
      per_page: 100,
    }
  };

  const fieldDepartmentsPayload = () => {
    return {
      page: 1,
      search: null,
      per_page: 100,
      chiefIds: null,
      departmentIds: null,
      platformIds: null,
    }
  };

  return (
    <>
      <Box className={styles.wrapper}>
        <Scrollbars
          className={styles.customScroll}
          style={{ overflow: "hidden" }}
          hideTracksWhenNotNeeded={true}
          renderView={props => <div {...props} className="view"/>}
          renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
          renderTrackVertical={props => <div {...props} className="track-vertical"/>}
        >
          <Box className={styles.box}>
            <Box className={styles.fieldWrapper}>
              {!mobile && (
                <Box className={styles.fieldFavorite}>
                  <FavoriteCheckboxField
                    checked={favAdmEmployeesDepartments}
                    setChecked={setFavAdmEmployeesDepartments}
                    fieldName={t(`admin.employees.form.departments.label`)}
                  />
                </Box>
              )}
              <Box className={styles.field}>
                <MuiAutocompleteSelectLazy
                  keyValue="value"
                  keyId="id"
                  id="departments"
                  name="departments"
                  label={t(`admin.employees.form.departments.label`)}
                  value={filter.departments}
                  multiple={true}
                  showSelectAll={false}
                  disabled={false}
                  getOptions={getDepartments}
                  payloadData={fieldDepartmentsPayload()}
                  labelWithId={false}
                  onChange={setFilter}
                  disableCloseOnSelect={true}
                  autoFocus={activeField === 'departments'}
                  showLabelCounter={true}
                />
              </Box>
            </Box>
          </Box>

          <Box className={styles.box}>
            <Box className={styles.fieldWrapper}>
              {!mobile && (
                <Box className={styles.fieldFavorite}>
                  <FavoriteCheckboxField
                    checked={favAdmEmployeesStatus}
                    setChecked={setFavAdmEmployeesStatus}
                    fieldName={t(`admin.employees.form.status.label`)}
                  />
                </Box>
              )}
              <Box className={styles.field}>
                <MuiAutocompleteSelect
                  keyValue="label"
                  keyId="id"
                  id="status"
                  name="status"
                  label={t(`admin.employees.form.status.label`)}
                  value={filter.status}
                  multiple={false}
                  showSelectAll={false}
                  disabled={false}
                  staticOptions={[
                    { id: "status_active", label:"status_active" },
                    { id: "status_not_active", label:"status_not_active" },
                  ]}
                  onChange={setFilter}
                  prefix="admin.employees.form.status.options."
                  autoFocus={activeField === 'status'}
                  showLabelCounter={true}
                />
              </Box>
            </Box>
          </Box>

          <Box className={styles.box}>
            <Box className={styles.fieldWrapper}>
              {!mobile && (
                <Box className={styles.fieldFavorite}>
                  <FavoriteCheckboxField
                    checked={favAdmEmployeesRoles}
                    setChecked={setFavAdmEmployeesRoles}
                    fieldName={t(`admin.employees.form.roles.label`)}
                  />
                </Box>
              )}
              <Box className={styles.field}>
                <MuiAutocompleteSelectLazy
                  keyValue="name"
                  keyId="id"
                  id="roles"
                  name="roles"
                  label={t(`admin.employees.form.roles.label`)}
                  value={filter.roles}
                  multiple={true}
                  showSelectAll={false}
                  disabled={false}
                  getOptions={getFilterRoles}
                  payloadData={fieldPayload()}
                  labelWithId={false}
                  onChange={setFilter}
                  disableCloseOnSelect={true}
                  autoFocus={activeField === 'roles'}
                  showLabelCounter={true}
                />
              </Box>
            </Box>
          </Box>

          {!!platformsList && platformsList.length > 1 && (<Box className={styles.box}>
            <Box className={styles.fieldWrapper}>
              {!mobile && (
                <Box className={styles.fieldFavorite}>
                  <FavoriteCheckboxField
                    checked={favAdmEmployeesPlatforms}
                    setChecked={setFavAdmEmployeesPlatforms}
                    fieldName={t(`admin.employees.form.platforms.label`)}
                  />
                </Box>
              )}
              <Box className={styles.field}>
                <MuiAutocompleteSelectLazy
                  keyValue="value"
                  keyId="id"
                  id="platforms"
                  name="platforms"
                  label={t(`admin.employees.form.platforms.label`)}
                  value={filter.platforms}
                  multiple={true}
                  showSelectAll={false}
                  disabled={false}
                  getOptions={getPlatforms}
                  payloadData={fieldPayload()}
                  labelWithId={false}
                  onChange={setFilter}
                  disableCloseOnSelect={true}
                  autoFocus={activeField === 'platforms'}
                  showLabelCounter={true}
                />
              </Box>
            </Box>
          </Box>)}
        </Scrollbars>
      </Box>

      <Box className={styles.actions}>
        <Box className={styles.actionsWrapper}>
          <Button
            gradient
            className={styles.button}
            fullWidth
            disableElevation
            type="submit"
            variant="contained"
            size={mobile ? 'medium' : 'medium'}
            onClick={handleApplyFilter}
            disabled={arePropsEqual(initialFilter, filter)}
          >
            {t("common.buttons.apply")}
          </Button>
          <Button
            className={styles.button}
            color="secondary"
            disableElevation
            type="button"
            variant="outlined"
            size={mobile ? 'medium' : 'medium'}
            onClick={() => setToggleDrawer(false)}
          >
            {t("common.buttons.cancel")}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Filter;
