import { useDispatch } from "react-redux";

import {
  clearListState,
  clearWidgetsState,
  getWidgetsData,
  getListData,
  getPaymentDetailsData,
  clearPaymentDetailsState,
  ListPayloadType, PaymentDetailsPayloadType,
} from "./index";

export const usePaymentHistory = () => {
  const dispatch = useDispatch();

  const getWidgets = () => dispatch(getWidgetsData());
  const getList = (data: ListPayloadType) => dispatch(getListData(data));
  const getPaymentDetails = (data: PaymentDetailsPayloadType) => dispatch(getPaymentDetailsData(data));

  const clearList = () => dispatch(clearListState());
  const clearWidgets = () => dispatch(clearWidgetsState());
  const clearPaymentDetails = () => dispatch(clearPaymentDetailsState());

  return {
    getWidgets,
    getList,
    clearList,
    clearWidgets,
    getPaymentDetails,
    clearPaymentDetails,
  }
}
