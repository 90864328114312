//core
import React, {useEffect, useState} from "react";
import { makeStyles } from "@mui/styles";
import {numberToLocaleFormat, strToNumberFormat} from "../../../../../helpers/utils";
import { Box } from "@mui/material";
import {useTranslation} from "react-i18next";

interface IValue {
  value: string | number;
  color?: string;
}

const useStyles = makeStyles({
  text: {}
});

const FloatFormat = ({ value, color = '', }: IValue) => {
  const styles = useStyles();
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState<string>('en');
  const renderValue = (!!value) && (value !== '∞') ? numberToLocaleFormat(value, locale) : value;
  const textColor = ((renderValue !== '0.00') && (renderValue !== '0,00') && (renderValue !== '0') && (renderValue !== 0))
    ? !!color
      ? color
      : 'inherit'
    : 'inherit';

  useEffect(() => {
    if (i18n.language === 'ru') {
      setLocale('ru');
    }

    if (i18n.language === 'en') {
      setLocale('en');
    }
  }, [i18n.language]);

  return (
    <Box component="span" className={styles.text} sx={{ color: textColor }}>
      { value === null ? '—' : renderValue }
    </Box>
  );
};

export default FloatFormat;
