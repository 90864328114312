//core
import React from 'react';
import {useTranslation} from "react-i18next";
import arePropsEqual from "react-fast-compare";
import {Chip} from "@mui/material";

//theme
import {Theme} from "@mui/material/styles/createTheme";
import {useTheme} from "@mui/styles";


interface IAdjustmentStatus {
    value: boolean;
}

const AdjustmentStatus = ({ value }: IAdjustmentStatus) => {
    const theme: Theme = useTheme();
    const {t} = useTranslation();

    return (
        <>
            { value === true && <Chip size="small" label="Approved" color="primary" sx={{ backgroundColor: theme.palette.success.main }} />}
            { value === false && <Chip size="small" label="Pending" color="primary" sx={{ backgroundColor: theme.palette.warning.main }} />}
        </>
    );
};

export default React.memo(AdjustmentStatus, arePropsEqual)
