import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import { cookieSupervision, cookieToken } from "../../constants";

const getReferralsToken = () => {
  const token = getCookie(cookieToken) || null;
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.reference.token : api.affiliate.reference.token;

  return fetch(`${endpoint}?authenticity_token=${token}${isSupervision ? '&supervision=true' : ''}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getConditions = () => {
  const token = getCookie(cookieToken) || null;
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.reference.conditions : api.affiliate.reference.conditions;

  return fetch(`${endpoint}?authenticity_token=${token}${isSupervision ? '&supervision=true' : ''}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

export const referenceService = {
  getReferralsToken,
  getConditions,
};
