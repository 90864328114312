import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import { cookieToken } from "../../constants";

const invitePartner = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.invite_partner;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('PUT', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const resendPartnerLink = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  const endpoint = api.resend_invite_partner.replace('{confirmationToken}', `${data.token}`);

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

export const invitePartnerService = {
  invitePartner,
  resendPartnerLink,
};
