import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getCookie, setCookie } from "../../../helpers/utils";

//theme
import { appTheme, themes } from "./index";

//context
import { ThemeContext } from "./context";

//constants
import { cookieTheme } from '../../../constants';

interface IMuiThemeProviderProps {
  children: React.ReactNode,
}

const MuiThemeProvider: React.FC<IMuiThemeProviderProps> = ({
  children,
}): JSX.Element => {
  const themePrefix = appTheme.split('_')[0];

  const [selectedThemeName, setSelectedThemeName] = useState(
    getCookie(cookieTheme).includes(`${themePrefix}`)
      ? (getCookie(cookieTheme) || appTheme)
      : appTheme
  );
  const [selectedTheme, setSelectedTheme] = useState(
    !!themes[selectedThemeName] ? themes[selectedThemeName] : themes[`${appTheme}`]
  );
  const [mode, setMode] = useState(
    (getCookie(cookieTheme).includes(`${themePrefix}`)
      ? (getCookie(cookieTheme) || `${themePrefix}_light`)
      : appTheme).includes('dark')
  );

  const handleChangeTheme = (value: boolean) => {
    const newThemeName = value ? `${themePrefix}_dark` : `${themePrefix}_light`;

    const expiresDate = new Date();
    expiresDate.setDate(expiresDate.getDate() + 365);

    setSelectedThemeName(newThemeName);
    setSelectedTheme(themes[newThemeName]);
    setMode(value);
    setCookie(cookieTheme, newThemeName, {
      expires: expiresDate,
      secure: true,
    })
  }

  return (
    <ThemeContext.Provider value={{
      darkMode: mode,
      setDarkMode: handleChangeTheme,
    }}>
      <ThemeProvider theme={createTheme(selectedTheme)}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default MuiThemeProvider;
