//core
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {statusService} from "../../../services/common/status.service";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";

export type userSliceState = {
  user: {
    authorized?: boolean,
    user: any,
  },
  status: {
    data: any,
    error: any,
    isFetching: boolean,
  }
}

const localStorageUser = !!localStorage.getItem('user')
  ? localStorage.getItem('user')
  : "";
const userData = (typeof localStorageUser === "string" && localStorageUser.length > 0)
  ? JSON.parse(localStorageUser)
  : null;
const user = (!!userData && userData.hasOwnProperty('user') && !userData?.user.hasOwnProperty('platform'))
  ? null
  : userData;

const initialState: userSliceState = {
  user: user,
  status: {
    data: null,
    error: null,
    isFetching: false,
  }
}

export const getStatus: any = createAsyncThunk(
  'user/status',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await statusService.getStatus();
      let data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    updateUserInfo(state, action) {
      if (!!state.user) {
        const user = {
          ...state.user,
          user: {
            ...state.user.user,
            ...action.payload,
          }
        };

        state.user = user;
        localStorage.setItem('user', JSON.stringify(user));
      }
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<userSliceState>>) => {
    builder.addCase(getStatus.pending, (state) => {
      state.status.isFetching = true;
      state.status.error = null;
    });
    builder.addCase(getStatus.fulfilled, (state, action: any) => {
      state.status.error = null;
      state.status.isFetching = false;
      state.status.data = action.payload;
    });
    builder.addCase(getStatus.rejected, (state, action) => {
      state.status.error = action.payload;
    });
  },
})

export default userSlice.reducer;

export const { setUser, updateUserInfo } = userSlice.actions;
