import {
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  startOfYear,
  endOfYear,
  subYears,
  lastDayOfQuarter,
  startOfQuarter,
} from "date-fns";

export type PeriodTypes = 'today' | 'yesterday' | 'last_7_days' | "previous_7_days" | 'week' | 'last_week' | 'month' | 'last_month' | 'year' | 'last_year' | 'current_quater' | 'last_quater' | '';

export type weekStartsOnType = 0 | 1 | 2 | 3 | 4 | 5 | 6;

interface IPeriodResult {
  start: Date | '',
  end: Date | '',
}

export type Options = {
  id: string;
  label: string;
};

export type ObjArrayItem = {
  id: string | number;
  [key: string]: any,
};

interface IObj {
  [i:string]: string;
}

export type GroupByObj = {
  [k: string]: string | IObj
}

type SegmentItem = {
  id: number;
  name: string;
};

type DataItemProps = {
  id: number | string;
  check: boolean;
  value: number | string;
};

export const getPeriod = (period: PeriodTypes): IPeriodResult => {
  const i18nextLng: string = localStorage.getItem("i18nextLng") || '';
  let weekStartsOn: weekStartsOnType = 0;
  if (!i18nextLng.includes('en')) {
    weekStartsOn = 1;
  }

  switch (period) {
    case "today":
      return {
        start: new Date(),
        end: new Date(),
      }
    case "yesterday":
      return {
        start: subDays(new Date(), 1),
        end: subDays(new Date(), 1),
      }
    case "last_7_days":
      return {
        start: subDays(new Date(), 6),
        end: new Date(),
      }
    case "previous_7_days":
      return {
        start: subDays(subDays(new Date(), 7), 6),
        end: subDays(new Date(), 7),
      }
    case "week":
      return {
        start: startOfWeek(new Date(), { weekStartsOn: weekStartsOn }),
        end: endOfWeek(new Date(), { weekStartsOn: weekStartsOn }),
      }
    case "last_week":
      return {
        start: startOfWeek(subDays(new Date(), 7), { weekStartsOn: weekStartsOn }),
        end: endOfWeek(subDays(new Date(), 7), { weekStartsOn: weekStartsOn }),
      }
    case "month":
      return {
        start: startOfMonth(new Date()),
        end: endOfMonth(new Date()),
      }
    case "last_month":
      return {
        start: startOfMonth(subMonths(new Date(), 1)),
        end: endOfMonth(subMonths(new Date(), 1)),
      }
    case "year":
      return {
        start: startOfYear(new Date()),
        end: endOfYear(new Date()),
      }
    case "last_year":
      return {
        start: startOfYear(subYears(new Date(), 1)),
        end: endOfYear(subYears(new Date(), 1)),
      }
    case "current_quater":
      return {
        start: startOfQuarter(new Date()),
        end: lastDayOfQuarter(new Date()),
      }
    case "last_quater":
      return {
        start: startOfQuarter(subMonths(startOfMonth(new Date()), 3)),
        end: lastDayOfQuarter(subMonths(startOfMonth(new Date()), 3)),
      }
    default:
      return {
        start: '',
        end: '',
      }
  }
};

export const getComparativePeriodFromPeriod = (period: PeriodTypes): PeriodTypes => {
  const mapping: any = {
    today: 'yesterday',
    yesterday: 'today',
    last_7_days: 'previous_7_days',
    previous_7_days: 'last_7_days',
    week: 'last_week',
    last_week: 'week',
    month: 'last_month',
    last_month: 'month',
    year: 'last_year',
    last_year: 'year',
    current_quater: 'last_quater',
    last_quater: 'current_quater',
  };

  return mapping[period];
}
