//core
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { paymentHistoryService } from "../../../services/affiliate/paymentHistory.service";
import { setUser } from "../../common/user";
import {
  setGeneralProgressHide,
  setGeneralProgressShow,
  //setGeneralSnackbarState
} from "../../common/ui";

export type ListPayloadType = {
  /*filter: {
    [key: string]: any,
    search: string | null;
  };*/
  order: {
    [key: string]: 'asc' | 'desc'
  };
  page: number;
  perPage: number;
};

export type PaymentDetailsPayloadType = {
  id: number;
};

export type PaymentHistorySliceState = {
  list: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  widgets: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  paymentDetails: {
    data: any | null,
    error: any,
    isFetching: boolean,
  }
};

const initialState: PaymentHistorySliceState = {
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  widgets: {
    data: null,
    error: null,
    isFetching: false,
  },
  paymentDetails: {
    data: null,
    error: null,
    isFetching: false,
  },
};

export const getWidgetsData: any = createAsyncThunk(
  'aff/paymentHistory/getWidgetsData',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await paymentHistoryService.getWidgets();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getListData: any = createAsyncThunk(
  'aff/paymentHistory/getListData',
  async (payload: ListPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await paymentHistoryService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getPaymentDetailsData: any = createAsyncThunk(
  'aff/paymentHistory/getPaymentDetailsData',
  async (payload: PaymentDetailsPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await paymentHistoryService.getPaymentDetails(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

//slice
const paymentHistorySlice = createSlice({
  name: 'aff/paymentHistory',
  initialState: initialState,
  reducers: {
    clearListState(state) {
      state.list.data = null;
      state.list.error = null;
      state.list.isFetching = false;
    },
    clearWidgetsState(state) {
      state.widgets.data = null;
      state.widgets.error = null;
      state.widgets.isFetching = false;
    },
    clearPaymentDetailsState(state) {
      state.paymentDetails.data = null;
      state.paymentDetails.error = null;
      state.paymentDetails.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<PaymentHistorySliceState>>) => {
    builder.addCase(getWidgetsData.pending, (state ) => {
      state.widgets.isFetching = true;
      state.widgets.error = null;
    });
    builder.addCase(getWidgetsData.fulfilled, (state , action) => {
      state.widgets.error = null;
      state.widgets.isFetching = false;
      state.widgets.data = action.payload;
    });
    builder.addCase(getWidgetsData.rejected, (state , action) => {
      state.widgets.isFetching = false;
      state.widgets.error = action.payload;
    });

    builder.addCase(getListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getListData.fulfilled, (state , action) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getListData.rejected, (state , action) => {
      state.list.isFetching = false;
      state.list.error = action.payload;
    });

    builder.addCase(getPaymentDetailsData.pending, (state ) => {
      state.paymentDetails.isFetching = true;
      state.paymentDetails.error = null;
    });
    builder.addCase(getPaymentDetailsData.fulfilled, (state , action) => {
      state.paymentDetails.error = null;
      state.paymentDetails.isFetching = false;
      state.paymentDetails.data = action.payload;
    });
    builder.addCase(getPaymentDetailsData.rejected, (state , action) => {
      state.paymentDetails.isFetching = false;
      state.paymentDetails.error = action.payload;
    });
  },
});

export default paymentHistorySlice.reducer;

export const {
  clearListState,
  clearWidgetsState,
  clearPaymentDetailsState,
} = paymentHistorySlice.actions;
