import { useDispatch } from "react-redux";

import {
  getFiltersData,
  getListData,
  getAcceptManagersData,
  getAcceptManagersAllData,
  getDealsData,
  setApproveAffiliate,
  setDeclineAffiliate,
  setBlockAffiliate,
  setUnblockAffiliate,
  setResendConfirmationEmail,
  getAffiliatesData,
  getChiefsData,
  getDepartmentsData,
  getDetailsData,
  getPlatformsStaticData,
  clearApproveState,
  clearDeclineState,
  clearBlockState,
  clearUnblockState,
  clearResendConfirmationState,
  clearListState,
  clearDetailsState,
  clearPlatformsState,
  clearAffiliatesState,
  clearPlatformsStaticState,
  getPlatformsData,
  createPartnerData,
  clearNewPartnerData,
  getInvitationLinksData,
  getInvitationManagersData,
  getAffDealsListData,
  clearAffDealsListData,
  OptionsPayloadType,
  DealsPayloadType,
  ListPayloadType,
  OptionsPayloadManagerType,
  ApprovePayloadType,
  NotesPayloadType,
  ResendConfirmationPayloadType,
  AffDealsPayloadType,
} from "./index";

export const usePartners = () => {
  const dispatch = useDispatch();

  const getFilters = () => dispatch(getFiltersData());
  const getList = (data: ListPayloadType) => dispatch(getListData(data));
  const clearList = () => dispatch(clearListState());
  const getAcceptManagers = (data: OptionsPayloadManagerType) => dispatch(getAcceptManagersData(data));
  const getAcceptManagersAll = (data: OptionsPayloadManagerType) => dispatch(getAcceptManagersAllData(data));
  const getDeals = (data: DealsPayloadType) => dispatch(getDealsData(data));
  const setApprove = (data: ApprovePayloadType) => dispatch(setApproveAffiliate(data));
  const setDecline = (data: NotesPayloadType) => dispatch(setDeclineAffiliate(data));
  const setBlock = (data: NotesPayloadType) => dispatch(setBlockAffiliate(data));
  const setUnblock = (data: NotesPayloadType) => dispatch(setUnblockAffiliate(data));
  const setResendConfirmation = (data: ResendConfirmationPayloadType) => dispatch(setResendConfirmationEmail(data));

  const getAffiliates = (data: OptionsPayloadType) => dispatch(getAffiliatesData(data));
  const clearAffiliates = () => dispatch(clearAffiliatesState());
  const getChiefs = (data: OptionsPayloadType) => dispatch(getChiefsData(data));
  const getDepartments = (data: OptionsPayloadType) => dispatch(getDepartmentsData(data));

  const clearApprove = () => dispatch(clearApproveState());
  const clearDecline = () => dispatch(clearDeclineState());
  const clearBlock = () => dispatch(clearBlockState());
  const clearUnblock = () => dispatch(clearUnblockState());
  const clearResendConfirmation = () => dispatch(clearResendConfirmationState());

  const getPlatforms = (data:any) => dispatch(getPlatformsData(data));
  const clearPlatforms = () => dispatch(clearPlatformsState());

  const getPlatformsStatic = (data:any) => dispatch(getPlatformsStaticData(data));
  const clearPlatformsStatic = () => dispatch(clearPlatformsStaticState());

  const getDetails = (data: ListPayloadType) => dispatch(getDetailsData(data));
  const clearDetails = () => dispatch(clearDetailsState());
  const createPartner = (data:any) => dispatch(createPartnerData(data));
  const clearNewPartner = () => dispatch(clearNewPartnerData());

  const getInvitationLinks = (data:any) => dispatch(getInvitationLinksData(data));
  const getInvitationManagers = (data:any) => dispatch(getInvitationManagersData(data));

  const getAffDealsList = (data: AffDealsPayloadType) => dispatch(getAffDealsListData(data));
  const clearAffDealsList = () => dispatch(clearAffDealsListData());

  return {
    getFilters,
    getList,
    getAcceptManagers,
    getDeals,
    setApprove,
    setDecline,
    setBlock,
    setUnblock,
    setResendConfirmation,
    getAffiliates,
    getChiefs,
    getDepartments,
    clearApprove,
    clearDecline,
    clearBlock,
    clearUnblock,
    clearResendConfirmation,
    getAcceptManagersAll,
    clearList,
    getPlatforms,
    getDetails,
    clearDetails,
    clearPlatforms,
    clearAffiliates,
    getPlatformsStatic,
    clearPlatformsStatic,
    createPartner,
    clearNewPartner,
    getInvitationLinks,
    getInvitationManagers,
    getAffDealsList,
    clearAffDealsList,
  }
}
