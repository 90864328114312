import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles({
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: "12px 0"
  },
  input: {
    margin: "0 3px",
    width: "100%",
    maxWidth: 80,
    '& input': {
      padding: "6.5px 10px",
      textAlign: "center",
      '-moz-appearance': 'textfield',
      '&::-webkit-outer-spin-button': {
        "-webkit-appearance": 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        "-webkit-appearance": 'none',
        margin: 0,
      },
    },
  },

  total: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 4,
    padding: '8px 0 6px',
  }
});
