//core
import React from "react";
import arePropsEqual from "react-fast-compare";
import {makeStyles} from "@mui/styles";
import { Tooltip } from "@mui/material";

interface ILinkProps {
  value: string;
  width?: number;
  color?: string;
  className?: string;
}

const useStyles = makeStyles({
  text: {
    color: "inherit",
    //width: "360px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
    //wordBreak: 'break-word'
  }
});

const Link = ({ value, width = 360, color, className = '' }: ILinkProps) => {
  const styles = useStyles();

  return (
    <>
      {value
        ? <Tooltip title={value}>
            <a
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              className={`${styles.text} ${className}`}
              style={{ width: `${width}px`, color: color }}
            >
              { value }
            </a>
          </Tooltip>
        : '—'}
    </>
  );
};

export default React.memo(Link, arePropsEqual);
