//core
import React from "react";
import { Box } from "@mui/material";
import {makeStyles, useTheme} from "@mui/styles";

//icons
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

//theme
import {Theme} from "@mui/material/styles/createTheme";

interface IBadgeFieldProps {
  value: boolean;
}

const BadgeField: React.FC<IBadgeFieldProps> = ({ value = false }): JSX.Element => {
  const theme: Theme = useTheme();
  const useStyles = makeStyles({
    box: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 0,
    },
    icon: {
      width: 20,
      height: 20,
    },
    gray: {
      color: theme.palette.action.active,
    }
  });
  const styles = useStyles();

  return (
    <Box className={styles.box}>
      {value
        ? <CheckCircleOutlineIcon className={styles.icon} color="primary" />
        : <HighlightOffIcon className={`${styles.icon} ${styles.gray}`} />
      }
    </Box>
  );
};

export default BadgeField;
