//core
import React from 'react';
import { useTranslation } from "react-i18next";
import arePropsEqual from "react-fast-compare";
import { Chip } from "@mui/material";


interface IStatus {
  value: string;
}

const PaymentHistoryStatus = ({value}: IStatus) => {
  const {t} = useTranslation();

  return (
    <>
      {/*details fin report*/}
      { value === "approved" && <Chip
        size="small"
        label={t("affiliate.payment_history.status.approved")}
        color="default"
        sx={{ backgroundColor: '#F7E587', color: '#292621' }}
      />}
      { value === "approvedFinance" && <Chip
        size="small"
        label={t("affiliate.payment_history.status.approved")}
        color="default"
        sx={{ backgroundColor: '#F7E587', color: '#292621' }}
      />}
      { value === "processingPayment" && <Chip
        size="small"
        label={t("affiliate.payment_history.status.approved")}
        color="default"
        sx={{ backgroundColor: '#F7E587', color: '#292621' }}
      />}
      { value === "new" && <Chip
        size="small"
        label={t("affiliate.payment_history.status.new")}
        color="primary"
        sx={{ backgroundColor: '#F69B5A'}}
      />}
      { value === "paid" && <Chip
        size="small"
        label={t("affiliate.payment_history.status.paid")}
        color="primary"
        sx={{ backgroundColor: '#00AF55'}}
      />}
      { value === "carryover" && <Chip
        size="small"
        label={t("affiliate.payment_history.status.carryover")}
        color="default"
        sx={{ backgroundColor: '#83CEF3'}}
      />}
    </>
  );
};

export default React.memo(PaymentHistoryStatus, arePropsEqual)
