//core
import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

interface IAdjustmentReason {
  value: string | number;
  bold?: boolean;
  prefix?: string;
  whiteSpace?: boolean;
  breakAll?: boolean;
}

const useStyles = makeStyles({
  text: {
    color: 'inherit',
  },
  whiteSpace: {
    whiteSpace: 'nowrap',
  }
});

const AdjustmentReason: React.FC<IAdjustmentReason> = ({value = '', bold = false, prefix, whiteSpace = false, breakAll = false,}): JSX.Element  => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <span
      className={`${styles.text} ${whiteSpace ? styles.whiteSpace : ''}`}
      style={{
        fontWeight: bold ? "bold" : "inherit",
        wordBreak: breakAll ? "break-all" : "normal",
      }}
    >
      {prefix ? (
        !!value ? t(`${prefix}${value}`) : t('columns_for_display.table.adjustments.auto')
      ): (
        !!value ? value : t('columns_for_display.table.adjustments.auto')
      )}
    </span>
  );
};

export default AdjustmentReason;
