//core
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";

export type ProtectedAPIPayload = {
  [key: string]: any;
}

export type ProtectedAPISliceState = {
  type: string;
  error: any;
  payload: ProtectedAPIPayload | null;
};

const initialState: ProtectedAPISliceState = {
  type: '',
  error: null,
  payload: null,
};

const protectedAPISlice = createSlice({
  name: 'protectedAPI',
  initialState: initialState,
  reducers: {
    setType(state, action) {
      state.type = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setPayload(state, action) {
      state.payload = action.payload;
    },
    clearState(state) {
      state.type = '';
      state.error = null;
      state.payload = null;
    },
  },
});

export default protectedAPISlice.reducer;

export const {
  setType,
  setError,
  setPayload,
  clearState,
} = protectedAPISlice.actions;
