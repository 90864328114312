import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  block: {
    width: '100%',
    maxWidth: 860,
    '@media (max-width: 1500px)': {},
  },
  fieldGroup: {
    display: 'flex',
  },
  role: {
    flex: '1 1 auto',
  },
  status: {
    height: 40,
    paddingLeft: 24,
    width: 130,
  },
  cancel: {
    height: 40,
    padding: '0 4px',
    '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
      fontSize: '12px',
    },
  },
  btn__approve: {
    width: '100%',
  },
  btn__wrapper : {
    display: 'flex',
    marginTop: 60,
  },
  btn: {
    width: '100%',
  },
  btn__block: {
    display: 'inline-block',
    maxWidth: '250px',
    width: "100%",
    marginRight: '16px',
    '&:last-of-type': {
      marginRight: 0
    }
  },
  telegram: {
    marginRight: '0!important',
    '& p': {
      color: theme.palette.text.secondary,
    },
  },
}));
