import { api } from "../../api";
import {handleResponse, RequestOptions} from "../types";
import {getCookie} from "../../helpers/utils";
import {cookieToken} from "../../constants";

const getUrlsData = () => {
    return fetch(`${api.admin.invitationLinks.createOptions}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const addNewLink = (data:any) => {
  const authenticity_token = getCookie(cookieToken);
  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };
  return fetch(`${api.admin.invitationLinks.root}`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getList = (data:any) => {
  const authenticity_token = getCookie(cookieToken);
  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };
  return fetch(`${api.admin.invitationLinks.list}`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const editLink = (data:any) => {
  const authenticity_token = getCookie(cookieToken);
  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };
  return fetch(`${api.admin.invitationLinks.root}/${payload.id}`, {...RequestOptions('PUT', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const removeLink = (data:any) => {
  const authenticity_token = getCookie(cookieToken);
  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };
  return fetch(`${api.admin.invitationLinks.root}/${payload.id}`, {...RequestOptions('DELETE', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const archiveLink = (data:any) => {
  const authenticity_token = getCookie(cookieToken);
  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };
  return fetch(`${api.admin.invitationLinks.root}/${payload.id}/archive`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

export const invitationLinksService = {
  getUrlsData,
  addNewLink,
  getList,
  editLink,
  removeLink,
  archiveLink,
};
