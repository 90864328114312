import {api} from "../../api";
import {handleResponse, RequestOptions} from "../types";
import {getCookie} from "../../helpers/utils";
import {cookieSupervision, cookieToken} from "../../constants";

const getStatisticsApi = () => {
  const token = getCookie(cookieToken) || null;
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.api.statistics : api.affiliate.api.statistics;

  return fetch(`${endpoint}?authenticity_token=${token}${isSupervision ? '&supervision=true' : ''}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

export const statisticsApiService = {
  getStatisticsApi,
};
