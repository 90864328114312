import { createContext } from "react";

export interface IAutocompleteSelectContext {
  showSelectAll: boolean;
  selectAllState: boolean;
  maxSelectAllCount: number;
  options: readonly any[],
  handleCheckAllOptions: () => void;
}

export const initialValue: IAutocompleteSelectContext = {
  showSelectAll: false,
  selectAllState: false,
  maxSelectAllCount: 100,
  options: [],
  handleCheckAllOptions: () => {},
}

export const AutocompleteSelectContext = createContext<IAutocompleteSelectContext>(initialValue);
