//core
import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "usehooks-ts";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import { endOfDay, startOfDay } from "date-fns";
import {
  Box,
  SelectChangeEvent, useMediaQuery,
} from "@mui/material";

//styles
import { useStyles } from "./styles";

//helpers
import { getPeriod, PeriodTypes } from "../../../../../utils";

//constant
import { summaryPeriodOptions } from "../../../../../constant";
import { favoriteFilterKeys } from "../../../../constants";

//context
import { GlobalContext } from "../../../../../../../../context/GlobalContext";

//selectors
import { selectFiltersData } from "../../../../../../../../store/admin/reports/webhooks_report/selectors";

//hooks
import { usePartners } from "../../../../../../../../store/admin/partners/usePartners";
import { useFields } from "../../../../../../../../store/common/fields/useFields";

//components
import CSelect from "../../../../../../../UI/Fields/CSelect";
import { IFilterData } from "../../../../types";
import CDateRangePicker from "../../../../../../../UI/Fields/CDateRangePicker";
import MuiAutocompleteSelectLazy from "../../../../../../../UI/Fields/MuiAutocompleteSelectLazy";
import MuiAutocompleteSelect from "../../../../../../../UI/Fields/MuiAutocompleteSelect";


interface IVisibleFilterProps {
  filter: IFilterData;
  setUpdateFilter: (data: any) => void;
}

const VisibleFilter: React.FC<IVisibleFilterProps> = ({
  filter,
  setUpdateFilter,
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();
  const mobile = useMediaQuery('(max-width:767px)');

  const context = useContext(GlobalContext);

  const { getAffiliates } = usePartners();
  const { getTrackers, getWebhooks } = useFields();

  const filtersData = useSelector(selectFiltersData);

  //state
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodTypes>(filter.period);
  const [startDate, setStartDate] = useState<Date | ''>(getPeriod(filter.period).start);
  const [endDate, setEndDate] = useState<Date | ''>(getPeriod(filter.period).end);

  //favorite fields state
  const [favWRAffiliates, setFavWRAffiliates] = useLocalStorage<boolean>(favoriteFilterKeys.affiliateIds, false);
  const [favWRTrackers, setFavWRTrackers] = useLocalStorage<boolean>(favoriteFilterKeys.trackerIds, false);
  const [favWRWebhooks, setFavWRWebhooks] = useLocalStorage<boolean>(favoriteFilterKeys.webhookIds, false);
  const [favWRWebhookTypes, setFavWRWebhookTypes] = useLocalStorage<boolean>(favoriteFilterKeys.webhookTypes, false);
  const [favWRStatuses, setFavWRStatuses] = useLocalStorage<boolean>(favoriteFilterKeys.statuses, false);

  useEffect(() => {
    if (!!selectedPeriod) {
      const period = getPeriod(selectedPeriod);

      if (!!period.start && !!period.end) {
        setStartDate(period.start);
        setEndDate(period.end);

        context.setAdmWebhooksReportFilter({
          ...context.admWebhooksReportFilter,
          period: selectedPeriod,
          sent_from: format(startOfDay(new Date(period.start)), "yyyy-MM-dd HH:mm:ss"),
          sent_to: format(endOfDay(new Date(period.end)), "yyyy-MM-dd HH:mm:ss"),
        });
        setUpdateFilter((prevState: any) => {
          return {
            ...prevState,
            period: selectedPeriod,
            sent_from: format(startOfDay(new Date(period.start)), "yyyy-MM-dd HH:mm:ss"),
            sent_to: format(endOfDay(new Date(period.end)), "yyyy-MM-dd HH:mm:ss"),
          }
        })
      }
    }
  }, [selectedPeriod]);

  useEffect(() => {
    setSelectedPeriod(filter.period);
  }, [filter.period]);

  useEffect(() => {
    context.setAdmWebhooksReportFilter(filter);
  }, [filter]);

  const handleChangeDates = (start: Date, end: Date) => {
    const noEndDate = !end;

    setStartDate(start);
    setEndDate(noEndDate ? start : end);
    context.setAdmWebhooksReportFilter({
      ...context.admWebhooksReportFilter,
      period: '',
      sent_from: format(startOfDay(new Date(start)), "yyyy-MM-dd HH:mm:ss"),
      sent_to: format(endOfDay(new Date(noEndDate ? start : end)), "yyyy-MM-dd HH:mm:ss"),
    });
    setUpdateFilter((prevState: any) => {
      return {
        ...prevState,
        period: '',
        sent_from: format(startOfDay(new Date(start)), "yyyy-MM-dd HH:mm:ss"),
        sent_to: format(endOfDay(new Date(noEndDate ? start : end)), "yyyy-MM-dd HH:mm:ss"),
      }
    });
  };

  const handleChangePeriodSelect = (event: SelectChangeEvent) => {
    //@ts-ignore
    const value: PeriodTypes = event.target.value;
    setSelectedPeriod(value);
  };

  const affPayload = () => {
    return {
      chiefIds: null,
      departmentIds: null,
      platformIds: null,
      page: 1,
      search: null,
      per_page: 100,
    }
  };

  const fieldPayload = () => {
    return {
      page: 1,
      search: null,
      per_page: 100,
    }
  };

  const favoriteFields =
    !mobile && !!filtersData?.types && !!filtersData?.statuses
    && (favWRAffiliates || favWRTrackers || favWRWebhooks || favWRWebhookTypes || favWRStatuses) ? (
    <Box className={styles.favoriteFields}>
      {favWRAffiliates && (
        <Box>
          <MuiAutocompleteSelectLazy
            keyValue="value"
            keyId="id"
            id="affiliateIds"
            name="affiliateIds"
            label={t(`admin.reports.filter.affiliates`)}
            value={filter.affiliateIds}
            multiple={true}
            showSelectAll={false}
            disabled={false}
            getOptions={getAffiliates}
            payloadData={affPayload()}
            labelWithId={true}
            onChange={setUpdateFilter}
            disableCloseOnSelect={true}
            maxHeightFieldScroll={32}
            showLabelCounter={true}
          />
        </Box>
      )}
      {favWRTrackers && (
        <Box>
          <MuiAutocompleteSelectLazy
            keyValue="value"
            keyId="id"
            id="trackerIds"
            name="trackerIds"
            label={t(`admin.reports.filter.trackers`)}
            value={filter.trackerIds}
            multiple={true}
            showSelectAll={false}
            disabled={false}
            getOptions={getTrackers}
            payloadData={fieldPayload()}
            labelWithId={false}
            onChange={setUpdateFilter}
            disableCloseOnSelect={true}
            maxHeightFieldScroll={32}
            showLabelCounter={true}
          />
        </Box>
      )}
      {favWRWebhooks && (
        <Box>
          <MuiAutocompleteSelectLazy
            keyValue="value"
            keyId="id"
            id="webhookIds"
            name="webhookIds"
            label={t(`admin.reports.filter.webhooks`)}
            value={filter.webhookIds}
            multiple={true}
            showSelectAll={false}
            disabled={false}
            getOptions={getWebhooks}
            payloadData={fieldPayload()}
            labelWithId={true}
            onChange={setUpdateFilter}
            disableCloseOnSelect={true}
            maxHeightFieldScroll={32}
            showLabelCounter={true}
          />
        </Box>
      )}
      {favWRWebhookTypes && (
        <Box>
          <MuiAutocompleteSelect
            keyValue="value"
            keyId="id"
            id="webhookTypes"
            name="webhookTypes"
            label={t(`admin.reports.filter.types`)}
            value={filter.webhookTypes}
            multiple={true}
            showSelectAll={false}
            disabled={false}
            staticOptions={[...filtersData.types.list].reduce<Record<any, any>>((acc, item) => {
              acc.push({
                id: item,
                value: item,
              })
              return acc;
            }, [])}
            onChange={setUpdateFilter}
            disableCloseOnSelect={true}
            maxHeightFieldScroll={32}
            showLabelCounter={true}
          />
        </Box>
      )}
      {favWRStatuses && (
        <Box>
          <MuiAutocompleteSelect
            keyValue="value"
            keyId="id"
            id="statuses"
            name="statuses"
            label={t(`admin.reports.filter.statuses`)}
            value={filter.statuses}
            multiple={true}
            showSelectAll={false}
            disabled={false}
            staticOptions={[...filtersData.statuses.list].reduce<Record<any, any>>((acc, item) => {
              acc.push({
                id: item,
                value: item,
              })
              return acc;
            }, [])}
            onChange={setUpdateFilter}
            disableCloseOnSelect={true}
            maxHeightFieldScroll={32}
            showLabelCounter={true}
          />
        </Box>
      )}
    </Box>
  ) : null;

  return (<>
    {!!filtersData?.types && !!filtersData?.statuses && (
      <Box>
        <Box className={styles.wrapper}>
          <Box className={styles.box}>
            <CSelect
              name="period"
              label={t("admin.reports.filter.period")}
              value={selectedPeriod}
              options={summaryPeriodOptions}
              onChange={handleChangePeriodSelect}
              prefix="common.components.periods."
            />
          </Box>
          <Box className={styles.boxDate}>
            <CDateRangePicker
              startDate={startDate}
              endDate={endDate}
              onChange={handleChangeDates}
            />
          </Box>
        </Box>
        {favoriteFields}
      </Box>
    )}
  </>);
};

export default VisibleFilter;
