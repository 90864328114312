//core
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { emailTemplatesService } from "../../../services/admin/emailTemplates.service";
import { setGeneralProgressHide, setGeneralProgressShow, setGeneralSnackbarState } from "../../common/ui";
import { setUser } from "../../common/user";

export interface IEditPayloadType {
  platform_id: number;
  id_mailer: string;
  locale: string;
}
export interface ISaveEmailTplPayloadType {
  platform_id: number | string;
  id_mailer: string;
  locale: string;
  //system_sender_name: string;
  mailer: {
    subject: string;
    body: string;
    enabled: string;
  },
}

export interface IDeleteEmailTplPayloadType {
  platform_id: number | string;
  id_mailer: string;
  locale: string;
}

export interface ISendTestEmailPayloadType {
  platform_id: number | string;
  id_mailer: string;
  locale: string;
  mail_to: string;
}

export type emailTemplatesSliceState = {
  list: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  edit: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  save: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  delete: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  sendTest: {
    data: any | null,
    error: any,
    isFetching: boolean,
  }
}

const initialState: emailTemplatesSliceState = {
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  edit: {
    data: null,
    error: null,
    isFetching: false,
  },
  save: {
    data: null,
    error: null,
    isFetching: false,
  },
  delete: {
    data: null,
    error: null,
    isFetching: false,
  },
  sendTest: {
    data: null,
    error: null,
    isFetching: false,
  },
}

export const getListData: any = createAsyncThunk(
  'emailTemplates/getListData',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await emailTemplatesService.getList();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getEditData: any = createAsyncThunk(
  'emailTemplates/getEditData',
  async (payload: IEditPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await emailTemplatesService.getEdit(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const saveEmailTplData: any = createAsyncThunk(
  'emailTemplates/saveEmailTplData',
  async (payload: ISaveEmailTplPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await emailTemplatesService.saveEmailTemplate(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.edited',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const deleteEmailTplData: any = createAsyncThunk(
  'emailTemplates/deleteEmailTplData',
  async (payload: IDeleteEmailTplPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await emailTemplatesService.deleteEmailTemplate(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.edited',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const sendTestEmailData: any = createAsyncThunk(
  'emailTemplates/sendTestEmailData',
  async (payload: ISendTestEmailPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await emailTemplatesService.sendTestEmail(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

//slice
const emailTemplatesSlice = createSlice({
  name: 'emailTemplates',
  initialState: initialState,
  reducers: {
    clearListState(state) {
      state.list.data = null;
      state.list.error = null;
      state.list.isFetching = false;
    },
    clearEditState(state) {
      state.edit.data = null;
      state.edit.error = null;
      state.edit.isFetching = false;
    },
    clearSaveState(state) {
      state.save.data = null;
      state.save.error = null;
      state.save.isFetching = false;
    },
    clearDeleteState(state) {
      state.delete.data = null;
      state.delete.error = null;
      state.delete.isFetching = false;
    },
    clearSendTestState(state) {
      state.sendTest.data = null;
      state.sendTest.error = null;
      state.sendTest.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<emailTemplatesSliceState>>) => {
    builder.addCase(getListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getListData.fulfilled, (state , action) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getListData.rejected, (state , action) => {
      state.list.isFetching = false;
      state.list.error = action.payload;
    });

    builder.addCase(getEditData.pending, (state ) => {
      state.edit.isFetching = true;
      state.edit.error = null;
    });
    builder.addCase(getEditData.fulfilled, (state , action) => {
      state.edit.error = null;
      state.edit.isFetching = false;
      state.edit.data = action.payload;
    });
    builder.addCase(getEditData.rejected, (state , action) => {
      state.edit.isFetching = false;
      state.edit.error = action.payload;
    });

    builder.addCase(saveEmailTplData.pending, (state ) => {
      state.save.isFetching = true;
      state.save.error = null;
    });
    builder.addCase(saveEmailTplData.fulfilled, (state , action) => {
      state.save.error = null;
      state.save.isFetching = false;
      state.save.data = action.payload;
    });
    builder.addCase(saveEmailTplData.rejected, (state , action) => {
      state.save.isFetching = false;
      state.save.error = action.payload;
    });

    builder.addCase(sendTestEmailData.pending, (state ) => {
      state.sendTest.isFetching = true;
      state.sendTest.error = null;
    });
    builder.addCase(sendTestEmailData.fulfilled, (state , action) => {
      state.sendTest.error = null;
      state.sendTest.isFetching = false;
      state.sendTest.data = action.payload;
    });
    builder.addCase(sendTestEmailData.rejected, (state , action) => {
      state.sendTest.isFetching = false;
      state.sendTest.error = action.payload;
    });

    builder.addCase(deleteEmailTplData.pending, (state ) => {
      state.delete.isFetching = true;
      state.delete.error = null;
    });
    builder.addCase(deleteEmailTplData.fulfilled, (state , action) => {
      state.delete.error = null;
      state.delete.isFetching = false;
      state.delete.data = action.payload;
    });
    builder.addCase(deleteEmailTplData.rejected, (state , action) => {
      state.delete.isFetching = false;
      state.delete.error = action.payload;
    });
  },
});

export default emailTemplatesSlice.reducer;

export const {
  clearListState,
  clearEditState,
  clearSaveState,
  clearSendTestState,
  clearDeleteState,
} = emailTemplatesSlice.actions;

