//core
import React, {MouseEventHandler} from "react";
import {
  IconButton,
  Tooltip
} from "@mui/material";

//styles
import { useStyles } from "./styles";

//types
interface IActionIconButton {
  text: string;
  color?: 'white';
  icon: React.ReactNode;
  onClick?: MouseEventHandler | undefined;
  disabled? : boolean;
  component?: 'a' | 'span';
  href?: string;
}

const ActionIconButton = (props: IActionIconButton) => {
  const styles = useStyles();

  const {
    text,
    icon,
    onClick,
    disabled = false,
    color,
    component = 'span',
    href = ''
  } = props;

  return (
    <>
      <Tooltip title={text}>
        <IconButton
          onClick={onClick}
          className={`${styles.button} ${color === 'white' ? styles.buttonWhite : ''} ${ disabled ? `disabled` : ''}`}
          size="small"
          aria-label="btn"
          component={component}
          disabled={disabled}
          color="primary"
          {...(!!href ? { href: href } : {})}
        >
          {icon}
        </IconButton>
      </Tooltip>
    </>
  );
};

export default React.memo(ActionIconButton);
