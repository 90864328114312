import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import {
  payloadConfirmRecoveryPassword,
  payloadRecoveryPassword,
  payloadReferrals,
  payloadChangePassword,
} from "../../store/common/registration";
import {getCookie} from "../../helpers/utils";
import {cookieToken} from "../../constants";

const registration = (data: any) => {
  return fetch(`${api.registrations}`, {...RequestOptions('POST', JSON.stringify(data))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getCountries = () => {
  return fetch(`${api.reg_country}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getTrafficSource = () => {
  return fetch(`${api.reg_traffic_source}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const confirmationEmail = (data: any, hash: string) => {
  return fetch(`${api.confirmation}${hash}`, {...RequestOptions('POST', JSON.stringify(data))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const resendConfirmationEmail = (data: any) => {
  return fetch(`${api.applicant.resend_confirmation}`, {...RequestOptions('POST', JSON.stringify(data))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const registrationsReferrals = (data: payloadReferrals) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.registrations_referrals;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const recoveryPassword = (data: payloadRecoveryPassword) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.recoveryPassword;

  const payload = {
    ...data,
    domain: window.location.host,
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const confirmRecoveryPassword = (data: payloadConfirmRecoveryPassword) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.confirmRecoveryPassword}?authenticity_token=${authenticity_token ? authenticity_token : `null`}&reset_password_token=${data.token}`;

  return fetch(`${endpoint}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const changePassword = (data: payloadChangePassword) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.changePassword;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('PUT', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

export const registrationService = {
  registration,
  getCountries,
  getTrafficSource,
  confirmationEmail,
  resendConfirmationEmail,
  registrationsReferrals,
  recoveryPassword,
  confirmRecoveryPassword,
  changePassword,
};
