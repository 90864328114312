//core
import React from "react";
import {makeStyles} from "@mui/styles";

interface IName {
  value: string | number;
}

const useStyles = makeStyles({
  text: {
    display: 'block',
    color: 'inherit',
    width: '100%',
    minWidth: '240px',
    maxWidth: '320px',
    wordBreak: 'break-all',
  }
});

const Name = ({ value }: IName) => {
  const styles = useStyles();

  return (
    <span className={styles.text}>
      { value }
    </span>
  );
};

export default Name;
