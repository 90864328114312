import React, { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import arePropsEqual from "react-fast-compare";
import { Box, SelectChangeEvent } from "@mui/material";

//selectors
import { selectFiltersData } from "../../../../../store/admin/partners/selectors";

//hooks
import { useAffProfile } from "../../../../../store/admin/affiliateProfile/useAffProfile";

import { itemListData } from "../../../../common/TableData";
import CSelect from "../../../../UI/Fields/CSelect";
import { usePermissions } from "../../../../../store/common/configuration/usePermissions";

interface ITrafficStatus {
  data: itemListData;
  fullWidth?: boolean;
  label?: string;
  extraSmall?: boolean;
}

const TrafficStatus: React.FC<ITrafficStatus> = ({
  data,
  fullWidth= false,
  label = '',
  extraSmall= false,
}): JSX.Element => {

  const { hasPermissions } = usePermissions();
  const hasUpdate = hasPermissions(["api3.admin.affiliatescontroller.update"]);

  const { updateTrafficStatus } = useAffProfile();

  const [value, setValue] = useState<any>(data.traffic_status);

  const filterData = useSelector(selectFiltersData);

  const handleChangeField = useCallback((event: SelectChangeEvent) => {
    setValue(event.target.value);

    const payload = {
      affiliateId: data.id,
      data: {
        traffic_status: event.target.value,
      }
    };

    updateTrafficStatus(payload);
  }, [setValue]);

  return (
    <Box
      sx={{
        width: '100%',
        minWidth: `${fullWidth ? '100%' : '150px'}`,
        maxWidth: `${fullWidth ? '100%' : '150px'}`,
      }}
    >
      <CSelect
        name="communicationStatus"
        value={value}
        options={filterData.traffic_statuses.options}
        onChange={handleChangeField}
        prefix="common.components.traffic_statuses."
        disabled={!hasUpdate}
        {...(!!label && {label: label})}
        {...(!!extraSmall && {extraSmall: extraSmall})}
      />
    </Box>
  );
};

export default React.memo(TrafficStatus, arePropsEqual);
