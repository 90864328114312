import * as Yup from 'yup';

interface IErrors {
  required: string,
}

function validationSchema(errors: IErrors) {
  return Yup.object().shape({
    field: Yup.string()
      .required(errors.required),
  });
}

export const validationRequiredSelectField = (val: any, errors: IErrors) => {
  const value = { field: val };

  const schema = validationSchema(errors);

  try {
    schema.validateSync(value, { abortEarly: false });
    return undefined;
  } catch (error: any) {
    return error.inner[0].errors[0];
  }
};
