//core
import React from 'react';
import {Box, CircularProgress} from "@mui/material";

interface ICircularLoaderProps {
  size?: number | string;
}

const CircularLoader: React.FC<ICircularLoaderProps> = ({
  size = 40
}): JSX.Element => {
  return (
    <Box sx={{ display: 'block', width: '100%', overflow: 'hidden' }}>
      <CircularProgress size={size} sx={{ display: 'block', margin: '0 auto' }} />
    </Box>
  );
};

export default CircularLoader;
