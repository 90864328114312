import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import {cookieSupervision, cookieToken} from "../../constants";

import {
  CreateSubAffiliateDealPayloadType,
  DeleteSubAffiliateHistoryPayloadType,
  HistoryOfSubaffiliatesChangePayloadType,
  SubAffiliateConditionsPayloadType,
  SubAffiliateHistoryRowPayloadType,
} from "../../store/admin/affiliateDeals";


const getSubAffiliateConditions = (data: SubAffiliateConditionsPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.deals.conditions.get.replace(
    '{affiliateId}',
    data.affiliateId.toString()
  );

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const createSubAffiliateDeal = (data: CreateSubAffiliateDealPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.deals.conditions.create;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const deleteSubAffiliateHistoryRow = (data: DeleteSubAffiliateHistoryPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.deals.conditions.delete.replace(
    '{id}',
    data.id.toString()
  );

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`,
    {...RequestOptions('DELETE')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getHistoryRow = (data: SubAffiliateHistoryRowPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.deals.conditions.getHistoryRow.replace(
    '{historyId}',
    data.id.toString()
  );

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const updateHistoryRow = (data: HistoryOfSubaffiliatesChangePayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.deals.conditions.update.replace(
    '{id}',
    data.id.toString()
  );

  const payload = {
    ...data.data,
    authenticity_token: authenticity_token,
  }

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const updateDeal = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.deals.updateDeal.replace(
    '{affiliateId}',
    data.affiliateId.toString()
  );

  const payload = {
    ...data.data,
    authenticity_token: authenticity_token,
  }

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getAdditionalSettings = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.deals.conditions.additional_settings.replace(
    '{affiliateId}',
    data.id.toString()
  );

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const updateAdditionalSettings = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.deals.conditions.additional_settings.replace(
    '{affiliateId}',
    data.affiliateId.toString()
  );

  const payload = {
    ...data.data,
    authenticity_token: authenticity_token,
  }

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

export const affDealsService = {
  getSubAffiliateConditions,
  deleteSubAffiliateHistoryRow,
  createSubAffiliateDeal,
  getHistoryRow,
  updateHistoryRow,
  updateDeal,
  getAdditionalSettings,
  updateAdditionalSettings,
}
