import {makeStyles} from "@mui/styles";
import {alpha} from "@mui/material/styles";
import {Theme} from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  list: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    gap: 8,
  },
  row: {
    width: "100%",
    padding: "4px 12px",
    position: "relative",
    '& .tooltipText': {
      //maxWidth: 230,
    },
  },
  box: {
    position: 'relative',
    padding: '16px',
    marginBottom: 8,
    boxShadow: "none",
  },
  label: {
    paddingRight: 4,
    color: alpha(theme.palette.secondary.main, 0.5),
  },
  name: {
    wordBreak: 'break-all',
    marginBottom: 8,
  },
  btn: {
    minWidth: 140,
    marginRight: 10
  },
  url: {
    wordBreak: "break-all",
    marginBottom: 8,
  },
  actions: {
    marginTop: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

}));
