//core
import React from "react";
import arePropsEqual from "react-fast-compare";
import { makeStyles } from "@mui/styles";

interface ICondition {
  id: number;
  name: string;
  whiteSpace?: boolean;
}

const useStyles = makeStyles({
  text: {
    width: '100%',
    minWidth: '240px',
    color: 'inherit',
  },
  whiteSpace: {
    whiteSpace: 'nowrap',
  }
});

const Condition: React.FC<ICondition> = ({
 name = '',
 id ,
 whiteSpace = true,
}): JSX.Element => {
  const styles = useStyles();

  return (
    <div className={`${styles.text} ${whiteSpace ? styles.whiteSpace : ''}`}>
      {name ? name : !!id ? id : '—'}
    </div>
  );
};

export default React.memo(Condition, arePropsEqual);
