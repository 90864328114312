//core
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "usehooks-ts";
import { useSelector } from "react-redux";
import {
  Box,
  useMediaQuery
} from "@mui/material";

//styles
import { useStyles } from "./styles";

//constants
import { favoriteFilterKeys } from "../../../../constants";

//hooks
import { useFields } from "../../../../../../../store/common/fields/useFields";
import { usePartners } from "../../../../../../../store/admin/partners/usePartners";
import { useEmployees } from "../../../../../../../store/admin/employees/useEmployees";

//components
import MuiAutocompleteSelectLazy from "../../../../../../UI/Fields/MuiAutocompleteSelectLazy";
import MuiAutocompleteSelect from "../../../../../../UI/Fields/MuiAutocompleteSelect";

interface IFilterData {
  [key: string]: any;
}

interface IVisibleFilterProps {
  initialFilter: IFilterData;
  setUpdateFilter: (data: any) => void;
}

const VisibleFilter: React.FC<IVisibleFilterProps> = ({
  initialFilter,
  setUpdateFilter,
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();
  const mobile = useMediaQuery('(max-width:767px)');

  const { getPlatforms } = useFields();
  const { getDepartments } = usePartners();
  const { getFilterRoles } = useEmployees();

  //favorite fields state
  const [favAdmEmployeesDepartments, setFavAdmEmployeesDepartments] = useLocalStorage<boolean>(favoriteFilterKeys.departments, false);
  const [favAdmEmployeesStatus, setFavAdmEmployeesStatus] = useLocalStorage<boolean>(favoriteFilterKeys.status, false);
  //const [favAdmEmployeesCompany, setFavAdmEmployeesCompany] = useLocalStorage<boolean>(favoriteFilterKeys.company, false);
  const [favAdmEmployeesRoles, setFavAdmEmployeesRoles] = useLocalStorage<boolean>(favoriteFilterKeys.roles, false);
  const [favAdmEmployeesPlatforms, setFavAdmEmployeesPlatforms] = useLocalStorage<boolean>(favoriteFilterKeys.platforms, false);

  const [platformsList, setPlatformsList] = useState<any | null>(null);

  useEffect( () => {
    if (!platformsList) {
      (async () => {
        const data = await getPlatforms({});
        setPlatformsList(data.payload.list)
      })()
    }
  }, [platformsList]);

  const fieldPayload = () => {
    return {
      page: 1,
      search: null,
      per_page: 100,
    }
  };

  const fieldDepartmentsPayload = () => {
    return {
      page: 1,
      search: null,
      per_page: 100,
      chiefIds: null,
      departmentIds: null,
      platformIds: null,
    }
  };


  const favoriteFields = !mobile && (
    favAdmEmployeesDepartments || favAdmEmployeesStatus || favAdmEmployeesRoles || favAdmEmployeesPlatforms
  ) ? (
    <Box className={styles.favoriteFields}>
      {favAdmEmployeesDepartments && (
        <Box>
          <MuiAutocompleteSelectLazy
            keyValue="value"
            keyId="id"
            id="departments"
            name="departments"
            label={t(`admin.employees.form.departments.label`)}
            value={initialFilter.departments}
            multiple={true}
            showSelectAll={false}
            disabled={false}
            getOptions={getDepartments}
            payloadData={fieldDepartmentsPayload()}
            labelWithId={false}
            onChange={setUpdateFilter}
            disableCloseOnSelect={true}
            showLabelCounter={true}
          />
        </Box>
      )}

      {favAdmEmployeesStatus && (
        <Box>
          <MuiAutocompleteSelect
            keyValue="label"
            keyId="id"
            id="status"
            name="status"
            label={t(`admin.employees.form.status.label`)}
            value={initialFilter.status}
            multiple={false}
            showSelectAll={false}
            disabled={false}
            staticOptions={[
              { id: "status_active", label:"status_active" },
              { id: "status_not_active", label:"status_not_active" },
            ]}
            onChange={setUpdateFilter}
            prefix="admin.employees.form.status.options."
            showLabelCounter={true}
          />
        </Box>
      )}

      {favAdmEmployeesRoles && (
        <Box>
          <MuiAutocompleteSelectLazy
            keyValue="name"
            keyId="id"
            id="roles"
            name="roles"
            label={t(`admin.employees.form.roles.label`)}
            value={initialFilter.roles}
            multiple={true}
            showSelectAll={false}
            disabled={false}
            getOptions={getFilterRoles}
            payloadData={fieldPayload()}
            labelWithId={false}
            onChange={setUpdateFilter}
            disableCloseOnSelect={true}
            showLabelCounter={true}
          />
        </Box>
      )}


      {favAdmEmployeesPlatforms && !!platformsList && platformsList.length > 1 && (
        <Box>
          <MuiAutocompleteSelectLazy
            keyValue="value"
            keyId="id"
            id="platforms"
            name="platforms"
            label={t(`admin.employees.form.platforms.label`)}
            value={initialFilter.platforms}
            multiple={true}
            showSelectAll={false}
            disabled={false}
            getOptions={getPlatforms}
            payloadData={fieldPayload()}
            labelWithId={false}
            onChange={setUpdateFilter}
            disableCloseOnSelect={true}
            showLabelCounter={true}
          />
        </Box>
      )}
    </Box>
  ) : null;

  return (
    <>
      {favoriteFields}
    </>
  );
};

export default VisibleFilter;
