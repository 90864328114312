import { api } from "../../api";
import {handleResponse, RequestOptions} from "../types";
import {getCookie} from "../../helpers/utils";
import {cookieToken} from "../../constants";

const getListData = (data: any) => {
    return fetch(`${api.admin.reports.finance_report.list}`, {...RequestOptions('POST', JSON.stringify(data))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getFiltersField = (data: {
  [key: string]: any;
}) => {
  const authenticity_token = getCookie(cookieToken);
  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  const mapping = {
    departmentIds: "departments",
    managerIds: "chiefs",
    statuses: "statuses",
    affiliateIds: "affiliates",
    platformIds: "platforms",
    paymentMethodNames: "payment_method_names",
    companies: "affiliate_companies",
  };

  //@ts-ignore
  const field = `${mapping[`${data.label}`]}`;

  if (field === 'platforms') {
    return fetch(`${api.fields.platforms}`, {...RequestOptions('POST', JSON.stringify(payload))})
      .then(handleResponse)
      .then(
        data => {
          return data;
        }
      )
      .catch((error) => {
        return error
      });
  } else {
    return fetch(`${api.admin.reports.finance_report.filters}/${field}`, {...RequestOptions('POST', JSON.stringify(payload))})
      .then(handleResponse)
      .then(
        data => {
          return data;
        }
      )
      .catch((error) => {
        return error
      });
  }
}

export const financeReportService = {
  getListData,
  getFiltersField
};
