//core
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";

//service
import {invitePartnerService} from "../../../services/common/invitePartner.service";

export type invitePartnerSliceState = {
  invitePartner: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  resend: {
    data: any,
    error: any,
    isFetching: boolean,
  }
}

const initialState: invitePartnerSliceState = {
  invitePartner: {
    data: null,
    error: null,
    isFetching: false,
  },
  resend: {
    data: null,
    error: null,
    isFetching: false,
  },
}

export const sendPartnerPasswordData: any = createAsyncThunk(
  'sendPartnerPasswordData',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await invitePartnerService.invitePartner(payload);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const resendPartnerLinkData: any = createAsyncThunk(
  'resendPartnerLinkData',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await invitePartnerService.resendPartnerLink(payload);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);


//slice
const invitePartnerSlice = createSlice({
  name: 'invite_partner',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    clearSendStateData(state) {
      state.resend.data = null;
      state.resend.error = null;
      state.resend.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<invitePartnerSliceState>>) => {
    builder.addCase(sendPartnerPasswordData.pending, (state ) => {
      state.invitePartner.isFetching = true;
      state.invitePartner.error = null;
    });
    builder.addCase(sendPartnerPasswordData.fulfilled, (state, action ) => {
      state.invitePartner.error = null;
      state.invitePartner.isFetching = false;
      state.invitePartner.data = action.payload;
    });
    builder.addCase(sendPartnerPasswordData.rejected, (state, action ) => {
      state.invitePartner.error = action.payload;
    });

    builder.addCase(resendPartnerLinkData.pending, (state ) => {
      state.resend.isFetching = true;
      state.resend.error = null;
    });
    builder.addCase(resendPartnerLinkData.fulfilled, (state, action ) => {
      state.resend.error = null;
      state.resend.isFetching = false;
      state.resend.data = action.payload;
    });
    builder.addCase(resendPartnerLinkData.rejected, (state, action ) => {
      state.resend.error = action.payload;
    });
  },
});

export default invitePartnerSlice.reducer;

export const {
  reset,
  clearSendStateData,
} = invitePartnerSlice.actions;
