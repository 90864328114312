import { createContext } from "react";
import { itemListData } from "../../../../common/TableData";

interface IDealsContext {
  selectedRow: itemListData | null,
  setSelectedRow: (data: itemListData | null) => void;
}

export const DealsContext = createContext<IDealsContext>({
  selectedRow: null,
  setSelectedRow: () => {},
});


