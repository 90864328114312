//core
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";

//service
import {invitationLinksService} from "../../../services/admin/invitation_links.service";
import {setApproveAdjustmentState, setGeneralProgressHide, setGeneralSnackbarState} from "../../common/ui";
import {setUser} from "../../common/user";

export type invitationLinksSliceState = {
  urlsData: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  invitationLinksData: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  addNewLink: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  getListLinks: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  getArchivedListLinks: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  editCurrentLink: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  removeLink: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  archiveLink: {
    data: any,
    error: any,
    isFetching: boolean,
  },
}

const initialState: invitationLinksSliceState = {
  urlsData: {
    data: null,
    error: null,
    isFetching: false,
  },
  invitationLinksData: {
    data: null,
    error: null,
    isFetching: false,
  },
  addNewLink: {
    data: null,
    error: null,
    isFetching: false,
  },
  getListLinks: {
    data: null,
    error: null,
    isFetching: false,
  },
  getArchivedListLinks: {
    data: null,
    error: null,
    isFetching: false,
  },
  editCurrentLink: {
    data: null,
    error: null,
    isFetching: false,
  },
  removeLink: {
    data: null,
    error: null,
    isFetching: false,
  },
  archiveLink: {
    data: null,
    error: null,
    isFetching: false,
  },
}

export const getUrlsData: any = createAsyncThunk(
  '/invitation-link/create-options',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await invitationLinksService.getUrlsData();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getListLinks: any = createAsyncThunk(
  '/invitation-link/list',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await invitationLinksService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }



      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getArchivedListLinks: any = createAsyncThunk(
  '/invitation-link/archived_list',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await invitationLinksService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const addNewLink: any = createAsyncThunk(
  '/invitation-link/',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await invitationLinksService.addNewLink(payload);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const editCurrentLink: any = createAsyncThunk(
  '/invitation-link/edit',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await invitationLinksService.editLink(payload);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const removeLink: any = createAsyncThunk(
  '/invitation-link/remove',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await invitationLinksService.removeLink(payload);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const archiveLink: any = createAsyncThunk(
  '/invitation-link/archive',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await invitationLinksService.archiveLink(payload);
      const data = await response.json();

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setApproveAdjustmentState(false));
      }

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

//slice
const invitationLinksSlice = createSlice({
  name: 'invitation_links',
  initialState: initialState,
  reducers: {
    invitationLinksSetError(state, action) {
      state.invitationLinksData.error = action.payload;
    },
    setInvitationLinksData(state, action) {
      state.invitationLinksData.data = action.payload;
    },
    clearAddNewLinkState(state) {
      state.addNewLink.data = null;
      state.addNewLink.isFetching = false;
      state.addNewLink.error = null;
    },
    clearEditNewLinkState(state) {
      state.editCurrentLink.data = null;
      state.editCurrentLink.isFetching = false;
      state.editCurrentLink.error = null;
    },
    clearActualListState(state) {
      state.getListLinks.data = null;
      state.getListLinks.isFetching = false;
      state.getListLinks.error = null;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<invitationLinksSliceState>>) => {
    builder.addCase(getUrlsData.pending, (state ) => {
      state.urlsData.isFetching = true;
      state.urlsData.error = null;
    });
    builder.addCase(getUrlsData.fulfilled, (state, action ) => {
      state.urlsData.error = null;
      state.urlsData.isFetching = false;
      state.urlsData.data = action.payload;
    });
    builder.addCase(getUrlsData.rejected, (state, action ) => {
      state.urlsData.error = action.payload;
    });
    builder.addCase(addNewLink.pending, (state ) => {
      state.addNewLink.isFetching = true;
      state.addNewLink.error = null;
    });
    builder.addCase(addNewLink.fulfilled, (state, action ) => {
      state.addNewLink.error = null;
      state.addNewLink.isFetching = false;
      state.addNewLink.data = action.payload;
    });
    builder.addCase(addNewLink.rejected, (state, action ) => {
      state.addNewLink.error = action.payload;
    });
    builder.addCase(getListLinks.pending, (state ) => {
      state.getListLinks.isFetching = true;
      state.getListLinks.error = null;
    });
    builder.addCase(getListLinks.fulfilled, (state, action ) => {
      state.getListLinks.error = null;
      state.getListLinks.isFetching = false;
      state.getListLinks.data = action.payload;
    });
    builder.addCase(getListLinks.rejected, (state, action ) => {
      state.getListLinks.error = action.payload;
    });
    builder.addCase(getArchivedListLinks.pending, (state ) => {
      state.getArchivedListLinks.isFetching = true;
      state.getArchivedListLinks.error = null;
    });
    builder.addCase(getArchivedListLinks.fulfilled, (state, action ) => {
      state.getArchivedListLinks.error = null;
      state.getArchivedListLinks.isFetching = false;
      state.getArchivedListLinks.data = action.payload;
    });
    builder.addCase(getArchivedListLinks.rejected, (state, action ) => {
      state.getArchivedListLinks.error = action.payload;
    });
    builder.addCase(editCurrentLink.pending, (state ) => {
      state.editCurrentLink.isFetching = true;
      state.editCurrentLink.error = null;
    });
    builder.addCase(editCurrentLink.fulfilled, (state, action ) => {
      state.editCurrentLink.error = null;
      state.editCurrentLink.isFetching = false;
      state.editCurrentLink.data = action.payload;
    });
    builder.addCase(editCurrentLink.rejected, (state, action ) => {
      state.editCurrentLink.error = action.payload;
    });
    builder.addCase(removeLink.pending, (state ) => {
      state.removeLink.isFetching = true;
      state.removeLink.error = null;
    });
    builder.addCase(removeLink.fulfilled, (state, action ) => {
      state.removeLink.error = null;
      state.removeLink.isFetching = false;
      state.removeLink.data = action.payload;
    });
    builder.addCase(removeLink.rejected, (state, action ) => {
      state.removeLink.error = action.payload;
    });
    builder.addCase(archiveLink.pending, (state ) => {
      state.archiveLink.isFetching = true;
      state.archiveLink.error = null;
    });
    builder.addCase(archiveLink.fulfilled, (state, action ) => {
      state.archiveLink.error = null;
      state.archiveLink.isFetching = false;
      state.archiveLink.data = action.payload;
    });
    builder.addCase(archiveLink.rejected, (state, action ) => {
      state.archiveLink.error = action.payload;
    });
  },
});

export default invitationLinksSlice.reducer;

export const {
  invitationLinksSetError,
  setInvitationLinksData,
  clearAddNewLinkState,
  clearEditNewLinkState,
  clearActualListState,
} = invitationLinksSlice.actions;
