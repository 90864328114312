import {api} from "../../api";
import {handleResponse, RequestOptions} from "../types";
import {getCookie} from "../../helpers/utils";
import {cookieSupervision, cookieToken} from "../../constants";


const getList = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.download_report.list : api.affiliate.download_report.list;

  const payload = {
    ...data,
    authenticity_token: `${authenticity_token ? authenticity_token : 'null'}`,
    ...(isSupervision ? { supervision: true } : {}),
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getDownload = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.download_report.download : api.affiliate.download_report.download;

  const url = `${endpoint
    .replace('{reportId}', data.id)}${authenticity_token ? `?authenticity_token=${authenticity_token}` : 'null'}${isSupervision ? '&supervision=true' : ''}`

  return fetch(
    `${url}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const deleteReport = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.download_report.delete : api.affiliate.download_report.delete;

  const url = `${endpoint
    .replace('{reportId}', data.id)}${authenticity_token ? `?authenticity_token=${authenticity_token}` : 'null'}${isSupervision ? '&supervision=true' : ''}`

  return fetch(
    `${url}`,
    {...RequestOptions('DELETE')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};


export const downloadReportService = {
  getList,
  getDownload,
  deleteReport,
};
