//core
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { partnersService } from "../../../services/admin/partners.service";
import { setUser } from "../../common/user";
import {setGeneralProgressHide, setGeneralProgressShow, setGeneralSnackbarState} from "../../common/ui";
import { OrderType } from "../../../components/affiliate/Trackers";

export type ListItemType = {
  id: string | number;
  value: string;
}

export type ListItemData =  {
  id: number;
  name: string;
  username: string;
  email: string;
  status: string;
  is_blocked: boolean;
  is_email_confirmed: boolean;
  contact_type: string;
  contact_value: string;
  manager_id: number | null;
  manager_team: number | null;
  manager_username: string;
  condition_id: number;
  condition_name: string;
  referral_token: string | null;
  master_id: number | null;
  master_name: string;
  traffic_status: string;
  traffic_url: string | null;
  traffic_geo: string[];
  traffic_sources: string[];
  last_visit_date: string;
  last_visit_ip: string;
  last_visit_country: string;
  communication_status: string;
  communication_date: string;
  registration_date: string;
  decision_date: string;
  trackers_count: number;
  role_name: string;
  role_id: number,
  is_survey_completed: boolean;
  invitation_token: string;
  invitation_manager: string;
  invitation_manager_id: number | null;
  platform_name: string;
  promocode: string;
};

export type ColumnsItemData = {
  name: string;
  type: string;
  sort: boolean;
  match?: boolean;
};

export type PartnersListData = {
  list: ListItemData[];
  columns: ColumnsItemData[];
  start_row_num: number;
  end_row_num: number;
  total_entries: number | string;
  total_pages: number;
};

export type ListPayloadType = {
  filter: {
    [key: string]: any,
    search: string | null;
  };
  order: {
    [key: string]: 'asc' | 'desc'
  };
  page: number;
  perPage: number;
};

export type OptionsPayloadManagerType = {
  label?: string;
  page: number,
  chiefIds: number[] | string[] | null;
  departmentIds:  number[] | string[] | null;
  search: string | null;
  updateList?: boolean;
  per_page: number;
};

export type DealsPayloadType = {
  page: number;
  perPage: number;
  search: string | null;
};

export type AffDealsPayloadType = {
  affiliateId: string | number;
  payload: {
    page: number;
    per_page: number;
    order: OrderType;
    search: {
      keyword: string;
    };
    filter: {
      [key: string]: any,
    };
  }
};

export type SelectFieldDataType = {
  list: ListItemType[];
  start_row_num: number;
  end_row_num: number;
  total_entries: number;
  total_pages: number;
}

export type DealsListItemType = {
  id: number | string;
  value: string;
}

export type DealsDataType = {
  list: DealsListItemType[],
  total_entries: number;
  start_row_num: number;
  end_row_num: number;
  total_pages: number;
}

export type ApprovePayloadType = {
  affiliateId: string | number;
  data: {
    deal_id: number;
    chief_id: number;
  }
}

export type NotesPayloadType = {
  affiliateId: string | number;
  data: {
    notes: string;
  }
}

export type ResendConfirmationPayloadType = {
  affiliateId: string | number;
  data: {
    domain: string;
  }
}

export type OptionsPayloadType = {
  label?: string;
  page: number,
  status?: string;
  platformIds?: number[] | string[] | null;
  chiefIds: number[] | string[] | null;
  departmentIds:  number[] | string[] | null;
  search: string | null;
  updateList?: boolean;
  per_page: number;
};

export type PartnersSliceState = {
  list: {
    data: PartnersListData | null,
    error: any,
    isFetching: boolean,
  },
  filters: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  acceptManagers: {
    data: SelectFieldDataType | null,
    error: any,
    isFetching: boolean,
  },
  acceptManagersAll: {
    data: SelectFieldDataType | null,
    error: any,
    isFetching: boolean,
  },
  deals: {
    data: DealsDataType | null,
    error: any,
    isFetching: boolean,
  },
  approve: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  decline: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  block: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  unblock: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  resendConfirmationEmail: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  affiliates: {
    data: SelectFieldDataType | null,
    error: any,
    isFetching: boolean,
  },
  chiefs: {
    data: SelectFieldDataType | null,
    error: any,
    isFetching: boolean,
  },
  departments: {
    data: SelectFieldDataType | null,
    error: any,
    isFetching: boolean,
  },
  platforms: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  platformsStatic: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  details: {
    data: PartnersListData | null,
    error: any,
    isFetching: boolean,
  },
  createPartner: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  invitationLinks: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  invitationManagers: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  affDealsList: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
};

const initialState: PartnersSliceState = {
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  filters: {
    data: null,
    error: null,
    isFetching: false,
  },
  acceptManagers: {
    data: null,
    error: null,
    isFetching: false,
  },
  acceptManagersAll: {
    data: null,
    error: null,
    isFetching: false,
  },
  deals: {
    data: null,
    error: null,
    isFetching: false,
  },
  approve: {
    data: null,
    error: null,
    isFetching: false,
  },
  decline: {
    data: null,
    error: null,
    isFetching: false,
  },
  block: {
    data: null,
    error: null,
    isFetching: false,
  },
  unblock: {
    data: null,
    error: null,
    isFetching: false,
  },
  resendConfirmationEmail: {
    data: null,
    error: null,
    isFetching: false,
  },
  affiliates: {
    data: null,
    error: null,
    isFetching: false,
  },
  chiefs: {
    data: null,
    error: null,
    isFetching: false,
  },
  departments: {
    data: null,
    error: null,
    isFetching: false,
  },
  platforms: {
    data: null,
    error: null,
    isFetching: false,
  },
  platformsStatic: {
    data: null,
    error: null,
    isFetching: false,
  },
  details: {
    data: null,
    error: null,
    isFetching: false,
  },
  createPartner: {
    data: null,
    error: null,
    isFetching: false,
  },
  invitationLinks: {
    data: null,
    error: null,
    isFetching: false,
  },
  invitationManagers: {
    data: null,
    error: null,
    isFetching: false,
  },
  affDealsList: {
    data: null,
    error: null,
    isFetching: false,
  },
};


export const getFiltersData: any = createAsyncThunk(
  'partners/getFiltersData',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await partnersService.getFilters();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getListData: any = createAsyncThunk(
  'partners/getListData',
  async (payload: ListPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await partnersService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getAcceptManagersData: any = createAsyncThunk(
  'partners/getAcceptManagersData',
  async (payload: OptionsPayloadManagerType, { rejectWithValue, dispatch }) => {

    try {
      const response = await partnersService.getChiefs(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);


export const getAcceptManagersAllData: any = createAsyncThunk(
  'partners/getAcceptManagersAllData',
  async (payload: OptionsPayloadManagerType, { rejectWithValue, dispatch }) => {

    try {
      const response = await partnersService.getChiefsAll(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getDealsData: any = createAsyncThunk(
  'partners/getDealsData',
  async (payload: DealsPayloadType, { rejectWithValue, dispatch }) => {
    try {
      const response = await partnersService.getDeals(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const setApproveAffiliate: any = createAsyncThunk(
  'partners/setApproveAffiliate',
  async (payload: ApprovePayloadType, { rejectWithValue, dispatch }) => {
    try {
      const response = await partnersService.setApproveAffiliate(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      return {
        affId: payload.affiliateId,
        data: data,
      };
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const setDeclineAffiliate: any = createAsyncThunk(
  'partners/setDeclineAffiliate',
  async (payload: NotesPayloadType, { rejectWithValue, dispatch }) => {
    try {
      const response = await partnersService.setDeclineAffiliate(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const setBlockAffiliate: any = createAsyncThunk(
  'partners/setBlockAffiliate',
  async (payload: NotesPayloadType, { rejectWithValue, dispatch }) => {
    try {
      const response = await partnersService.setBlockAffiliate(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const setUnblockAffiliate: any = createAsyncThunk(
  'partners/setUnblockAffiliate',
  async (payload: NotesPayloadType, { rejectWithValue, dispatch }) => {
    try {
      const response = await partnersService.setUnblockAffiliate(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const setResendConfirmationEmail: any = createAsyncThunk(
  'partners/setResendConfirmationEmail',
  async (payload: ResendConfirmationPayloadType, { rejectWithValue, dispatch }) => {
    try {
      const response = await partnersService.setResendConfirmationEmail(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.resend_confirmation_success',
          })
        );
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const getAffiliatesData: any = createAsyncThunk(
  'partners/getAffiliatesData',
  async (payload: OptionsPayloadType, { rejectWithValue, dispatch }) => {

    try {
      const response = await partnersService.getAffiliates(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getChiefsData: any = createAsyncThunk(
  'partners/getChiefsData',
  async (payload: OptionsPayloadType, { rejectWithValue, dispatch }) => {

    try {
      const response = await partnersService.getChiefs(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getDepartmentsData: any = createAsyncThunk(
  'partners/getDepartmentsData',
  async (payload: OptionsPayloadType, { rejectWithValue, dispatch }) => {

    try {
      const response = await partnersService.getDepartments(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getPlatformsData: any = createAsyncThunk(
  'partners/getPlatfromsData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await partnersService.getPlatfroms(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getPlatformsStaticData: any = createAsyncThunk(
  'partners/getPlatformsStaticData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await partnersService.getPlatfroms(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getDetailsData: any = createAsyncThunk(
  'partners/getDetailsData',
  async (payload: ListPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await partnersService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const createPartnerData: any = createAsyncThunk(
  'partners/createPartnerData',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await partnersService.createPartner(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.resend_confirmation_success',
          })
        );
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: `activerecord.errors.models.${data.error}`,
          })
        );
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


export const getInvitationLinksData: any = createAsyncThunk(
  'partners/getInvitationLinksData',
  async (payload: OptionsPayloadManagerType, { rejectWithValue, dispatch }) => {

    try {
      const response = await partnersService.getInvitationLinks(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getInvitationManagersData: any = createAsyncThunk(
  'partners/getInvitationManagersData',
  async (payload: OptionsPayloadManagerType, { rejectWithValue, dispatch }) => {

    try {
      const response = await partnersService.getInvitationManagers(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getAffDealsListData: any = createAsyncThunk(
  'partners/getAffDealsListData',
  async (payload: AffDealsPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await partnersService.getAffDeals(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


//slice
const partnersSlice = createSlice({
  name: 'partners',
  initialState: initialState,
  reducers: {
    clearListState(state) {
      state.list.data = null;
      state.list.error = null;
      state.list.isFetching = false;
    },
    clearApproveState(state) {
      state.approve.data = null;
      state.approve.error = null;
      state.approve.isFetching = false;
    },
    clearDeclineState(state) {
      state.decline.data = null;
      state.decline.error = null;
      state.decline.isFetching = false;
    },
    clearBlockState(state) {
      state.block.data = null;
      state.block.error = null;
      state.block.isFetching = false;
    },
    clearUnblockState(state) {
      state.unblock.data = null;
      state.unblock.error = null;
      state.unblock.isFetching = false;
    },
    clearResendConfirmationState(state) {
      state.resendConfirmationEmail.data = null;
      state.resendConfirmationEmail.error = null;
      state.resendConfirmationEmail.isFetching = false;
    },
    clearDetailsState(state) {
      state.details.data = null;
      state.details.error = null;
      state.details.isFetching = false;
    },
    clearPlatformsState(state) {
      state.platforms.data = null;
      state.platforms.error = null;
      state.platforms.isFetching = false;
    },
    clearPlatformsStaticState(state) {
      state.platformsStatic.data = null;
      state.platformsStatic.error = null;
      state.platformsStatic.isFetching = false;
    },
    clearAffiliatesState(state) {
      state.affiliates.data = null;
      state.affiliates.error = null;
      state.affiliates.isFetching = false;
    },
    clearNewPartnerData(state) {
      state.createPartner.data = null;
      state.createPartner.error = null;
      state.createPartner.isFetching = false;
    },
    clearAffDealsListData(state) {
      state.affDealsList.data = null;
      state.affDealsList.error = null;
      state.affDealsList.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<PartnersSliceState>>) => {
    builder.addCase(getFiltersData.pending, (state ) => {
      state.filters.isFetching = true;
      state.filters.error = null;
    });
    builder.addCase(getFiltersData.fulfilled, (state , action) => {
      state.filters.error = null;
      state.filters.isFetching = false;
      state.filters.data = action.payload;
    });
    builder.addCase(getFiltersData.rejected, (state , action) => {
      state.filters.isFetching = false;
      state.filters.error = action.payload;
    });

    builder.addCase(getListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getListData.fulfilled, (state , action) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getListData.rejected, (state , action) => {
      state.list.isFetching = false;
      state.list.error = action.payload;
    });

    builder.addCase(getAcceptManagersData.pending, (state ) => {
      state.acceptManagers.isFetching = true;
      state.acceptManagers.error = null;
    });
    builder.addCase(getAcceptManagersData.fulfilled, (state , action) => {
      state.acceptManagers.error = null;
      state.acceptManagers.isFetching = false;
      state.acceptManagers.data = action.payload;
    });
    builder.addCase(getAcceptManagersData.rejected, (state , action) => {
      state.acceptManagers.isFetching = false;
      state.acceptManagers.error = action.payload;
    });

    builder.addCase(getAcceptManagersAllData.pending, (state ) => {
      state.acceptManagersAll.isFetching = true;
      state.acceptManagersAll.error = null;
    });
    builder.addCase(getAcceptManagersAllData.fulfilled, (state , action) => {
      state.acceptManagersAll.error = null;
      state.acceptManagersAll.isFetching = false;
      state.acceptManagersAll.data = action.payload;
    });
    builder.addCase(getAcceptManagersAllData.rejected, (state , action) => {
      state.acceptManagersAll.isFetching = false;
      state.acceptManagersAll.error = action.payload;
    });

    builder.addCase(getDealsData.pending, (state ) => {
      state.deals.isFetching = true;
      state.deals.error = null;
    });
    builder.addCase(getDealsData.fulfilled, (state , action) => {
      state.deals.error = null;
      state.deals.isFetching = false;
      state.deals.data = action.payload;
    });
    builder.addCase(getDealsData.rejected, (state , action) => {
      state.deals.isFetching = false;
      state.deals.error = action.payload;
    });

    builder.addCase(setApproveAffiliate.pending, (state ) => {
      state.approve.isFetching = true;
      state.approve.error = null;
    });
    builder.addCase(setApproveAffiliate.fulfilled, (state , action) => {
      state.approve.error = null;
      state.approve.isFetching = false;
      state.approve.data = action.payload;
    });
    builder.addCase(setApproveAffiliate.rejected, (state , action) => {
      state.approve.isFetching = false;
      state.approve.error = action.payload;
    });

    builder.addCase(setDeclineAffiliate.pending, (state ) => {
      state.decline.isFetching = true;
      state.decline.error = null;
    });
    builder.addCase(setDeclineAffiliate.fulfilled, (state , action) => {
      state.decline.error = null;
      state.decline.isFetching = false;
      state.decline.data = action.payload;
    });
    builder.addCase(setDeclineAffiliate.rejected, (state , action) => {
      state.decline.isFetching = false;
      state.decline.error = action.payload;
    });

    builder.addCase(setBlockAffiliate.pending, (state ) => {
      state.block.isFetching = true;
      state.block.error = null;
    });
    builder.addCase(setBlockAffiliate.fulfilled, (state , action) => {
      state.block.error = null;
      state.block.isFetching = false;
      state.block.data = action.payload;
    });
    builder.addCase(setBlockAffiliate.rejected, (state , action) => {
      state.block.isFetching = false;
      state.block.error = action.payload;
    });

    builder.addCase(setUnblockAffiliate.pending, (state ) => {
      state.unblock.isFetching = true;
      state.unblock.error = null;
    });
    builder.addCase(setUnblockAffiliate.fulfilled, (state , action) => {
      state.unblock.error = null;
      state.unblock.isFetching = false;
      state.unblock.data = action.payload;
    });
    builder.addCase(setUnblockAffiliate.rejected, (state , action) => {
      state.unblock.isFetching = false;
      state.unblock.error = action.payload;
    });

    builder.addCase(setResendConfirmationEmail.pending, (state ) => {
      state.resendConfirmationEmail.isFetching = true;
      state.resendConfirmationEmail.error = null;
    });
    builder.addCase(setResendConfirmationEmail.fulfilled, (state , action) => {
      state.resendConfirmationEmail.error = null;
      state.resendConfirmationEmail.isFetching = false;
      state.resendConfirmationEmail.data = action.payload;
    });
    builder.addCase(setResendConfirmationEmail.rejected, (state , action) => {
      state.resendConfirmationEmail.isFetching = false;
      state.resendConfirmationEmail.error = action.payload;
    });

    builder.addCase(getAffiliatesData.pending, (state ) => {
      state.affiliates.isFetching = true;
      state.affiliates.error = null;
    });
    builder.addCase(getAffiliatesData.fulfilled, (state , action) => {
      state.affiliates.error = null;
      state.affiliates.isFetching = false;
      state.affiliates.data = action.payload;
    });
    builder.addCase(getAffiliatesData.rejected, (state , action) => {
      state.affiliates.isFetching = false;
      state.affiliates.error = action.payload;
    });

    builder.addCase(getChiefsData.pending, (state ) => {
      state.chiefs.isFetching = true;
      state.chiefs.error = null;
    });
    builder.addCase(getChiefsData.fulfilled, (state , action) => {
      state.chiefs.error = null;
      state.chiefs.isFetching = false;
      state.chiefs.data = action.payload;
    });
    builder.addCase(getChiefsData.rejected, (state , action) => {
      state.chiefs.isFetching = false;
      state.chiefs.error = action.payload;
    });

    builder.addCase(getDepartmentsData.pending, (state ) => {
      state.departments.isFetching = true;
      state.departments.error = null;
    });
    builder.addCase(getDepartmentsData.fulfilled, (state , action) => {
      state.departments.error = null;
      state.departments.isFetching = false;
      state.departments.data = action.payload;
    });
    builder.addCase(getDepartmentsData.rejected, (state , action) => {
      state.departments.isFetching = false;
      state.departments.error = action.payload;
    });

    builder.addCase(getPlatformsData.pending, (state ) => {
      state.platforms.isFetching = true;
      state.platforms.error = null;
    });
    builder.addCase(getPlatformsData.fulfilled, (state , action) => {
      state.platforms.error = null;
      state.platforms.isFetching = false;
      state.platforms.data = action.payload;
    });
    builder.addCase(getPlatformsData.rejected, (state , action) => {
      state.platforms.isFetching = false;
      state.platforms.error = action.payload;
    });

    builder.addCase(getPlatformsStaticData.pending, (state ) => {
      state.platformsStatic.isFetching = true;
      state.platformsStatic.error = null;
    });
    builder.addCase(getPlatformsStaticData.fulfilled, (state , action) => {
      state.platformsStatic.error = null;
      state.platformsStatic.isFetching = false;
      state.platformsStatic.data = action.payload;
    });
    builder.addCase(getPlatformsStaticData.rejected, (state , action) => {
      state.platformsStatic.isFetching = false;
      state.platformsStatic.error = action.payload;
    });

    builder.addCase(getDetailsData.pending, (state ) => {
      state.details.isFetching = true;
      state.details.error = null;
    });
    builder.addCase(getDetailsData.fulfilled, (state , action) => {
      state.details.error = null;
      state.details.isFetching = false;
      state.details.data = action.payload;
    });
    builder.addCase(getDetailsData.rejected, (state , action) => {
      state.details.isFetching = false;
      state.details.error = action.payload;
    });

    builder.addCase(createPartnerData.pending, (state ) => {
      state.createPartner.isFetching = true;
      state.createPartner.error = null;
    });
    builder.addCase(createPartnerData.fulfilled, (state , action) => {
      state.createPartner.error = null;
      state.createPartner.isFetching = false;
      state.createPartner.data = action.payload;
    });
    builder.addCase(createPartnerData.rejected, (state , action) => {
      state.createPartner.isFetching = false;
      state.createPartner.error = action.payload;
    });

    builder.addCase(getInvitationLinksData.pending, (state ) => {
      state.invitationLinks.isFetching = true;
      state.invitationLinks.error = null;
    });
    builder.addCase(getInvitationLinksData.fulfilled, (state , action) => {
      state.invitationLinks.error = null;
      state.invitationLinks.isFetching = false;
      state.invitationLinks.data = action.payload;
    });
    builder.addCase(getInvitationLinksData.rejected, (state , action) => {
      state.invitationLinks.isFetching = false;
      state.invitationLinks.error = action.payload;
    });

    builder.addCase(getInvitationManagersData.pending, (state ) => {
      state.invitationManagers.isFetching = true;
      state.invitationManagers.error = null;
    });
    builder.addCase(getInvitationManagersData.fulfilled, (state , action) => {
      state.invitationManagers.error = null;
      state.invitationManagers.isFetching = false;
      state.invitationManagers.data = action.payload;
    });
    builder.addCase(getInvitationManagersData.rejected, (state , action) => {
      state.invitationManagers.isFetching = false;
      state.invitationManagers.error = action.payload;
    });

    builder.addCase(getAffDealsListData.pending, (state ) => {
      state.affDealsList.isFetching = true;
      state.affDealsList.error = null;
    });
    builder.addCase(getAffDealsListData.fulfilled, (state , action) => {
      state.affDealsList.error = null;
      state.affDealsList.isFetching = false;
      state.affDealsList.data = action.payload;
    });
    builder.addCase(getAffDealsListData.rejected, (state , action) => {
      state.affDealsList.isFetching = false;
      state.affDealsList.error = action.payload;
    });
  },
});

export default partnersSlice.reducer;

export const {
  clearApproveState,
  clearDeclineState,
  clearBlockState,
  clearUnblockState,
  clearResendConfirmationState,
  clearListState,
  clearDetailsState,
  clearPlatformsState,
  clearAffiliatesState,
  clearPlatformsStaticState,
  clearNewPartnerData,
  clearAffDealsListData,
} = partnersSlice.actions;
