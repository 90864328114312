import React, { useCallback, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProvided,
  DropResult,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import { DragIndicator, ArrowDropDown } from '@mui/icons-material';

import {
  Box,
  Chip,
  Menu,
  MenuItem,
  InputLabel,
  useMediaQuery,
} from "@mui/material";


//styles
import {
  Block,
  useStyles,
  DragField,
  FieldArrow,
  Fieldset,
} from "./styles";


//hooks
import useDimensions from "../../../../../../customHooks/useDimensions";

//components
import ExpandedMenuItem from "./ExpandedMenuItem";

export interface IItemOption {
  id: string | number;
  value: string | string[];
  expanded: boolean;
}

type StrNum = string | number;

interface IGroupByProps {
  name: string;
  disabled?: boolean;
  value: IItemOption[];
  options: IItemOption[];
  setFilter: (props: any) => void;
  prefix?: string;
}

export const hasActiveItem = (id: string | number, selected: IItemOption[]) => {
  const ids: StrNum[] = selected.reduce((acc: string[], item: IItemOption) => {
    if (typeof item.id === "string") {
      acc.push(item.id);
    }
    return acc;
  }, []);
  return ids.includes(id);
}

const GroupByField: React.FC<IGroupByProps> = ({
  name,
  value,
  options,
  setFilter,
  prefix= '',
  disabled = false,
}): JSX.Element => {
  const styles = useStyles();
  const { t } = useTranslation();
  const mobile = useMediaQuery('(max-width:767px)');

  const fieldRef = useRef(null);
  const fieldSize = useDimensions(fieldRef);

  const [dropdownWidth, setDropdownWidth] = useState(fieldSize.width | 0);

  const [selected, setSelected] = useState<IItemOption[]>(value);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  useEffect(() => {
    setDropdownWidth(fieldSize.width);
  }, [fieldSize]);

  const handleDelete = useCallback((element: any) => {
    const newSelectedValue = selected.filter((item: any) => item.id !== element.id);
    setFilter(newSelectedValue);
  }, [value, selected]);

  /*const handleChipMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, item: any) => {
    e.stopPropagation();
    // @ts-ignore
    const tagName = e.target.tagName.toLowerCase();
    if (tagName === 'svg' || tagName === 'path') {
      handleDelete(item);
    }
  }*/

  const handleChipClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleClick = useCallback((ref: any) => {
    setDropdownWidth(ref.current?.offsetWidth);
    setAnchorEl(ref.current);
  }, [setAnchorEl]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  },[setAnchorEl]);

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    const {destination, source} = result;

    if (!destination) {
      return;
    }

    const newSelectedItems: IItemOption[] = Array.from(selected);
    const [removed] = newSelectedItems.splice(source.index, 1);
    newSelectedItems.splice(destination.index, 0, removed);

    setFilter(newSelectedItems);
  };

  const handleChangeOption = (item: IItemOption) => {
    const hasItem = !!selected.filter((el: IItemOption) => el.id === item.id).length;

    if (hasItem) {
      setFilter((prevState: IItemOption[]) => {
        const newState = prevState.filter((el: IItemOption) => el.id !== item.id);

        return newState;
      });
    } else {
      setFilter((prevState: IItemOption[]) => {
        return [ ...prevState, item ]
      });
    }
  }

  return (
    <Block>
      <InputLabel className={`${styles.inputLabel} ${!!selected.length || open ? 'active' : ''}`}>
        {t("admin.reports.filter.group_by")}
      </InputLabel>
      <DragField
        isOpen={open}
        ref={fieldRef}
        id="group-by-menu"
        aria-controls={open ? 'basic-group-by-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Fieldset
          component="fieldset"
          selected={!!selected.length}
          isOpen={open}
          onClick={() => handleClick(fieldRef)}
        >
          <legend>
            <span>{t("admin.reports.filter.group_by")}</span>
          </legend>
        </Fieldset>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" ignoreContainerClipping={true} direction={mobile ? 'vertical' : 'horizontal'}>
            {(provided: DroppableProvided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="droppable-wrap"
              >
                {selected.map((item: IItemOption, index) => {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={`${item.id}`}
                      index={index}
                    >
                      {(providedDraggable: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                        <div
                          ref={providedDraggable.innerRef}
                          {...providedDraggable.draggableProps}
                          {...providedDraggable.dragHandleProps}
                          className="chipWrapper"
                          onClick={e => {
                            e.stopPropagation()
                          }}
                        >
                          <Chip
                            className={`${styles.chip} ${snapshot.isDragging ? 'isDragging' : ''} `}
                            size="small"
                            label={prefix ? t(`${prefix}${item.value}`) : item.value}
                            onClick={e => handleChipClick(e)}
                            //onMouseDown={(e) => handleChipMouseDown(e, item)}
                            onDelete={() => handleDelete(item)}
                            icon={<DragIndicator className={styles.drag} />}
                          />
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <FieldArrow isOpen={open}>
          <ArrowDropDown />
        </FieldArrow>
        <Box
          className={'handleLayer'}
          onClick={() => handleClick(fieldRef)}
        />
      </DragField>

      <Menu
        id="basic-group-by-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'group-by-menu' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        PaperProps={{
          style: {
            width: "100%",
            maxWidth: `${dropdownWidth}px`,
          }
        }}
      >
        {
          options.map((item) => {
            if (!item.expanded) {
              return (
                <MenuItem
                  sx={{
                    minHeight: '36px',
                  }}
                  key={item.id}
                  selected={hasActiveItem(item.id, selected)}
                  onClick={() => handleChangeOption(item)}
                >
                  {prefix ? t(`${prefix}${item.value}`) : item.value}
                </MenuItem>
              )
            } else {
              return (
                <ExpandedMenuItem
                  key={item.id}
                  id={item.id}
                  name={name}
                  selected={selected}
                  options={item.value}
                  setFilter={setFilter}
                  prefix={prefix}
                />
              )
            }
          })
        }
      </Menu>
    </Block>
  );
};

export default GroupByField;
