//core
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { fieldsService } from "../../../services/common/fields.service";
import { setUser } from "../user";
import {setGeneralProgressHide, setGeneralProgressShow} from "../ui";

export type countryItem = {
  code: string
  name: string
}

export type OptionsPayloadType = {
  page: number,
  search?: string | null;
  per_page: number;
  label?: string;
  chiefIds?: number[] | string[] | null;
  departmentIds?:  number[] | string[] | null;
  updateList?: boolean;
  filter?: {
    search?: string | null;
    use_as_traffic_back?: boolean;
  };
  order?: {
    [key: string]: 'asc' | 'desc';
  },
};

export type ListItemType = {
  id: string | number;
  value: string;
};

export type SelectFieldDataType = {
  list: ListItemType[];
  start_row_num: number;
  end_row_num: number;
  total_entries: number;
  total_pages: number;
};

export type FieldsSliceState = {
  affiliates: {
    data: SelectFieldDataType | null,
    error: any,
    isFetching: boolean,
  },
  chiefs: {
    data: SelectFieldDataType | null,
    error: any,
    isFetching: boolean,
  },
  departments: {
    data: SelectFieldDataType | null,
    error: any,
    isFetching: boolean,
  },
  platforms: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  countries: {
    data: countryItem[] | null,
    error: any,
    isFetching: boolean,
  },
  trackers: {
    data: SelectFieldDataType | null,
    error: any,
    isFetching: boolean,
  },
  webhooks: {
    data: SelectFieldDataType | null,
    error: any,
    isFetching: boolean,
  },
  offers: {
    data: SelectFieldDataType | null,
    error: any,
    isFetching: boolean,
  },
  operators: {
    data: SelectFieldDataType | null,
    error: any,
    isFetching: boolean,
  },
  brands: {
    data: SelectFieldDataType | null,
    error: any,
    isFetching: boolean,
  },
};

const initialState: FieldsSliceState = {
  affiliates: {
    data: null,
    error: null,
    isFetching: false,
  },
  chiefs: {
    data: null,
    error: null,
    isFetching: false,
  },
  departments: {
    data: null,
    error: null,
    isFetching: false,
  },
  platforms: {
    data: null,
    error: null,
    isFetching: false,
  },
  countries: {
    data: null,
    error: null,
    isFetching: false,
  },
  trackers: {
    data: null,
    error: null,
    isFetching: false,
  },
  webhooks: {
    data: null,
    error: null,
    isFetching: false,
  },
  offers: {
    data: null,
    error: null,
    isFetching: false,
  },
  operators: {
    data: null,
    error: null,
    isFetching: false,
  },
  brands: {
    data: null,
    error: null,
    isFetching: false,
  },
};

export const getAffiliatesData: any = createAsyncThunk(
  'fields/getAffiliatesData',
  async (payload: OptionsPayloadType, { rejectWithValue, dispatch }) => {

    try {
      const response = await fieldsService.getAffiliates(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getChiefsData: any = createAsyncThunk(
  'fields/getChiefsData',
  async (payload: OptionsPayloadType, { rejectWithValue, dispatch }) => {

    try {
      const response = await fieldsService.getChiefs(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getDepartmentsData: any = createAsyncThunk(
  'fields/getDepartmentsData',
  async (payload: OptionsPayloadType, { rejectWithValue, dispatch }) => {

    try {
      const response = await fieldsService.getDepartments(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getPlatformsData: any = createAsyncThunk(
  'fields/getPlatformsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await fieldsService.getPlatforms(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getCountriesData: any = createAsyncThunk(
  'fields/getCountriesData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fieldsService.getCountries();
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data.countries;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getTrackersData: any = createAsyncThunk(
  'fields/getTrackersData',
  async (payload: OptionsPayloadType, { rejectWithValue, dispatch }) => {

    try {
      const response = await fieldsService.getTrackers(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getWebhooksData: any = createAsyncThunk(
  'fields/getWebhooksData',
  async (payload: OptionsPayloadType, { rejectWithValue, dispatch }) => {

    try {
      const response = await fieldsService.getWebhooks(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getOffersData: any = createAsyncThunk(
  'fields/getOffersData',
  async (payload: OptionsPayloadType, { rejectWithValue, dispatch }) => {

    try {
      const response = await fieldsService.getOffers(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getOperatorsData: any = createAsyncThunk(
  'fields/getOperatorsData',
  async (payload: OptionsPayloadType, { rejectWithValue, dispatch }) => {

    try {
      const response = await fieldsService.getOperators(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getBrandsData: any = createAsyncThunk(
  'fields/getBrandsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await fieldsService.getBrands(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

//slice
const fieldsSlice = createSlice({
  name: 'fields',
  initialState: initialState,
  reducers: {
    clearOperatorsState(state) {
      state.operators.data = null;
      state.operators.error = null;
      state.operators.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<FieldsSliceState>>) => {
    builder.addCase(getAffiliatesData.pending, (state ) => {
      state.affiliates.isFetching = true;
      state.affiliates.error = null;
    });
    builder.addCase(getAffiliatesData.fulfilled, (state , action) => {
      state.affiliates.error = null;
      state.affiliates.isFetching = false;
      state.affiliates.data = action.payload;
    });
    builder.addCase(getAffiliatesData.rejected, (state , action) => {
      state.affiliates.isFetching = false;
      state.affiliates.error = action.payload;
    });

    builder.addCase(getChiefsData.pending, (state ) => {
      state.chiefs.isFetching = true;
      state.chiefs.error = null;
    });
    builder.addCase(getChiefsData.fulfilled, (state , action) => {
      state.chiefs.error = null;
      state.chiefs.isFetching = false;
      state.chiefs.data = action.payload;
    });
    builder.addCase(getChiefsData.rejected, (state , action) => {
      state.chiefs.isFetching = false;
      state.chiefs.error = action.payload;
    });

    builder.addCase(getDepartmentsData.pending, (state ) => {
      state.departments.isFetching = true;
      state.departments.error = null;
    });
    builder.addCase(getDepartmentsData.fulfilled, (state , action) => {
      state.departments.error = null;
      state.departments.isFetching = false;
      state.departments.data = action.payload;
    });
    builder.addCase(getDepartmentsData.rejected, (state , action) => {
      state.departments.isFetching = false;
      state.departments.error = action.payload;
    });

    builder.addCase(getPlatformsData.pending, (state ) => {
      state.platforms.isFetching = true;
      state.platforms.error = null;
    });
    builder.addCase(getPlatformsData.fulfilled, (state , action) => {
      state.platforms.error = null;
      state.platforms.isFetching = false;
      state.platforms.data = action.payload;
    });
    builder.addCase(getPlatformsData.rejected, (state , action) => {
      state.platforms.isFetching = false;
      state.platforms.error = action.payload;
    });

    builder.addCase(getCountriesData.pending, (state ) => {
      state.countries.isFetching = true;
      state.countries.error = null;
    });
    builder.addCase(getCountriesData.fulfilled, (state , action) => {
      state.countries.error = null;
      state.countries.isFetching = false;
      state.countries.data = action.payload;
    });
    builder.addCase(getCountriesData.rejected, (state , action) => {
      state.countries.isFetching = false;
      state.countries.error = action.payload;
    });

    builder.addCase(getTrackersData.pending, (state ) => {
      state.trackers.isFetching = true;
      state.trackers.error = null;
    });
    builder.addCase(getTrackersData.fulfilled, (state , action) => {
      state.trackers.error = null;
      state.trackers.isFetching = false;
      state.trackers.data = action.payload;
    });
    builder.addCase(getTrackersData.rejected, (state , action) => {
      state.trackers.isFetching = false;
      state.trackers.error = action.payload;
    });

    builder.addCase(getWebhooksData.pending, (state ) => {
      state.webhooks.isFetching = true;
      state.webhooks.error = null;
    });
    builder.addCase(getWebhooksData.fulfilled, (state , action) => {
      state.webhooks.error = null;
      state.webhooks.isFetching = false;
      state.webhooks.data = action.payload;
    });
    builder.addCase(getWebhooksData.rejected, (state , action) => {
      state.webhooks.isFetching = false;
      state.webhooks.error = action.payload;
    });

    builder.addCase(getOffersData.pending, (state ) => {
      state.offers.isFetching = true;
      state.offers.error = null;
    });
    builder.addCase(getOffersData.fulfilled, (state , action) => {
      state.offers.error = null;
      state.offers.isFetching = false;
      state.offers.data = action.payload;
    });
    builder.addCase(getOffersData.rejected, (state , action) => {
      state.offers.isFetching = false;
      state.offers.error = action.payload;
    });

    builder.addCase(getOperatorsData.pending, (state ) => {
      state.operators.isFetching = true;
      state.operators.error = null;
    });
    builder.addCase(getOperatorsData.fulfilled, (state , action) => {
      state.operators.error = null;
      state.operators.isFetching = false;
      state.operators.data = action.payload;
    });
    builder.addCase(getOperatorsData.rejected, (state , action) => {
      state.operators.isFetching = false;
      state.operators.error = action.payload;
    });

    builder.addCase(getBrandsData.pending, (state ) => {
      state.brands.isFetching = true;
      state.brands.error = null;
    });
    builder.addCase(getBrandsData.fulfilled, (state , action) => {
      state.brands.error = null;
      state.brands.isFetching = false;
      state.brands.data = action.payload;
    });
    builder.addCase(getBrandsData.rejected, (state , action) => {
      state.brands.isFetching = false;
      state.brands.error = action.payload;
    });
  },
});

export default fieldsSlice.reducer;

export const {
  clearOperatorsState,
} = fieldsSlice.actions;
