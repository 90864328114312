//core
import {createSlice} from "@reduxjs/toolkit";
import GeneralLinearProgress from "../../../components/common/GeneralLinearProgress";

export type uiSliceState = {
  mainNavigation: boolean,
  navigationExpandedPanel: string | false,
  generalProgress: boolean,
  generalSnackbar: {
    open: boolean,
    type: 'success' | 'info' | 'warning' | 'error',
    message: string,
    messageKey?: string,
  }
  modals: {
    [key: string]: any,
  }
}

const initialState: uiSliceState = {
  mainNavigation: false,
  navigationExpandedPanel: false,
  generalProgress: false,
  generalSnackbar: {
    open: false,
    type: 'info',
    message: '',
    messageKey: '',
  },
  modals: {
    createCreative: false,
    confirmationEmailSuccess: false,
    confirmationEmailError: false,
    registrationSuccess: false,
    addNewTracker: false,
    adminAddNewTracker: false,
    affTrackingLink: false,
    addAdjustment: false,
  }
}


//slice
const uiSlice = createSlice({
  name: 'ui',
  initialState: initialState,
  reducers: {
    setGeneralProgressShow(state) {
      state.generalProgress = true
    },
    setGeneralProgressHide(state) {
      state.generalProgress = false
    },

    setMainNavigationState(state, { payload }) {
      state.mainNavigation = payload
    },
    setNavigationExpandedPanelState(state, { payload }) {
      state.navigationExpandedPanel = payload
    },

    setGeneralSnackbarState(state, { payload }) {
      state.generalSnackbar = payload
    },

    setCreateCreativeState(state, { payload }) {
      state.modals.createCreative = payload
    },
    setConfirmationEmailSuccessState(state, { payload }) {
      state.modals.confirmationEmailSuccess = payload
    },
    setConfirmationEmailErrorState(state, { payload }) {
      state.modals.confirmationEmailError = payload
    },
    setRegistrationSuccessState(state, { payload }) {
      state.modals.registrationSuccess = payload
    },
    setAddNewTrackerState(state, { payload }) {
      state.modals.addNewTracker = payload
    },
    setAdminAddNewTrackerState(state, { payload }) {
      state.modals.adminAddNewTracker = payload
    },
    setAffTrackingLinkState(state, { payload }) {
      state.modals.affTrackingLink = payload
    },
    setAddAdjustmentState(state, { payload }) {
      state.modals.addAdjustment = payload
    },
    setEditAdjustmentState(state, { payload }) {
      state.modals.addAdjustment = payload
    },
    setApproveAdjustmentState(state, { payload }) {
      state.modals.addAdjustment = payload
    },
  },
});

export default uiSlice.reducer;

export const {
  setGeneralProgressShow,
  setGeneralProgressHide,
  setMainNavigationState,
  setNavigationExpandedPanelState,
  setGeneralSnackbarState,
  setCreateCreativeState,
  setConfirmationEmailSuccessState,
  setConfirmationEmailErrorState,
  setRegistrationSuccessState,
  setAddNewTrackerState,
  setAffTrackingLinkState,
  setAddAdjustmentState,
  setApproveAdjustmentState,
  setEditAdjustmentState,
  setAdminAddNewTrackerState,
} = uiSlice.actions;
