//core
import React from "react";
import {makeStyles} from "@mui/styles";

interface IValue {
  value: string | number;
}

const useStyles = makeStyles({
  text: {
    color: 'inherit',
    whiteSpace: 'nowrap'
  }
});

const DateRange = ({ value }: IValue) => {
  const styles = useStyles();

  return (
    <span className={styles.text}>
      { value }
    </span>
  );
};

export default DateRange;
