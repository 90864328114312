import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import { Theme } from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    position: "relative",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  field: {
    marginRight: 0,
    marginBottom: 0,
  },
  footer: {
    position: "absolute",
    right: 0,
    top: 0,
    display: "flex",
    width: "auto",
    alignItems: "center",
    justifyContent: "center",
    padding: "1px 4px",
  },
  iconBtn: {
    color: alpha(theme.palette.action.active, 0.54),
  },
}));
