import React, {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Formik, FormikHelpers } from "formik";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from '@mui/icons-material/Done';
import {
  Box,
  IconButton
} from "@mui/material";

//styles
import { useStyles } from "./EditFormStyles";

//validation
import { validationTextField } from "../../../../../../../../../validation/textFieldNotRequired.validation";

//hooks
import {
  useDetailsFinReport
} from "../../../../../../../../../../store/admin/reports/details_finance_report/useFinReport";

//selectors
import {
  selectChangeCommentData
} from "../../../../../../../../../../store/admin/reports/details_finance_report/selectors";

//components
import MuiTextField from "../../../../../../../../../UI/FormFields/MuiTextField";


interface IEditFormProps {
  row: {
    [key: string]: any,
  };
  comment: string;
  setHideForm: (val: boolean) => void;
  setComment: (val: string) => void;
}

const EditForm: React.FC<IEditFormProps> = ({
  row,
  comment,
  setHideForm,
  setComment,
}): JSX.Element => {
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  const formRef: any = useRef();
  const { reportId } = useParams();

  const { setChangeComment, clearChangeCommentState } = useDetailsFinReport();

  const data = useSelector(selectChangeCommentData);

  const [initialValues, setInitialValues] = useState<any>({
    comment: comment,
  });

  // validation errors translation
  const [errorsMessages, setErrorsMessages] = useState({
    maxLength: t("common.validation.comment_max_length"),
  });

  useEffect(() => {
    setErrorsMessages({
      maxLength: t("common.validation.comment_max_length"),
    })
  }, [i18n.language, t]);

  useEffect(() => {
    if (!!data) {
      const currentRow = `${row.affiliateId}-${row.brand}-${row.currency}`;
      const savedRow = `${data.affiliateId}-${data.brand}-${data.currency}`;

      if (currentRow === savedRow) {
        setComment(formRef.current.values.comment)
        setHideForm(false);
        clearChangeCommentState();
      }
    }
  }, [data, row]);

  const handleCancel = () => {
    setHideForm(false);
  }

  const submitForm = (values: { comment: string }, actions: FormikHelpers<any>) => {
    const sendData = {
      affiliateId: row.affiliateId,
      periodId: Number(reportId),
      brand: row.brand,
      currency: row.currency,
      comment: values.comment
    }

    setChangeComment(sendData)

    actions.setSubmitting(false);
  }

  const validateComment = (value: any) => validationTextField(value, 500, errorsMessages);

  return (
    <>
      <Formik
        validateOnChange
        validateOnBlur
        validateOnMount
        enableReinitialize
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={ (values, actions) => submitForm(values, actions) }
      >
        {
          props => {
            const {values, dirty, handleSubmit, handleChange, isValid} = props;

            const disableSubmit = isValid ? !dirty : true;

            return (
              <form className={styles.form} onSubmit={handleSubmit}>
                <MuiTextField
                  fieldClass={styles.field}
                  size="small"
                  id="comment"
                  type="text"
                  name="comment"
                  autoComplete="comment"
                  InputProps={{
                    sx: {
                      '> input': {
                        boxSizing: "border-box",
                        height: 30,
                        padding: "4px 68px 4px 14px",
                        lineHeight: '1.2',
                        fontSize: 14,
                      }
                    }
                  }}
                  validate={validateComment}
                  value={values.comment}
                  onChange={handleChange}
                />
                <Box className={styles.footer}>
                  <IconButton
                    disabled={disableSubmit}
                    sx={{ padding: '4px' }}
                    color="primary"
                    size="small"
                    type="submit"
                  >
                    <DoneIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    onClick={handleCancel}
                    sx={{ padding: '4px' }}
                    color="secondary"
                    size="small"
                  >
                    <CloseIcon className={styles.iconBtn} fontSize="small" />
                  </IconButton>
                </Box>
              </form>
            )
          }
        }
      </Formik>
    </>
  );
};

export default EditForm;
