//core
import { createSelector } from "@reduxjs/toolkit";

//store
import {RootState} from "../../../index";

// state
const creativeReportSelector = (state: RootState) => state.admin.creative_report;

const creativeReportOptionsSelector = (state: RootState) => state.admin.creative_report.options;
const creativeReportOptionsDataSelector = (state: RootState) => state.admin.creative_report.options.data;
const creativeReportOptionsErrorSelector = (state: RootState) => state.admin.creative_report.options.error;
const creativeReportOptionsFetchingSelector = (state: RootState) => state.admin.creative_report.options.isFetching;

const creativeReportListSelector = (state: RootState) => state.admin.creative_report.list;
const creativeReportListDataSelector = (state: RootState) => state.admin.creative_report.list.data;
const creativeReportListErrorSelector = (state: RootState) => state.admin.creative_report.list.error;
const creativeReportListFetchingSelector = (state: RootState) => state.admin.creative_report.list.isFetching;

const creativeReportDownloadSelector = (state: RootState) => state.admin.creative_report.download;
const creativeReportDownloadDataSelector = (state: RootState) => state.admin.creative_report.download.data;
const creativeReportDownloadErrorSelector = (state: RootState) => state.admin.creative_report.download.error;
const creativeReportDownloadFetchingSelector = (state: RootState) => state.admin.creative_report.download.isFetching;

const creativeReportAffiliatesSelector = (state: RootState) => state.admin.creative_report.affiliates;
const creativeReportAffiliatesDataSelector = (state: RootState) => state.admin.creative_report.affiliates.data;
const creativeReportAffiliatesErrorSelector = (state: RootState) => state.admin.creative_report.affiliates.error;
const creativeReportAffiliatesFetchingSelector = (state: RootState) => state.admin.creative_report.affiliates.isFetching;

const creativeReportTrackersSelector = (state: RootState) => state.admin.creative_report.trackers;
const creativeReportTrackersDataSelector = (state: RootState) => state.admin.creative_report.trackers.data;
const creativeReportTrackersErrorSelector = (state: RootState) => state.admin.creative_report.trackers.error;
const creativeReportTrackersFetchingSelector = (state: RootState) => state.admin.creative_report.trackers.isFetching;

const creativeReportDealsSelector = (state: RootState) => state.admin.creative_report.deals;
const creativeReportDealsDataSelector = (state: RootState) => state.admin.creative_report.deals.data;
const creativeReportDealsErrorSelector = (state: RootState) => state.admin.creative_report.deals.error;
const creativeReportDealsFetchingSelector = (state: RootState) => state.admin.creative_report.deals.isFetching;

const creativeReportCreativesSelector = (state: RootState) => state.admin.creative_report.creatives;
const creativeReportCreativesDataSelector = (state: RootState) => state.admin.creative_report.creatives.data;
const creativeReportCreativesErrorSelector = (state: RootState) => state.admin.creative_report.creatives.error;
const creativeReportCreativesFetchingSelector = (state: RootState) => state.admin.creative_report.creatives.isFetching;

const creativeReportColumnsDataSelector = (state: RootState) => state.admin.creative_report.columns.data;
const creativeReportColumnsErrorSelector = (state: RootState) => state.admin.creative_report.columns.error;
const creativeReportColumnsFetchingSelector = (state: RootState) => state.admin.creative_report.columns.isFetching;


//selectors
export const selectSummaryReport = createSelector(creativeReportSelector, (report) => report);

export const selectOptions = createSelector(creativeReportOptionsSelector, (options) => options);
export const selectOptionsData = createSelector(creativeReportOptionsDataSelector, (data) => data);
export const selectOptionsError = createSelector(creativeReportOptionsErrorSelector, (error) => error);
export const selectOptionsIsFetching = createSelector(creativeReportOptionsFetchingSelector, (isFetching) => isFetching);

export const selectList = createSelector(creativeReportListSelector, (list) => list);
export const selectListDataColumnsSort = createSelector(creativeReportListDataSelector, (data) => data?.columns.filter((el: any) => el.sort === true));
const columnsFloat = [
  'poker_rake_sum',
  'poker_bonus',
  'ngr_poker',
  'poker_revenue_share_profit',
  'pkm_casino_bet_sum',
  'pkm_casino_ggr',
  'pkm_casino_bonus',
  'ngr_pkm_casino',
  'pkm_casino_revenue_share_profit',
];
export const selectListData = createSelector(creativeReportListDataSelector, (data) => {
  if (!!data) {
    const columns = data.columns.reduce((acc: any[], item: any) => {
      if (item.name === 'creative_group') {
        acc.push({
          name: "creative_id",
          sort: true,
          type: "string",
        });
        acc.push({
          name: "creative_name",
          sort: true,
          type: "shortString",
        });
      } else if (item.name === 'tracker_group') {
        acc.push({
          name: "tracker_id",
          sort: true,
          type: "string",
        });
        acc.push({
          name: "tracker_description",
          sort: true,
          type: "shortString",
        });
      } else if (item.name === 'affiliate_group') {
        acc.push({
          name: "affiliate_id",
          sort: true,
          type: "string",
        });
        acc.push({
          name: "affiliate_name",
          sort: true,
          type: "shortUsername",
        });
      } else if (item.name === 'date_for_report') {
        acc.push({ ...item, type: 'datetime' });
      } else if (item.name === 'chief_name') {
        acc.push({ ...item, type: 'shortString' });
      } else if (item.name === 'landing_url') {
        acc.push({ ...item, type: 'link' });
      } else if (columnsFloat.includes(item.name)) {
        acc.push({ ...item, type: 'float' });
      } else if ((item.name === "anid")) {
        acc.push({ ...item, type: 'mediumString' });
      } else {
        acc.push({ ...item })
      }
      return acc;
    }, []);

    return { ...data, columns: columns };
  } else {
    return data;
  }
});
export const selectListError = createSelector(creativeReportListErrorSelector, (error) => error);
export const selectListIsFetching = createSelector(creativeReportListFetchingSelector, (isFetching) => isFetching);

export const selectDownload = createSelector(creativeReportDownloadSelector, (data) => data);
export const selectDownloadData = createSelector(creativeReportDownloadDataSelector, (data) => data);
export const selectDownloadError = createSelector(creativeReportDownloadErrorSelector, (error) => error);
export const selectDownloadIsFetching = createSelector(creativeReportDownloadFetchingSelector, (isFetching) => isFetching);

export const selectAffiliates = createSelector(creativeReportAffiliatesSelector, (data) => data);
export const selectAffiliatesData = createSelector(creativeReportAffiliatesDataSelector, (data) => data);
export const selectAffiliatesError = createSelector(creativeReportAffiliatesErrorSelector, (error) => error);
export const selectAffiliatesIsFetching = createSelector(creativeReportAffiliatesFetchingSelector, (isFetching) => isFetching);

export const selectTrackers = createSelector(creativeReportTrackersSelector, (data) => data);
export const selectTrackersData = createSelector(creativeReportTrackersDataSelector, (data) => data);
export const selectTrackersError = createSelector(creativeReportTrackersErrorSelector, (error) => error);
export const selectTrackersIsFetching = createSelector(creativeReportTrackersFetchingSelector, (isFetching) => isFetching);

export const selectDeals = createSelector(creativeReportDealsSelector, (data) => data);
export const selectDealsData = createSelector(creativeReportDealsDataSelector, (data) => data);
export const selectDealsError = createSelector(creativeReportDealsErrorSelector, (error) => error);
export const selectDealsIsFetching = createSelector(creativeReportDealsFetchingSelector, (isFetching) => isFetching);

export const selectCreatives = createSelector(creativeReportCreativesSelector, (data) => data);
export const selectCreativesData = createSelector(creativeReportCreativesDataSelector, (data) => data);
export const selectCreativesError = createSelector(creativeReportCreativesErrorSelector, (error) => error);
export const selectCreativesIsFetching = createSelector(creativeReportCreativesFetchingSelector, (isFetching) => isFetching);

export const selectColumnsData = createSelector(creativeReportColumnsDataSelector, (data) => data);
export const selectColumnsError = createSelector(creativeReportColumnsErrorSelector, (error) => error);
export const selectColumnsIsFetching = createSelector(creativeReportColumnsFetchingSelector, (isFetching) => isFetching);
