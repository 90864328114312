import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

interface IPaymentDescriptionProps {
  data: any;
}

const PaymentDescription: React.FC<IPaymentDescriptionProps> = ({
  data,
}): JSX.Element => {
  const { t } = useTranslation();
  const valueFields = Object.keys(data.payment_value);

  return (
    <>
      <Typography variant="body2">
        {`${t("common.components.payment.history.description.p1")} `}
        <strong>{data.affiliate_username}</strong>
        {` ${t("common.components.payment.history.description.p2")} ${t(`common.components.payment.history.action.${data.action}`)} ${t("common.components.payment.history.description.p3")} `}
        <strong>{data.modified_by}</strong>
        {`. ${t("common.components.payment.history.description.p4")}: `}
        <strong>{t(`common.components.payment.payment_method_options.${data.payment_method}`)}</strong>
        {data.action !== "deleted" && (
          <>
            {`. ${t("common.components.payment.history.description.p5")} `}
            {valueFields.map((item, i) => {
              return (
                <strong key={`payment-description-${i}`}>
                  {`${t(`common.components.payment.form.${item}`)}: ${!!data.payment_value[item] ? data.payment_value[item] : ' -'}`}
                  {(i !== (valueFields.length - 1)) && ', '}
                </strong>
              )
            })}
          </>
        )}
        {`.`}
      </Typography>
    </>
  );
};

export default PaymentDescription;
