import {AnyAction, Middleware} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {
  setType,
  setError,
  setPayload,
} from "../common/protectedAPI";

const protectedErrors: string[] = [
  'user.two_factor.password_or_auth_code_required',
  'user.two_factor.auth_code_required',
  'user.two_factor.auth_code_already_sent',
];

export const protectedMiddleware: Middleware<{}, RootState> = storeAPI => next => (action: AnyAction) => {
  /*if (!!action.payload) {
    console.log('action: ', action);
  }*/

  const authorized = storeAPI.getState().user?.user?.authorized || false;

  if (authorized) {
    if (!action.type.includes('protectedAPI')) {
      if (!!action.payload && action.payload.hasOwnProperty('error')) {
        const errorMessage = action.payload.error;

        if (protectedErrors.includes(errorMessage)) {
          const type = action.type.replace('/rejected', '');
          const error = action.payload;
          const payload = action.meta.arg;

          //You could also dispatch an error handling action or show a notification here
          storeAPI.dispatch(setType(type));
          storeAPI.dispatch(setError(error));
          storeAPI.dispatch(setPayload(payload));
        }
      }
    }
  }

  return next(action);
};
