//core
import React from "react";
import {useTranslation} from "react-i18next";
import {alpha, styled} from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import {IconButton, InputAdornment, SelectChangeEvent} from '@mui/material';
import CSelect from "../../../../UI/Fields/CSelect";

const Block = styled('div')(() => ({
  width: '100%',
  maxWidth: '594px',
  '@media (max-width: 767px)': {
    maxWidth: '100%',
  }
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.secondary.dark, 0.08),
  '&:hover': {
    backgroundColor: alpha(theme.palette.secondary.dark, 0.1),
  },
  marginRight: 0,
  marginLeft: 0,
  height: 40,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: '100%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.secondary.light,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(0.5, 1, 0.5, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: '36px',
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
}));

const SelectWrapper = styled('div')(({theme}) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '195px',
  padding: '0 9px 0 0',
  '@media (max-width: 767px)': {
    maxWidth: '130px',
  },
  '&:after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    right: 0,
    top: '50%',
    width: 1,
    height: 30,
    backgroundColor: theme.palette.divider,
    transform: 'translateY(-50%)',
  },
  'fieldset': {
    borderColor: 'transparent!important'
  }
}));

const SearchWrapper = styled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
}));

export type OptionsItem = {
  id: string,
  label: string,
}

interface IPrimarySearch {
  hideSearch?: boolean;
  selected: string;
  options: OptionsItem[] | [];
  value: string;
  name: string;
  placeholder?: string;
  prefix?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  onChangeSelect: (event: SelectChangeEvent) => void,
}

const CategorySearch = (props: IPrimarySearch) => {
  const { t } = useTranslation();
  const {
    hideSearch = false,
    selected,
    options,
    value,
    name,
    prefix,
    placeholder = t("common.forms.fields.search"),
    onChange,
    onClear,
    onChangeSelect,
  } = props;

  return (
    <Block>
      { !hideSearch && (
        <>
          <Search>
            { !!options.length && (
              <>
                <SelectWrapper>
                  <CSelect
                    name="search-filter"
                    value={selected}
                    options={options}
                    onChange={onChangeSelect}
                    {...(prefix ? {prefix: prefix} : {})}
                  />
                </SelectWrapper>
              </>
            )}
            <SearchWrapper>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                value={value}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                inputProps={{
                  'aria-label': 'search'
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="clear"
                      onClick={onClear}
                      onMouseDown={onClear}
                      edge="end"
                      sx={{ marginRight: '2px' }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </SearchWrapper>
          </Search>
        </>
      )}
    </Block>
  );
};

export default CategorySearch;
