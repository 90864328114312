import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  button: {
    padding: '4px',
    margin: '0 4px',
    '&.disabled': {
      '& > svg': {
        color: 'inherit',
      }
    },
  },
  buttonWhite: {
    background: 'transparent',
  },
});
