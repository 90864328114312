//core
import React, { Dispatch, SetStateAction, useEffect, useState, useRef } from "react";
import arePropsEqual from "react-fast-compare";
import { alpha } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useDebounce } from "usehooks-ts";
import { Scrollbars } from "react-custom-scrollbars";
import {
  Paper,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TextField,
  useMediaQuery, Box,
} from "@mui/material";
import { LabelDisplayedRowsArgs } from "@mui/material/TablePagination/TablePagination";

//theme
import {Theme} from "@mui/material/styles/createTheme";
import {useTheme} from "@mui/styles";

//styles
import { useCustomScroll, useStyles } from "./styles";

//components
import TableHeadColumns from "./components/TableHeadColumns";
import TableRows from "./components/TableRows";
import { OrderType } from "../../affiliate/Trackers";
import EmptyTable from "./components/EmptyTable";

export type itemListData = {
  [key: string]: any;
}
export type itemColumnsData = {
  [key: string]: any;
}

const exceptThisSymbols = ["e", "E", "+", "-", ".", ","];

interface ITableData {
  list: itemListData[];
  columns: itemColumnsData[];
  totalRow?: itemColumnsData,
  order: OrderType;
  setOrder: Dispatch<SetStateAction<OrderType>> | Dispatch<OrderType>;
  page: number;
  rowsPerPage: number;
  totalEntries: number;
  onPageChange: (event: unknown, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  ActionsComponent?: any;
  actionsCount: number;
  disabled?: boolean;
  translationColumnsPrefix: string;
  translationTooltipsPrefix?: string;
  stickyHeader?: boolean;
  hideRowPerPage?: boolean;
  freezeColumns?: string[];
  tooltipRow?: boolean;
  dataTooltipRow?: string[];
  tooltipExceptions?: string[];
  startRowNum?: number;
  endRowNum?: number;
  detailModalTitle?: string;
  showDetailOnMobile?: boolean;
  hidePagination?: boolean;
  hideTotal?: boolean;
  elevation?: number;
  columnCurrency?: any;
}

const TableData = (props: ITableData) => {
  const theme: Theme = useTheme();
  const styles = useStyles();
  const scrollStyles = useCustomScroll();
  const { t } = useTranslation();
  const mobile = useMediaQuery('(max-width:767px)');
  const inputRef: any = useRef(null);

  const {
    list,
    columns,
    totalRow,
    order,
    page,
    rowsPerPage,
    totalEntries,
    setOrder,
    onPageChange,
    onRowsPerPageChange,
    ActionsComponent,
    actionsCount,
    disabled = false,
    translationColumnsPrefix,
    translationTooltipsPrefix = '',
    stickyHeader = false,
    hideRowPerPage = false,
    freezeColumns,
    tooltipRow = false,
    dataTooltipRow,
    tooltipExceptions = [],
    startRowNum,
    endRowNum,
    detailModalTitle,
    showDetailOnMobile = false,
    hidePagination = false,
    hideTotal = false,
    elevation = 2,
    columnCurrency = null,
  } = props;

  const [currentPage, setCurrentPage] = useState(page+1);
  const [inputError, setInputError] = useState<boolean>(false);
  const [initialOrder, setInitialOrder] = useState(order);

  const debouncedPage = useDebounce<number>(currentPage, 700);

  useEffect(() => {
    setCurrentPage(page+1);
    if (page > 0 || page <= Math.ceil(totalEntries/rowsPerPage)) {
      setInputError(false)
    }
  }, [page]);

  useEffect(() => {
    const currPage = page + 1;
    const newPage = Number(debouncedPage);
    const lastPage = Math.ceil(totalEntries/rowsPerPage);

    if (currPage !== newPage && newPage >= 0 && newPage <= lastPage) {
      if (newPage <= 0) {
        onPageChange(null, 0);
      } else {
        onPageChange(null, newPage - 1);
      }
    }
  }, [debouncedPage]);

  const handleChangePage = (event: any) => {
    const maxPages = Math.ceil(totalEntries/rowsPerPage);
    const value = +event.target.value;

    if (value < 0 || value > maxPages) {
      setInputError(true);
    } else {
      setInputError(false);
    }

    if (value >= maxPages) {
      setCurrentPage(value)
    } else if (value < 1) {
      setCurrentPage(1)
    } else {
      setCurrentPage(value)
    }
    //setCurrentPage(value)
    //inputRef.current.blur();
  }

  const handleOnKeyDown = (e: any) => {
    if (exceptThisSymbols.includes(e.key)) {
      e.preventDefault()
    }
    if (e.key === 'Enter') {
      inputRef.current.blur();
      handleChangePage(e);
    }
  };

  const handleOnFocus = () => {
    inputRef.current.select();
  };

  const defaultLabelDisplayedRows = (args: LabelDisplayedRowsArgs) => {
    const totalPages = Math.ceil(totalEntries/rowsPerPage);
    return (
      <>
        {!!totalEntries && <>
          <span className={styles.page}>
            <Box className={styles.page_row}>
              <span>{t("common.components.table.page")}</span>
              <span className={styles.field}>
                <TextField
                    size="small"
                    type="number"
                    value={currentPage}
                    disabled={totalPages === 1}
                    inputRef={inputRef}
                    className={styles.input}
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      min: 1,
                      max: Math.ceil(totalEntries/rowsPerPage),
                    }}
                    error={inputError}
                    onChange={(e) => handleChangePage(e)}
                    onKeyDown={e => handleOnKeyDown(e)}
                    onFocus={handleOnFocus}
                />
              </span>
              <span>{`${t("common.components.table.of")} ${totalPages}`}</span>
            </Box>
            {!!startRowNum && !!endRowNum && (<div className={styles.total_items}>
              <span>{`${startRowNum} — ${endRowNum} ${t("common.components.table.from")} ${totalEntries}`}</span>
            </div>)}
          </span>
        </>}
      </>
    );
  };

  return (
    <>
      <Paper className={styles.paper} elevation={elevation}>
        {!!list.length && !!columns.length && (
          <Scrollbars
            className={scrollStyles.customScroll}
            //autoHide={true}
            hideTracksWhenNotNeeded={true}
            autoHeight={true}
            autoHeightMin={100}
            autoHeightMax={stickyHeader ? !mobile ? 658 : 'calc(100vh - 140px)' : 'inherit'}
            renderView={props => <div {...props} className="view"/>}
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
          >
            <TableContainer
              className={`${styles.tableContainer} ${stickyHeader ? !mobile ? styles.stickyTableContainer : styles.stickyTableContainerMobile : ''}`}
            >
              <Table
                stickyHeader={stickyHeader}
                className={styles.table}
                sx={{ color: disabled ? alpha(theme.palette.text.primary, 0.5) : theme.palette.text.primary }}
              >
                <TableHead
                  className={styles.tableHead}
                  sx={{
                    backgroundColor: disabled
                      ? alpha(theme.palette.secondary.dark, 0.04)
                      : alpha(theme.palette.secondary.dark, 0.09)
                  }}
                >
                  <TableRow>
                    <TableHeadColumns
                      disabled={disabled}
                      columns={columns}
                      order={order}
                      initialOrder={initialOrder}
                      setOrder={setOrder}
                      actions={!!ActionsComponent}
                      actionsCount={actionsCount}
                      translationColumnsPrefix={translationColumnsPrefix}
                      freezeColumns={freezeColumns}
                      translationTooltipsPrefix={translationTooltipsPrefix}
                      columnCurrency={columnCurrency}
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRows
                    columns={columns}
                    list={list}
                    totalRow={totalRow}
                    ActionsComponent={ActionsComponent}
                    freezeColumns={freezeColumns}
                    tooltipRow={tooltipRow}
                    dataTooltipRow={dataTooltipRow}
                    tooltipExceptions={tooltipExceptions}
                    translationColumnsPrefix={translationColumnsPrefix}
                    disabled={disabled}
                    detailModalTitle={detailModalTitle}
                    showDetailOnMobile={showDetailOnMobile}
                    actionsCount={actionsCount}
                    translationTooltipsPrefix={translationTooltipsPrefix}
                    hideTotal={hideTotal}
                    columnCurrency={columnCurrency}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbars>
        )}
        {!list.length && <EmptyTable />}
        {!hidePagination && !!totalEntries && <>
          <TablePagination
            className={styles.tablePagination}
            rowsPerPageOptions={hideRowPerPage ? [] : [10, 20, 50, 100]}
            component="div"
            count={totalEntries}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            labelRowsPerPage={`${t("common.components.table.rows_label")}:`}
            labelDisplayedRows={defaultLabelDisplayedRows}
            showFirstButton
            showLastButton
          />
        </>}
      </Paper>
    </>
  );
};

export default React.memo(TableData, arePropsEqual);
