//core
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";
import {setUser} from "../../common/user";
import { employeesService } from "../../../services/admin/Employees.service";
import {
  setAddAdjustmentState,
  setGeneralProgressHide,
  setGeneralProgressShow,
  setGeneralSnackbarState
} from "../../common/ui";

export type ListItemData =  {
  id: number;
  name: string;
  platform_id: number;
  email: string;
  role_id: number;
  role_name: string;
  department_id: number;
  department_name: string;
  contact_type: string;
  contact_value: string;
  created_at: string;
  last_sign_in_at: string;
  last_sign_in_ip: string | null;
  banned: boolean;
  invitation_sent_at: string;
  invitation_accepted_at: string;
  platform_name: string | null;
  [key: string]: any;
};

export type ColumnsItemData = {
  name: string;
  type: string;
  sort: boolean;
  match?: boolean;
};

export type EmployeesListData = {
  list: ListItemData[];
  columns_for_display_data: ColumnsItemData[];
  start_row_num: number;
  end_row_num: number;
  total_entries: number | string;
  total_pages: number;
  locale_key: string;
  [key: string]: any;
};

export type employeesSliceState = {
  list: {
    data: EmployeesListData | null,
    error: any,
    isFetching: boolean,
  },
  list_roles: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  roles: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  list_departments: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  add: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  remove: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  invite: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  block: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  edit: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
};

const initialState: employeesSliceState = {
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  list_roles: {
    data: null,
    error: null,
    isFetching: false,
  },
  roles: {
    data: null,
    error: null,
    isFetching: false,
  },
  list_departments: {
    data: null,
    error: null,
    isFetching: false,
  },
  add: {
    data: null,
    error: null,
    isFetching: false,
  },
  remove: {
    data: null,
    error: null,
    isFetching: false,
  },
  invite: {
    data: null,
    error: null,
    isFetching: false,
  },
  block: {
    data: null,
    error: null,
    isFetching: false,
  },
  edit: {
    data: null,
    error: null,
    isFetching: false,
  },
};

export const getListData: any = createAsyncThunk(
  'employees/getListData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await employeesService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


export const getListRolesData: any = createAsyncThunk(
  'employees/getListRolesData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await employeesService.getListRoles(payload);
      const data = await response.json();

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }
      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getFilterRolesData: any = createAsyncThunk(
  'employees/getFilterRolesData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await employeesService.getRoles(payload);
      const data = await response.json();

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }
      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


export const getListDepartmetsData: any = createAsyncThunk(
  'employees/getListDepartmetsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await employeesService.getListDepartments(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


export const addAdminData: any = createAsyncThunk(
  'employees/addAdminData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await employeesService.addNewAdmin(payload);
      const data = await response.json();

      if (response.ok && response.status === 201) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setAddAdjustmentState(false));
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


export const removeAdminData: any = createAsyncThunk(
  'employees/removeAdminData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await employeesService.removeAdmin(payload);
      const data = await response.json();

      if (response.ok && response.status === 201) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setAddAdjustmentState(false));
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: data.error,
          })
        );
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


export const inviteAdminData: any = createAsyncThunk(
  'employees/inviteAdminData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await employeesService.inviteAdmin(payload);
      const data = await response.json();

      if (response.ok && response.status === 201) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setAddAdjustmentState(false));
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


export const blockAdminData: any = createAsyncThunk(
  'employees/blockAdminData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await employeesService.blockAdmin(payload);
      const data = await response.json();

      if (response.ok && response.status === 201) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setAddAdjustmentState(false));
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


export const editAdminData: any = createAsyncThunk(
  'employees/editAdminData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await employeesService.editAdmin(payload);
      const data = await response.json();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setAddAdjustmentState(false));
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

//slice
const employeesSlice = createSlice({
  name: 'employees',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    clearListState(state) {
      state.list.data = null;
      state.list.error = null;
      state.list.isFetching = false;
    },
    clearAddAdminState(state) {
      state.add.data = null;
      state.add.error = null;
      state.add.isFetching = false;
    },
    clearEditAdminState(state) {
      state.edit.data = null;
      state.edit.error = null;
      state.edit.isFetching = false;
    },
    clearFilterRolesState(state) {
      state.roles.data = null;
      state.roles.error = null;
      state.roles.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<employeesSliceState>>) => {
    builder.addCase(getListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getListData.fulfilled, (state , action) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getListData.rejected, (state , action) => {
      state.list.isFetching = false;
      state.list.error = action.payload;
    });

    builder.addCase(getListRolesData.pending, (state ) => {
      state.list_roles.isFetching = true;
      state.list_roles.error = null;
    });
    builder.addCase(getListRolesData.fulfilled, (state , action) => {
      state.list_roles.error = null;
      state.list_roles.isFetching = false;
      state.list_roles.data = action.payload;
    });
    builder.addCase(getListRolesData.rejected, (state , action) => {
      state.list_roles.isFetching = false;
      state.list_roles.error = action.payload;
    });

    builder.addCase(getFilterRolesData.pending, (state ) => {
      state.roles.isFetching = true;
      state.roles.error = null;
    });
    builder.addCase(getFilterRolesData.fulfilled, (state , action) => {
      state.roles.error = null;
      state.roles.isFetching = false;
      state.roles.data = action.payload;
    });
    builder.addCase(getFilterRolesData.rejected, (state , action) => {
      state.roles.isFetching = false;
      state.roles.error = action.payload;
    });

    builder.addCase(getListDepartmetsData.pending, (state ) => {
      state.list_departments.isFetching = true;
      state.list_departments.error = null;
    });
    builder.addCase(getListDepartmetsData.fulfilled, (state , action) => {
      state.list_departments.error = null;
      state.list_departments.isFetching = false;
      state.list_departments.data = action.payload;
    });
    builder.addCase(getListDepartmetsData.rejected, (state , action) => {
      state.list_departments.isFetching = false;
      state.list_departments.error = action.payload;
    });

    builder.addCase(addAdminData.pending, (state ) => {
      state.add.isFetching = true;
      state.add.error = null;
    });
    builder.addCase(addAdminData.fulfilled, (state , action) => {
      state.add.error = null;
      state.add.isFetching = false;
      state.add.data = action.payload;
    });
    builder.addCase(addAdminData.rejected, (state , action) => {
      state.add.isFetching = false;
      state.add.error = action.payload;
    });

    builder.addCase(removeAdminData.pending, (state ) => {
      state.remove.isFetching = true;
      state.remove.error = null;
    });
    builder.addCase(removeAdminData.fulfilled, (state , action) => {
      state.remove.error = null;
      state.remove.isFetching = false;
      state.remove.data = action.payload;
    });
    builder.addCase(removeAdminData.rejected, (state , action) => {
      state.remove.isFetching = false;
      state.remove.error = action.payload;
    });

    builder.addCase(inviteAdminData.pending, (state ) => {
      state.invite.isFetching = true;
      state.invite.error = null;
    });
    builder.addCase(inviteAdminData.fulfilled, (state , action) => {
      state.invite.error = null;
      state.invite.isFetching = false;
      state.invite.data = action.payload;
    });
    builder.addCase(inviteAdminData.rejected, (state , action) => {
      state.invite.isFetching = false;
      state.invite.error = action.payload;
    });

    builder.addCase(blockAdminData.pending, (state ) => {
      state.block.isFetching = true;
      state.block.error = null;
    });
    builder.addCase(blockAdminData.fulfilled, (state , action) => {
      state.block.error = null;
      state.block.isFetching = false;
      state.block.data = action.payload;
    });
    builder.addCase(blockAdminData.rejected, (state , action) => {
      state.block.isFetching = false;
      state.block.error = action.payload;
    });

    builder.addCase(editAdminData.pending, (state ) => {
      state.edit.isFetching = true;
      state.edit.error = null;
    });
    builder.addCase(editAdminData.fulfilled, (state , action) => {
      state.edit.error = null;
      state.edit.isFetching = false;
      state.edit.data = action.payload;
    });
    builder.addCase(editAdminData.rejected, (state , action) => {
      state.edit.isFetching = false;
      state.edit.error = action.payload;
    });
  },
});

export default employeesSlice.reducer;

export const {
  clearListState,
  reset,
  clearAddAdminState,
  clearEditAdminState,
  clearFilterRolesState,
} = employeesSlice.actions;
