import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import { cookieToken } from "../../constants";
import { payloadTrackersList } from "../../store/admin/admTrackers";

const getTrackers = (data: payloadTrackersList) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.affProgramTrackers.list;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  }

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

export const trackersService = {
  getTrackers,
};
