//core
import React from "react";
import { useTranslation } from "react-i18next";
import {Box, Button, Typography} from "@mui/material";

//styles
import { useStyles } from "./styles";

//hooks
import { useEmployees } from "../../../../../store/admin/employees/useEmployees";

interface IBlockAdminProps {
  data: any;
  onClose: (data: boolean) => void;
}

const BlockAdmin: React.FC<IBlockAdminProps> = ({data, onClose}): JSX.Element => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { blockAdmin } = useEmployees();

  const handleBlockAdmin = ( ) => {
    const payload = {
      admin_id: data.id,
    }

    blockAdmin(payload);
    onClose(false);
  }

  return (
    <Box className={styles.block}>
      {
        data.banned
          ? <Typography sx={{ mb: 3 }} variant="h6">{t("admin.employees.unblock_admin_title")}</Typography>
          : <Typography sx={{ mb: 3 }} variant="h6">{t("admin.employees.block_admin_title")}</Typography>
      }
      {
        data.banned
          ? <Typography variant="body2">
              {t("admin.employees.unblock_admin_text")}<Box sx={{ wordWrap: 'break-word' }} component="span">{data.name}</Box>?
            </Typography>
          : <Typography variant="body2">
              {t("admin.employees.block_admin_text")}<Box sx={{ wordWrap: 'break-word' }} component="span">{data.name}</Box>?
            </Typography>
      }
      <Box className={styles.btn__wrapper}>
        <Box className={styles.btn__block}>
          <Button
            variant="contained"
            color="secondary"
            className={styles.btn__approve}
            onClick={handleBlockAdmin}
          >
            {
              data.banned
                ? t("common.buttons.unblock")
                : t("common.buttons.block")
            }
          </Button>
        </Box>
        <Box className={styles.btn__block}>
          <Button
            variant="outlined"
            color="secondary"
            className={styles.btn}
            onClick={() => {onClose(false)}}
          >
            {t("common.buttons.cancel")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default BlockAdmin;
