//core
import { createSelector } from "@reduxjs/toolkit";

//store
import { RootState } from "../../index";

const creativeCreateDataSelector = (state: RootState) => state.admin.creatives_v2.create.data;

const creativesListSelector = (state: RootState) => state.admin.creatives_v2.list;
const creativesListDataSelector = (state: RootState) => state.admin.creatives_v2.list.data;
const creativesListErrorSelector = (state: RootState) => state.admin.creatives_v2.list.error;
const creativesListFetchingSelector = (state: RootState) => state.admin.creatives_v2.list.isFetching;

const creativesFilterSelector = (state: RootState) => state.admin.creatives_v2.filter;
const creativesFilterDataSelector = (state: RootState) => state.admin.creatives_v2.filter.data;
const creativesFilterErrorSelector = (state: RootState) => state.admin.creatives_v2.filter.error;
const creativesFilterFetchingSelector = (state: RootState) => state.admin.creatives_v2.filter.isFetching;

const creativesDeleteDataSelector = (state: RootState) => state.admin.creatives_v2.delete.data;

const creativesToggleHideDataSelector = (state: RootState) => state.admin.creatives_v2.toggleHide.data;
const creativesToggleHideErrorSelector = (state: RootState) => state.admin.creatives_v2.toggleHide.error;
const creativesToggleHideFetchingSelector = (state: RootState) => state.admin.creatives_v2.toggleHide.isFetching;

const creativeCopyDataSelector = (state: RootState) => state.admin.creatives_v2.copy.data;

const creativesBrandsListDataSelector = (state: RootState) => state.admin.creatives_v2.brands.data;
const creativesBrandsListErrorSelector = (state: RootState) => state.admin.creatives_v2.brands.error;
const creativesBrandsListFetchingSelector = (state: RootState) => state.admin.creatives_v2.brands.isFetching;

const creativesRulesBrandsListDataSelector = (state: RootState) => state.admin.creatives_v2.rulesBrands.data;
const creativesRulesBrandsListErrorSelector = (state: RootState) => state.admin.creatives_v2.rulesBrands.error;
const creativesRulesBrandsListFetchingSelector = (state: RootState) => state.admin.creatives_v2.rulesBrands.isFetching;

const creativesCreateDataSelector = (state: RootState) => state.admin.creatives_v2.create.data;
const creativesCreateErrorSelector = (state: RootState) => state.admin.creatives_v2.create.error;
const creativesCreateFetchingSelector = (state: RootState) => state.admin.creatives_v2.create.isFetching;

const creativesEditDataSelector = (state: RootState) => state.admin.creatives_v2.edit.data;
const creativesEditErrorSelector = (state: RootState) => state.admin.creatives_v2.edit.error;
const creativesEditFetchingSelector = (state: RootState) => state.admin.creatives_v2.edit.isFetching;

const creativesUpdateDataSelector = (state: RootState) => state.admin.creatives_v2.update.data;
const creativesUpdateErrorSelector = (state: RootState) => state.admin.creatives_v2.update.error;
const creativesUpdateFetchingSelector = (state: RootState) => state.admin.creatives_v2.update.isFetching;

const creativesDetailsDataSelector = (state: RootState) => state.admin.creatives_v2.details.data;
const creativesDetailsDataErrorSelector = (state: RootState) => state.admin.creatives_v2.details.error;

const creativesFormDataSelector = (state: RootState) => state.admin.creatives;

const creativesMirrorBrandsDataSelector = (state: RootState) => state.admin.creatives_v2.mirrors.data;

const creativesCreateFormDataSelector = (state: RootState) => state.admin.creatives_v2.form.data;
const creativesCreateFormDataErrorSelector = (state: RootState) => state.admin.creatives_v2.form.error;

const creativesOptionsDataSelector = (state: RootState) => state.admin.creatives_v2.creativesOptions.data;
const creativesOptionsErrorSelector = (state: RootState) => state.admin.creatives_v2.creativesOptions.error;
const creativesOptionsFetchingSelector = (state: RootState) => state.admin.creatives_v2.creativesOptions.isFetching;

export const selectCreativeCreateData = createSelector(creativeCreateDataSelector, (data) => data);

export const selectCreativesList = createSelector(creativesListSelector, (list) => list);
export const selectCreativesListData = createSelector(creativesListDataSelector, (data) => {
  if (!!data) {
    const columns = data.columns.map((item: any) => {
      if (item.name === 'geo') {
        return { ...item, type: 'countriesArray'};
      }
      if (item.name === 'brands') {
        return { ...item, type: 'brandsArray'};
      }
      if (item.name === 'offerId') {
        return { ...item, type: 'text'};
      }
      if (item.name === 'name') {
        return { ...item, type: 'mediumString'};
      }
      if (item.name === 'hidden') {
        return { ...item, type: 'toggleCreativeHideFieldV2'};
      }
      if (item.name === 'format') {
        return { ...item, type: 'creative_type'};
      }

      return item;
    });

    return { ...data, columns: columns };
  } else {
    return data;
  }
});
export const selectCreativesListDataColumnsSort = createSelector(creativesListDataSelector, (data) => data?.columns.filter((el) => el.sort === true));
export const selectCreativesListError = createSelector(creativesListErrorSelector, (error) => error);
export const selectCreativesListIsFetching = createSelector(creativesListFetchingSelector, (isFetching) => isFetching);

export const selectCreativesFilter = createSelector(creativesFilterSelector, (filter) => filter);
export const selectCreativesFilterData = createSelector(creativesFilterDataSelector, (data) => data);
export const selectCreativesFilterError = createSelector(creativesFilterErrorSelector, (error) => error);
export const selectCreativesFilterIsFetching = createSelector(creativesFilterFetchingSelector, (isFetching) => isFetching);

export const selectDeleteCreativesData = createSelector(creativesDeleteDataSelector, (data) => data);

export const selectCreativesToggleHideData = createSelector(creativesToggleHideDataSelector, (data) => data);
export const selectCreativesToggleHideError = createSelector(creativesToggleHideErrorSelector, (error) => error);
export const selectCreativesToggleHideIsFetching = createSelector(creativesToggleHideFetchingSelector, (isFetching) => isFetching);

export const selectCreativesBrandsListData = createSelector(creativesBrandsListDataSelector, (data) => {
  if (!!data) {
    const list = data.list.map((item: {id: any, value: string}) => {
      return {
        id: item.id,
        value: `${item.id}-${item.value}`
      }
    });

    return {
      ...data,
      list: list,
    }
  } else {
    return data
  }
});
export const selectCreativesBrandsListError = createSelector(creativesBrandsListErrorSelector, (error) => error);
export const selectCreativesBrandsListIsFetching = createSelector(creativesBrandsListFetchingSelector, (isFetching) => isFetching);

export const selectCreativesRulesBrandsListData = createSelector(creativesRulesBrandsListDataSelector, (data) => {
  if (!!data) {
    const list = data.list.map((item: {id: any, value: string}) => {
      return {
        id: item.id,
        value: `${item.id}-${item.value}`
      }
    });

    return {
      ...data,
      list: list,
    }
  } else {
    return data
  }
});
export const selectCreativesRulesBrandsListError = createSelector(creativesRulesBrandsListErrorSelector, (error) => error);
export const selectCreativesRulesBrandsListIsFetching = createSelector(creativesRulesBrandsListFetchingSelector, (isFetching) => isFetching);

export const selectCreativeCopyData = createSelector(creativeCopyDataSelector, (data) => data);

export const selectCreativesCreateData = createSelector(creativesCreateDataSelector, (data) => data);
export const selectCreativesCreateError = createSelector(creativesCreateErrorSelector, (error) => error);
export const selectCreativesCreateIsFetching = createSelector(creativesCreateFetchingSelector, (isFetching) => isFetching);

export const selectCreativesEditData = createSelector(creativesEditDataSelector, (data) => data);
export const selectCreativesEditError = createSelector(creativesEditErrorSelector, (error) => error);
export const selectCreativesEditIsFetching = createSelector(creativesEditFetchingSelector, (isFetching) => isFetching);

export const selectCreativesUpdateData = createSelector(creativesUpdateDataSelector, (data) => data);
export const selectCreativesUpdateError = createSelector(creativesUpdateErrorSelector, (error) => error);
export const selectCreativesUpdateIsFetching = createSelector(creativesUpdateFetchingSelector, (isFetching) => isFetching);

export const selectCreativesDetailsData = createSelector(creativesDetailsDataSelector, (data) => {
  if (!!data) {
    const columns = data.columns.map((item: any) => {
      if (item.name === 'geo') {
        return { ...item, type: 'geoArray'};
      }
      if (item.name === 'brands') {
        return { ...item, type: 'brandsArray'};
      }
      if (item.name === 'offerId') {
        return { ...item, type: 'text'};
      }

      return item;
    });

    return { ...data, columns: columns };
  } else {
    return data;
  }
});
export const selectCreativesDetailsError = createSelector(creativesDetailsDataErrorSelector, (error) => error);


export const selectCreativesFormatData = createSelector(creativesFormDataSelector, (data) => {
  if(!!data.form.data) {
    return {
      ...data.form.data.format,
      options: data.form.data.format.options.filter(option => option.id === 'link' || option.id === 'link_with_mirror')
    }} else {
    return data;
  }
});

export const selectCreativesMirrorBrandsData = createSelector(creativesMirrorBrandsDataSelector, (data) => data);

export const selectCreativesCreateFormData = createSelector(creativesCreateFormDataSelector, (data) => data);
export const selectCreativesCreateFormError = createSelector(creativesCreateFormDataErrorSelector, (error) => error);

export const selectCreativesOptionsData = createSelector(creativesOptionsDataSelector, (data) => data);
export const selectCreativesOptionsError = createSelector(creativesOptionsErrorSelector, (error) => error);
export const selectCreativesOptionsIsFetching = createSelector(creativesOptionsFetchingSelector, (isFetching) => isFetching);
