import {api} from "../../api";
import {handleResponse, RequestOptions} from "../types";
import {getCookie} from "../../helpers/utils";
import {cookieSupervision, cookieToken} from "../../constants";

const getOptions = () => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.creative_report.options;

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getAffiliates = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.creative_report.affiliates;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getTrackers = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.creative_report.trackers;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getDeals = () => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.creative_report.deals;

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getCreatives = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.creative_report.creatives;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getReport = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.creative_report.generate_report;

  const sendData = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(sendData))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const setDownload = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.creative_report.download_report;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getColumns = () => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.creative_report.columns;

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

export const creativeReportService = {
  getOptions,
  getReport,
  getColumns,
  setDownload,
  getAffiliates,
  getTrackers,
  getDeals,
  getCreatives,
};
