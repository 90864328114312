//core
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";
import {setUser} from "../../../common/user";
import { webhooksReportService } from "../../../../services/admin/webhooksReport.service";
import { setGeneralProgressHide, setGeneralProgressShow } from "../../../common/ui";

type OptionsDataType = {
  group_by: {
    date: string[];
    affiliate: {
      [key: string]: string;
    },
    affiliate_second_level: {
      [key: string]: string;
    },
    row_on_page: number[];
  },
  separators_for_format: {
    csv: string[];
  },
  encoding_types: string[];
}

type ListItem = {
  id: number;
  value: string;
}

type filterDataType = {
  list: ListItem[];
  label?: string;
}

export type webhooksReportSliceState = {
  options: {
    data: OptionsDataType | null,
    error: any,
    isFetching: boolean,
  },
  list: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  columns: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  download: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  filters: {
    data: {
      affiliates: filterDataType | null;
      statuses: filterDataType | null;
      trackers: filterDataType | null;
      types: filterDataType | null;
      webhooks: filterDataType | null;
    },
    error: any,
    isFetching: boolean,
  },
  details_webhook: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
};

const initialState: webhooksReportSliceState = {
  options: {
    data: null,
    error: null,
    isFetching: false,
  },
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  columns: {
    data: null,
    error: null,
    isFetching: false,
  },
  download: {
    data: null,
    error: null,
    isFetching: false,
  },
  filters: {
    data: {
      affiliates: null,
      statuses: null,
      trackers: null,
      types: null,
      webhooks: null,
    },
    error: null,
    isFetching: false,
  },
  details_webhook: {
    data: null,
    error: null,
    isFetching: false,
  },
};

export const getOptionsData: any = createAsyncThunk(
  'webhooks_report/getOptionsData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await webhooksReportService.getOptions();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getListData: any = createAsyncThunk(
  'webhooks_report/getListData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await webhooksReportService.getReport(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getColumnsData: any = createAsyncThunk(
  'webhooks_report/getColumnsData',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await webhooksReportService.getColumns();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const setDownloadData: any = createAsyncThunk(
  'webhooks_report/setDownloadData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await webhooksReportService.setDownload(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


export const getFiltersData: any = createAsyncThunk(
  'webhooks_report/getFiltersData',
  async (payload:any, { rejectWithValue,  dispatch}) => {
    try {
      const response = await webhooksReportService.getFilters(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return {
        ...data,
        label: payload.label,
      };
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);


export const getDetailsWebhookData: any = createAsyncThunk(
  'webhooks_report/getDetailsWebhookData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await webhooksReportService.getDetailsReport(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


//slice
const webhooksReportSlice = createSlice({
  name: 'webhooks_report',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    clearListState(state) {
      state.list.data = null;
      state.list.error = null;
      state.list.isFetching = false;
    },
    clearDownload(state) {
      state.download.data = null;
      state.download.error = null;
      state.download.isFetching = false;
    },
    clearDetailsWebhook(state) {
      state.details_webhook.data = null;
      state.details_webhook.error = null;
      state.details_webhook.isFetching = false;
    },
    clearColumnsState(state) {
      state.columns.data = null;
      state.columns.error = null;
      state.columns.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<webhooksReportSliceState>>) => {
    builder.addCase(getOptionsData.pending, (state ) => {
      state.options.isFetching = true;
      state.options.error = null;
    });
    builder.addCase(getOptionsData.fulfilled, (state , action) => {
      state.options.error = null;
      state.options.isFetching = false;
      state.options.data = action.payload;
    });
    builder.addCase(getOptionsData.rejected, (state , action) => {
      state.options.isFetching = false;
      state.options.error = action.payload;
    });

    builder.addCase(getListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getListData.fulfilled, (state , action) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getListData.rejected, (state , action) => {
      state.list.isFetching = false;
      state.list.error = action.payload;
    });

    builder.addCase(getColumnsData.pending, (state ) => {
      state.columns.isFetching = true;
      state.columns.error = null;
    });
    builder.addCase(getColumnsData.fulfilled, (state , action) => {
      state.columns.error = null;
      state.columns.isFetching = false;
      state.columns.data = action.payload;
    });
    builder.addCase(getColumnsData.rejected, (state , action) => {
      state.columns.isFetching = false;
      state.columns.error = action.payload;
    });

    builder.addCase(setDownloadData.pending, (state ) => {
      state.download.isFetching = true;
      state.download.error = null;
    });
    builder.addCase(setDownloadData.fulfilled, (state , action) => {
      state.download.error = null;
      state.download.isFetching = false;
      state.download.data = action.payload;
    });
    builder.addCase(setDownloadData.rejected, (state , action) => {
      state.download.isFetching = false;
      state.download.error = action.payload;
    });

    builder.addCase(getFiltersData.pending, (state ) => {
      state.filters.isFetching = true;
      state.filters.error = null;
    });
    builder.addCase(getFiltersData.fulfilled, (state, action) => {
      state.filters.error = null;
      state.filters.isFetching = false;
      //@ts-ignore
      state.filters.data[action.payload.label] = action.payload
    });
    builder.addCase(getFiltersData.rejected, (state, action ) => {
      state.filters.error = action.payload;
    });

    builder.addCase(getDetailsWebhookData.pending, (state ) => {
      state.details_webhook.isFetching = true;
      state.details_webhook.error = null;
    });
    builder.addCase(getDetailsWebhookData.fulfilled, (state , action) => {
      state.details_webhook.error = null;
      state.details_webhook.isFetching = false;
      state.details_webhook.data = action.payload;
    });
    builder.addCase(getDetailsWebhookData.rejected, (state , action) => {
      state.details_webhook.isFetching = false;
      state.details_webhook.error = action.payload;
    });
  },
});

export default webhooksReportSlice.reducer;

export const {
  clearDownload,
  clearListState,
  clearColumnsState,
  reset,
  clearDetailsWebhook,
} = webhooksReportSlice.actions;
