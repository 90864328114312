import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import { cookieToken } from "../../constants";
import {
  IDeleteEmailTplPayloadType,
  IEditPayloadType,
  ISaveEmailTplPayloadType,
  ISendTestEmailPayloadType
} from "../../store/admin/emailTemplates";

const getList = () => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.mailers_manager.list;

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getEdit = (data: IEditPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.mailers_manager.edit;

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}&platform_id=${data.platform_id}&id_mailer=${data.id_mailer}&locale=${data.locale}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const saveEmailTemplate = (data: ISaveEmailTplPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.mailers_manager.save;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const sendTestEmail = (data: ISendTestEmailPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.mailers_manager.send_test;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const deleteEmailTemplate = (data: IDeleteEmailTplPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.mailers_manager.delete;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('DELETE', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

export const emailTemplatesService = {
  getList,
  getEdit,
  saveEmailTemplate,
  sendTestEmail,
  deleteEmailTemplate,
}
