//core
import React, { useEffect } from "react";
import { useFormikContext, Field } from "formik";
import {useSelector} from "react-redux";
import useDidMountEffect from "../../../../../../../customHooks/useDidMountEffect";
import {useEmployees} from "../../../../../../../store/admin/employees/useEmployees";

interface IHelperFieldProps  {
  name: string;
  value: any;
}

const HelperFieldPlatform = (props: IHelperFieldProps) => {
  const {
    setFieldValue,
  } = useFormikContext();
  const { getListRoles, getListDepartments} = useEmployees();

  const {
    value,
    name,
  } = props;

  useDidMountEffect(() => {
    if (!!value) {
      getListRoles({
        platform_id: value
      });
      getListDepartments({
        platform_id: value
      });
      setFieldValue('role', '');
    }
  }, [value, setFieldValue]);

  return (
    <>
      <Field name={name} hidden />
    </>
  );
};

export default HelperFieldPlatform;
