//core
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Chip,
  IconButton
} from "@mui/material";

//styles
import { useStyles } from "./styles";

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { PeriodTypes } from "../FormFields/PeriodDatesField/utils";

interface IItemData {
  id: PeriodTypes;
  label: PeriodTypes;
}

interface IDateShortcutsListProps {
  limit: number; // default 3
  data: IItemData[];
  period: PeriodTypes;
  setPeriod: (data: PeriodTypes) => void;
}

const DateShortcutsList: React.FC<IDateShortcutsListProps> = ({
  limit= 3,
  data,
  period,
  setPeriod,
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();

  const [showAll, setShowAll] = useState(false);

  const handleToggleShowAll = () => {
    setShowAll(prevState => !prevState);
  };

  const handleClickChip = (value: PeriodTypes) => {
    setPeriod(value);
  }

  const list = showAll ? data.map((item) => (
    <Chip
      key={item.id}
      size="small"
      variant={item.label === period ? 'filled' : 'outlined'}
      label={t(`common.components.periods.${item.label}`)}
      onClick={() => handleClickChip(item.label)}
      sx={{
        ...(item.label === period ? {
          border: '1px solid transparent'
        } : {}),
      }}
    />
  )) : data.slice(0, limit).map((item) => (
    <Chip
      key={item.id}
      size="small"
      variant={item.label === period ? 'filled' : 'outlined'}
      label={t(`common.components.periods.${item.label}`)}
      onClick={() => handleClickChip(item.label)}
      sx={{
        ...(item.label === period ? {
          border: '1px solid transparent'
        } : {}),
      }}
    />
  ));

  return (
    <Box className={styles.list}>
      {!!data.length ? (
        <>
          {list}

          {data.length > limit && (
            <IconButton
              className={styles.btn}
              size="small"
              aria-label="show-all"
              onClick={handleToggleShowAll}
            >
              {showAll ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </>
      ) : '—'}
    </Box>
  );
};

export default DateShortcutsList;
