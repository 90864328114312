import { makeStyles } from "@mui/styles";
import {Theme} from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  preview: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 104,
    maxWidth: 104,
    height: 78,
    backgroundColor: theme.palette.secondary.main,
    margin: '0 0',
  },
  img: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  emptyBg: {
    backgroundColor: theme.palette.grey['500'],
  },
  icon: {
    color: theme.palette.action.active
  },
  chip: {
    width: 'auto',
    minWidth: 110,
    position: "absolute",
    left: -16,
    top: 4,
    zIndex: 2,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  }
}));

