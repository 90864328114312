import { makeStyles } from "@mui/styles";
//import { alpha } from "@mui/material/styles";
import { Theme } from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  block: {
    padding: '0 0 8px',
  },
  checkbox: {
    padding: '0 0 0 11px',
  },
  form: {
    padding: '16px 0 0',
  },
  group: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: 16,
  },
  field: {
    flex: '1 1 auto',
    '> div': {
      marginBottom: 0,
    }
  },
  actions: {

  },
  button: {

  }
}));
