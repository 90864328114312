import {stateConfiguration} from "./useConfiguration";
import {createSelector} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";

//actions
import {
  setLanguage
} from "./index";

const languageDataSelector = (state: stateConfiguration) => state.configuration.language.data;

const selectLanguageData = createSelector(
  languageDataSelector,
  (data) => data
);

export const useLanguage = () => {
  const dispatch = useDispatch();

  const language = useSelector(selectLanguageData);

  const setLanguageData = (data: string) => dispatch(setLanguage(data));

  return {
    language,
    setLanguageData,
  }
}
