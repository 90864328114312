//core
import React, {useState, useEffect} from "react";
import arePropsEqual from "react-fast-compare";
import { Switch } from "@mui/material";

//actions
import {useCreatives} from "../../../../../store/admin/сreatives/useCreatives";
import {useSelector} from "react-redux";
import {
  selectCreativesToggleHideError
} from "../../../../../store/admin/сreatives/selectors";

export type itemListData = {
  [key: string]: any;
}

interface ISwitcherProps {
  value: boolean;
  creative: itemListData;
}

const HideSwitcher: React.FC<ISwitcherProps> = ({ value, creative }): JSX.Element => {
  const { updateCreativeHideField } = useCreatives();

  const [checked, setChecked] = useState<boolean>(value);

  const error = useSelector(selectCreativesToggleHideError);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateCreativeHideField({
      hidden: event.target.checked,
      creative_id: creative.id,
    });

    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (!!error) {
      if (error.creative_id === creative.id) {
        setChecked(!checked);
      }
    }
  }, [error]);


  return (
    <Switch
      size="small"
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
};

export default React.memo(HideSwitcher, arePropsEqual);
