//core
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { setUser } from "../../../common/user";
import { downloadReportService } from "../../../../services/admin/downloadReport.service";
import { setGeneralProgressHide, setGeneralProgressShow } from "../../../common/ui";

export type DownloadReportListItem = {
  id: number | string;
  report_file_name: string;
  report_type: string;
  aasm_state: string;
  created_at: string;
}

export type DownloadReportColumnsItem = {
  type: string;
  name: string;
  sort: boolean;
  match: boolean;
  order_number: number;
}

export type DownloadReportListType = {
  list: DownloadReportListItem[],
  columns_for_display_data: DownloadReportColumnsItem[];
  start_row_num: number;
  end_row_num: number;
  locale_key: string;
  total_entries: number | string;
  total_pages: number;
}

export type DownloadReportSliceState = {
  list: {
    data: DownloadReportListType | null;
    error: any;
    isFetching: boolean;
  },
  download: {
    data: any | null;
    error: any;
    isFetching: boolean;
  },
  delete: {
    data: any | null;
    error: any;
    isFetching: boolean;
  },
};


const initialState: DownloadReportSliceState = {
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  download: {
    data: null,
    error: null,
    isFetching: false,
  },
  delete: {
    data: null,
    error: null,
    isFetching: false,
  },
};


export const getListData: any = createAsyncThunk(
  'adm_download_report/getListData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await downloadReportService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getDownloadData: any = createAsyncThunk(
  'adm_download_report/getDownloadData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await downloadReportService.getDownload(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const deleteReportData: any = createAsyncThunk(
  'adm_download_report/deleteReportData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await downloadReportService.deleteReport(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


//slice
const adminDownloadReportSlice = createSlice({
  name: 'adm_download_report',
  initialState: initialState,
  reducers: {
    clearListData(state, action) {
      state.list.data = action.payload;
      state.list.isFetching = false;
      state.list.error = null;
    },
    clearDownloadData(state, action) {
      state.download.data = action.payload;
      state.download.isFetching = false;
      state.download.error = null;
    },
    clearDeleteData(state, action) {
      state.delete.data = action.payload;
      state.delete.isFetching = false;
      state.delete.error = null;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<DownloadReportSliceState>>) => {
    builder.addCase(getListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getListData.fulfilled, (state , action) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getListData.rejected, (state , action) => {
      state.list.isFetching = false;
      state.list.error = action.payload;
    });

    builder.addCase(getDownloadData.pending, (state ) => {
      state.download.isFetching = true;
      state.download.error = null;
    });
    builder.addCase(getDownloadData.fulfilled, (state , action) => {
      state.download.error = null;
      state.download.isFetching = false;
      state.download.data = action.payload;
    });
    builder.addCase(getDownloadData.rejected, (state , action) => {
      state.download.isFetching = false;
      state.download.error = action.payload;
    });

    builder.addCase(deleteReportData.pending, (state ) => {
      state.delete.isFetching = true;
      state.delete.error = null;
    });
    builder.addCase(deleteReportData.fulfilled, (state , action) => {
      state.delete.error = null;
      state.delete.isFetching = false;
      state.delete.data = action.payload;
    });
    builder.addCase(deleteReportData.rejected, (state , action) => {
      state.delete.isFetching = false;
      state.delete.error = action.payload;
    });
  },
});

export default adminDownloadReportSlice.reducer;

export const {
  clearListData,
  clearDownloadData,
  clearDeleteData,
} = adminDownloadReportSlice.actions;
