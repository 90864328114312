//core
import React from "react";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

interface IBoolean {
  value: boolean;
}

const useStyles = makeStyles({
  text: {
    color: 'inherit',
  },
  whiteSpace: {
    whiteSpace: 'nowrap',
  }
});

const Boolean = ({ value }: IBoolean) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <span
      className={styles.text}
    >
      {value ? "True" : 'False'}
    </span>
  );
};

export default Boolean;
