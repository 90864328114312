//core
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { rolesAndPermissionsService } from "../../../services/admin/rolesAndPermissions.service";
import { setGeneralProgressHide, setGeneralProgressShow, setGeneralSnackbarState } from "../../common/ui";
import { setUser } from "../../common/user";



export type rolesAndPermissionsSliceState = {
  namespaces: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  roles: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  createRole: {
    data: any | null,
    error: any,
    isFetching: boolean,
    success: boolean,
  },
  deleteRole: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  updateRoleName: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  reportFields: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  addReportFields: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  removeReportFields: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  productAccess: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  addProductAccess: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  removeProductAccess: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  permissionsTree: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  savePermissions: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  platforms: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  platformsRole: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  addPlatformForRole: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  removePlatformForRole: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
}

const initialState: rolesAndPermissionsSliceState = {
  namespaces: {
    data: null,
    error: null,
    isFetching: false,
  },
  roles: {
    data: null,
    error: null,
    isFetching: false,
  },
  createRole: {
    data: null,
    error: null,
    isFetching: false,
    success: false,
  },
  deleteRole: {
    data: null,
    error: null,
    isFetching: false,
  },
  updateRoleName: {
    data: null,
    error: null,
    isFetching: false,
  },
  reportFields: {
    data: null,
    error: null,
    isFetching: false,
  },
  addReportFields: {
    data: null,
    error: null,
    isFetching: false,
  },
  removeReportFields: {
    data: null,
    error: null,
    isFetching: false,
  },
  productAccess: {
    data: null,
    error: null,
    isFetching: false,
  },
  addProductAccess: {
    data: null,
    error: null,
    isFetching: false,
  },
  removeProductAccess: {
    data: null,
    error: null,
    isFetching: false,
  },
  permissionsTree: {
    data: null,
    error: null,
    isFetching: false,
  },
  savePermissions: {
    data: null,
    error: null,
    isFetching: false,
  },
  platforms: {
    data: null,
    error: null,
    isFetching: false,
  },
  platformsRole: {
    data: null,
    error: null,
    isFetching: false,
  },
  addPlatformForRole: {
    data: null,
    error: null,
    isFetching: false,
  },
  removePlatformForRole: {
    data: null,
    error: null,
    isFetching: false,
  },
}

export const getNamespacesData: any = createAsyncThunk(
  'rolesAndPermissions/getNamespacesData',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await rolesAndPermissionsService.getNamespaces();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getRolesData: any = createAsyncThunk(
  'rolesAndPermissions/getRolesData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await rolesAndPermissionsService.getRoles(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const createRoleData: any = createAsyncThunk(
  'rolesAndPermissions/createRoleData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await rolesAndPermissionsService.createRole(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const deleteRoleData: any = createAsyncThunk(
  'rolesAndPermissions/deleteRoleData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await rolesAndPermissionsService.deleteRole(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const updateRoleNameData: any = createAsyncThunk(
  'rolesAndPermissions/updateRoleNameData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await rolesAndPermissionsService.updateRoleName(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const getReportFieldsData: any = createAsyncThunk(
  'rolesAndPermissions/getReportFieldsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await rolesAndPermissionsService.getReportFields(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const addReportFieldsData: any = createAsyncThunk(
  'rolesAndPermissions/addReportFieldsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await rolesAndPermissionsService.addReportFields(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const removeReportFieldsData: any = createAsyncThunk(
  'rolesAndPermissions/removeReportFieldsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await rolesAndPermissionsService.removeReportFields(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getProductAccessData: any = createAsyncThunk(
  'rolesAndPermissions/getProductAccessData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await rolesAndPermissionsService.getProductAccess(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const addProductAccessData: any = createAsyncThunk(
  'rolesAndPermissions/addProductAccessData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await rolesAndPermissionsService.addProductAccess(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const removeProductAccessData: any = createAsyncThunk(
  'rolesAndPermissions/removeProductAccessData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await rolesAndPermissionsService.removeProductAccess(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getPermissionsTreeData: any = createAsyncThunk(
  'rolesAndPermissions/getPermissionsTreeData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await rolesAndPermissionsService.getPermissionsTree(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const savePermissionsData: any = createAsyncThunk(
  'rolesAndPermissions/savePermissionsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await rolesAndPermissionsService.savePermissions(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return {
        data: data,
        permission: payload.permission,
      };
    } catch (error) {
      dispatch(setGeneralProgressHide());
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

export const getPlatformsData: any = createAsyncThunk(
  'rolesAndPermissions/getPlatformsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await rolesAndPermissionsService.getPlatforms(payload);
      const data = await response.json();
      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getPlatformForRoleData: any = createAsyncThunk(
  'rolesAndPermissions/getPlatformForRoleData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await rolesAndPermissionsService.getPlatformForRole(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const addPlatformForRoleData: any = createAsyncThunk(
  'rolesAndPermissions/addPlatformForRoleData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await rolesAndPermissionsService.addPlatformForRole(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const removePlatformForRoleData: any = createAsyncThunk(
  'rolesAndPermissions/removePlatformForRoleData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await rolesAndPermissionsService.removePlatformForRole(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

//slice
const rolesAndPermissionsSlice = createSlice({
  name: 'rolesAndPermissions',
  initialState: initialState,
  reducers: {
    clearNamespacesState(state) {
      state.namespaces.data = null;
      state.namespaces.error = null;
      state.namespaces.isFetching = false;
    },
    clearRolesState(state) {
      state.roles.data = null;
      state.roles.error = null;
      state.roles.isFetching = false;
    },
    setRolesDataState(state, {payload}) {
      state.roles.data = payload;
    },
    setCreateRoleSuccess(state, { payload }) {
      state.createRole.success = payload;
    },
    clearCreateRoleState(state) {
      state.createRole.data = null;
      state.createRole.error = null;
      state.createRole.isFetching = false;
    },
    clearDeleteRoleState(state) {
      state.deleteRole.data = null;
      state.deleteRole.error = null;
      state.deleteRole.isFetching = false;
    },
    clearUpdateRoleNameState(state) {
      state.updateRoleName.data = null;
      state.updateRoleName.error = null;
      state.updateRoleName.isFetching = false;
    },
    clearReportFieldsState(state) {
      state.reportFields.data = null;
      state.reportFields.error = null;
      state.reportFields.isFetching = false;
    },
    clearProductAccessState(state) {
      state.productAccess.data = null;
      state.productAccess.error = null;
      state.productAccess.isFetching = false;
    },
    clearPermissionsTreeState(state) {
      state.permissionsTree.data = null;
      state.permissionsTree.error = null;
      state.permissionsTree.isFetching = false;
    },
    clearSavePermissionsState(state) {
      state.savePermissions.data = null;
      state.savePermissions.error = null;
      state.savePermissions.isFetching = false;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<rolesAndPermissionsSliceState>>) => {
    builder.addCase(getNamespacesData.pending, (state ) => {
      state.namespaces.isFetching = true;
      state.namespaces.error = null;
    });
    builder.addCase(getNamespacesData.fulfilled, (state , action) => {
      state.namespaces.error = null;
      state.namespaces.isFetching = false;
      state.namespaces.data = action.payload;
    });
    builder.addCase(getNamespacesData.rejected, (state , action) => {
      state.namespaces.isFetching = false;
      state.namespaces.error = action.payload;
    });

    builder.addCase(getRolesData.pending, (state ) => {
      state.roles.isFetching = true;
      state.roles.error = null;
    });
    builder.addCase(getRolesData.fulfilled, (state , action) => {
      state.roles.error = null;
      state.roles.isFetching = false;
      state.roles.data = action.payload;
    });
    builder.addCase(getRolesData.rejected, (state , action) => {
      state.roles.isFetching = false;
      state.roles.error = action.payload;
    });

    builder.addCase(createRoleData.pending, (state ) => {
      state.createRole.isFetching = true;
      state.createRole.error = null;
    });
    builder.addCase(createRoleData.fulfilled, (state , action) => {
      state.createRole.error = null;
      state.createRole.isFetching = false;
      state.createRole.success = true;
      state.createRole.data = action.payload;
    });
    builder.addCase(createRoleData.rejected, (state , action) => {
      state.createRole.isFetching = false;
      state.createRole.success = false;
      state.createRole.error = action.payload;
    });

    builder.addCase(deleteRoleData.pending, (state ) => {
      state.deleteRole.isFetching = true;
      state.deleteRole.error = null;
    });
    builder.addCase(deleteRoleData.fulfilled, (state , action) => {
      state.deleteRole.error = null;
      state.deleteRole.isFetching = false;
      state.deleteRole.data = action.payload;
    });
    builder.addCase(deleteRoleData.rejected, (state , action) => {
      state.deleteRole.isFetching = false;
      state.deleteRole.error = action.payload;
    });

    builder.addCase(updateRoleNameData.pending, (state ) => {
      state.updateRoleName.isFetching = true;
      state.updateRoleName.error = null;
    });
    builder.addCase(updateRoleNameData.fulfilled, (state , action) => {
      state.updateRoleName.error = null;
      state.updateRoleName.isFetching = false;
      state.updateRoleName.data = action.payload;
    });
    builder.addCase(updateRoleNameData.rejected, (state , action) => {
      state.updateRoleName.isFetching = false;
      state.updateRoleName.error = action.payload;
    });

    builder.addCase(getReportFieldsData.pending, (state ) => {
      state.reportFields.isFetching = true;
      state.reportFields.error = null;
    });
    builder.addCase(getReportFieldsData.fulfilled, (state , action) => {
      state.reportFields.error = null;
      state.reportFields.isFetching = false;
      state.reportFields.data = action.payload;
    });
    builder.addCase(getReportFieldsData.rejected, (state , action) => {
      state.reportFields.isFetching = false;
      state.reportFields.error = action.payload;
    });

    builder.addCase(addReportFieldsData.pending, (state ) => {
      state.addReportFields.isFetching = true;
      state.addReportFields.error = null;
    });
    builder.addCase(addReportFieldsData.fulfilled, (state , action) => {
      state.addReportFields.error = null;
      state.addReportFields.isFetching = false;
      state.addReportFields.data = action.payload;
    });
    builder.addCase(addReportFieldsData.rejected, (state , action) => {
      state.addReportFields.isFetching = false;
      state.addReportFields.error = action.payload;
    });

    builder.addCase(removeReportFieldsData.pending, (state ) => {
      state.removeReportFields.isFetching = true;
      state.removeReportFields.error = null;
    });
    builder.addCase(removeReportFieldsData.fulfilled, (state , action) => {
      state.removeReportFields.error = null;
      state.removeReportFields.isFetching = false;
      state.removeReportFields.data = action.payload;
    });
    builder.addCase(removeReportFieldsData.rejected, (state , action) => {
      state.removeReportFields.isFetching = false;
      state.removeReportFields.error = action.payload;
    });

    builder.addCase(getProductAccessData.pending, (state ) => {
      state.productAccess.isFetching = true;
      state.productAccess.error = null;
    });
    builder.addCase(getProductAccessData.fulfilled, (state , action) => {
      state.productAccess.error = null;
      state.productAccess.isFetching = false;
      state.productAccess.data = action.payload;
    });
    builder.addCase(getProductAccessData.rejected, (state , action) => {
      state.productAccess.isFetching = false;
      state.productAccess.error = action.payload;
    });

    builder.addCase(addProductAccessData.pending, (state ) => {
      state.addProductAccess.isFetching = true;
      state.addProductAccess.error = null;
    });
    builder.addCase(addProductAccessData.fulfilled, (state , action) => {
      state.addProductAccess.error = null;
      state.addProductAccess.isFetching = false;
      state.addProductAccess.data = action.payload;
    });
    builder.addCase(addProductAccessData.rejected, (state , action) => {
      state.addProductAccess.isFetching = false;
      state.addProductAccess.error = action.payload;
    });

    builder.addCase(removeProductAccessData.pending, (state ) => {
      state.removeProductAccess.isFetching = true;
      state.removeProductAccess.error = null;
    });
    builder.addCase(removeProductAccessData.fulfilled, (state , action) => {
      state.removeProductAccess.error = null;
      state.removeProductAccess.isFetching = false;
      state.removeProductAccess.data = action.payload;
    });
    builder.addCase(removeProductAccessData.rejected, (state , action) => {
      state.removeProductAccess.isFetching = false;
      state.removeProductAccess.error = action.payload;
    });

    builder.addCase(getPermissionsTreeData.pending, (state ) => {
      state.permissionsTree.isFetching = true;
      state.permissionsTree.error = null;
    });
    builder.addCase(getPermissionsTreeData.fulfilled, (state , action) => {
      state.permissionsTree.error = null;
      state.permissionsTree.isFetching = false;
      state.permissionsTree.data = action.payload;
    });
    builder.addCase(getPermissionsTreeData.rejected, (state , action) => {
      state.permissionsTree.isFetching = false;
      state.permissionsTree.error = action.payload;
    });

    builder.addCase(savePermissionsData.pending, (state ) => {
      state.savePermissions.isFetching = true;
      state.savePermissions.error = null;
    });
    builder.addCase(savePermissionsData.fulfilled, (state , action) => {
      state.savePermissions.error = null;
      state.savePermissions.isFetching = false;
      state.savePermissions.data = action.payload;
    });
    builder.addCase(savePermissionsData.rejected, (state , action) => {
      state.savePermissions.isFetching = false;
      state.savePermissions.error = action.payload;
    });

    builder.addCase(getPlatformsData.pending, (state ) => {
      state.platforms.isFetching = true;
      state.platforms.error = null;
    });
    builder.addCase(getPlatformsData.fulfilled, (state , action) => {
      state.platforms.error = null;
      state.platforms.isFetching = false;
      state.platforms.data = action.payload;
    });
    builder.addCase(getPlatformsData.rejected, (state , action) => {
      state.platforms.isFetching = false;
      state.platforms.error = action.payload;
    });

    builder.addCase(getPlatformForRoleData.pending, (state ) => {
      state.platformsRole.isFetching = true;
      state.platformsRole.error = null;
    });
    builder.addCase(getPlatformForRoleData.fulfilled, (state , action) => {
      state.platformsRole.error = null;
      state.platformsRole.isFetching = false;
      state.platformsRole.data = action.payload;
    });
    builder.addCase(getPlatformForRoleData.rejected, (state , action) => {
      state.platformsRole.isFetching = false;
      state.platformsRole.error = action.payload;
    });

    builder.addCase(addPlatformForRoleData.pending, (state ) => {
      state.addPlatformForRole.isFetching = true;
      state.addPlatformForRole.error = null;
    });
    builder.addCase(addPlatformForRoleData.fulfilled, (state , action) => {
      state.addPlatformForRole.error = null;
      state.addPlatformForRole.isFetching = false;
      state.addPlatformForRole.data = action.payload;
    });
    builder.addCase(addPlatformForRoleData.rejected, (state , action) => {
      state.addPlatformForRole.isFetching = false;
      state.addPlatformForRole.error = action.payload;
    });

    builder.addCase(removePlatformForRoleData.pending, (state ) => {
      state.removePlatformForRole.isFetching = true;
      state.removePlatformForRole.error = null;
    });
    builder.addCase(removePlatformForRoleData.fulfilled, (state , action) => {
      state.removePlatformForRole.error = null;
      state.removePlatformForRole.isFetching = false;
      state.removePlatformForRole.data = action.payload;
    });
    builder.addCase(removePlatformForRoleData.rejected, (state , action) => {
      state.removePlatformForRole.isFetching = false;
      state.removePlatformForRole.error = action.payload;
    });
  },
});

export default rolesAndPermissionsSlice.reducer;

export const {
  clearNamespacesState,
  clearRolesState,
  setRolesDataState,
  clearCreateRoleState,
  setCreateRoleSuccess,
  clearDeleteRoleState,
  clearUpdateRoleNameState,
  clearReportFieldsState,
  clearProductAccessState,
  clearPermissionsTreeState,
  clearSavePermissionsState,
} = rolesAndPermissionsSlice.actions;
