//core
import React, { useRef } from "react";
import {makeStyles, useTheme} from "@mui/styles";
import { Box, Tooltip } from "@mui/material";

//hooks
import useDimensions from "../../../../../customHooks/useDimensions";

//icons
import {
  TelegramIcon,
  SkypeIcon,
} from "../../../../UI/Icons";
import {Theme} from "@mui/material/styles/createTheme";

interface IContact {
  type: string;
  value: string;
}

const Contact: React.FC<IContact> = ({ type, value }): JSX.Element => {
  const theme: Theme = useTheme();
  const useStyles = makeStyles({
    block: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 4,
      color: 'inherit',
    },
    text: {
      whiteSpace: 'nowrap',
      color: 'inherit',
      '& a': {
        color: 'var(--text-primary)',
        maxWidth: 140,
        overflow: 'hidden',
        display: 'block',
        textOverflow: 'ellipsis',
      },
      '& span': {
        maxWidth: 160,
        overflow: 'hidden',
        display: 'block',
        textOverflow: 'ellipsis',
      },
    },
    icon: {
      width: 16,
      height: 16,
      color: theme.palette.action.active,
      '& svg': {
        width: 16,
        height: 16,
      }
    },
    wrapper: {
      width: "max-content",
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  });
  const styles = useStyles();
  const wrapperRef: any = useRef(null);
  const size = useDimensions(wrapperRef);

  const icon = type === 'telegram'
                ? <TelegramIcon width={16} height={16} />
                : type === 'skype'
                  ? <SkypeIcon width={16} height={16} />
                  : '';

  return (
    <>
      <Box ref={wrapperRef} className={styles.wrapper}
           sx={{ opacity: 0, visibility: "hidden", position: "absolute" }}
      >
        {value ? value : '—'}
      </Box>
      <Box className={styles.block}>
        <span className={styles.text}>
          {type === 'telegram' && <a href={`https://t.me/${value}`} target="_blank">{ value ? value : '—' }</a>}
          {type === 'skype' && <a href={`skype:${value}?chat`}>{ value ? value : '—' }</a>}
          {type === 'other' && (
            <>
              {(size.width >= 160) ? (
                <Tooltip title={value ? value : '—'}>
                  <span>{value ? value : '—'}</span>
                </Tooltip>
              ) : (
                <span>{value ? value : '—'}</span>
              )}
            </>
          )}
        </span>
        <span className={styles.icon}>
          { icon }
        </span>
      </Box>
    </>
  );
};

export default Contact;
