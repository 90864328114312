import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    '@media (max-width: 1500px)': {},
  },
  icon: {
    width: 12,
    height: 12,
    margin: '0 -1px',
    ...(theme.palette.mode === 'light' ? {} : {
      color: theme.palette.text.secondary,
    }),
  },
  iconSuccess: {
    color: theme.palette.primary.main,
  },
  iconButton: {
    width: 24,
    height: 24,
    padding: 0
  },
}));
