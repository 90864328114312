import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import {
  ApprovePayloadType,
  DealsPayloadType,
  ListPayloadType,
  NotesPayloadType,
  ResendConfirmationPayloadType,
  OptionsPayloadType,
  AffDealsPayloadType,
} from "../../store/admin/partners";
import { cookieSupervision, cookieToken } from "../../constants";

const getFilters = () => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.partners.filters;

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getList = (data: ListPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.partners.list;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getDeals = (data: DealsPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.partners.deals;

  const payload = {
    ...data,
  };

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const setApproveAffiliate = (data: ApprovePayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.partners.approve.replace('{affiliateId}', data.affiliateId.toString());

  const payload = {
    ...data.data,
  };

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const setDeclineAffiliate = (data: NotesPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.partners.decline.replace('{affiliateId}', data.affiliateId.toString());

  const payload = {
    ...data.data,
  };

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const setBlockAffiliate = (data: NotesPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.partners.block.replace('{affiliateId}', data.affiliateId.toString());

  const payload = {
    ...data.data,
  };

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const setUnblockAffiliate = (data: NotesPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.partners.unblock.replace('{affiliateId}', data.affiliateId.toString());

  const payload = {
    ...data.data,
  };

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const setResendConfirmationEmail = (data: ResendConfirmationPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.partners.resend_confirmation.replace('{affiliateId}', data.affiliateId.toString());

  const payload = {
    authenticity_token: authenticity_token,
    ...data.data,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getAffiliates = (data: OptionsPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.partners.affiliates;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getChiefs = (data: OptionsPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.partners.chiefs;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};


const getChiefsAll = (data: OptionsPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.partners.chiefs_all;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getDepartments = (data: OptionsPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.partners.departments;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getPlatfroms = (data:any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.partners.platforms;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const createPartner = (data:any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.partners.create_partner;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getInvitationLinks = (data:any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.partners.invitation_links;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getInvitationManagers = (data:any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.partners.invitation_managers;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getAffDeals = (data: AffDealsPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.partners.aff_deals.replace('{affiliateId}', `${data.affiliateId}`);

  const payload = {
    ...data.payload,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

export const partnersService = {
  getFilters,
  getList,
  getDeals,
  setApproveAffiliate,
  setDeclineAffiliate,
  setBlockAffiliate,
  setUnblockAffiliate,
  setResendConfirmationEmail,
  getAffiliates,
  getChiefs,
  getDepartments,
  getChiefsAll,
  getPlatfroms,
  createPartner,
  getInvitationLinks,
  getInvitationManagers,
  getAffDeals,
}
