//core
import React, {useEffect, useState} from "react";
import { makeStyles } from "@mui/styles";
import {numberToLocaleFormat, numberToLocaleFormatInteger, strToNumberFormat} from "../../../../../helpers/utils";
import { Box } from "@mui/material";
import {useTranslation} from "react-i18next";

interface IValue {
  value: string | number;
  color?: string;
  column_name: string;
  item: any;
  percentageValue?: boolean;
}

const useStyles = makeStyles({
  text: {}
});

const BillingFormat = ({ value, color = '', item, percentageValue = false, column_name }: IValue) => {
  const styles = useStyles();
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState<string>('en');

  const brandValue = (!!value) && (value !== '∞') ?
    column_name === 'price_for_overlay'
      ? numberToLocaleFormat(value, locale) + ' $'
      : numberToLocaleFormatInteger(value, locale)
    : value;

  const ngrValue = (!!value) && (value !== '∞') ?
    column_name === 'price_for_overlay'
      ? percentageValue
        ? numberToLocaleFormatInteger(value, locale) + ' %'
        : numberToLocaleFormat(value, locale)
      : numberToLocaleFormat(value, locale)
    : value;

  const ggrValue = (!!value) && (value !== '∞') ?
    column_name === 'price_for_overlay'
      ? percentageValue
        ? numberToLocaleFormatInteger(value, locale) + ' %'
        : numberToLocaleFormat(value, locale)
      : numberToLocaleFormat(value, locale)
    : value;

  const clicksValue = (!!value) && (value !== '∞') ?
    column_name === 'price_for_overlay'
      ? numberToLocaleFormat(value, locale) + ' $'
      : numberToLocaleFormatInteger(value, locale)
    : value;

  const renderValue = item.package_key === 'brands'
    ? brandValue
    : item.package_key === 'ngr'
      ? ngrValue
      : item.package_key === 'ggr'
        ? ggrValue
        : item.package_key === 'clicks'
          ? clicksValue
          : value;

  const textColor = ((renderValue !== '0.00') && (renderValue !== '0,00') && (renderValue !== '0'))
    ? !!color
      ? color
      : 'inherit'
    : 'inherit';

  useEffect(() => {
    if (i18n.language === 'ru') {
      setLocale('ru');
    }

    if (i18n.language === 'en') {
      setLocale('en');
    }
  }, [i18n.language]);

  return (
    <Box component="span" className={styles.text} sx={{ color: textColor }}>
      { value === null ? '—' : renderValue}
    </Box>
  );
};

export default BillingFormat;
