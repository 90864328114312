import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import { cookieToken } from "../../constants";
import {
  payloadCloneConditions,
  payloadConditionsList,
} from "../../store/admin/conditions";

const getList = (data: payloadConditionsList) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.conditions.list;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  }

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const deleteCondition = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.conditions.delete.replace('{id}', `${data.id}`);

  return fetch(`${endpoint}?authenticity_token=${authenticity_token}`, {...RequestOptions('DELETE')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const cloneCondition = (data: payloadCloneConditions) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.conditions.clone.replace('{id}', `${data.id}`);

  const payload = {
    authenticity_token: authenticity_token,
  }

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getConstants = () => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.conditions.constants}?authenticity_token=${authenticity_token}`;

  return fetch(`${endpoint}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const createCondition = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.conditions.create;

  const payload = {
    authenticity_token: authenticity_token,
    condition: data,
  }

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getCondition = (id: string) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.conditions.condition
    .replace('{conditionId}', id)}?authenticity_token=${authenticity_token}`;

  return fetch(`${endpoint}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const editCondition = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.conditions.edit.replace('{conditionId}', `${data.id}`);

  const payload = {
    authenticity_token: authenticity_token,
    condition: data,
  }

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}



const getOffersId = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.conditions.offers_id;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  }

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getExternalCurrency = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.conditions.offer_currency.replace('{offerId}', `${data.id}`);

  return fetch(`${endpoint}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};


const archiveDeal = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = data.archive ? api.admin.conditions.unarchive.replace('{id}', `${data.id}`) : api.admin.conditions.archive.replace('{id}', `${data.id}`);

  const payload = {
    authenticity_token: authenticity_token,
  }

  return fetch(
      `${endpoint}`,
      {...RequestOptions('POST', JSON.stringify(payload))}
  )
      .then(handleResponse)
      .then(
          data => {
            return data;
          }
      )
      .catch((error) => {
        return error
      });
};

const renameDeal = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.conditions.rename.replace('{id}', `${data.id}`)

  const payload = {
    authenticity_token: authenticity_token,
    name: data.name,
  }

  return fetch(
      `${endpoint}`,
      {...RequestOptions('POST', JSON.stringify(payload))}
  )
      .then(handleResponse)
      .then(
          data => {
            return data;
          }
      )
      .catch((error) => {
        return error
      });
};

const getBrandsList = (data: payloadConditionsList) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.conditions.brands_list;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  }

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getFilterCreator = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.conditions.filter_creator;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  }

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

export const conditionsService = {
  getList,
  deleteCondition,
  cloneCondition,
  getConstants,
  createCondition,
  getCondition,
  editCondition,
  getOffersId,
  getExternalCurrency,
  archiveDeal,
  renameDeal,
  getBrandsList,
  getFilterCreator,
};
