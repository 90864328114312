import * as Yup from 'yup';

interface IErrors {
  required: string,
  maxLength: string,
}

function validationSchema(errors: IErrors, maxLength: number) {
  return Yup.object().shape({
    name: Yup.string()
      .min(1, errors.required)
      .max(maxLength, errors.maxLength.replace('{max}', `${maxLength}`))
      .required(errors.required),
  });
}

export const validationTextField = (val: any, maxLength: number, errors: IErrors) => {
  const value = { name: val };

  const schema = validationSchema(errors, maxLength);

  try {
    schema.validateSync(value, { abortEarly: false });
    return undefined;
  } catch (error: any) {
    return error.inner[0].errors[0];
  }
};
