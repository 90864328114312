import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles/createTheme";
import {alpha} from "@mui/material/styles";

export const useStyles = makeStyles<Theme>((theme) => ({
  field: {
    paddingTop: 8,
    paddingBottom: 8,
    '&.inline': {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: 'wrap',
      gap: 4,
      '& .label': {
        marginBottom: 0,
      },
      '& .content': {},
    },
  },
  fieldContent: {
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.palette.text.primary,
    '& strong': {
      fontSize: 14,
      lineHeight: '24px',
    }
  },
  label: {
    marginBottom: 4,
    '& label': {
      lineHeight: 1,
      fontSize: 12,
    },
  },
  link: {
    maxWidth: '100%',
    width: 'auto!important',
  },
  labelColor: {
    color: alpha(theme.palette.text.secondary, 0.6),
  },
}));
