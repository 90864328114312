import React from "react";
import { useTranslation } from "react-i18next";

//components
import DateTimeRange from "../../../../../../../../../../../common/TableData/components/DateTimeRange";

interface IPaymentDeletedProps {
  data: any;
}

const PaymentDeleted: React.FC<IPaymentDeletedProps> = ({
  data,
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      {t("admin.reports.detailed_fin_report.logs.actions.payment.deleted")}{' '}
      {t("admin.reports.detailed_fin_report.logs.actions.payment.date")}: {<DateTimeRange value={data.paymentDate} fullDate />},{' '}
      {t("admin.reports.detailed_fin_report.logs.actions.payment.amount")}: {data.amount},{' '}
      {t("admin.reports.detailed_fin_report.logs.actions.payment.comment")}: {!!data.comment ? data.comment : '-'}
    </>
  );
};

export default PaymentDeleted;
