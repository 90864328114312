import {stateConfiguration} from "./useConfiguration";
import {createSelector} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {useCallback, useMemo} from "react";
import {hasItemArrayInArray} from "../../../helpers/hasStringInArray";

const permissionDataSelector = (state: stateConfiguration) => state.configuration.permission.data;

export const selectPermissionData = createSelector(
  permissionDataSelector,
  (data) => data
);

export const usePermissions = () => {
  const permissionData = useSelector(selectPermissionData);

  const permissions = useMemo(() => {
    if (permissionData) {
      return permissionData.permissions
    } else {
      return null
    }
  }, [permissionData]);

  const hasPermissions = useCallback(
    (keys: string[]) => {
      return hasItemArrayInArray(keys, permissions)
    },
    [permissionData],
  );

  return {
    hasPermissions
  }
}
