//core
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";
import {setUser} from "../../../common/user";
import { newCreativeReportService } from "../../../../services/affiliate/newCreativeReport.service";
import {setGeneralProgressHide, setGeneralProgressShow} from "../../../common/ui";

interface IObj {
  [i:string]: string;
}

export type GroupByObj = {
  [k: string]: string | IObj
}

type OptionsDataType = {
  group_by: GroupByObj,
  row_on_page: number[],
  separators_for_format: {
    csv: string[];
  },
  encoding_types: string[];
}

export type CreativeReportSliceState = {
  options: {
    data: OptionsDataType | null,
    error: any,
    isFetching: boolean,
  },
  list: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  affiliates: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  trackers: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  deals: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  creatives: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  download: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
};

const initialState: CreativeReportSliceState = {
  options: {
    data: null,
    error: null,
    isFetching: false,
  },
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  affiliates: {
    data: null,
    error: null,
    isFetching: false,
  },
  trackers: {
    data: null,
    error: null,
    isFetching: false,
  },
  deals: {
    data: null,
    error: null,
    isFetching: false,
  },
  creatives: {
    data: null,
    error: null,
    isFetching: false,
  },
  download: {
    data: null,
    error: null,
    isFetching: false,
  },
};

export const getOptionsData: any = createAsyncThunk(
  'aff_creative_report/getOptionsData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await newCreativeReportService.getOptions();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getListData: any = createAsyncThunk(
  'aff_creative_report/getListData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await newCreativeReportService.getReport(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getTrackersData: any = createAsyncThunk(
  'aff_creative_report/getTrackersData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await newCreativeReportService.getTrackers(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getCreativesData: any = createAsyncThunk(
  'aff_creative_report/getCreativesData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await newCreativeReportService.getCreatives(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const setDownloadData: any = createAsyncThunk(
  'aff_creative_report/setDownloadData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await newCreativeReportService.setDownload(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

//slice
const newCreativeReportSlice = createSlice({
  name: 'aff_creative_report',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    clearListState(state) {
      state.list.data = null;
      state.list.error = null;
      state.list.isFetching = false;
    },
    clearTrackersState(state) {
      state.trackers.data = null;
      state.trackers.error = null;
      state.trackers.isFetching = false;
    },
    clearDownload(state) {
      state.download.data = null;
      state.download.error = null;
      state.download.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<CreativeReportSliceState>>) => {
    builder.addCase(getOptionsData.pending, (state ) => {
      state.options.isFetching = true;
      state.options.error = null;
    });
    builder.addCase(getOptionsData.fulfilled, (state , action) => {
      state.options.error = null;
      state.options.isFetching = false;
      state.options.data = action.payload;
    });
    builder.addCase(getOptionsData.rejected, (state , action) => {
      state.options.isFetching = false;
      state.options.error = action.payload;
    });

    builder.addCase(getListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getListData.fulfilled, (state , action) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getListData.rejected, (state , action) => {
      state.list.isFetching = false;
      state.list.error = action.payload;
    });

    builder.addCase(setDownloadData.pending, (state ) => {
      state.download.isFetching = true;
      state.download.error = null;
    });
    builder.addCase(setDownloadData.fulfilled, (state , action) => {
      state.download.error = null;
      state.download.isFetching = false;
      state.download.data = action.payload;
    });
    builder.addCase(setDownloadData.rejected, (state , action) => {
      state.download.isFetching = false;
      state.download.error = action.payload;
    });

    builder.addCase(getTrackersData.pending, (state ) => {
      state.trackers.isFetching = true;
      state.trackers.error = null;
    });
    builder.addCase(getTrackersData.fulfilled, (state , action) => {
      state.trackers.error = null;
      state.trackers.isFetching = false;
      state.trackers.data = action.payload;
    });
    builder.addCase(getTrackersData.rejected, (state , action) => {
      state.trackers.isFetching = false;
      state.trackers.error = action.payload;
    });

    builder.addCase(getCreativesData.pending, (state ) => {
      state.creatives.isFetching = true;
      state.creatives.error = null;
    });
    builder.addCase(getCreativesData.fulfilled, (state , action) => {
      state.creatives.error = null;
      state.creatives.isFetching = false;
      state.creatives.data = action.payload;
    });
    builder.addCase(getCreativesData.rejected, (state , action) => {
      state.creatives.isFetching = false;
      state.creatives.error = action.payload;
    });
  },
});

export default newCreativeReportSlice.reducer;

export const {
  clearDownload,
  clearListState,
  clearTrackersState,
  reset,
} = newCreativeReportSlice.actions;
