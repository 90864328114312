//core
import React, {useCallback, useState} from "react";
import { useTranslation } from "react-i18next";
import arePropsEqual from "react-fast-compare";
import {Delete, Send, AccountCircle, LockPerson, LockOpen} from "@mui/icons-material";

//components
import ActionIconButton from "../../../../UI/Buttons/ActionIconButton";
import MuiModal from "../../../../UI/MuiModal";
import { usePermissions } from "../../../../../store/common/configuration/usePermissions";
import { useUI } from "../../../../../store/common/ui/useUI";
import RemoveAdmin from "../RemoveAdmin";
import InviteAdmin from "../InviteAdmin";
import BlockAdmin from "../BlockAdmin";
import RoleForm from "../RoleForm";
import {useSelector} from "react-redux";
import {selectListDepartmentsData, selectListRolesData} from "../../../../../store/admin/employees/selectors";
import {useEmployees} from "../../../../../store/admin/employees/useEmployees";


export interface ITableActions {
    id: number;
    data: any;
}

const TableActions: React.FC<ITableActions> = (props): JSX.Element => {
  const { t } = useTranslation();
  const { hasPermissions } = usePermissions();
  const { i18n } = useTranslation();
  const { setGeneralSnackbar } = useUI();
  const { editAdmin } = useEmployees();

  const { id, data } = props;

  const [showRemoveAdminModal, setShowRemoveAdminModal] = useState<boolean>(false);
  const [showInviteAdminModal, setShowInviteAdminModal] = useState<boolean>(false);
  const [showBlockAdminModal, setShowBlockAdminModal] = useState<boolean>(false);
  const [showEditAdminModal, setShowEditAdminModal] = useState<boolean>(false);

  const isPermissionEditBtn = hasPermissions(["api2.admin.personalscontroller.edit"]);
  const isPermissionBanBtn = hasPermissions(["api2.admin.personalscontroller.change_ban_status"]);
  const isPermissionRemoveBtn = hasPermissions(["api2.admin.personalscontroller.delete"]);

  const handleToggleRemoveAdminModal = useCallback((state: boolean) => {
    setShowRemoveAdminModal(state);
  }, [setShowRemoveAdminModal]);

  const handleToggleInviteAdminModal = useCallback((state: boolean) => {
    setShowInviteAdminModal(state);
  }, [setShowInviteAdminModal]);

  const handleToggleBlockAdminModal = useCallback((state: boolean) => {
    setShowBlockAdminModal(state);
  }, [setShowBlockAdminModal]);

  const handleToggleEditAdminModal = useCallback((state: boolean) => {
    setShowEditAdminModal(state);
  }, [setShowEditAdminModal]);

  const handleEditAdmin = (payload: any) => {
    editAdmin(payload);
  }

  return (
    <>
      {
        !!data && (
          <>
            {
              isPermissionEditBtn && !data.invitation_accepted_at && (
                <ActionIconButton
                  text={t("common.buttons.resend")}
                  icon={<Send fontSize="small" color='primary' />}
                  onClick={() => handleToggleInviteAdminModal(true)}
                />
              )
            }
            {
              isPermissionEditBtn && (
                <ActionIconButton
                  text={t("common.buttons.account")}
                  icon={<AccountCircle fontSize="small" color='primary' />}
                  onClick={() => handleToggleEditAdminModal(true)}
                />
              )
            }
            {
              isPermissionBanBtn && (
                <ActionIconButton
                  text={data.banned ? t("common.buttons.unblock") : t("common.buttons.block")}
                  icon={data.banned ? <LockOpen fontSize="small" color='primary' /> : <LockPerson fontSize="small" color='primary' />}
                  onClick={() => handleToggleBlockAdminModal(true)}
                />
              )
            }
            {
              isPermissionRemoveBtn && (
                <ActionIconButton
                  text={t("common.buttons.delete")}
                  icon={<Delete fontSize="small" color='primary' />}
                  onClick={() => handleToggleRemoveAdminModal(true)}
                />
              )
            }
            {
              isPermissionRemoveBtn && (
                <MuiModal
                  width={472}
                  open={showRemoveAdminModal}
                  closeOnOutside={false}
                  setToggleModal={handleToggleRemoveAdminModal}
                >
                  <RemoveAdmin
                    data={data}
                    onClose={handleToggleRemoveAdminModal}
                  />
                </MuiModal>
              )
            }
            {
              isPermissionEditBtn && (
                <MuiModal
                  width={472}
                  open={showInviteAdminModal}
                  closeOnOutside={false}
                  setToggleModal={handleToggleInviteAdminModal}
                >
                  <InviteAdmin
                    data={data}
                    onClose={handleToggleInviteAdminModal}
                  />
                </MuiModal>
              )
            }
            {
              isPermissionBanBtn && (
                <MuiModal
                  width={472}
                  open={showBlockAdminModal}
                  closeOnOutside={false}
                  setToggleModal={handleToggleBlockAdminModal}
                >
                  <BlockAdmin
                    data={data}
                    onClose={handleToggleBlockAdminModal}
                  />
                </MuiModal>
              )
            }
            {
              isPermissionEditBtn && (
                <MuiModal
                  width={472}
                  open={showEditAdminModal}
                  closeOnOutside={false}
                  setToggleModal={handleToggleEditAdminModal}
                >
                  <RoleForm
                    data={data}
                    onClose={handleToggleEditAdminModal}
                    onSubmit={handleEditAdmin}
                  />
                </MuiModal>
              )
            }
          </>
        )
      }
    </>
  );
};

export default React.memo(TableActions, arePropsEqual);
