import { api } from "../../api";
import {handleResponse, RequestOptions} from "../types";
import {getCookie} from "../../helpers/utils";
import {cookieSupervision, cookieToken} from "../../constants";

const getGeneralData = () => {
  const authenticity_token = getCookie(cookieToken);

  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.settings.edit : api.affiliate.settings.edit;

  return fetch(`${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const updateGeneralData = (data: any) => {
  const payload = {
    ...data.data,
  }

  const locale = data.locale === 'ru' ? 'ru_RU' : 'en_US';

  return fetch(`${api.affiliate.settings.update}?locale=${locale}`, {...RequestOptions('PATCH', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const updateEmailData = (data: any) => {
  const payload = {
    ...data.data,
  }

  const locale = data.locale === 'ru' ? 'ru_RU' : 'en_US';

  return fetch(`${api.affiliate.settings.update_email}?locale=${locale}`, {...RequestOptions('PUT', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const updatePasswordData = (data: any) => {
  const payload = {
    ...data.data,
  }

  const locale = data.locale === 'ru' ? 'ru_RU' : 'en_US';

  return fetch(`${api.affiliate.settings.update_password}?locale=${locale}`, {...RequestOptions('PUT', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getPaymentList = () => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.settings.payment.list : api.affiliate.settings.payment.list;

  return fetch(`${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getPaymentMethods = () => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.settings.payment.methods : api.affiliate.settings.payment.methods;

  return fetch(`${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}
const postPaymentMethod = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.settings.payment.create : api.affiliate.settings.payment.create;

  const payload = {
    ...data.payload,
    authenticity_token: authenticity_token,
  };

  const headers = {
    ...(data.hasOwnProperty('auth_type') ? {"auth-type": data.auth_type} : {}),
    ...(data.hasOwnProperty('auth_code') ? {"auth-code": data.auth_code} : {}),
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload), headers)}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const deletePaymentMethod = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.settings.payment.delete.replace('{id}', `${data.id}`)
    : api.affiliate.settings.payment.delete.replace('{id}', `${data.id}`);

  const headers = {
    ...(data.hasOwnProperty('auth_type') ? {"auth-type": data.auth_type} : {}),
    ...(data.hasOwnProperty('auth_code') ? {"auth-code": data.auth_code} : {}),
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('DELETE', '', headers)}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const updatePaymentMethod = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.settings.payment.update.replace('{id}', `${data.id}`)
    : api.affiliate.settings.payment.update.replace('{id}', `${data.id}`);

  const payload = {
    ...data.payload,
    authenticity_token: authenticity_token,
  }

  const headers = {
    ...(data.hasOwnProperty('auth_type') ? {"auth-type": data.auth_type} : {}),
    ...(data.hasOwnProperty('auth_code') ? {"auth-code": data.auth_code} : {}),
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PUT', JSON.stringify(payload), headers)}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getPaymentHistory = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.affiliate.settings.payment.history;


  const payload = {
    ...data.payload,
    authenticity_token: authenticity_token,
  }

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getBalance = () => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.settings.balance
    : api.affiliate.settings.balance;

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

export const profileService = {
  getGeneralData,
  updateGeneralData,
  updateEmailData,
  updatePasswordData,
  getPaymentList,
  getPaymentMethods,
  postPaymentMethod,
  deletePaymentMethod,
  updatePaymentMethod,
  getPaymentHistory,
  getBalance,
};
