import { useDispatch } from "react-redux";

import {
  getListData,
  clearListState,
  createItemData,
  clearCreateState,
  updateItemData,
  clearUpdateState,
  deleteItemData,
  clearDeleteState,
  getLabelsData,
  clearLabelsState,
  getItemData,
  clearItemState,
  ListPayloadType,
  CreatePayloadType,
  UpdatePayloadType,
  DeletePayloadType,
  LabelsPayloadType,
  ReadPayloadType,
} from "./index";
import { storageService } from "../../../services/common/storage.service";
import { setUser } from "../user";
import { setGeneralSnackbarState } from "../ui";


export const useStorage = () => {
  const dispatch = useDispatch();

  const getList = (data: ListPayloadType) => dispatch(getListData(data));
  const clearListData = () => dispatch(clearListState());

  const createItem = (data: CreatePayloadType) => dispatch(createItemData(data));
  const clearCreateData = () => dispatch(clearCreateState());

  const updateItem = (data: UpdatePayloadType) => dispatch(updateItemData(data));
  const clearUpdateData = () => dispatch(clearUpdateState());

  const deleteItem = (data: DeletePayloadType) => dispatch(deleteItemData(data));
  const clearDeleteData = () => dispatch(clearDeleteState());

  const getLabels = (data: LabelsPayloadType) => dispatch(getLabelsData(data));
  const clearLabelsData = () => dispatch(clearLabelsState());

  const getItem = (data: ReadPayloadType) => dispatch(getItemData(data));
  const clearItemData = () => dispatch(clearItemState());

  const getStorageList = async (payload: ListPayloadType) => {
    try {
      const response = await storageService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return {
          status: response.status,
          statusText: response.statusText,
          data: data,
        }
      }

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const getStorageItemById = async (id: number, type: string, target: string) => {
    try {
      const response = await storageService.getById({
        type: type,
        target: target,
        id: id,
      });

      return response;
    } catch (e) {
      console.log(e);
    }
  };

  const createStorageItem = async (payload: CreatePayloadType) => {
    try {
      const response = await storageService.create(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 201) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.saved',
          })
        );
      }

      if (!response.ok) {
        return {
          status: response.status,
          statusText: response.statusText,
          data: data,
        }
      }

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const updateStorageItem = async (payload: UpdatePayloadType) => {
    try {
      const response = await storageService.update(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.saved',
          })
        );
      }

      if (!response.ok) {
        return {
          status: response.status,
          statusText: response.statusText,
          data: data,
        }
      }

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const deleteStorageItem = async (payload: DeletePayloadType) => {
    try {
      const response = await storageService.remove(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.removed',
          })
        );
      }

      if (!response.ok) {
        return {
          status: response.status,
          statusText: response.statusText,
          data: data,
        }
      }

      return data;
    } catch (e) {
      console.log(e);
    }
  };


  return {
    getList,
    clearListData,
    createItem,
    clearCreateData,
    updateItem,
    clearUpdateData,
    deleteItem,
    clearDeleteData,
    getLabels,
    clearLabelsData,
    getItem,
    clearItemData,

    //without store
    getStorageList,
    getStorageItemById,
    createStorageItem,
    updateStorageItem,
    deleteStorageItem,
  }
};
