import {api} from "../../api";
import {handleResponse, RequestOptions} from "../types";
import {getCookie} from "../../helpers/utils";
import {cookieToken} from "../../constants";


const getList = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.download_report.list;

  const payload = {
    ...data,
    authenticity_token: `${authenticity_token ? authenticity_token : 'null'}`,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getDownload = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.download_report.download.replace('{reportId}', data.id);

  const url = `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : 'null'}`

  return fetch(
    `${url}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const deleteReport = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.download_report.delete.replace('{reportId}', data.id);

  const url = `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : 'null'}`

  return fetch(
    `${url}`,
    {...RequestOptions('DELETE')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};


export const downloadReportService = {
  getList,
  getDownload,
  deleteReport,
};
