//core
import React, {useCallback, useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import arePropsEqual from "react-fast-compare";
import {Box, Button, Typography} from "@mui/material";
import {useSelector} from "react-redux";

//components
import {useWebhooksReport} from "../../../../../../store/admin/reports/webhooks_report/useWebhooksReport";
import TableData from "../../../../../common/TableData";
import {OrderType} from "../../../../../affiliate/Trackers";
import {useStyles} from "./styles";
import {selectDetailsWebhook} from "../../../../../../store/admin/reports/webhooks_report/selectors";
import useDidMountEffect from "../../../../../../customHooks/useDidMountEffect";


export interface IDetailsWebhook {
  id: number;
  data: any;
  onClose: (state:boolean) => void;
}


const initialOrderState: OrderType = {
  created_at: "desc",
}

const DetailsWebhook: React.FC<IDetailsWebhook> = (props): JSX.Element => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();
  const { clearDetailsWebhookState, getDetailsWebhook } = useWebhooksReport();

  const { id, data, onClose } = props;

  const detailsWebhook = useSelector(selectDetailsWebhook);

  const [order, setOrder] = useState<OrderType>(initialOrderState);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    return () => {
      clearDetailsWebhookState();
    }
  },[])

  useEffect(() => {
    const payload = {
      webhookTaskId: data.id,
    }
    getDetailsWebhook(payload);
  },[])

  useDidMountEffect(() => {
    const payload = {
      webhookTaskId: data.id,
      order: order,
    }
    getDetailsWebhook(payload);
  },[order])

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, [setPage]);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setRowsPerPage(+event.target.value);
  },[setRowsPerPage, setPage]);

  return (
    <>
      {!!data  && (
        <>
          <Typography variant="h6" sx={{mb: 2}}>{t("admin.reports.webhooks_report.details_title")}</Typography>
          {!!detailsWebhook && (
            <TableData
              list={detailsWebhook.list}
              columns={detailsWebhook.columns_for_display_data}
              totalRow={detailsWebhook.sum_columns}
              order={order}
              setOrder={setOrder}
              page={page}
              rowsPerPage={rowsPerPage}
              totalEntries={+detailsWebhook.total_entries}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              actionsCount={1}
              translationColumnsPrefix="columns_for_display.table.details_webhooks_report."
              stickyHeader={true}
            />
          )}
          <Box className={styles.footer}>
            <Button
              color="secondary"
              type="button"
              variant="outlined"
              onClick={() => {onClose(false)}}
            >
              { t("common.buttons.back") }
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default React.memo(DetailsWebhook, arePropsEqual);
