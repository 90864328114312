import { makeStyles } from "@mui/styles";
import {alpha} from "@mui/material/styles";
import {Theme} from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    position: 'relative',
    padding: '16px 16px 16px',
    marginBottom: 8,
  },

  top: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: 8,
    marginBottom: 8,
  },

  topContent: {
    flex: '1 1 auto',
  },

  textSecondary: {
    color: alpha(theme.palette.text.secondary, 0.6),
  },

  id: {
    marginBottom: 4,
    padding: '2px 40px 2px 0'
  },

  field: {
    padding: '2px 0',
    marginBottom: 4,
  },
  label: {
    color: alpha(theme.palette.text.secondary, 0.6),
    marginRight: 4,
  },
  text: {},

  emailField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  email: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    display: 'inline-block',
  },
  actions: {
    paddingTop: 12,
  },
  button: {
    width: "100%",
  },
}));
