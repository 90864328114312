//core
import { createSelector } from "@reduxjs/toolkit";

//store
import {RootState} from "../../index";

const uiSelector = (state: RootState) => state.ui;
const uiModalsSelector = (state: RootState) => state.ui.modals;

export const selectUi = createSelector(uiSelector, (data) => data);
export const selectModals = createSelector(uiModalsSelector, (modals) => modals);
