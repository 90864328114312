import React, { useState } from "react";
import arePropsEqual from "react-fast-compare";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import format from "date-fns/format";
import { Box } from "@mui/material";

//selectors
import { selectCommunicationUpdateData } from "../../../../../store/admin/affiliateProfile/selectors";

//hooks
import { useAffProfile } from "../../../../../store/admin/affiliateProfile/useAffProfile";

import { itemListData } from "../../../../common/TableData";

import CSingleDatePicker from "../../../../UI/Fields/CSingleDatePicker";
import useDidMountEffect from "../../../../../customHooks/useDidMountEffect";

const useStyles = makeStyles({
  datepicker: {
    backgroundColor: 'red',
    display: "none!important",
  },
});

interface ICommunicationDate {
  data: itemListData;
}

const CommunicationDate: React.FC<ICommunicationDate> = ({
 data,
}): JSX.Element => {
  const styles = useStyles();

  const { updateCommunicationStatus, clearCommunicationUpdate } = useAffProfile();

  const [value, setValue] = useState<Date | ''>(data.communication_date);

  const updateData = useSelector(selectCommunicationUpdateData);

  const handleChangeDate = (value: Date) => {
    setValue(value);
  };

  const handleOnClose = () => {
    const payload = {
      affiliateId: data.id,
      communicationId: data.communication_id,
      data: {
        dateF: value ? format(new Date(value),"yyyy-MM-dd HH:mm:ss") : null,
        dateD: value ? format(new Date(data.communication_date),"yyyy-MM-dd HH:mm:ss") : null,
      }
    }
  }

  useDidMountEffect(() => {
    if (!!updateData) {
      clearCommunicationUpdate();
    }
  }, [updateData]);

  return (
    <Box sx={{ width: '100%', minWidth: '150px', maxWidth: '150px', position: 'relative', zIndex: '0' }}>
      <CSingleDatePicker
        className={styles.datepicker}
        id="communication_date"
        name="communication_date"
        autoComplete="communication_date"
        label={`Select date`}
        value={value}
        onChange={handleChangeDate}
        noMB
        extraSmall
        onCloseCallback={handleOnClose}
      />
    </Box>
  );
};

export default React.memo(CommunicationDate, arePropsEqual);
