//core
import React, { useContext, useState, useEffect, useCallback } from "react";
import arePropsEqual from "react-fast-compare";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
} from "@mui/material";
import {useSelector} from "react-redux";

//context
import {
  BrandsContext,
} from "../../../../admin/Deals/components/PaymentPlanForm/components/ConditionBrandsForm/BrandsContext";

//selectors
import {selectConditionBrandsListData} from "../../../../../store/admin/conditions/selectors";

//types
import { itemListData } from "../../index";
import {GlobalContext} from "../../../../../context/GlobalContext";

interface IText {
  value: string | number;
  data: itemListData;
  setSelectedRow: (state: boolean) => void;
}

const useStyles = makeStyles({
  block: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  text: {
    color: 'inherit',
  }
});

const SelectedBrandsDealsRow = ({
  value = '',
  data,
  setSelectedRow,
}: IText) => {
  const styles = useStyles();
  const context = useContext(BrandsContext);
  const globalContext = useContext(GlobalContext);

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(false);
    if (!!context.selectedRows.length) {
      context.selectedRows.map((item) => {
        if (item === data.code) {
          setChecked(true);
        }
      });
    } else {
      setChecked(false);
    }
  }, [context.selectedRows]);

  useEffect(() => {
    if (checked) {
      setSelectedRow(true);
    } else {
      setSelectedRow(false);
    }
  }, [checked, context.selectedRows]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const selected: itemListData = data;
    if (event.target.checked) {
      context.addSelectedRow(selected.code);
      // globalContext.setAdmDealsSelectedRowBrands([...globalContext.admDealsSelectedRowBrands, selected]);

    } else {
      const filtered = context.selectedRows.filter(item => {
        return item !== selected.code
      });

      // const filteredRow = globalContext.admDealsSelectedRowBrands.filter((item:any) => {
      //   return item.code !== selected.code
      // });
      context.setSelectedRows(filtered)
      // globalContext.setAdmDealsSelectedRowBrands(filteredRow);
    }

    setChecked(event.target.checked);
  };

  return (
    <Box
      component="span"
      className={styles.block}
    >
      <Checkbox
        size="small"
        sx={{ padding: '4px' }}
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
        disabled={context.selectedAllBrands}
      />
      <span className={styles.text}>
        {value ? value : '—'}
      </span>
    </Box>
  );
};

export default React.memo(SelectedBrandsDealsRow, arePropsEqual);
