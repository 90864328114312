//core
import React, {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import { useField, useFormikContext } from "formik";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {DateTimePicker, DesktopDateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {FormControl, FormHelperText, InputAdornment, IconButton, Box} from "@mui/material";
import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";

//styles
import styles from "./styles/styles.module.scss";
import Calendar from "@mui/icons-material/Event";
import {DateOrTimeView} from "@mui/x-date-pickers/models";
import {useOnClickOutside} from "../../../../customHooks/useOnClickOutside";

//types
interface IMuiFieldProps {
  id?: string;
  name: string;
  autoComplete?: string;
  label: string;
  value?: any;
  secondValue?: any;
  maxDate?: any,
  minDate?: any,
  //type?: React.InputHTMLAttributes<unknown>['type'];
  hideError?: boolean,
  placeholder?: string,
  disabled?: boolean,
  className?: string,
  helperText?: string,
  dataId?: string,
  InputProps?: any,
  error?: boolean | string,
  validation?: (value: any) => any,
  validate?: (value: any) => any,
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  inputFormat?: string;
  views?: DateOrTimeView[];
  onCloseCallback?: () => void;
  closeOnOutside?: boolean;
}

const localeMap = {
  en: enLocale,
  ru: ruLocale,
};

export const MuiSingleDateTimePicker = (props: IMuiFieldProps) => {
  const [field, meta] = useField(props);
  const ref: any = useRef<HTMLDivElement>();

  const { i18n } = useTranslation();

  const { setFieldValue } = useFormikContext();

  const {
    id,
    name,
    autoComplete,
    label,
    value,
    secondValue,
    disabled = false,
    maxDate,
    minDate,
    error = '',
    hideError = false,
    //views = [],
    inputFormat = 'dd/MM/yyyy HH:mm',
    views = ["year", "day", "hours", "minutes"],
    onCloseCallback,
    closeOnOutside = false
  } = props;


  const [fieldVal, setFieldVal] = React.useState<Date | null>(new Date(value));
  const [open, setOpen] = useState(false);
  const [locale, setLocale] = useState<object>(localeMap.en);

  useEffect(() => {
    setFieldVal(new Date(value))
  }, [value]);

  useEffect(() => {
    if (i18n.language === 'ru') {
      setLocale(localeMap.ru);
    }

    if (i18n.language === 'en') {
      setLocale(localeMap.en);
    }

  }, [i18n.language]);

  useEffect(() => {
    if (fieldVal !== null) {
      if (name === 'at_end_show') {
        if (new Date(fieldVal) < new Date(secondValue)) {
          setFieldValue(field.name, secondValue);
          setFieldVal(secondValue);
        }
      }
      if (name === 'at_beginning_show') {
        if (new Date(fieldVal) > new Date(secondValue)) {
          setFieldValue('at_end_show', fieldVal);
          setFieldVal(fieldVal);
        }
      }
    }

  }, [fieldVal, minDate]);

  const handleClose = () => {
    setOpen(false);
    if (typeof onCloseCallback === 'function') {
      onCloseCallback();
    }
  };

  useOnClickOutside(ref, closeOnOutside ? handleClose : () => {});

  return (
    <LocalizationProvider
      //@ts-ignore
      dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <div className={styles.formField}>
        <FormControl fullWidth >
          <DateTimePicker
            views={views}
            ampm={false}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={handleClose}
            label={label}
            disabled={disabled}
            //value={new Date(value)}
            value={fieldVal}
            format={i18n.language === 'en' ? 'MM/dd/yyyy HH:mm' : inputFormat}
            // @ts-ignore
            closeOnSelect={true}
            {...(maxDate ? {maxDate: new Date(maxDate)} : {})}
            {...(minDate ? {minDate: new Date(minDate)} : {})}
            // @ts-ignore
            onChange={(newValue) => {
              setFieldVal(newValue);
              setFieldValue(field.name, newValue);
            }}
            slotProps={{
              popper: {
                disablePortal: false,
                ref: ref,
              },
              // layout: {
              // sx: {
              // },
              // },
              textField: {
                size: 'small',
                id: id,
                onClick: () => {
                  setOpen(true);
                },
                inputProps: {
                  readOnly: true,
                  disabled: true,
                  style: {
                    pointerEvents: 'none',
                    userSelect: 'none',
                  },
                },
                className: `${styles.input} ${ !!(meta.touched && !!meta.error && meta.error !== 'error') ? styles.inputError : styles.inputErrorDef }`,
                InputProps: {
                  endAdornment: <InputAdornment position="end">
                    <IconButton size="small" className={styles.iconButton} sx={{ padding: '2px' }}>
                      <Calendar  color="action" />
                    </IconButton>
                  </InputAdornment>,
                  readOnly: true,
                  sx: {
                    userSelect: 'none',
                    cursor: 'pointer',
                  },
                },
                autoComplete: autoComplete,
                ...(hideError ? {error: false} : {}),
                ...( !!(meta.touched && meta.error) ? {color: 'error'} : {})
              },
              actionBar: ({ wrapperVariant }) => {
                return {
                  actions: wrapperVariant === 'desktop' ? [] : ['accept'],
                }
              },
            }}
          />
          { meta.touched && !!meta.error && meta.error !== 'error' ? (
            <FormHelperText error>{meta.error}</FormHelperText>
          ) : null }
        </FormControl>
      </div>
    </LocalizationProvider>
  );
};
