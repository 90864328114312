import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import { cookieToken } from "../../constants";
import {
  payloadSubAffDealsList,
} from "../../store/admin/subAffDeals";

const getList = (data: payloadSubAffDealsList) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.sub_aff_deals.list;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  }

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const deleteCondition = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.sub_aff_deals.delete.replace('{id}', `${data.id}`);

  return fetch(`${endpoint}?authenticity_token=${authenticity_token}`, {...RequestOptions('DELETE')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getConstants = () => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.sub_aff_deals.constants}?authenticity_token=${authenticity_token}`;

  return fetch(`${endpoint}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const createCondition = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.sub_aff_deals.create;

  const payload = {
    subaffiliate_condition: data,
    authenticity_token: authenticity_token,
  }

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getCondition = (id: string) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.sub_aff_deals.condition
    .replace('{id}', id)}?authenticity_token=${authenticity_token}`;

  return fetch(`${endpoint}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const editCondition = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.sub_aff_deals.edit.replace('{id}', `${data.id}`);

  const payload = {
    subaffiliate_condition: data,
    authenticity_token: authenticity_token,
  }

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}


export const subAffDealsService = {
  getList,
  deleteCondition,
  getConstants,
  createCondition,
  getCondition,
  editCondition,
};
