//core
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";

//icons
import { MoreVert, Send, AccountCircle , LockOpen, LockPerson, Delete } from "@mui/icons-material";

//styles
import { useStyles } from "./styles";

//routes
import routes from "../../../../../../../navigation/routes";

//hooks
import { usePermissions } from "../../../../../../../store/common/configuration/usePermissions";

//types
import { ListItemData } from "../../../../../../../store/admin/employees";
import { useEmployees } from "../../../../../../../store/admin/employees/useEmployees";
import MuiModal from "../../../../../../UI/MuiModal";
import RemoveAdmin from "../../../RemoveAdmin";
import InviteAdmin from "../../../InviteAdmin";
import BlockAdmin from "../../../BlockAdmin";
import RoleForm from "../../../RoleForm";


//components


export interface ICardMenuProps {
  data: ListItemData;
}

const CardMenu: React.FC<ICardMenuProps> = ({
  data,
}): JSX.Element => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { hasPermissions } = usePermissions();
  const { editAdmin } = useEmployees();


  const [showRemoveAdminModal, setShowRemoveAdminModal] = useState<boolean>(false);
  const [showInviteAdminModal, setShowInviteAdminModal] = useState<boolean>(false);
  const [showBlockAdminModal, setShowBlockAdminModal] = useState<boolean>(false);
  const [showEditAdminModal, setShowEditAdminModal] = useState<boolean>(false);

  const isPermissionEditBtn = hasPermissions(["api2.admin.personalscontroller.edit"]);
  const isPermissionBanBtn = hasPermissions(["api2.admin.personalscontroller.change_ban_status"]);
  const isPermissionRemoveBtn = hasPermissions(["api2.admin.personalscontroller.delete"]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  },[setAnchorEl]);

  const handleToggleRemoveAdminModal = useCallback((state: boolean) => {
    setShowRemoveAdminModal(state);
  }, [setShowRemoveAdminModal]);

  const handleToggleInviteAdminModal = useCallback((state: boolean) => {
    setShowInviteAdminModal(state);
    setAnchorEl(null);
  }, [setShowInviteAdminModal]);

  const handleToggleBlockAdminModal = useCallback((state: boolean) => {
    setShowBlockAdminModal(state);
    setAnchorEl(null);
  }, [setShowBlockAdminModal]);

  const handleToggleEditAdminModal = useCallback((state: boolean) => {
    setShowEditAdminModal(state);
    setAnchorEl(null);
  }, [setShowEditAdminModal]);

  const handleEditAdmin = (payload: any) => {
    editAdmin(payload);
    setAnchorEl(null);
  }

  return (
    <>
      <Button
        className={`employees-card-menu ${styles.button} ${ open ? styles.active : ''}`}
        color="secondary"
        onClick={handleClick}
        id="aff-card-menu"
        aria-controls={open ? 'basic-card-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <MoreVert className={styles.icon} />
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'tracker-card-menu',
          sx: {
            paddingTop: 0,
            paddingBottom: 0,
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {isPermissionEditBtn && !data.invitation_accepted_at && (
          <MenuItem
            onClick={() => handleToggleInviteAdminModal(true)}
          >
            <Send className={styles.icon} sx={{ marginRight: '16px' }} />
            {t("common.buttons.resend")}
          </MenuItem>
        )}

        {isPermissionEditBtn && !data.invitation_accepted_at && (
          <Divider className={styles.divider}/>
        )}

        {isPermissionEditBtn && (
          <MenuItem
            onClick={() => handleToggleEditAdminModal(true)}
          >
            <AccountCircle className={styles.icon} sx={{ marginRight: '16px' }} />
            {t("common.buttons.account")}
          </MenuItem>
        )}

        {isPermissionEditBtn && isPermissionBanBtn && (
          <Divider className={styles.divider}/>
        )}

        {isPermissionBanBtn && (
          <MenuItem
            onClick={() => handleToggleBlockAdminModal(true)}
          >
            {data.banned
              ? <LockOpen className={styles.icon} sx={{ marginRight: '16px' }} />
              : <LockPerson className={styles.icon} sx={{ marginRight: '16px' }} />
            }
            {data.banned ? t("common.buttons.unblock") : t("common.buttons.block")}
          </MenuItem>
        )}

        {isPermissionBanBtn && isPermissionRemoveBtn && (
          <Divider className={styles.divider}/>
        )}

        {isPermissionRemoveBtn && (
          <MenuItem
            onClick={() => handleToggleRemoveAdminModal(true)}
          >
            <Delete className={styles.icon} sx={{ marginRight: '16px' }} />
            {t("common.buttons.delete")}
          </MenuItem>
        )}
      </Menu>

      {
        isPermissionRemoveBtn && (
          <MuiModal
            width={472}
            open={showRemoveAdminModal}
            closeOnOutside={false}
            setToggleModal={handleToggleRemoveAdminModal}
          >
            <RemoveAdmin
              data={data}
              onClose={handleToggleRemoveAdminModal}
            />
          </MuiModal>
        )
      }
      {
        isPermissionEditBtn && (
          <MuiModal
            width={472}
            open={showInviteAdminModal}
            closeOnOutside={false}
            setToggleModal={handleToggleInviteAdminModal}
          >
            <InviteAdmin
              data={data}
              onClose={handleToggleInviteAdminModal}
            />
          </MuiModal>
        )
      }
      {
        isPermissionBanBtn && (
          <MuiModal
            width={472}
            open={showBlockAdminModal}
            closeOnOutside={false}
            setToggleModal={handleToggleBlockAdminModal}
          >
            <BlockAdmin
              data={data}
              onClose={handleToggleBlockAdminModal}
            />
          </MuiModal>
        )
      }
      {
        isPermissionEditBtn && (
          <MuiModal
            width={472}
            open={showEditAdminModal}
            closeOnOutside={false}
            setToggleModal={handleToggleEditAdminModal}
          >
            <RoleForm
              data={data}
              onClose={handleToggleEditAdminModal}
              onSubmit={handleEditAdmin}
            />
          </MuiModal>
        )
      }
    </>
  );
};

export default CardMenu;
