//core
import React, {useEffect, useState} from "react";
import arePropsEqual from "react-fast-compare";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {format, parse} from "date-fns";
import enLocale from "date-fns/locale/en-US";
import ruLocale from "date-fns/locale/ru";
import ukLocale from "date-fns/locale/uk";


interface IDateTime {
  value: any;
  fullDate?: boolean;
  hideSeconds?: boolean;
}

type itemLocale = {
  locale: object,
  format: string,
  withOutSeconds: string,
}

const localeMap = {
  en: {
    locale: enLocale,
    format: 'MM/dd/yyyy',
    withOutSeconds: 'MM/dd/yyyy',
  },
  ru: {
    locale: ruLocale,
    format: 'dd.MM.yyyy',
    withOutSeconds: 'dd.MM.yyyy',
  },
  uk: {
    locale: ukLocale,
    format: 'dd.MM.yyyy',
    withOutSeconds: 'dd.MM.yyyy',
  },
};

const fullLocaleMap = {
  en: {
    locale: enLocale,
    format: 'MM/dd/yyyy HH:mm:ss',
    withOutSeconds: 'MM/dd/yyyy HH:mm',
  },
  ru: {
    locale: ruLocale,
    format: 'dd.MM.yyyy HH:mm:ss',
    withOutSeconds: 'dd.MM.yyyy HH:mm',
  },
  uk: {
    locale: ruLocale,
    format: 'dd.MM.yyyy HH:mm:ss',
    withOutSeconds: 'dd.MM.yyyy HH:mm',
  },
};

const useStyles = makeStyles({
  text: {
    color: 'inherit',
    whiteSpace: 'nowrap',
  }
});

const DateTimeRange = ({
  value,
  fullDate = false,
  hideSeconds = false,
}: IDateTime) => {
  const styles = useStyles();
  const { i18n } = useTranslation();

  const val: string[] = !!value ? value.split(' - ') : [];

  const [locale, setLocale] = useState<itemLocale>(fullDate ? fullLocaleMap.en : localeMap.en);

  useEffect(() => {
    if (i18n.language === 'ru') {
      setLocale(fullDate ? fullLocaleMap.ru : localeMap.ru);
    }
    if (i18n.language === 'uk') {
      setLocale(fullDate ? fullLocaleMap.uk : localeMap.uk);
    }
    if (i18n.language === 'en') {
      setLocale(fullDate ? fullLocaleMap.en : localeMap.en);
    }
  }, [i18n.language]);

  const date = !!value && val.reduce((acc, item, i) => {
    try {
      const parsedDate = parse(item, 'yyyy-MM-dd HH:mm:ss', new Date(), { locale: locale.locale });
      return acc + `${format(parsedDate, hideSeconds ? locale.withOutSeconds : locale.format, {locale: locale.locale})}${val.length !== i+1 ? ' - ' : ''}`
    } catch (e) {
      return acc + `${format(new Date(item), hideSeconds ? locale.withOutSeconds : locale.format, {locale: locale.locale})}${val.length !== i+1 ? ' - ' : ''}`
    }
  }, '');

  return (
    <span className={styles.text}>
      { value ? date : '—' }
    </span>
  );
};

export default React.memo(DateTimeRange, arePropsEqual);
