//core
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";
import {setUser} from "../../../common/user";
import {summaryReportService} from "../../../../services/affiliate/summaryReport.service";
import {setGeneralProgressHide, setGeneralProgressShow} from "../../../common/ui";

type OptionsDataType = {
  group_by: {
    date: string[];
    affiliate: {
      [key: string]: string;
    },
    affiliate_second_level: {
      [key: string]: string;
    },
    row_on_page: number[];
  },
  separators_for_format: {
    csv: string[];
  },
  encoding_types: string[];
}

type SegmentItem = {
  id: number;
  name: string;
}

type SegmentDataType = {
  list: SegmentItem[];
  tabs_list: number[];
}

export type SummaryReportSliceState = {
  options: {
    data: OptionsDataType | null,
    error: any,
    isFetching: boolean,
  },
  segments: {
    data: SegmentDataType | null,
    error: any,
    isFetching: boolean,
  },
  list: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  deleted: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  created: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  update: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  segmentTab: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  updateTab: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  download: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
};

const initialState: SummaryReportSliceState = {
  options: {
    data: null,
    error: null,
    isFetching: false,
  },
  segments: {
    data: null,
    error: null,
    isFetching: false,
  },
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  deleted: {
    data: null,
    error: null,
    isFetching: false,
  },
  created: {
    data: null,
    error: null,
    isFetching: false,
  },
  update: {
    data: null,
    error: null,
    isFetching: false,
  },
  segmentTab: {
    data: null,
    error: null,
    isFetching: false,
  },
  updateTab: {
    data: null,
    error: null,
    isFetching: false,
  },
  download: {
    data: null,
    error: null,
    isFetching: false,
  },
};

export const getOptionsData: any = createAsyncThunk(
  'summary_report/getOptionsData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await summaryReportService.getOptions();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getSegmentsData: any = createAsyncThunk(
  'summary_report/getSegmentsData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await summaryReportService.getSegments();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getListData: any = createAsyncThunk(
  'summary_report/getListData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await summaryReportService.getReport(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const deleteSegmentData: any = createAsyncThunk(
  'summary_report/deleteSegmentData',
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await summaryReportService.deleteSegment(id);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const createSegmentData: any = createAsyncThunk(
  'summary_report/createSegmentData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await summaryReportService.createSegment();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const updateSegmentData: any = createAsyncThunk(
  'summary_report/updateSegmentData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await summaryReportService.updateSegment(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getSegmentTabData: any = createAsyncThunk(
  'summary_report/getSegmentTabData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await summaryReportService.getSegmentTab(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const addSegmentTabData: any = createAsyncThunk(
  'summary_report/addSegmentTabData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await summaryReportService.addSegmentTabData(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const removeSegmentTabData: any = createAsyncThunk(
  'summary_report/removeSegmentTabData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await summaryReportService.removeSegmentTabData(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const setDownloadData: any = createAsyncThunk(
  'summary_report/setDownloadData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await summaryReportService.setDownload(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


//slice
const summaryReportSlice = createSlice({
  name: 'summary_report',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    clearListState(state) {
      state.list.data = null;
      state.list.error = null;
      state.list.isFetching = false;
    },
    clearDeleteSegment(state) {
      state.deleted.data = null;
      state.deleted.error = null;
      state.deleted.isFetching = false;
    },
    clearCreatedSegment(state) {
      state.created.data = null;
      state.created.error = null;
      state.created.isFetching = false;
    },
    clearSegmentTab(state) {
      state.segmentTab.data = null;
      state.segmentTab.error = null;
      state.segmentTab.isFetching = false;
    },
    clearUpdateTab(state) {
      state.updateTab.data = null;
      state.updateTab.error = null;
      state.updateTab.isFetching = false;
    },
    clearUpdate(state) {
      state.update.data = null;
      state.update.error = null;
      state.update.isFetching = false;
    },
    clearDownload(state) {
      state.download.data = null;
      state.download.error = null;
      state.download.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<SummaryReportSliceState>>) => {
    builder.addCase(getOptionsData.pending, (state ) => {
      state.options.isFetching = true;
      state.options.error = null;
    });
    builder.addCase(getOptionsData.fulfilled, (state , action) => {
      state.options.error = null;
      state.options.isFetching = false;
      state.options.data = action.payload;
    });
    builder.addCase(getOptionsData.rejected, (state , action) => {
      state.options.isFetching = false;
      state.options.error = action.payload;
    });

    builder.addCase(getListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getListData.fulfilled, (state , action) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getListData.rejected, (state , action) => {
      state.list.isFetching = false;
      state.list.error = action.payload;
    });

    builder.addCase(getSegmentsData.pending, (state ) => {
      state.segments.isFetching = true;
      state.segments.error = null;
    });
    builder.addCase(getSegmentsData.fulfilled, (state , action) => {
      state.segments.error = null;
      state.segments.isFetching = false;
      state.segments.data = action.payload;
    });
    builder.addCase(getSegmentsData.rejected, (state , action) => {
      state.segments.isFetching = false;
      state.segments.error = action.payload;
    });

    builder.addCase(deleteSegmentData.pending, (state ) => {
      state.deleted.isFetching = true;
      state.deleted.error = null;
    });
    builder.addCase(deleteSegmentData.fulfilled, (state , action) => {
      state.deleted.error = null;
      state.deleted.isFetching = false;
      state.deleted.data = action.payload;
    });
    builder.addCase(deleteSegmentData.rejected, (state , action) => {
      state.deleted.isFetching = false;
      state.deleted.error = action.payload;
    });

    builder.addCase(createSegmentData.pending, (state ) => {
      state.created.isFetching = true;
      state.created.error = null;
    });
    builder.addCase(createSegmentData.fulfilled, (state , action) => {
      state.created.error = null;
      state.created.isFetching = false;
      state.created.data = action.payload;
    });
    builder.addCase(createSegmentData.rejected, (state , action) => {
      state.created.isFetching = false;
      state.created.error = action.payload;
    });

    builder.addCase(updateSegmentData.pending, (state ) => {
      state.update.isFetching = true;
      state.update.error = null;
    });
    builder.addCase(updateSegmentData.fulfilled, (state , action) => {
      state.update.error = null;
      state.update.isFetching = false;
      state.update.data = action.payload;
    });
    builder.addCase(updateSegmentData.rejected, (state , action) => {
      state.update.isFetching = false;
      state.update.error = action.payload;
    });

    builder.addCase(getSegmentTabData.pending, (state ) => {
      state.segmentTab.isFetching = true;
      state.segmentTab.error = null;
    });
    builder.addCase(getSegmentTabData.fulfilled, (state , action) => {
      state.segmentTab.error = null;
      state.segmentTab.isFetching = false;
      state.segmentTab.data = action.payload;
    });
    builder.addCase(getSegmentTabData.rejected, (state , action) => {
      state.segmentTab.isFetching = false;
      state.segmentTab.error = action.payload;
    });

    builder.addCase(addSegmentTabData.pending, (state ) => {
      state.updateTab.isFetching = true;
      state.updateTab.error = null;
    });
    builder.addCase(addSegmentTabData.fulfilled, (state , action) => {
      state.updateTab.error = null;
      state.updateTab.isFetching = false;
      state.updateTab.data = action.payload;
    });
    builder.addCase(addSegmentTabData.rejected, (state , action) => {
      state.updateTab.isFetching = false;
      state.updateTab.error = action.payload;
    });

    builder.addCase(removeSegmentTabData.pending, (state ) => {
      state.updateTab.isFetching = true;
      state.updateTab.error = null;
    });
    builder.addCase(removeSegmentTabData.fulfilled, (state , action) => {
      state.updateTab.error = null;
      state.updateTab.isFetching = false;
      state.updateTab.data = action.payload;
    });
    builder.addCase(removeSegmentTabData.rejected, (state , action) => {
      state.updateTab.isFetching = false;
      state.updateTab.error = action.payload;
    });

    builder.addCase(setDownloadData.pending, (state ) => {
      state.download.isFetching = true;
      state.download.error = null;
    });
    builder.addCase(setDownloadData.fulfilled, (state , action) => {
      state.download.error = null;
      state.download.isFetching = false;
      state.download.data = action.payload;
    });
    builder.addCase(setDownloadData.rejected, (state , action) => {
      state.download.isFetching = false;
      state.download.error = action.payload;
    });
  },
});

export default summaryReportSlice.reducer;

export const {
  clearDeleteSegment,
  clearCreatedSegment,
  clearSegmentTab,
  clearUpdateTab,
  clearUpdate,
  clearDownload,
  clearListState,
  reset,
} = summaryReportSlice.actions;
