import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { setUser } from "../../common/user";
import { subAffDealsService } from "../../../services/admin/subAffDeals.service";
import { OrderType } from "../../../components/affiliate/Trackers";
import { setGeneralProgressHide, setGeneralProgressShow, setGeneralSnackbarState } from "../../common/ui";

export type payloadSubAffDealsList = {
  additional_options: null;
  page: number;
  perPage?: number;
  segment_id: number;
  order: OrderType;
  search: {
    keyword: string;
  };
};

export type listItem = {
  id: number;
  title: string;
  description: string;
  read_only: boolean;
  created_at: string;
};

export type columnsItem = {
  name: string;
  type: string;
  sort: boolean;
  match?: boolean;
};

export interface ISubAffDealsListData {
  list: listItem[];
  columns_for_display_data: columnsItem[];
  start_row_num: number;
  end_row_num: number;
  total_entries: number | string;
  total_pages: number;
  locale_key: string;
  stateList: string[];
};


export type subAffDealsSliceState = {
  list: {
    data: ISubAffDealsListData | null,
    error: any,
    isFetching: boolean,
  },
  deleteCondition: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  constants: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  createCondition: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  viewCondition: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  editCondition: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
};

const initialState: subAffDealsSliceState = {
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  deleteCondition: {
    data: null,
    error: null,
    isFetching: false,
  },
  constants: {
    data: null,
    error: null,
    isFetching: false,
  },
  createCondition: {
    data: null,
    error: null,
    isFetching: false,
  },
  viewCondition: {
    data: null,
    error: null,
    isFetching: false,
  },
  editCondition: {
    data: null,
    error: null,
    isFetching: false,
  },
};

export const getListData: any = createAsyncThunk(
  'subAffDeals/getListData',
  async (payload: payloadSubAffDealsList, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await subAffDealsService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const deleteConditionData: any = createAsyncThunk(
  'subAffDeals/deleteCondition',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await subAffDealsService.deleteCondition(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.removed',
          })
        );
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getConstantsData: any = createAsyncThunk(
  'subAffDeals/getConstantsData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await subAffDealsService.getConstants();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const createConditionData: any = createAsyncThunk(
  'subAffDeals/createConditionData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await subAffDealsService.createCondition(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getViewConditionData: any = createAsyncThunk(
  'subAffDeals/getViewConditionData',
  async (id: string, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await subAffDealsService.getCondition(id);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue({
          status: response.status,
          statusText: response.statusText,
          data: data,
        })
      }
      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const editConditionData: any = createAsyncThunk(
  'subAffDeals/editConditionData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await subAffDealsService.editCondition(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const subAffDealsSlice = createSlice({
  name: 'subAffDeals',
  initialState: initialState,
  reducers: {
    clearListState(state) {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = null;
    },
    clearDeleteConditionState(state) {
      state.deleteCondition.error = null;
      state.deleteCondition.isFetching = false;
      state.deleteCondition.data = null;
    },
    clearCreateConditionState(state) {
      state.createCondition.error = null;
      state.createCondition.isFetching = false;
      state.createCondition.data = null;
    },
    clearViewConditionState(state) {
      state.viewCondition.error = null;
      state.viewCondition.isFetching = false;
      state.viewCondition.data = null;
    },
    clearConstantsState(state) {
      state.constants.error = null;
      state.constants.isFetching = false;
      state.constants.data = null;
    },
    clearEditConditionState(state) {
      state.editCondition.error = null;
      state.editCondition.isFetching = false;
      state.editCondition.data = null;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<subAffDealsSliceState>>) => {
    builder.addCase(getListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getListData.fulfilled, (state , action) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getListData.rejected, (state , action) => {
      state.list.isFetching = false;
      state.list.error = action.payload;
      state.list.data = null;
    });

    builder.addCase(deleteConditionData.pending, (state ) => {
      state.deleteCondition.isFetching = true;
      state.deleteCondition.error = null;
    });
    builder.addCase(deleteConditionData.fulfilled, (state , action) => {
      state.deleteCondition.error = null;
      state.deleteCondition.isFetching = false;
      state.deleteCondition.data = action.payload;
    });
    builder.addCase(deleteConditionData.rejected, (state , action) => {
      state.deleteCondition.isFetching = false;
      state.deleteCondition.error = action.payload;
      state.deleteCondition.data = null;
    });

    builder.addCase(getConstantsData.pending, (state ) => {
      state.constants.isFetching = true;
      state.constants.error = null;
    });
    builder.addCase(getConstantsData.fulfilled, (state , action) => {
      state.constants.error = null;
      state.constants.isFetching = false;
      state.constants.data = action.payload.constants;
    });
    builder.addCase(getConstantsData.rejected, (state , action) => {
      state.constants.isFetching = false;
      state.constants.error = action.payload;
      state.constants.data = null;
    });

    builder.addCase(createConditionData.pending, (state ) => {
      state.createCondition.isFetching = true;
      state.createCondition.error = null;
    });
    builder.addCase(createConditionData.fulfilled, (state , action) => {
      state.createCondition.error = null;
      state.createCondition.isFetching = false;
      state.createCondition.data = action.payload;
    });
    builder.addCase(createConditionData.rejected, (state , action) => {
      state.createCondition.isFetching = false;
      state.createCondition.error = action.payload;
      state.createCondition.data = null;
    });

    builder.addCase(getViewConditionData.pending, (state ) => {
      state.viewCondition.isFetching = true;
      state.viewCondition.error = null;
    });
    builder.addCase(getViewConditionData.fulfilled, (state , action) => {
      state.viewCondition.error = null;
      state.viewCondition.isFetching = false;
      state.viewCondition.data = action.payload;
    });
    builder.addCase(getViewConditionData.rejected, (state , action) => {
      state.viewCondition.isFetching = false;
      state.viewCondition.error = action.payload;
      state.viewCondition.data = null;
    });

    builder.addCase(editConditionData.pending, (state ) => {
      state.editCondition.isFetching = true;
      state.editCondition.error = null;
    });
    builder.addCase(editConditionData.fulfilled, (state , action) => {
      state.editCondition.error = null;
      state.editCondition.isFetching = false;
      state.editCondition.data = action.payload;
    });
    builder.addCase(editConditionData.rejected, (state , action) => {
      state.editCondition.isFetching = false;
      state.editCondition.error = action.payload;
      state.editCondition.data = null;
    });
  },
});

export const {
  clearListState,
  clearDeleteConditionState,
  clearCreateConditionState,
  clearViewConditionState,
  clearConstantsState,
  clearEditConditionState,
} = subAffDealsSlice.actions;

export default subAffDealsSlice.reducer;
