import { api } from "../../api";
import { handleResponse, RequestOptions} from "../types";
import { getCookie} from "../../helpers/utils";
import { cookieSupervision, cookieToken} from "../../constants";

const getOptions = () => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.webhooks_report.options;

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getReport = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.webhooks_report.generate_report;

  const sendData = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(sendData))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const setDownload = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.webhooks_report.download_report;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};


const getFilters = (data:any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.webhooks_report.filter;

  const url = `${endpoint
    .replace('{filterLabel}', data.label)}`;

  return fetch(
    `${url}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};


const getDetailsReport = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.webhooks_report.details_report;

  const sendData = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(sendData))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getColumns = () => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.webhooks_report.columns;

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

export const webhooksReportService = {
  getOptions,
  getReport,
  setDownload,
  getFilters,
  getDetailsReport,
  getColumns,
};
