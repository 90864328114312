import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import {cookieSupervision, cookieToken} from "../../constants";

const getListData = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.s2s.list
    : api.affiliate.s2s.list;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}


const createWebhook = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.s2s.create
    : api.affiliate.s2s.create;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}


const copyWebhook = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.s2s.copy.replace('{id}', data.id)
    : api.affiliate.s2s.copy.replace('{id}', data.id);

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}


const removeWebhook = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.s2s.remove.replace('{id}', data.id)
    : api.affiliate.s2s.remove.replace('{id}', data.id);

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('DELETE', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}


const activateWebhook = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.s2s.activate.replace('{id}', data.id)
    : api.affiliate.s2s.activate.replace('{id}', data.id);

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('PATCH', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const deactivateWebhook = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.s2s.deactivate.replace('{id}', data.id)
    : api.affiliate.s2s.deactivate.replace('{id}', data.id);

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('PATCH', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}


const getWebhook = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.s2s.webhook.replace('{webhookId}', data.id)
    : api.affiliate.s2s.webhook.replace('{webhookId}', data.id);

  return fetch(`${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const updateUrlOption = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? `${api.supervision.s2s.update_url_option
        .replace('{urlOptionId}', data.id)
        .replace('{idUrlOption}', data.id_url_option)}`
    : `${api.affiliate.s2s.update_url_option
        .replace('{urlOptionId}', data.id)
        .replace('{idUrlOption}', data.id_url_option)}`;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('PUT', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}


const getUrlOption = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.s2s.url_option.replace('{urlOptionId}', data.id)
    : api.affiliate.s2s.url_option.replace('{urlOptionId}', data.id);

  return fetch(`${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}


const createUrlOption = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.s2s.url_option.replace('{urlOptionId}', data.id)
    : api.affiliate.s2s.url_option.replace('{urlOptionId}', data.id);

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}


const removeUrlOption = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? `${api.supervision.s2s.delete_url_option
      .replace('{urlOptionId}', data.id)
      .replace('{idUrlOption}', data.id_url_option)}`
    : `${api.affiliate.s2s.delete_url_option
      .replace('{urlOptionId}', data.id)
      .replace('{idUrlOption}', data.id_url_option)}`;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('DELETE', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const updateWebhook = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision
    ? api.supervision.s2s.webhook.replace('{webhookId}', data.id)
    : api.affiliate.s2s.webhook.replace('{webhookId}', data.id);


  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('PUT', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

export const s2sService = {
  getListData,
  createWebhook,
  copyWebhook,
  removeWebhook,
  activateWebhook,
  deactivateWebhook,
  getWebhook,
  updateUrlOption,
  getUrlOption,
  createUrlOption,
  removeUrlOption,
  updateWebhook,
};
