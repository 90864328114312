//core
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { billingService } from "../../../services/admin/billing.service";
import { setUser } from "../../common/user";
import {
  setCreateCreativeState,
  setGeneralProgressHide,
  setGeneralProgressShow,
  setGeneralSnackbarState
} from "../../common/ui";

export type BillingSliceState = {
  package: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  createUpdatePackage: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  history: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  ngrList: {
    data: any | null,
    error: any,
    isFetching: boolean,
  }
};

const initialState: BillingSliceState = {
  package: {
    data: null,
    error: null,
    isFetching: false,
  },
  createUpdatePackage: {
    data: null,
    error: null,
    isFetching: false,
  },
  history: {
    data: null,
    error: null,
    isFetching: false,
  },
  ngrList: {
    data: null,
    error: null,
    isFetching: false,
  }
};

export const getPackageData: any = createAsyncThunk(
  'adm/billing/getPackageData',
  async (payload:any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await billingService.getPackage(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const createUpdatePackageData: any = createAsyncThunk(
  'adm/billing/createUpdatePackageData',
  async (payload:any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await billingService.createUpdatePackage(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setCreateCreativeState(false));
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getHistoryChangesData: any = createAsyncThunk(
  'adm/billing/getHistoryChangesData',
  async (payload:any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await billingService.getHistory(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getNgrListData: any = createAsyncThunk(
  'adm/billing/getNgrListData',
  async (payload:any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await billingService.getNgrList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

//slice
const billingSlice = createSlice({
  name: 'adm/billing',
  initialState: initialState,
  reducers: {
    clearPackageState(state) {
      state.package.data = null;
      state.package.error = null;
      state.package.isFetching = false;
    },

    clearNgrListState(state) {
      state.ngrList.data = null;
      state.ngrList.error = null;
      state.ngrList.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<BillingSliceState>>) => {
    builder.addCase(getPackageData.pending, (state ) => {
      state.package.isFetching = true;
      state.package.error = null;
    });
    builder.addCase(getPackageData.fulfilled, (state , action) => {
      state.package.error = null;
      state.package.isFetching = false;
      state.package.data = action.payload;
    });
    builder.addCase(getPackageData.rejected, (state , action) => {
      state.package.isFetching = false;
      state.package.error = action.payload;
    });

    builder.addCase(createUpdatePackageData.pending, (state ) => {
      state.createUpdatePackage.isFetching = true;
      state.createUpdatePackage.error = null;
    });
    builder.addCase(createUpdatePackageData.fulfilled, (state , action) => {
      state.createUpdatePackage.error = null;
      state.createUpdatePackage.isFetching = false;
      state.createUpdatePackage.data = action.payload;
    });
    builder.addCase(createUpdatePackageData.rejected, (state , action) => {
      state.createUpdatePackage.isFetching = false;
      state.createUpdatePackage.error = action.payload;
    });

    builder.addCase(getHistoryChangesData.pending, (state ) => {
      state.history.isFetching = true;
      state.history.error = null;
    });
    builder.addCase(getHistoryChangesData.fulfilled, (state , action) => {
      state.history.error = null;
      state.history.isFetching = false;
      state.history.data = action.payload;
    });
    builder.addCase(getHistoryChangesData.rejected, (state , action) => {
      state.history.isFetching = false;
      state.history.error = action.payload;
    });

    builder.addCase(getNgrListData.pending, (state ) => {
      state.ngrList.isFetching = true;
      state.ngrList.error = null;
    });
    builder.addCase(getNgrListData.fulfilled, (state , action) => {
      state.ngrList.error = null;
      state.ngrList.isFetching = false;
      state.ngrList.data = action.payload;
    });
    builder.addCase(getNgrListData.rejected, (state , action) => {
      state.ngrList.isFetching = false;
      state.ngrList.error = action.payload;
    });
  },
});

export default billingSlice.reducer;

export const {
  clearPackageState,
  clearNgrListState,
} = billingSlice.actions;
