import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import {
  ListPayloadType,
} from "../../store/admin/brands";
import { cookieToken } from "../../constants";


const getFilters = () => {
  const authenticity_token = getCookie(cookieToken);
  const endpoint = `${api.admin.brands.filters}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`;

  return fetch(
    `${endpoint}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getList = (data: ListPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.brands.list;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getArchiveList = (data: ListPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.brands.list;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const setCreateBrand = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.brands.create;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const deleteBrand = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.brands.delete.replace('{code}', data.code);

  const payload = {
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('DELETE', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getBrand = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const endpoint = api.admin.brands.get.replace('{code}', `${data.code}`);

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const updateBrand = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.brands.update.replace('{code}', `${data.brand_code}`);

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PATCH', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getCreateOptions = () => {
  const authenticity_token = getCookie(cookieToken);
  const endpoint = api.admin.brands.createOptions;

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const archiveBrand = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  let endpoint;

  if (data.archive) {
    endpoint = api.admin.brands.unarchive.replace('{code}', `${data.code}`);
  } else {
    endpoint = api.admin.brands.archive.replace('{code}', `${data.code}`);
  }

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

export const brandsService = {
  getFilters,
  getList,
  setCreateBrand,
  deleteBrand,
  getBrand,
  updateBrand,
  getCreateOptions,
  archiveBrand,
  getArchiveList,
}
