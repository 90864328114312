//core
import { useDispatch } from "react-redux";
import {
  getListData,
  createWebhook,
  copyWebhook,
  removeWebhook,
  activateWebhook,
  deactivateWebhook,
  getWebhook,
  updateUrlOption,
  getUrlOption,
  createUrlOption,
  removeUrlOption,
  updateWebhook,
  clearCreateState,
} from "./index";

export const useS2s = () => {
  const dispatch = useDispatch();

  const getTableList = (data:any) => dispatch(getListData(data));
  const createNewWebhook = (data:any) => dispatch(createWebhook(data));
  const copyNewWebhook = (data:any) => dispatch(copyWebhook(data));
  const removeCurrentWebhook = (data:any) => dispatch(removeWebhook(data));
  const activateCurrentWebhook = (data:any) => dispatch(activateWebhook(data));
  const deactivateCurrentWebhook = (data:any) => dispatch(deactivateWebhook(data));
  const getCurrentWebhook = (data:any) => dispatch(getWebhook(data));
  const updateCurrentUrlOption = (data:any) => dispatch(updateUrlOption(data));
  const getCurrentUrlOption = (data:any) => dispatch(getUrlOption(data));
  const createNewUrlOption = (data:any) => dispatch(createUrlOption(data));
  const removeCurrentUrlOption = (data:any) => dispatch(removeUrlOption(data));
  const updateCurrentWebhook = (data:any) => dispatch(updateWebhook(data));
  const clearCreateListState = () => dispatch(clearCreateState());

  return {
    getTableList,
    createNewWebhook,
    copyNewWebhook,
    removeCurrentWebhook,
    activateCurrentWebhook,
    deactivateCurrentWebhook,
    getCurrentWebhook,
    updateCurrentUrlOption,
    getCurrentUrlOption,
    createNewUrlOption,
    removeCurrentUrlOption,
    updateCurrentWebhook,
    clearCreateListState,
  }
}
