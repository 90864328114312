import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateRange, DateRangePicker } from "@mui/x-date-pickers-pro";
import {
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
} from "@mui/material";

//icon
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

//locales
import ruLocale from "date-fns/locale/ru";
import ukLocale from "date-fns/locale/uk";
import enLocale from "date-fns/locale/en-US";

//styles
import { useStyles } from "./styles";

const localeMap = {
  en: enLocale,
  ru: ruLocale,
  uk: ukLocale,
};

interface IMuiTwiceDateRangePickerProps {
  startDate: any;
  endDate: any;
  label: string;
  startLabel: string;
  endLabel: string;
  onChange: (start: Date | null, end: Date | null) => void;
  error?: any;
  hideLabel?: boolean;
  hideClear?: boolean;
  fieldClassName?: string;
}

const MuiTwiceDateRangePicker: React.FC<IMuiTwiceDateRangePickerProps> = ({
  startDate,
  endDate,
  startLabel,
  endLabel,
  onChange,
  error = null,
  label = 'common.forms.fields.custom_dates',
  hideLabel = false,
  hideClear = false,
  fieldClassName = '',
}): JSX.Element => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();

  const [locale, setLocale] = useState<object>(localeMap.en);

  const [value, setValue] = React.useState<DateRange<Date>>(() => [
    null,
    null,
  ]);

  useEffect(() => {
    setValue(() => [
      !!startDate ? new Date(startDate) : null,
      !!endDate ? new Date(endDate) : null,
    ]);
  }, [startDate, endDate]);

  useEffect(() => {
    if (i18n.language === 'ru') {
      setLocale(localeMap.ru);
    }

    if (i18n.language === 'en') {
      setLocale(localeMap.en);
    }

    if (i18n.language === 'uk') {
      setLocale(localeMap.uk);
    }
  }, [i18n.language]);

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  return (
    <Box className={styles.field}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        {!hideLabel && (
          <InputLabel shrink={true} sx={{ marginBottom: '4px' }}>
            {t(label)}
          </InputLabel>
        )}
        <DateRangePicker
          className={`${fieldClassName}`}
          localeText={{ start: t(startLabel), end: t(endLabel) }}
          value={value}
          onChange={(newValue: DateRange<Date>) => {
            //@ts-ignore
            onChange(newValue[0], newValue[1]);
            setValue(newValue);
          }}
          maxDate={new Date()}
          slotProps={{
            textField: ({ position }) => ({
              size: 'small',
              onKeyDown: (e) => {
                e.preventDefault();
                e.stopPropagation();
                if (e.key === 'Backspace') {
                  e.preventDefault();
                  e.stopPropagation();
                  return null;
                }
              },
              error: position === 'start' ? !!error : false,
              disabled: true,
              inputProps: {
                readOnly: true,
                disabled: true,
                style: {
                  pointerEvents: 'none',
                  userSelect: 'none',
                },
                onBeforeInput: disableKeyboardEntry,
                onKeyDown: disableKeyboardEntry,
              },
              InputProps: {
                readOnly: true,
                sx: {
                  userSelect: 'none',
                  cursor: 'pointer',
                },
                endAdornment: (<>
                  {!hideClear && (<>
                    {position === 'start' ? (<>
                      {!!value[0] && (
                        <InputAdornment position="end">
                          <IconButton
                            className={'clear'}
                            size="small"
                            onClick={(e) => {
                              e.preventDefault();

                              setValue((prevState) => {
                                onChange(null, prevState[1]);
                                return [ null, prevState[1] ]
                              });

                            }}
                          >
                            <CloseOutlinedIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )}
                    </>) : (<>
                      {!!value[1] && (
                        <InputAdornment position="end">
                          <IconButton
                            className={'clear'}
                            size="small"
                            onClick={(e) => {
                              e.preventDefault();

                              setValue((prevState) => {
                                onChange(prevState[0], null);
                                return [ prevState[0], null ]
                              })
                            }}
                          >
                            <CloseOutlinedIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )}
                    </>)}
                  </>)}
                </>),
              },
            }),
            actionBar: ({ wrapperVariant }) => {
              return {
                actions: wrapperVariant === 'desktop' ? [] : ['accept'],
              }
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default MuiTwiceDateRangePicker;
