//core
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { setUser } from "../../common/user";
import { statisticsApiService } from "../../../services/affiliate/statisticsApi.service";


export interface IStatisticsApiData {
  api_token: string;
  domain: string;
}

export type statisticsApiSliceState = {
  statisticsApi: {
    data: IStatisticsApiData | null,
    error: any,
    isFetching: boolean,
  }
}

const initialState: statisticsApiSliceState = {
  statisticsApi: {
    data: null,
    error: null,
    isFetching: false,
  }
}

export const getStatisticsApiData: any = createAsyncThunk(
  'statisticsApi/getData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await statisticsApiService.getStatisticsApi();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const statisticsApiSlice = createSlice({
  name: 'statisticsApi',
  initialState: initialState,
  reducers: {
    clearStatisticsApiDataState(state) {
      state.statisticsApi.data = null;
      state.statisticsApi.error = null;
      state.statisticsApi.isFetching = false;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<statisticsApiSliceState>>) => {
    builder.addCase(getStatisticsApiData.pending, (state ) => {
      state.statisticsApi.isFetching = true;
      state.statisticsApi.error = null;
    });
    builder.addCase(getStatisticsApiData.fulfilled, (state , action) => {
      state.statisticsApi.error = null;
      state.statisticsApi.isFetching = false;
      state.statisticsApi.data = action.payload;
    });
    builder.addCase(getStatisticsApiData.rejected, (state , action) => {
      state.statisticsApi.isFetching = false;
      state.statisticsApi.error = action.payload;
    });
  }
});

export default statisticsApiSlice.reducer;

export const {
  clearStatisticsApiDataState
} = statisticsApiSlice.actions;
