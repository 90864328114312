import * as Yup from 'yup';

//types
interface IErrors {
  messengerUsernameRequired: string,
  messengerMaxLength: string,
}

function validationSchema(errors: IErrors) {
  return Yup.object().shape({
    messenger_username: Yup.string()
      .min(1, errors.messengerUsernameRequired)
      .max(255, errors.messengerMaxLength)
      .required(errors.messengerUsernameRequired),
  });
}

export const validationMessengerUsername = (val: any, errors: IErrors) => {
  const value = { messenger_username: val };

  const schema = validationSchema(errors);

  try {
    schema.validateSync(value, { abortEarly: false });
    return undefined;
  } catch (error: any) {
    return error.inner[0].errors[0];
  }
};
