import * as Yup from 'yup';

//types
interface IErrors {
  emailError: string,
  emailRequired: string,
}

function validationSchema(errors: IErrors) {
  return Yup.object().shape({
    email: Yup.string()
      .email(errors.emailError)
      .required(errors.emailRequired),
  });
}

export const validationEmail = (val: string, errors: IErrors) => {
  const value = { email: val };

  const schema = validationSchema(errors);

  try {
    schema.validateSync(value, { abortEarly: false });
    return undefined;
  } catch (error: any) {
    return error.inner[0].errors[0];
  }
};
