import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import { cookieToken } from "../../constants";

const getApis = () => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.quints_api.list;

  return fetch(`${endpoint}?authenticity_token=${authenticity_token}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

export const quintsApiService = {
  getApis
};
