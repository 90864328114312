export const favoriteFilterKeys = {
  productIds: 'favAdmSRProductIds',
  platformIds: 'favAdmSRPlatformIds',
  managerIds: 'favAdmSRManagerIds',
  affiliateIds: 'favAdmSRAffiliateIds',
  trackerIds: 'favAdmSRTrackerIds',
  creativeIds: 'favAdmSRCreativeIds',
  brands: 'favAdmSRBrands',
  currencies: 'favAdmSRCurrencies',
  dealTypes: 'favAdmSRDealTypes',
  registrationDates: 'favAdmSRRegDates',
  firstDepositDates: 'favAdmSRFirstDepDates',
  anid: 'favAdmSRAnid',
  playerId: 'favAdmSRPlayerId',
  source1: 'favAdmSRSource1',
  source2: 'favAdmSRSource2',
  source3: 'favAdmSRSource3',
  source4: 'favAdmSRSource4',
  source5: 'favAdmSRSource5',
  deviceTypes: 'favAdmSRDeviceTypes',
}

export const reportStorageProps = {
  type: 'player-and-commission-report',
  target: 'filters',
  tableSettingsTarget: 'table-settings',
  exceptions: ['group_by', 'period', 'sent_from', 'sent_to'],
  exceptionsIds: ['productIds', 'platformIds', 'managerIds', 'affiliateIds', 'trackerIds', 'brands', 'currencies', 'dealTypes', 'deviceTypes'],
  exceptionsStr: ['anid', 'playerId', 'source1', 'source2', 'source3', 'source4', 'source5'],
}
