//core
import {useDispatch, useSelector} from "react-redux";

//selectors
import {selectUi} from "./selectors";

import {
  setGeneralProgressShow,
  setGeneralProgressHide,
  setGeneralSnackbarState,
  setMainNavigationState,
  setNavigationExpandedPanelState,
  setCreateCreativeState,
  setConfirmationEmailSuccessState,
  setConfirmationEmailErrorState,
  setRegistrationSuccessState,
  setAddNewTrackerState,
  setAffTrackingLinkState,
  setAdminAddNewTrackerState,
} from "./index";

export const useUI = () => {
  const dispatch = useDispatch();

  const ui = useSelector(selectUi);

  const setMainNavigation = (data: boolean) => dispatch(setMainNavigationState(data));
  const setNavigationExpandedPanel = (data: string | false) => dispatch(setNavigationExpandedPanelState(data));

  const generalProgressShow = () => dispatch(setGeneralProgressShow());
  const generalProgressHide = () => dispatch(setGeneralProgressHide());

  const setGeneralSnackbar = (data: any) => dispatch(setGeneralSnackbarState(data));
  const setCreateCreative = (data: boolean) => dispatch(setCreateCreativeState(data));

  const setRegistrationSuccess = (data: boolean) => dispatch(setRegistrationSuccessState(data));

  const setConfirmationEmailSuccess = (data: boolean) => dispatch(setConfirmationEmailSuccessState(data));
  const setConfirmationEmailError = (data: boolean) => dispatch(setConfirmationEmailErrorState(data));

  const setAddNewTracker = (data: boolean) => dispatch(setAddNewTrackerState(data));
  const setAdminAddNewTracker = (data: boolean) => dispatch(setAdminAddNewTrackerState(data));

  const setAffTrackingLink = (data: boolean) => dispatch(setAffTrackingLinkState(data));

  return {
    ui,
    generalProgressShow,
    generalProgressHide,
    setMainNavigation,
    setNavigationExpandedPanel,
    setGeneralSnackbar,
    setCreateCreative,
    setConfirmationEmailSuccess,
    setConfirmationEmailError,
    setRegistrationSuccess,
    setAddNewTracker,
    setAffTrackingLink,
    setAdminAddNewTracker,
  }
}
