import { api } from "../../api";
import {handleResponse, RequestOptions} from "../types";
import {getCookie} from "../../helpers/utils";
import {cookieToken} from "../../constants";

const getConfig = () => {
  /*return fetch(`${api.config}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });*/

  return Promise.resolve({
    "domains": {
      "development":  ["localhost", "127.0.0.1"],
      "production":   ["q.pmaffiliates.com"],
      "preprod":      ["aff.pmcorp.loc", "pof.pmaffiliates.com"],
      "prod":         ["pof.pmaffiliates.com"]
    },
    "vars": {
      "development": {
        "apiUrl": "http://127.0.0.1:8087/api2/"
      },
      "production": {
        "apiUrl": "https://api.pmaffiliates.com/api2/"
      },
      "preprod": {
        "apiUrl": "https://aff.pmcorp.loc/api2/"
      },
      "prod": {
        "apiUrl": "https://api2.pmaffiliates.com/api2/"
      }
    }
  })
}

const getCsrfToken = () => {
  return fetch(`${api.csrf_token}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getUploadConfig = () => {
  const authenticity_token = getCookie(cookieToken);

  return fetch(`${api.upload_config}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getPermission = () => {
  const authenticity_token = getCookie(cookieToken);

  return fetch(`${api.permission}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const setLanguage = (lang: string) => {
  const authenticity_token = getCookie(cookieToken);

  const payload = {
    locale: lang,
    authenticity_token: authenticity_token
  };

  //`${api.language}${`?locale=${lang}`}`,

  return fetch(
    `${api.language}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

export const configurationService = {
  getConfig,
  getCsrfToken,
  getUploadConfig,
  getPermission,
  setLanguage,
};
