import React, { useContext, useEffect, useState } from "react";
import arePropsEqual from "react-fast-compare";
import { useSelector } from "react-redux";
import { Checkbox } from "@mui/material";

//context
import { BrandsContext } from "../PaymentPlanForm/components/ConditionBrandsForm/BrandsContext";

//selectors
import {selectConditionBrandsListData} from "../../../../../store/admin/conditions/selectors";



const SelectAllDeals = () => {
    const context = useContext(BrandsContext);
    const data = useSelector(selectConditionBrandsListData);

    const [checked, setChecked] = useState<boolean>(false);

    useEffect(() => {
        if (!context.selectedRows.length) {
            setChecked(false);
        }
    }, [context.selectedRows]);

    useEffect(() => {
        setChecked(false);
    }, [context.updateSelectAllCheckbox]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        if (event.target.checked) {
            const codeList = data.list.reduce((acc:string[], item:any) => {
                acc.push(item.code);
                return acc
            },[])
            const brands:any = [...new Set(context.selectedRows.concat(...codeList))];
            context.setSelectedRows(brands);

        } else {
            const unCheckedRow = data.list.reduce((acc: any[], row:any) => {
                acc = acc.filter((brand:any) => brand !== row.code);
                return acc
            },context.selectedRows);

            context.setSelectedRows(unCheckedRow);
        }
    }

    return (
        <>
            <Checkbox
                size="small"
                sx={{ padding: '4px' }}
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={context.selectedAllBrands}
            />
        </>
    );
};

export default React.memo(SelectAllDeals, arePropsEqual);
