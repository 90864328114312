//core
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, IconButton, Typography } from "@mui/material";

//icons
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

//styles
import { useStyles } from "./styles"

//selectors
import {
  selectPaymentHistoryPaymentDetailsData,
  selectPaymentHistoryPaymentDetailsError
} from "../../../../../store/affiliate/paymentHistory/selectors";

//hooks
import { usePaymentHistory } from "../../../../../store/affiliate/paymentHistory/usePaymentHistory";

//components
import FloatFormat from "../FloatFormat";
import MuiModal from "../../../../UI/MuiModal";
import PaymentDetailsTable from "./components/PaymentDetailsTable";
import ErrorData from "../../../ErrorData";
import PermissionsWrapper from "../../../PermissionsWrapper";
import CircularLoader from "../../../../UI/CircularLoader";
import { itemListData } from "../../index";

interface IAffPaymentDetailsProps {
  data: itemListData;
  value: string | number;
}

const AffPaymentDetails: React.FC<IAffPaymentDetailsProps> = ({
  data,
  value,
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();

  const { getPaymentDetails, clearPaymentDetails } = usePaymentHistory();

  const tableData = useSelector(selectPaymentHistoryPaymentDetailsData);
  const error = useSelector(selectPaymentHistoryPaymentDetailsError);

  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);

  const handleOpenDetails = () => {
    getPaymentDetails({ id: data.id });
    setShowDetailsModal(true);
  };

  const handleToggleModal = (state: boolean) => {
    setShowDetailsModal(state);
    setTimeout(() => {
      clearPaymentDetails();
    }, 225);
  };

  return (
    <>
      <Box className={styles.box}>
        <FloatFormat value={value} />
        <PermissionsWrapper permissions={["api3.affiliate.paymenthistorycontroller.payment_details"]}>
          {value != '0.00' && (
            <IconButton
              aria-label="details"
              size="small"
              color="primary"
              onClick={handleOpenDetails}
            >
              <RemoveRedEyeIcon fontSize="small" />
            </IconButton>
          )}
        </PermissionsWrapper>
      </Box>

      <MuiModal
        width={856}
        open={showDetailsModal}
        closeOnOutside={false}
        setToggleModal={handleToggleModal}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t("affiliate.payment_history.payment_details")}
        </Typography>

        {!!tableData ? (
          <PaymentDetailsTable
            data={tableData}
            toggleModal={handleToggleModal}
          />
        ) : (
          <CircularLoader size={32} />
        )}

        {
          !!error && <ErrorData title={error.status} text={error.detail} elevation={0} />
        }
      </MuiModal>
    </>
  );
};

export default AffPaymentDetails;
