//core
import {useDispatch, useSelector} from "react-redux";

//types
import {
  creativesSliceState,
  getFormData,
  createCreative,
  getCreativeData,
  updateCreativeData,
  getListData,
  getFilterData,
  deleteCreative,
  toggleHideCreative,
  setCreativeInitialData,
  setCreativesListData,
  clearCreativesListState,
  getBrandsListData,
  copyCreativeData,
  clearCopyState,
  clearUpdateState,
  clearCreateState,
  clearCreativeDetailsState,
  getCreativeDetailsData,
  getMirrorForBrandData,
  activateMirrorForBrand,
  clearBrandsListState,
  clearFormDataState,
  getRulesBrandsListData,
  clearRulesBrandsListState,
  getCreativesOptionsData,
  clearCreativesOptionsState,
  clearDeleteState,
} from "./index";

export type stateProfile = {
  admin: {
    creatives: creativesSliceState
  }
};

export const useCreatives = () => {
  const dispatch = useDispatch();

  const { form, create, edit, update } = useSelector((state: stateProfile) => state.admin.creatives);

  const getFormFields = () => dispatch(getFormData());
  const clearFormFieldsData = () => dispatch(clearFormDataState());
  const postCreateCreative = (data: any) => dispatch(createCreative(data));
  const getCreative = (data: string) => dispatch(getCreativeData(data));
  const clearCreativesList = () => dispatch(clearCreativesListState());
  const setCreativesList = (data: any) => dispatch(setCreativesListData(data));
  const deleteCreativeById = (data: string) => dispatch(deleteCreative(data));
  const updateCreative = (data: any) => dispatch(updateCreativeData(data));
  const getList = (data: any) => dispatch(getListData(data));
  const getFilter = () => dispatch(getFilterData());
  const updateCreativeHideField = (data: any) => dispatch(toggleHideCreative(data));
  const getBrandsList = (data: any) => dispatch(getBrandsListData(data));
  const getRulesBrandsList = (data: any) => dispatch(getRulesBrandsListData(data));
  const copyCreative = (data: any) => dispatch(copyCreativeData(data));
  const getCreativeDetails = (data: any) => dispatch(getCreativeDetailsData(data));
  const getMirror = (data: any) => dispatch(getMirrorForBrandData(data));
  const activateMirror = (data: any) => dispatch(activateMirrorForBrand(data));

  const clearCreativeData = () => dispatch(setCreativeInitialData());
  const clearCopyDate = () => dispatch(clearCopyState());
  const clearDeleteData = () => dispatch(clearDeleteState());

  const clearUpdate = () => dispatch(clearUpdateState());
  const clearCreate = () => dispatch(clearCreateState());
  const clearCreativeDetails = () => dispatch(clearCreativeDetailsState());
  const clearBrandsList = () => dispatch(clearBrandsListState());
  const clearRulesBrandsList = () => dispatch(clearRulesBrandsListState());

  const getCreativesOptions = (data: any) => dispatch(getCreativesOptionsData(data));
  const clearCreativesOptions = () => dispatch(clearCreativesOptionsState());

  return {
    form,
    create,
    edit,
    update,
    getFormFields,
    postCreateCreative,
    getCreative,
    updateCreative,
    getFilter,
    getList,
    deleteCreativeById,
    clearCreativeData,
    updateCreativeHideField,
    setCreativesList,
    getBrandsList,
    copyCreative,
    clearCopyDate,
    clearUpdate,
    clearCreate,
    getCreativeDetails,
    clearCreativeDetails,
    getMirror,
    activateMirror,
    clearBrandsList,
    clearCreativesList,
    clearFormFieldsData,
    getRulesBrandsList,
    clearRulesBrandsList,
    getCreativesOptions,
    clearCreativesOptions,
    clearDeleteData,
  }
}
