import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import {
  CommunicationCreatePayloadType,
  CommunicationDeletePayloadType,
  CommunicationHistoryPayloadType,
  CommunicationUpdatePayloadType,
  ProfilePayloadType,
  TrafficStatusUpdatePayloadType, UpdateProfileChiefsPayloadType,
  UpdateProfilePayloadType,
} from "../../store/admin/affiliateProfile";
import { cookieSupervision, cookieToken } from "../../constants";

const getCommunicationHistory = (data: CommunicationHistoryPayloadType) => {
  const endpoint = api.admin.affiliateProfile.communication.list.replace(
    '{affiliateId}',
    data.affiliateId.toString()
  );

  const payload = {
    ...data.data,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const createCommunicationStatus = (data: CommunicationCreatePayloadType) => {
  const endpoint = api.admin.affiliateProfile.communication.create.replace(
    '{affiliateId}',
    data.affiliateId.toString()
  );

  const payload = {
    ...data.data,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const deleteCommunicationStatus = (data: CommunicationDeletePayloadType) => {
  const endpoint = api.admin.affiliateProfile.communication.delete
    .replace('{affiliateId}', data.affiliateId.toString())
    .replace('{communicationId}', data.communicationId.toString());

  return fetch(
    `${endpoint}`,
    {...RequestOptions('DELETE')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const updateCommunicationStatus = (data: CommunicationUpdatePayloadType) => {
  const endpoint = api.admin.affiliateProfile.communication.update
    .replace('{affiliateId}', data.affiliateId.toString())
    .replace('{communicationId}', data.communicationId.toString());

  const payload = {
    date: data.data.date,
    notes: data.data.notes,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const updateTrafficStatus = (data: TrafficStatusUpdatePayloadType) => {
  const endpoint = api.admin.affiliateProfile.trafficStatus.update
    .replace('{affiliateId}', data.affiliateId.toString());

  const payload = {
    ...data.data,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PATCH', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getProfile = (data: ProfilePayloadType) => {
  const endpoint = api.admin.affiliateProfile.get.replace(
    '{affiliateId}',
    data.affiliateId.toString()
  );

  return fetch(
    `${endpoint}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const updateProfile = (data: UpdateProfilePayloadType) => {
  const endpoint = api.admin.affiliateProfile.update
    .replace('{affiliateId}', data.affiliateId.toString());

  const payload = {
    ...data.data,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PATCH', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const updateChiefs = (data: UpdateProfileChiefsPayloadType) => {
  const endpoint = api.admin.affiliateProfile.updateChiefs
    .replace('{affiliateId}', data.affiliateId.toString());

  const payload = {
    ...data.data,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PATCH', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};


const getPaymentList = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.affiliateProfile.payment.list.replace('{affiliateId}', `${data.affId}`);

  return fetch(`${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getPaymentMethods = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.affiliateProfile.payment.methods.replace('{affiliateId}', `${data.affId}`);;

  return fetch(`${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}
const postPaymentMethod = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.affiliateProfile.payment.create
    .replace('{affiliateId}', `${data.affId}`);

  const payload = {
    ...data.payload,
    authenticity_token: authenticity_token,
  }

  const headers = {
    ...(data.hasOwnProperty('auth_type') ? {"auth-type": data.auth_type} : {}),
    ...(data.hasOwnProperty('auth_code') ? {"auth-code": data.auth_code} : {}),
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload), headers)}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const deletePaymentMethod = (data: any) => {
  const endpoint = api.admin.affiliateProfile.payment.delete
    .replace('{affiliateId}', `${data.affId}`)
    .replace('{id}', `${data.id}`);

  const headers = {
    ...(data.hasOwnProperty('auth_type') ? {"auth-type": data.auth_type} : {}),
    ...(data.hasOwnProperty('auth_code') ? {"auth-code": data.auth_code} : {}),
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('DELETE', '', headers)}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const updatePaymentMethod = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.affiliateProfile.payment.update
    .replace('{affiliateId}', `${data.affId}`)
    .replace('{id}', `${data.id}`);

  const payload = {
    ...data.payload,
    authenticity_token: authenticity_token,
  }

  const headers = {
    ...(data.hasOwnProperty('auth_type') ? {"auth-type": data.auth_type} : {}),
    ...(data.hasOwnProperty('auth_code') ? {"auth-code": data.auth_code} : {}),
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PUT', JSON.stringify(payload), headers)}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getPaymentHistory = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.affiliateProfile.payment.history
    .replace('{affiliateId}', `${data.affId}`);

  const payload = {
    ...data.payload,
    authenticity_token: authenticity_token,
  }

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}


export const affProfileService = {
  getCommunicationHistory,
  createCommunicationStatus,
  deleteCommunicationStatus,
  updateCommunicationStatus,
  updateTrafficStatus,
  getProfile,
  updateProfile,
  updateChiefs,
  getPaymentList,
  getPaymentMethods,
  postPaymentMethod,
  deletePaymentMethod,
  updatePaymentMethod,
  getPaymentHistory,
}
