import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import {
  ListPayloadType,
  CreatePayloadType,
  UpdatePayloadType,
  DeletePayloadType,
  ReadPayloadType,
  LabelsPayloadType,
} from "../../store/common/storage";
import { cookieSupervision, cookieToken } from "../../constants";

const getList = (data: ListPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.storage.list
    .replace('{{type}}', data.type)
    .replace('{{target}}', data.target);

  const payload = {
    ...data.payload,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const create = (data: CreatePayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.storage.create
    .replace('{{type}}', data.type)
    .replace('{{target}}', data.target);

  const payload = {
    ...data.payload,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const update = (data: UpdatePayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.storage.update
    .replace('{{type}}', data.type)
    .replace('{{target}}', data.target)
    .replace('{{id}}', `${data.id}`);

  const payload = {
    ...data.payload,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PATCH', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const remove = (data: DeletePayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.storage.delete
    .replace('{{type}}', data.type)
    .replace('{{target}}', data.target)
    .replace('{{id}}', `${data.id}`);

  return fetch(
    `${endpoint}?authenticity_token=${authenticity_token}`,
    {...RequestOptions('DELETE')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getById = (data: ReadPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.storage.get
    .replace('{{type}}', data.type)
    .replace('{{target}}', data.target)
    .replace('{{id}}', `${data.id}`);

  return fetch(
    `${endpoint}?authenticity_token=${authenticity_token}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      response => {
        return response.json();
      }
    )
    .catch((error) => {
      return error
    });
};

const getLabels = (data: LabelsPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.storage.labels;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      response => {
        return response.json();
      }
    )
    .catch((error) => {
      return error
    });
};

export const storageService = {
  getList,
  create,
  update,
  remove,
  getById,
  getLabels,
}
