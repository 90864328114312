export const favoriteFilterKeys = {
  affiliateIds: 'favCRAffiliateIds',
  dealTypes: 'favCRDealTypes',
  trackerIds: 'favCRTrackerIds',
  creativeIds: 'favCRCreativeIds',
  geo: 'favCRGeo',
}

export const reportStorageProps = {
  type: 'creatives-report',
  target: 'filters',
  tableSettingsTarget: 'table-settings',
  exceptions: ['group_by', 'period', 'sent_from', 'sent_to'],
  exceptionsIds: ['affiliateIds', 'dealTypes', 'trackerIds', 'creativeIds', 'geo'],
}
