//core
import React, { useEffect } from "react";
import { useFormikContext, Field } from "formik";

//hooks
import useDidMountEffect from "../../../../../../customHooks/useDidMountEffect";

//utils
import { getStatusById } from "./utils";

interface IHelperFieldProps  {
  name: string;
  options: any[];
  status: string;
}

const HelperField: React.FC<IHelperFieldProps> = ({
  name,
  options,
  status,
}): JSX.Element => {
  const {
    setFieldValue,
    values,
  } = useFormikContext();

  useDidMountEffect(() => {
    if (!!status) {
      const selectedStatus = getStatusById(options, status);
      setFieldValue('send_email', selectedStatus.send_email)
    } else {
      setFieldValue('send_email', false);
    }
  }, [status])

  return (
    <>
      <Field name={name} hidden />
    </>
  );
};

export default HelperField;
