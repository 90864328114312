//core
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";

//service
import {quintsApiService} from "../../../services/admin/quintsApi.service";
import {setGeneralProgressHide} from "../../common/ui";
import {setUser} from "../../common/user";

export type quintsApiSliceState = {
  list: {
    data: any,
    error: any,
    isFetching: boolean,
  }
}

const initialState: quintsApiSliceState = {
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
}

export const getApiListData: any = createAsyncThunk(
  'getApiListData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await quintsApiService.getApis();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);


//slice
const quintsApiSlice = createSlice({
  name: 'quints_api',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<quintsApiSliceState>>) => {
    builder.addCase(getApiListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getApiListData.fulfilled, (state, action ) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getApiListData.rejected, (state, action ) => {
      state.list.error = action.payload;
    });
  },
});

export default quintsApiSlice.reducer;

export const {
  reset
} = quintsApiSlice.actions;
