import * as Yup from 'yup';

interface IErrors {
  nameRequired: string,
  maxLength: string,
  maxLengthText?: string;
}

function validationSchema(errors: IErrors) {
  return Yup.object().shape({
    name: Yup.string()
      .min(1, errors.nameRequired)
      .max(255, errors.maxLengthText || errors.maxLength)
      .required(errors.nameRequired),
  });
}

export const validationName = (val: any, errors: IErrors) => {
  const value = { name: val };

  const schema = validationSchema(errors);

  try {
    schema.validateSync(value, { abortEarly: false });
    return undefined;
  } catch (error: any) {
    return error.inner[0].errors[0];
  }
};
