//core
import React from "react";
import {Theme} from "@mui/material/styles/createTheme";
import {makeStyles} from "@mui/styles";
import {
  Card,
  Typography
} from "@mui/material";

//icon
import {NoDataIcon} from "../../UI/Icons";

const useStyles = makeStyles<Theme>((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "200px",
    width: '100%',
    overflowX: 'auto',
    borderRadius: '0',
    paddingLeft: 16,
    paddingRight: 16,
  },
  img: {
    width: '60px',
    height: '60px',
    margin: '0 0 12px',
    color: theme.palette.primary.main,
  },
  text: {},
}));

interface IErrorDataProps {
  title: string;
  text?: string;
  elevation?: number
}

const ErrorData = (props: IErrorDataProps) => {
  const styles = useStyles();

  const {
    title,
    text,
    elevation = 2,
  } = props;

  return (
    <Card className={styles.card} elevation={elevation}>
      <NoDataIcon className={styles.img} />
      <Typography sx={{mb: 1}} variant="h6" component="div">{title}</Typography>
      {
        text && <Typography variant="body2" component="p">{text}</Typography>
      }
    </Card>
  );
};

export default ErrorData;
