//core
import { createSelector } from "@reduxjs/toolkit";

//store
import {RootState} from "../../index";
import {getMirrorForBrandData} from "./index";

const creativeCreateDataSelector = (state: RootState) => state.admin.creatives.create.data;

const creativesListSelector = (state: RootState) => state.admin.creatives.list;
const creativesListDataSelector = (state: RootState) => state.admin.creatives.list.data;
const creativesListErrorSelector = (state: RootState) => state.admin.creatives.list.error;
const creativesListFetchingSelector = (state: RootState) => state.admin.creatives.list.isFetching;

const creativesFilterSelector = (state: RootState) => state.admin.creatives.filter;
const creativesFilterDataSelector = (state: RootState) => state.admin.creatives.filter.data;
const creativesFilterErrorSelector = (state: RootState) => state.admin.creatives.filter.error;
const creativesFilterFetchingSelector = (state: RootState) => state.admin.creatives.filter.isFetching;

const creativesDeleteDataSelector = (state: RootState) => state.admin.creatives.delete.data;

const creativesToggleHideDataSelector = (state: RootState) => state.admin.creatives.toggleHide.data;
const creativesToggleHideErrorSelector = (state: RootState) => state.admin.creatives.toggleHide.error;
const creativesToggleHideFetchingSelector = (state: RootState) => state.admin.creatives.toggleHide.isFetching;

const creativeCopyDataSelector = (state: RootState) => state.admin.creatives.copy.data;

const creativesBrandsListDataSelector = (state: RootState) => state.admin.creatives.brands.data;
const creativesBrandsListErrorSelector = (state: RootState) => state.admin.creatives.brands.error;
const creativesBrandsListFetchingSelector = (state: RootState) => state.admin.creatives.brands.isFetching;

const creativesCreateDataSelector = (state: RootState) => state.admin.creatives.create.data;
const creativesCreateErrorSelector = (state: RootState) => state.admin.creatives.create.error;
const creativesCreateFetchingSelector = (state: RootState) => state.admin.creatives.create.isFetching;

const creativesUpdateDataSelector = (state: RootState) => state.admin.creatives.update.data;
const creativesUpdateErrorSelector = (state: RootState) => state.admin.creatives.update.error;
const creativesUpdateFetchingSelector = (state: RootState) => state.admin.creatives.update.isFetching;

const creativesDetailsDataSelector = (state: RootState) => state.admin.creatives.details.data;

const creativesFormDataSelector = (state: RootState) => state.admin.creatives;

const creativesMirrorBrandsDataSelector = (state: RootState) => state.admin.creatives.mirrors.data;

const creativesCreateFormDataSelector = (state: RootState) => state.admin.creatives.form.data;

export const selectCreativeCreateData = createSelector(creativeCreateDataSelector, (data) => data);

export const selectCreativesList = createSelector(creativesListSelector, (list) => list);
export const selectCreativesListData = createSelector(creativesListDataSelector, (data) => {
  if (!!data) {
    const columns = data.columns.map((item: any) => {
      if (item.name === 'geo') {
        return { ...item, type: 'countriesArray'};
      }
      if (item.name === 'brands') {
        return { ...item, type: 'brandsArray'};
      }
      if (item.name === 'offerId') {
        return { ...item, type: 'text'};
      }

      return item;
    });

    return { ...data, columns: columns };
  } else {
    return data;
  }
});
export const selectCreativesListDataColumnsSort = createSelector(creativesListDataSelector, (data) => data?.columns.filter((el) => el.sort === true));
export const selectCreativesListError = createSelector(creativesListErrorSelector, (error) => error);
export const selectCreativesListIsFetching = createSelector(creativesListFetchingSelector, (isFetching) => isFetching);

export const selectCreativesFilter = createSelector(creativesFilterSelector, (filter) => filter);
export const selectCreativesFilterData = createSelector(creativesFilterDataSelector, (data) => {
  if (!!data) {
    const brandsOptions = data.brands.options.map((item: any) => {
      return {
        id: item.id,
        label: `${item.label}-${item.id}`
      }
    });

    return {
      ...data,
      brands: {
        ...data.brands,
        options: brandsOptions,
      }
    };
  } else {
    return data;
  }
});
export const selectCreativesFilterError = createSelector(creativesFilterErrorSelector, (error) => error);
export const selectCreativesFilterIsFetching = createSelector(creativesFilterFetchingSelector, (isFetching) => isFetching);

export const selectDeleteCreativesData = createSelector(creativesDeleteDataSelector, (data) => data);

export const selectCreativesToggleHideData = createSelector(creativesToggleHideDataSelector, (data) => data);
export const selectCreativesToggleHideError = createSelector(creativesToggleHideErrorSelector, (error) => error);
export const selectCreativesToggleHideIsFetching = createSelector(creativesToggleHideFetchingSelector, (isFetching) => isFetching);

export const selectCreativesBrandsListData = createSelector(creativesBrandsListDataSelector, (data) => {
  if (!!data) {
    const brandsOptions = data.list.map((item: any) => {
      return {
        id: item.id,
        value: `${item.value}-${item.id}`
      }
    });

    return {
      ...data,
      list: brandsOptions,
    };
  } else {
    return data;
  }
});
export const selectCreativesBrandsListError = createSelector(creativesBrandsListErrorSelector, (error) => error);
export const selectCreativesBrandsListIsFetching = createSelector(creativesBrandsListFetchingSelector, (isFetching) => isFetching);

export const selectCreativeCopyData = createSelector(creativeCopyDataSelector, (data) => data);

export const selectCreativesCreateData = createSelector(creativesCreateDataSelector, (data) => data);
export const selectCreativesCreateError = createSelector(creativesCreateErrorSelector, (error) => error);
export const selectCreativesCreateIsFetching = createSelector(creativesCreateFetchingSelector, (isFetching) => isFetching);

export const selectCreativesUpdateData = createSelector(creativesUpdateDataSelector, (data) => data);
export const selectCreativesUpdateError = createSelector(creativesUpdateErrorSelector, (error) => error);
export const selectCreativesUpdateIsFetching = createSelector(creativesUpdateFetchingSelector, (isFetching) => isFetching);

export const selectCreativesDetailsData = createSelector(creativesDetailsDataSelector, (data) => {
  if (!!data) {
    const columns = data.columns.map((item: any) => {
      if (item.name === 'geo') {
        return { ...item, type: 'geoArray'};
      }
      if (item.name === 'brands') {
        return { ...item, type: 'brandsArray'};
      }
      if (item.name === 'offerId') {
        return { ...item, type: 'text'};
      }

      return item;
    });

    return { ...data, columns: columns };
  } else {
    return data;
  }
});

export const selectCreativesFormatData = createSelector(creativesFormDataSelector, (data) => {
  if(!!data.form.data) {
    return {
      ...data.form.data.format,
      options: data.form.data.format.options.filter(option => option.id === 'link' || option.id === 'link_with_mirror')
    }} else {
    return data;
  }
});

export const selectCreativesMirrorBrandsData = createSelector(creativesMirrorBrandsDataSelector, (data) => data);

export const selectCreativesCreateFormData = createSelector(creativesCreateFormDataSelector, (data) => data);
