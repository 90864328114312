//core
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { offersService } from "../../../services/admin/offers.service";
import { setUser } from "../../common/user";
import {
  setCreateCreativeState,
  setGeneralProgressHide,
  setGeneralProgressShow,
  setGeneralSnackbarState
} from "../../common/ui";


export type ListItemData =  {
  id: number;
  name: string;
  username: string;
  status: string;
  [key: string]: any;
};

export type ColumnsItemData = {
  name: string;
  type: string;
  sort: boolean;
  match?: boolean;
};

export type OffersListData = {
  list: ListItemData[];
  columns: ColumnsItemData[];
  start_row_num: number;
  end_row_num: number;
  total_entries: number | string;
  total_pages: number;
};

export type ListPayloadType = {
  filter: {
    [key: string]: any,
    search: string | null;
  };
  order: {
    [key: string]: 'asc' | 'desc'
  };
  page: number;
  perPage: number;
};

export type IStatusOption = {
  id: number;
  label: string;
};

export type IPrivacyLevelOption = {
  id: number;
  label: string;
}


export type OffersSliceState = {
  list: {
    data: OffersListData | null,
    error: any,
    isFetching: boolean,
  },
  filters: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  create: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  delete: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  offer: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  update: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  createOptions: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  copy: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
};

const initialState: OffersSliceState = {
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  filters: {
    data: null,
    error: null,
    isFetching: false,
  },
  create: {
    data: null,
    error: null,
    isFetching: false,
  },
  delete: {
    data: null,
    error: null,
    isFetching: false,
  },
  offer: {
    data: null,
    error: null,
    isFetching: false,
  },
  update: {
    data: null,
    error: null,
    isFetching: false,
  },
  createOptions: {
    data: null,
    error: null,
    isFetching: false,
  },
  copy: {
    data: null,
    error: null,
    isFetching: false,
  },
};

export const getFiltersData: any = createAsyncThunk(
  'adm/offers/getFiltersData',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await offersService.getFilters();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getListData: any = createAsyncThunk(
  'adm/offers/getListData',
  async (payload: ListPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await offersService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const createOfferData: any = createAsyncThunk(
  'adm/offers/create',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await offersService.setCreateOffer(payload);
      const data = await response.json();

      if (response.ok && response.status === 201) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.created',
          })
        );
        dispatch(setCreateCreativeState(false));
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const deleteOfferData: any = createAsyncThunk(
  'adm/offers/delete',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await offersService.deleteOffer(payload);
      const data = await response.json();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.removed',
          })
        );
        dispatch(setCreateCreativeState(false));
      }

      if (!response.ok) {
        const message = data?.errors?.message || '';

        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            ...(!!message ? {
              message: message,
              messageKey: '',
            } : {
              message: 'error',
              messageKey: `common.messages.error`,
            })
          })
        );

        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const copyOfferData: any = createAsyncThunk(
  'adm/offers/copy',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await offersService.copyOffer(payload);
      const data = await response.json();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.copied',
          })
        );
        dispatch(setCreateCreativeState(false));
      }

      if (!response.ok && data.hasOwnProperty('error') && data.error === 'offer.brand.brand_mismatch' ) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.not_available_brand',
          })
        );
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      if (!response.ok) {
        const message = data?.errors?.message || '';

        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            ...(!!message ? {
              message: message,
              messageKey: '',
            } : {
              message: 'error',
              messageKey: `common.messages.error`,
            })
          })
        );

        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getOfferData: any = createAsyncThunk(
  'adm/offers/getOfferData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await offersService.getOffer(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const updateOfferData: any = createAsyncThunk(
  'adm/offers/updateOfferData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await offersService.updateOffer(payload);
      const data = await response.json();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.edited',
          })
        );
        dispatch(setCreateCreativeState(false));
      }

      if (!response.ok && data.hasOwnProperty('error') && data.error === 'offer.brand.brand_mismatch' ) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.not_available_brand',
          })
        );
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getCreateOptionsData: any = createAsyncThunk(
  'adm/offers/getCreateOptionsData',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await offersService.getCreateOptions();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

//slice
const offersSlice = createSlice({
  name: 'adm/offers',
  initialState: initialState,
  reducers: {
    clearListState(state) {
      state.list.data = null;
      state.list.error = null;
      state.list.isFetching = false;
    },
    clearCreateState(state) {
      state.create.data = null;
      state.create.error = null;
      state.create.isFetching = false;
    },
    clearDeleteState(state) {
      state.delete.data = null;
      state.delete.error = null;
      state.delete.isFetching = false;
    },
    clearOfferState(state) {
      state.offer.data = null;
      state.offer.error = null;
      state.offer.isFetching = false;
    },
    clearUpdateState(state) {
      state.update.data = null;
      state.update.error = null;
      state.update.isFetching = false;
    },
    clearCreateOptionsState(state) {
      state.update.data = null;
      state.update.error = null;
      state.update.isFetching = false;
    },
    clearCopyState(state) {
      state.copy.data = null;
      state.copy.error = null;
      state.copy.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<OffersSliceState>>) => {
    builder.addCase(getFiltersData.pending, (state ) => {
      state.filters.isFetching = true;
      state.filters.error = null;
    });
    builder.addCase(getFiltersData.fulfilled, (state , action) => {
      state.filters.error = null;
      state.filters.isFetching = false;
      state.filters.data = action.payload;
    });
    builder.addCase(getFiltersData.rejected, (state , action) => {
      state.filters.isFetching = false;
      state.filters.error = action.payload;
    });

    builder.addCase(getListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getListData.fulfilled, (state , action) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getListData.rejected, (state , action) => {
      state.list.isFetching = false;
      state.list.error = action.payload;
    });

    builder.addCase(createOfferData.pending, (state ) => {
      state.create.isFetching = true;
      state.create.error = null;
    });
    builder.addCase(createOfferData.fulfilled, (state , action) => {
      state.create.error = null;
      state.create.isFetching = false;
      state.create.data = action.payload;
    });
    builder.addCase(createOfferData.rejected, (state , action) => {
      state.create.isFetching = false;
      state.create.error = action.payload;
    });

    builder.addCase(deleteOfferData.pending, (state ) => {
      state.delete.isFetching = true;
      state.delete.error = null;
    });
    builder.addCase(deleteOfferData.fulfilled, (state , action) => {
      state.delete.error = null;
      state.delete.isFetching = false;
      state.delete.data = action.payload;
    });
    builder.addCase(deleteOfferData.rejected, (state , action) => {
      state.delete.isFetching = false;
      state.delete.error = action.payload;
    });

    builder.addCase(getOfferData.pending, (state ) => {
      state.offer.isFetching = true;
      state.offer.error = null;
    });
    builder.addCase(getOfferData.fulfilled, (state , action) => {
      state.offer.error = null;
      state.offer.isFetching = false;
      state.offer.data = action.payload;
    });
    builder.addCase(getOfferData.rejected, (state , action) => {
      state.offer.isFetching = false;
      state.offer.error = action.payload;
    });

    builder.addCase(updateOfferData.pending, (state ) => {
      state.update.isFetching = true;
      state.update.error = null;
    });
    builder.addCase(updateOfferData.fulfilled, (state , action) => {
      state.update.error = null;
      state.update.isFetching = false;
      state.update.data = action.payload;
    });
    builder.addCase(updateOfferData.rejected, (state , action) => {
      state.update.isFetching = false;
      state.update.error = action.payload;
    });

    builder.addCase(getCreateOptionsData.pending, (state ) => {
      state.createOptions.isFetching = true;
      state.createOptions.error = null;
    });
    builder.addCase(getCreateOptionsData.fulfilled, (state , action) => {
      state.createOptions.error = null;
      state.createOptions.isFetching = false;
      state.createOptions.data = action.payload;
    });
    builder.addCase(getCreateOptionsData.rejected, (state , action) => {
      state.createOptions.isFetching = false;
      state.createOptions.error = action.payload;
    });

    builder.addCase(copyOfferData.pending, (state ) => {
      state.copy.isFetching = true;
      state.copy.error = null;
    });
    builder.addCase(copyOfferData.fulfilled, (state , action) => {
      state.copy.error = null;
      state.copy.isFetching = false;
      state.copy.data = action.payload;
    });
    builder.addCase(copyOfferData.rejected, (state , action) => {
      state.copy.isFetching = false;
      state.copy.error = action.payload;
    });
  },
});

export default offersSlice.reducer;

export const {
  clearListState,
  clearCreateState,
  clearDeleteState,
  clearOfferState,
  clearUpdateState,
  clearCreateOptionsState,
  clearCopyState,
} = offersSlice.actions;
