//core
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MenuItem,
  Collapse,
} from "@mui/material";

import {hasActiveItem, IItemOption} from "./index";

//utils
import { getGroupByOptionsByArr } from "../../../utils";
import {ArrowDropDown} from "@mui/icons-material";
import {FieldArrow} from "./styles";
//import {IFilterData} from "../DesktopReport";

interface IExpandedMenuItemProps {
  name: string;
  options: string | string[],
  id: string | number;
  selected: IItemOption[];
  setFilter: (props: any) => void;
  key?: string | number;
  prefix?: string;
}

const ExpandedMenuItem: React.FC<IExpandedMenuItemProps> = ({
  name,
  options,
  id,
  selected,
  prefix = '',
  setFilter,
}): JSX.Element => {
  const { t } = useTranslation();

  const [show, setShow] = useState<boolean>(false);
  const [optionsList, setOptionsList] = useState<IItemOption[]>(getGroupByOptionsByArr(typeof options === 'object' ? options : []));

  const handleShow = () => {
    setShow((prevState) => !prevState);
  }

  const handleChangeOption = (item: IItemOption) => {
    const newSelected = selected.reduce((acc: IItemOption[], el: IItemOption) => {
      const hasItem = options.includes(`${el.id}`);

      if (!hasItem) {
        acc.push(el);
      }

      return acc;
    }, []);

    setFilter([
      ...newSelected,
      item,
    ]);
  }

  return (
    <>
      <MenuItem
        sx={{
          minHeight: '36px',
        }}
        onClick={handleShow}
      >
        {prefix ? t(`${prefix}${id}`) : id}
        <FieldArrow isOpen={show}>
          <ArrowDropDown />
        </FieldArrow>
      </MenuItem>
      <Collapse in={show} collapsedSize={0}>
        {
          optionsList.map((item) => {
            return (
              <MenuItem
                key={item.id}
                sx={{
                  padding: '6px 16px 6px 24px',
                  minHeight: '36px',
                }}
                selected={hasActiveItem(item.id, selected)}
                onClick={() => handleChangeOption(item)}
              >
                {prefix ? t(`${prefix}${item.value}`) : item.value}
              </MenuItem>
            )
          })
        }
      </Collapse>
    </>
  );
};

export default ExpandedMenuItem;
