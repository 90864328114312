//core
import React, { useState } from "react";
import arePropsEqual from "react-fast-compare";
import { SxProps } from "@mui/system";
import { Switch, Theme } from "@mui/material";

//actions
import { useS2s } from "../../../../../store/affiliate/s2s/useS2s";


export type itemListData = {
    [key: string]: any;
}

interface ISwitcherProps {
    value: boolean;
    webhook: itemListData;
    sx?: SxProps<Theme>;
}

const Switcher: React.FC<ISwitcherProps> = ({
    value,
    webhook,
    sx = {}
}): JSX.Element => {
    const { activateCurrentWebhook, deactivateCurrentWebhook } = useS2s();

    const [checked, setChecked] = useState<boolean>(value);


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setChecked(event.target.checked);

        const payload = {
            id: webhook.id,
        }
        if (event.target.checked) {
            activateCurrentWebhook(payload)
        } else {
            deactivateCurrentWebhook(payload)
        }
    };


    return (
        <Switch
            sx={sx}
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />
    );
};

export default React.memo(Switcher, arePropsEqual);
