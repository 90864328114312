import { api } from "../../api";
import {handleResponse, RequestOptions} from "../types";
import {getCookie} from "../../helpers/utils";
import {cookieToken} from "../../constants";

const getStatus = () => {
  const authenticity_token = getCookie(cookieToken);

  return fetch(`${api.applicant.status}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}


export const statusService = {
  getStatus
};
