import React, { useState, useEffect, useCallback } from "react";
import arePropsEqual from "react-fast-compare";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import EditForm from "./EditForm";

//styles
import { useStyles } from "./styles";

import { itemListData } from "../../../../../../../../../common/TableData";


interface IDetailFinReportRowComment {
  data: itemListData;
}

const DetailFinReportRowComment: React.FC<IDetailFinReportRowComment> = ({
  data,
}): JSX.Element => {
  const styles = useStyles();

  const [showForm, setShowForm] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');

  const maxLength = data.periodStatus === "closed" ? 35 : 27;

  useEffect(() => {
    if (typeof data.comment === 'object' ) {
      setShowForm(false);
    }
    if (typeof data.comment === 'string') {
      setComment(data.comment);
    }
  }, [data]);

  const handleEdit = useCallback(() => {
    setShowForm(true);
  }, [setShowForm]);

  return (
    <Box className={styles.block}>
      {
        data.periodStatus === "closed" ? (<>
          {
            !!comment ? (<>
              {comment.length > maxLength ? (
                <Tooltip title={comment}>
                  <Box className={`${styles.commentBlock} close`}>
                    <Box className={`${styles.comment} close`}>
                      <Box component="span" className={styles.text}>{comment.slice(0, maxLength)}...</Box>
                    </Box>
                  </Box>
                </Tooltip>
              ) : (<>
                <Box className={`${styles.commentBlock} close`}>
                  <Box className={`${styles.comment} close`}>
                    {comment}
                  </Box>
                </Box>
              </>)}
            </>) : (
              <Box className={`${styles.commentBlock} close`}>
                <Box className={`${styles.comment} close`}>—</Box>
              </Box>
            )
          }
        </>) : (<>
          {!showForm ? <>
            <Box className={`${styles.commentBlock}`}>
              <Box className={`${styles.comment}`}>
                {
                  !!comment ? (
                    <>
                      {comment.length > maxLength ? (
                        <Tooltip title={comment}>
                          <Box component="span" className={styles.text}>{comment.slice(0, maxLength)}...</Box>
                        </Tooltip>
                      ) : (<>{comment}</>)}
                    </>
                  ) : (
                    <Box component={'span'} sx={{ whiteSpace: 'nowrap' }} />
                  )
                }
              </Box>
              <Box className={styles.actions}>
                <IconButton className={styles.btn} onClick={handleEdit} color="secondary" size="small">
                  <EditIcon className={styles.iconBtn} fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          </> : <>
            <EditForm row={data} comment={comment} setHideForm={setShowForm} setComment={setComment} />
          </>}
        </>)
      }
    </Box>
  );
};

export default React.memo(DetailFinReportRowComment, arePropsEqual);
