import { PeriodTypes } from "../../UI/FormFields/PeriodDatesField/utils";

interface IItemData {
  id: PeriodTypes;
  label: PeriodTypes;
}

export const periodShortcuts: IItemData[] = [
  {
    id: "last_7_days",
    label: "last_7_days",
  },
  {
    id: "week",
    label: "week",
  },
  {
    id: "last_week",
    label: "last_week",
  },
  {
    id: "month",
    label: "month",
  },
  {
    id: "last_month",
    label: "last_month",
  },
  /*{
    id: "previous_7_days",
    label: "previous_7_days",
  },*/
  {
    id: "today",
    label: "today",
  },
  {
    id: "yesterday",
    label: "yesterday",
  },
  {
    id: "year",
    label: "year",
  },
  {
    id: "last_year",
    label: "last_year",
  },
  {
    id: "current_quater",
    label: "current_quater",
  },
  {
    id: "last_quater",
    label: "last_quater",
  }
];
