import {
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  startOfYear,
  endOfYear,
  subYears,
  lastDayOfQuarter,
  startOfQuarter,
} from "date-fns";
import { IItemOption } from "./CreativeReport/components/GroupByField";

export type PeriodTypes = 'today' | 'yesterday' | 'last_7_days' | 'week' | 'last_week' | 'month' | 'last_month' | 'year' | 'last_year' | 'current_quater' | 'last_quater' | '';

export type weekStartsOnType = 0 | 1 | 2 | 3 | 4 | 5 | 6;

interface IPeriodResult {
  start: Date | '',
  end: Date | '',
}

export type Options = {
  id: string;
  label: string;
};

export type ObjArrayItem = {
  id: string | number;
  [key: string]: any,
};

interface IObj {
  [i:string]: string;
}

export type GroupByObj = {
  [k: string]: string | IObj
}

type SegmentItem = {
  id: number;
  name: string;
};

type DataItemProps = {
  id: number | string;
  check: boolean;
  value: number | string;
};

export const getPeriod = (period: PeriodTypes): IPeriodResult => {
  const i18nextLng: string = localStorage.getItem("i18nextLng") || '';
  let weekStartsOn: weekStartsOnType = 0;
  if (!i18nextLng.includes('en')) {
    weekStartsOn = 1;
  }

  switch (period) {
    case "today":
      return {
        start: new Date(),
        end: new Date(),
      }
    case "yesterday":
      return {
        start: subDays(new Date(), 1),
        end: subDays(new Date(), 1),
      }
    case "last_7_days":
      return {
        start: subDays(new Date(), 6),
        end: new Date(),
      }
    case "week":
      return {
        start: startOfWeek(new Date(), { weekStartsOn: weekStartsOn }),
        end: endOfWeek(new Date(), { weekStartsOn: weekStartsOn }),
      }
    case "last_week":
      return {
        start: startOfWeek(subDays(new Date(), 7), { weekStartsOn: weekStartsOn }),
        end: endOfWeek(subDays(new Date(), 7), { weekStartsOn: weekStartsOn }),
      }
    case "month":
      return {
        start: startOfMonth(new Date()),
        end: endOfMonth(new Date()),
      }
    case "last_month":
      return {
        start: startOfMonth(subMonths(new Date(), 1)),
        end: endOfMonth(subMonths(new Date(), 1)),
      }
    case "year":
      return {
        start: startOfYear(new Date()),
        end: endOfYear(new Date()),
      }
    case "last_year":
      return {
        start: startOfYear(subYears(new Date(), 1)),
        end: endOfYear(subYears(new Date(), 1)),
      }
    case "current_quater":
      return {
        start: startOfQuarter(new Date()),
        end: lastDayOfQuarter(new Date()),
      }
    case "last_quater":
      return {
        start: startOfQuarter(subMonths(startOfMonth(new Date()), 3)),
        end: lastDayOfQuarter(subMonths(startOfMonth(new Date()), 3)),
      }
    default:
      return {
        start: '',
        end: '',
      }
  }
};

export const getOptionsList = (arr: string[]): Options[] => {
  if (!!arr) {
    return arr.reduce((acc: Options[], item) => {
      acc.push({id: item, label: item});
      return acc;
    }, []);
  } else {
    return []
  }
};

export const getOptionsListFromObject = (obj: { [key: string]: string; }): Options[] => {
  if (!!obj) {
    return Object.values(obj).reduce((acc: Options[], item) => {
      acc.push({id: item, label: item});
      return acc;
    }, []);
  } else {
    return []
  }
};

export const getParamsOptions = (obj: { [key: string]: string }): Options[] => {
  return Object.keys(obj).reduce((acc: Options[], key: string) => {
    return [
      ...acc,
      { id: obj[key], label: obj[key] }
    ]
  }, []);
};

export const getSegmentName = (arr: SegmentItem[], id: number): string => {
  const item = arr.find(x => x.id === id);
  return !!item ? item.name : ''
}

export const updateArrItemById = (arr: DataItemProps[], id: number | string): DataItemProps[] => {
  return arr.reduce((acc: DataItemProps[], item: DataItemProps) => {
    if (item.id === id) {
      acc.push({ ...item, check: !item.check });
    } else {
      acc.push(item);
    }
    return acc;
  }, []);
};

export const updateArrAllItems = (arr: DataItemProps[], check: boolean): DataItemProps[] => {
  return arr.reduce((acc: DataItemProps[], item: DataItemProps) => {
    acc.push({ ...item, check: check });
    return acc;
  }, []);
};

export const sortListByKey = (arr: DataItemProps[], key: 'id' | 'check' | 'value'): DataItemProps[] | [] => {
  return arr.sort((a, b) => Number(b[key]) - Number(a[key]));
}

export const getGroupByOptions = (obj: GroupByObj): IItemOption[] => {
  const keys = Object.keys(obj);
  const options = keys.reduce((acc: IItemOption[], item: string) => {
    if (typeof obj[item] === "string") {
      acc.push({
        id: `${obj[item]}`,
        value: `${obj[item]}`,
        expanded: false,
      })
    } else {
      const data = obj[item];
      const expandedKeys = Object.keys(obj[item]);
      const expandedOptions: string[] = expandedKeys.reduce((acc: string[], el: string) => {
        //@ts-ignore
        acc.push(data[el]);
        return acc;
      }, []);

      acc.push({
        id: `group_by_${item}`,
        value: expandedOptions,
        expanded: true,
      })
    }

    return acc;
  }, []);

  return options;
}

export const getGroupByOptionsByArr = (arr: string[]): IItemOption[] => {
  return arr.reduce((acc: IItemOption[], item) => {
    acc.push({
      id: item,
      value: item,
      expanded: false,
    })
    return acc;
  }, [])
}

export const getIds = (arr: ObjArrayItem[]): string[] | number[] => {
  return arr.reduce((acc: string[] | number[], el) => {
    //@ts-ignore
    acc.push(el.id);
    return acc;
  }, []);
}

export const getStringIds = (arr: ObjArrayItem[]): string[] => {
  return arr.reduce((acc: string[], el) => {
    acc.push(`${el.id}`);
    return acc;
  }, []);
}

export const getDataIds = (arr: object[]): string[] | number[] => {
  return arr.reduce((acc: string[] | number[], el) => {
    //@ts-ignore
    acc.push(el.id);
    return acc;
  }, []);
}
