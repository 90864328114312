import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles({
  title: {
    marginBottom: 8,
    fontWeight: "bold",
    paddingTop: 8,
  },
  actions: {
    paddingTop: 24,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: 16,
    '@media (max-width: 570px)': {
      flexDirection: "column"
    },
    '& button': {
      width: "100%",
    }
  }
});
