import { api } from "../../api";
import {handleResponse, RequestOptions} from "../types";
import {getCookie} from "../../helpers/utils";
import { cookieSupervision, cookieToken } from "../../constants";

const getWidgetsGrid = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = data.namespace === 'admin'
    ? `${api.admin.dashboardV2.widgets.list}`
    : `${api[isSupervision ? 'supervision' : 'affiliate'].dashboardV2.widgets.list}`

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });

}

const getWidgetSettingsByName = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = data.namespace === 'admin'
    ? `${api.admin.dashboardV2.widgets.getSettings.replace('{{name}}', data.name)}`
    : `${api[isSupervision ? 'supervision' : 'affiliate'].dashboardV2.widgets.getSettings.replace('{{name}}', data.name)}`

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });

}

const getWidgetDataByName = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = data.namespace === 'admin'
    ? `${api.admin.dashboardV2.widgets.getData.replace('{{name}}', data.name)}`
    : `${api[isSupervision ? 'supervision' : 'affiliate'].dashboardV2.widgets.getData.replace('{{name}}', data.name)}`

  const payload = {
    ...data.payload,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getFilters = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = data.namespace === 'admin'
    ? `${api.admin.dashboardV2.widgets.filters}`
    : `${api[isSupervision ? 'supervision' : 'affiliate'].dashboardV2.widgets.filters}`

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });

}

const getAffiliates = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.dashboardV2.widgets.affiliates;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

//creatives
const getCreatives = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = data.namespace === 'admin'
    ? `${api.admin.dashboardV2.widgets.creatives}`
    : `${api[isSupervision ? 'supervision' : 'affiliate'].dashboardV2.widgets.creatives}`;

  const payload = {
    page: data.page,
    search: data.search,
    per_page: data.per_page,
    authenticity_token: authenticity_token,
  }

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });

}

//trackers
const getTrackers = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = data.namespace === 'admin'
    ? `${api.admin.dashboardV2.widgets.trackers}`
    : `${api[isSupervision ? 'supervision' : 'affiliate'].dashboardV2.widgets.trackers}`;

  const payload = {
    affiliate_ids: data.affiliate_ids,
    page: data.page,
    search: data.search,
    per_page: data.per_page,
    authenticity_token: authenticity_token,
  }

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });

}

//brands
const getBrands = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = data.namespace === 'admin'
    ? `${api.admin.dashboardV2.widgets.brands}`
    : `${api[isSupervision ? 'supervision' : 'affiliate'].dashboardV2.widgets.brands}`;

  const payload = {
    page: data.page,
    search: data.search,
    per_page: data.per_page,
    authenticity_token: authenticity_token,
  }

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });

}

export const dashboardV2Service = {
  getWidgetsGrid,
  getWidgetSettingsByName,
  getWidgetDataByName,
  getFilters,
  getAffiliates,
  getCreatives,
  getTrackers,
  getBrands,
};
