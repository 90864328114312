//core
import React, { useRef } from "react";
import arePropsEqual from "react-fast-compare";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Box, Tooltip } from "@mui/material";
import useDimensions from "../../../../../customHooks/useDimensions";
import { importAll } from "../../../../../helpers/importAll";
import {alpha} from "@mui/material/styles";
import {Theme} from "@mui/material/styles/createTheme";

type Item = {
  id: string,
  label: string,
}

interface ICountriesArrayProps {
  value: Item[];
  prefix?: string;
  maxWidth?: number;
  showAll?: boolean;
}

export const useStyles = makeStyles<Theme>((theme) => ({
  block: {
    position: "relative",
    color: "inherit",
    display: "block",
    width: "100%",
    minWidth: "60px",
  },
  wrapper: {
    width: "max-content",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    '&.show': {
      overflow: "visible",
      textOverflow: "inherit",
      whiteSpace: "normal",
    }
  },
  item: {
    display: "inline-block",
    whiteSpace: "nowrap",
    marginRight: "4px",
    verticalAlign: "middle",
  },
  country: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: 20,
    '& img': {
      width: "100%",
      maxWidth: 20,
      border: `1px solid ${alpha(theme.palette.secondary.light, 0.2)}`,
      marginRight: 4,
    },
  },
  text: {
    display: "block",
    lineHeight: "1",
  },
  hide: {
    opacity: 0,
    visibility: "hidden",
  }
}));

const CountriesArray = ({ value, prefix, maxWidth = 320, showAll = false }: ICountriesArrayProps) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const wrapperRef: any = useRef(null);
  const size = useDimensions(wrapperRef);

  // @ts-ignore
  const images = importAll(require.context('../../../../../assets/images/flags', false, /\.(png|jpe?g|svg)$/));

  const listArr = value.reduce((acc:any[], item) => {
    acc.push({
      id: item.id,
      label: !!prefix ? t(`${prefix}${item.label}`) : item.label
    })
    return acc
  }, []);

  const list = listArr.map((item, index) => {
    return <span key={`${item.id}-${index}`} className={styles.item}>
      <span className={styles.country}>
        {item.id.toLowerCase() !== 'all' && (
          <img src={images[`${item.id.toLowerCase()}.svg`]} alt={item.label} />
        )}
        <span className={styles.text}>
          {item.label}{index+1 !== listArr.length && ','}
        </span>
      </span>
    </span>
  });

  return (
    <>
      <Box className={`${styles.block} countries-array`} sx={{ maxWidth: `${maxWidth}px` }}>
        <Box ref={wrapperRef} className={styles.wrapper}
             sx={{ opacity: 0, visibility: "hidden", position: "absolute" }}
        >
          { list }
        </Box>
        {showAll ? (<>
          <Box className={`${styles.wrapper} show`}>
            {
              value.length > 0 ? list : '—'
            }
          </Box>
        </>) : (<>
          {
            (size.width === maxWidth) ? (
              <Tooltip title={list}>
                <Box className={styles.wrapper}>
                  { list }
                </Box>
              </Tooltip>
            ) : (
              <Box className={styles.wrapper}>
                {
                  value.length > 0 ? list : '—'
                }
              </Box>
            )
          }
        </>)}
      </Box>
    </>
  );
};


export default React.memo(CountriesArray, arePropsEqual);
