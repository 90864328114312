//core
import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Box,
  Typography, useMediaQuery,
} from '@mui/material';

//styles
import { useStyles } from "./styles";
import { useHeaderStyles } from "../../UI/PageHeaderStyles/styles";

//helpers
import { getPeriod, PeriodTypes } from "../../UI/FormFields/PeriodDatesField/utils";

//hooks
import { useUser } from "../../../store/common/user/useUser";
import { useDashboardV2 } from "../../../store/admin/dashboardV2/useDashboardV2";
import { usePermissions, selectPermissionData } from "../../../store/common/configuration/usePermissions";
import { useStorage } from "../../../store/common/storage/useStorage";

//selectors
import {
  selectDashboardFiltersData,
  selectDashboardGridWidgetsData,
} from "../../../store/admin/dashboardV2/selectors";

//constants
import { settingsProps } from "./Settings/constants";

//context
import { GlobalContext } from "../../../context/GlobalContext";

//components
import WidgetsDrawerFilter from "./components/WidgetsDrawerFilter";
import TopWidgets from "./components/TopWidgets";
import GridWidgets from "./components/GridWidgets";
import PermissionsWrapper from "../PermissionsWrapper";
import { GridWidgetItemType } from "../../../store/admin/dashboardV2";
import { IDashboardFilterType } from "./types";
import { getCookie, setCookieObject } from "../../../helpers/utils";
import { getCookieDomain } from "../../../helpers/getCookieDomain";

export type IPeriodsType = {
  main: {
    period: PeriodTypes;
    start: Date | '';
    end: Date | '';
  },
  comparative: {
    period: PeriodTypes;
    start: Date | '';
    end: Date | '';
  }
}

export const initialDashboardV2FilterData: IDashboardFilterType = {
  geo: [],
  affiliateIds: [],
  creativeIds: [],
  trackerIds: [],
  brands: [],
};

export const defaultPeriodsState: IPeriodsType = {
  main: {
    period: 'last_7_days',
    start: new Date(getPeriod('last_7_days').start),
    end: new Date(getPeriod('last_7_days').end),
  },
  comparative: {
    period: '',
    start: '',
    end: '',
  }
};

const DashboardV2 = () => {
  const styles = useStyles();
  const headerStyles = useHeaderStyles();
  const { t } = useTranslation();
  const { hasPermissions } = usePermissions();
  const mobile = useMediaQuery('(max-width:767px)');

  const context = useContext(GlobalContext);

  const permissionData = useSelector(selectPermissionData);

  const { user } = useUser();
  const { getGridWidgets, clearGridWidgetsData, getFilters, clearFiltersData } = useDashboardV2();
  const { getStorageList, getStorageItemById } = useStorage();

  const showDashboard = hasPermissions(["api3.admin.dashboardv1widgetcontroller.show", "api3.affiliate.dashboardv1widgetcontroller.show"]);

  const gridWidgetsList = useSelector(selectDashboardGridWidgetsData);
  const availableFilterFields = useSelector(selectDashboardFiltersData);

  const [periods, setPeriods] = useState<IPeriodsType | null>(null);

  const cookiePolicy = getCookie(`${user.user.namespace}DashboardPeriods`);

  const [filter, setFilter] = useState<IDashboardFilterType>(context.dashboardV2Filter);
  const [availableWidgetsTypes, setAvailableWidgetsTypes] = useState<string[] | null>(null);
  const [savedSettingsData, setSavedSettingsData] = useState<any | null>(null);

  useEffect(() => {
    if (!!permissionData) {
      if (!gridWidgetsList) {
        if (showDashboard) {
          getGridWidgets({
            namespace: user.user.namespace,
          });
        }
      } else {
        setAvailableWidgetsTypes(gridWidgetsList.map((item: GridWidgetItemType) => item.type));
      }
    }
  }, [gridWidgetsList, permissionData, user]);

  useEffect(() => {
    getStorageList({
      payload: {
        page: 1,
        per_page: 100,
        order: {
          updated: "desc",
        },
      },
      type: settingsProps.type,
      target: settingsProps.target,
    }).then((storageData) => {
      const savedData = storageData.list.length;

      if (!!savedData) {
        //has saved data
        getStorageItemById(storageData.list[0].id, settingsProps.type, settingsProps.target).then((settings) => {
          const settingsData = JSON.parse(settings.data);
          setSavedSettingsData(settingsData);
        });
      } else {
        setSavedSettingsData({
          topWidgetsSettings: null,
          bottomWidgetsSettings: null,
        })
      }
    })
  }, []);

  useEffect(() => {
    if (!availableFilterFields) {
      getFilters({
        namespace: user.user.namespace,
      });
    }
  }, []);

  useEffect(() => {
    const cookie = JSON.parse(getCookie(`${user.user.namespace}-dashboard-periods`));

    if (!!cookie) {
      setPeriods(cookie);
    } else {
      setPeriods(defaultPeriodsState);
    }
  }, [cookiePolicy]);

  useEffect(() => {
    return () => {
      clearGridWidgetsData();
      clearFiltersData();
      setSavedSettingsData(null);
    };
  }, []);

  const setPeriodsData = (data: IPeriodsType) => {
    const date = new Date();
    date.setDate(date.getDate() + 1);

    const domain = getCookieDomain();

    const cookieInfo = {
      expires: date,
      path: '/',
      //domain: domain.includes('.loc') ? 'fe.marktech-stage.pmcorp.loc' : domain,
    }

    setCookieObject(`${user.user.namespace}-dashboard-periods`, JSON.stringify(data), cookieInfo);
    setPeriods(data);
  }

  return (
    <>
      {!mobile && (
        <Box className={headerStyles.header}>
          <Box className={headerStyles.headerTop}>
            <Typography variant="h6">{t("common.breadcrumbs.overview")}</Typography>
          </Box>
        </Box>
      )}

      <PermissionsWrapper
        permissions={[
          "api3.admin.dashboardv1widgetcontroller.show",
          "api3.affiliate.dashboardv1widgetcontroller.show"
        ]}
      >
        <Box className={headerStyles.contentWrapper}>
          <PermissionsWrapper
            permissions={[
              "api3.admin.dashboardv1widgetcontroller.top_widgets",
              "api3.affiliate.dashboardv1widgetcontroller.top_widgets",
              "api3.admin.dashboardv1widgetcontroller.statistic_charts",
              "api3.affiliate.dashboardv1widgetcontroller.statistic_charts"
            ]}
          >
            {!!periods && !!availableFilterFields && (
              <WidgetsDrawerFilter
                periods={periods}
                setPeriods={setPeriodsData}
                initialFilter={filter}
                setUpdateFilter={setFilter}
                availableFields={availableFilterFields.filters}
              />
            )}
          </PermissionsWrapper>
          <Box className={styles.wrapper}>
            <PermissionsWrapper
              permissions={[
                "api3.admin.dashboardv1widgetcontroller.top_widgets",
                "api3.affiliate.dashboardv1widgetcontroller.top_widgets"
              ]}
            >
              {!!periods &&!!availableWidgetsTypes && availableWidgetsTypes.includes('top_widgets') && (
                <Box className={styles.top}>
                  <TopWidgets
                    periods={periods}
                    filter={filter}
                    settings={(!!savedSettingsData && savedSettingsData.hasOwnProperty('topWidgetsSettings'))
                      ? savedSettingsData.topWidgetsSettings
                      : null}
                  />
                </Box>
              )}
            </PermissionsWrapper>

            <PermissionsWrapper
              permissions={[
                "api3.admin.dashboardv1widgetcontroller.statistic_charts",
                "api3.affiliate.dashboardv1widgetcontroller.statistic_charts"
              ]}
            >
              <Box>
                {!!periods && !!savedSettingsData && (
                  <GridWidgets
                    periods={periods}
                    filter={filter}
                    gridWidgetsList={gridWidgetsList}
                    settings={!!savedSettingsData
                      ? savedSettingsData.hasOwnProperty('bottomWidgetsSettings')
                        ? savedSettingsData.bottomWidgetsSettings
                        : null
                      : null}
                  />
                )}
              </Box>
            </PermissionsWrapper>
          </Box>
        </Box>
      </PermissionsWrapper>
    </>
  );
};

export default DashboardV2;
