//core
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {FieldHookConfig, useField, useFormikContext} from "formik";
import {Box, InputLabel, MenuItem, FormControl, Select, OutlinedInput, FormHelperText} from "@mui/material";
import {MenuProps} from "@mui/material/Menu";
import useDimensions from "../../../../customHooks/useDimensions";

interface IDataItem {
  id: number | string,
  label?: string,
  [key: string]: any,
}

interface IMuiSelect  {
  keyValue: string,
  keyId: string,
  data: IDataItem[] | null,
  value: number | string,
  name: string,
  label: string,
  disabled?: boolean,
  prefix?: string,
  onHandleChange?: (value: any) => void,
  marginBottom?: number,
  preventChangeValue?: boolean,
}

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 4;

export const menuProps: Partial<MenuProps> = {
  variant: 'menu',
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + (ITEM_PADDING_TOP * 2),
      width: 'auto',
      maxWidth: '100%',
    }
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  }
};

export const MuiSelect = (props: IMuiSelect & FieldHookConfig<string>) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const { t } = useTranslation();
  const wrapperRef: any = useRef(null);
  const size = useDimensions(wrapperRef);

  const [field, meta] = useField(props);

  const {
    keyValue,
    keyId,
    data,
    value,
    name,
    label,
    disabled,
    prefix = '',
    marginBottom = 3,
    onHandleChange,
    preventChangeValue = false
  } = props;

  const [selectValue, setSelectValue] = useState(value);

  useEffect(() => {
    setSelectValue(value);
  }, [value]);


  const setFieldTouchedFn = useCallback(() => {
      setTimeout(() => setFieldTouched(field.name, true), 20)},
    [field.name]
  );

  const handleChangeField = (event: { target: { value: any; }; }) => {
    const {target: { value }} = event;
    typeof onHandleChange === 'function' && onHandleChange(value);
    if (!preventChangeValue) {
      setSelectValue(value);
      setFieldValue(field.name, value);
    }
    setFieldTouchedFn();
  };

  const handleBlur = () => {
    if (!!meta && meta.hasOwnProperty('value') && meta.value.length === 0) {
      setFieldTouched(field.name, true);
    }
  };

  return (
    <>
      <Box ref={wrapperRef}  sx={{ marginBottom: marginBottom }}>
        <FormControl fullWidth size="small">
          <InputLabel
            error={!!(meta.touched && meta.error && meta.error !== 'error')}
            id={`select-field-label-${name}`}
            disabled={disabled}
          >
            {label}
          </InputLabel>
          <Select
            labelId={`select-field-label-${name}`}
            id={`select-field-${name}`}
            name={`select-field-${name}`}
            label={label}
            value={selectValue}
            disabled={disabled}
            error={!!(meta.touched && meta.error && meta.error !== 'error')}
            input={
              <OutlinedInput
                id={`select-input-field-${name}`}
                label={label}
                //color="success"
              />
            }
            onChange={handleChangeField}
            onClose={handleBlur}
            // renderValue={(value) => {
            //   return (
            //     <>
            //       {!!prefix ? t(`${prefix}${value}`) : t(value)}
            //     </>
            //   );
            // }}
            MenuProps={{
              ...menuProps,
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 8 + (ITEM_PADDING_TOP * 2),
                  width: `${size.width}px`,
                  maxWidth: '100%',
                }
              },
            }}
          >
            {
              data && data.map((item: IDataItem) => (
                  //ts-ignore
                  <MenuItem
                    key={`option-${name}-${item[keyId]}`}
                    value={item[keyId]}
                    sx={{
                      minHeight: `${ITEM_HEIGHT}px`,
                      whiteSpace: 'normal',
                    }}
                  >
                    <Box component='span' sx={{
                      display: 'block',
                      width: '100%',
                      overflow: 'hidden',
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                    }}>
                      { !!prefix ? t(`${prefix}${item[keyValue]}`) : t(item[keyValue])}
                    </Box>
                  </MenuItem>
                )
              )
            }
          </Select>
          { meta.touched && meta.error && meta.error !== 'error' ? (
            <FormHelperText error>{meta.error}</FormHelperText>
          ) : null }
        </FormControl>
      </Box>
    </>
  );
};
