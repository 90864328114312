//core
import React from "react";
import {makeStyles, useTheme} from "@mui/styles";
import {Theme} from "@mui/material/styles/createTheme";
import {Box, LinearProgress, Typography, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import {alpha} from "@mui/material/styles";

interface IProgress {
  value: number;
}

export const useStyles = makeStyles<Theme>((theme) => ({
  block: {
    padding: '4px 0',
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  label: {
    color: alpha(theme.palette.text.secondary, 0.6),
  },
  text: {
    color: 'inherit',
    fontWeight: 'inherit',
    fontSize: 12,
    letterSpacing: '0.4px'
  },
  row: {
    display: "flex",
    alignItems: 'center'
  },
  progress: {
    width: "100%",
    marginRight: 8,
    borderRadius: 4,
    backgroundColor: theme.palette.success.light,
    '@media (max-width: 767px)': {
      marginRight: 0,
      height: 8,
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.success.main,
    },
  }
}));

const Text: React.FC<IProgress> = ({
  value = 0,
}): JSX.Element  => {
  const styles = useStyles();
  const theme: Theme = useTheme();
  const { t } = useTranslation();
  const mobile = useMediaQuery('(max-width:767px)');

  const progressValue = 100 - value;

  return (
    <>
      {mobile ? (
        <Box className={styles.block}>
          <Box className={styles.top}>
            <Typography className={styles.label} variant="body2" component="span">
              {t("columns_for_display.table.billing.progress")}:
            </Typography>
            <Typography variant="caption" component="span">
              {
                value === Infinity ? (
                  '—'
                ) : (
                  `${value ? value : '0'}/100%`
                )
              }
            </Typography>
          </Box>
          <Box>
            {
              value === Infinity ? (
                ''
              ) : (
                <LinearProgress
                  className={styles.progress}
                  variant="determinate"
                  value={value ? value : 0}
                  color={'primary'}
                  {...(value > 100 ? {
                    sx: {
                      backgroundColor: `${theme.palette.error.main}!important`,
                      '& .MuiLinearProgress-barColorPrimary': {transform: `translateX(${progressValue}%)!important`}
                    }
                  } : {} )}
                />
              )
            }
          </Box>
        </Box>
      ) : (
        <Box className={styles.row}>
          {
            value === Infinity ? (
              ''
            ) : (
              <LinearProgress
                className={styles.progress}
                variant="determinate"
                value={value ? value : 0}
                color={'primary'}
                {...(value > 100 ? {
                  sx: {
                    backgroundColor: `${theme.palette.error.main}!important`,
                    '& .MuiLinearProgress-barColorPrimary': {transform: `translateX(${progressValue}%)!important`}
                  }
                } : {} )}
              />
            )
          }
          <span className={styles.text}>
            {
              value === Infinity ? (
                '—'
              ) : (
                `${value ? value : '0'}/100%`
              )
            }
          </span>
        </Box>
      )}
    </>
  );
};

export default Text;
