import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import { Theme } from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  block: {
    maxWidth: 300,
    width: 300,
  },
  commentBlock: {
    height: 30,
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: 0,
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: theme.shape.borderRadius,
    borderColor: alpha(theme.palette.secondary.dark, 0.23),
    padding: "4px 58px 4px 13px",
    '&.close': {
      padding: "4px 14px 4px 13px",
    }
  },
  comment: {
    overflow: 'hidden',
    maxHeight: 30,
    flex: '1 1 auto',
    lineHeight: '1.2',
    fontSize: 14,
    whiteSpace: "nowrap",
    cursor: "default",
  },
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    display: 'block',
  },
  iconBtn: {
    color: alpha(theme.palette.action.active, 0.54),
  },
  btn: {
    padding: "4px",
    margin: '0 0 0 8px',
  },
  actions: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: "1px 4px",
  },
}));
