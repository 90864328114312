//core
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { setUser } from "../../common/user";
import { referenceService } from "../../../services/affiliate/reference.service";


export interface ITokenData {
  domain: string;
  token: string;
}

export interface ConditionStepItem {
  id: number;
  payment_size: number;
  payment_column: string;
}

export interface IConditionsData {
  subaffiliate_condition: {
    id: number;
    title: string;
    description: string;
    created_at: string;
    subaffiliate_condition_steps_attributes: ConditionStepItem[],
  }
}

export type ReferenceSliceState = {
  token: {
    data: ITokenData | null,
    error: any,
    isFetching: boolean,
  },
  condition: {
    data: IConditionsData | null,
    error: any,
    isFetching: boolean,
  }
}

const initialState: ReferenceSliceState = {
  token: {
    data: null,
    error: null,
    isFetching: false,
  },
  condition: {
    data: null,
    error: null,
    isFetching: false,
  }
}

export const getTokenData: any = createAsyncThunk(
  'reference/getTokenData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await referenceService.getReferralsToken();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getConditionData: any = createAsyncThunk(
  'reference/getConditionData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await referenceService.getConditions();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const referenceSlice = createSlice({
  name: 'reference',
  initialState: initialState,
  reducers: {
    clearTokenDataState(state) {
      state.token.data = null;
      state.token.error = null;
      state.token.isFetching = false;
    },
    clearConditionDataState(state) {
      state.condition.data = null;
      state.condition.error = null;
      state.condition.isFetching = false;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<ReferenceSliceState>>) => {
    builder.addCase(getTokenData.pending, (state ) => {
      state.token.isFetching = true;
      state.token.error = null;
    });
    builder.addCase(getTokenData.fulfilled, (state , action) => {
      state.token.error = null;
      state.token.isFetching = false;
      state.token.data = action.payload;
    });
    builder.addCase(getTokenData.rejected, (state , action) => {
      state.token.isFetching = false;
      state.token.error = action.payload;
    });

    builder.addCase(getConditionData.pending, (state ) => {
      state.condition.isFetching = true;
      state.condition.error = null;
    });
    builder.addCase(getConditionData.fulfilled, (state , action) => {
      state.condition.error = null;
      state.condition.isFetching = false;
      state.condition.data = action.payload;
    });
    builder.addCase(getConditionData.rejected, (state , action) => {
      state.condition.isFetching = false;
      state.condition.error = action.payload;
    });
  }
});

export default referenceSlice.reducer;


export const {
  clearTokenDataState,
  clearConditionDataState,
} = referenceSlice.actions;
