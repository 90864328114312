import { makeStyles } from "@mui/styles";
import { styled } from '@mui/system';
import {alpha} from "@mui/material/styles";
import {Box} from "@mui/material";
import {MenuProps} from "@mui/material/Menu";
import {InputProps} from "@mui/material/Input";
import { ArrowDropDown } from '@mui/icons-material';
import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";
import {Theme} from "@mui/material/styles/createTheme";

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 4;

export const menuProps: Partial<MenuProps> = {
  variant: 'menu',
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + (ITEM_PADDING_TOP * 2),
      width: 'auto',
      maxWidth: '288px',
    }
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  }
};

export const SelectInputProps: InputProps['inputProps'] = {
  sx: {
    maxHeight: '48px', overflowY: 'scroll!important'
  }
};

export const useStyles = makeStyles<Theme>((theme) => ({
  chip: {
    position: 'relative',
    zIndex: 10,
    // backgroundColor: theme.palette.grey[300],
    // color: theme.palette.grey[900],
    '&.isDragging': {
      //backgroundColor: theme.palette.grey[400],
    },
    '& > span': {
      cursor: 'grab',
    },
    '@media (min-width: 768px)': {
      margin: '8px 4px',
    },
    '@media (max-width: 767px)': {
      margin: '2px 0',
    },
  },
  drag: {
    cursor: 'grab',
    color: alpha(theme.palette.text.primary, 0.26) + '!important',
  },
  draggable: {
    '@media (max-width: 767px)': {
      width: '100%',
    }
  },
  handleLayer: {
    backgroundColor: "red",
  },
  inputLabel: {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translate(14px, 9px) scale(1)',
    transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    '&.active ': {
      transform: 'translate(14px, -9px) scale(0.75)',
    }
  },
}));

export const Block = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
}));

export const Fieldset = styled(Box)<{ selected: boolean, isOpen: boolean }>(({ theme, selected, isOpen }) => ({
  textAlign: 'left',
  position: 'absolute',
  cursor: 'pointer',
  bottom: 0,
  right: 0,
  top: -5,
  left: 0,
  margin: 0,
  padding: '0 8px',
  pointerEvents: 'none',
  borderStyle: 'solid',
  borderWidth: '1px',
  overflow: 'hidden',
  minWidth: '0%',
  borderRadius: theme.shape.borderRadius,
  borderColor: theme?.components?.hasOwnProperty('MuiSelect')
    ? theme?.components?.MuiSelect?.styleOverrides.select["& .MuiSelect-select"]["& > fieldset"].borderColor
    : alpha(theme.palette.primary.main, 0.23),
  '&:hover': {
    borderColor: theme.palette.text.primary,
    ...(isOpen && {
      borderColor: theme.palette.primary.main,
    })
  },
  '& > legend': {
    float: 'unset',
    overflow: 'hidden',
    display: 'block',
    width: 'auto',
    padding: 0,
    height: 11,
    fontSize: '0.75em',
    visibility: 'hidden',
    maxWidth: selected || isOpen ? '100%' : '0.01px',
    transition: selected || isOpen ? 'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms' : 'max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    whiteSpace: 'nowrap',
    '& > span': {
      paddingLeft: '5px',
      paddingRight: '5px',
      display: 'inline-block',
      opacity: 0,
      visibility: 'visible',
    }
  },
}));

export const DragField = styled(Box)<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  gap: 0.5,
  width: '100%',
  minHeight: 40,
  padding: '0 40px 0 10px',
  overflow: 'hidden',
  '&:hover': {
    cursor: 'pointer',
    '& > fieldset': {
      borderColor: theme.palette.text.primary,
      ...(isOpen && {
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
      }),
    },
  },
  ...(isOpen && {
    '& > fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: '2px',
    }
  }),
  '> .handleLayer': {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    cursor: 'pointer',
    borderWidth: '1px',
    borderStyle: 'solid',
    opacity: 0,
    borderColor: theme?.components?.hasOwnProperty('MuiSelect')
      ? theme?.components?.MuiSelect?.styleOverrides.select["& .MuiSelect-select"]["& > fieldset"].borderColor
      : alpha(theme.palette.primary.main, 0.23),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    zIndex: 0,
    ...(isOpen && {
      borderColor: theme.palette.primary.main,
      borderWidth: '2px',
    }),
  },
  '.droppable-wrap': {
    '@media (min-width: 768px)': {
      display: 'flex',
      flexWrap: 'wrap',
    },
    '@media (max-width: 767px)': {
      padding: '8px 0',
    },
  },
  '.chipWrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '1!important',
    '@media (min-width: 768px)': {
      //padding: '8px 4px',
    },
    '@media (max-width: 767px)': {
      justifyContent: 'flex-start',
    }
  },
}));

export const FieldArrow = styled(ArrowDropDown, {
  shouldForwardProp: (prop: string) => prop !== 'isOpen'
})<SvgIconProps & { isOpen: boolean }>(
  ({ theme, isOpen }) => ({
    display: 'block',
    position: 'absolute',
    right: '7px',
    top: 'calc(50% - 0.5em)',
    color: alpha(theme.palette.secondary.dark, 0.54),
    zIndex: 0,
    ...(isOpen && {
      transform: 'rotate(-180deg)'
    })
  }),
);

export const HandleLayer = styled(Box)<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  cursor: 'pointer',
}));

