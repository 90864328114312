//core
import React from 'react';
import {useTranslation} from "react-i18next";
import arePropsEqual from "react-fast-compare";
import {Chip} from "@mui/material";

import { teal } from '@mui/material/colors';


interface IStatus {
  value: string;
}

const FinReportStatus = ({value}: IStatus) => {
  const {t} = useTranslation();


  return (
    <>
      {/*fin report*/}
      { value === "inReview" && <Chip
        size="small"
        label={t("common.components.status.in_review")}
        color="primary"
        sx={{ backgroundColor: '#44B2E8' }}
      />}
      { value === "inReviewHoldFinal" && <Chip
        size="small"
        label={t("common.components.status.in_review")}
        color="primary"
        sx={{ backgroundColor: '#44B2E8' }}
      />}
      { value === "closed" && <Chip
        size="small"
        label={t("common.components.status.closed")}
        color="primary"
        sx={{ backgroundColor: '#00AF55' }}
      />}

      {/*details fin report*/}
      { value === "approved" && <Chip
        size="small"
        label={t("admin.details_finance_report.payment_statuses.approved")}
        color="default"
        sx={{ backgroundColor: '#F7E587' }}
      />}
      { value === "approvedFinance" && <Chip
        size="small"
        label={t("admin.details_finance_report.payment_statuses.approvedFinance")}
        color="primary"
        sx={{ backgroundColor: '#C07BF0' }}
      />}
      { value === "processingPayment" && <Chip
        size="small"
        label={t("admin.details_finance_report.payment_statuses.processingPayment")}
        color="primary"
        sx={{ backgroundColor: `${teal["500"]}` }}
      />}
      { value === "new" && <Chip
        size="small"
        label={t("admin.details_finance_report.payment_statuses.new")}
        color="primary"
        sx={{ backgroundColor: '#F69B5A'}}
      />}
      { value === "paid" && <Chip
        size="small"
        label={t("admin.details_finance_report.payment_statuses.paid")}
        color="primary"
        sx={{ backgroundColor: '#00AF55'}}
      />}
      { value === "carryover" && <Chip
        size="small"
        label={t("admin.details_finance_report.payment_statuses.carryover")}
        color="default"
        sx={{ backgroundColor: '#83CEF3'}}
      />}
    </>
  );
};

export default React.memo(FinReportStatus, arePropsEqual)
