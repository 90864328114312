//core
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";

//services
import {creativesService} from "../../../services/admin/creatives.service";

//actions
import {setCreateCreativeState, setGeneralProgressShow, setGeneralProgressHide, setGeneralSnackbarState} from "../../common/ui";
import {setUser} from "../../common/user";
import {trackersSlice} from "../../affiliate/trackers";

//type
export type creativesFormFieldOptions = {
  id: string,
  label: string
};

export type creativesFormSelectField = {
  label: string,
  is_disable: boolean,
  options: creativesFormFieldOptions[],
  value: string[] | [] | '',
  translatable?: boolean,
};

export type creativesFormTextField = {
  label: string,
  is_disable: boolean,
  value: string | '',
};

export type creativesFormRadioField = {
  label: string,
  is_disable: boolean,
  value: boolean,
};

export type creativesFormFileField = {
  label: string,
  is_disable: boolean,
  value: {
    contents: string | '',
    file: {
      lastModified: number | '',
      lastModifiedDate: string,
      name: string,
      size: number,
      type: 'image/jpeg' | 'image/jpg' | 'image/png',
      webkitRelativePath: string,
    } | '',
  },
};

export interface ICreativesForm {
  id: number | null,
  format: creativesFormSelectField,
  status: creativesFormSelectField,
  name: creativesFormTextField,
  geo: creativesFormSelectField,
  language: creativesFormSelectField,
  at_beginning_show: creativesFormTextField,
  at_end_show: creativesFormTextField,
  created_at?: string | '',
  product: creativesFormSelectField,
  concept: creativesFormSelectField,
  link: creativesFormTextField,
  qtag: creativesFormTextField,
  utm: creativesFormTextField,
  final_link: creativesFormTextField,
  recommended: creativesFormRadioField,
  hide_creative: creativesFormRadioField,
  promo: creativesFormFileField,
  platform: creativesFormSelectField,
  brands: creativesFormSelectField,
  is_external: creativesFormRadioField,
  offer_id: any,
  traffic_back_url: creativesFormTextField,
  traffic_back_mode: any,
}

export type ListItem = {
  id: number | string;
  tracker_code: number | string;
  name: string;
  assigning_at: string;
  description: string;
  type_of_transaction: string;
  [key: string]: any;
}

export type ColumnsItem = {
  name: string;
  type: string;
  sort: boolean;
  match: boolean;
}

export interface ICreativesListData {
  list: ListItem[];
  columns: ColumnsItem[];
  start_row_num: number;
  end_row_num: number;
  total_entries: number | string;
  total_pages: number;
  available_trackers: number;
}

export type creativesSliceState = {
  form: {
    data: ICreativesForm | null,
    error: any | null,
    isFetching: boolean,
  },
  create: {
    data: any | null,
    error: { errors: { [key: string]: string, } } | null,
    isFetching: boolean,
  }
  edit: {
    data: any | null,
    error: any | null,
    isFetching: boolean,
  },
  delete: {
    data: any | null,
    error: any | null,
    isFetching: boolean,
  },
  update: {
    data: any | null,
    error: { errors: { [key: string]: string, } } | null,
    isFetching: boolean,
  },
  filter: {
    data: any | null,
    error: any | null,
    isFetching: boolean,
  },
  list: {
    data: ICreativesListData | null,
    error: any | null,
    isFetching: boolean,
  },
  toggleHide: {
    data: any | null,
    error: any | null,
    isFetching: boolean,
  },
  brands: {
    data: any | null,
    error: any | null,
    isFetching: boolean,
  },
  copy: {
    data: any | null,
    error: any | null,
    isFetching: boolean,
  },
  details: {
    data: ICreativesListData | null,
    error: any | null,
    isFetching: boolean,
  },
  mirrors: {
    data: any | null,
    error: any | null,
    isFetching: boolean,
  },
  activateMirror: {
    data: any | null,
    error: any | null,
    isFetching: boolean,
  },
}

const initialState: creativesSliceState = {
  form: {
    data: null,
    error: null,
    isFetching: false,
  },
  create: {
    data: null,
    error: null,
    isFetching: false,
  },
  edit: {
    data: null,
    error: null,
    isFetching: false,
  },
  delete: {
    data: null,
    error: null,
    isFetching: false,
  },
  update: {
    data: null,
    error: null,
    isFetching: false,
  },
  filter: {
    data: null,
    error: null,
    isFetching: false,
  },
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  toggleHide: {
    data: null,
    error: null,
    isFetching: false,
  },
  copy: {
    data: null,
    error: null,
    isFetching: false,
  },
  brands: {
    data: null,
    error: null,
    isFetching: false,
  },
  details: {
    data: null,
    error: null,
    isFetching: false,
  },
  mirrors: {
    data: null,
    error: null,
    isFetching: false,
  },
  activateMirror: {
    data: null,
    error: null,
    isFetching: false,
  },
}

export const getFormData: any = createAsyncThunk(
  'creatives/form',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await creativesService.getFormData();
      const data = await response.json();
      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const createCreative: any = createAsyncThunk(
  'creatives/create',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await creativesService.createCreative(payload);
      const data = await response.json();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.created',
          })
        );
        dispatch(setCreateCreativeState(false));
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const deleteCreative: any = createAsyncThunk(
  'creatives/delete',
  async (payload: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await creativesService.deleteCreative(payload);
      const data = await response.json();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.removed',
          })
        );
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getCreativeData: any = createAsyncThunk(
  'creatives/getCreativeData',
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await creativesService.getCreative(payload);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const updateCreativeData: any = createAsyncThunk(
  'creatives/updateCreativeData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await creativesService.editCreative(payload);
      const data = await response.json();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.edited',
          })
        );
        dispatch(setGeneralProgressHide());
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getFilterData: any = createAsyncThunk(
  'creatives/getFilterData',
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await creativesService.getFilter();
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getListData: any = createAsyncThunk(
  'creatives/getListData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await creativesService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const toggleHideCreative: any = createAsyncThunk(
  'creatives/toggleHideCreative',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await creativesService.toggleHideCreative(payload);
      const data = await response.json();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.edited',
          })
        );
        dispatch(setGeneralProgressHide());
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        dispatch(setGeneralProgressHide());
        return rejectWithValue({
          ...data,
          creative_id: payload.creative_id,
        })
      }

      return {
        ...data,
        creative_id: payload.creative_id,
      };
    } catch (error) {
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


export const getBrandsListData: any = createAsyncThunk(
  'creatives/getBrandsListData',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await creativesService.getBrandsList(payload);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);


export const copyCreativeData: any = createAsyncThunk(
  'creatives/copy',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await creativesService.copyCreative(payload);
      const data = await response.json();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.copied',
          })
        );
        dispatch(setCreateCreativeState(false));
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getCreativeDetailsData: any = createAsyncThunk(
  'creatives/getCreativeDetailsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await creativesService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getMirrorForBrandData: any = createAsyncThunk(
  'creatives/getMirrorForBrandData',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await creativesService.getMirrorForBrand(payload);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const activateMirrorForBrand: any = createAsyncThunk(
  'creatives/activateMirrorForBrand',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await creativesService.activateMirrorForBrand(payload);
      const data = await response.json();

      if (response.ok && response.status === 200) {
        dispatch(setGeneralProgressHide());
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const creativesSlice = createSlice({
  name: 'creatives',
  initialState: initialState,
  reducers: {
    setCreativeInitialData(state) {
      state.edit.error = null;
      state.edit.isFetching = false;
      state.edit.data = null;
    },
    setCreativesListData(state, action) {
      state.list.data = action.payload
    },
    clearCreativesListState(state) {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = null;
    },
    clearCopyState(state) {
      state.copy.error = null;
      state.copy.isFetching = false;
      state.copy.data = null;
    },
    clearUpdateState(state) {
      state.update.data = null;
      state.update.error = null;
      state.update.isFetching = false;
    },
    clearCreateState(state) {
      state.create.data = null;
      state.create.error = null;
      state.create.isFetching = false;
    },
    clearCreativeDetailsState(state) {
      state.details.data = null;
      state.details.error = null;
      state.details.isFetching = false;
    },
    clearBrandsListState(state) {
      state.brands.data = null;
      state.brands.error = null;
      state.brands.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<creativesSliceState>>) => {
    builder.addCase(getFormData.pending, (state) => {
      state.form.isFetching = true;
      state.form.error = null;
    });
    builder.addCase(getFormData.fulfilled, (state, action) => {
      state.form.error = null;
      state.form.isFetching = false;
      state.form.data = action.payload;
    });
    builder.addCase(getFormData.rejected, (state, action) => {
      state.form.error = action.payload;
    });

    builder.addCase(createCreative.pending, (state) => {
      state.create.isFetching = true;
      state.create.error = null;
    });
    builder.addCase(createCreative.fulfilled, (state, action) => {
      state.create.error = null;
      state.create.isFetching = false;
      state.create.data = action.payload;
    });
    builder.addCase(createCreative.rejected, (state, action) => {
      state.create.error = action.payload;
    });

    builder.addCase(deleteCreative.pending, (state) => {
      state.delete.isFetching = true;
      state.delete.error = null;
    });
    builder.addCase(deleteCreative.fulfilled, (state, action) => {
      state.delete.error = null;
      state.delete.isFetching = false;
      state.delete.data = action.payload;
    });
    builder.addCase(deleteCreative.rejected, (state, action) => {
      state.delete.error = action.payload;
    });

    builder.addCase(getCreativeData.pending, (state) => {
      state.edit.isFetching = true;
      state.edit.error = null;
    });
    builder.addCase(getCreativeData.fulfilled, (state, action) => {
      state.edit.error = null;
      state.edit.isFetching = false;
      state.edit.data = action.payload;
    });
    builder.addCase(getCreativeData.rejected, (state, action) => {
      state.edit.error = action.payload;
      state.edit.isFetching = false;
    });

    builder.addCase(updateCreativeData.pending, (state) => {
      state.update.isFetching = true;
      state.update.error = null;
    });
    builder.addCase(updateCreativeData.fulfilled, (state, action) => {
      state.update.error = null;
      state.update.isFetching = false;
      state.update.data = action.payload;
    });
    builder.addCase(updateCreativeData.rejected, (state, action) => {
      state.update.error = action.payload;
    });

    builder.addCase(getFilterData.pending, (state) => {
      state.filter.isFetching = true;
      state.filter.error = null;
    });
    builder.addCase(getFilterData.fulfilled, (state, action) => {
      state.filter.error = null;
      state.filter.isFetching = false;
      state.filter.data = action.payload;
    });
    builder.addCase(getFilterData.rejected, (state, action) => {
      state.filter.error = action.payload;
    });

    builder.addCase(getListData.pending, (state) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getListData.fulfilled, (state, action) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getListData.rejected, (state, action) => {
      state.list.error = action.payload;
    });

    builder.addCase(toggleHideCreative.pending, (state) => {
      state.toggleHide.isFetching = true;
      state.toggleHide.error = null;
    });
    builder.addCase(toggleHideCreative.fulfilled, (state, action) => {
      state.toggleHide.error = null;
      state.toggleHide.isFetching = false;
      state.toggleHide.data = action.payload;
    });
    builder.addCase(toggleHideCreative.rejected, (state, action) => {
      state.toggleHide.error = action.payload;
      state.toggleHide.isFetching = false;
    });

    builder.addCase(getBrandsListData.pending, (state) => {
      state.brands.isFetching = true;
      state.brands.error = null;
    });
    builder.addCase(getBrandsListData.fulfilled, (state, action) => {
      state.brands.error = null;
      state.brands.isFetching = false;
      state.brands.data = action.payload;
    });
    builder.addCase(getBrandsListData.rejected, (state, action) => {
      state.brands.error = action.payload;
      state.brands.isFetching = false;
    });

    builder.addCase(copyCreativeData.pending, (state) => {
      state.copy.isFetching = true;
      state.copy.error = null;
    });
    builder.addCase(copyCreativeData.fulfilled, (state, action) => {
      state.copy.error = null;
      state.copy.isFetching = false;
      state.copy.data = action.payload;
    });
    builder.addCase(copyCreativeData.rejected, (state, action) => {
      state.copy.error = action.payload;
      state.copy.isFetching = false;
    });

    builder.addCase(getCreativeDetailsData.pending, (state) => {
      state.details.isFetching = true;
      state.details.error = null;
    });
    builder.addCase(getCreativeDetailsData.fulfilled, (state, action) => {
      state.details.error = null;
      state.details.isFetching = false;
      state.details.data = action.payload;
    });
    builder.addCase(getCreativeDetailsData.rejected, (state, action) => {
      state.details.error = action.payload;
    });

    builder.addCase(getMirrorForBrandData.pending, (state) => {
      state.mirrors.isFetching = true;
      state.mirrors.error = null;
    });
    builder.addCase(getMirrorForBrandData.fulfilled, (state, action) => {
      state.mirrors.error = null;
      state.mirrors.isFetching = false;
      state.mirrors.data = action.payload;
    });
    builder.addCase(getMirrorForBrandData.rejected, (state, action) => {
      state.mirrors.error = action.payload;
      state.mirrors.isFetching = false;
    });

    builder.addCase(activateMirrorForBrand.pending, (state) => {
      state.activateMirror.isFetching = true;
      state.activateMirror.error = null;
    });
    builder.addCase(activateMirrorForBrand.fulfilled, (state, action) => {
      state.activateMirror.error = null;
      state.activateMirror.isFetching = false;
      state.activateMirror.data = action.payload;
    });
    builder.addCase(activateMirrorForBrand.rejected, (state, action) => {
      state.activateMirror.error = action.payload;
    });
  }
});

export const {
  setCreativeInitialData,
  setCreativesListData,
  clearCreativesListState,
  clearCopyState,
  clearUpdateState,
  clearCreateState,
  clearCreativeDetailsState,
  clearBrandsListState,
} = creativesSlice.actions;

export default creativesSlice.reducer;
