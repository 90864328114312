//core
import React, {useState, useEffect, useCallback} from "react";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
  Box,
  Tab,
  Badge,
} from "@mui/material";

//styles
import { useStyles } from "./styles";

//selectors
import {
  selectStorageCreateData,
  selectStorageDeleteData,
} from "../../../../../../../../store/common/storage/selectors";

//hooks
import { useStorage } from "../../../../../../../../store/common/storage/useStorage";
import { setUser } from "../../../../../../../../store/common/user";
import { usePermissions } from "../../../../../../../../store/common/configuration/usePermissions";
import useDidMountEffect from "../../../../../../../../customHooks/useDidMountEffect";

//services
import { storageService } from "../../../../../../../../services/common/storage.service";

//constants
import { reportStorageProps } from "../../../../constants";

import { ISummaryReportFilterDataType as IFilterData } from "../../../../types";
import Filter from "../Filter";
import MyFilters from "../../../../../../../common/FiltersComponents/MyFilters";
import EmptyTable from "../../../../../../../common/TableData/components/EmptyTable";
import { ListPayloadType } from "../../../../../../../../store/common/storage";

interface IFilterTabsProps {
  initialFilter: IFilterData;
  defaultFilter: IFilterData;
  activeField: string;
  setUpdateFilter: (data: any) => void;
  setToggleDrawer: (data: boolean) => void;
  setGenerateReport: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFilterId: string;
  setSelectedFilterId: React.Dispatch<React.SetStateAction<string>>;
}

const FilterTabs: React.FC<IFilterTabsProps> = ({
  initialFilter,
  defaultFilter,
  activeField,
  setUpdateFilter,
  setToggleDrawer,
  setGenerateReport,
  selectedFilterId,
  setSelectedFilterId,
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();
  const dispatch = useDispatch();
  const { hasPermissions } = usePermissions();

  const hasMyFilters = hasPermissions(["api3.affiliate.playercommissionreportcontroller.my_filters"]);

  const { clearCreateData, clearDeleteData } = useStorage();
  const successCreate = useSelector(selectStorageCreateData);
  const successDelete = useSelector(selectStorageDeleteData);

  const [activeTab, setActiveTab] = useState<string>('filter');
  const [myFiltersList, setMyFiltersList] = useState<any | null>(null);
  const [selectedMyFilterId, setSelectedMyFilterId] = useState<string>(!!selectedFilterId ? `${selectedFilterId}` : '');

  useEffect(() => {
    if (!myFiltersList && hasMyFilters) {
      getMyFilters(getPayloadFilters());
    }
  }, [myFiltersList]);

  useDidMountEffect(() => {
    if (!!successCreate) {
      clearCreateData();
      getMyFilters(getPayloadFilters());
    }
    if (!!successDelete) {
      clearDeleteData();
      getMyFilters(getPayloadFilters());
    }
  }, [successCreate, successDelete]);

  const getMyFilters = async (payload: ListPayloadType) => {
    try {
      const response = await storageService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return {
          status: response.status,
          statusText: response.statusText,
          data: data,
        }
      }

      setMyFiltersList(data)
    } catch (e) {
      console.log(e);
    }
  };

  const getPayloadFilters = (): ListPayloadType => {
    return {
      payload: {
        page: 1,
        per_page: 100,
        order: {
          updated: "desc",
        },
      },
      type: reportStorageProps.type,
      target: reportStorageProps.target,
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent | any, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <TabContext value={activeTab}>
      <Box className={styles.wrapper}>
        {hasMyFilters && (
          <Box className={styles.tabsWrapper}>
            <Box
              className={styles.tabs}
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                width: '100%'
              }}>
              <TabList
                className={styles.tabList}
                onChange={handleChangeTab}
                aria-label="tabs"
                textColor="secondary"
                indicatorColor="secondary"
              >
                <Tab
                  className={styles.tab}
                  label={t("common.components.filters.title")}
                  value={'filter'}
                />
                <Tab
                  className={styles.tab}
                  label={!!myFiltersList ?
                    <Badge className={styles.counter} badgeContent={myFiltersList?.list.length} color="secondary">
                      {t("common.components.filters.my_filters")}
                    </Badge> : null
                  }
                  disabled={!myFiltersList}
                  value={'my_filters'}
                />
              </TabList>
            </Box>
          </Box>
        )}
        <Box className={styles.panels}>
          <TabPanel className={styles.panel} value={`filter`}>
            <Filter
              activeField={activeField}
              initialFilter={initialFilter}
              setUpdateFilter={setUpdateFilter}
              setToggleDrawer={setToggleDrawer}
              setGenerateReport={setGenerateReport}
              setSelectedFilterId={setSelectedFilterId}
            />
          </TabPanel>
          <TabPanel className={styles.panel} value={`my_filters`}>
            {!!myFiltersList?.list.length ? (
              <MyFilters
                initialFilter={initialFilter}
                data={myFiltersList?.list}
                type={reportStorageProps.type}
                target={reportStorageProps.target}
                exceptions={reportStorageProps.exceptions}
                exceptionsIds={reportStorageProps.exceptionsIds}
                exceptionsStr={reportStorageProps.exceptionsStr}
                getMyFilters={getMyFilters}
                getPayloadFilters={getPayloadFilters}
                selectedMyFilterId={selectedMyFilterId}
                setSelectedMyFilterId={setSelectedMyFilterId}
                selectedFilterId={selectedFilterId}
                setSelectedFilterId={setSelectedFilterId}
                setUpdateFilter={setUpdateFilter}
                setToggleDrawer={setToggleDrawer}
                setGenerateReport={setGenerateReport}
                fieldsLabelPrefix={'admin.reports.filter.'}
                defaultFilter={defaultFilter}
                EditComponent={Filter}
              />
            ) : (
              <EmptyTable
                elevation={0}
                transparent
                messageKey={'common.messages.no_saved_filters'}
              />
            )}
          </TabPanel>
        </Box>
      </Box>
    </TabContext>
  );
};

export default FilterTabs;
