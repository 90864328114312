import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles/createTheme";
import { alpha } from "@mui/material/styles";

export const useStyles = makeStyles<Theme>((theme) => ({
  openBtn: {
    fontWeight: "normal",
    textTransform: "none",
    '@media (max-width: 767px)': {
      width: '100%',
      justifyContent: 'flex-start',
      padding: "6px 8px",
    },
    '& > span': {
      margin: "0 2px",
    },
    '& .group': {
      '@media (max-width: 480px)': {
        flexDirection: 'column',
        display: 'flex',
        width: '40%',
      }
    },
    '& .line': {
      '@media (max-width: 480px)': {
        display: 'none',
      }
    },
  },

  block: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 12,
    '@media (max-width: 767px)': {
      width: '100%',
      gap: 8,
    }
  },

  menu: {
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiPaper-root': {
      paddingTop: 0,
      paddingBottom: 0,
      '@media (min-width: 768px)': {
        minWidth: '512px',
        maxWidth: '512px',
      },
    },
  },

  dropdown: {
    width: '100%',
    //maxWidth: 512,
    padding: "24px 16px 16px 16px",
  },
  shortcutList: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: 8,
    marginBottom: 24,
  },

  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 16,
    padding: "16px 16px 0",
    margin: "24px -16px 0",
    borderTop: `1px solid ${alpha(theme.palette.secondary.main, 0.12)}`,
  },

  compare: {
    marginTop: 24,
  },
  compareFields: {
    marginTop: 24,
  },
  dateRange: {
    '@media (max-width: 480px)': {
      flexDirection: 'column',
      '& > .MuiTypography-root': {
        display: 'none',
      },
      '& > .MuiFormControl-root': {
        margin: '0 0 16px',
        '&:last-child': {
          margin: '0!important',
        }
      }
    }
  }
}));
