import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import { cookieToken } from "../../constants";
import { ListPayloadType, PaymentDetailsPayloadType, WidgetsPayloadType } from "../../store/admin/paymentHistory";

const getWidgets = (data: WidgetsPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.paymentHistory.widgets.replace('{affiliateId}', data.affId);

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : null}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getList = (data: ListPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.paymentHistory.list.replace('{affiliateId}', data.affId);

  const payload = {
    ...data.payload,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getPaymentDetails = (data: PaymentDetailsPayloadType) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.paymentHistory.paymentDetails.replace('{affiliateId}', data.affId);

  const payload = {
    id: data.id,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};


export const paymentHistoryService = {
  getWidgets,
  getList,
  getPaymentDetails,
};

