//core
import {useDispatch} from "react-redux";
import {
  getListData,
  getFiltersData,
  getAdjustmentListData,
  getAdjustmentReasonsData,
  getAdjustmentBrandsData,
  setAdjustmentAddData,
  setAdjustmentEditData,
  setAdjustmentApproveData,
  setAdjustmentRemoveData,
  getDownloadDetailsReport,
  getDownloadDetailsReportWithLogs,
  setPaymentData,
  clearPaymentData,
  clearManagerApproveData,
  PaymentSetData,
  setManagerApproveData,
  IApproveReports,
  setFinManagerApproveData,
  clearFiltersData,
  setCancelManagerApproveData,
  clearCancelManagerApproveData,
  setToPaidApproveData,
  clearToPaidApproveData,
  setCarryOverApproveData,
  clearCarryOverApproveData,
  clearAdjustmentEditData,
  clearAdjustmentAddData,
  clearAdjustmentListData,
  clearDetailsListData,
  setCancelCarryOverApproveData,
  clearCancelCarryOverApproveData,
  setChangeCommentData,
  clearChangeCommentData,
  setAdjustmentApprovePaidData,
  getFiltersField,
  getBrandsListData,
  getReportRowData,
  clearReportRowData,
  getColumnsData,
  clearColumnsState,
  getPaymentBreakdownData,
  clearPaymentBreakdownData,
  getAffiliatesIdsField,
  setPayoutRowData,
  clearAddPayoutRowData,
  setProcessingPaymentData,
  clearProcessingPaymentData,
  setCancelProcessingPaymentData,
  clearCancelProcessingPaymentData,
  getPayoutsData,
  clearPayoutsListData,
  createPayoutData,
  updatePayoutData,
  removePayoutData,
  getAvailablePaymentsData,
  editPaymentData,
  clearEditPaymentData,
  getLogsData,
  clearLogsListData,
  clearAvailablePaymentsData,
  clearCreatePayoutData,
  clearUpdatePayoutData,
  clearRemovePayoutData,
  getInvoiceData,
  uploadInvoiceData,
  removeInvoiceData,
  clearGetInvoiceData,
  clearUploadInvoiceData,
  clearAdjustmentsRemoveData,
  getPeriodsListData,
  clearPeriodsListData,
  clearAffiliatesIdsData,
  getConvertCurrencyData,
  clearСonvertCurrencyData,
  clearAdjustmentApproveData,
  setAdjustmentApproveCoverPrepaymentPaidData,
  getAvailablePaymentsListData,
  clearAvailablePaymentsListData,
} from "./index";

export const useDetailsFinReport = () => {
  const dispatch = useDispatch();

  const getTableList = (data: any) => dispatch(getListData(data));
  const getFiltersList = (data: any) => dispatch(getFiltersData(data));
  const getFiltersFieldData = (data: any) => dispatch(getFiltersField(data));
  const getAffiliatesIdsData = (data: any) => dispatch(getAffiliatesIdsField(data));
  const clearAffiliatesIds = () => dispatch(clearAffiliatesIdsData());
  const clearFiltersList = () => dispatch(clearFiltersData());

  const getAdjustmentList = (data: any) => dispatch(getAdjustmentListData(data));
  const getAdjustmentReasons = (data: any) => dispatch(getAdjustmentReasonsData(data));
  const getAdjustmentBrands = () => dispatch(getAdjustmentBrandsData());
  const setAdjustmentAdd = (data: any) => dispatch(setAdjustmentAddData(data));
  const setAdjustmentEdit = (data: any) => dispatch(setAdjustmentEditData(data));
  const setAdjustmentApprove = (data: any) => dispatch(setAdjustmentApproveData(data));
  const setAdjustmentApprovePaid = (data: any) => dispatch(setAdjustmentApprovePaidData(data));
  const setAdjustmentApproveCoverPrepaymentPaid = (data: any) => dispatch(setAdjustmentApproveCoverPrepaymentPaidData(data));
  const setAdjustmentRemove = (data: any) => dispatch(setAdjustmentRemoveData(data));
  const downloadDetailsReport = (data: any) => dispatch(getDownloadDetailsReport(data));
  const downloadDetailsReportWithLogs = (data: any) => dispatch(getDownloadDetailsReportWithLogs(data));
  const clearAdjustmentAddState = () => dispatch(clearAdjustmentAddData());
  const clearAdjustmentEditState = () => dispatch(clearAdjustmentEditData());
  const clearAdjustmentListState = () => dispatch(clearAdjustmentListData());
  const clearAdjustmentsRemoveState = () => dispatch(clearAdjustmentsRemoveData());
  const clearAdjustmentApproveState = () => dispatch(clearAdjustmentApproveData());
  const clearDetailsListState = () => dispatch(clearDetailsListData());

  const setPayment = (data: PaymentSetData) => dispatch(setPaymentData(data));
  const clearPaymentState = () => dispatch(clearPaymentData());

  const setManagerApprove = (data: IApproveReports) => dispatch(setManagerApproveData(data));
  const clearManagerApproveState = () => dispatch(clearManagerApproveData());
  const setCancelManagerApprove = (data: IApproveReports) => dispatch(setCancelManagerApproveData(data));
  const clearCancelManagerApproveState = () => dispatch(clearCancelManagerApproveData());

  const setFinManagerApprove = (data: IApproveReports) => dispatch(setFinManagerApproveData(data));

  const setToPaidApprove = (data: IApproveReports) => dispatch(setToPaidApproveData(data));
  const clearToPaidApprove = () => dispatch(clearToPaidApproveData());

  const setProcessingPayment = (data: IApproveReports) => dispatch(setProcessingPaymentData(data));
  const clearProcessingPayment = () => dispatch(clearProcessingPaymentData());

  const setCancelProcessingPayment = (data: IApproveReports) => dispatch(setCancelProcessingPaymentData(data));
  const clearCancelProcessingPayment = () => dispatch(clearCancelProcessingPaymentData());

  const setCarryOverApprove = (data: IApproveReports) => dispatch(setCarryOverApproveData(data));
  const clearCarryOverApprove = () => dispatch(clearCarryOverApproveData());
  const setCancelCarryOverApprove = (data: IApproveReports) => dispatch(setCancelCarryOverApproveData(data));
  const clearCancelCarryOverApprove = () => dispatch(clearCancelCarryOverApproveData());

  const setChangeComment = (data: any) => dispatch(setChangeCommentData(data));
  const clearChangeCommentState = () => dispatch(clearChangeCommentData());

  const getBrandsList = (data: any) => dispatch(getBrandsListData(data));

  const getReportRow = (data: any) => dispatch(getReportRowData(data));
  const clearReportRow = () => dispatch(clearReportRowData());

  const getColumns = () => dispatch(getColumnsData());
  const clearColumns = () => dispatch(clearColumnsState());

  const getPaymentBreakdown = (data: any) => dispatch(getPaymentBreakdownData(data));
  const clearPaymentBreakdown = () => dispatch(clearPaymentBreakdownData());

  const setPayoutRow = (data: any) => dispatch(setPayoutRowData(data));
  const clearAddPayoutRow = () => dispatch(clearAddPayoutRowData());

  const getPayouts = (data: any) => dispatch(getPayoutsData(data));
  const clearPayoutsList = () => dispatch(clearPayoutsListData());

  const createPayout = (data: any) => dispatch(createPayoutData(data));
  const clearCreatePayout = () => dispatch(clearCreatePayoutData());
  const clearUpdatePayout = () => dispatch(clearUpdatePayoutData());
  const clearRemovePayout = () => dispatch(clearRemovePayoutData());
  const updatePayout = (data: any) => dispatch(updatePayoutData(data));
  const removePayout = (data: any) => dispatch(removePayoutData(data));

  const getAvailablePayments = (data: any) => dispatch(getAvailablePaymentsData(data));
  const getAvailablePaymentsList = (data: any) => dispatch(getAvailablePaymentsListData(data));
  const clearAvailablePayments = () => dispatch(clearAvailablePaymentsData());
  const clearAvailablePaymentsList = () => dispatch(clearAvailablePaymentsListData());
  const editPayment = (data: any) => dispatch(editPaymentData(data));
  const clearEditPayment = () => dispatch(clearEditPaymentData());

  const getLogs = (data: any) => dispatch(getLogsData(data));
  const clearLogsList = () => dispatch(clearLogsListData());

  const getInvoice = (data: any) => dispatch(getInvoiceData(data));
  const uploadInvoice = (data: any) => dispatch(uploadInvoiceData(data));
  const removeInvoice = (data: any) => dispatch(removeInvoiceData(data));
  const clearUploadInvoice = () => dispatch(clearUploadInvoiceData());
  const clearGetInvoice = () => dispatch(clearGetInvoiceData());

  const getPeriodsList = (data: any) => dispatch(getPeriodsListData(data));
  const clearPeriodsList = () => dispatch(clearPeriodsListData());

  const getConvertCurrency = (data:any) => dispatch(getConvertCurrencyData(data));
  const clearСonvertCurrency = () => dispatch(clearСonvertCurrencyData());

  return {
    getTableList,
    getFiltersList,
    clearFiltersList,
    getAdjustmentList,
    getAdjustmentReasons,
    getAdjustmentBrands,
    setAdjustmentAdd,
    setAdjustmentEdit,
    setAdjustmentApprove,
    setAdjustmentRemove,
    downloadDetailsReport,
    downloadDetailsReportWithLogs,
    setPayment,
    clearPaymentState,
    setManagerApprove,
    clearManagerApproveState,
    setFinManagerApprove,
    setCancelManagerApprove,
    clearCancelManagerApproveState,
    setToPaidApprove,
    clearToPaidApprove,
    setCarryOverApprove,
    clearCarryOverApprove,
    clearAdjustmentEditState,
    clearAdjustmentAddState,
    clearAdjustmentListState,
    clearDetailsListState,
    setCancelCarryOverApprove,
    clearCancelCarryOverApprove,
    setChangeComment,
    clearChangeCommentState,
    setAdjustmentApprovePaid,
    getFiltersFieldData,
    getBrandsList,
    getReportRow,
    clearReportRow,
    getColumns,
    clearColumns,
    getPaymentBreakdown,
    clearPaymentBreakdown,
    getAffiliatesIdsData,
    clearAffiliatesIds,
    setPayoutRow,
    clearAddPayoutRow,
    setProcessingPayment,
    clearProcessingPayment,
    setCancelProcessingPayment,
    clearCancelProcessingPayment,
    getPayouts,
    clearPayoutsList,
    createPayout,
    updatePayout,
    removePayout,
    getAvailablePayments,
    editPayment,
    clearEditPayment,
    getLogs,
    clearLogsList,
    clearAvailablePayments,
    clearCreatePayout,
    clearUpdatePayout,
    clearRemovePayout,
    getInvoice,
    uploadInvoice,
    removeInvoice,
    clearUploadInvoice,
    clearGetInvoice,
    clearAdjustmentsRemoveState,
    getPeriodsList,
    clearPeriodsList,
    getConvertCurrency,
    clearСonvertCurrency,
    clearAdjustmentApproveState,
    setAdjustmentApproveCoverPrepaymentPaid,
    getAvailablePaymentsList,
    clearAvailablePaymentsList
  }
}
