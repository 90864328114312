import { api } from "../../api";
import { handleResponse, RequestOptions} from "../types";
import { getCookie} from "../../helpers/utils";
import { cookieSupervision, cookieToken} from "../../constants";

const getList = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.employees.list;

  const sendData = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(sendData))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getListRoles = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.employees.list_roles;
  const platformId = !!data.platform_id && `?platform_id=${data.platform_id}` || ''

  return fetch(
    `${endpoint}${platformId}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};


const getListDepartments = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.employees.list_departments;
  const platformId = !!data.platform_id && `?platform_id=${data.platform_id}` || ''

  const sendData = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}${platformId}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};


const addNewAdmin = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.employees.add_admin;

  const sendData = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(sendData))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};


const removeAdmin = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.employees.remove_admin;

  const url = `${endpoint
    .replace('{adminId}', data.admin_id)}`;

  const sendData = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${url}`,
    {...RequestOptions('DELETE', JSON.stringify(sendData))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};


const inviteAdmin = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.employees.invite_admin;

  const url = `${endpoint
    .replace('{adminId}', data.admin_id)}`;

  const sendData = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${url}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const blockAdmin = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.employees.block_admin;

  const url = `${endpoint
    .replace('{adminId}', data.admin_id)}`;

  const sendData = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${url}`,
    {...RequestOptions('POST', JSON.stringify(sendData))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};



const editAdmin = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.employees.edit_admin;

  const url = `${endpoint
    .replace('{adminId}', data.admin_id)}`;

  const sendData = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${url}`,
    {...RequestOptions('PUT', JSON.stringify(sendData))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getRoles = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.employees.roles;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

export const employeesService = {
  getList,
  getListRoles,
  getListDepartments,
  addNewAdmin,
  removeAdmin,
  inviteAdmin,
  blockAdmin,
  editAdmin,
  getRoles,
};
