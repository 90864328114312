//core
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";

//service
import {financeReportService} from "../../../../services/admin/finance_report.service";
import {setGeneralProgressHide} from "../../../common/ui";
import {setUser} from "../../../common/user";
import {getFiltersData} from "../details_finance_report";

type filtersDataType = {
  list: any[];
  total_entries: number;
  start_row_num: number;
  end_row_num: number;
  total_pages: number;
  label?: string;
  search?: string
  updateList?: boolean
}

export type financeReportSliceState = {
  list: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  financeReportData: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  filters: {
    data: {
      departments: filtersDataType | null;
      chiefs: filtersDataType | null;
      affiliates: filtersDataType | null;
      platforms: filtersDataType | null;
      statuses: filtersDataType | null;
    },
    error: any,
    isFetching: boolean,
  },
}

const initialState: financeReportSliceState = {
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  financeReportData: {
    data: null,
    error: null,
    isFetching: false,
  },
  filters: {
    data: {
      departments: null,
      chiefs: null,
      affiliates: null,
      platforms: null,
      statuses: null,
    },
    error: null,
    isFetching: false,
  },
}

export const getListData: any = createAsyncThunk(
  'finance_report/list',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await financeReportService.getListData(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getFiltersField: any = createAsyncThunk(
  'details_finance_report/getFiltersField',
  async (payload:any, { rejectWithValue }) => {
    try {
      const response = await financeReportService.getFiltersField(payload);
      const data = await response.json();
      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

//slice
const financeReportSlice = createSlice({
  name: 'finance_report',
  initialState: initialState,
  reducers: {
    financeReportSetError(state, action) {
      state.financeReportData.error = action.payload;
    },
    setFinanceReportData(state, action) {
      state.financeReportData.data = action.payload;
    },
    clearFiltersData(state) {
      state.filters.error = null;
      state.filters.isFetching = false;
      state.filters.data = {
        departments: null,
        chiefs: null,
        affiliates: null,
        platforms: null,
        statuses: null,
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<financeReportSliceState>>) => {
    builder.addCase(getListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getListData.fulfilled, (state, action ) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getListData.rejected, (state, action ) => {
      state.list.error = action.payload;
    });

    builder.addCase(getFiltersData.pending, (state ) => {
      state.filters.isFetching = true;
      state.filters.error = null;
    });
    builder.addCase(getFiltersData.fulfilled, (state, action) => {
      state.filters.error = null;
      state.filters.isFetching = false;
      //@ts-ignore
      if (state.filters.data[action.payload.label] && state.filters.data[action.payload.label].search === action.payload.search && action.payload.updateList !== true) {
        //@ts-ignore
        state.filters.data[action.payload.label] = {
          ...action.payload,
          list: [
            //@ts-ignore
            ...(state.filters.data[action.payload.label] ? state.filters.data[action.payload.label].list : []),
            ...action.payload.list
          ],
        }
      } else {
        //@ts-ignore
        state.filters.data[action.payload.label] = {
          ...action.payload,
          list: [
            //@ts-ignore
            ...action.payload.list
          ],
        }
      }
      //@ts-ignore
      if (!!state.filters.data[action.payload.label] && state.filters.data[action.payload.label].label !== 'statuses') {
        //@ts-ignore
        state.filters.data[action.payload.label].list.sort((prev,next)=> {
          if ( prev.id < next.id ) return -1;
          if ( prev.id < next.id ) return 1;
        });
      }
    });
    builder.addCase(getFiltersData.rejected, (state, action ) => {
      state.filters.error = action.payload;
    });
  },
});

export default financeReportSlice.reducer;

export const {
  financeReportSetError,
  setFinanceReportData,
  clearFiltersData,
} = financeReportSlice.actions;
