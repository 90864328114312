//core
import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

//icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

//theme
import {Theme} from "@mui/material/styles/createTheme";

interface IBadgeFieldProps {
  value: boolean;
}

const CheckedField: React.FC<IBadgeFieldProps> = ({ value = false }): JSX.Element => {
  const useStyles = makeStyles<Theme>((theme) => ({
    box: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 0,
    },
    icon: {
      width: 20,
      height: 20,
      color: theme.palette.action.active,
    },
    boxText: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }));
  const styles = useStyles();

  return (<>
    {value
      ? (
        <Box className={styles.box}>
          <CheckCircleIcon className={styles.icon} color="primary" />
        </Box>
      ) : (
        <Box className={styles.boxText}>—</Box>
      )
    }
  </>);
};

export default CheckedField;
