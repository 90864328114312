//core
import React from "react";
import { Box, Card } from "@mui/material";

//styles
import { useStyles } from "./styles";

//components
import DetailsInfoBlock from "../../../../../TableRowItem/components/DetailsInfoBlock";

interface IMobileListProps {
  data: any;
}

const MobileList: React.FC<IMobileListProps> = ({
  data,
}): JSX.Element => {
  const styles = useStyles();

  return (
    <Box className={styles.list}>
      {data.list.map((row: any, i: any) => (
        <Card key={`row-${i}`} className={styles.row} variant="outlined">
          {data.columns.map((column: any, j: number) => (
            <Box key={`row-field-${i}-${j}`}>
              <DetailsInfoBlock
                column={column}
                row={row}
                key={column.name}
                translationColumnsPrefix={'columns_for_display.table.generate_report.'}
                inline={true}
              />
            </Box>
          ))}
        </Card>
      ))}
    </Box>
  );
};

export default MobileList;
