import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { setUser } from "../../common/user";
import { conditionsService } from "../../../services/admin/conditions.service";
import { OrderType } from "../../../components/affiliate/Trackers";
import {setGeneralProgressHide, setGeneralProgressShow, setGeneralSnackbarState} from "../../common/ui";


export type payloadConditionsList = {
  additional_options: null;
  page: number;
  perPage?: number;
  segment_id: number;
  order: OrderType;
  search: {
    keyword: string;
  };
};

export type payloadCloneConditions = {
  id: number | string;
};

export type conditionsListItem = {
  id: number;
  title: string;
  description: string;
  type_of_transaction: string;
  created_at: string;
  read_only: boolean;
  created_by: string;
  updated_by: string;
  is_archived: boolean;
  platform_name: string;
};

export type conditionsColumnsItem = {
  name: string;
  type: string;
  sort: boolean;
  match?: boolean;
};

export interface IConditionsData {
  list: conditionsListItem[];
  columns_for_display_data: conditionsColumnsItem[];
  start_row_num: number;
  end_row_num: number;
  total_entries: number | string;
  total_pages: number;
  locale_key: string;
  stateList: string[];
};

export type cloneConditionDataType = {
  id: number;
};


export type conditionsSliceState = {
  list: {
    data: IConditionsData | null,
    error: any,
    isFetching: boolean,
  },
  deleteCondition: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  cloneCondition: {
    data: cloneConditionDataType | null,
    error: any,
    isFetching: boolean,
  },
  constants: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  createCondition: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  viewCondition: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  editCondition: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  offersId: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  externalCurrency: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  archiveDeal: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  renameDeal: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  archivedList: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  brandsList: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  filterCreator: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  brandsForDeal: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
};

const initialState: conditionsSliceState = {
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  deleteCondition: {
    data: null,
    error: null,
    isFetching: false,
  },
  cloneCondition: {
    data: null,
    error: null,
    isFetching: false,
  },
  constants: {
    data: null,
    error: null,
    isFetching: false,
  },
  createCondition: {
    data: null,
    error: null,
    isFetching: false,
  },
  viewCondition: {
    data: null,
    error: null,
    isFetching: false,
  },
  editCondition: {
    data: null,
    error: null,
    isFetching: false,
  },
  offersId: {
    data: null,
    error: null,
    isFetching: false,
  },
  externalCurrency: {
    data: null,
    error: null,
    isFetching: false,
  },
  archiveDeal: {
    data: null,
    error: null,
    isFetching: false,
  },
  renameDeal: {
    data: null,
    error: null,
    isFetching: false,
  },
  archivedList: {
    data: null,
    error: null,
    isFetching: false,
  },
  brandsList: {
    data: null,
    error: null,
    isFetching: false,
  },
  filterCreator: {
    data: null,
    error: null,
    isFetching: false,
  },
  brandsForDeal: {
    data: null,
    error: null,
    isFetching: false,
  },
};

export const getListData: any = createAsyncThunk(
  'conditions/getListData',
  async (payload: payloadConditionsList, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await conditionsService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const deleteConditionData: any = createAsyncThunk(
  'conditions/deleteCondition',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await conditionsService.deleteCondition(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.removed',
          })
        );
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const cloneConditionData: any = createAsyncThunk(
  'conditions/cloneConditionData',
  async (payload: payloadCloneConditions, { rejectWithValue, dispatch }) => {
    try {
      const response = await conditionsService.cloneCondition(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.copied',
          })
        );
      }

      if (!response.ok) {
        if (data.hasOwnProperty('error') && data.error === 'condition.copy.brand.access') {
          dispatch(
            setGeneralSnackbarState({
              open: true,
              type: 'warning',
              message: 'error',
              messageKey: 'common.messages.condition_brand_access',
            })
          );
        } else {
          dispatch(
            setGeneralSnackbarState({
              open: true,
              type: 'warning',
              message: 'error',
              messageKey: 'common.messages.error',
            })
          );
        }

        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getConstantsData: any = createAsyncThunk(
  'conditions/getConstantsData',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await conditionsService.getConstants();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const createConditionData: any = createAsyncThunk(
  'conditions/createConditionData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await conditionsService.createCondition(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getViewConditionData: any = createAsyncThunk(
  'conditions/getViewConditionData',
  async (id: string, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await conditionsService.getCondition(id);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue({
          status: response.status,
          statusText: response.statusText,
          data: data,
        })
      }
      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const editConditionData: any = createAsyncThunk(
  'conditions/editConditionData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await conditionsService.editCondition(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok && data.hasOwnProperty('errors') && data.errors.hasOwnProperty('condition_filters_attributes')) {
        for (const key in data.errors.condition_filters_attributes) {
          if (data.errors.condition_filters_attributes[key].value[0].indexOf('is not available brand') !== -1) {
            dispatch(
              setGeneralSnackbarState({
                open: true,
                type: 'warning',
                message: 'error',
                messageKey: 'common.messages.not_available_brand',
              })
            );
            return rejectWithValue(data)
          }
        }
      }

      if (!response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);


export const getOffersIdData: any = createAsyncThunk(
  'conditions/getOffersIdData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await conditionsService.getOffersId(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


export const getExternalCurrencyData: any = createAsyncThunk(
  'conditions/getExternalCurrencyData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await conditionsService.getExternalCurrency(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());

        return rejectWithValue({
          status: response.status,
          statusText: response.statusText,
        })
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);
export const archiveDealData: any = createAsyncThunk(
    'conditions/archiveDealData',
    async (payload: any, { rejectWithValue, dispatch }) => {
      try {
        const response = await conditionsService.archiveDeal(payload);
        const data = await response.json();

        if (data.hasOwnProperty('authorized') && data.authorized === false) {
          dispatch(setUser(null));
          localStorage.removeItem('user');
        }

        if (response.ok) {
          dispatch(
              setGeneralSnackbarState({
                open: true,
                type: 'success',
                message: 'success',
                messageKey: 'common.messages.success',
              })
          );
        }

        if (!response.ok) {
          dispatch(
              setGeneralSnackbarState({
                open: true,
                type: 'warning',
                message: 'error',
                messageKey: 'common.messages.error',
              })
          );
          return rejectWithValue(data)
        }

        return data;
      } catch (error) {
        return rejectWithValue(error)
      }
    }
);


export const renameDealData: any = createAsyncThunk(
    'conditions/renameDealData',
    async (payload: any, { rejectWithValue, dispatch }) => {
      try {
        const response = await conditionsService.renameDeal(payload);
        const data = await response.json();

        if (data.hasOwnProperty('authorized') && data.authorized === false) {
          dispatch(setUser(null));
          localStorage.removeItem('user');
        }

        if (response.ok) {
          dispatch(
              setGeneralSnackbarState({
                open: true,
                type: 'success',
                message: 'success',
                messageKey: 'common.messages.success',
              })
          );
        }

        if (!response.ok) {
          dispatch(
              setGeneralSnackbarState({
                open: true,
                type: 'warning',
                message: 'error',
                messageKey: 'common.messages.error',
              })
          );
          return rejectWithValue(data)
        }

        return data;
      } catch (error) {
        return rejectWithValue(error)
      }
    }
);

export const getArchivedListData: any = createAsyncThunk(
  'conditions/getArchivedListData',
  async (payload: payloadConditionsList, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await conditionsService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getBrandsListData: any = createAsyncThunk(
  'conditions/getBrandsListData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await conditionsService.getBrandsList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getBrandsForDealData: any = createAsyncThunk(
  'conditions/getBrandsForDealData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await conditionsService.getBrandsList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getFilterCreatorData: any = createAsyncThunk(
  'conditions/getFilterCreatorData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await conditionsService.getFilterCreator(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);


export const conditionsSlice = createSlice({
  name: 'conditions',
  initialState: initialState,
  reducers: {
    clearListState(state) {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = null;
    },
    clearDeleteConditionState(state) {
      state.deleteCondition.error = null;
      state.deleteCondition.isFetching = false;
      state.deleteCondition.data = null;
    },
    clearCloneConditionState(state) {
      state.cloneCondition.error = null;
      state.cloneCondition.isFetching = false;
      state.cloneCondition.data = null;
    },
    clearCreateConditionState(state) {
      state.createCondition.error = null;
      state.createCondition.isFetching = false;
      state.createCondition.data = null;
    },
    clearViewConditionState(state) {
      state.viewCondition.error = null;
      state.viewCondition.isFetching = false;
      state.viewCondition.data = null;
    },
    clearConstantsState(state) {
      state.constants.error = null;
      state.constants.isFetching = false;
      state.constants.data = null;
    },
    clearEditConditionState(state) {
      state.editCondition.error = null;
      state.editCondition.isFetching = false;
      state.editCondition.data = null;
    },
    clearExternalCurrencyConditionState(state) {
      state.externalCurrency.error = null;
      state.externalCurrency.isFetching = false;
      state.externalCurrency.data = null;
    },
    clearOffersIdState(state) {
      state.offersId.error = null;
      state.offersId.isFetching = false;
      state.offersId.data = null;
    },
    clearArchiveConditionState(state) {
      state.archiveDeal.error = null;
      state.archiveDeal.isFetching = false;
      state.archiveDeal.data = null;
    },
    clearArchivedListState(state) {
      state.archivedList.error = null;
      state.archivedList.isFetching = false;
      state.archivedList.data = null;
    },
    clearBrandsListState(state) {
      state.brandsList.error = null;
      state.brandsList.isFetching = false;
      state.brandsList.data = null;
    },
    clearBrandsForDealState(state) {
      state.brandsForDeal.error = null;
      state.brandsForDeal.isFetching = false;
      state.brandsForDeal.data = null;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<conditionsSliceState>>) => {
    builder.addCase(getListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getListData.fulfilled, (state , action) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getListData.rejected, (state , action) => {
      state.list.isFetching = false;
      state.list.error = action.payload;
      state.list.data = null;
    });

    builder.addCase(deleteConditionData.pending, (state ) => {
      state.deleteCondition.isFetching = true;
      state.deleteCondition.error = null;
    });
    builder.addCase(deleteConditionData.fulfilled, (state , action) => {
      state.deleteCondition.error = null;
      state.deleteCondition.isFetching = false;
      state.deleteCondition.data = action.payload;
    });
    builder.addCase(deleteConditionData.rejected, (state , action) => {
      state.deleteCondition.isFetching = false;
      state.deleteCondition.error = action.payload;
      state.deleteCondition.data = null;
    });

    builder.addCase(cloneConditionData.pending, (state ) => {
      state.cloneCondition.isFetching = true;
      state.cloneCondition.error = null;
    });
    builder.addCase(cloneConditionData.fulfilled, (state , action) => {
      state.cloneCondition.error = null;
      state.cloneCondition.isFetching = false;
      state.cloneCondition.data = action.payload;
    });
    builder.addCase(cloneConditionData.rejected, (state , action) => {
      state.cloneCondition.isFetching = false;
      state.cloneCondition.error = action.payload;
      state.cloneCondition.data = null;
    });

    builder.addCase(getConstantsData.pending, (state ) => {
      state.constants.isFetching = true;
      state.constants.error = null;
    });
    builder.addCase(getConstantsData.fulfilled, (state , action) => {
      state.constants.error = null;
      state.constants.isFetching = false;
      state.constants.data = action.payload.constants;
    });
    builder.addCase(getConstantsData.rejected, (state , action) => {
      state.constants.isFetching = false;
      state.constants.error = action.payload;
      state.constants.data = null;
    });

    builder.addCase(createConditionData.pending, (state ) => {
      state.createCondition.isFetching = true;
      state.createCondition.error = null;
    });
    builder.addCase(createConditionData.fulfilled, (state , action) => {
      state.createCondition.error = null;
      state.createCondition.isFetching = false;
      state.createCondition.data = action.payload;
    });
    builder.addCase(createConditionData.rejected, (state , action) => {
      state.createCondition.isFetching = false;
      state.createCondition.error = action.payload;
      state.createCondition.data = null;
    });

    builder.addCase(getViewConditionData.pending, (state ) => {
      state.viewCondition.isFetching = true;
      state.viewCondition.error = null;
    });
    builder.addCase(getViewConditionData.fulfilled, (state , action) => {
      state.viewCondition.error = null;
      state.viewCondition.isFetching = false;
      state.viewCondition.data = action.payload;
    });
    builder.addCase(getViewConditionData.rejected, (state , action) => {
      state.viewCondition.isFetching = false;
      state.viewCondition.error = action.payload;
      state.viewCondition.data = null;
    });

    builder.addCase(editConditionData.pending, (state ) => {
      state.editCondition.isFetching = true;
      state.editCondition.error = null;
    });
    builder.addCase(editConditionData.fulfilled, (state , action) => {
      state.editCondition.error = null;
      state.editCondition.isFetching = false;
      state.editCondition.data = action.payload;
    });
    builder.addCase(editConditionData.rejected, (state , action) => {
      state.editCondition.isFetching = false;
      state.editCondition.error = action.payload;
      state.editCondition.data = null;
    });

    builder.addCase(getOffersIdData.pending, (state ) => {
      state.offersId.isFetching = true;
      state.offersId.error = null;
    });
    builder.addCase(getOffersIdData.fulfilled, (state , action) => {
      state.offersId.error = null;
      state.offersId.isFetching = false;
      state.offersId.data = action.payload;
    });
    builder.addCase(getOffersIdData.rejected, (state , action) => {
      state.offersId.isFetching = false;
      state.offersId.error = action.payload;
      state.offersId.data = null;
    });

    builder.addCase(getExternalCurrencyData.pending, (state ) => {
      state.externalCurrency.isFetching = true;
      state.externalCurrency.error = null;
    });
    builder.addCase(getExternalCurrencyData.fulfilled, (state , action) => {
      state.externalCurrency.error = null;
      state.externalCurrency.isFetching = false;
      state.externalCurrency.data = action.payload;
    });
    builder.addCase(getExternalCurrencyData.rejected, (state , action) => {
      state.externalCurrency.isFetching = false;
      state.externalCurrency.error = action.payload;
      state.externalCurrency.data = null;
    });

    builder.addCase(archiveDealData.pending, (state ) => {
      state.archiveDeal.isFetching = true;
      state.archiveDeal.error = null;
    });
    builder.addCase(archiveDealData.fulfilled, (state , action) => {
      state.archiveDeal.error = null;
      state.archiveDeal.isFetching = false;
      state.archiveDeal.data = action.payload;
    });
    builder.addCase(archiveDealData.rejected, (state , action) => {
      state.archiveDeal.isFetching = false;
      state.archiveDeal.error = action.payload;
      state.archiveDeal.data = null;
    });

    builder.addCase(renameDealData.pending, (state ) => {
      state.renameDeal.isFetching = true;
      state.renameDeal.error = null;
    });
    builder.addCase(renameDealData.fulfilled, (state , action) => {
      state.renameDeal.error = null;
      state.renameDeal.isFetching = false;
      state.renameDeal.data = action.payload;
    });
    builder.addCase(renameDealData.rejected, (state , action) => {
      state.renameDeal.isFetching = false;
      state.renameDeal.error = action.payload;
      state.renameDeal.data = null;
    });

    builder.addCase(getArchivedListData.pending, (state ) => {
      state.archivedList.isFetching = true;
      state.archivedList.error = null;
    });
    builder.addCase(getArchivedListData.fulfilled, (state , action) => {
      state.archivedList.error = null;
      state.archivedList.isFetching = false;
      state.archivedList.data = action.payload;
    });
    builder.addCase(getArchivedListData.rejected, (state , action) => {
      state.archivedList.isFetching = false;
      state.archivedList.error = action.payload;
      state.archivedList.data = null;
    });

    builder.addCase(getBrandsListData.pending, (state ) => {
      state.brandsList.isFetching = true;
      state.brandsList.error = null;
    });
    builder.addCase(getBrandsListData.fulfilled, (state , action) => {
      state.brandsList.error = null;
      state.brandsList.isFetching = false;
      state.brandsList.data = action.payload;
    });
    builder.addCase(getBrandsListData.rejected, (state , action) => {
      state.brandsList.isFetching = false;
      state.brandsList.error = action.payload;
      state.brandsList.data = null;
    });

    builder.addCase(getFilterCreatorData.pending, (state ) => {
      state.filterCreator.isFetching = true;
      state.filterCreator.error = null;
    });
    builder.addCase(getFilterCreatorData.fulfilled, (state , action) => {
      state.filterCreator.error = null;
      state.filterCreator.isFetching = false;
      state.filterCreator.data = action.payload;
    });
    builder.addCase(getFilterCreatorData.rejected, (state , action) => {
      state.filterCreator.isFetching = false;
      state.filterCreator.error = action.payload;
      state.filterCreator.data = null;
    });

    builder.addCase(getBrandsForDealData.pending, (state ) => {
      state.brandsForDeal.isFetching = true;
      state.brandsForDeal.error = null;
    });
    builder.addCase(getBrandsForDealData.fulfilled, (state , action) => {
      state.brandsForDeal.error = null;
      state.brandsForDeal.isFetching = false;
      state.brandsForDeal.data = action.payload;
    });
    builder.addCase(getBrandsForDealData.rejected, (state , action) => {
      state.brandsForDeal.isFetching = false;
      state.brandsForDeal.error = action.payload;
      state.brandsForDeal.data = null;
    });
  },
});

export const {
  clearListState,
  clearDeleteConditionState,
  clearCloneConditionState,
  clearCreateConditionState,
  clearViewConditionState,
  clearConstantsState,
  clearEditConditionState,
  clearExternalCurrencyConditionState,
  clearOffersIdState,
  clearArchiveConditionState,
  clearArchivedListState,
  clearBrandsListState,
  clearBrandsForDealState,
} = conditionsSlice.actions;

export default conditionsSlice.reducer;
