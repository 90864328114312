import React from "react";
import { useTranslation } from "react-i18next";

interface IAdjustmentCRUDProps {
  data: any;
  label: string;
}

const AdjustmentCRUD: React.FC<IAdjustmentCRUDProps> = ({
  data,
  label,
}): JSX.Element => {
  const { t } = useTranslation();

  const message = `
    ${label} ${t("admin.reports.detailed_fin_report.logs.actions.adjustment.reason")}: ${t(`admin.details_finance_report.adjustments.options.${data.reason}`)}, ${t("admin.reports.detailed_fin_report.logs.actions.adjustment.amount")}: ${data.amount}.   
  `;

  return (
    <>
      {message}
    </>
  );
};

export default AdjustmentCRUD;
