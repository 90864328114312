//core
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";

//services
import { dashboardV2Service } from "../../../services/admin/dashboardV2.service";

//actions
import {
  setCreateCreativeState,
  setGeneralProgressShow,
  setGeneralProgressHide,
  setGeneralSnackbarState,
} from "../../common/ui";
import { setUser } from "../../common/user";

export type GridWidgetItemType = {
  id: string;
  type: string;
}

export type dashboardV2SliceState = {
  filters: {
    data: any | null,
    error: any | null,
    isFetching: boolean,
  },
  topWidgets: {
    data: any | null,
    error: any | null,
    isFetching: boolean,
  },
  gridWidgets: {
    data: GridWidgetItemType[] | null,
    error: any | null,
    isFetching: boolean,
  },
  statisticCharts: {
    data: any | null,
    error: any | null,
    isFetching: boolean,
  },
  statisticChartsSettings: {
    data: any | null,
    error: any | null,
    isFetching: boolean,
  },
  affiliates: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  creatives: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  trackers: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  brands: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
}

const initialState: dashboardV2SliceState = {
  filters: {
    data: null,
    error: null,
    isFetching: false,
  },
  topWidgets: {
    data: null,
    error: null,
    isFetching: false,
  },
  gridWidgets: {
    data: null,
    error: null,
    isFetching: false,
  },
  statisticCharts: {
    data: null,
    error: null,
    isFetching: false,
  },
  statisticChartsSettings: {
    data: null,
    error: null,
    isFetching: false,
  },
  affiliates: {
    data: null,
    error: null,
    isFetching: false,
  },
  creatives: {
    data: null,
    error: null,
    isFetching: false,
  },
  trackers: {
    data: null,
    error: null,
    isFetching: false,
  },
  brands: {
    data: null,
    error: null,
    isFetching: false,
  },
}

//action creators
export const getFiltersData: any = createAsyncThunk(
  'dashboardV2/getFiltersData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await dashboardV2Service.getFilters({
        namespace: payload.namespace,
      });
      const data = await response.json();
      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue({
          status: response.status,
          statusText: response.statusText,
          data: data,
        })
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {

      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getTopWidgetsData: any = createAsyncThunk(
  'dashboardV2/getTopWidgetsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await dashboardV2Service.getWidgetDataByName({
        name: 'top_widgets',
        namespace: payload.namespace,
        payload: payload.payload,
      });
      const data = await response.json();
      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue({
          status: response.status,
          statusText: response.statusText,
          data: data,
        })
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {

      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getGridWidgetsData: any = createAsyncThunk(
  'dashboardV2/getGridWidgetsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await dashboardV2Service.getWidgetsGrid({
        namespace: payload.namespace,
      });
      const data = await response.json();
      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue({
          status: response.status,
          statusText: response.statusText,
          data: data,
        })
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {

      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getStatisticChartsData: any = createAsyncThunk(
  'dashboardV2/getStatisticChartsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await dashboardV2Service.getWidgetDataByName({
        name: 'statistic_charts',
        namespace: payload.namespace,
        payload: payload.payload,
      });
      const data = await response.json();
      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue({
          status: response.status,
          statusText: response.statusText,
          data: data,
        })
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getStatisticChartsSettings: any = createAsyncThunk(
  'dashboardV2/getStatisticChartsSettings',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await dashboardV2Service.getWidgetSettingsByName({
        name: 'statistic_charts',
        namespace: payload.namespace,
      });
      const data = await response.json();
      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue({
          status: response.status,
          statusText: response.statusText,
          data: data,
        })
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getAffiliatesData: any = createAsyncThunk(
  'dashboardV2/getAffiliatesData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await dashboardV2Service.getAffiliates(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getCreativesData: any = createAsyncThunk(
  'dashboardV2/getCreativesData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await dashboardV2Service.getCreatives(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getTrackersData: any = createAsyncThunk(
  'dashboardV2/getTrackersData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await dashboardV2Service.getTrackers(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getBrandsData: any = createAsyncThunk(
  'dashboardV2/getBrandsData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await dashboardV2Service.getBrands(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const dashboardV2Slice = createSlice({
  name: 'dashboardV2',
  initialState: initialState,
  reducers: {
    resetState(state) {
      state = initialState;
    },
    clearTopWidgetsState(state) {
      state.topWidgets.data = null;
      state.topWidgets.error = null;
      state.topWidgets.isFetching = false;
    },
    clearGridWidgetsState(state) {
      state.gridWidgets.data = null;
      state.gridWidgets.error = null;
      state.gridWidgets.isFetching = false;
    },
    clearStatisticChartsState(state) {
      state.topWidgets.data = null;
      state.topWidgets.error = null;
      state.topWidgets.isFetching = false;
    },
    clearStatisticChartsSettingsState(state) {
      state.statisticChartsSettings.data = null;
      state.statisticChartsSettings.error = null;
      state.statisticChartsSettings.isFetching = false;
    },
    clearFiltersState(state) {
      state.filters.data = null;
      state.filters.error = null;
      state.filters.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<dashboardV2SliceState>>) => {
    builder.addCase(getStatisticChartsSettings.pending, (state) => {
      state.statisticChartsSettings.isFetching = true;
      state.statisticChartsSettings.error = null;
    });
    builder.addCase(getStatisticChartsSettings.fulfilled, (state, action) => {
      state.statisticChartsSettings.error = null;
      state.statisticChartsSettings.isFetching = false;
      state.statisticChartsSettings.data = action.payload;
    });
    builder.addCase(getStatisticChartsSettings.rejected, (state, action) => {
      state.statisticChartsSettings.error = action.payload;
      state.statisticChartsSettings.isFetching = false;
    });

    builder.addCase(getTopWidgetsData.pending, (state) => {
      state.topWidgets.isFetching = true;
      state.topWidgets.error = null;
    });
    builder.addCase(getTopWidgetsData.fulfilled, (state, action) => {
      state.topWidgets.error = null;
      state.topWidgets.isFetching = false;
      state.topWidgets.data = action.payload;
    });
    builder.addCase(getTopWidgetsData.rejected, (state, action) => {
      state.topWidgets.error = action.payload;
    });

    builder.addCase(getStatisticChartsData.pending, (state) => {
      state.statisticCharts.isFetching = true;
      state.statisticCharts.error = null;
    });
    builder.addCase(getStatisticChartsData.fulfilled, (state, action) => {
      state.statisticCharts.error = null;
      state.statisticCharts.isFetching = false;
      state.statisticCharts.data = action.payload;
    });
    builder.addCase(getStatisticChartsData.rejected, (state, action) => {
      state.statisticCharts.error = action.payload;
    });

    builder.addCase(getGridWidgetsData.pending, (state) => {
      state.gridWidgets.isFetching = true;
      state.gridWidgets.error = null;
    });
    builder.addCase(getGridWidgetsData.fulfilled, (state, action) => {
      state.gridWidgets.error = null;
      state.gridWidgets.isFetching = false;
      state.gridWidgets.data = action.payload;
    });
    builder.addCase(getGridWidgetsData.rejected, (state, action) => {
      state.gridWidgets.error = action.payload;
    });

    builder.addCase(getFiltersData.pending, (state) => {
      state.filters.isFetching = true;
      state.filters.error = null;
    });
    builder.addCase(getFiltersData.fulfilled, (state, action) => {
      state.filters.error = null;
      state.filters.isFetching = false;
      state.filters.data = action.payload;
    });
    builder.addCase(getFiltersData.rejected, (state, action) => {
      state.filters.error = action.payload;
    });

    builder.addCase(getAffiliatesData.pending, (state) => {
      state.affiliates.isFetching = true;
      state.affiliates.error = null;
    });
    builder.addCase(getAffiliatesData.fulfilled, (state, action) => {
      state.affiliates.error = null;
      state.affiliates.isFetching = false;
      state.affiliates.data = action.payload;
    });
    builder.addCase(getAffiliatesData.rejected, (state, action) => {
      state.affiliates.error = action.payload;
    });

    builder.addCase(getCreativesData.pending, (state) => {
      state.creatives.isFetching = true;
      state.creatives.error = null;
    });
    builder.addCase(getCreativesData.fulfilled, (state, action) => {
      state.creatives.error = null;
      state.creatives.isFetching = false;
      state.creatives.data = action.payload;
    });
    builder.addCase(getCreativesData.rejected, (state, action) => {
      state.creatives.error = action.payload;
    });

    builder.addCase(getTrackersData.pending, (state) => {
      state.trackers.isFetching = true;
      state.trackers.error = null;
    });
    builder.addCase(getTrackersData.fulfilled, (state, action) => {
      state.trackers.error = null;
      state.trackers.isFetching = false;
      state.trackers.data = action.payload;
    });
    builder.addCase(getTrackersData.rejected, (state, action) => {
      state.trackers.error = action.payload;
    });

    builder.addCase(getBrandsData.pending, (state) => {
      state.brands.isFetching = true;
      state.brands.error = null;
    });
    builder.addCase(getBrandsData.fulfilled, (state, action) => {
      state.brands.error = null;
      state.brands.isFetching = false;
      state.brands.data = action.payload;
    });
    builder.addCase(getBrandsData.rejected, (state, action) => {
      state.brands.error = action.payload;
    });
  },
});

export const {
  resetState,
  clearTopWidgetsState,
  clearGridWidgetsState,
  clearStatisticChartsState,
  clearStatisticChartsSettingsState,
  clearFiltersState,
} = dashboardV2Slice.actions;

export default dashboardV2Slice.reducer;
