//core
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";

//service
import {playersListService} from "../../../services/admin/playersList.service";
import {setGeneralProgressHide, setGeneralProgressShow, setGeneralSnackbarState} from "../../common/ui";
import {setUser} from "../../common/user";

export type playersListSliceState = {
  list: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  profile: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  updatePlayer: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
}

const initialState: playersListSliceState = {
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  profile: {
    data: null,
    error: null,
    isFetching: false,
  },
  updatePlayer: {
    data: null,
    error: null,
    isFetching: false,
  },
}

export const getList: any = createAsyncThunk(
  '/players_list/list',
  async (payload, { rejectWithValue, dispatch}) => {
    try {
      const response = await playersListService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);


export const getProfileData: any = createAsyncThunk(
  'players_list/getProfile',
  async (payload, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await playersListService.getProfile(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const updatePlayerData: any = createAsyncThunk(
  'players_list/updatePlayerData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await playersListService.updatePlayer(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (response.ok) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

//slice
const playersListSlice = createSlice({
  name: 'players_list',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    clearListState(state) {
      state.list.data = null;
      state.list.isFetching = false;
      state.list.error = null;
    },
    clearProfileState(state) {
      state.profile.data = null;
      state.profile.error = null;
      state.profile.isFetching = false;
    },
    clearUpdateProfileState(state) {
      state.updatePlayer.data = null;
      state.updatePlayer.error = null;
      state.updatePlayer.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<playersListSliceState>>) => {
    builder.addCase(getList.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getList.fulfilled, (state, action ) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getList.rejected, (state, action ) => {
      state.list.error = action.payload;
    });

    builder.addCase(getProfileData.pending, (state ) => {
      state.profile.isFetching = true;
      state.profile.error = null;
    });
    builder.addCase(getProfileData.fulfilled, (state , action) => {
      state.profile.error = null;
      state.profile.isFetching = false;
      state.profile.data = action.payload;
    });
    builder.addCase(getProfileData.rejected, (state , action) => {
      state.profile.isFetching = false;
      state.profile.error = action.payload;
    });

    builder.addCase(updatePlayerData.pending, (state ) => {
      state.updatePlayer.isFetching = true;
      state.updatePlayer.error = null;
    });
    builder.addCase(updatePlayerData.fulfilled, (state , action) => {
      state.updatePlayer.error = null;
      state.updatePlayer.isFetching = false;
      state.updatePlayer.data = action.payload;
    });
    builder.addCase(updatePlayerData.rejected, (state , action) => {
      state.updatePlayer.isFetching = false;
      state.updatePlayer.error = action.payload;
    });
  },
});

export default playersListSlice.reducer;

export const {
  clearListState,
  reset,
  clearProfileState,
  clearUpdateProfileState,
} = playersListSlice.actions;
