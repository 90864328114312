//core
import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

interface IText {
  value: string | number;
  bold?: boolean;
  prefix?: string;
  whiteSpace?: boolean;
  breakAll?: boolean;
}

const useStyles = makeStyles({
  text: {
    color: 'inherit',
  },
  whiteSpace: {
    whiteSpace: 'nowrap',
  }
});

const Text: React.FC<IText> = ({
  value = '',
  bold = false,
  prefix,
  whiteSpace = false,
  breakAll = false,
}): JSX.Element  => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <span
      className={`${styles.text} ${whiteSpace ? styles.whiteSpace : ''}`}
      style={{
        fontWeight: bold ? "bold" : "inherit",
        wordBreak: breakAll ? "break-all" : "normal",
      }}
    >
      {prefix ? (
          !!value ? t(`${prefix}${value}`) : '—'
      ): (
           value ? value : '—'
      )}
    </span>
  );
};

export default Text;
