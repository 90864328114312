//core
import React, {useCallback, useContext, useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "usehooks-ts";
import arePropsEqual from "react-fast-compare";
import {
  Box,
  IconButton,
  Badge,
  Tooltip,
  //Button,
  Collapse,
  useMediaQuery,
} from '@mui/material';
import Button from "../../../../UI/Buttons/Button";

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

//styles
import { useStyles } from "./styles";

//helpers
import { countValuesInObj } from "../../../../../helpers/countValuesInObj";

//context
import {
  GlobalContext,
  OrderType
} from "../../../../../context/GlobalContext";

//constants
import { favoriteFilterKeys } from "../../constants";

//selectors
import { selectListDataColumnsSort } from "../../../../../store/admin/employees/selectors";

//components
import { IEmployeesFilterData, initialFilterData } from "../DesktopReport";
import MuiDrawerWrapper from "../../../../UI/MuiDrawerWrapper";
import Filter from "./components/Filter";
import VisibleFilter from "./components/VisibleFilter";
import SelectedFiltersWrapper from "./components/SelectedFiltersWrapper";
import SortMenu from "../../../../common/SortMenu";


interface IDrawerFilterProps {
  initialFilter: IEmployeesFilterData;
  setUpdateFilter: React.Dispatch<React.SetStateAction<IEmployeesFilterData>>;
  children?: React.ReactNode | null;
  order: OrderType;
  setOrder: (order: OrderType) => void;
  showOrderOnlyMobile?: boolean;
  onCreate: () => void;
}

const DrawerFilter: React.FC<IDrawerFilterProps> = ({
  initialFilter,
  setUpdateFilter,
  children = null,
  order,
  setOrder,
  showOrderOnlyMobile = false,
  onCreate,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const mobile = useMediaQuery('(max-width:767px)');

  const context = useContext(GlobalContext);

  const sortingColumns = useSelector(selectListDataColumnsSort);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(true);
  const [activeField, setActiveField] = useState('');

  //favorite fields state
  const [favAdmEmployeesDepartments, setFavAdmEmployeesDepartments] = useLocalStorage<boolean>(favoriteFilterKeys.departments, false);
  const [favAdmEmployeesStatus, setFavAdmEmployeesStatus] = useLocalStorage<boolean>(favoriteFilterKeys.status, false);
  //const [favAdmEmployeesCompany, setFavAdmEmployeesCompany] = useLocalStorage<boolean>(favoriteFilterKeys.company, false);
  const [favAdmEmployeesRoles, setFavAdmEmployeesRoles] = useLocalStorage<boolean>(favoriteFilterKeys.roles, false);
  const [favAdmEmployeesPlatforms, setFavAdmEmployeesPlatforms] = useLocalStorage<boolean>(favoriteFilterKeys.platforms, false);

  const hasFavoriteFields = favAdmEmployeesDepartments || favAdmEmployeesStatus || favAdmEmployeesRoles || favAdmEmployeesPlatforms;

  const handleClearFilters = useCallback(() => {
    setUpdateFilter(initialFilterData);
  }, [setUpdateFilter]);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleSetActiveField = (field: string) => {
    setActiveField(field);
    setOpenDrawer(true);
  };

  const handleToggleCollapse = () => {
    setOpenCollapse(prevState => !prevState);
  };

  const onDeleteOption = useCallback((id: string | number, fieldName: string) => {
    setUpdateFilter( (prevState) => {
      const newFieldData = fieldName === 'status'
        ? null
        : prevState[fieldName].filter((item: any) => item.id !== id);

      context.setAdmCreativesV2Filter({
        ...context.admCreativesV2Filter,
        [fieldName]: newFieldData,
      });

      return {
        ...prevState,
        [fieldName]: newFieldData,
      }
    });
  }, [setUpdateFilter]);

  const handleChangeOrder = useCallback((order: OrderType) => {
    setOrder(order);
    context.setAdmEmployeesOrder(order);
  }, [setOrder]);

  const collapseButton = (<>
    <Button
      type="button"
      variant="text"
      color="secondary"
      size="small"
      onClick={handleToggleCollapse}
      startIcon={openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    >
      {openCollapse
        ? t("common.components.filters.hide")
        : t("common.components.filters.show")}
    </Button>
  </>);

  const createAdminBtn = (
    <Button
      gradient
      sx={{ width: 'max-content' }}
      variant="contained"
      size={mobile ? 'small' : 'medium'}
      onClick={onCreate}
    >
      {t("admin.employees.add_employer")}
    </Button>
  );


  return (
    <>
      <Box className={`${styles.block}`}>
        {!mobile && (
          <Box className={styles.first}>
            { !!children && children }
          </Box>
        )}

        <Box className={styles.second}>
          <Box className={styles.actions}>
            {/*reset filter*/}
            <IconButton
              className={styles.iconButton}
              size={mobile ? 'small' : 'medium'}
              onClick={handleClearFilters}
              disabled={arePropsEqual(initialFilterData, initialFilter)}
            >
              <Tooltip title={t("common.buttons.reset_filters")} placement="bottom">
                <FilterAltOffIcon
                  color={arePropsEqual(initialFilterData, initialFilter) ? 'inherit' : 'primary'}
                />
              </Tooltip>
            </IconButton>

            {/*open filter*/}
            <IconButton
              className={styles.iconButton}
              size={mobile ? 'small' : 'medium'}
              onClick={handleOpenDrawer}
            >
              <Tooltip title={t("common.components.filters.title")} placement="bottom">
                <Badge
                  className={styles.badge}
                  badgeContent={countValuesInObj(initialFilter)}
                  color="secondary"
                >
                  <FilterAltIcon
                    color={!!countValuesInObj(initialFilter) ? 'primary' : 'inherit'}
                  />
                </Badge>
              </Tooltip>
            </IconButton>

            {!!sortingColumns && !showOrderOnlyMobile && mobile && <SortMenu
              color="default"
              columns={sortingColumns}
              order={order}
              setOrder={handleChangeOrder}
              noMargin={true}
              prefix="columns_for_display.table.employees_report."
            />}
          </Box>
        </Box>
      </Box>

      {!mobile && (<>
        <Box className={styles.buttonWrapper}>
          {createAdminBtn}
          {(hasFavoriteFields || !!countValuesInObj(initialFilter)) && collapseButton}
        </Box>
      </>)}

      {!mobile && (
        <Collapse in={openCollapse} collapsedSize={0}>
          <VisibleFilter
            initialFilter={initialFilter}
            setUpdateFilter={setUpdateFilter}
          />

          {!!countValuesInObj(initialFilter) && (
            <SelectedFiltersWrapper
              initialFilter={context.admEmployeesFilter}
              setActiveField={handleSetActiveField}
              onDeleteOption={onDeleteOption}
            />
          )}
        </Collapse>
      )}

      <MuiDrawerWrapper
        title={t("common.components.filters.title")}
        open={openDrawer}
        toggleDrawerState={setOpenDrawer}
      >
        <Filter
          activeField={activeField}
          initialFilter={initialFilter}
          setUpdateFilter={setUpdateFilter}
          setToggleDrawer={setOpenDrawer}
        />
      </MuiDrawerWrapper>
    </>
  );
};

export default DrawerFilter;
