//core
import React, {useRef, useEffect, useContext} from "react";
import { LinearProgress } from "@mui/material";
import Lottie from "lottie-react";

//styles
import classes from "./styles/styles.module.scss";

//img
import { BRAND, TITLE } from "../../../api";

//context
import { ThemeContext } from "../MuiTheme/context";

//afft loader
import afftAnimation from "./data/afft.json";
import grpAnimation from "./data/grp.json";
//import batAnimation from "./data/bat.json";

const AppLoader: React.FC = () => {
  const lottieRef = useRef<any>(null);
  const context = useContext(ThemeContext);

  useEffect(() => {
    if (lottieRef.hasOwnProperty('current') && !!lottieRef.current) {
      if (BRAND === 'bat') {
        lottieRef?.current.setSpeed(6);
      } else {
        lottieRef?.current.setSpeed(4);
      }
    }
  }, [lottieRef]);

  return (
    <div className={classes.loader}>
      <div className={`${classes.logo} ${BRAND === 'bat' ? classes.large : ''}`}>
        {BRAND === 'afft' ? (
          <Lottie animationData={afftAnimation} loop={true} />
        ) : BRAND === 'grp' ? (
          <Lottie lottieRef={lottieRef} animationData={grpAnimation} loop={true}  />
        ) : (
          <>
            {BRAND === 'bat' ? (
              <>
                {context.darkMode ? (
                  <img src={`/assets/images/logo/${BRAND}/logo-solid.svg?v=1.56`} alt={TITLE}/>
                ) : (
                  <img src={`/assets/images/logo/${BRAND}/logo-large.svg?v=1.56`} alt={TITLE}/>
                )}
              </>
            ) : BRAND === 'btp' ? (
              <>
                {context.darkMode ? (
                  <img src={`/assets/images/logo/${BRAND}/logo-solid.svg?v=1.56`} alt={TITLE}/>
                ) : (
                  <img src={`/assets/images/logo/${BRAND}/logo-large.svg?v=1.56`} alt={TITLE}/>
                )}
              </>
            ) : BRAND === 'lxa' ? (
              <>
                {context.darkMode ? (
                  <img src={`/assets/images/logo/${BRAND}/logo-solid.svg?v=1.56`} alt={TITLE}/>
                ) : (
                  <img src={`/assets/images/logo/${BRAND}/logo-large.svg?v=1.56`} alt={TITLE}/>
                )}
              </>
            ) : BRAND === 'hlp' ? (
              <>
                {context.darkMode ? (
                  <img src={`/assets/images/logo/${BRAND}/logo-solid.svg?v=1.56`} alt={TITLE}/>
                ) : (
                  <img src={`/assets/images/logo/${BRAND}/logo-large.svg?v=1.56`} alt={TITLE}/>
                )}
              </>
            ) : BRAND === 'gca' ? (
              <>
                {context.darkMode ? (
                  <img src={`/assets/images/logo/${BRAND}/logo-solid.svg?v=1.56`} alt={TITLE}/>
                ) : (
                  <img src={`/assets/images/logo/${BRAND}/logo-large.svg?v=1.56`} alt={TITLE}/>
                )}
              </>
              ) : (
              <img src={`/assets/images/logo/${BRAND}/logo-large.svg?v=1.56`} alt={TITLE}/>
            )}
          </>
        )}
      </div>
      <div className={classes.progress}>
        <LinearProgress />
      </div>
    </div>
  );
};

export default AppLoader;
