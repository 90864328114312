//core
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { brandsService } from "../../../services/admin/brands.service";
import { setUser } from "../../common/user";
import {
  setCreateCreativeState,
  setGeneralProgressHide,
  setGeneralProgressShow,
  setGeneralSnackbarState
} from "../../common/ui";


export type ListItemData =  {
  id: number;
  name: string;
  username: string;
  status: string;
  [key: string]: any;
};

export type ColumnsItemData = {
  name: string;
  type: string;
  sort: boolean;
  match?: boolean;
};

export type BrandsListData = {
  list: ListItemData[];
  columns: ColumnsItemData[];
  start_row_num: number;
  end_row_num: number;
  total_entries: number | string;
  total_pages: number;
};

export type ListPayloadType = {
  filter: {
    [key: string]: any,
    search: string | null;
  };
  order: {
    [key: string]: 'asc' | 'desc'
  };
  page: number;
  perPage: number;
};

export type IStatusOption = {
  id: number;
  label: string;
};

export type IPrivacyLevelOption = {
  id: number;
  label: string;
}


export type BrandsSliceState = {
  list: {
    data: BrandsListData | null,
    error: any,
    isFetching: boolean,
  },
  filters: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  create: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  delete: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  brand: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  update: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  createOptions: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  archive: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  archiveList: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
};

const initialState: BrandsSliceState = {
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  filters: {
    data: null,
    error: null,
    isFetching: false,
  },
  create: {
    data: null,
    error: null,
    isFetching: false,
  },
  delete: {
    data: null,
    error: null,
    isFetching: false,
  },
  brand: {
    data: null,
    error: null,
    isFetching: false,
  },
  update: {
    data: null,
    error: null,
    isFetching: false,
  },
  createOptions: {
    data: null,
    error: null,
    isFetching: false,
  },
  archive: {
    data: null,
    error: null,
    isFetching: false,
  },
  archiveList: {
    data: null,
    error: null,
    isFetching: false,
  },
};

export const getFiltersData: any = createAsyncThunk(
  'adm/brands/getFiltersData',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await brandsService.getFilters();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getListData: any = createAsyncThunk(
  'adm/brands/getListData',
  async (payload: ListPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await brandsService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getArchiveListData: any = createAsyncThunk(
  'adm/brands/getArchiveListData',
  async (payload: ListPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await brandsService.getArchiveList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const createBrandData: any = createAsyncThunk(
  'adm/brands/create',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await brandsService.setCreateBrand(payload);
      const data = await response.json();

      if (response.ok && response.status === 201) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.created',
          })
        );
        dispatch(setCreateCreativeState(false));
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const deleteBrandData: any = createAsyncThunk(
  'adm/brands/delete',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await brandsService.deleteBrand(payload);
      const data = await response.json();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.removed',
          })
        );
        dispatch(setCreateCreativeState(false));
      }

      if (!response.ok) {
        const message = data?.errors?.message || '';

        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            ...(!!message ? {
              message: message,
              messageKey: '',
            } : {
              message: 'error',
              messageKey: `common.messages.error`,
            })
          })
        );

        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getBrandData: any = createAsyncThunk(
  'adm/brands/getBrandData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await brandsService.getBrand(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const updateBrandData: any = createAsyncThunk(
  'adm/brands/updateBrandData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await brandsService.updateBrand(payload);
      const data = await response.json();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.edited',
          })
        );
        dispatch(setCreateCreativeState(false));
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getCreateOptionsData: any = createAsyncThunk(
  'adm/brands/getCreateOptionsData',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await brandsService.getCreateOptions();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const archiveBrandData: any = createAsyncThunk(
  'adm/brands/archiveBrandData',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await brandsService.archiveBrand(payload);
      const data = await response.json();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
        dispatch(setCreateCreativeState(false));
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

//slice
const brandsSlice = createSlice({
  name: 'adm/brands',
  initialState: initialState,
  reducers: {
    clearListState(state) {
      state.list.data = null;
      state.list.error = null;
      state.list.isFetching = false;
    },
    clearArchiveListState(state) {
      state.archiveList.data = null;
      state.archiveList.error = null;
      state.archiveList.isFetching = false;
    },
    clearCreateState(state) {
      state.create.data = null;
      state.create.error = null;
      state.create.isFetching = false;
    },
    clearDeleteState(state) {
      state.delete.data = null;
      state.delete.error = null;
      state.delete.isFetching = false;
    },
    clearBrandState(state) {
      state.brand.data = null;
      state.brand.error = null;
      state.brand.isFetching = false;
    },
    clearUpdateState(state) {
      state.update.data = null;
      state.update.error = null;
      state.update.isFetching = false;
    },
    clearCreateOptionsState(state) {
      state.createOptions.data = null;
      state.createOptions.error = null;
      state.createOptions.isFetching = false;
    },
    clearArchiveState(state) {
      state.archive.data = null;
      state.archive.error = null;
      state.archive.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<BrandsSliceState>>) => {
    builder.addCase(getFiltersData.pending, (state ) => {
      state.filters.isFetching = true;
      state.filters.error = null;
    });
    builder.addCase(getFiltersData.fulfilled, (state , action) => {
      state.filters.error = null;
      state.filters.isFetching = false;
      state.filters.data = action.payload;
    });
    builder.addCase(getFiltersData.rejected, (state , action) => {
      state.filters.isFetching = false;
      state.filters.error = action.payload;
    });

    builder.addCase(getListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getListData.fulfilled, (state , action) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getListData.rejected, (state , action) => {
      state.list.isFetching = false;
      state.list.error = action.payload;
    });

    builder.addCase(createBrandData.pending, (state ) => {
      state.create.isFetching = true;
      state.create.error = null;
    });
    builder.addCase(createBrandData.fulfilled, (state , action) => {
      state.create.error = null;
      state.create.isFetching = false;
      state.create.data = action.payload;
    });
    builder.addCase(createBrandData.rejected, (state , action) => {
      state.create.isFetching = false;
      state.create.error = action.payload;
    });

    builder.addCase(deleteBrandData.pending, (state ) => {
      state.delete.isFetching = true;
      state.delete.error = null;
    });
    builder.addCase(deleteBrandData.fulfilled, (state , action) => {
      state.delete.error = null;
      state.delete.isFetching = false;
      state.delete.data = action.payload;
    });
    builder.addCase(deleteBrandData.rejected, (state , action) => {
      state.delete.isFetching = false;
      state.delete.error = action.payload;
    });

    builder.addCase(getBrandData.pending, (state ) => {
      state.brand.isFetching = true;
      state.brand.error = null;
    });
    builder.addCase(getBrandData.fulfilled, (state , action) => {
      state.brand.error = null;
      state.brand.isFetching = false;
      state.brand.data = action.payload;
    });
    builder.addCase(getBrandData.rejected, (state , action) => {
      state.brand.isFetching = false;
      state.brand.error = action.payload;
    });

    builder.addCase(updateBrandData.pending, (state ) => {
      state.update.isFetching = true;
      state.update.error = null;
    });
    builder.addCase(updateBrandData.fulfilled, (state , action) => {
      state.update.error = null;
      state.update.isFetching = false;
      state.update.data = action.payload;
    });
    builder.addCase(updateBrandData.rejected, (state , action) => {
      state.update.isFetching = false;
      state.update.error = action.payload;
    });

    builder.addCase(getCreateOptionsData.pending, (state ) => {
      state.createOptions.isFetching = true;
      state.createOptions.error = null;
    });
    builder.addCase(getCreateOptionsData.fulfilled, (state , action) => {
      state.createOptions.error = null;
      state.createOptions.isFetching = false;
      state.createOptions.data = action.payload;
    });
    builder.addCase(getCreateOptionsData.rejected, (state , action) => {
      state.createOptions.isFetching = false;
      state.createOptions.error = action.payload;
    });

    builder.addCase(archiveBrandData.pending, (state ) => {
      state.archive.isFetching = true;
      state.archive.error = null;
    });
    builder.addCase(archiveBrandData.fulfilled, (state , action) => {
      state.archive.error = null;
      state.archive.isFetching = false;
      state.archive.data = action.payload;
    });
    builder.addCase(archiveBrandData.rejected, (state , action) => {
      state.archive.isFetching = false;
      state.archive.error = action.payload;
    });

    builder.addCase(getArchiveListData.pending, (state ) => {
      state.archiveList.isFetching = true;
      state.archiveList.error = null;
    });
    builder.addCase(getArchiveListData.fulfilled, (state , action) => {
      state.archiveList.error = null;
      state.archiveList.isFetching = false;
      state.archiveList.data = action.payload;
    });
    builder.addCase(getArchiveListData.rejected, (state , action) => {
      state.archiveList.isFetching = false;
      state.archiveList.error = action.payload;
    });
  },
});

export default brandsSlice.reducer;

export const {
  clearListState,
  clearCreateState,
  clearDeleteState,
  clearBrandState,
  clearUpdateState,
  clearCreateOptionsState,
  clearArchiveListState,
  clearArchiveState,
} = brandsSlice.actions;
