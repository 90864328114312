import React from "react";
import MuiButton from "@mui/material/Button";
import { ButtonProps } from "@mui/material/Button/Button";

import { CustomButton, useStyles, useStylesDefault } from "./styles";

interface Props {
  gradient?: boolean;
  target?: string | undefined;
  rel?: string | undefined;
  href?: string | undefined;
  type?: string | undefined;
}

const Button = (props: Props & Omit<ButtonProps, keyof Props>) => {
  const { gradient = false, ...other } = props;
  const classes = useStyles();
  const classesDefault = useStylesDefault();
  return  (
    <>
      {gradient
        //@ts-ignore
        ? <CustomButton className={`${other.className}`} {...other}>
            <span className={classes.text}>{other.children}</span>
          </CustomButton>
        //@ts-ignore
        : <MuiButton className={classesDefault.root} {...other} />
      }
    </>
  );
};

export default Button;
