import { root } from "../api";

export const getCookieDomain = () => {
  let url:any = new URL(`${root !== '/' ? root : window.location.href}`);
  let new_url = url.hostname.split('.');

  new_url.shift();
  new_url = new_url.join('.');

  return `.${new_url}`;
}