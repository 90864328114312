//core
import React from 'react';
import {useTranslation} from "react-i18next";
import arePropsEqual from "react-fast-compare";
import {Chip} from "@mui/material";

//theme
import {Theme} from "@mui/material/styles/createTheme";
import {useTheme} from "@mui/styles";


interface IStatus {
    value: string;
}

const Status = ({ value }: IStatus) => {
    const theme: Theme = useTheme();
    const {t} = useTranslation();

    return (
        <>
            { value === "approved" && <Chip size="small" label={t("common.components.status.approved")} color="primary" sx={{ backgroundColor: '#A68CE9' }} />}
            { value === "approvedFinance" && <Chip size="small" label={t("common.components.status.approvedFinance")} color="primary" sx={{ backgroundColor: '#A68CE9' }} />}
            { value === "new" && <Chip size="small" label={t("common.components.status.new")} color="primary" sx={{ backgroundColor: '#F69B5A'}} />}
            { value === "inReview" && <Chip size="small" label={t("common.components.status.in_review")} color="primary" sx={{ backgroundColor: theme.palette.info.main }} />}
            { value === "inReviewHoldFinal" && <Chip size="small" label={t("common.components.status.in_review")} color="primary" sx={{ backgroundColor: theme.palette.info.main }} />}
            { value === "pending" && <Chip size="small" label={t("common.components.status.pending")} color="primary" sx={{ backgroundColor: '#44B2E8'}} />}
            { value === "failed" && <Chip size="small" label={t("common.components.status.failed")} color="primary" sx={{ backgroundColor: theme.palette.error.dark }} />}
            { value === "waiting" && <Chip size="small" label={t("common.components.status.waiting")} color="primary" sx={{ backgroundColor: theme.palette.info.main }} />}
            { value === "paid" && <Chip size="small" label={t("common.components.status.paid")} color="primary" sx={{ backgroundColor: '#4CAF50'}} />}

            { value === "awaiting" && <Chip size="small" label={t("common.components.status.awaiting")} color="info" />}
            { value === "denied" && <Chip size="small" label={t("common.components.status.declined")} color="default" />}

            { value === "in_progress" &&
              <Chip
                size="small"
                label={t("common.components.status.in_progress")}
                color="primary"
                sx={{ backgroundColor: theme.palette.info.main }}
              />
            }
            { value === "ready" &&
              <Chip
                size="small"
                label={t("common.components.status.ready")}
                color="primary"
                sx={{ backgroundColor: theme.palette.success.main }}
              />
            }

            {
              value === "status_active" &&
              <Chip
                size="small"
                label={t("admin.creatives.form.status.options.status_active")}
                color="primary"
                sx={{ backgroundColor: theme.palette.success.main }} />
            }
            {
              value === "status_not_active" &&
              <Chip
                size="small"
                label={t("admin.creatives.form.status.options.status_not_active")}
                color="primary"
                sx={{ backgroundColor: theme.palette.text.disabled }} />
            }

            {/* offers status */}
            { value === "Active" && <Chip size="small" label={t("common.components.status.active")} color="primary" sx={{ backgroundColor: '#4CAF50'}} />}
            { value === "Paused" && <Chip size="small" label={t("common.components.status.paused")} color="primary" sx={{ backgroundColor: '#2F8FC7'}} />}
            { value === "Archived" && <Chip size="small" label={t("common.components.status.archived")} color="primary" sx={{ backgroundColor: '#F69B5A'}} />}
        </>
    );
};

export default React.memo(Status, arePropsEqual)
