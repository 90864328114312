//core
import React, {useRef, useState, useEffect, useCallback} from "react";
import { useTranslation } from "react-i18next";
import { Formik, FormikHelpers, FormikValues } from "formik";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import {useSelector} from "react-redux";
import Button from "../../../../UI/Buttons/Button";

//styles
import { useStyles } from "./styles";

//validation
import { validationName } from "../../../../Forms/Registration/validation/name.validation";
import { validationEmail } from "../../../../Forms/Registration/validation/email.validation";
import { validationRequiredSelectField } from "../../../../validation/requiredSelect.validation";

//helpers
import { isObjectEmpty } from "../../../../../helpers/isObjectEmpty";

//hooks
import { usePermissions } from "../../../../../store/common/configuration/usePermissions";
import {useFields} from "../../../../../store/common/fields/useFields";
import {useEmployees} from "../../../../../store/admin/employees/useEmployees";

//components
import MuiTextField from "../../../../UI/FormFields/MuiTextField";
import { MuiSelect } from "../../../../UI/FormFields/MuiSelect";
import {validationMessengerUsername} from "../../../../Forms/Registration/validation/messengerUsername.validation";
import HelperFieldPlatform from "../../../Deals/components/PaymentPlanForm/components/HelperFieldPlatform/HelperFieldPlatform";

//selectors
import {
  selectAddData,
  selectAddError,
  selectEditError,
  selectListDepartmentsData,
  selectListRolesData,
} from "../../../../../store/admin/employees/selectors";
import {selectPlatformsData} from "../../../../../store/common/fields/selectors";


interface IAddAdminProps {
  data?: any;
  onClose: (data: boolean) => void;
  onSubmit: (data: any) => void;
}

const RoleForm: React.FC<IAddAdminProps> = ({data, onClose, onSubmit}): JSX.Element => {
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  const formRef: any = useRef();

  const { hasPermissions } = usePermissions();
  const { getListRoles, getListDepartments, clearAddAdminData, clearEditAdminData } = useEmployees();
  const { getPlatforms } = useFields();

  const [initialValues, setInitialValues] = useState<any | null>(null);

  const addError = useSelector(selectAddError);
  const addData = useSelector(selectAddData);
  const editError = useSelector(selectEditError);
  const dataRoles = useSelector(selectListRolesData);
  const dataDepartments = useSelector(selectListDepartmentsData);
  const platformsData = useSelector(selectPlatformsData);

  // validation errors translation
  const [errorsMessages, setErrorsMessages] = useState({
    nameRequired: t("common.validation.required_field"),
    emailError: t("common.validation.invalid_email_format"),
    emailRequired: t("common.validation.required_field"),
    maxLength: t("common.validation.notes_max_length"),
    maxLengthText: t("common.validation.max_length_255"),
    required: t("common.validation.required_field"),
    secondMax: t("common.validation.secondManagerMax"),
    secondMin: t("common.validation.required_field"),
    secondNotContain: t("common.validation.second_manager_not_contain"),
    nameMaxLength: t("common.validation.max_length_255"),
    messengerUsernameRequired: t("common.validation.required_field"),
    messengerMaxLength: t("common.validation.max_length_255"),
  });

  useEffect(() => {
    setErrorsMessages({
      nameRequired: t("common.validation.required_field"),
      emailError: t("common.validation.invalid_email_format"),
      emailRequired: t("common.validation.required_field"),
      maxLength: t("common.validation.notes_max_length"),
      maxLengthText: t("common.validation.max_length_255"),
      required: t("common.validation.required_field"),
      secondMax: t("common.validation.secondManagerMax"),
      secondMin: t("common.validation.required_field"),
      secondNotContain: t("common.validation.second_manager_not_contain"),
      nameMaxLength: t("common.validation.max_length_255"),
      messengerUsernameRequired: t("common.validation.required_field"),
      messengerMaxLength: t("common.validation.max_length_255"),
    })

    if (formRef.current) {
      if (!isObjectEmpty(formRef.current.errors)) {
        setTimeout(() => {
          formRef.current.validateForm();
        }, 50)
      }
    }
  }, [i18n.language, t]);

  useEffect(() => {
    !!data && data.platform_id && getListRoles({platform_id: data.platform_id});
    !!data && data.platform_id && getListDepartments({platform_id: data.platform_id});
    getPlatforms({});
  },[])

  useEffect(() => {
    let platform_id;

    if (!!platformsData && platformsData.list.length === 1) {
      platform_id = platformsData.list[0].id;
      getListRoles({platform_id: platform_id});
      getListDepartments({platform_id: platform_id});
    } else {
      platform_id = '';
    }

    setInitialValues({
      name: !!data && data.name || '',
      //username: !!data && data.username || '',
      email: !!data && data.email || '',
      role: !!data && data.role_id || '',
      platform: !!data && data.platform_id || platform_id,
      department: !!data && data.department_id || '',
      messenger_type: !!data && data.contact_type || 'other',
      messenger_value: !!data && data.contact_value || '',
    })
  }, [data, platformsData]);

  const submitForm = (values: FormikValues, actions: FormikHelpers<any>): void => {
    const payload = {
      personal: {
        name: values.name,
        //username: values.username,
        email: values.email,
        role_id: values.role,
        department_id: values.department,
        platform_id: values.platform,
        contact: {
          type: values.messenger_type,
          value: values.messenger_value
        },
      },
      ...(!!data && !!data.id ? {admin_id: data.id} : {})
    };

    onSubmit(payload);
    actions.setSubmitting(false);
  };

  const validateName = (value: any) => validationName(value, errorsMessages);
  const validateUsername = (value: any) => validationName(value, errorsMessages);
  const validateEmail = (value: any) => validationEmail(value, errorsMessages);
  const validateRole = (value: any) => validationRequiredSelectField(value, errorsMessages);
  const validatePlatforms = (value: any) => validationRequiredSelectField(value, errorsMessages);
  const validateDepartment = (value: any) => validationRequiredSelectField(value, errorsMessages);
  const validateMessengerUsername = (value: any) => validationMessengerUsername(value, errorsMessages);

  useEffect(() => {
    if (!!addError || !!editError) {
      let errorMessage = !!addError && addError.errors.email[0] || !!editError && editError.errors.email[0];

      errorMessage && formRef.current.setFieldError('email', t(errorMessage));
    }
  }, [addError, editError]);

  useEffect(() => {
    if (!!addData) {
      clearAddAdminData();
      onClose(false);
    }
  },[addData])

  useEffect(() => {
    return () => {
      clearAddAdminData();
      clearEditAdminData();
    }
  },[])

  return (
    <Box className={styles.block}>
      {!!initialValues && <>
          <Formik
              validateOnChange
              validateOnBlur
              validateOnMount
              enableReinitialize
              innerRef={formRef}
              initialValues={initialValues}
              onSubmit={ (values, actions) => submitForm(values, actions) }
          >
            {
              props => {
                const { values, handleSubmit, handleChange, isValid, dirty, errors } = props;

                const disableSubmit = isValid ? !dirty : true;

                return (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={{ xs: 2, sm: 4, md: 4, lg: 7.5 }}>
                      <Grid item xs={12}>

                        {data ? (
                          <Typography variant="h6" sx={{mb: 3}}>{t("admin.employees.title_edit_admin")}</Typography>
                        ) : (
                          <Typography variant="h6" sx={{mb: 3}}>{t("admin.employees.title_add_admin")}</Typography>
                        )}

                        {
                          !!platformsData && platformsData.list.length > 1 && (
                            <Box>
                              <MuiSelect
                                keyValue="value"
                                keyId="id"
                                name="platform"
                                label={t("common.forms.fields.platforms")}
                                data={platformsData.list}
                                value={values.platform}
                                validate={validatePlatforms}
                              />

                              <HelperFieldPlatform name="helper-field-platform" value={values.platform} />
                            </Box>
                          )
                        }

                        <MuiTextField
                          size="small"
                          id="name"
                          type="text"
                          name="name"
                          autoComplete="name"
                          label={t("common.forms.fields.name")}
                          value={values.name}
                          validate={validateName}
                          onChange={handleChange}
                        />

                        {/*<MuiTextField
                          size="small"
                          id="username"
                          type="text"
                          name="username"
                          autoComplete="username"
                          label={t("common.forms.fields.username")}
                          value={values.username}
                          validate={validateUsername}
                          onChange={handleChange}
                        />*/}

                        <MuiTextField
                          size="small"
                          id="email"
                          type="text"
                          name="email"
                          autoComplete="email"
                          label={t("common.forms.fields.email")}
                          validate={validateEmail}
                          value={values.email}
                          onChange={handleChange}
                        />

                        {
                          !!dataRoles && (
                            <MuiSelect
                              keyValue="name"
                              keyId="id"
                              name="role"
                              label={t("common.forms.fields.role")}
                              data={dataRoles.roles}
                              value={values.role}
                              validate={validateRole}
                              onChange={handleChange}
                              disabled={!values.platform}
                            />
                          )
                        }

                        {
                          !!dataDepartments && (
                            <MuiSelect
                              keyValue="name"
                              keyId="id"
                              name="department"
                              label={t("common.forms.fields.department")}
                              data={dataDepartments.departments}
                              value={values.department}
                              validate={validateDepartment}
                              onChange={handleChange}
                            />
                          )
                        }

                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="messenger_type"
                            name="messenger_type"
                            value={values.messenger_type}
                            onChange={handleChange}
                          >
                            <FormControlLabel value="telegram" control={<Radio />} label={t("common.forms.fields.telegram")} />
                            <FormControlLabel value="skype" control={<Radio />} label={t("common.forms.fields.skype")} />
                            <FormControlLabel value="other" control={<Radio />} label={t("common.forms.fields.other")} />
                          </RadioGroup>
                        </FormControl>

                        <MuiTextField
                          size="small"
                          id="messenger_value"
                          type="text"
                          name="messenger_value"
                          autoComplete="messenger_value"
                          label={values.messenger_type === 'telegram' ? `@${t("common.forms.fields.messenger")}` : t("common.forms.fields.messenger")}
                          value={values.messenger_value}
                          onChange={handleChange}
                          InputProps={
                            values.messenger_type === 'telegram' ? {
                              startAdornment: <InputAdornment className={styles.telegram} position="start">@</InputAdornment>,
                            } : null
                          }
                          // validate={validateMessengerUsername}
                        />
                      </Grid>
                    </Grid>
                    <Box sx={{ marginBottom: 3 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Button
                            gradient
                            fullWidth
                            disableElevation
                            type="submit"
                            variant="contained"
                            disabled={!isValid}
                          >{t("common.buttons.save")}</Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Button
                            fullWidth
                            disableElevation
                            type="button"
                            variant="outlined"
                            color="secondary"
                            onClick={() => onClose(false)}
                          >{t("common.buttons.back")}</Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </form>
                )
              }
            }
          </Formik>
      </>}
    </Box>
  );
};

export default RoleForm;
