//core
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

//actions
import {
  getCountries,
  getTrafficSource,
  registrationSetError,
  setRegData,
  clearConfirmationEmailState,
  registrationSliceState,
  registrationUser,
  confirmationEmail,
  resendConfirmationEmail,
  clearConfirmationDataState,
  clearResendConfirmationDataState,
} from "./index";
import {ListItemType} from "../fields";

export type stateRegistration = {
  registration: registrationSliceState
};

const registrationSelector = (state: stateRegistration) => state.registration;
const masksSelector = (state: stateRegistration) => state.registration.masks;
const trafficSourceSelector = (state: stateRegistration) => state.registration.trafficSource;
const trafficSourceDataSelector = (state: stateRegistration) => state.registration.trafficSource.data;
const countrySelector = (state: stateRegistration) => state.registration.country;
const countriesSelector = (state: stateRegistration) => state.registration.countries;
const countriesDataSelector = (state: stateRegistration) => state.registration.countries.data;
const regDataSelector = (state: stateRegistration) => state.registration.regData;
const confirmationDataSelector = (state: stateRegistration) => state.registration.confirmationData;
const resendConfirmationSelector = (state: stateRegistration) => state.registration.resendConfirmation;

export const selectRegistration = createSelector(registrationSelector, (registration) => registration);
export const selectMasks = createSelector(masksSelector, (masks) => masks);
export const selectTrafficSource = createSelector(trafficSourceSelector, (trafficSource) => trafficSource);
export const selectTrafficSourceData = createSelector(trafficSourceDataSelector, (trafficSourceData) => trafficSourceData);
export const selectCountry = createSelector(countrySelector, (country) => country);
export const selectCountries = createSelector(countriesSelector, (countries) => countries);
export const selectCountriesData = createSelector(countriesDataSelector, (countriesData) => countriesData);
export const selectRegData = createSelector(regDataSelector, (regData) => regData);
export const selectConfirmationData = createSelector(confirmationDataSelector, (confirmationData) => confirmationData);
export const selectResendConfirmation = createSelector(resendConfirmationSelector, (resendConfirmation) => resendConfirmation);
export const selectCountriesOptionsList = createSelector(countriesDataSelector, (countries) => {
  if (!!countries) {
    return countries.map(item => {
      return {id: item.code, label: item.code}
    })
  } else return countries;
});

export const useRegistration = () => {
  const dispatch = useDispatch();

  const masks = useSelector(selectMasks);
  const trafficSource = useSelector(selectTrafficSource);
  const trafficSourceData = useSelector(selectTrafficSourceData);
  const country = useSelector(selectCountry);
  const countries = useSelector(selectCountries);
  const countriesData = useSelector(selectCountriesData);
  const regData = useSelector(selectRegData);
  const confirmationData = useSelector(selectConfirmationData);
  const resendConfirmation = useSelector(selectResendConfirmation);
  const countriesOptionsList = useSelector(selectCountriesOptionsList);

  useEffect(() => {
    if (!countriesData) {
      dispatch(getCountries());
    }
  }, [countriesData, countriesOptionsList]);

  useEffect(() => {
    if (!trafficSourceData) {
      dispatch(getTrafficSource());
    }
  }, [trafficSourceData]);

  const registration = (data: any) => dispatch(registrationUser(data));

  const confirmation = (payload: any) => dispatch(confirmationEmail(payload));

  const regSetError = (data: any) => dispatch(registrationSetError(data));
  const regSetRegData = (data: any) => dispatch(setRegData(data));

  const resendConfirmationData = (data: any) => dispatch(resendConfirmationEmail(data));

  const clearConfirmationEmail = () => dispatch(clearConfirmationEmailState());
  const clearConfirmationData = () => dispatch(clearConfirmationDataState());
  const clearResendConfirmationData = () => dispatch(clearResendConfirmationDataState());

  return {
    masks,
    country,
    trafficSource,
    countries,
    regData,
    confirmationData,
    resendConfirmation,
    countriesOptionsList,
    registration,
    regSetError,
    regSetRegData,
    confirmation,
    resendConfirmationData,
    clearConfirmationEmail,
    clearConfirmationData,
    clearResendConfirmationData
  }
}
