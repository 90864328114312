import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  block: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 8,
    '@media (max-width: 460px)': {}
  },

}));
