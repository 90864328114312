//core
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { offersService } from "../../../services/affiliate/offers.service";
import { setUser } from "../../common/user";
import {
  setGeneralProgressHide,
  setGeneralProgressShow,
  setGeneralSnackbarState
} from "../../common/ui";

export type ListPayloadType = {
  filter: {
    [key: string]: any,
    search: string | null;
  };
  order: {
    [key: string]: 'asc' | 'desc'
  };
  page: number;
  perPage: number;
};

export type OffersSliceState = {
  list: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  filters: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
  request: {
    data: any | null,
    error: any,
    isFetching: boolean,
  },
};

const initialState: OffersSliceState = {
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
  filters: {
    data: null,
    error: null,
    isFetching: false,
  },
  request: {
    data: null,
    error: null,
    isFetching: false,
  },
};

export const getFiltersData: any = createAsyncThunk(
  'aff/offers/getFiltersData',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await offersService.getFilters();
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const getListData: any = createAsyncThunk(
  'aff/offers/getListData',
  async (payload: ListPayloadType, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await offersService.getList(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setGeneralProgressHide());
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());
      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      return rejectWithValue(error)
    }
  }
);

export const sendOfferRequest: any = createAsyncThunk(
  'aff/offers/sendOfferRequest',
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(setGeneralProgressShow());
    try {
      const response = await offersService.sendOfferRequest(payload);
      const data = await response.json();

      if (response.ok && response.status === 200) {
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'success',
            message: 'success',
            messageKey: 'common.messages.success',
          })
        );
      }

      if (!response.ok) {
        dispatch(setGeneralProgressHide());
        dispatch(
          setGeneralSnackbarState({
            open: true,
            type: 'warning',
            message: 'error',
            messageKey: 'common.messages.error',
          })
        );
        return rejectWithValue(data)
      }

      dispatch(setGeneralProgressHide());

      return data;
    } catch (error) {
      dispatch(setGeneralProgressHide());
      dispatch(
        setGeneralSnackbarState({
          open: true,
          type: 'warning',
          message: 'error',
          messageKey: 'common.messages.error',
        })
      );
      return rejectWithValue(error)
    }
  }
);

//slice
const offersSlice = createSlice({
  name: 'aff/offers',
  initialState: initialState,
  reducers: {
    clearListState(state) {
      state.list.data = null;
      state.list.error = null;
      state.list.isFetching = false;
    },
    clearFiltersState(state) {
      state.filters.data = null;
      state.filters.error = null;
      state.filters.isFetching = false;
    },
    clearRequestState(state) {
      state.request.data = null;
      state.request.error = null;
      state.request.isFetching = false;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<OffersSliceState>>) => {
    builder.addCase(getFiltersData.pending, (state ) => {
      state.filters.isFetching = true;
      state.filters.error = null;
    });
    builder.addCase(getFiltersData.fulfilled, (state , action) => {
      state.filters.error = null;
      state.filters.isFetching = false;
      state.filters.data = action.payload;
    });
    builder.addCase(getFiltersData.rejected, (state , action) => {
      state.filters.isFetching = false;
      state.filters.error = action.payload;
    });

    builder.addCase(getListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getListData.fulfilled, (state , action) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getListData.rejected, (state , action) => {
      state.list.isFetching = false;
      state.list.error = action.payload;
    });

    builder.addCase(sendOfferRequest.pending, (state ) => {
      state.request.isFetching = true;
      state.request.error = null;
    });
    builder.addCase(sendOfferRequest.fulfilled, (state , action) => {
      state.request.error = null;
      state.request.isFetching = false;
      state.request.data = action.payload;
    });
    builder.addCase(sendOfferRequest.rejected, (state , action) => {
      state.request.isFetching = false;
      state.request.error = action.payload;
    });
  },
});

export default offersSlice.reducer;

export const {
  clearListState,
  clearFiltersState,
  clearRequestState,
} = offersSlice.actions;
