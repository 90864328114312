import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    gap: 16,
    '@media (max-width: 767px)': {
      flexWrap: 'wrap',
      marginBottom: 16,
    }
  },

  box: {
    width: '100%',
    maxWidth: 220,
    paddingTop: 5,
    '@media (max-width: 1024px)': {
      maxWidth: 'calc(50% - 8px)',
      '& > div': {
        marginBottom: 0,
      }
    },
    '@media (max-width: 767px)': {
      maxWidth: 'calc(50% - 8px)',
      '& > div': {
        marginBottom: 0,
      }
    },
    '&:first-child': {
      '@media (max-width: 767px)': {
        maxWidth: '100%',
      },
    },
    '& > div': {
      marginBottom: 16,
      '@media (max-width: 767px)': {
        marginBottom: 0,
      }
    }
  },

  boxDate: {
    width: '100%',
    maxWidth: 306,
    paddingTop: 5,
    '@media (max-width: 1024px)': {
      maxWidth: 'calc(50% - 8px)',
      '& > div': {
        marginBottom: 0,
      }
    },
    '@media (max-width: 767px)': {
      maxWidth: '100%',
      '& > div': {
        marginBottom: 0,
      }
    },
    '& > div': {
      marginBottom: 16,
      '@media (max-width: 767px)': {
        marginBottom: 0,
      }
    }
  },

  field: {
    width: '100%',
    marginBottom: 16,
    paddingTop: 5,
  },

  favoriteFields: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: 0,
    margin: '0 -8px 0',
    '@media (max-width: 1368px)': {
      gap: 0,
      margin: '0 -8px',
    },
    '& > div': {
      width: '100%',
      maxWidth: '25%',
      padding: '0 8px 16px',
      '@media (max-width: 1368px)': {
        maxWidth: '33.3333%',
        padding: '0 8px 16px'
      },
      '@media (max-width: 1024px)': {
        maxWidth: '50%',
      },
      '@media (max-width: 767px)': {
        maxWidth: '100%',
      }
    }
  }
});
