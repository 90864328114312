import React, { useContext, useEffect, useState } from "react";
import arePropsEqual from "react-fast-compare";
import { useSelector } from "react-redux";
import { Checkbox } from "@mui/material";

//context
import { DetailsContext } from "../Details/DetailsContext";

//selectors
import { selectListData } from "../../../../../../store/admin/reports/details_finance_report/selectors";

//types
import { itemListData } from "../../../../../common/TableData";



const SelectAllRows = () => {
  const context = useContext(DetailsContext);
  const data = useSelector(selectListData);

  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (!context.selectedRows.length) {
      setChecked(false);
    }
  }, [context.selectedRows]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      context.setSelectedRows(data.list);
    } else {
      context.setSelectedRows([]);
    }
  }

  return (
    <>
      <Checkbox
        size="small"
        sx={{ padding: '4px' }}
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </>
  );
};

export default React.memo(SelectAllRows, arePropsEqual);
