export const hasStringInArray = (key: string, arr: string[] | null) => {
  if (!!arr) {
    return arr.includes(key)
  } else {
    return false
  }
}

export const hasItemArrayInArray = (keys: string[], arr: string[] | null) => {
  if (!!arr) {
    return !!keys.reduce((acc, item) => arr.includes(item) ? acc + 1 : acc, 0)
  } else {
    return false
  }
}
