//core
import React, {Dispatch, SetStateAction, useCallback} from "react";
import arePropsEqual from "react-fast-compare";
import { IconButton } from "@mui/material";

//icons
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

//styles
import { useStyles } from "./styles";


import {OrderType} from "../../../../affiliate/Trackers";

interface ISortButton {
  order: OrderType;
  name: string;
  className?: string;
  setOrder: Dispatch<SetStateAction<OrderType>> | Dispatch<OrderType>;
  initialOrder?: OrderType;
}

const SortButton = (props: ISortButton) => {
  const styles = useStyles();

  const {
    order,
    name,
    className,
    setOrder,
    initialOrder = false,
  } = props;

  const icon = !!order && (
    order[name] === 'desc'
      ? <>
          <ArrowDownwardIcon color="success" className={`${styles.icon} ${styles.iconSuccess}`} />
        </>
      : order[name] === 'asc'
        ? <>
            <ArrowUpwardIcon color="success" className={`${styles.icon} ${styles.iconSuccess}`} />
          </>
        : <>
            <ArrowDownwardIcon color="disabled" className={styles.icon} />
          </>
  )

  const handleClick = useCallback(() => {
    if (!!order && Object.keys(order)[0] === name) {
      if (order[name] === 'desc') {
        setOrder({[name]: 'asc'});
      }
      if (order[name] === 'asc') {
        if (typeof initialOrder === 'object') {
          if (Object.values(initialOrder)[0] === 'asc' && Object.keys(initialOrder)[0] === name) {
            setOrder({[name]: 'desc'});
          } else {
            setOrder(initialOrder);
          }
        } else {
          setOrder({[name]: 'desc'});
        }
      }
    } else {
      setOrder({[name]: 'desc'});
    }
  },[order, setOrder]);

  return (
    <>
      <IconButton
        className={`${styles.iconButton} ${className ? className : ''}`}
        onClick={handleClick}
        aria-label="sort"
        size="small"
      >
        {icon}
      </IconButton>
    </>
  );
};

export default React.memo(SortButton, arePropsEqual);
