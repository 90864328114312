//core
import React from "react";
import arePropsEqual from "react-fast-compare";
import { useTranslation } from "react-i18next";
import TooltipText from "../TooltipText";

interface IWallet {
  data: any;
}

const Wallet: React.FC<IWallet> = ({
   data,
}): JSX.Element => {
  const { t } = useTranslation();

  const str = !!data.walletPaymentMethodName
    ? `${t(`common.components.payment.payment_method_options.${data.walletPaymentMethodName}`)} - ${data.walletPaymentMethodValue[data.walletPaymentMethodDefaultField]}`
    : '';

  return (
    <>
      <TooltipText value={str} width={240} maxWidth={240}/>
    </>
  );
};

export default React.memo(Wallet, arePropsEqual);
