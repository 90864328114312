export const MuiButtonsOverrides = {
  styleOverrides: {
    root: {
      minHeight: 40,
      '&.MuiButton-sizeSmall': {
        minHeight: 'inherit',
      }
    },
  }
}
