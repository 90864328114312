export const periodOptions = [
  {
    id: "today",
    label: "today",
  },
  {
    id: "yesterday",
    label: "yesterday",
  },
  {
    id: "last_7_days",
    label: "last_7_days",
  },
  {
    id: "week",
    label: "week",
  },
  {
    id: "last_week",
    label: "last_week",
  },
  {
    id: "month",
    label: "month",
  },
  {
    id: "last_month",
    label: "last_month",
  },
  {
    id: "year",
    label: "year",
  }
];

export const summaryPeriodOptions = [
  {
    id: "today",
    label: "today",
  },
  {
    id: "yesterday",
    label: "yesterday",
  },
  {
    id: "last_7_days",
    label: "last_7_days",
  },
  {
    id: "week",
    label: "week",
  },
  {
    id: "last_week",
    label: "last_week",
  },
  {
    id: "month",
    label: "month",
  },
  {
    id: "last_month",
    label: "last_month",
  },
  {
    id: "year",
    label: "year",
  },
  {
    id: "current_quater",
    label: "current_quater",
  },
  {
    id: "last_quater",
    label: "last_quater",
  }
];

export const creativePeriodOptions = [
  {
    id: "today",
    label: "today",
  },
  {
    id: "yesterday",
    label: "yesterday",
  },
  {
    id: "last_7_days",
    label: "last_7_days",
  },
  {
    id: "week",
    label: "week",
  },
  {
    id: "last_week",
    label: "last_week",
  },
  {
    id: "month",
    label: "month",
  },
  {
    id: "last_month",
    label: "last_month",
  },
];
