export type countriesItemType = {
  iso3: string,
  iso2: string,
  id: number,
  name: string,
  masks: string[],
};

export type defaultMasksType = {
  countries: countriesItemType[]
}

export const defaultMasks: defaultMasksType = {
  countries: [
    {
      "iso3": "ALB",
      "iso2": "AL",
      "id": 8,
      "name": "Albania",
      "masks": [
        "+355(XXX) XXX - XXX"
      ]
    },
    {
      "iso3": "DZA",
      "iso2": "DZ",
      "id": 12,
      "name": "Algeria",
      "masks": [
        "+213 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "AND",
      "iso2": "AD",
      "id": 20,
      "name": "Andorra",
      "masks": [
        "+376 - XXX - XXX"
      ]
    },
    {
      "iso3": "AGO",
      "iso2": "AO",
      "id": 24,
      "name": "Angola",
      "masks": [
        "+244(XXX) XXX - XXX"
      ]
    },
    {
      "iso3": "AIA",
      "iso2": "AI",
      "id": 660,
      "name": "Anguilla",
      "masks": [
        "+1(264) XXX - XXXX"
      ]
    },
    {
      "iso3": "ATA",
      "iso2": "AQ",
      "id": 10,
      "name": "Antarctic",
      "masks": [
        "+672 - 1XX - XXX"
      ]
    },
    {
      "iso3": "ATG",
      "iso2": "AG",
      "id": 28,
      "name": "Antigua and Barbuda",
      "masks": [
        "+1(268) XXX - XXXX"
      ]
    },
    {
      "iso3": "ARG",
      "iso2": "AR",
      "id": 32,
      "name": "Argentina",
      "masks": [
        "+54(XXX) XXX - XXXX"
      ]
    },
    {
      "iso3": "ARM",
      "iso2": "AM",
      "id": 51,
      "name": "Armenia",
      "masks": [
        "+374 - XX - XXX - XXX"
      ]
    },
    {
      "iso3": "ABW",
      "iso2": "AW",
      "id": 533,
      "name": "Aruba",
      "masks": [
        "+297 - XXX - XXXX"
      ]
    },
    {
      "iso3": "AUS",
      "iso2": "AU",
      "id": 36,
      "name": "Australia",
      "masks": [
        "+61 - X - XXXX - XXXX",
        "+61 - XX - XXXX - XXXX"
      ]
    },
    {
      "iso3": "AUT",
      "iso2": "AT",
      "id": 40,
      "name": "Austria",
      "masks": [
        "+43(XXX) XXX - XXXX"
      ]
    },
    {
      "iso3": "AZE",
      "iso2": "AZ",
      "id": 31,
      "name": "Azerbaijan",
      "masks": [
        "+994 - XX - XXX - XX - XX"
      ]
    },
    {
      "iso3": "BHS",
      "iso2": "BS",
      "id": 44,
      "name": "Bahamas",
      "masks": [
        "+1(242) XXX - XXXX"
      ]
    },
    {
      "iso3": "BHR",
      "iso2": "BH",
      "id": 48,
      "name": "Bahrain",
      "masks": [
        "+973 - XXXX - XXXX"
      ]
    },
    {
      "iso3": "BGD",
      "iso2": "BD",
      "id": 50,
      "name": "Bangladesh",
      "masks": [
        "+880 - XX - XXX - XXX",
        "+880 - XX - XXXX - XXXX"
      ]
    },
    {
      "iso3": "BRB",
      "iso2": "BB",
      "id": 52,
      "name": "Barbados",
      "masks": [
        "+1(246) XXX - XXXX"
      ]
    },
    {
      "iso3": "BLR",
      "iso2": "BY",
      "id": 112,
      "name": "Belarus",
      "masks": [
        "+375(XX) XXX - XX - XX"
      ]
    },
    {
      "iso3": "BEL",
      "iso2": "BE",
      "id": 56,
      "name": "Belgium",
      "masks": [
        "+32(XXX) XXX - XXX"
      ]
    },
    {
      "iso3": "BLZ",
      "iso2": "BZ",
      "id": 84,
      "name": "Belize",
      "masks": [
        "+501 - XXX - XXXX"
      ]
    },
    {
      "iso3": "BEN",
      "iso2": "BJ",
      "id": 204,
      "name": "Benin",
      "masks": [
        "+229 - XX - XX - XXXX"
      ]
    },
    {
      "iso3": "BMU",
      "iso2": "BM",
      "id": 60,
      "name": "Bermuda",
      "masks": [
        "+1(441) XXX - XXXX"
      ]
    },
    {
      "iso3": "BTN",
      "iso2": "BT",
      "id": 64,
      "name": "Bhutan",
      "masks": [
        "+975 - 17 - XXX - XXX",
        "+975 - X - XXX - XXX"
      ]
    },
    {
      "iso3": "BOL",
      "iso2": "BO",
      "id": 68,
      "name": "Bolivia",
      "masks": [
        "+591 - X - XXX - XXXX"
      ]
    },
    {
      "iso3": "BWA",
      "iso2": "BW",
      "id": 72,
      "name": "Botswana",
      "masks": [
        "+267 - XX - XXX - XXX"
      ]
    },
    {
      "iso3": "BVT",
      "iso2": "BV",
      "id": 74,
      "name": "Bouvet Island",
      "masks": []
    },
    {
      "iso3": "BRA",
      "iso2": "BR",
      "id": 76,
      "name": "Brazil",
      "masks": [
        "+55(XX) XXXX - XXXX",
        "+55(XX) XXXXX - XXXX"
      ]
    },
    {
      "iso3": "CZE",
      "iso2": "CZ",
      "id": 203,
      "name": "Czech Republic",
      "masks": [
        "+420 XXX XXX XXX"
      ]
    },
    {
      "iso3": "IOT",
      "iso2": "IO",
      "id": 86,
      "name": "British Indian Ocean Territory",
      "masks": [
        "+246 - XXX - XXXX"
      ]
    },
    {
      "iso3": "VGB",
      "iso2": "VG",
      "id": 92,
      "name": "British Virgin Islands",
      "masks": [
        "+1(284) XXX - XXXX"
      ]
    },
    {
      "iso3": "BRN",
      "iso2": "BN",
      "id": 96,
      "name": "Brunei Darussalam",
      "masks": [
        "+673 - XXX - XXXX"
      ]
    },
    {
      "iso3": "BGR",
      "iso2": "BG",
      "id": 100,
      "name": "Bulgaria",
      "masks": [
        "+359(XXX) XXX - XXX"
      ]
    },
    {
      "iso3": "BFA",
      "iso2": "BF",
      "id": 854,
      "name": "Burkina Faso",
      "masks": [
        "+226 - XX - XX - XXXX"
      ]
    },
    {
      "iso3": "BDI",
      "iso2": "BI",
      "id": 108,
      "name": "Burundi",
      "masks": [
        "+257 - XX - XX - XXXX"
      ]
    },
    {
      "iso3": "KHM",
      "iso2": "KH",
      "id": 116,
      "name": "Cambodia",
      "masks": [
        "+855 - XX - XXX - XXX"
      ]
    },
    {
      "iso3": "CMR",
      "iso2": "CM",
      "id": 120,
      "name": "Cameroon",
      "masks": [
        "+237 - XXXX - XXXX"
      ]
    },
    {
      "iso3": "CAN",
      "iso2": "CA",
      "id": 124,
      "name": "Canada",
      "masks": [
        "+1 - XXX - XXX - XXXX"
      ]
    },
    {
      "iso3": "CPV",
      "iso2": "CV",
      "id": 132,
      "name": "Cape Verde Islands",
      "masks": [
        "+238(XXX) XX - XX"
      ]
    },
    {
      "iso3": "CYM",
      "iso2": "KY",
      "id": 136,
      "name": "Cayman Islands",
      "masks": [
        "+1(345) XXX - XXXX"
      ]
    },
    {
      "iso3": "CAF",
      "iso2": "CF",
      "id": 140,
      "name": "Central African Republic",
      "masks": [
        "+236 - XX - XX - XXXX"
      ]
    },
    {
      "iso3": "TCD",
      "iso2": "TD",
      "id": 148,
      "name": "Chad",
      "masks": [
        "+235 - XX - XX - XX - XX"
      ]
    },
    {
      "iso3": "CHL",
      "iso2": "CL",
      "id": 152,
      "name": "Chile",
      "masks": [
        "+56 - X - XXXX - XXXX"
      ]
    },
    {
      "iso3": "CHN",
      "iso2": "CN",
      "id": 156,
      "name": "China PR",
      "masks": [
        "+86(XXX) XXXX - XXXX",
        "+86(XXX) XXXX - XXX",
        "+86 - XX - XXXXX - XXXXX"
      ]
    },
    {
      "iso3": "CXR",
      "iso2": "CX",
      "id": 162,
      "name": "Christmas Island",
      "masks": []
    },
    {
      "iso3": "CCK",
      "iso2": "CC",
      "id": 166,
      "name": "Cocos Islands",
      "masks": []
    },
    {
      "iso3": "COL",
      "iso2": "CO",
      "id": 170,
      "name": "Colombia",
      "masks": [
        "+57(XXX) XXX - XXXX"
      ]
    },
    {
      "iso3": "COM",
      "iso2": "KM",
      "id": 174,
      "name": "Comoros",
      "masks": [
        "+269 - XX - XXXXX"
      ]
    },
    {
      "iso3": "COK",
      "iso2": "CK",
      "id": 184,
      "name": "Cook Islands",
      "masks": [
        "+682 - XX - XXX"
      ]
    },
    {
      "iso3": "CRI",
      "iso2": "CR",
      "id": 188,
      "name": "Costa Rica",
      "masks": [
        "+506 - XXXX - XXXX"
      ]
    },
    {
      "iso3": "HRV",
      "iso2": "HR",
      "id": 191,
      "name": "Croatia",
      "masks": [
        "+385 - XX - XXX - XXX"
      ]
    },
    {
      "iso3": "DNK",
      "iso2": "DK",
      "id": 208,
      "name": "Denmark",
      "masks": [
        "+45 - XX - XX - XX - XX"
      ]
    },
    {
      "iso3": "DJI",
      "iso2": "DJ",
      "id": 262,
      "name": "Djibouti",
      "masks": [
        "+253 - XX - XX - XX - XX"
      ]
    },
    {
      "iso3": "DMA",
      "iso2": "DM",
      "id": 212,
      "name": "Dominica",
      "masks": [
        "+1(767) XXX - XXXX"
      ]
    },
    {
      "iso3": "TLS",
      "iso2": "TL",
      "id": 626,
      "name": "East Timor",
      "masks": [
        "+670 - XXX - XXXX",
        "+670 - 77X - XXXXX",
        "+670 - 78X - XXXXX"
      ]
    },
    {
      "iso3": "ECU",
      "iso2": "EC",
      "id": 218,
      "name": "Ecuador",
      "masks": [
        "+593 - XX - XXX - XXXX",
        "+593 - X - XXX - XXXX"
      ]
    },
    {
      "iso3": "EGY",
      "iso2": "EG",
      "id": 818,
      "name": "Egypt",
      "masks": [
        "+20(XXX) XXX - XXXX"
      ]
    },
    {
      "iso3": "SLV",
      "iso2": "SV",
      "id": 222,
      "name": "El Salvador",
      "masks": [
        "+503 - XX - XX - XXXX"
      ]
    },
    {
      "iso3": "GNQ",
      "iso2": "GQ",
      "id": 226,
      "name": "Equatorial Guinea",
      "masks": [
        "+240 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "ERI",
      "iso2": "ER",
      "id": 232,
      "name": "Eritrea",
      "masks": [
        "+291 - X - XXX - XXX"
      ]
    },
    {
      "iso3": "ETH",
      "iso2": "ET",
      "id": 231,
      "name": "Ethiopia",
      "masks": [
        "+251 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "FLK",
      "iso2": "FK",
      "id": 238,
      "name": "Falkland (Malvinas) Islands",
      "masks": [
        "+500 - XXXXX"
      ]
    },
    {
      "iso3": "FRO",
      "iso2": "FO",
      "id": 234,
      "name": "Faroe Islands",
      "masks": [
        "+298 - XXX - XXX"
      ]
    },
    {
      "iso3": "FJI",
      "iso2": "FJ",
      "id": 242,
      "name": "Fiji",
      "masks": [
        "+679 - XX - XXXXX"
      ]
    },
    {
      "iso3": "FIN",
      "iso2": "FI",
      "id": 246,
      "name": "Finland",
      "masks": [
        "+358(XXX) XXX - XX - XX"
      ]
    },
    {
      "iso3": "GAB",
      "iso2": "GA",
      "id": 266,
      "name": "Gabon",
      "masks": [
        "+241 - X - XX - XX - XX"
      ]
    },
    {
      "iso3": "GMB",
      "iso2": "GM",
      "id": 270,
      "name": "Gambia",
      "masks": [
        "+220(XXX) XX - XX"
      ]
    },
    {
      "iso3": "GEO",
      "iso2": "GE",
      "id": 268,
      "name": "Georgia",
      "masks": [
        "+995(XXX) XXX - XXX"
      ]
    },
    {
      "iso3": "DEU",
      "iso2": "DE",
      "id": 276,
      "name": "Germany",
      "masks": [
        "+49(XXXX) XXX - XXXX",
        "+49(XXX) XXX - XXXX",
        "+49(XXX) XX - XXXX",
        "+49(XXX) XX - XXX",
        "+49(XXX) XX - XX",
        "+49 - XXX - XXX"
      ]
    },
    {
      "iso3": "GHA",
      "iso2": "GH",
      "id": 288,
      "name": "Ghana",
      "masks": [
        "+233(XXX) XXX - XXX"
      ]
    },
    {
      "iso3": "GIB",
      "iso2": "GI",
      "id": 292,
      "name": "Gibraltar",
      "masks": [
        "+350 - XXX - XXXXX"
      ]
    },
    {
      "iso3": "GRC",
      "iso2": "GR",
      "id": 300,
      "name": "Greece",
      "masks": [
        "+30(XXX) XXX - XXXX"
      ]
    },
    {
      "iso3": "GRL",
      "iso2": "GL",
      "id": 304,
      "name": "Greenland",
      "masks": [
        "+299 - XX - XX - XX"
      ]
    },
    {
      "iso3": "GRD",
      "iso2": "GD",
      "id": 308,
      "name": "Grenada",
      "masks": [
        "+1(473) XXX - XXXX"
      ]
    },
    {
      "iso3": "GTM",
      "iso2": "GT",
      "id": 320,
      "name": "Guatemala",
      "masks": [
        "+502 - X - XXX - XXXX"
      ]
    },
    {
      "iso3": "GIN",
      "iso2": "GN",
      "id": 324,
      "name": "Guinea",
      "masks": [
        "+224 - XX - XXX - XXX"
      ]
    },
    {
      "iso3": "GNB",
      "iso2": "GW",
      "id": 624,
      "name": "Guinea-Bissau",
      "masks": [
        "+245 - X - XXXXXX"
      ]
    },
    {
      "iso3": "GUY",
      "iso2": "GY",
      "id": 328,
      "name": "Guyana",
      "masks": [
        "+592 - XXX - XXXX"
      ]
    },
    {
      "iso3": "HMD",
      "iso2": "HM",
      "id": 334,
      "name": "Heard and McDonald Islands",
      "masks": []
    },
    {
      "iso3": "HND",
      "iso2": "HN",
      "id": 340,
      "name": "Honduras",
      "masks": [
        "+504 - XXXX - XXXX"
      ]
    },
    {
      "iso3": "HUN",
      "iso2": "HU",
      "id": 348,
      "name": "Hungary",
      "masks": [
        "+36(XXX) XXX - XXX"
      ]
    },
    {
      "iso3": "ISL",
      "iso2": "IS",
      "id": 352,
      "name": "Iceland",
      "masks": [
        "+354 - XXX - XXXX"
      ]
    },
    {
      "iso3": "IND",
      "iso2": "IN",
      "id": 356,
      "name": "India",
      "masks": [
        "+91(XXXX) XXX - XXX"
      ]
    },
    {
      "iso3": "IDN",
      "iso2": "ID",
      "id": 360,
      "name": "Indonesia",
      "masks": [
        "+62 - XX - XXX - XX",
        "+62 - XX - XXX - XXX",
        "+62(XXX) XXX - XXXX",
        "+62 - XX - XXX - XXXX",
        "+62(XXX) XXX - XX - XXX",
        "+62(XXX) XXX - XXX - XXX",
        "+62(XXX) XX - XXXX - XXXX"
      ]
    },
    {
      "iso3": "IRN",
      "iso2": "IR",
      "id": 364,
      "name": "Iran",
      "masks": [
        "+98-XXX-XXX-XXXX",
        "+98-XX-XXXX-XXXX"
      ]
    },
    {
      "iso3": "ITA",
      "iso2": "IT",
      "id": 380,
      "name": "Italy",
      "masks": [
        "+39(XXX) XXXX - XXX"
      ]
    },
    {
      "iso3": "JAM",
      "iso2": "JM",
      "id": 388,
      "name": "Jamaica",
      "masks": [
        "+1(876) XXX - XXXX"
      ]
    },
    {
      "iso3": "JPN",
      "iso2": "JP",
      "id": 392,
      "name": "Japan",
      "masks": [
        "+81 - XX - XXXX - XXXX",
        "+81(XXX) XXX - XXX"
      ]
    },
    {
      "iso3": "JEY",
      "iso2": "JE",
      "id": 832,
      "name": "Jersey",
      "masks": []
    },
    {
      "iso3": "JOR",
      "iso2": "JO",
      "id": 400,
      "name": "Jordan",
      "masks": [
        "+962 - X - XXXX - XXXX"
      ]
    },
    {
      "iso3": "KAZ",
      "iso2": "KZ",
      "id": 398,
      "name": "Kazakhstan",
      "masks": [
        "+7(XXX) XXX - XX - XX"
      ]
    },
    {
      "iso3": "KEN",
      "iso2": "KE",
      "id": 404,
      "name": "Kenya",
      "masks": [
        "+254 - XXX - XXXXXX"
      ]
    },
    {
      "iso3": "KIR",
      "iso2": "KI",
      "id": 296,
      "name": "Kiribati",
      "masks": [
        "+686 - XX - XXX"
      ]
    },
    {
      "iso3": "KOR",
      "iso2": "KR",
      "id": 410,
      "name": "Korea Republic",
      "masks": [
        "+82 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "KOS",
      "iso2": "KO",
      "id": 780,
      "name": "Kosovo",
      "masks": []
    },
    {
      "iso3": "KWT",
      "iso2": "KW",
      "id": 414,
      "name": "Kuwait",
      "masks": [
        "+965 - XXXX - XXXX"
      ]
    },
    {
      "iso3": "KGZ",
      "iso2": "KG",
      "id": 417,
      "name": "Kyrgyzstan",
      "masks": [
        "+996(XXX) XXX - XXX"
      ]
    },
    {
      "iso3": "LAO",
      "iso2": "LA",
      "id": 418,
      "name": "Laos",
      "masks": [
        "+856(20XX) XXX - XXX",
        "+856 - XX - XXX - XXX"
      ]
    },
    {
      "iso3": "LVA",
      "iso2": "LV",
      "id": 428,
      "name": "Latvia",
      "masks": [
        "+371 - XX - XXX - XXX"
      ]
    },
    {
      "iso3": "LBN",
      "iso2": "LB",
      "id": 422,
      "name": "Lebanon",
      "masks": [
        "+961 - XX - XXX - XXX",
        "+961 - X - XXX - XXX"
      ]
    },
    {
      "iso3": "LSO",
      "iso2": "LS",
      "id": 426,
      "name": "Lesotho",
      "masks": [
        "+266 - X - XXX - XXXX"
      ]
    },
    {
      "iso3": "LBY",
      "iso2": "LY",
      "id": 434,
      "name": "Libya",
      "masks": [
        "+218 - XX - XXX - XXX",
        "+218 - 21 - XXX - XXXX"
      ]
    },
    {
      "iso3": "LIE",
      "iso2": "LI",
      "id": 438,
      "name": "Liechtenstein",
      "masks": [
        "+423(XXX) XXX - XXXX"
      ]
    },
    {
      "iso3": "LTU",
      "iso2": "LT",
      "id": 440,
      "name": "Lithuania",
      "masks": [
        "+370(XXX) XX - XXX"
      ]
    },
    {
      "iso3": "LUX",
      "iso2": "LU",
      "id": 442,
      "name": "Luxembourg",
      "masks": [
        "+352(XXX) XXX - XXX"
      ]
    },
    {
      "iso3": "MAC",
      "iso2": "MO",
      "id": 446,
      "name": "Macao",
      "masks": [
        "+853 - XXXX - XXXX"
      ]
    },
    {
      "iso3": "MDG",
      "iso2": "MG",
      "id": 450,
      "name": "Madagascar",
      "masks": [
        "+261 - XX - XX - XXXXX"
      ]
    },
    {
      "iso3": "MWI",
      "iso2": "MW",
      "id": 454,
      "name": "Malawi",
      "masks": [
        "+265 - 1 - XXX - XXX",
        "+265 - X - XXXX - XXXX"
      ]
    },
    {
      "iso3": "MYS",
      "iso2": "MY",
      "id": 458,
      "name": "Malaysia",
      "masks": [
        "+60 - XX - XXX - XXXX",
        "+60(XXX) XXX - XXX",
        "+60 - XX - XXX - XXX",
        "+60 - X - XXX - XXX",
        "+60 - XX - XXXX - XXXX"
      ]
    },
    {
      "iso3": "MDV",
      "iso2": "MV",
      "id": 462,
      "name": "Maldives Republic",
      "masks": [
        "+960 - XXX - XXXX"
      ]
    },
    {
      "iso3": "MLI",
      "iso2": "ML",
      "id": 466,
      "name": "Mali",
      "masks": [
        "+223 - XX - XX - XXXX"
      ]
    },
    {
      "iso3": "MLT",
      "iso2": "MT",
      "id": 470,
      "name": "Malta",
      "masks": [
        "+356 - XXXX - XXXX"
      ]
    },
    {
      "iso3": "MTQ",
      "iso2": "MQ",
      "id": 474,
      "name": "Martinique",
      "masks": [
        "+596(XXX) XX - XX - XX"
      ]
    },
    {
      "iso3": "MRT",
      "iso2": "MR",
      "id": 478,
      "name": "Mauritania",
      "masks": [
        "+222 - XX - XX - XXXX"
      ]
    },
    {
      "iso3": "MUS",
      "iso2": "MU",
      "id": 480,
      "name": "Mauritius",
      "masks": [
        "+230 - XXX - XXXX"
      ]
    },
    {
      "iso3": "MEX",
      "iso2": "MX",
      "id": 484,
      "name": "Mexico",
      "masks": [
        "+52 - XX - XXXX - XXXX",
        "+52 - XXX - XXX - XXXX",
        "+52 - XXX - XXXX - XXXX"
      ]
    },
    {
      "iso3": "MDA",
      "iso2": "MD",
      "id": 498,
      "name": "Moldova",
      "masks": [
        "+373 - XXXX - XXXX"
      ]
    },
    {
      "iso3": "MCO",
      "iso2": "MC",
      "id": 492,
      "name": "Monaco",
      "masks": [
        "+377(XXX) XXX - XXX",
        "+377 - XX - XXX - XXX"
      ]
    },
    {
      "iso3": "MNG",
      "iso2": "MN",
      "id": 496,
      "name": "Mongolia",
      "masks": [
        "+976 - XX - XX - XXXX"
      ]
    },
    {
      "iso3": "MSR",
      "iso2": "MS",
      "id": 500,
      "name": "Montserrat",
      "masks": [
        "+1(664) XXX - XXXX"
      ]
    },
    {
      "iso3": "MAR",
      "iso2": "MA",
      "id": 504,
      "name": "Morocco",
      "masks": [
        "+212 - XX - XXXX - XXX"
      ]
    },
    {
      "iso3": "MOZ",
      "iso2": "MZ",
      "id": 508,
      "name": "Mozambique",
      "masks": [
        "+258 - XX - XXX - XXX"
      ]
    },
    {
      "iso3": "NAM",
      "iso2": "NA",
      "id": 516,
      "name": "Namibia",
      "masks": [
        "+264 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "NRU",
      "iso2": "NR",
      "id": 520,
      "name": "Nauru",
      "masks": [
        "+674 - XXX - XXXX"
      ]
    },
    {
      "iso3": "NPL",
      "iso2": "NP",
      "id": 524,
      "name": "Nepal",
      "masks": [
        "+977 - XX - XXX - XXX",
        "+977- XX - XXXX - XXXX"
      ]
    },
    {
      "iso3": "NCL",
      "iso2": "NC",
      "id": 540,
      "name": "New Caledonia",
      "masks": [
        "+687 - XX - XXXX"
      ]
    },
    {
      "iso3": "NZL",
      "iso2": "NZ",
      "id": 554,
      "name": "New Zealand",
      "masks": [
        "+64 - XX - XXX - XXXX",
        "+64 - XXX - XXX - XXXX",
        "+64 - XXXX - XXX - XXX"
      ]
    },
    {
      "iso3": "NER",
      "iso2": "NE",
      "id": 562,
      "name": "Niger",
      "masks": [
        "+227 - XX - XX - XXXX"
      ]
    },
    {
      "iso3": "NGA",
      "iso2": "NG",
      "id": 566,
      "name": "Nigeria",
      "masks": [
        "+234 - XX - XXX - XXX",
        "+234 - XX - XXX - XX",
        "+234(XXX) XXX - XXXX"
      ]
    },
    {
      "iso3": "NIU",
      "iso2": "NU",
      "id": 570,
      "name": "Niue",
      "masks": [
        "+683 - XXXX"
      ]
    },
    {
      "iso3": "NFK",
      "iso2": "NF",
      "id": 574,
      "name": "Norfolk Island",
      "masks": [
        "+672 - 3XX - XXX"
      ]
    },
    {
      "iso3": "MKD",
      "iso2": "MK",
      "id": 807,
      "name": "North Macedonia",
      "masks": [
        "+389 - XX - XXX - XXX"
      ]
    },
    {
      "iso3": "NOR",
      "iso2": "NO",
      "id": 578,
      "name": "Norway",
      "masks": [
        "+47(XXX) XX - XXX"
      ]
    },
    {
      "iso3": "OMN",
      "iso2": "OM",
      "id": 512,
      "name": "Oman",
      "masks": [
        "+968 - XX - XXX - XXX"
      ]
    },
    {
      "iso3": "PAK",
      "iso2": "PK",
      "id": 586,
      "name": "Pakistan",
      "masks": [
        "+92(XXX) XXX - XXXX"
      ]
    },
    {
      "iso3": "PSE",
      "iso2": "PS",
      "id": 275,
      "name": "Palestine",
      "masks": [
        "+970 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "PNG",
      "iso2": "PG",
      "id": 598,
      "name": "Papua New Guinea",
      "masks": [
        "+675(XXX) XX - XXX"
      ]
    },
    {
      "iso3": "PRY",
      "iso2": "PY",
      "id": 600,
      "name": "Paraguay",
      "masks": [
        "+595(XXX) XXX - XXX"
      ]
    },
    {
      "iso3": "PER",
      "iso2": "PE",
      "id": 604,
      "name": "Peru",
      "masks": [
        "+51(XXX) XXX - XXX"
      ]
    },
    {
      "iso3": "PCN",
      "iso2": "PN",
      "id": 612,
      "name": "Pitcairn",
      "masks": []
    },
    {
      "iso3": "POL",
      "iso2": "PL",
      "id": 616,
      "name": "Poland",
      "masks": [
        "+48(XXX) XXX - XXX"
      ]
    },
    {
      "iso3": "PRT",
      "iso2": "PT",
      "id": 620,
      "name": "Portugal",
      "masks": [
        "+351 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "QAT",
      "iso2": "QA",
      "id": 634,
      "name": "Qatar",
      "masks": [
        "+974 - XXXX - XXXX"
      ]
    },
    {
      "iso3": "ROU",
      "iso2": "RO",
      "id": 642,
      "name": "Romania",
      "masks": [
        "+40 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "RWA",
      "iso2": "RW",
      "id": 646,
      "name": "Rwanda",
      "masks": [
        "+250(XXX) XXX - XXX"
      ]
    },
    {
      "iso3": "WSM",
      "iso2": "WS",
      "id": 882,
      "name": "Samoa",
      "masks": [
        "+685 - XX - XXXX"
      ]
    },
    {
      "iso3": "SMR",
      "iso2": "SM",
      "id": 674,
      "name": "San Marino",
      "masks": [
        "+378 - XXXX - XXXXXX"
      ]
    },
    {
      "iso3": "STP",
      "iso2": "ST",
      "id": 678,
      "name": "Sao Tome e Principe",
      "masks": [
        "+239 - XX - XXXXX"
      ]
    },
    {
      "iso3": "SAU",
      "iso2": "SA",
      "id": 682,
      "name": "Saudi Arabia",
      "masks": [
        "+966 - 5 - XXXX - XXXX",
        "+966 - X - XXX - XXXX"
      ]
    },
    {
      "iso3": "SEN",
      "iso2": "SN",
      "id": 686,
      "name": "Senegal",
      "masks": [
        "+221 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "SYC",
      "iso2": "SC",
      "id": 690,
      "name": "Seychelles",
      "masks": [
        "+248 - X - XXX - XXX"
      ]
    },
    {
      "iso3": "SLE",
      "iso2": "SL",
      "id": 694,
      "name": "Sierra Leone",
      "masks": [
        "+232 - XX - XXXXXX"
      ]
    },
    {
      "iso3": "SVN",
      "iso2": "SI",
      "id": 705,
      "name": "Slovenia",
      "masks": [
        "+386 - XX - XXX - XXX"
      ]
    },
    {
      "iso3": "SLB",
      "iso2": "SB",
      "id": 90,
      "name": "Solomon Islands",
      "masks": [
        "+677 - XXX - XXXX",
        "+677 - XXXXX"
      ]
    },
    {
      "iso3": "SOM",
      "iso2": "SO",
      "id": 706,
      "name": "Somalia",
      "masks": [
        "+252 - XX - XXX - XXX",
        "+252 - X - XXX - XXX"
      ]
    },
    {
      "iso3": "ZAF",
      "iso2": "ZA",
      "id": 710,
      "name": "South Africa",
      "masks": [
        "+27 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "SGS",
      "iso2": "GS",
      "id": 239,
      "name": "South Georgia and the South Sandwich Islands",
      "masks": []
    },
    {
      "iso3": "SSD",
      "iso2": "SS",
      "id": 728,
      "name": "South Sudan",
      "masks": [
        "+211 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "LKA",
      "iso2": "LK",
      "id": 144,
      "name": "Sri Lanka",
      "masks": [
        "+94 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "SHN",
      "iso2": "SH",
      "id": 654,
      "name": "St. Helena",
      "masks": [
        "+290 - XXXX"
      ]
    },
    {
      "iso3": "KNA",
      "iso2": "KN",
      "id": 659,
      "name": "St. Kitts and Nevis",
      "masks": [
        "+1(869) XXX - XXXX"
      ]
    },
    {
      "iso3": "LCA",
      "iso2": "LC",
      "id": 662,
      "name": "St. Lucia",
      "masks": [
        "+1(758) XXX - XXXX"
      ]
    },
    {
      "iso3": "SPM",
      "iso2": "PM",
      "id": 666,
      "name": "St. Pierre and Miquelon",
      "masks": []
    },
    {
      "iso3": "VCT",
      "iso2": "VC",
      "id": 670,
      "name": "St. Vincent and the Grenadines",
      "masks": [
        "+1(784) XXX - XXXX"
      ]
    },
    {
      "iso3": "SUR",
      "iso2": "SR",
      "id": 740,
      "name": "Surinam",
      "masks": [
        "+597 - XXX - XXXX",
        "+597 - XXX - XXX"
      ]
    },
    {
      "iso3": "SJM",
      "iso2": "SJ",
      "id": 744,
      "name": "Svalbard and Jan Mayen Islands",
      "masks": []
    },
    {
      "iso3": "SWZ",
      "iso2": "SZ",
      "id": 748,
      "name": "Swaziland",
      "masks": [
        "+268 - XX - XX - XXXX"
      ]
    },
    {
      "iso3": "SWE",
      "iso2": "SE",
      "id": 752,
      "name": "Sweden",
      "masks": [
        "+46 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "CHE",
      "iso2": "CH",
      "id": 756,
      "name": "Switzerland",
      "masks": [
        "+41 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "TWN",
      "iso2": "TW",
      "id": 158,
      "name": "Taiwan",
      "masks": [
        "+886 - X - XXXX - XXXX",
        "+886 - XXXX - XXXX"
      ]
    },
    {
      "iso3": "TJK",
      "iso2": "TJ",
      "id": 762,
      "name": "Tajikistan",
      "masks": [
        "+992 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "TZA",
      "iso2": "TZ",
      "id": 895,
      "name": "Tanzania",
      "masks": [
        "+255(XX) XXXX - XXX"
      ]
    },
    {
      "iso3": "THA",
      "iso2": "TH",
      "id": 764,
      "name": "Thailand",
      "masks": [
        "+66 - XX - XXX - XXXX",
        "+66 - XX - XXX - XXX",
        "+66 - XX - XXXX - XXXX"
      ]
    },
    {
      "iso3": "TGO",
      "iso2": "TG",
      "id": 768,
      "name": "Togo",
      "masks": [
        "+228 - XX - XXX - XXX"
      ]
    },
    {
      "iso3": "TKL",
      "iso2": "TK",
      "id": 772,
      "name": "Tokelau",
      "masks": [
        "+690 - XXXX"
      ]
    },
    {
      "iso3": "TON",
      "iso2": "TO",
      "id": 776,
      "name": "Tonga",
      "masks": [
        "+676 - XXXXX"
      ]
    },
    {
      "iso3": "TTO",
      "iso2": "TT",
      "id": 781,
      "name": "Trinidad and Tobago",
      "masks": [
        "+1(868) XXX - XXXX"
      ]
    },
    {
      "iso3": "TUN",
      "iso2": "TN",
      "id": 788,
      "name": "Tunisia",
      "masks": [
        "+216 - XX - XXX - XXX"
      ]
    },
    {
      "iso3": "TKM",
      "iso2": "TM",
      "id": 795,
      "name": "Turkmenistan",
      "masks": [
        "+993 - X - XXX - XXXX"
      ]
    },
    {
      "iso3": "TCA",
      "iso2": "TC",
      "id": 796,
      "name": "Turks and Caicos Islands",
      "masks": [
        "+1(649) XXX - XXXX"
      ]
    },
    {
      "iso3": "TUV",
      "iso2": "TV",
      "id": 798,
      "name": "Tuvalu",
      "masks": [
        "+688 - 90XXXX",
        "+688 - 2XXXX"
      ]
    },
    {
      "iso3": "UGA",
      "iso2": "UG",
      "id": 800,
      "name": "Uganda",
      "masks": [
        "+256(XXX) XXX - XXX"
      ]
    },
    {
      "iso3": "UKR",
      "iso2": "UA",
      "id": 804,
      "name": "Ukraine",
      "masks": [
        "+380(XX) XXX - XX - XX"
      ]
    },
    {
      "iso3": "ARE",
      "iso2": "AE",
      "id": 784,
      "name": "United Arab Emirates",
      "masks": [
        "+971 - 5X - XXX - XXXX",
        "+971 - X - XXX - XXXX"
      ]
    },
    {
      "iso3": "GBR",
      "iso2": "GB",
      "id": 826,
      "name": "United Kingdom",
      "masks": [
        "+44 - XX - XX - XX - XX - XX"
      ]
    },
    {
      "iso3": "USA",
      "iso2": "US",
      "id": 840,
      "name": "United States of America",
      "masks": [
        "+1 - XXX - XXX - XXXX"
      ]
    },
    /*{
      "iso3": "UMI",
      "iso2": "UM",
      "id": 581,
      "name": "United States Minor Outlying Islands",
      "masks": []
    },*/
    {
      "iso3": "URY",
      "iso2": "UY",
      "id": 858,
      "name": "Uruguay",
      "masks": [
        "+598 - X - XXX - XX - XX"
      ]
    },
    {
      "iso3": "UZB",
      "iso2": "UZ",
      "id": 860,
      "name": "Uzbekistan",
      "masks": [
        "+998 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "VUT",
      "iso2": "VU",
      "id": 548,
      "name": "Vanuatu",
      "masks": [
        "+678 - XX - XXXXX",
        "+678 - XXXXX"
      ]
    },
    {
      "iso3": "VAT",
      "iso2": "VA",
      "id": 336,
      "name": "Vatican (Holy See)",
      "masks": [
        "+39 - 6 - 698 - XXXXX"
      ]
    },
    {
      "iso3": "VEN",
      "iso2": "VE",
      "id": 862,
      "name": "Venezuela",
      "masks": [
        "+58(XXX) XXX - XXXX"
      ]
    },
    {
      "iso3": "VNM",
      "iso2": "VN",
      "id": 704,
      "name": "Vietnam SR",
      "masks": [
        "+84 - XX - XXXX - XXX",
        "+84(XXX) XXXX - XXX"
      ]
    },
    {
      "iso3": "WLF",
      "iso2": "WF",
      "id": 876,
      "name": "Wallis and Futuna Islands",
      "masks": [
        "+681 - XX - XXXX"
      ]
    },
    {
      "iso3": "ESH",
      "iso2": "EH",
      "id": 732,
      "name": "Western Sahara",
      "masks": []
    },
    {
      "iso3": "YEM",
      "iso2": "YE",
      "id": 887,
      "name": "Yemen",
      "masks": [
        "+967 - XXX - XXX - XXX",
        "+967 - X - XXX - XXX",
        "+967 - XX - XXX - XXX"
      ]
    },
    {
      "iso3": "ZMB",
      "iso2": "ZM",
      "id": 894,
      "name": "Zambia",
      "masks": [
        "+260 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "SYR",
      "iso2": "SY",
      "id": 235,
      "name": "Syria",
      "masks": ["+963 - 9XX - XXX - XXX"]
    },
    {
      "iso3": "ISR",
      "iso2": "IL",
      "id": 97,
      "name": "Israel",
      "masks": ["+972 - 5X - XXX - XXXX"]
    },
    {
      "iso3": "TUR",
      "iso2": "TR",
      "id": 792,
      "name": "Turkey",
      "masks": [
        "+90 (XXX) XXX-XX-XX"
      ]
    },
    {
      "iso3": "AFG",
      "iso2": "AF",
      "id": 4,
      "name": "Afghanistan",
      "masks": [
        "+93 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "BIH",
      "iso2": "BA",
      "id": 70,
      "name": "Bosnia and Herzegovina",
      "masks": [
        "+387 - XX - XXX - XXX"
      ]
    },
    {
      "iso3": "CUB",
      "iso2": "CU",
      "id": 192,
      "name": "Cuba",
      "masks": [
        "+53 - X - XXX - XXXX",
        "+53 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "CUW",
      "iso2": "CW",
      "id": 531,
      "name": "Curacao",
      "masks": [
        "+599 - 9 - XXX - XXXX"
      ]
    },
    {
      "iso3": "CYP",
      "iso2": "CY",
      "id": 196,
      "name": "Cyprus",
      "masks": [
        "+357 - XX - XXX - XXX"
      ]
    },
    {
      "iso3": "COD",
      "iso2": "CD",
      "id": 180,
      "name": "Democratic Republic of the Congo",
      "masks": [
        "+243 - XXX - XXX - XXX"
      ]
    },
    {
      "iso3": "DOM",
      "iso2": "DO",
      "id": 214,
      "name": "Dominican Republic",
      "masks": [
        "+1 - 809 - XXX - XXXX",
        "+1 - 829 - XXX - XXXX",
        "+1 - 849 - XXX - XXXX"
      ]
    },
    {
      "iso3": "EST",
      "iso2": "EE",
      "id": 233,
      "name": "Estonia",
      "masks": [
        "+372 - XXX - XXXX"
      ]
    },
    {
      "iso3": "FRA",
      "iso2": "FR",
      "id": 250,
      "name": "France",
      "masks": [
        "+33 - X - XX - XX - XX - XX"
      ]
    },
    {
      "iso3": "PYF",
      "iso2": "PF",
      "id": 258,
      "name": "French Polynesia",
      "masks": [
        "+689 - XX - XX - XX"
      ]
    },
    {
      "iso3": "GUM",
      "iso2": "GU",
      "id": 316,
      "name": "Guam",
      "masks": [
        "+1 - 671 - XXX - XXXX"
      ]
    },
    {
      "iso3": "GGY",
      "iso2": "GG",
      "id": 831,
      "name": "Guernsey",
      "masks": [
        "+44 - 1481 - XXXXXX"
      ]
    },
    {
      "iso3": "HTI",
      "iso2": "HT",
      "id": 332,
      "name": "Haiti",
      "masks": [
        "+509 - XXXX - XXXX"
      ]
    },
    {
      "iso3": "HKG",
      "iso2": "HK",
      "id": 344,
      "name": "Hong Kong",
      "masks": [
        "+852 - XXXX - XXXX"
      ]
    },
    {
      "iso3": "IRQ",
      "iso2": "IQ",
      "id": 368,
      "name": "Iraq",
      "masks": [
        "+964 - XXX - XXX - XXXX"
      ]
    },
    {
      "iso3": "IRL",
      "iso2": "IE",
      "id": 372,
      "name": "Ireland",
      "masks": [
        "+353 - XX - XXX - XXXX"
      ]
    },
    {
      "iso3": "IMN",
      "iso2": "IM",
      "id": 833,
      "name": "Isle of Man",
      "masks": [
        "+44 - 1624 - XXXXXX"
      ]
    },
    {
      "iso3": "CIV",
      "iso2": "CI",
      "id": 384,
      "name": "Ivory Coast",
      "masks": [
        "+225 - XX - XX - XXXX - XXXX"
      ]
    },
    {
      "iso3": "LBR",
      "iso2": "LR",
      "id": 430,
      "name": "Liberia",
      "masks": [
        "+231 - XXX - XXX - XXX"
      ]
    },
    {
      "iso3": "MHL",
      "iso2": "MH",
      "id": 584,
      "name": "Marshall Islands",
      "masks": [
        "+692 - XXX - XXXX"
      ]
    },
    {
      "iso3": "MYT",
      "iso2": "YT",
      "id": 175,
      "name": "Mayotte",
      "masks": [
        "+262 - XX - XX - XX - XX"
      ]
    },
    {
      "iso3": "FSM",
      "iso2": "FM",
      "id": 583,
      "name": "Micronesia",
      "masks": [
        "+691 - XXX - XXXX"
      ]
    },
    {
      "iso3": "MNE",
      "iso2": "ME",
      "id": 499,
      "name": "Montenegro",
      "masks": [
        "+382 - XX - XXX - XXX"
      ]
    },
    {
      "iso3": "MMR",
      "iso2": "MM",
      "id": 104,
      "name": "Myanmar",
      "masks": [
        "+95 - XX - XXX - XXXX"
      ]
    },

    {
      "iso3": "NLD",
      "iso2": "NL",
      "id": 156,
      "name": "Netherlands",
      "masks": [
        "+31 - XXX - XXX - XXXX"
      ]
    },
    {
      "iso3": "NIC",
      "iso2": "NI",
      "id": 558,
      "name": "Nicaragua",
      "masks": [
        "+505 - XXXX - XXXX"
      ]
    },
    {
      "iso3": "MNP",
      "iso2": "MP",
      "id": 580,
      "name": "Northern Mariana Islands",
      "masks": [
        "+1 - 670 - XXX - XXXX"
      ]
    },
    {
      "iso3": "PLW",
      "iso2": "PW",
      "id": 585,
      "name": "Palau",
      "masks": [
        "+680 - XXX - XXXX"
      ]
    },
    {
      "iso3": "PAN",
      "iso2": "PA",
      "id": 591,
      "name": "Panama",
      "masks": [
        "+507 - XXX - XXXX"
      ]
    },
    {
      "iso3": "PHL",
      "iso2": "PH",
      "id": 608,
      "name": "Philippines",
      "masks": [
        "+63 - XX - XXX - XXXX"
      ]
    },


    {
      "iso3": "PRI",
      "iso2": "PR",
      "id": 630,
      "name": "Puerto Rico",
      "masks": [
        "+1 - 787 - XXX - XXXX",
        "+1 - 939 - XXX - XXXX"
      ]
    },
    {
      "iso3": "COG",
      "iso2": "CG",
      "id": 178,
      "name": "Republic of the Congo",
      "masks": [
        "+242 - XXX - XXX - XXX"
      ]
    },
    {
      "iso3": "REU",
      "iso2": "RE",
      "id": 638,
      "name": "Reunion",
      "masks": [
        "+262 - XXX - XX - XX - XX"
      ]
    },
    {
      "iso3": "STP",
      "iso2": "ST",
      "id": 678,
      "name": "Sao Tome and Principe",
      "masks": [
        "+239 - XXX - XXXX"
      ]
    },
    {
      "iso3": "SRB",
      "iso2": "RS",
      "id": 688,
      "name": "Serbia",
      "masks": [
        "+381 - XX - XXXX - XXX"
      ]
    },
    {
      "iso3": "SGP",
      "iso2": "SG",
      "id": 702,
      "name": "Singapore",
      "masks": [
        "+65 - XXXX - XXXX"
      ]
    },
    {
      "iso3": "SXM",
      "iso2": "SX",
      "id": 534,
      "name": "Sint Maarten",
      "masks": [
        "+1 - 721 - XXX - XXXX"
      ]
    },
    {
      "iso3": "SVK",
      "iso2": "SK",
      "id": 703,
      "name": "Slovakia",
      "masks": [
        "+421 - XXX - XXX - XXX"
      ]
    },
    {
      "iso3": "ESP",
      "iso2": "ES",
      "id": 724,
      "name": "Spain",
      "masks": [
        "+34 - XXX - XXX - XXX"
      ]
    },
    {
      "iso3": "SDN",
      "iso2": "SD",
      "id": 729,
      "name": "Sudan",
      "masks": [
        "+249 - XXX - XXX - XXXX"
      ]
    },
    {
      "iso3": "VIR",
      "iso2": "VI",
      "id": 850,
      "name": "U.S. Virgin Islands",
      "masks": [
        "+1 - 340 - XXX - XXXX"
      ]
    },
    {
      "iso3": "ZWE",
      "iso2": "ZW",
      "id": 716,
      "name": "Zimbabwe",
      "masks": [
        "+263 - XX - XXX - XXXX"
      ]
    }
  ]
};
