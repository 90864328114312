//core
import React from "react";
import {
  Drawer,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";

//icons
import CloseIcon from "@mui/icons-material/Close";

//styles
import { useStyles } from "./styles";


interface IMuiDrawerWrapperProps {
  title?: string;
  open: boolean;
  toggleDrawerState: (state: boolean) => void;
  children: React.ReactNode;
  reportDrawer?: boolean;
}

const MuiDrawerWrapper: React.FC<IMuiDrawerWrapperProps> = ({
  title= '',
  open,
  toggleDrawerState,
  reportDrawer = false,
  children,
}): JSX.Element => {
  const styles = useStyles();
  const mobile = useMediaQuery('(max-width:767px)');

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    toggleDrawerState(open);
  };

  return (
    <>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={toggleDrawer(false)}
        classes={{
          root: styles.root,
          paper: reportDrawer ? styles.reportPaper : styles.paper,
        }}
      >
        <Box className={styles.wrapper}>
          <Box className={styles.header}>
            <Box>
              {!!title && (
                <Typography align="center" variant={mobile ? 'h6' : 'h5'} className={styles.title}>
                  {title}
                </Typography>
              )}
            </Box>
            <IconButton
              color="secondary"
              aria-label="Close"
              size="small"
              onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className={styles.content}>
            {children}
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default MuiDrawerWrapper;
