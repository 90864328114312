//core
import React, {useState, useEffect} from "react";
import arePropsEqual from "react-fast-compare";
import { useSelector } from "react-redux";
import { Switch } from "@mui/material";

//hooks
import { useCreatives } from "../../../../../store/admin/сreativesV2/useCreatives";
import { usePermissions } from "../../../../../store/common/configuration/usePermissions";

//selectors
import {
  selectCreativesToggleHideError
} from "../../../../../store/admin/сreativesV2/selectors";

export type itemListData = {
  [key: string]: any;
}

interface ISwitcherProps {
  value: boolean;
  creative: itemListData;
}

const HideSwitcherV2: React.FC<ISwitcherProps> = ({ value, creative }): JSX.Element => {
  const { hasPermissions } = usePermissions();
  const { updateCreativeHideField } = useCreatives();

  const [checked, setChecked] = useState<boolean>(value);

  const error = useSelector(selectCreativesToggleHideError);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateCreativeHideField({
      hidden: event.target.checked,
      creative_id: creative.id,
    });

    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (!!error) {
      if (error.creative_id === creative.id) {
        setChecked(!checked);
      }
    }
  }, [error]);

  const disableEdit = creative.format === 'link' && !hasPermissions(["api3.admin.creativesv1controller.edit_link"]) ||
    creative.format === 'external' && !hasPermissions(["api3.admin.creativesv1controller.edit_external"]) ||
    creative.format === 'smartlink' && !hasPermissions(["api3.admin.creativesv1controller.edit_smartlink"]);


  return (<>
      <Switch
        size="small"
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
        disabled={disableEdit}
      />
  </>

  );
};

export default React.memo(HideSwitcherV2, arePropsEqual);
