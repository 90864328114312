import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  block: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: 16,
    marginBottom: 0,
    paddingBottom: 16,
    '@media (max-width: 767px)': {},
  },
});
