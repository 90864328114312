import { makeStyles } from "@mui/styles";
//import { alpha } from "@mui/material/styles";
import { Theme } from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },
  header: {},

  tabsWrapper: {
    display: "flex",
    marginBottom: 20,
    '& button': {
      whiteSpace: "nowrap",
      flexShrink: 0,
    },
  },
  tabs: {
    flexShrink: 1,
  },
  tabList: {
    width: '100%',
    marginBottom: -1,
  },
  tab: {
    width: '50%',
    '& .MuiBadge-root': {
      margin: '0 -12px 0 0',
    }
  },
  panels: {
    flex: '1 1 auto',
  },
  panel: {
    width: '100%',
    height: '100%',
    padding: 0,
  },
  counter: {
    '& > span': {
      marginRight: -12,
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    }
  },
}));
