//core
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";

//service
import {configurationService} from "../../../services/common/configuration.service";

//helpers
import {setCookie} from "../../../helpers/utils";

//const
import {cookieSupervision, cookieToken} from "../../../constants";

//actions
import {setUser} from "../user";
import {setGeneralProgressHide} from "../ui";
import { gaEvents } from "../../../helpers/gaEvents";

//types
export type permissionsType = {
  permissions: string[],
  permissions_by_role: {
    affiliate_default?: string[],
    super_admin?: string[],
  },
}

export type configurationSliceState = {
  config: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  csrf_token: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  upload_config: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  permission: {
    data: permissionsType | null,
    error: any,
    isFetching: boolean,
  },
  language: {
    data: any | null,
    error: any,
    isFetching: boolean,
  }
}

const initialState: configurationSliceState = {
  config: {
    data: null,
    error: null,
    isFetching: false,
  },
  csrf_token: {
    data: null,
    error: null,
    isFetching: false,
  },
  upload_config: {
    data: null,
    error: null,
    isFetching: false,
  },
  permission: {
    data: null,
    error: null,
    isFetching: false,
  },
  language: {
    data: null,
    error: null,
    isFetching: false,
  }
};

export const getConfig: any = createAsyncThunk(
  'configuration/config',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await configurationService.getConfig();
      //const data = await response.json();
      const data = response;

      dispatch(getCsrfToken());

      /*if (response.ok) {
        dispatch(getCsrfToken());
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }*/

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getCsrfToken: any = createAsyncThunk(
  'configuration/csrf_token',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await configurationService.getCsrfToken();
      const data = await response.json();

      if (response.ok) {
        dispatch(getUploadConfig());
      }

      if (!response.ok) {
        return rejectWithValue(data)
      } else {
        setCookie(cookieSupervision, !!data.supervision);
      }

      setCookie(cookieToken, data.authenticity_token);

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getUploadConfig: any = createAsyncThunk(
  'configuration/upload_config',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await configurationService.getUploadConfig();
      let data = await response.json();

      if (response.ok) {
        if (data.hasOwnProperty('current_user')) {
          const userData = {
            authorized: true,
            user: {
              ...data.current_user,
              locale: data.locale || 'en_US'
            }
          };

          gaEvents.setUserProperty({
            userId: data.current_user.id,
            platform: data.current_user.platform.name,
          })

          dispatch(setUser(userData));
          localStorage.setItem('user', JSON.stringify(userData));
        } else {
          gaEvents.setUserId(null);
          dispatch(setUser(null));
          localStorage.removeItem('user');
        }

        dispatch(getPermission());
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getPermission: any = createAsyncThunk(
  'configuration/permission',
  async (_, { rejectWithValue }) => {
    try {
      const response = await configurationService.getPermission();
      let data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const setLanguage: any = createAsyncThunk(
  'configuration/setLanguage',
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await configurationService.setLanguage(payload);
      let data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

//slice
const configurationSlice = createSlice({
  name: 'configuration',
  initialState: initialState,
  reducers: {
    setSupervision(state, action) {
      state.csrf_token.data = {
        ...state.csrf_token.data,
        supervision: action.payload,
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<configurationSliceState>>) => {
    builder.addCase(getConfig.pending, (state ) => {
      state.config.isFetching = true;
      state.config.error = null;
    });
    builder.addCase(getConfig.fulfilled, (state, action ) => {
      state.config.error = null;
      state.config.isFetching = false;
      state.config.data = action.payload;
    });
    builder.addCase(getConfig.rejected, (state , action) => {
      state.config.error = action.payload;
    });

    builder.addCase(getCsrfToken.pending, (state ) => {
      state.csrf_token.isFetching = true;
      state.csrf_token.error = null;
    });
    builder.addCase(getCsrfToken.fulfilled, (state , action) => {
      state.csrf_token.error = null;
      state.csrf_token.isFetching = false;
      state.csrf_token.data = action.payload;
    });
    builder.addCase(getCsrfToken.rejected, (state , action) => {
      state.csrf_token.error = action.payload;
    });

    builder.addCase(getUploadConfig.pending, (state ) => {
      state.upload_config.isFetching = true;
      state.upload_config.error = null;
    });
    builder.addCase(getUploadConfig.fulfilled, (state, action ) => {
      state.upload_config.error = null;
      state.upload_config.isFetching = false;
      state.upload_config.data = action.payload;
    });
    builder.addCase(getUploadConfig.rejected, (state, action ) => {
      state.upload_config.error = action.payload;
    });

    builder.addCase(getPermission.pending, (state ) => {
      state.permission.isFetching = true;
      state.permission.error = null;
    });
    builder.addCase(getPermission.fulfilled, (state, action ) => {
      state.permission.error = null;
      state.permission.isFetching = false;
      state.permission.data = action.payload;
    });
    builder.addCase(getPermission.rejected, (state, action ) => {
      state.permission.error = action.payload;
    });

    builder.addCase(setLanguage.pending, (state ) => {
      state.language.isFetching = true;
      state.language.error = null;
    });
    builder.addCase(setLanguage.fulfilled, (state, action ) => {
      state.language.error = null;
      state.language.isFetching = false;
      state.language.data = action.payload;
    });
    builder.addCase(setLanguage.rejected, (state, action ) => {
      state.language.error = action.payload;
    });
  },
});

export default configurationSlice.reducer;

export const {
  setSupervision,
} = configurationSlice.actions;
