import { api } from "../../api";
import {handleResponse, RequestOptions} from "../types";
import {getCookie} from "../../helpers/utils";
import {cookieToken} from "../../constants";

const getFormData = () => {
  const authenticity_token = getCookie(cookieToken);

  return fetch(`${api.admin.creatives_v2.form}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const createCreative = (data: any) => {
  return fetch(`${api.admin.creatives_v2.create}`, {...RequestOptions('POST', JSON.stringify(data))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const editCreative = (data: any) => {
  return fetch(`${api.admin.creatives_v2.edit}${data.creative.id ? data.creative.id : null}`, {...RequestOptions('PUT', JSON.stringify(data))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const deleteCreative = (id: string) => {
  const url = api.admin.creatives_v2.delete.replace('{id}', id);
  const authenticity_token = getCookie(cookieToken);

  return fetch(`${url}`, {...RequestOptions('DELETE', JSON.stringify({authenticity_token: authenticity_token} ))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getCreative = (id: string) => {
  return fetch(`${api.admin.creatives_v2.get}${id}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getList = (data: any) => {
  return fetch(`${api.admin.creatives_v2.list}`, {...RequestOptions('POST', JSON.stringify(data))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getFilter = () => {
  return fetch(`${api.admin.creatives_v2.filters}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const toggleHideCreative = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(`${api.admin.creatives_v2.toggle_hide.replace('{id}', `${data.creative_id}`)}`, {...RequestOptions('PATCH', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getBrandsList = (data: any) => {
  return fetch(`${api.admin.creatives_v2.brands_list}`, {...RequestOptions('POST', JSON.stringify(data))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getMirrorForBrand = (data: any) => {
  const endpoint = api.admin.creatives_v2.brand_mirror.replace('{brandId}', data.id);

  return fetch(`${endpoint}`, {...RequestOptions('GET')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const activateMirrorForBrand = (data: any) => {
  const endpoint = api.admin.creatives_v2.activate_mirror.replace('{brandId}', data.id);

  return fetch(`${endpoint}`, {...RequestOptions('PUT', JSON.stringify({isActiveMirror: data.isActiveMirror,  mirrorSource: data.mirrorSource}))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const copyCreative = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };
  const url = api.admin.creatives_v2.copy.replace('{id}', data.id);

  return fetch(`${url}`, {...RequestOptions('POST', JSON.stringify(payload ))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getCreativesOptionsList = (data: any) => {
  return fetch(`${api.admin.creatives_v2.creatives_options}`, {...RequestOptions('POST', JSON.stringify(data))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

export const creativesService = {
  getFormData,
  createCreative,
  deleteCreative,
  editCreative,
  getCreative,
  getFilter,
  getList,
  toggleHideCreative,
  getBrandsList,
  copyCreative,
  getMirrorForBrand,
  activateMirrorForBrand,
  getCreativesOptionsList,
};
