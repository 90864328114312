//core
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Card, Box, Typography, Button } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

//styles
import { useStyles } from "./styles";

//components
import CardMenu from "../CardMenu";

//types
import { ListItemData } from "../../../../../../../store/admin/employees";
import routes from "../../../../../../../navigation/routes";

interface IItemCardProps {
  data: ListItemData;
}

const ItemCard: React.FC<IItemCardProps> = ({
  data,
}): JSX.Element => {
  const styles = useStyles();
  const { t } = useTranslation();
  let history = useNavigate();

  const handleDetails = () => {
    history(routes.admin.employees.details.replace(':id', `${data.id}`))
  }

  return (
    <Card className={styles.card} elevation={1}>
      <CardMenu data={data} />
      <Box className={styles.top}>
        <Box className={styles.topContent}>
          <Typography className={`${styles.id} ${styles.textSecondary}`} component="div" variant="caption">
            {`${t("columns_for_display.table.employees_report.id")} ${data.id}`}
          </Typography>
          <Typography variant="subtitle2" sx={{ wordBreak: 'break-word' }}>
            {data.name}
          </Typography>
        </Box>
      </Box>

      <Box>
        <Box className={styles.field}>
          <Typography className={styles.label} variant="body2" component="span">
            {t("columns_for_display.table.employees_report.platform_name")}:
          </Typography>
          <Typography variant="body2" component="span" sx={{ wordBreak: 'break-word' }}>
            {!!data.platform_name ? data.platform_name : '—'}
          </Typography>
        </Box>

        <Box className={styles.field}>
          <Typography className={styles.label} variant="body2" component="span">
            {t("columns_for_display.table.employees_report.role_name")}:
          </Typography>
          <Typography variant="body2" component="span" sx={{ wordBreak: 'break-word' }}>
            {!!data.role_name ? data.role_name : '—'}
          </Typography>
        </Box>

        <Box className={`${styles.field} ${styles.emailField}`}>
          <Typography className={styles.label} variant="body2" component="span">
            {t("columns_for_display.table.employees_report.email")}:
          </Typography>
          <Typography className={styles.email} variant="body2" component="span" sx={{ wordBreak: 'break-word' }}>
            <a href={`mailto:${data.email}`} target="_blank" rel="noopener noreferrer" className={styles.text}>
              {data.email}
            </a>
          </Typography>
        </Box>
      </Box>

      <Box className={styles.actions}>
        <Button
          className={styles.button}
          color="primary"
          size="small"
          variant="outlined"
          endIcon={<ArrowForward />}
          onClick={handleDetails}
        >
          {t("common.buttons.view_all_info")}
        </Button>
      </Box>
    </Card>
  );
};

export default ItemCard;
