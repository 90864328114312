//core
import React from "react";

//hooks
//import {useConfiguration} from "../../../store/common/configuration/useConfiguration";
import {usePermissions} from "../../../store/common/configuration/usePermissions";

interface IPermissionsWrapper {
  permissions: string[];
  children: React.ReactNode;
}

const PermissionsWrapper = (props: IPermissionsWrapper): JSX.Element => {
  const { hasPermissions } = usePermissions();

  const { permissions, children } = props;

  const isPermission = hasPermissions(permissions);

  return (
    <>
      { isPermission && children }
    </>
  );
};

export default PermissionsWrapper;
