import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import { cookieToken } from "../../constants";

const sendConfirmPassword = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.two_factor_auth.verify_password.replace(
    '{type}',
    data.type,
  );

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions(data.method, JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const resendConfirmEmailPassword = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.two_factor_auth.email.resend_password;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const enableEmail = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.two_factor_auth.email.enable_2fa;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('PUT', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const makeDefault = (factor: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.two_factor_auth.make_default.replace(
    '{factor}',
    factor,
  );

  const payload = {
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const enableOtp = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.two_factor_auth.otp.enable_2fa;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(`${endpoint}`, {...RequestOptions('PUT', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

export const twoFactorAuthService = {
  sendConfirmPassword,
  resendConfirmEmailPassword,
  enableEmail,
  makeDefault,
  enableOtp,
};
