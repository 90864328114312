//core
import React, {useRef} from "react";
import arePropsEqual from "react-fast-compare";
import {makeStyles} from "@mui/styles";
import {Box, Tooltip} from "@mui/material";
import useDimensions from "../../../../../customHooks/useDimensions";

interface ITextProps {
  value: string;
  maxWidth: number;
  width?: number;
  tooltipText: string;
}

const useStyles = makeStyles({
  text: {
    color: "inherit",
    width: "150px",
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
    //wordBreak: 'break-word'
  },
  block: {
    position: "relative",
    color: "inherit",
    display: "block",
    width: "100%",
    minWidth: "60px",
  },
  wrapper: {
    width: "max-content",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

const BillingTooltipOverlay = ({ value, maxWidth = 200, width = 150, tooltipText }: ITextProps) => {
  const styles = useStyles();
  const wrapperRef: any = useRef(null);

  return (
      <>
        <Box className={styles.block} sx={{ maxWidth: `${maxWidth}px` }}>
          <Box ref={wrapperRef} className={styles.wrapper}
               sx={{ opacity: 0, visibility: "hidden", position: "absolute" }}
          >
            { value }
          </Box>
          {!!value && value !== '—' && value !== '0$' ? (
            <Tooltip title={tooltipText}>
              <span className={styles.text} style={{ width: `${width}px` }}>
                { value ? value : '—' }
              </span>
            </Tooltip>
          ) : (
            <span className={styles.text} style={{ width: `${width}px` }}>
                { value ? value : '—' }
              </span>
          )}
        </Box>
      </>
  );
};

export default React.memo(BillingTooltipOverlay, arePropsEqual);
