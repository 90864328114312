//core
import React, { Suspense } from "react";
import { Box, LinearProgress } from "@mui/material";

interface ISuspenseLinear {
  children: React.ReactNode;
}

const SuspenseLinear: React.FC<ISuspenseLinear> = ({
  children
}): JSX.Element => {
  return (
    <Suspense fallback={
      <Box sx={{
        display: 'block',
        width: '100%',
        maxWidth: '100%',
        position: 'fixed',
        left: '0',
        top: '0',
        zIndex: '9999'
      }}><LinearProgress /></Box>
    }>
      {children}
    </Suspense>
  );
};

export default SuspenseLinear;
