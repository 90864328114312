import { api } from "../../api";
import { handleResponse, RequestOptions } from "../types";
import { getCookie } from "../../helpers/utils";
import { cookieToken } from "../../constants";

const getNamespaces = () => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.roles_and_permissions.getNamespaces;

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getRoles = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.roles_and_permissions.getRoles;

  return fetch(
    `${endpoint}?authenticity_token=${authenticity_token}&namespace=${data.namespace}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const createRole = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.roles_and_permissions.createRole;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const deleteRole = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.roles_and_permissions.deleteRole.replace('{roleId}', data.roleId);

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('DELETE')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const updateRoleName = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.roles_and_permissions.updateRoleName.replace('{roleId}', data.roleId);

  const payload = {
    role: data.role,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getReportFields = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.roles_and_permissions.getReportFields;

  return fetch(
    `${endpoint}?authenticity_token=${authenticity_token}&id=${data.roleId}&namespace=role&report_name=${data.reportName}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const addReportFields = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.roles_and_permissions.addReportFields;

  const payload = {
    report_field: {
      value: data.value,
      id: data.id,
      namespace: data.namespace,
      report_name: data.report_name,
    },
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const removeReportFields = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.roles_and_permissions.removeReportFields;

  const payload = {
    report_field: data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getProductAccess = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.roles_and_permissions.getProductAccess.replace('{roleId}', data.roleId);

  return fetch(
    `${endpoint}?authenticity_token=${authenticity_token}&id=${data.roleId}&namespace=role&report_name=${data.reportName}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const addProductAccess = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.roles_and_permissions.addProductAccess.replace('{roleId}', data.id);

  const payload = {
    product: data.valueId,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const removeProductAccess = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.roles_and_permissions.removeProductAccess.replace('{roleId}', data.id);

  const payload = {
    product: data.valueId,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getPermissionsTree = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.roles_and_permissions.getPermissionsTree.replace('{roleId}', data.roleId);

  return fetch(
    `${endpoint}?authenticity_token=${authenticity_token}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const savePermissions = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.roles_and_permissions.savePermissions.replace('{roleId}', data.roleId);

  const payload = {
    permission: data.permission,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};


const getPlatforms = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.roles_and_permissions.getPlatforms;

  return fetch(
    `${endpoint}?authenticity_token=${authenticity_token}&namespace=${data.namespace}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};


const getPlatformForRole = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.roles_and_permissions.getPlatformForRole.replace('{roleId}', data.roleId);

  return fetch(
    `${endpoint}?authenticity_token=${authenticity_token}&id=${data.roleId}&namespace=role`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const addPlatformForRole = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.roles_and_permissions.addPlatformForRole.replace('{roleId}', data.id);

  const payload = {
    platform_id: data.valueId,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}${data.valueId === `all` ? `/all` : ''}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const removePlatformForRole = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.roles_and_permissions.removePlatformForRole.replace('{roleId}', data.id);

  const payload = {
    platform_id: data.valueId,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}${data.valueId === `all` ? `/all` : ''}`,
    {...RequestOptions('DELETE', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

export const rolesAndPermissionsService = {
  getNamespaces,
  getRoles,
  createRole,
  deleteRole,
  updateRoleName,
  getReportFields,
  addReportFields,
  removeReportFields,
  getProductAccess,
  addProductAccess,
  removeProductAccess,
  getPermissionsTree,
  savePermissions,
  getPlatforms,
  getPlatformForRole,
  addPlatformForRole,
  removePlatformForRole,
}
