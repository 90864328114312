//core
import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ActionReducerMapBuilder} from "@reduxjs/toolkit/src/mapBuilders";
import {NoInfer} from "@reduxjs/toolkit/src/tsHelpers";

//phone masks
import {defaultMasks} from "../../../constants/default-masks";
import {IDefaultCountries, defaultCountriesPreparation, defaultCountryCodeByBrand} from "../../../helpers/phoneUtils";
import { BRAND } from "../../../api";

//service
import {registrationService} from "../../../services/common/registration.service";
import {gaEvents} from "../../../helpers/gaEvents";

export type trafficSourceItem = {
  id: number,
  name: string
}

export type countryItem = {
  code: string
  name: string
}

export type payloadReferrals = {
  token: string;
}

export type payloadRecoveryPassword = {
  email: string;
}

export type payloadConfirmRecoveryPassword = {
  token: string;
}

export type payloadChangePassword = {
  reset_password_token: string;
  password: string;
  password_confirmation: string;
}

export type registrationSliceState = {
  masks: {
    data: IDefaultCountries
  },
  country: { code: string },
  trafficSource: {
    data: trafficSourceItem[] | null,
    error: any,
    isFetching: boolean,
  },
  countries: {
    data: countryItem[] | null,
    error: any,
    isFetching: boolean,
  },
  regData: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  confirmationData: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  resendConfirmation: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  referrals: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  recovery: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  confirmRecovery: {
    data: any,
    error: any,
    isFetching: boolean,
  },
  changePassword: {
    data: any,
    error: any,
    isFetching: boolean,
  }
}

const initialState: registrationSliceState = {
  masks: {
    data: defaultCountriesPreparation(defaultMasks.countries)
  },
  country: defaultCountryCodeByBrand(`${BRAND}`),
  trafficSource: {
    data: null,
    error: null,
    isFetching: false,
  },
  countries: {
    data: null,
    error: null,
    isFetching: false,
  },
  regData: {
    data: null,
    error: null,
    isFetching: false,
  },
  confirmationData: {
    data: null,
    error: null,
    isFetching: false,
  },
  resendConfirmation: {
    data: null,
    error: null,
    isFetching: false,
  },
  referrals: {
    data: null,
    error: null,
    isFetching: false,
  },
  recovery: {
    data: null,
    error: null,
    isFetching: false,
  },
  confirmRecovery: {
    data: null,
    error: null,
    isFetching: false,
  },
  changePassword: {
    data: null,
    error: null,
    isFetching: false,
  },
}

export const getCountries: any = createAsyncThunk(
  'registration/countries',
  async (_, { rejectWithValue }) => {
    try {
      const response = await registrationService.getCountries();
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data.countries;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const getTrafficSource: any = createAsyncThunk(
  'registration/trafficSource',
  async (_, { rejectWithValue }) => {
    try {
      const response = await registrationService.getTrafficSource();
      const data: any = await response.json();
      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data.traffic_sources;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const registrationUser: any = createAsyncThunk(
  'registrationUser',
  async (payload:any, { rejectWithValue }) => {
    try {
      const response = await registrationService.registration(payload);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      gaEvents.setSignUp();

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const confirmationEmail: any = createAsyncThunk(
  'confirmationEmail',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await registrationService.confirmationEmail(payload.data, payload.hash);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const resendConfirmationEmail: any = createAsyncThunk(
  'resendConfirmationEmail',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await registrationService.resendConfirmationEmail(payload);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const sendRegistrationsReferralsData: any = createAsyncThunk(
  'sendRegistrationsReferralsData',
  async (payload: payloadReferrals, { rejectWithValue }) => {
    try {
      const response = await registrationService.registrationsReferrals(payload);

      if (!response.ok) {
        return rejectWithValue({ error: true })
      }

      return response.ok
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const sendRecoveryData: any = createAsyncThunk(
  'sendRecoveryData',
  async (payload: payloadRecoveryPassword, { rejectWithValue }) => {
    try {
      const response = await registrationService.recoveryPassword(payload);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const confirmRecoveryPasswordData: any = createAsyncThunk(
  'confirmRecoveryPasswordData',
  async (payload: payloadConfirmRecoveryPassword, { rejectWithValue }) => {
    try {
      const response = await registrationService.confirmRecoveryPassword(payload);

      if (!response.ok) {
        return rejectWithValue({ error: true })
      }

      return response.ok
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const sendChangePasswordData: any = createAsyncThunk(
  'sendChangePasswordData',
  async (payload: payloadChangePassword, { rejectWithValue }) => {
    try {
      const response = await registrationService.changePassword(payload);
      const data = await response.json();

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);


//slice
const registrationSlice = createSlice({
  name: 'registration',
  initialState: initialState,
  reducers: {
    registrationSetError(state, action) {
      state.regData.error = action.payload;
    },
    setRegData(state, action) {
      state.regData.data = action.payload;
    },
    clearConfirmationEmailState(state) {
      state.confirmationData.data = null;
      state.confirmationData.error = null;
      state.confirmationData.isFetching = false;
    },
    clearRecoveryState(state) {
      state.recovery.data = null;
      state.recovery.error = null;
      state.recovery.isFetching = false;
    },
    clearConfirmRecoveryState(state) {
      state.recovery.data = null;
      state.recovery.error = null;
      state.recovery.isFetching = false;
    },
    clearChangePasswordState(state) {
      state.changePassword.data = null;
      state.changePassword.error = null;
      state.changePassword.isFetching = false;
    },
    clearConfirmationDataState(state) {
      state.confirmationData.data = null;
      state.confirmationData.error = null;
      state.confirmationData.isFetching = false;
    },
    clearResendConfirmationDataState(state) {
      state.resendConfirmation.data = null;
      state.resendConfirmation.error = null;
      state.resendConfirmation.isFetching = false;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<registrationSliceState>>) => {
    builder.addCase(getCountries.pending, (state ) => {
      state.countries.isFetching = true;
      state.countries.error = null;
    });
    builder.addCase(getCountries.fulfilled, (state, action ) => {
      state.countries.error = null;
      state.countries.isFetching = false;
      state.countries.data = action.payload;
    });
    builder.addCase(getCountries.rejected, (state, action ) => {
      state.countries.error = action.payload;
    });

    builder.addCase(getTrafficSource.pending, (state ) => {
      state.trafficSource.isFetching = true;
      state.trafficSource.error = null;
    });
    builder.addCase(getTrafficSource.fulfilled, (state, action ) => {
      state.trafficSource.error = null;
      state.trafficSource.isFetching = false;
      state.trafficSource.data = action.payload;
    });
    builder.addCase(getTrafficSource.rejected, (state, action ) => {
      state.trafficSource.error = action.payload;
    });

    builder.addCase(registrationUser.pending, (state ) => {
      state.regData.isFetching = true;
      state.regData.error = null;
    });
    builder.addCase(registrationUser.fulfilled, (state, action ) => {
      state.regData.error = null;
      state.regData.isFetching = false;
      state.regData.data = action.payload;
    });
    builder.addCase(registrationUser.rejected, (state, action ) => {
      state.regData.error = action.payload;
      state.regData.isFetching = false;
    });

    builder.addCase(confirmationEmail.pending, (state ) => {
      state.confirmationData.isFetching = true;
      state.confirmationData.error = null;
    });
    builder.addCase(confirmationEmail.fulfilled, (state, action ) => {
      state.confirmationData.error = null;
      state.confirmationData.isFetching = false;
      state.confirmationData.data = action.payload;
    });
    builder.addCase(confirmationEmail.rejected, (state, action ) => {
      state.confirmationData.error = action.payload;
    });

    builder.addCase(resendConfirmationEmail.pending, (state ) => {
      state.resendConfirmation.isFetching = true;
      state.resendConfirmation.error = null;
    });
    builder.addCase(resendConfirmationEmail.fulfilled, (state, action ) => {
      state.resendConfirmation.error = null;
      state.resendConfirmation.isFetching = false;
      state.resendConfirmation.data = action.payload;
    });
    builder.addCase(resendConfirmationEmail.rejected, (state, action ) => {
      state.resendConfirmation.error = action.payload;
    });

    builder.addCase(sendRegistrationsReferralsData.pending, (state ) => {
      state.referrals.isFetching = true;
      state.referrals.error = null;
    });
    builder.addCase(sendRegistrationsReferralsData.fulfilled, (state , action) => {
      state.referrals.error = null;
      state.referrals.isFetching = false;
      state.referrals.data = action.payload;
    });
    builder.addCase(sendRegistrationsReferralsData.rejected, (state , action) => {
      state.referrals.isFetching = false;
      state.referrals.error = action.payload;
    });

    builder.addCase(sendRecoveryData.pending, (state ) => {
      state.recovery.isFetching = true;
      state.recovery.error = null;
    });
    builder.addCase(sendRecoveryData.fulfilled, (state , action) => {
      state.recovery.error = null;
      state.recovery.isFetching = false;
      state.recovery.data = action.payload;
    });
    builder.addCase(sendRecoveryData.rejected, (state , action) => {
      state.recovery.isFetching = false;
      state.recovery.error = action.payload;
    });

    builder.addCase(confirmRecoveryPasswordData.pending, (state ) => {
      state.confirmRecovery.isFetching = true;
      state.confirmRecovery.error = null;
    });
    builder.addCase(confirmRecoveryPasswordData.fulfilled, (state , action) => {
      state.confirmRecovery.error = null;
      state.confirmRecovery.isFetching = false;
      state.confirmRecovery.data = action.payload;
    });
    builder.addCase(confirmRecoveryPasswordData.rejected, (state , action) => {
      state.confirmRecovery.isFetching = false;
      state.confirmRecovery.error = action.payload;
    });

    builder.addCase(sendChangePasswordData.pending, (state ) => {
      state.changePassword.isFetching = true;
      state.changePassword.error = null;
    });
    builder.addCase(sendChangePasswordData.fulfilled, (state , action) => {
      state.changePassword.error = null;
      state.changePassword.isFetching = false;
      state.changePassword.data = action.payload;
    });
    builder.addCase(sendChangePasswordData.rejected, (state , action) => {
      state.changePassword.isFetching = false;
      state.changePassword.error = action.payload;
    });
  },
});

export default registrationSlice.reducer;

export const {
  registrationSetError,
  setRegData,
  clearConfirmationEmailState,
  clearRecoveryState,
  clearConfirmRecoveryState,
  clearChangePasswordState,
  clearConfirmationDataState,
  clearResendConfirmationDataState,
} = registrationSlice.actions;
