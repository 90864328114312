import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit/src/mapBuilders";
import { NoInfer } from "@reduxjs/toolkit/src/tsHelpers";
import { setUser } from "../../common/user";
import { trackersService } from "../../../services/admin/trackers.service";
import { OrderType } from "../../../components/affiliate/Trackers";
//import { setGeneralProgressHide, setGeneralProgressShow, setGeneralSnackbarState } from "../../common/ui";


export type payloadTrackersList = {
  additional_options: null;
  page: number;
  perPage?: number;
  segment_id: number;
  order: OrderType;
  search: {
    keyword: string;
  };
};

export type trackersListItem = {
  aasm_state: string;
  affiliate_email: string;
  affiliate_id: number;
  assigning_at: string;
  condition_id: number;
  created_at: string;
  description: string;
  id: number;
  name: string;
  tracker_code: number;
  updated_at: string;
}

export type trackersColumnsItem = {
  name: string;
  type: string;
  sort: boolean;
  match?: boolean;
}

export interface ITrackersData {
  list: trackersListItem[];
  columns_for_display_data: trackersColumnsItem[];
  start_row_num: number;
  end_row_num: number;
  total_entries: number | string;
  total_pages: number;
  locale_key: string;
  stateList: string[];
}


export type trackersSliceState = {
  list: {
    data: ITrackersData | null,
    error: any,
    isFetching: boolean,
  },
};

const initialState: trackersSliceState = {
  list: {
    data: null,
    error: null,
    isFetching: false,
  },
};

export const getTrackersListData: any = createAsyncThunk(
  'admTrackers/getTrackersListData',
  async (payload: payloadTrackersList, { rejectWithValue, dispatch }) => {
    try {
      const response = await trackersService.getTrackers(payload);
      const data = await response.json();

      if (data.hasOwnProperty('authorized') && data.authorized === false) {
        dispatch(setUser(null));
        localStorage.removeItem('user');
      }

      if (!response.ok) {
        return rejectWithValue(data)
      }

      return data;
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const admTrackersSlice = createSlice({
  name: 'admTrackers',
  initialState: initialState,
  reducers: {
    clearTrackerListState(state) {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = null;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<trackersSliceState>>) => {
    builder.addCase(getTrackersListData.pending, (state ) => {
      state.list.isFetching = true;
      state.list.error = null;
    });
    builder.addCase(getTrackersListData.fulfilled, (state , action) => {
      state.list.error = null;
      state.list.isFetching = false;
      state.list.data = action.payload;
    });
    builder.addCase(getTrackersListData.rejected, (state , action) => {
      state.list.isFetching = false;
      state.list.error = action.payload;
      state.list.data = null;
    });
  },
});

export const {
  clearTrackerListState,
} = admTrackersSlice.actions;

export default admTrackersSlice.reducer;
