import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateRange, DateRangePicker, SingleInputDateRangeField  } from "@mui/x-date-pickers-pro";
import { Box, InputAdornment, IconButton } from "@mui/material";

//icon
import Calendar from "@mui/icons-material/Event";

//locales
import ruLocale from "date-fns/locale/ru";
import ukLocale from "date-fns/locale/uk";
import enLocale from "date-fns/locale/en-US";

//styles
import { useStyles } from "./styles";

const localeMap = {
  en: enLocale,
  ru: ruLocale,
  uk: ukLocale,
};

interface ICDateRangePickerProps {
  startDate: any,
  endDate: any,
  onChange: (start: Date, end: Date) => void;
  label?: string;
}


const CDateRangePicker: React.FC<ICDateRangePickerProps> = ({
  startDate,
  endDate,
  onChange,
  label = 'common.forms.fields.custom_dates',
}): JSX.Element => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();

  const [locale, setLocale] = useState<object>(localeMap.en);

  const [value, setValue] = React.useState<DateRange<Date>>(() => [
    new Date(startDate),
    new Date(endDate),
  ]);

  useEffect(() => {
    setValue(() => [
      new Date(startDate),
      endDate === null ? null : new Date(endDate),
    ]);
  }, [startDate, endDate]);

  useEffect(() => {
    if (i18n.language === 'ru') {
      setLocale(localeMap.ru);
    }

    if (i18n.language === 'en') {
      setLocale(localeMap.en);
    }

    if (i18n.language === 'uk') {
      setLocale(localeMap.uk);
    }
  }, [i18n.language]);

  /*useDidMountEffect(() => {
    //@ts-ignore
    onChange(value[0], value[1]);
  }, [value]);*/

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  return (
    <Box className={styles.field}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <DateRangePicker
          label={t(label)}
          autoFocus
          value={value}
          onChange={(newValue: DateRange<Date>) => {
            //@ts-ignore
            onChange(newValue[0], newValue[1]);
            setValue(newValue);
          }}
          slots={{
            field: SingleInputDateRangeField,
          }}
          slotProps={{
            textField: {
              size: 'small',
              inputProps: {
                readOnly: true,
                disabled: true,
                style: {
                  pointerEvents: 'none',
                  userSelect: 'none',
                },
                onBeforeInput: disableKeyboardEntry,
                onKeyDown: disableKeyboardEntry,
              },
              InputProps: {
                endAdornment: <InputAdornment position="end">
                  <IconButton size="small" className={styles.iconButton} sx={{ padding: '2px' }}>
                    <Calendar color="action" />
                  </IconButton>
                </InputAdornment>,
                readOnly: true,
                sx: {
                  userSelect: 'none',
                  cursor: 'pointer',
                },
              },
            },
            actionBar: ({ wrapperVariant }) => {
              return {
                actions: wrapperVariant === 'desktop' ? [] : ['accept'],
              }
            },
          }}
          minDate={new Date("2015-01-01T00:00")}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default CDateRangePicker;
