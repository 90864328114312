//core
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import i18n from "i18next";

//action
import {getStatus, setUser, updateUserInfo, userSliceState} from "./index";

//hooks
import routes from "./../../../navigation/routes";

//languages
import {availableBrandLanguages, availableLanguages} from "../../../constants/languages";

//hooks
import { useLanguage } from "../configuration/useLanguage";

export type stateUser = {
  user: userSliceState
};


export const useUser = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  let navigate = useNavigate();

  const { setLanguageData } = useLanguage();

  const { user, status } = useSelector((state: stateUser) => state.user);

  const [ userIsAdmin, setUserIsAdmin ] = useState(false);
  const [ userIsAff, setUserIsAff ] = useState(false);
  const [ userIsApplicant, setUserIsApplicant ] = useState(false);


  useEffect(() => {
    if (!!user && !!user.user) {
      if (!!user.user.locale) {
        //const localLang = localStorage.getItem('i18nextLng');
        const userLang = user.user.locale.slice(0,2);

        if (availableBrandLanguages.includes(userLang)) {
          localStorage.setItem('i18nextLng', userLang);
          i18n.changeLanguage(userLang);
        } else {
          const lang = availableBrandLanguages[0];
          const langCode = availableLanguages.filter((item) => item.lang === lang)[0].code;

          localStorage.setItem('i18nextLng', lang);
          i18n.changeLanguage(lang);
          setLanguageData(langCode);
        }
      }

      if (user.user.namespace === "admin") {
        setUserIsAdmin(true);
      } else {
        setUserIsAdmin(false);
      }

      if (user.user.namespace === "affiliate") {
        setUserIsAff(true);
      } else {
        setUserIsAff(false);
      }

      if (user.user.namespace === "applicant") {
        setUserIsApplicant(true);
      } else {
        setUserIsApplicant(false);
      }
    } else {
      const localUser = localStorage.getItem('user');
      const hasUser = !!localUser;

      if (hasUser) {
        const user = JSON.parse(localUser);
        if (user.user.namespace === "admin") {
          setUserIsAdmin(true);
        }
        if (user.user.namespace === "affiliate") {
          setUserIsAff(true);
        }
        if (user.user.namespace === "applicant") {
          setUserIsApplicant(true);
        }
      } else {
        setUserIsAdmin(false);
        setUserIsAff(false);
        setUserIsApplicant(false);
      }
    }

    // setUserIsAdmin(false);
    // setUserIsAff(false);
    // setUserIsApplicant(true);
  }, [user]);

  useEffect(() => {
    if (!!user) {
      // @ts-ignore
      if (!!location.state && location?.state?.hasOwnProperty('from')) {
        // @ts-ignore
        const fromPage: any = !!location.state && !!location?.state?.from && !!location?.state?.from?.pathname ? `${location?.state?.from.pathname}${location?.state?.from.search}` : routes.root;
        setTimeout(() => {
          navigate(fromPage, {replace: true});
        }, 50)

      }
    }
  }, [user]);

  const setUserData = (data: userSliceState) => dispatch(setUser(data));
  const setUpdateUserInfo = (data: any) => dispatch(updateUserInfo(data));

  const getUserStatus = () => dispatch(getStatus());

  return {
    user,
    status,
    userIsAdmin,
    userIsAff,
    userIsApplicant,
    setUserData,
    setUpdateUserInfo,
    getUserStatus,
  }
}
