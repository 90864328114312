import {createContext} from "react";

interface IBillingContext {
  showNgrModal: boolean;
  setShowNgrModal: (state:boolean) => void;
  showGgrModal: boolean;
  setShowGgrModal: (state:boolean) => void;
}

export const BillingContext = createContext<IBillingContext>({
  showNgrModal: false,
  setShowNgrModal: () => {},
  showGgrModal: false,
  setShowGgrModal: () => {},
});
