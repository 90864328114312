//core
import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

interface ICurrencyProps {
  value: string | number;
  bold?: boolean;
  whiteSpace?: boolean;
  breakAll?: boolean;
}

const useStyles = makeStyles({
  text: {
    color: 'inherit',
  },
  whiteSpace: {
    whiteSpace: 'nowrap',
  }
});

const Currency: React.FC<ICurrencyProps> = ({
  value = '',
  bold = false,
  whiteSpace = false,
  breakAll = false,
}): JSX.Element  => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <span
      className={`${styles.text} ${whiteSpace ? styles.whiteSpace : ''}`}
      style={{
        fontWeight: bold ? "bold" : "inherit",
        wordBreak: breakAll ? "break-all" : "normal",
      }}
    >
      {(value === null || value === '')
        ? '—'
        : value === 'MULTIPLE'
          ? (`${t('common.components.currency.multipleCurrency')}`)
          : value}
    </span>
  );
};

export default Currency;
