import {api} from "../../api";
import {handleResponse, RequestOptions} from "../types";
import {getCookie} from "../../helpers/utils";
import {cookieSupervision, cookieToken} from "../../constants";

const getOptions = () => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.creative_report.options : api.affiliate.creative_report.options;

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}${isSupervision ? '&supervision=true' : ''}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getSegments = () => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.creative_report.segments_list : api.affiliate.creative_report.segments_list;

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}${isSupervision ? '&supervision=true' : ''}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const deleteSegment = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.creative_report.delete_segment : api.affiliate.creative_report.delete_segment;

  return fetch(
    `${endpoint}/${data}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}${isSupervision ? '&supervision=true' : ''}`,
    {...RequestOptions('DELETE')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const createSegment = () => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.creative_report.create_segment : api.affiliate.creative_report.create_segment;

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify({
        authenticity_token: authenticity_token || null,
        ...(isSupervision ? { supervision: true } : {}),
      }))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const updateSegment = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.creative_report.update_segment : api.affiliate.creative_report.update_segment;

  const url = `${endpoint.replace('{segmentId}', data.segmentId)}`;

  return fetch(
    url,
    {
      ...RequestOptions('PUT', JSON.stringify({
        report_segment: data.report_segment,
        authenticity_token: authenticity_token || null,
        ...(isSupervision ? { supervision: true } : {}),
      }))
    }
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getReport = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.creative_report.generate_report : api.affiliate.creative_report.generate_report;

  const sendData = {
    ...data,
    authenticity_token: authenticity_token,
    ...(isSupervision ? { supervision: true } : {}),
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(sendData))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getSegmentTab = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.creative_report.get_segment_tab : api.affiliate.creative_report.get_segment_tab;

  const url = `${endpoint.replace('{segmentId}', data.segmentId)}${data.tabId}`

  return fetch(
    `${url}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}${isSupervision ? '&supervision=true' : ''}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const addSegmentTabData = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.creative_report.add_segment_tab_data : api.affiliate.creative_report.add_segment_tab_data;

  const url = `${endpoint
    .replace('{segmentId}', data.segmentId)
    .replace('{tabId}', data.tabId)}`;

  const payload = {
    segment_tab: data.data,
    authenticity_token: authenticity_token || null,
    ...(isSupervision ? { supervision: true } : {}),
  };

  return fetch(
    `${url}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const removeSegmentTabData = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.creative_report.remove_segment_tab_data : api.affiliate.creative_report.remove_segment_tab_data;

  const url = `${endpoint
    .replace('{segmentId}', data.segmentId)
    .replace('{tabId}', data.tabId)}`;

  const payload = {
    segment_tab: data.data,
    authenticity_token: authenticity_token || null,
    ...(isSupervision ? { supervision: true } : {}),
  };

  return fetch(
    `${url}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const setDownload = (data: any) => {
  const authenticity_token = getCookie(cookieToken);
  const isSupervision = JSON.parse(getCookie(cookieSupervision));

  const endpoint = isSupervision ? api.supervision.creative_report.download_report : api.affiliate.creative_report.download_report;

  const sendData = {
    ...data,
    authenticity_token: authenticity_token,
    ...(isSupervision ? { supervision: true } : {}),
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(sendData))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

export const creativeReportService = {
  getOptions,
  getReport,
  setDownload,
  getSegments,
  deleteSegment,
  createSegment,
  updateSegment,
  getSegmentTab,
  addSegmentTabData,
  removeSegmentTabData,
};
