import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles/createTheme";


export const useStyles = makeStyles<Theme>((theme) => ({
  formField: {
    display: 'flex',
    '& label': {
      color: theme.palette.text.primary,
      '& .MuiFormControlLabel-asterisk': {
        display: 'none',
      }
    }
  },
  smallFont: {
    '& span': {
      fontSize: '0.75em',
    },
  },
}));
