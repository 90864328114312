import { api } from "../../api";
import {handleResponse, RequestOptions} from "../types";
import {getCookie} from "../../helpers/utils";
import {cookieToken} from "../../constants";
import {
  IApproveReports,
  PaymentSetData,
} from "../../store/admin/reports/details_finance_report";

const getListData = (data:any) => {
    const authenticity_token = getCookie(cookieToken);
    const payload = {
        ...data,
        authenticity_token: authenticity_token,
    };
    return fetch(`${api.admin.reports.finance_report.detailed.root}${data.id}/list`, {...RequestOptions('POST', JSON.stringify(payload))})
        .then(handleResponse)
        .then(
            data => {
                return data;
            }
        )
        .catch((error) => {
            return error
        });
}

const getFiltersData = (data: {
  [key: string]: any;
}) => {
    const authenticity_token = getCookie(cookieToken);
    const payload = {
        ...data,
        authenticity_token: authenticity_token,
    };

    const mapping = {
      departmentIds: "departments",
      managerIds: "chiefs",
      currencies: "currencies",
      statuses: "statuses",
      affiliateIds: "affiliates",
      brands: "brands",
      paymentMethodNames: "payment_method_names",
      companies: "affiliate_companies",
    };

    //@ts-ignore
    const field = `${mapping[`${data.label}`]}`;

    return fetch(`${api.admin.reports.finance_report.filters}/${field}`, {...RequestOptions('POST', JSON.stringify(payload))})
        .then(handleResponse)
        .then(
            data => {
                return data;
            }
        )
        .catch((error) => {
            return error
        });
}

const getAdjustmentList = (data:any) => {
    const authenticity_token = getCookie(cookieToken);
    const payload = {
        ...data,
        authenticity_token: authenticity_token,
    };
    return fetch(`${api.admin.reports.finance_report.detailed.adjustment.list}`, {...RequestOptions('POST', JSON.stringify(payload))})
        .then(handleResponse)
        .then(
            data => {
                return data;
            }
        )
        .catch((error) => {
            return error
        });
}

const getAdjustmentReasons = (data:any) => {
    const authenticity_token = getCookie(cookieToken);
    const payload = {
        ...data,
        authenticity_token: authenticity_token,
    };
    return fetch(`${api.admin.reports.finance_report.detailed.adjustment.reasons}`, {...RequestOptions('POST', JSON.stringify(payload))})
        .then(handleResponse)
        .then(
            data => {
                return data;
            }
        )
        .catch((error) => {
            return error
        });
}

const getAdjustmentBrands = () => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.adjustment.brands}`;

  return fetch(
    `${endpoint}?authenticity_token=${authenticity_token}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const setAdjustmentAdd = (data:any) => {
    const authenticity_token = getCookie(cookieToken);
    const payload = {
        ...data,
        authenticity_token: authenticity_token,
    };
    return fetch(`${api.admin.reports.finance_report.detailed.adjustment.add}`, {...RequestOptions('POST', JSON.stringify(payload))})
        .then(handleResponse)
        .then(
            data => {
                return data;
            }
        )
        .catch((error) => {
            return error
        });
}

const setAdjustmentEdit = (data:any) => {
    const authenticity_token = getCookie(cookieToken);
    const payload = {
        ...data,
        authenticity_token: authenticity_token,
    };
    return fetch(`${api.admin.reports.finance_report.detailed.adjustment.edit}`, {...RequestOptions('PUT', JSON.stringify(payload))})
        .then(handleResponse)
        .then(
            data => {
                return data;
            }
        )
        .catch((error) => {
            return error
        });
}

const setAdjustmentApprove = (data:any) => {
    const authenticity_token = getCookie(cookieToken);
    const payload = {
        ...data,
        authenticity_token: authenticity_token,
    };
    return fetch(`${api.admin.reports.finance_report.detailed.adjustment.approve}`, {...RequestOptions('POST', JSON.stringify(payload))})
        .then(handleResponse)
        .then(
            data => {
                return data;
            }
        )
        .catch((error) => {
            return error
        });
}

const setAdjustmentApprovePaid = (data:any) => {
    const authenticity_token = getCookie(cookieToken);
    const payload = {
        ...data,
        authenticity_token: authenticity_token,
    };
    return fetch(`${api.admin.reports.finance_report.detailed.adjustment.approve_paid}`, {...RequestOptions('POST', JSON.stringify(payload))})
        .then(handleResponse)
        .then(
            data => {
                return data;
            }
        )
        .catch((error) => {
            return error
        });
}

const setAdjustmentApproveCoverPrepaymentPaid = (data:any) => {
    const authenticity_token = getCookie(cookieToken);
    const payload = {
        ...data,
        authenticity_token: authenticity_token,
    };
    return fetch(`${api.admin.reports.finance_report.detailed.adjustment.approve_cover_prepayment_paid}`, {...RequestOptions('POST', JSON.stringify(payload))})
        .then(handleResponse)
        .then(
            data => {
                return data;
            }
        )
        .catch((error) => {
            return error
        });
}

const setAdjustmentRemove = (data:any) => {
    const authenticity_token = getCookie(cookieToken);
    const payload = {
        ...data,
        authenticity_token: authenticity_token,
    };
    return fetch(`${api.admin.reports.finance_report.detailed.adjustment.deleted}`, {...RequestOptions('POST', JSON.stringify(payload))})
        .then(handleResponse)
        .then(
            data => {
                return data;
            }
        )
        .catch((error) => {
            return error
        });
}

const downloadReport = (data:any) => {
  const authenticity_token = getCookie(cookieToken);

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  const endpoint = `${api.admin.reports.finance_report.detailed.download.replace('{reportId}', data.id)}`;

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const downloadReportLogs = (data:any) => {
  const authenticity_token = getCookie(cookieToken);

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  const endpoint = `${api.admin.reports.finance_report.detailed.download_with_logs.replace('{reportId}', data.id)}`;

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const setPayment = (data: PaymentSetData) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.payment.change}`;

  const payload = {
    ...data,
    authenticity_token: authenticity_token
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const setManagerApprove = (data: IApproveReports) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.managerApprove.replace('{reportId}', data.periodId)}`;

  const payload = {
    reports: data.reports,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const setCancelManagerApprove = (data: IApproveReports) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.cancelManagerApprove.replace('{reportId}', data.periodId)}`;

  const payload = {
    reports: data.reports,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const setFinManagerApprove = (data: IApproveReports) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.finManagerApprove.replace('{reportId}', data.periodId)}`;

  const payload = {
    reports: data.reports,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const setToPaidApprove = (data: IApproveReports) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.toPaidApprove.replace('{reportId}', data.periodId)}`;

  const payload = {
    reports: data.reports,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const setProcessingPaymentApprove = (data: IApproveReports) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.processingPayment.replace('{reportId}', data.periodId)}`;

  const payload = {
    reports: data.reports,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const setCancelProcessingPaymentApprove = (data: IApproveReports) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.cancelProcessingPayment.replace('{reportId}', data.periodId)}`;

  const payload = {
    reports: data.reports,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const setCarryOverApprove = (data: IApproveReports) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.carryover.replace('{reportId}', data.periodId)}`;

  const payload = {
    reports: data.reports,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const setCancelCarryOverApprove = (data: IApproveReports) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.cancelCarryover.replace('{reportId}', data.periodId)}`;

  const payload = {
    reports: data.reports,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const setChangeComment = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.changeComment
    .replace('{reportId}', data.periodId)}`;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  }

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getBrandsList = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.reports.finance_report.detailed.brands;

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  }

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const getReportRowData = (data:any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.showReportRow.replace('{reportId}', `${data.periodId}`)}`

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getPaymentBreakdownData = (data:any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.brandStatistics.replace('{reportId}', `${data.periodId}`)}`

  const payload = {
    affiliateId: data.affiliateId,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getColumns = () => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.reports.finance_report.detailed.columns;

  return fetch(
    `${endpoint}${authenticity_token ? `?authenticity_token=${authenticity_token}` : ''}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const setPayoutRow = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.addPayoutRow
    .replace('{periodId}', `${data.periodId}`)}`;

  const payload = {
    ...data.payload,
    authenticity_token: authenticity_token
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getPayouts = (data:any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.payoutsList}`

  const payload = {
    affiliateId: data.affiliateId,
    periodId: data.periodId,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const createPayout = (data:any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.createUpdatePayout}`

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const updatePayout = (data:any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.createUpdatePayout}`

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PUT', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const removePayout = (data:any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.createUpdatePayout}`

  const payload = {
    ...data,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('DELETE', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getAvailablePayments = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.availablePayments
    .replace('{periodId}', `${data.periodId}`).replace('{affiliateId}', `${data.affiliateId}`)}`;

  const payload = {
    ...data.payload,
    authenticity_token: authenticity_token
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const editPayment = (data: any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.availablePayments
    .replace('{periodId}', `${data.periodId}`).replace('{affiliateId}', `${data.affiliateId}`)}`;

  const payload = {
    ...data.payload,
    authenticity_token: authenticity_token
  };

  const headers = {
    ...(data.hasOwnProperty('auth_type') ? {"auth-type": data.auth_type} : {}),
    ...(data.hasOwnProperty('auth_code') ? {"auth-code": data.auth_code} : {}),
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('PUT', JSON.stringify(payload), headers)}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getLogs = (data:any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.logs.replace('{$periodId}', `${data.periodId}`)}`;

  const payload = {
    affiliateId: data.affiliateId,
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getPeriodsList = (data:any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = api.admin.reports.finance_report.detailed.periodsList;

  const payload = {
    authenticity_token: authenticity_token,
    ...data,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getInvoice = (data:any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.invoice
    .replace('{periodId}', `${data.periodId}`).replace('{affiliateId}', `${data.affiliateId}`)}`;

  const payload = {
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const uploadInvoice = (data:any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.invoice
    .replace('{periodId}', `${data.periodId}`).replace('{affiliateId}', `${data.affiliateId}`)}`;

  const payload = {
    authenticity_token: authenticity_token,
    ...data,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('POST', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const removeInvoice = (data:any) => {
  const authenticity_token = getCookie(cookieToken);

  const endpoint = `${api.admin.reports.finance_report.detailed.invoice
    .replace('{periodId}', `${data.periodId}`).replace('{affiliateId}', `${data.affiliateId}`)}`;

  const payload = {
    authenticity_token: authenticity_token,
  };

  return fetch(
    `${endpoint}`,
    {...RequestOptions('DELETE', JSON.stringify(payload))}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const getConvertCurrency = (data:any) => {
  const endpoint = `${api.admin.reports.finance_report.detailed.convert_currency.replace('{reportId}', data.reportId)}`;

  return fetch(
    `${endpoint}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

export const detailsFinanceReportService = {
  getListData,
  getFiltersData,
  getAdjustmentList,
  getAdjustmentReasons,
  getAdjustmentBrands,
  setAdjustmentAdd,
  setAdjustmentEdit,
  setAdjustmentApprove,
  setAdjustmentRemove,
  downloadReport,
  downloadReportLogs,
  setPayment,
  setManagerApprove,
  setFinManagerApprove,
  setCancelManagerApprove,
  setToPaidApprove,
  setCarryOverApprove,
  setCancelCarryOverApprove,
  setChangeComment,
  setAdjustmentApprovePaid,
  getBrandsList,
  getReportRowData,
  getColumns,
  getPaymentBreakdownData,
  setPayoutRow,
  setProcessingPaymentApprove,
  setCancelProcessingPaymentApprove,
  getPayouts,
  createPayout,
  updatePayout,
  removePayout,
  getAvailablePayments,
  editPayment,
  getLogs,
  getInvoice,
  uploadInvoice,
  removeInvoice,
  getPeriodsList,
  getConvertCurrency,
  setAdjustmentApproveCoverPrepaymentPaid,
};
