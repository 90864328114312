import { api } from "../../api";
import {handleResponse, ILogin, RequestOptions} from "../types";
import {getCookie} from "../../helpers/utils";
import {cookieToken} from "../../constants";

const login = (data: ILogin) => {
  return fetch(`${api.sign_in}`, {...RequestOptions('POST', JSON.stringify(data))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const logout = () => {
  const authenticity_token = getCookie(cookieToken);

  return fetch(`${api.sign_out}?authenticity_token=${authenticity_token}`, {...RequestOptions('DELETE')})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

const loginWithEmail = (data: any) => {
  return fetch(`${api.two_factor_auth.email.login_check}`, {...RequestOptions('POST', JSON.stringify(data))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

const resendEmailCode = (data: any) => {
  return fetch(`${api.two_factor_auth.email.resend_password}`, {...RequestOptions('POST', JSON.stringify(data))})
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
};

export const authService = {
  login,
  logout,
  loginWithEmail,
  resendEmailCode,
};
