//core
import React from "react";
import { Box } from "@mui/material";

//components
import MobilePagination from "../../../../common/MobilePagination";
import ItemCard from "./components/ItemCard";
import EmptyTable from "../../../../common/TableData/components/EmptyTable";
import { EmployeesListData, ListItemData } from "../../../../../store/admin/employees";

interface IMobileListProps {
  data: EmployeesListData;
  currentPage: number;
  setPage: (event: unknown, newPage: number) => void;
}

const MobileList: React.FC<IMobileListProps> = ({
  data,
  currentPage,
  setPage,
}): JSX.Element => {
  return (
    <>
      <Box>
        {data.list.map((item: ListItemData) => <ItemCard key={item.id} data={item} />)}
      </Box>

      {!data.list.length && <EmptyTable />}

      {!!data.list.length && (
        <MobilePagination
          currentPage={currentPage}
          lastPage={data.total_pages}
          setPage={setPage}
          total={{
            end_row_num: data.end_row_num,
            start_row_num: data.start_row_num,
            total_entries: data.total_entries,
          }}
        />
      )}
    </>
  );
};

export default MobileList;
