import { api } from "../../api";
import {handleResponse, RequestOptions} from "../types";
import {getCookie} from "../../helpers/utils";
import {cookieFullStat, cookieToken} from "../../constants";

const getStats = () => {
  const authenticity_token = getCookie(cookieToken);
  const fullStat = getCookie(cookieFullStat) === 'null' ? false : getCookie(cookieFullStat);

  const url = `${api.admin.dashboard.get_stats}?authenticity_token=${authenticity_token}&full_stat=${fullStat}`

  return fetch(
    `${url}`,
    {...RequestOptions('GET')}
  )
    .then(handleResponse)
    .then(
      data => {
        return data;
      }
    )
    .catch((error) => {
      return error
    });
}

export const dashboardService = {
  getStats,
};
