import { makeStyles } from "@mui/styles";
import {Theme} from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  box: {
    display: "flex",
    alignItems: 'center',
    justifyContent: "space-between",
    gap: 0,
    '@media (max-width: 767px)': {
      justifyContent: "flex-start",
      gap: 12,
    }
  },

}));
