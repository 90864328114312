import { makeStyles } from "@mui/styles";
import {Theme} from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  button: {
    position: "absolute",
    right: 10,
    top: 10,
    height: 36,
    width: 36,
    padding: 4,
    marginLeft: 4,
    lineHeight: "36px",
    minWidth: "auto",
    borderRadius: 0,
  },
  active: {
    backgroundColor: theme.palette.action.hover,
  },
  icon: {
    color: theme.palette.action.active,
  },
  divider: {
    marginTop: '0!important',
    marginBottom: '0!important',
  },
}));
