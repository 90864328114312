const routes = {
  root: '/',
  dashboard: {
    root: '/',
    settings: '/dashboard/settings',
  },

  admin: {
    root: '/admin',
    finance_report: {
      root: '/admin/finance_report',
      finance_report_details_root: '/admin/finance_report/details',
      finance_report_details: '/admin/finance_report/details/:reportId',
      finance_report_details_mobile_view: '/admin/finance_report/details/:reportId/full_details/:affiliateId',
      finance_report_consolidate_details: '/admin/finance_report/consolidate_details/:reportId',
      finance_report_payment_details: '/admin/finance_report/details/:reportId/payment_details',
      //'/admin/finance_report/details/19/payment_details/123123/null/null',
      //'/admin/finance_report/details/19/payment_details/1283126',
      //'/admin/finance_report/details/19/payment_details?affId=1232132&curr=null&brand=null',
    },
    creatives: {
      root: '/admin/creatives',
      creative: '/admin/creatives/:creativeId',
      edit: '/admin/creatives/:creativeId/edit',
      details: {
        root: '/admin/creatives/details',
        main: '/admin/creatives/details/:creativeId',
      },
    },
    creatives_v2: {
      root: '/admin/creatives-v2',
      creative: '/admin/creatives-v2/:creativeId',
      edit: '/admin/creatives-v2/:creativeId/edit',
      create: '/admin/creatives-v2/create',
      details: {
        root: '/admin/creatives-v2/details',
        main: '/admin/creatives-v2/details/:creativeId',
      },
    },
    trackers: {
      root: '/admin/trackers',
      creatives: {
        root: '/admin/trackers/creatives/',
        id: '/admin/trackers/creatives/:trackersId',
      },
      condition: {
        root: '/admin/trackers/condition/',
        id: '/admin/trackers/condition/:trackersId',
      }
    },
    settings: {
      root: '/admin/settings_profile',
      main: '/admin/settings_profile/main',
      password: {
        root: '/admin/settings_profile/password',
      },
      two_fa: {
        root: '/admin/settings_profile/two_fa',
      },
    },
    downloadReport: {
      root: '/admin/download_report',
    },
    partners: {
      root: '/admin/partners',
      details: {
        root: '/admin/partners/details',
        main: '/admin/partners/details/:affId',
      },
      profile: {
        root: '/admin/partners/profile',
        main: '/admin/partners/profile/:affId',
        paymentHistory: {
          root: '/admin/partners/profile/:affId',
          details: '/admin/partners/profile/:affId/payment_history/:id',
          detailsRoot: '/admin/partners/profile/:affId',
        }
      },
      deals: {
        root: '/admin/partners/deals',
        main: '/admin/partners/deals/:affId',
        details: {
          root: '/admin/partners/deals/details',
          main: '/admin/partners/deals/details/:dealId',
        },
        general: {
          root: '/admin/partners/deals/:affId/general',
        },
        trackers: {
          root: '/admin/partners/deals/:affId/trackers',
          conditions: '/admin/partners/deals/:affId/trackers/:trackerId/conditions',
          details: '/admin/partners/deals/:affId/trackers/:trackerId/details',
          creatives: {
            root: '/admin/partners/deals/:affId/trackers/:trackerId/creatives',
            link: {
              root: '/admin/partners/deals/:affId/trackers/:trackerId/creatives/:creativeId'
            }
          },
          additional_settings: '/admin/partners/deals/:affId/trackers/:trackerId/additional_settings',
        },
        subaffiliate: {
          root: '/admin/partners/deals/:affId/condition',
        },
        additional_settings: {
          root: '/admin/partners/deals/:affId/additional_settings',
        }
      },
      new_partner: {
        root: '/admin/partners/new_partner',
      }
    },
    invitation_links: {
      root: '/admin/invitation_links',
    },
    creativeReports: {
      root: '/admin/report/creative',
    },
    summary_report: {
      root: '/admin/summary_report',
    },
    summary_report_v2: {
      root: '/admin/player-and-commission-report',
    },
    player_commission_report: {
      root: '/admin/player-and-commission-report-v2',
    },
    subaff_report: {
      root: '/admin/subaffiliate_reports'
    },
    roles_and_permissions: {
      root: '/admin/roles_and_permissions',
    },
    webhooks_report: {
      root: '/admin/webhooks_report'
    },
    deals: {
      root: '/admin/deals',
      create: '/admin/deals/create',
      edit: '/admin/deals/edit/:id',
      editRoot: '/admin/deals/edit',
      detailsRoot: '/admin/deals/details',
      details: '/admin/deals/details/:id',
    },
    employees: {
      root: '/admin/employees',
      details: '/admin/employees/:id'
    },
    quints_api: {
      root: '/admin/api'
    },
    old_creative_report: {
      root: '/admin/creative_reports_old',
      editRoot: '/admin/deals/edit',
      detailsRoot: '/admin/deals/details',
      details: '/admin/deals/details/:id',
    },
    sub_aff_deals: {
      root: '/admin/sub-affiliate-deals',
      create: '/admin/sub-affiliate-deals/create',
      edit: '/admin/sub-affiliate-deals/edit/:id',
      editRoot: '/admin/sub-affiliate-deals/edit',
      detailsRoot: '/admin/sub-affiliate-deals/details',
      details: '/admin/sub-affiliate-deals/details/:id',
    },
    players_list: {
      root:  '/admin/players_list',
      profile: '/admin/players_list/view_player/:playerId'
    },
    system: {
      root: '/admin/settings',
      email: '/admin/settings/email-templates',
    },
    offers: {
      root: '/admin/offers',
      create: '/admin/offers/create',
      edit: '/admin/offers/edit/:offerId',
    },
    brands: {
      root: '/admin/brands',
      create: '/admin/brands/create',
      edit: '/admin/brands/edit/:brandsId',
      details: '/admin/brands/:brandsId'
    },
    external_brands: {
      root: '/admin/external-brands',
      create: '/admin/external-brands/create',
      edit: '/admin/external-brands/edit/:brandsId',
      details: '/admin/external-brands/:brandsId'
    },
    billing: {
      root: '/admin/billing',
      create_update: '/admin/billing/package',
    },
  },

  affiliate: {
    root: '/affiliate',
    settings: {
      root: '/affiliate/settings_profile',
      main: '/affiliate/settings_profile/main',
      email: '/affiliate/settings_profile/email',
      password: '/affiliate/settings_profile/password',
      payment: '/affiliate/settings_profile/payment',
      twofa: '/affiliate/settings_profile/twofa',
    },
    trackers: {
      root: '/affiliate/trackers',
      details: {
        root: '/affiliate/trackers/details',
        id: '/affiliate/trackers/details/:trackerId',
        creatives: {
          root: '/affiliate/trackers/details/:trackerId/creatives',
          link: {
            root: '/affiliate/trackers/details/:trackerId/creatives/:creativeId'
          }
        }
      }
    },
    api: {
      root: '/affiliate/api',
    },
    summaryReport: {
      root: '/affiliate/summary_report',
    },
    summary_report_v2: {
      root: '/affiliate/player-and-commission-report',
    },
    s2s: {
      root: '/affiliate/webhook',
      details: '/affiliate/webhook/:webhookId',
    },
    creativeReport: {
      root: '/affiliate/creative_report',
    },
    subAffiliateReport: {
      root: '/affiliate/subaffiliate_report',
    },
    downloadReport: {
      root: '/affiliate/download_report',
    },
    faq: {
      root: '/affiliate/faq',
    },
    reference: {
      root: '/affiliate/reference'
    },
    newCreativeReport: {
      root: '/affiliate/new_creative_report',
    },
    offers_list: {
      root: '/affiliate/offers_list',
      details: '/affiliate/offers_list/details/:offerId',
    },
    paymentHistory: {
      root: '/affiliate/payment_history',
      details: '/affiliate/payment_history/details/:id',
      detailsRoot: '/affiliate/payment_history/details',
    }
  },

  common: {
    auth: '/auth',
    registration: '/registration',
    dashboard: '/dashboard',
    confirmation: '/registration/confirmation/:confirmationId',
    terms: '/terms',
    privacyPolicy: '/privacy-policy',
    faq: '/faq',
    affiliate_ref: '/affiliate_ref/:token',
    passwordRecovery: '/password/recovery',
    confirmRecoveryRoot: '/password/confirm',
    confirmRecovery: '/password/confirm/:token',
    changePassword: '/password/change/:token',
    changePassRoot: '/password/change',
    inviteAdmin: '/invite/password/:token',
    invitePartner: '/password/setup/:token',
    twofa_email: '/email',
    twofa_google: '/google',
    maintenance: 'maintenance'
  }
};

export default routes;
