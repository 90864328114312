//core
import { createSelector } from "@reduxjs/toolkit";

//store
import { RootState } from "../../index";

const storageListSelector = (state: RootState) => state.storage.list;
const storageListDataSelector = (state: RootState) => state.storage.list.data;
const storageListErrorSelector = (state: RootState) => state.storage.list.error;
const storageListFetchingSelector = (state: RootState) => state.storage.list.isFetching;

export const selectStorageList = createSelector(storageListSelector, (list) => list);
export const selectStorageListData = createSelector(storageListDataSelector, (data) => data);
export const selectStorageListError = createSelector(storageListErrorSelector, (error) => error);
export const selectStorageListIsFetching = createSelector(storageListFetchingSelector, (isFetching) => isFetching);

//create
const storageCreateDataSelector = (state: RootState) => state.storage.create.data;
const storageCreateErrorSelector = (state: RootState) => state.storage.create.error;
const storageCreateFetchingSelector = (state: RootState) => state.storage.create.isFetching;

export const selectStorageCreateData = createSelector(storageCreateDataSelector, (data) => data);
export const selectStorageCreateError = createSelector(storageCreateErrorSelector, (error) => error);
export const selectStorageCreateIsFetching = createSelector(storageCreateFetchingSelector, (isFetching) => isFetching);

//update
const storageUpdateDataSelector = (state: RootState) => state.storage.update.data;
const storageUpdateErrorSelector = (state: RootState) => state.storage.update.error;
const storageUpdateFetchingSelector = (state: RootState) => state.storage.update.isFetching;

export const selectStorageUpdateData = createSelector(storageUpdateDataSelector, (data) => data);
export const selectStorageUpdateError = createSelector(storageUpdateErrorSelector, (error) => error);
export const selectStorageUpdateIsFetching = createSelector(storageUpdateFetchingSelector, (isFetching) => isFetching);

//delete
const storageDeleteDataSelector = (state: RootState) => state.storage.delete.data;
const storageDeleteErrorSelector = (state: RootState) => state.storage.delete.error;
const storageDeleteFetchingSelector = (state: RootState) => state.storage.delete.isFetching;

export const selectStorageDeleteData = createSelector(storageDeleteDataSelector, (data) => data);
export const selectStorageDeleteError = createSelector(storageDeleteErrorSelector, (error) => error);
export const selectStorageDeleteIsFetching = createSelector(storageDeleteFetchingSelector, (isFetching) => isFetching);

//labels
const storageLabelsDataSelector = (state: RootState) => state.storage.labels.data;
const storageLabelsErrorSelector = (state: RootState) => state.storage.labels.error;
const storageLabelsFetchingSelector = (state: RootState) => state.storage.labels.isFetching;

export const selectStorageLabelsData = createSelector(storageLabelsDataSelector, (data) => data);
export const selectStorageLabelsError = createSelector(storageLabelsErrorSelector, (error) => error);
export const selectStorageLabelsIsFetching = createSelector(storageLabelsFetchingSelector, (isFetching) => isFetching);

//item
const storageItemDataSelector = (state: RootState) => state.storage.item.data;
const storageItemErrorSelector = (state: RootState) => state.storage.item.error;
const storageItemFetchingSelector = (state: RootState) => state.storage.item.isFetching;

export const selectStorageItemData = createSelector(storageItemDataSelector, (data) => data);
export const selectStorageItemError = createSelector(storageItemErrorSelector, (error) => error);
export const selectStorageItemIsFetching = createSelector(storageItemFetchingSelector, (isFetching) => isFetching);
