import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  field: {
    width: '100%',
    '& > div': {
      width: '100%',
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: 8,
    }
  },
  icon: {
    color: theme.palette.other.outlinedBorder
  },
  iconButton: {
    margin: '0 -1px',
  },
}));
