//core
import {
  AnyAction,
  createAsyncThunk,
  combineReducers,
  configureStore,
  Middleware,
  isRejectedWithValue
} from "@reduxjs/toolkit";
// import logger from 'redux-logger';
// import { applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';

//reducers
import authSlice from './common/auth';
import userSlice from './common/user';
import registrationSlice from './common/registration';
import configurationSlice from './common/configuration';
import uiSlice from './common/ui';
import fieldsSlice from './common/fields';
import inviteAdminSlice from './common/inviteAdmin';
import invitePartnerSlice from './common/invitePartner';
import storageSlice from './common/storage';
import twoFactorAuthSlice from './common/twoFactorAuth';
import protectedAPISlice from './common/protectedAPI';

//affiliate
import dashboardSlice from './affiliate/dashboard';
import profileSlice from './affiliate/profile';
import trackersSlice from './affiliate/trackers';
import summaryReportSlice from './affiliate/reports/summary_report';
import creativeReportSlice from './affiliate/reports/creative_report';
import subAffiliateReportSlice from './affiliate/reports/subaffiliate_report';
import downloadReportSlice from './affiliate/reports/download_report';
import statisticsApi from './affiliate/statisticsApi';
import s2sSlice from './affiliate/s2s/';
import reference from './affiliate/reference';
import {default as affiliateCreativeReportSlice } from './affiliate/reports/new_creative_report';
import {default as affiliateOffersSlice } from './affiliate/offers';
import {default as affSummaryReportV2Slice } from './affiliate/reports/summary_report_v2';
import {default as affPaymentHistorySlice} from './affiliate/paymentHistory';
//admin
import creativesSlice from './admin/сreatives';
import {default as adminCreativesV2Slice} from './admin/сreativesV2';
import {default as adminDashboardSlice} from './admin/dashboard';
import financeReportSlice from './admin/reports/finance_report';
import detailsFinanceReportSlice from './admin/reports/details_finance_report';
import partnersSlice from './admin/partners';
import affiliateProfileSlice from './admin/affiliateProfile';
import affiliateDealsSlice from './admin/affiliateDeals';
import invitationLinksSlice from './admin/invitationLinks';
import affTrackersSlice from './admin/affiliateTrackers';
import {default as adminSummaryReportSlice } from './admin/reports/summary_report';
import {default as adminSummaryReportV2Slice } from './admin/reports/summary_report_v2';
import {default as adminSubAffiliateReportSlice } from './admin/reports/subaffiliate_report';
import {default as adminCreativeReportSlice } from './admin/reports/creative_report';
import {default as adminWebhooksReportSlice } from './admin/reports/webhooks_report';
import adminDownloadReportSlice from './admin/reports/download_report';
import rolesAndPermissionsSlice from './admin/rolesAndPermissions';
import admTrackersSlice from './admin/admTrackers'
import conditionsSlice from "./admin/conditions";
import employeesSlice from './admin/employees';
import quintsApiSlice from './admin/quintsApi';
import {default as adminOldCreativeReportSlice } from './admin/reports/old_creative_report';
import subAffDealsSlice from "./admin/subAffDeals";
import playersListSlice from './admin/playersList';
import emailTemplatesSlice from './admin/emailTemplates';
import { default as adminOffersSlice } from './admin/offers';
import { default as brandsSlice } from './admin/brands';
import { default as dashboardV2Slice } from './admin/dashboardV2';
import billingSlice from './admin/billing';
import {default as adminAccountSlice } from './admin/account';
import {default as admPaymentHistorySlice} from './admin/paymentHistory';

import { protectedMiddleware } from "./middleware/protectedMiddleware";



const affiliateReducer = combineReducers({
  dashboard: dashboardSlice,
  profile: profileSlice,
  trackers: trackersSlice,
  summary_report: summaryReportSlice,
  summary_report_v2: affSummaryReportV2Slice,
  creative_report: creativeReportSlice,
  subaffiliate_report: subAffiliateReportSlice,
  download_report: downloadReportSlice,
  api: statisticsApi,
  s2s: s2sSlice,
  reference: reference,
  new_creative_report: affiliateCreativeReportSlice,
  offers: affiliateOffersSlice,
  payment_history: affPaymentHistorySlice,
});

const adminReducer = combineReducers({
  creatives: creativesSlice,
  creatives_v2: adminCreativesV2Slice,
  dashboard: adminDashboardSlice,
  dashboardV2: dashboardV2Slice,
  finance_report: financeReportSlice,
  details_finance_report: detailsFinanceReportSlice,
  partners: partnersSlice,
  affiliateProfile: affiliateProfileSlice,
  affiliateDeals: affiliateDealsSlice,
  invitation_links: invitationLinksSlice,
  trackers: affTrackersSlice,
  summary_report: adminSummaryReportSlice,
  summary_report_v2: adminSummaryReportV2Slice,
  subaffiliate_report: adminSubAffiliateReportSlice,
  creative_report: adminCreativeReportSlice,
  roles_and_permissions: rolesAndPermissionsSlice,
  download_report: adminDownloadReportSlice,
  webhooks_report: adminWebhooksReportSlice,
  admTrackers: admTrackersSlice,
  conditions: conditionsSlice,
  employees: employeesSlice,
  quints_api: quintsApiSlice,
  old_creative_report: adminOldCreativeReportSlice,
  subAffDeals: subAffDealsSlice,
  players_list: playersListSlice,
  emailTemplates: emailTemplatesSlice,
  offers: adminOffersSlice,
  brands: brandsSlice,
  billing: billingSlice,
  account: adminAccountSlice,
  payment_history: admPaymentHistorySlice,
});

const rootReducer = combineReducers({
  configuration: configurationSlice,
  registration: registrationSlice,
  auth: authSlice,
  user: userSlice,
  fields: fieldsSlice,
  affiliate: affiliateReducer,
  admin: adminReducer,
  ui: uiSlice,
  invite_admin: inviteAdminSlice,
  invite_partner: invitePartnerSlice,
  storage: storageSlice,
  two_factor_auth: twoFactorAuthSlice,
  protected_api: protectedAPISlice,
});

export type RootState = ReturnType<typeof rootReducer>;

const reducerProxy = (state: any, action: AnyAction) => {
  if (action.type === 'logout/LOGOUT') {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

export const resetState = createAsyncThunk(
  "auth/logout",
  async function (_payload, thunkAPI) {
    thunkAPI.dispatch({ type: 'logout/LOGOUT' });
  }
);

export const store = configureStore({
  reducer: reducerProxy,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware<{
      serializableCheck: false;
    }>().concat(protectedMiddleware), // Add the custom middleware here
});


