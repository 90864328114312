import React from "react";
import { useTranslation } from "react-i18next";

interface IAdjustmentEditedProps {
  data: any;
}

const AdjustmentEdited: React.FC<IAdjustmentEditedProps> = ({
  data,
}): JSX.Element => {
  const { t } = useTranslation();

  const first = `
    ${t("admin.reports.detailed_fin_report.logs.actions.adjustment.reason_from")
    .replace('{reason}', `${t(`admin.details_finance_report.adjustments.options.${data.reason}`)}`)
    .replace('{amount}', `${data.amount}`)}
  `;

  const amount = data.from.amount !== data.to.amount && `${t("admin.reports.detailed_fin_report.logs.actions.adjustment.amount_from")
    .replace('{from}', `${data.from.amount}`)
    .replace('{to}', `${data.to.amount}`)}`;

  const brand = data.from.brand !== data.to.brand && `${t("admin.reports.detailed_fin_report.logs.actions.adjustment.brand_from")
    .replace('{from}', `${!!data.from.brand ? data.from.brand : '-'}`)
    .replace('{to}', `${!!data.to.brand ? data.to.brand : '-'}`)}`;

  const comment = data.from.comment !== data.to.comment && `${t("admin.reports.detailed_fin_report.logs.actions.adjustment.comment_from")
    .replace('{from}', `${data.from.comment}`)
    .replace('{to}', `${data.to.comment}`)}`;

  const message = `
    ${first} ${!!amount ? amount : ''}${!!amount && !!brand || !!comment ? ', ' : ''}${!!brand ? brand : ''}${!!comment ? ', ' : ''}${!!comment ? comment : ''}
  `;

  return (
    <>
      {message}
    </>
  );
};

export default AdjustmentEdited;
