//core
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, useMediaQuery } from "@mui/material";

//styles
import { useStyles } from "../../../AffPaymentDetails/components/PaymentDetailsTable/styles";

//components
import TableData from "../../../../index";
import MobileList from "../../../AffPaymentDetails/components/PaymentDetailsTable/components/MobileList";
import { OrderType } from "../../../../../../affiliate/Trackers";

interface IPaymentDetailsTableProps {
  data: any;
  toggleModal: (state: boolean) => void;
}

const PaymentDetailsTable: React.FC<IPaymentDetailsTableProps> = ({
  data,
  toggleModal,
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();

  const mobile = useMediaQuery('(max-width:767px)');

  const [order, setOrder] = useState<OrderType>({ id: 'desc' });

  return (
    <Box className={styles.block}>
      {mobile ? (<>
        <MobileList data={data} />
      </>) : (
        <TableData
          list={data.list}
          columns={data.columns}
          order={order}
          setOrder={setOrder}
          page={1}
          rowsPerPage={1000}
          totalEntries={+data.total_entries}
          onPageChange={() => {}}
          onRowsPerPageChange={() => {}}
          actionsCount={1}
          translationColumnsPrefix="columns_for_display.table.generate_report."
          startRowNum={data.start_row_num}
          endRowNum={data.end_row_num}
          hidePagination
          elevation={0}
        />
      )}

      <Box className={styles.footer}>
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          fullWidth
          onClick={() => toggleModal(false)}
        >
          {t("common.buttons.close")}
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentDetailsTable;
