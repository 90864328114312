import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  wrapper: {
    flex: '1 1 auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    top: 24,
    left: 0,
    right: 0,
    padding: '0 26px 0 36px',
    '@media (max-width: 767px)': {
      padding: '0 6px 0 16px',
    },
  },

  title: {
    lineHeight: 1,
    fontWeight: 700,
    textAlign: 'left',
  },

  content: {
    display: 'flex',
    flex: '1 1 auto',
    paddingTop: 0,
    overflow: 'hidden',
    height: '100%',
    marginRight: -12,
  },

  root: {
    zIndex: 1210,
    '@media (max-width: 767px)': {},
    '& .MuiBackdrop-root': {},
  },
  paper: {
    width: 456,
    padding: '82px 36px 114px 36px',
    '@media (max-width: 767px)': {
      width: '100%',
      padding: '72px 16px 85px 16px',
    },
  },
  reportPaper: {
    width: 456,
    padding: '82px 36px 150px 36px',
    '@media (max-width: 767px)': {
      width: '100%',
      padding: '72px 16px 132px 16px',
    },
  },
  modal: {
    background: 'red',
  },
});
