import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles/createTheme";

export const useStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    paddingTop: 4,
  },
  top: {
    marginBottom: 4,
    '@media (max-width: 767px)': {
      marginBottom: 8,
    }
  },
  paper: {
    '@media (max-width: 767px)': {}
  },
}));
