//core
import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import { useTranslation } from "react-i18next";
import {numberToLocaleFormat, numberToLocaleFormatInteger} from "../../../../../helpers/utils";

interface IText {
  value: string | number;
}

const useStyles = makeStyles({
  text: {
    color: 'inherit'
  }
});

const IntegerWithZero = ({ value }: IText) => {
  const styles = useStyles();

  const { i18n } = useTranslation();
  const [locale, setLocale] = useState<string>('en');
  const renderValue = !!value ? numberToLocaleFormatInteger(value, locale) : value;

  useEffect(() => {
    if (i18n.language === 'ru') {
      setLocale('ru');
    }

    if (i18n.language === 'en') {
      setLocale('en');
    }

  }, [i18n.language]);

  return (
    <span className={styles.text}>
      { value === null ? '—' : renderValue}
    </span>
  );
};

export default IntegerWithZero;
