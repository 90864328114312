export const favoriteFilterKeys = {
  productIds: 'favAffSRProductIds',
  platformIds: 'favAffSRPlatformIds',
  managerIds: 'favAffSRManagerIds',
  affiliateIds: 'favAffSRAffiliateIds',
  trackerIds: 'favAffSRTrackerIds',
  creativeIds: 'favAffSRCreativeIds',
  brands: 'favAffSRBrands',
  currencies: 'favAffSRCurrencies',
  dealTypes: 'favAffSRDealTypes',
  registrationDates: 'favAffSRRegDates',
  firstDepositDates: 'favAffSRFirstDepDates',
  anid: 'favAffSRAnid',
  playerId: 'favAffSRPlayerId',
  source1: 'favAffSRSource1',
  source2: 'favAffSRSource2',
  source3: 'favAffSRSource3',
  source4: 'favAffSRSource4',
  source5: 'favAffSRSource5',
  deviceTypes: 'favAdmSRDeviceTypes',
}

export const reportStorageProps = {
  type: 'player-and-commission-report',
  target: 'filters',
  tableSettingsTarget: 'table-settings',
  exceptions: ['group_by', 'period', 'sent_from', 'sent_to'],
  exceptionsIds: ['productIds', 'platformIds', 'managerIds', 'affiliateIds', 'trackerIds', 'brands', 'currencies', 'dealTypes', 'deviceTypes'],
  exceptionsStr: ['anid', 'playerId', 'source1', 'source2', 'source3', 'source4', 'source5'],
}
