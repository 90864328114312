//core
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from '../../../../store/';
import {numberToLocaleFormatInteger, strToNumberFormat} from "../../../../helpers/utils";
import {useTranslation} from "react-i18next";
// @ts-ignore
import Big from "big.js"

//Main list
const listDataSelector = (state: RootState) => state.admin.details_finance_report.list.data;
export const selectListData = createSelector(listDataSelector, (data) => {
  if (!!data) {
    const isRecalculatableСolumns = data.columns.filter((item:any) => item.isRecalculatable);

    const columns = data.columns.map((item: any) => {
      if (item.name === 'affiliateId') {
        return { ...item, type: 'finAffId', selectAll: true };
      }
      if (item.name === 'status') {
        return { ...item, type: 'detailsFinReportStatus'};
      }
      if (item.name === 'comment') {
        return { ...item, type: 'detailFinReportComment'};
      }
      if (item.type === 'stringWithPrefix') {
        return { ...item, prefix: 'common.components.payment.payment_method_options.'};
      }

      return item;
    });

    const list = !!data.list.length ? data.list.reduce((acc: any[], item: any) => {
      if (!!isRecalculatableСolumns.length && !!data.rate_currency) {
        const obj = isRecalculatableСolumns.reduce((object:any, recalculatableItem:any) => {
          if (item.hasOwnProperty(recalculatableItem.name) && item[recalculatableItem.name] !== null && item[recalculatableItem.name] !== 'N/A') {
            const columnValue = new Big(item[recalculatableItem.name])
            const rateValue = new Big(data.rate_currency.value)
            const newValue =  columnValue.times(rateValue);

            return {...object, [recalculatableItem.name]: (parseInt(String(newValue * 100))) / 100};
          } else {
            return {...object, [recalculatableItem.name]: item[recalculatableItem.name]};
          }

        }, {});
        acc.push({
          toPayNoRecalculatable: item.toPay,
          ...item,
          ...obj,
        });
        return acc;
      } else {
        acc.push({
          ...item,
        });
        return acc;
      }
    }, []) : [];

    let total;

    if (!!isRecalculatableСolumns.length && !!data.rate_currency) {
      const obj = isRecalculatableСolumns.reduce((object:any, recalculatableItem:any) => {
        if (data.total.hasOwnProperty(recalculatableItem.name) && data.total[recalculatableItem.name] !== null && data.total[recalculatableItem.name] !== 'N/A') {
          const columnValue = !!data.total[recalculatableItem.name].length ? new Big(data.total[recalculatableItem.name]) : new Big('0.00');
          const rateValue = new Big(data.rate_currency.value)
          const newValue =  columnValue.times(rateValue);
          return {...object, [recalculatableItem.name]: (parseInt(String(newValue * 100))) / 100};
        } else {
          return {...object, [recalculatableItem.name]: data.total[recalculatableItem.name]};
        }

      }, {});

      total = {
        ...data.total,
        ...obj,
      }
    } else {
      total = {
        ...data.total,
      }
    }

    return {
      ...data,
      columns: columns,
      list: list,
      total: total
    };
  } else {
    return data
  }

  return data;
});

const listIsFetchingSelector = (state: RootState) => state.admin.details_finance_report.list.isFetching;
export const selectIsFetchingList = createSelector(listIsFetchingSelector, (isFetching) => isFetching);

const listErrorSelector = (state: RootState) => state.admin.details_finance_report.list.error;
export const selectErrorList = createSelector(listErrorSelector, (error) => error);

export const selectListDataColumnsSort = createSelector(listDataSelector, (data) => data?.columns.filter((el: any) => el.sort === true));


//Filters
const filtersDataSelector = (state: RootState) => state.admin.details_finance_report.filters.data;
export const selectFiltersData = createSelector(filtersDataSelector, (data) => data);

const filtersIsFetchingSelector = (state: RootState) => state.admin.details_finance_report.filters.isFetching;
export const selectIsFetchingFilters = createSelector(filtersIsFetchingSelector, (isFetching) => isFetching);

const filtersErrorSelector = (state: RootState) => state.admin.details_finance_report.filters.error;
export const selectErrorFilters = createSelector(filtersErrorSelector, (error) => error);


//Adjustment
const adjustmentListDataSelector = (state: RootState) => state.admin.details_finance_report.adjustmentList.data;
export const selectAdjustmentData = createSelector(adjustmentListDataSelector, (data) => {
  if (!!data) {
    const columns = data.columns.map((item: any) => {
      if (item.name === 'isApproved') {
        return { ...item, type: 'AdjustmentStatus'};
      }

      if (item.name === 'reason') {
        return { ...item, type: 'AdjustmentReason'};
      }

      if (item.name === 'createdByUsername') {
        return { ...item, type: 'AdjustmentCreatedBy'};
      }

      if (item.name === 'comment') {
        return { ...item, type: 'mediumString'};
      }

      return item;
    });

    const list = !!data.list.length ? data.list.map((item: any) => {
      return { ...item, amountNoRecalculatable: item.amount };
    }) : [];

    const isRecalculatableСolumns = data.columns.filter((item:any) => item.isRecalculatable);
    const isRecalculatablelist = !!list.length ? list.reduce((acc: any[], item: any) => {
      if (!!isRecalculatableСolumns.length && !!data.rate_currency) {
        const obj = isRecalculatableСolumns.reduce((object:any, recalculatableItem:any) => {

          if (item.hasOwnProperty(recalculatableItem.name) && !!item[recalculatableItem.name]) {
            const columnValue = new Big(item[recalculatableItem.name])
            const rateValue = new Big(data.rate_currency.value)
            const newValue =  columnValue.times(rateValue);
            return {...object, [recalculatableItem.name]: (parseInt(String(newValue * 100))) / 100};
          } else {
            return {...object, [recalculatableItem.name]: item.name};
          }

        }, {});
        acc.push({
          ...item,
          ...obj,
        });
        return acc;
      } else {
        acc.push({
          ...item,
        });
        return acc;
      }
    }, []) : [];

    let total_amount;

    if (!!isRecalculatableСolumns.length && !!data.rate_currency) {
      if (data.hasOwnProperty('total_amount') && !!data.total_amount) {
        const columnValue = !!data.total_amount ? new Big(data.total_amount) : new Big('0.00');
        const rateValue = new Big(data.rate_currency.value)
        const newValue =  columnValue.times(rateValue);
        total_amount = (parseInt(String(newValue * 100))) / 100;
      } else {
        total_amount =  data.total_amount;
      }
    } else {
      total_amount = data.total_amount
    }

    return { ...data, columns: columns, list: isRecalculatablelist, total_amount: total_amount};

  } else {
    return data
  }

  return data;
});

const adjustmentListIsFetchingSelector = (state: RootState) => state.admin.details_finance_report.adjustmentList.isFetching;
export const selectIsFetchingAdjustmentList = createSelector(adjustmentListIsFetchingSelector, (isFetching) => isFetching);

const adjustmentListErrorSelector = (state: RootState) => state.admin.details_finance_report.adjustmentList.error;
export const selectErrorAdjustmentList = createSelector(adjustmentListErrorSelector, (error) => error);


//Adjustment reasons
const adjustmentReasonsDataSelector = (state: RootState) => state.admin.details_finance_report.adjustmentReasons.data;
export const selectAdjustmentReasonsData = createSelector(adjustmentReasonsDataSelector, (data) => data);

const adjustmentReasonsIsFetchingSelector = (state: RootState) => state.admin.details_finance_report.adjustmentReasons.isFetching;
export const selectIsFetchingAdjustmentReasons = createSelector(adjustmentReasonsIsFetchingSelector, (isFetching) => isFetching);

const adjustmentReasonsErrorSelector = (state: RootState) => state.admin.details_finance_report.adjustmentReasons.error;
export const selectErrorAdjustmentReasons = createSelector(adjustmentReasonsErrorSelector, (error) => error);

//Adjustment brands
const adjustmentBrandsDataSelector = (state: RootState) => state.admin.details_finance_report.adjustmentBrands.data;
export const selectAdjustmentBrandsData = createSelector(adjustmentBrandsDataSelector, (data) => {
  if (!!data) {
    const new_data = data.map((item: any,index: number) => {
      return { id: item, value: item};
    });

    return new_data;
  } else {
    return data
  }
});
const adjustmentBrandsIsFetchingSelector = (state: RootState) => state.admin.details_finance_report.adjustmentBrands.isFetching;
export const selectIsFetchingAdjustmentBrands = createSelector(adjustmentBrandsIsFetchingSelector, (isFetching) => isFetching);

const adjustmentBrandsErrorSelector = (state: RootState) => state.admin.details_finance_report.adjustmentBrands.error;
export const selectErrorAdjustmentBrands = createSelector(adjustmentBrandsErrorSelector, (error) => error);


//Adjustment add
const adjustmentAddDataSelector = (state: RootState) => state.admin.details_finance_report.adjustmentAdd.data;
export const selectAdjustmentAddData = createSelector(adjustmentAddDataSelector, (data) => data);


//Adjustment added error
const adjustmentAddErrorSelector = (state: RootState) => state.admin.details_finance_report.adjustmentAdd.error;
export const selectAdjustmentAddError = createSelector(adjustmentAddErrorSelector, (error) => error);

//Adjustment approve
const adjustmentApproveDataSelector = (state: RootState) => state.admin.details_finance_report.adjustmentApprove.data;
export const selectAdjustmentApproveData = createSelector(adjustmentApproveDataSelector, (data) => data);


//Adjustment remove
const adjustmentRemoveDataSelector = (state: RootState) => state.admin.details_finance_report.adjustmentRemove.data;
export const selectAdjustmentRemoveData = createSelector(adjustmentRemoveDataSelector, (data) => data);

//Adjustment edit
const adjustmentEditDataSelector = (state: RootState) => state.admin.details_finance_report.adjustmentEdit.data;
export const selectAdjustmentEditData = createSelector(adjustmentEditDataSelector, (data) => data);

//Adjustment edit error
const adjustmentEditErrorSelector = (state: RootState) => state.admin.details_finance_report.adjustmentEdit.error;
export const selectAdjustmentEditError = createSelector(adjustmentEditErrorSelector, (error) => error);

//Download Details Report
const downloadDetailsReportDataSelector = (state: RootState) => state.admin.details_finance_report.downloadDetailsReport.data;
export const selectDownloadDetailsReportData = createSelector(downloadDetailsReportDataSelector, (data) => data);

//Payment change
const paymentChangeDataSelector = (state: RootState) => state.admin.details_finance_report.paymentChange.data;
export const selectPaymentChangeData = createSelector(paymentChangeDataSelector, (data) => data);

const paymentChangeErrorSelector = (state: RootState) => state.admin.details_finance_report.paymentChange.error;
export const selectPaymentChangeError = createSelector(paymentChangeErrorSelector, (error) => error);

//Manager Approve
const managerApproveDataSelector = (state: RootState) => state.admin.details_finance_report.managerApprove.data;
export const selectManagerApproveData = createSelector(managerApproveDataSelector, (data) => data);

//Cancel Manager Approve
const managerCancelApproveDataSelector = (state: RootState) => state.admin.details_finance_report.cancelManagerApprove.data;
export const selectCancelManagerApproveData = createSelector(managerCancelApproveDataSelector, (data) => data);

//To Paid Approve
const toPaidApproveDataSelector = (state: RootState) => state.admin.details_finance_report.toPaidApprove.data;
export const selectToPaidApproveData = createSelector(toPaidApproveDataSelector, (data) => data);

//Processing Payment
const processingPaymentDataSelector = (state: RootState) => state.admin.details_finance_report.processingPayment.data;
export const selectProcessingPaymentData = createSelector(processingPaymentDataSelector, (data) => data);

//Cancel Processing Payment
const cancelProcessingPaymentDataSelector = (state: RootState) => state.admin.details_finance_report.cancelProcessingPayment.data;
export const selectCancelProcessingPaymentData = createSelector(cancelProcessingPaymentDataSelector, (data) => data);

//Carry Over Approve
const carryOverApproveDataSelector = (state: RootState) => state.admin.details_finance_report.carryOverApprove.data;
export const selectCarryOverApproveData = createSelector(carryOverApproveDataSelector, (data) => data);

//Cancel Carry Over Approve
const cancelCarryOverApproveDataSelector = (state: RootState) => state.admin.details_finance_report.cancelCarryOverApprove.data;
export const selectCancelCarryOverApproveData = createSelector(cancelCarryOverApproveDataSelector, (data) => data);

// Change Comment
const changeCommentDataSelector = (state: RootState) => state.admin.details_finance_report.changeComment.data;
export const selectChangeCommentData = createSelector(changeCommentDataSelector, (data) => data);

//report row data
const rowDataSelector = (state: RootState) => state.admin.details_finance_report.reportRowData.data;
const rowDataIsFetchingSelector = (state: RootState) => state.admin.details_finance_report.reportRowData.isFetching;
const rowDataErrorSelector = (state: RootState) => state.admin.details_finance_report.reportRowData.error;
export const selectRowData = createSelector(rowDataSelector, (data) => {
  if (!!data) {
    const new_data = {
      toPayNoRecalculatable: data.toPay,
      ...data,
    };
    return { ...new_data };
  }
});
export const selectRowDataIsFetching = createSelector(rowDataIsFetchingSelector, (isFetching) => isFetching);
export const selectRowDataError = createSelector(rowDataErrorSelector, (error) => error);

//columns
const columnsDataSelector = (state: RootState) => state.admin.details_finance_report.columns.data;
const columnsFetchingSelector = (state: RootState) => state.admin.details_finance_report.columns.isFetching;
const columnsErrorSelector = (state: RootState) => state.admin.details_finance_report.columns.error;
export const selectColumnsData = createSelector(columnsDataSelector, (data) => data);
export const selectColumnsError = createSelector(columnsErrorSelector, (error) => error);
export const selectColumnsIsFetching = createSelector(columnsFetchingSelector, (isFetching) => isFetching);


//PaymentBreakdown
const paymentBreakdownDataSelector = (state: RootState) => state.admin.details_finance_report.paymentBreakdown.data;
const paymentBreakdownFetchingSelector = (state: RootState) => state.admin.details_finance_report.paymentBreakdown.isFetching;
const paymentBreakdownErrorSelector = (state: RootState) => state.admin.details_finance_report.paymentBreakdown.error;
export const selectPaymentBreakdownData = createSelector(paymentBreakdownDataSelector, (data) => {
  if (!!data) {
    const isRecalculatableСolumns = data.columns.filter((item:any) => item.isRecalculatable);
    const list = !!data.list.length ? data.list.reduce((acc: any[], item: any) => {
      if (!!isRecalculatableСolumns.length && !!data.rate_currency) {
        const obj = isRecalculatableСolumns.reduce((object:any, recalculatableItem:any) => {

          if (item.hasOwnProperty(recalculatableItem.name) && !!item[recalculatableItem.name]) {
            const columnValue = new Big(item[recalculatableItem.name])
            const rateValue = new Big(data.rate_currency.value)
            const newValue =  columnValue.times(rateValue);
            return {...object, [recalculatableItem.name]: (parseInt(String(newValue * 100))) / 100};
          } else {
            return {...object, [recalculatableItem.name]: item.name};
          }

        }, {});
        acc.push({
          ...item,
          ...obj,
        });
        return acc;
      } else {
        acc.push({
          ...item,
        });
        return acc;
      }
    }, []) : [];

    return { ...data, list: list};

  } else {
    return data
  }

  return data;
});
export const selectPaymentBreakdownError = createSelector(paymentBreakdownFetchingSelector, (error) => error);
export const selectPaymentBreakdownIsFetching = createSelector(paymentBreakdownErrorSelector, (isFetching) => isFetching);


//AffiliatesIds
const paymentAffiliatesIdsDataSelector = (state: RootState) => state.admin.details_finance_report.affiliatesIds.data;
const paymentAffiliatesIdsFetchingSelector = (state: RootState) => state.admin.details_finance_report.affiliatesIds.isFetching;
const paymentAffiliatesIdsErrorSelector = (state: RootState) => state.admin.details_finance_report.affiliatesIds.error;
export const selectAffiliatesIdsData = createSelector(paymentAffiliatesIdsDataSelector, (data) => data);
export const selectAffiliatesIdsError = createSelector(paymentAffiliatesIdsErrorSelector, (error) => error);
export const selectAffiliatesIdsIsFetching = createSelector(paymentAffiliatesIdsFetchingSelector, (isFetching) => isFetching);


//addPayoutRow
const addPayoutRowDataSelector = (state: RootState) => state.admin.details_finance_report.addPayoutRow.data;
const addPayoutRowErrorSelector = (state: RootState) => state.admin.details_finance_report.addPayoutRow.error;
const addPayoutRowFetchingSelector = (state: RootState) => state.admin.details_finance_report.addPayoutRow.isFetching;
export const selectAddPayoutRowData = createSelector(addPayoutRowDataSelector, (data) => data);
export const selectAddPayoutRowError = createSelector(addPayoutRowErrorSelector, (error) => error);
export const selectAddPayoutRowIsFetching = createSelector(addPayoutRowFetchingSelector, (isFetching) => isFetching);


//getPayoutsList
const getPayoutsListDataSelector = (state: RootState) => state.admin.details_finance_report.payoutsList.data;
const getPayoutsListErrorSelector = (state: RootState) => state.admin.details_finance_report.payoutsList.error;
const getPayoutsListFetchingSelector = (state: RootState) => state.admin.details_finance_report.payoutsList.isFetching;
export const selectGetPayoutsListData = createSelector(getPayoutsListDataSelector, (data) => {
  if (!!data) {
    let columns = data.columns.map((item: any) => {
      if (item.name === 'paidAt') {
        return { ...item, type: "fulldatetime" };
      }

      // if (item.name === 'amount') {
      //   return { ...item, name: "paid_amount" };
      // }

      if (item.name === 'comment') {
        return { ...item, type: "mediumString" };
      }

      if (item.name === 'paidByUsername') {
        return { ...item, type: "string" };
      }

      if (item.type === 'stringWithPrefix') {
        return {
          ...item,
          prefix: 'common.components.payment.payment_method_options.'
        };
      }

      return item;
    });

    const list = data.list.map((item: any) => {
      return {
        ...item,
        paidAt: item.paymentDate,
        amountNoRecalculatable: item.amount,
        walletAmountNoRecalculatable: item.walletAmount,
      };
    });

    const isRecalculatableСolumns = data.columns.filter((item:any) => item.isRecalculatable);
    const isRecalculatableList = list.reduce((acc: any[], item: any) => {

      if (!!isRecalculatableСolumns.length && !!data.rate_currency) {
        const obj = isRecalculatableСolumns.reduce((object:any, recalculatableItem:any) => {

          if (item.hasOwnProperty(recalculatableItem.name) && !!item[recalculatableItem.name]) {
            const columnValue = new Big(item[recalculatableItem.name])
            const rateValue = new Big(data.rate_currency.value)
            const newValue =  columnValue.times(rateValue);
            return {...object, [recalculatableItem.name]: (parseInt(String(newValue * 100))) / 100};
          } else {
            return {...object, [recalculatableItem.name]: item.name};
          }

        }, {});
        acc.push({
          ...item,
          ...obj,
        });
        return acc;
      } else {
        acc.push({
          ...item,
        });
        return acc;
      }
    }, []);


    return { ...data, columns: columns, list: isRecalculatableList, };
  } else {
    return data
  }

  return data;
});
export const selectGetPayoutsListError = createSelector(getPayoutsListErrorSelector, (error) => error);
export const selectGetPayoutsListIsFetching = createSelector(getPayoutsListFetchingSelector, (isFetching) => isFetching);

//getAvailablePayments
const getAvailablePaymentsDataSelector = (state: RootState) => state.admin.details_finance_report.availablePayments.data;
const getAvailablePaymentsErrorSelector = (state: RootState) => state.admin.details_finance_report.availablePayments.error;
const getAvailablePaymentsFetchingSelector = (state: RootState) => state.admin.details_finance_report.availablePayments.isFetching;
export const selectGetAvailablePaymentsData = createSelector(getAvailablePaymentsDataSelector, (data) => {
  if (!!data) {
    const new_data = data.map((item: any) => {
      return {
        ...item,
        id: item.paymentMethodValue[item.defaultField],
        showValue: item.paymentMethodValue[item.defaultField],
      };
    });

    return new_data;
  } else {
    return data
  }

  return data;
});
export const selectGetAvailablePaymentsError = createSelector(getAvailablePaymentsErrorSelector, (error) => error);
export const selectGetAvailablePaymentsIsFetching = createSelector(getAvailablePaymentsFetchingSelector, (isFetching) => isFetching);

//getAvailablePaymentsList
const getAvailablePaymentsListDataSelector = (state: RootState) => state.admin.details_finance_report.availablePaymentsList.data;
const getAvailablePaymentsListErrorSelector = (state: RootState) => state.admin.details_finance_report.availablePaymentsList.error;
const getAvailablePaymentsListFetchingSelector = (state: RootState) => state.admin.details_finance_report.availablePaymentsList.isFetching;
export const selectGetAvailablePaymentsListData = createSelector(getAvailablePaymentsListDataSelector, (data) => {
  if (!!data) {
    const new_data = data.map((item: any) => {
      return {
        ...item,
        id: item.paymentMethodValue[item.defaultField],
        showValue: item.paymentMethodValue[item.defaultField],
      };
    });

    return new_data;
  } else {
    return data
  }

  return data;
});
export const selectGetAvailablePaymentsListError = createSelector(getAvailablePaymentsListErrorSelector, (error) => error);
export const selectGetAvailablePaymentsListIsFetching = createSelector(getAvailablePaymentsListFetchingSelector, (isFetching) => isFetching);


//editPayment
const editPaymentDataSelector = (state: RootState) => state.admin.details_finance_report.editPayment.data;
const editPaymentErrorSelector = (state: RootState) => state.admin.details_finance_report.editPayment.error;
const editPaymentFetchingSelector = (state: RootState) => state.admin.details_finance_report.editPayment.isFetching;
export const selectEditPaymentData = createSelector(editPaymentDataSelector, (data) => data);
export const selectEditPaymentError = createSelector(editPaymentErrorSelector, (error) => error);
export const selectEditPaymentIsFetching = createSelector(editPaymentFetchingSelector, (isFetching) => isFetching);

//logs list
const logsDataSelector = (state: RootState) => state.admin.details_finance_report.logs.data;
const logsErrorSelector = (state: RootState) => state.admin.details_finance_report.logs.error;
const logsFetchingSelector = (state: RootState) => state.admin.details_finance_report.logs.isFetching;
export const selectLogsData = createSelector(logsDataSelector, (data) => {
  if (!!data && !!data[0]) {
    const columns = [
      { name: "createdAt", sort: false, type: "fulldatetime" },
      { name: "username", sort: false, type: "createdBy" },
      { name: "action", sort: false, type: "logsItem" }
    ];

    const list = data.map((item: any) => {
      return {...item, created_by: {username: item.username, id: item.userId}};
    });

    return { columns: columns, list: list };
  } else {
    return data;
  }
});
export const selectLogsError = createSelector(logsErrorSelector, (error) => error);
export const selectLogsIsFetching = createSelector(logsFetchingSelector, (isFetching) => isFetching);

//createPayout
const createPayoutDataSelector = (state: RootState) => state.admin.details_finance_report.createPayout.data;
const createPayoutErrorSelector = (state: RootState) => state.admin.details_finance_report.createPayout.error;
const createPayoutFetchingSelector = (state: RootState) => state.admin.details_finance_report.createPayout.isFetching;
export const selectCreatePayoutData = createSelector(createPayoutDataSelector, (data) => data);
export const selectCreatePayoutError = createSelector(createPayoutErrorSelector, (error) => error);
export const selectCreatePayoutIsFetching = createSelector(createPayoutFetchingSelector, (isFetching) => isFetching);

//updatePayout
const updatePayoutDataSelector = (state: RootState) => state.admin.details_finance_report.updatePayout.data;
const updatePayoutErrorSelector = (state: RootState) => state.admin.details_finance_report.updatePayout.error;
const updatePayoutFetchingSelector = (state: RootState) => state.admin.details_finance_report.updatePayout.isFetching;
export const selectUpdatePayoutData = createSelector(updatePayoutDataSelector, (data) => data);
export const selectUpdatePayoutError = createSelector(updatePayoutErrorSelector, (error) => error);
export const selectUpdatePayoutIsFetching = createSelector(updatePayoutFetchingSelector, (isFetching) => isFetching);

//removePayout
const removePayoutDataSelector = (state: RootState) => state.admin.details_finance_report.removePayout.data;
const removePayoutErrorSelector = (state: RootState) => state.admin.details_finance_report.removePayout.error;
const removePayoutFetchingSelector = (state: RootState) => state.admin.details_finance_report.removePayout.isFetching;
export const selectRemovePayoutData = createSelector(removePayoutDataSelector, (data) => data);
export const selectRemovePayoutError = createSelector(removePayoutErrorSelector, (error) => error);
export const selectRemovePayoutIsFetching = createSelector(removePayoutFetchingSelector, (isFetching) => isFetching);

//getInvoice
const getInvoiceDataSelector = (state: RootState) => state.admin.details_finance_report.get_invoice.data;
const getInvoiceErrorSelector = (state: RootState) => state.admin.details_finance_report.get_invoice.error;
const getInvoiceFetchingSelector = (state: RootState) => state.admin.details_finance_report.get_invoice.isFetching;
export const selectGetInvoiceData = createSelector(getInvoiceDataSelector, (data) => data);
export const selectGetInvoiceError = createSelector(getInvoiceErrorSelector, (error) => error);
export const selectGetInvoiceIsFetching = createSelector(getInvoiceFetchingSelector, (isFetching) => isFetching);

//uploadInvoice
const uploadInvoiceDataSelector = (state: RootState) => state.admin.details_finance_report.upload_invoice.data;
const uploadInvoiceErrorSelector = (state: RootState) => state.admin.details_finance_report.upload_invoice.error;
const uploadInvoiceFetchingSelector = (state: RootState) => state.admin.details_finance_report.upload_invoice.isFetching;
export const selectUploadInvoiceData = createSelector(uploadInvoiceDataSelector, (data) => data);
export const selectUploadInvoiceError = createSelector(uploadInvoiceErrorSelector, (error) => error);
export const selectUploadInvoiceIsFetching = createSelector(uploadInvoiceFetchingSelector, (isFetching) => isFetching);

//removeInvoice
const removeInvoiceDataSelector = (state: RootState) => state.admin.details_finance_report.remove_invoice.data;
const removeInvoiceErrorSelector = (state: RootState) => state.admin.details_finance_report.remove_invoice.error;
const removeInvoiceFetchingSelector = (state: RootState) => state.admin.details_finance_report.remove_invoice.isFetching;
export const selectRemoveInvoiceData = createSelector(removeInvoiceDataSelector, (data) => data);
export const selectRemoveInvoiceError = createSelector(removeInvoiceErrorSelector, (error) => error);
export const selectRemoveInvoiceIsFetching = createSelector(removeInvoiceFetchingSelector, (isFetching) => isFetching);


// periodsList
const periodsListDataSelector = (state: RootState) => state.admin.details_finance_report.periodsList.data;
const periodsListErrorSelector = (state: RootState) => state.admin.details_finance_report.periodsList.error;
const periodsListFetchingSelector = (state: RootState) => state.admin.details_finance_report.periodsList.isFetching;
export const selectPeriodsListData = createSelector(periodsListDataSelector, (data) => {
  if (!!data) {
    const list = data.list.map((item: any) => {
      return {...item, value: item.start + item.end};
    });

    return { ...data, list: list};
  } else {
    return data;
  }
});
export const selectPeriodsListError = createSelector(periodsListErrorSelector, (error) => error);
export const selectPeriodsListIsFetching = createSelector(periodsListFetchingSelector, (isFetching) => isFetching);

// convert currency
const convertCurrencyDataSelector = (state: RootState) => state.admin.details_finance_report.convertCurrency.data;
const convertCurrencyErrorSelector = (state: RootState) => state.admin.details_finance_report.convertCurrency.error;
const convertCurrencyFetchingSelector = (state: RootState) => state.admin.details_finance_report.convertCurrency.isFetching;
export const selectConvertCurrencyData = createSelector(convertCurrencyDataSelector, (data) => data);
export const selectConvertCurrencyError = createSelector(convertCurrencyErrorSelector, (error) => error);
export const selectConvertCurrencyIsFetching = createSelector(convertCurrencyFetchingSelector, (isFetching) => isFetching);
